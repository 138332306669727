import Tooltip from "@material-ui/core/Tooltip";
import { RootState } from "app/rootReducer";
import DealershipFilter from "components/Filters/DealershipFilter";
import MultiSelectFilter from "components/Filters/MultiSelectFilter";
import { TabContext } from "components/Layout/LayoutWrapper";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Typography } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import HourglassEmptyIcon from "@material-ui/icons/HourglassEmpty";
import { MdCancel } from "react-icons/md";
import { formatNumberAsCurrency } from "utils/functions";
import { getLockList } from "utils/models/LockEntity/listLockSlice";
import { lockEntityFunction } from "utils/models/ShowForm";
import { v4 as uuidv4 } from "uuid";
import AccessControl from "../../Access/AccessControl";
import ApplicantFilter from "../../Filters/ApplicantFilter";
import LenderFilter from "../../Filters/LenderFilter";
import PriceFilter from "../../Filters/PriceFilter";
import TextFilter from "../../Filters/TextFilter";
import Table, { CellValue, Column } from "../../Table";
import { getStyleByStatus, statusToChipTooltip, statusToLabel } from "../Deals";
import { moveDate } from "../DealsLog/DealsLog";
import { deleteDeal } from "../deleteDealSlice";
import { getDealList } from "../listDealSlice";
import { recoverDeal } from "../recoverDealSlice";
import { Deal, DealData, DealStatus, ReasonsDead, ReasonsDidNotProcess } from "../types";
import AddStatusReason from "./AddStatusReason";
export const projections = {
  "data.applicant.data.info.firstName": 1,
  "data.applicant.data.info.lastName": 1,
  "data.coApplicant.data.info.firstName": 1,
  "data.info.aftermarketOptions": 1,
  "data.coApplicant.data.info.lastName": 1,
  "data.info.status": 1,
  "data.info.statusReasons": 1,
  "data.info.lastStatusHistory": 1,
  "data.notes": 1,
  "data.info.refNumber": 1,
  "data.dealership.data.info.displayName": 1,
  "data.dealership.data.representative.data.info.firstName": 1,
  "data.dealership.data.representative.data.info.lastName": 1,
  "data.info.vehicle.make": 1,
  "data.info.vehicle.model": 1,
  "data.info.vehicle.VIN": 1,
  "data.lender.data.info.name": 1,
  "data.user.data.info.firstName": 1,
  "data.user.data.info.lastName": 1,
  "data.info.creditScores": 1,
  "data.userId": 1,
  "data.user._id": 1,
  "data.info.payment.fundedAmount": 1,
  "data.info.payment.dealTotal": 1,
  "data.info.dealDates": 1,
  "data.order": 1,
  "data.creditBureaus": 1,
  "data.info.type": 1,
  availableToRolesIds: 1,
  _id: 1,
  approved: 1,
  approvedByUserId: 1,
  approvedAt: 1,
  createdAt: 1,
  creatorId: 1,
  updatedAt: 1,
  deleted: 1
};

export const reasonsTooltip = (type: "applicant" | "dealership", deal: Deal): JSX.Element => {
  if (
    deal.data?.info?.statusReasons?.[type]?.reason &&
    deal.data?.info?.statusReasons?.[type]?.marked
  ) {
    return (
      <Tooltip
        title={
          <Typography variant="subtitle2">
            Reason: {deal.data?.info?.statusReasons?.[type]?.reason}
          </Typography>
        }
      >
        <CheckIcon style={{ color: "#4CAF50" }} />
      </Tooltip>
    );
  }
  if (type === "applicant" && deal.data?.info?.statusReasons?.applicant?.stillConsidering) {
    return (
      <Tooltip title={<Typography variant="subtitle2">Applicant is still considering</Typography>}>
        <HourglassEmptyIcon style={{ color: "#FFCC00" }} />
      </Tooltip>
    );
  }
  return <MdCancel size="20px" color="#F44336" />;
};

export default function (): JSX.Element {
  const slice = "status_reasons_table";
  const dealList = useSelector((state: RootState) => state.listDealSlice[slice]);
  const dispatch = useDispatch();
  const [requestId] = useState(uuidv4());
  const addDealState = useSelector((state: RootState) => state.addDealSlice[requestId]);
  const createOrFocusTab = useContext(TabContext);

  useEffect(() => {
    if (
      addDealState !== undefined &&
      addDealState.status === "success" &&
      addDealState.data !== null
    ) {
      lockEntityFunction(dispatch, addDealState.data.message._id, "deal", requestId);
      createOrFocusTab({
        label: "Show page",
        index: "showPage",
        isForSidebar: false,
        isForQuickAccess: false,
        isPersistent: false,
        props: {
          _id: addDealState.data.message._id,
          type: "deal"
        }
      });
    }
  }, [addDealState, createOrFocusTab, dispatch, requestId]);

  const columns: Column<DealData>[] = [
    {
      getData: (entry): CellValue =>
        [
          entry.data?.applicant
            ? `${entry.data?.applicant?.data?.info?.firstName ?? ""} ${
                entry.data?.applicant?.data?.info?.lastName ?? ""
              }`
            : undefined,
          entry.data?.coApplicant
            ? `${entry.data?.coApplicant?.data?.info?.firstName ?? ""} ${
                entry.data?.coApplicant?.data?.info?.lastName ?? ""
              }`
            : undefined
        ]
          .filter((x) => x)
          .join(" / "),
      label: "Customer",
      name: "customer",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.customer?.show;
      },
      filters: [
        {
          path: [
            ["data", "applicant", "data", "info", "firstName"],
            ["data", "applicant", "data", "info", "lastName"],
            ["data", "coApplicant", "data", "info", "firstName"],
            ["data", "coApplicant", "data", "info", "lastName"]
          ],
          preview: ApplicantFilter
        }
      ]
    },
    {
      getData: (entry): CellValue => entry.data?.info?.refNumber,
      label: "Ref #",
      options: {
        sort: true,
        path: ["data", "info", "refNumber"]
      },
      name: "refNumber",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.refNumber?.show;
      },
      filters: [
        {
          path: ["data", "info", "refNumber"],
          preview: TextFilter
        }
      ]
    },
    {
      getData: (entry): CellValue => entry.data?.dealership?.data?.info?.displayName ?? "",
      label: "Dealership",
      name: "dealership",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.dealership?.show;
      },
      filters: [
        {
          path: ["data", "dealershipId"],
          preview: DealershipFilter
        }
      ]
    },
    {
      getData: (entry): CellValue => entry.data?.info?.vehicle?.make,
      label: "Make",
      name: "make",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.make?.show;
      },
      filters: [
        {
          path: ["data", "info", "vehicle", "make"],
          preview: TextFilter
        }
      ]
    },
    {
      getData: (entry): CellValue => entry.data?.info?.vehicle?.model,
      label: "Model",
      name: "model",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.model?.show;
      },
      filters: [
        {
          path: ["data", "info", "vehicle", "model"],
          preview: TextFilter
        }
      ]
    },
    {
      getData: (entry): CellValue => entry.data?.info?.vehicle?.VIN,
      label: "VIN",
      name: "vin",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.vin?.show;
      },
      filters: [
        {
          path: ["data", "info", "vehicle", "VIN"],
          preview: TextFilter
        }
      ]
    },
    {
      getData: (entry): CellValue => entry.data?.lender?.data?.info?.name,
      label: "Lender",
      name: "lender",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.lender?.show;
      },
      filters: [
        {
          path: ["data", "lenderId"],
          preview: LenderFilter
        }
      ]
    },
    {
      getData: (entry): CellValue => entry.data?.info?.status,
      label: "Status",
      options: {
        customBodyRender: (x: CellValue, deal): JSX.Element | string => {
          const dealStatus = x as DealStatus;
          return statusToChipTooltip(dealStatus, deal, getStyleByStatus(dealStatus));
        },
        sort: true,
        path: ["data", "info", "status"]
      },
      name: "status",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.status?.show;
      },
      filters: [
        {
          path: ["data", "info", "status"],
          preview: MultiSelectFilter,
          valuesForSelect: Object.values(DealStatus),
          optionLabelForSelect: (status) => statusToLabel(status)
        }
      ]
    },
    {
      getData: (entry): CellValue =>
        formatNumberAsCurrency(entry.data?.info?.payment?.dealTotal ?? 0),
      label: "Amount Financed",
      name: "amountFinanced",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.amountFinanced?.show;
      },
      filters: [
        {
          path: ["data", "info", "payment", "dealTotal"],
          preview: PriceFilter,
          name: "amountFinanced",
          label: "Amount Financed"
        }
      ]
    },
    {
      getData: (entry): CellValue =>
        entry.data?.info?.statusReasons?.applicant?.marked ? "Yes" : "No",
      label: "Applicant marked the deal?",
      truncate: 40,
      options: {
        customBodyRender: (x: CellValue, deal): JSX.Element | string => {
          return reasonsTooltip("applicant", deal);
        },
        sort: true,
        path: ["data", "info", "statusReasons", "applicant", "marked"]
      },
      name: "applicantMarked",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.applicantMarked?.show;
      },
      filters: [
        {
          path: ["data", "info", "statusReasons", "applicant", "marked"],
          preview: MultiSelectFilter,
          valuesForSelect: [true, false],
          optionLabelForSelect: (marked) => (marked ? "Yes" : "No")
        }
      ]
    },
    {
      getData: (entry): CellValue =>
        entry.data?.info?.statusReasons?.dealership?.marked ? "Yes" : "No",
      label: "Dealership marked the deal?",
      truncate: 40,
      options: {
        customBodyRender: (x: CellValue, deal): JSX.Element | string => {
          return reasonsTooltip("dealership", deal);
        },
        sort: true,
        path: ["data", "info", "statusReasons", "dealership", "marked"]
      },
      name: "dealershipMarked",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.dealershipMarked?.show;
      },
      filters: [
        {
          path: ["data", "info", "statusReasons", "dealership", "marked"],
          preview: MultiSelectFilter,
          valuesForSelect: [true, false],
          optionLabelForSelect: (marked) => (marked ? "Yes" : "No")
        }
      ]
    },
    {
      getData: (entry): CellValue => entry._id,
      label: "Manager reasons",
      options: {
        customBodyRender: (user: CellValue, deal): JSX.Element | string => {
          return (
            <div style={{ minWidth: "200px" }}>
              <AddStatusReason deal={deal} />
            </div>
          );
        }
      },
      name: "managerReasons",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.managerReasons?.show;
      },
      filters: [
        {
          path: ["data", "info", "statusReasons", "manager", "reason"],
          preview: MultiSelectFilter,
          valuesForSelect: [...Object.values(ReasonsDead), ...Object.values(ReasonsDidNotProcess)]
        }
      ]
    },
    {
      getData: (entry): CellValue => entry._id,
      label: "Actions",
      name: "actions",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.actions?.show;
      }
    }
  ];

  const user = useSelector((state: RootState) => state?.authSlice?.user?.databaseData);

  const lockData = useSelector((state: RootState) => state.listLockSlice["all"]);
  useEffect(() => {
    if (lockData?.entities === undefined) dispatch(getLockList("all"));
  }, [dispatch, lockData]);
  const [weekAgo] = useState(new Date(moveDate(new Date(), -7).setHours(23, 59, 59, 59)));
  const [dayAgo] = useState(new Date(moveDate(new Date(), -1).setHours(23, 59, 59, 59)));
  const isUserAdministartor = user?.data?.roles?.find(
    (role) => role?.data?.info?.name === "Administrator"
  );
  return (
    <div style={{ position: "relative" }}>
      <AccessControl requiredPermissions={{ entity: "deal", action: "read" }}>
        <Table
          tableName="status_reasons"
          entityName="deal"
          slice={slice}
          listFunction={getDealList}
          listEntity={dealList}
          deleteEntityFunction={deleteDeal}
          hideAddButton
          recoverEntityFunction={recoverDeal}
          sort={{ createdAt: "desc" }}
          title={"Deals for status reasons"}
          query={{
            ...(isUserAdministartor ? {} : { "data.userId": user?._id }),
            "data.info.statusReasons.manager.marked": { $ne: true },
            "data.info.statusReasons.manager.reason": { $ne: false },
            $or: [
              {
                "data.info.lastStatusHistory.status": {
                  $in: [DealStatus.Approved, DealStatus.Countered, DealStatus.SentToDealer]
                },
                "data.info.lastStatusHistory.date": {
                  $lte: weekAgo.toISOString()
                },
                "data.info.status": {
                  $in: [DealStatus.Approved, DealStatus.Countered, DealStatus.SentToDealer]
                }
              },
              {
                "data.info.lastStatusHistory.status": {
                  $in: [DealStatus.Dead, DealStatus.DidNotProcess]
                },
                "data.info.lastStatusHistory.date": {
                  $lte: dayAgo.toISOString()
                },
                "data.info.status": { $in: [DealStatus.Dead, DealStatus.DidNotProcess] }
              }
            ]
          }}
          columns={columns}
          projection={projections}
        />
      </AccessControl>
    </div>
  );
}
