import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";

interface RecoverStipulationRequest {
  _id: string;
}

export interface RecoverStipulationResponse {
  message: string;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  RecoverStipulationRequest,
  RecoverStipulationResponse
>("RecoverStipulation", {
  request: "recover_stipulation"
});

export const recoverStipulationActions = socketMessageSlice.actions;
export const recoverStipulation = socketAction;
export default socketMessageSlice.reducer;
