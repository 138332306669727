import React from "react";
import { createOptionsForSelect, Model } from "../../../utils/models/fields";

import DealershipProgramPreview from "components/DealershipPrograms/DealershipProgramPreview";
import { DealershipBills } from "./types";

export function toMonthName(monthNumber: number) {
  const date = new Date(2000, monthNumber - 1, 1); // Create a date object with the desired month

  return date.toLocaleString("en-US", {
    month: "long"
  });
}
export const dealershipBillsStruct: Model<DealershipBills> = {
  formComponent: "model",
  schema: "new_dealership_bills_report",
  name: "dealership_bills_report",
  entities: [
    {
      formComponent: "segment",
      width: "full",
      name: "Report data",
      entities: [
        {
          formComponent: "select-field",
          path: ["data", "year"],
          width: "1/3",
          name: "year",
          label: "Year",
          options: createOptionsForSelect({
            possibleValues: () => {
              const date = new Date().getFullYear();
              return [date - 3, date - 2, date - 1, date, date + 1, date + 2, date + 3];
            },
            getOptionLabel: (x) => x?.toString(),
            getSelectedOption: (x, y) => x === y
          }),
          valueToString: (x) => x,
          default:
            new Date().getMonth() === 0 ? new Date().getFullYear() - 1 : new Date().getFullYear()
        },
        {
          formComponent: "select-field",
          path: ["data", "month"],
          width: "1/3",
          name: "month",
          label: "Month",
          options: createOptionsForSelect({
            possibleValues: () => [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
            getOptionLabel: (x) => toMonthName(x),
            getSelectedOption: (x, y) => x === y
          }),
          default: new Date().getMonth() === 0 ? 12 : new Date().getMonth(),
          valueToString: (x) => x
        },
        {
          formComponent: "one-to-many-field",
          component: (stateAccess, _mainStateAccess, renderSet): JSX.Element => (
            <DealershipProgramPreview
              stateAccess={stateAccess}
              path={["data", "dealershipProgram"]}
              renderSet={renderSet}
              name={"program"}
              label={"Program"}
              required={true}
            />
          ),
          name: "Dealership program preview",
          width: "1/3",
          path: ["data", "dealershipProgram"]
        }
      ]
    }
  ]
};
