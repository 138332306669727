import { CrudEntity, EntityTypes } from "utils/types";

export type Notification = CrudEntity & {
  data: NotificationData;
};

export interface NotificationToSend {
  data: NotificationData;
  _id: string;
}
export enum NotificationType {
  MobileApplication = "mobile_application",
  DealershipApplication = "dealership_application",
  ColumnGroup = "column_group",
  ExternalCreditApplication = "external_credit_application",
  Contract = "contract",
  OneSpan = "one_span",
  Application = "application",
  LenderTicket = "lender_ticket",
  DealershipTicket = "dealership_ticket",
  DEAL_COAPPLICANT = "deal_co_applicant",
  Deal = "deal",
  Note = "note",
  Dealership = "dealership",
  TitleIssue = "title_issue"
}
export interface ContractNotificationInfo {
  type: NotificationType.Contract;
  body: {
    contract: string;
    dealership?: string;
    lender?: string;
  };
}
export interface ApplicationInfo {
  type: NotificationType.Application;
  body: {
    description?: string;
    customer?: string;
    dealership?: string;
  };
}
export interface DealershipInfo {
  type: NotificationType.Dealership;
  body: {
    description?: string;
  };
}
export interface TicketInfo {
  type: NotificationType.LenderTicket | NotificationType.DealershipTicket;
  body: {
    description?: string;
    dealershipOrLenderName?: string;
  };
}

export interface OneSpanNotificationInfo {
  type: NotificationType.OneSpan;
  body?: {
    description?: string;
  };
}

export interface NoteInfo {
  type: NotificationType.Note;
  body?: {
    description?: string;
  };
}
export interface ExternalCreditApplicationNotificationInfo {
  type: NotificationType.ExternalCreditApplication;
  body: {
    customer: string;
    dealership?: string;
  };
}
export interface DealNotificationInfo {
  type: NotificationType.Deal;
  body: {
    customer: string;
    dealership?: string;
  };
}

export interface MobileApplicationInfo {
  type: NotificationType.MobileApplication;
  body: {
    description?: string;
    customer?: string;
    dealership?: string;
  };
}
export interface DealershipApplicationInfo {
  type: NotificationType.DealershipApplication;
  body: {
    description?: string;
    customer?: string;
    dealership?: string;
  };
}
export interface DealCoApplicantNotificationInfo {
  type: NotificationType.DEAL_COAPPLICANT;
}
export interface TitleIssueNotificationInfo {
  type: NotificationType.TitleIssue;
}
export interface ColumnGroupNotificationInfo {
  type: NotificationType.ColumnGroup;
}
export interface DefaultInfo {
  level: number;
  userId: string;
  referenceId: string;
  title: string;
  seenAt?: string;
}
export type Info =
  | (DefaultInfo & ContractNotificationInfo)
  | (DefaultInfo & ExternalCreditApplicationNotificationInfo)
  | (DefaultInfo & MobileApplicationInfo)
  | (DefaultInfo & ApplicationInfo)
  | (DefaultInfo & TicketInfo)
  | (DefaultInfo & OneSpanNotificationInfo)
  | (DefaultInfo & DealCoApplicantNotificationInfo)
  | (DefaultInfo & TitleIssueNotificationInfo)
  | (DefaultInfo & DealershipApplicationInfo)
  | (DefaultInfo & ColumnGroupNotificationInfo)
  | (DefaultInfo & DealershipInfo)
  | (DefaultInfo & DealNotificationInfo)
  | (DefaultInfo & NoteInfo);

export interface NotificationData {
  info: Info;
}
export interface NotificationResponse {
  message: {
    _id: string;
    data: Notification;
    createdAt: string;
  };
}
