import { RootState } from "app/rootReducer";
import { ASCInsurance } from "components/ASCWarranty/types";
import ApplicantFilter from "components/Filters/ApplicantFilter";
import DateFilter from "components/Filters/DateFilter";
import DealershipFilter from "components/Filters/DealershipFilter";
import PriceFilter from "components/Filters/PriceFilter";
import TextFilter from "components/Filters/TextFilter";
import { ProtectiveInsurance } from "components/Protective/types";
import React from "react";
import { useSelector } from "react-redux";
import { sumNumbers } from "utils/calcReportTotal";
import AccessControl from "../../Access/AccessControl";
import { deleteDeal } from "../../Deals/deleteDealSlice";
import { getDealList } from "../../Deals/listDealSlice";
import { recoverDeal } from "../../Deals/recoverDealSlice";
import {
  CustomInsurance,
  DealData,
  ExpressInsurance,
  Insurances,
  InsuranceType
} from "../../Deals/types";
import Table, { CellValue, Column } from "../../Table";

const insuranceData = (
  insurances: Insurances,
  insuranceType: InsuranceType,
  insuranceField: "remitPrice" | "profit" | "dealerCostPrice" | "retailPrice"
): number | undefined => {
  return insurances?.reduce(
    (acc, insurance: ProtectiveInsurance | ExpressInsurance | ASCInsurance | CustomInsurance) =>
      insurance.chosenRate.insuranceType === insuranceType
        ? acc + insurance.chosenRate[insuranceField]
        : acc,
    0
  );
};
export const projections = {
  "data.applicant.data.info.firstName": 1,
  "data.applicant.data.info.lastName": 1,
  "data.info.status": 1,
  "data.info.refNumber": 1,
  "data.dealership.data.info.displayName": 1,
  "data.dealership.data.representative.data.info": 1,
  "data.info.vehicle": 1,
  "data.lender.data.info.name": 1,
  "data.payoffBank.data.info.name": 1,
  "data.info.profit": 1,
  "data.user.data.info": 1,
  "data.info.aftermarketOptions.insurances": 1,
  "data.info.taxesAndFees": 1,
  "data.info.payment": 1,
  "data.info.dealDates": 1,
  "data.info.price": 1,
  _id: 1,
  createdAt: 1,
  creatorId: 1,
  updatedAt: 1,
  deleted: 1
};
export default function (): JSX.Element {
  const dealList = useSelector((state: RootState) => state.listDealSlice["table"]);

  const columns: Column<DealData>[] = [
    {
      getData: (entry): CellValue =>
        `${entry.data?.applicant?.data?.info?.firstName ?? ""} ${
          entry.data?.applicant?.data?.info?.lastName ?? ""
        }`,
      label: "Applicant",
      total: () => "Total:",
      name: "applicant",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.applicant?.show;
      },
      filters: [
        {
          path: [
            ["data", "applicant", "data", "info", "firstName"],
            ["data", "applicant", "data", "info", "lastName"]
          ],
          preview: ApplicantFilter,
          caseInsensitive: true,
          label: "Applicant",
          name: "applicant"
        }
      ]
    },
    {
      getData: (entry): CellValue => entry.data?.info?.vehicle?.make,
      label: "Vehicle",
      name: "vehicle",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.vehicle?.show;
      },
      filters: [
        {
          path: ["data", "info", "vehicle", "make"],
          preview: TextFilter,
          name: "vehicleMake",
          label: "Vehicle make"
        }
      ]
    },
    {
      getData: (entry): CellValue => entry.data?.info?.vehicle?.VIN,
      label: "VIN",
      name: "vin",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.vin?.show;
      },
      filters: [
        {
          path: ["data", "info", "vehicle", "VIN"],
          preview: TextFilter,
          name: "vehicleVIN",
          label: "Vehicle VIN"
        }
      ]
    },
    {
      getData: (entry): CellValue => entry.data?.dealership?.data?.info?.displayName ?? "",
      label: "Dealer",
      options: {
        filter: true
      },
      name: "dealership",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.dealership?.show;
      },
      filters: [
        {
          path: ["data", "dealershipId"],
          preview: DealershipFilter,
          name: "dealership",
          label: "Dealership"
        }
      ]
    },
    {
      getData: (entry): CellValue => entry.data?.info?.price?.price ?? 0,
      label: "Price",
      total: sumNumbers,
      name: "price",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.price?.show;
      },
      filters: [
        {
          path: ["data", "info", "price", "price"],
          preview: PriceFilter,
          name: "price",
          label: "Price"
        }
      ]
    },
    {
      getData: (entry): CellValue => entry.data?.info?.price?.options ?? 0,
      label: "Options",
      name: "options",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.options?.show;
      },
      filters: [
        {
          path: ["data", "info", "price", "options"],
          preview: PriceFilter,
          name: "options",
          label: "Options"
        }
      ]
    },
    {
      getData: (entry): CellValue => entry.data?.info?.price?.rebates ?? 0,
      label: "Rebates",
      total: sumNumbers,
      name: "rebates",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.rebates?.show;
      },
      filters: [
        {
          path: ["data", "info", "price", "rebates"],
          preview: PriceFilter,
          name: "rebates",
          label: "Rebates"
        }
      ]
    },

    {
      getData: (entry): CellValue => entry.data?.info?.price?.totalCash ?? 0,
      label: "Total cash",
      total: sumNumbers,
      name: "totalCash",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.totalCash?.show;
      },
      filters: [
        {
          path: ["data", "info", "price", "totalCash"],
          preview: PriceFilter,
          name: "totalCash",
          label: "Total Cash"
        }
      ]
    },
    {
      getData: (entry): CellValue => entry.data?.info?.price?.totalTrade ?? 0,
      label: "Trade",
      total: sumNumbers,
      name: "trade",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.trade?.show;
      },
      filters: [
        {
          path: ["data", "info", "price", "trade", "value"],
          preview: PriceFilter,
          name: "tradeValue",
          label: "Trade-in value"
        }
      ]
    },
    {
      getData: (entry): CellValue => entry.data?.info?.price?.payoff ?? 0,
      label: "Payoff",
      total: sumNumbers,
      name: "payoff",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.payoff?.show;
      },
      filters: [
        {
          path: ["data", "info", "price", "payoff"],
          preview: PriceFilter,
          name: "payoff",
          label: "Payoff"
        }
      ]
    },
    {
      getData: (entry): CellValue => entry.data?.payoffBank?.data?.info?.name ?? "",
      label: "Payoff bank",
      name: "payoffBank",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.payoffBank?.show;
      },
      filters: [
        {
          path: ["data", "payoffBank", "data", "info", "name"],
          preview: TextFilter,
          name: "payoffBank",
          label: "Payoff bank",
          caseInsensitive: true,
          partialSearch: true
        }
      ]
    },
    {
      getData: (entry): CellValue => entry.data?.info?.price?.netTrade ?? 0,
      label: "Net trade",
      total: sumNumbers,
      name: "netTrade",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.netTrade?.show;
      },
      filters: [
        {
          path: ["data", "info", "price", "netTrade"],
          preview: PriceFilter,
          name: "netTrade",
          label: "Net trade"
        }
      ]
    },
    {
      getData: (entry): CellValue => entry.data?.info?.payment?.fundedAmount ?? 0,
      label: "Funded amount",
      total: sumNumbers,
      name: "fundedAmount",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.fundedAmount?.show;
      },
      filters: [
        {
          path: ["data", "info", "payment", "fundedAmount"],
          preview: PriceFilter,
          name: "fundedAmount",
          label: "Funded amount"
        }
      ]
    },
    {
      getData: (entry): CellValue =>
        entry.data?.info?.dealDates?.fundedAt
          ? new Date(entry.data?.info?.dealDates?.fundedAt)
          : "",
      label: "Funded at",
      options: {
        sort: true,
        path: ["data", "info", "dealDates", "fundedAt"]
      },
      name: "fundedAt",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.fundedAt?.show;
      },
      filters: [
        {
          path: ["data", "info", "dealDates", "fundedAt"],
          preview: DateFilter,
          name: "fundedAt",
          label: "Funded at"
        }
      ]
    },
    {
      getData: (entry): CellValue =>
        insuranceData(entry.data.info.aftermarketOptions?.insurances, "gap", "profit") ?? 0,
      label: "Gap Profit",
      total: sumNumbers,
      name: "gapProfit",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.gapProfit?.show;
      }
      //NO FIELD IN DB REPRESENTING SELL PRICE
      // filters: [
      //   {
      //     path: ["data", "info", "aftermarketOptions", "insurances", "totalGAPSellPrice"],
      //     preview: PriceFilter,
      //     name: "gapProfit",
      //     label: "Gap Profit"
      //   }
      // ]
    },
    {
      getData: (entry): CellValue =>
        insuranceData(entry.data.info.aftermarketOptions?.insurances, "gap", "remitPrice") ?? "",
      label: "Gap Remit Price",
      total: sumNumbers,
      name: "gapRemitPrice",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.gapRemitPrice?.show;
      },
      filters: [
        {
          path: ["data", "info", "aftermarketOptions", "insurances", "totalGAPRemitPrice"],
          preview: PriceFilter,
          name: "gapRemit",
          label: "Gap Remit"
        }
      ]
    },
    {
      getData: (entry): CellValue =>
        insuranceData(entry.data.info.aftermarketOptions?.insurances, "gap", "retailPrice") ?? "",
      label: "Gap Retail Price",
      total: sumNumbers,
      name: "gapRemitPrice",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.gapRemitPrice?.show;
      },
      filters: [
        {
          path: ["data", "info", "aftermarketOptions", "insurances", "totalGAPSellPrice"],
          preview: PriceFilter,
          name: "retailPrice",
          label: "Gap Retail"
        }
      ]
    },
    {
      getData: (entry): CellValue =>
        insuranceData(entry.data.info.aftermarketOptions?.insurances, "gap", "dealerCostPrice") ??
        0,
      label: "Gap Dealer Cost Price",
      total: sumNumbers,
      name: "gapDealerCostPrice",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.gapDealerCostPrice?.show;
      },
      filters: [
        {
          path: ["data", "info", "aftermarketOptions", "insurances", "totalGAPDealerCostPrice"],
          preview: PriceFilter,
          name: "dealerCostPrice",
          label: "Gap Dealer Cost"
        }
      ]
    },

    {
      getData: (entry): CellValue =>
        insuranceData(
          entry.data.info.aftermarketOptions?.insurances,
          "service warranty",
          "profit"
        ) ?? 0,
      label: "Service Warranty Profit",
      total: sumNumbers,
      name: "serviceWarrantyProfit",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.serviceWarrantyProfit?.show;
      }
      //NO FIELD IN DB
    },
    {
      getData: (entry): CellValue =>
        insuranceData(
          entry.data.info.aftermarketOptions?.insurances,
          "service warranty",
          "remitPrice"
        ) ?? 0,
      label: "Service Warranty Remit Price",
      total: sumNumbers,
      name: "serviceWarrantyRemitPrice",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.serviceWarrantyRemitPrice?.show;
      },
      filters: [
        {
          path: [
            "data",
            "info",
            "aftermarketOptions",
            "insurances",
            "totalServiceWarrantyRemitPrice"
          ],
          preview: PriceFilter,
          name: "serviceWarrantyRemitPrice",
          label: "Service Warranty Remit Price"
        }
      ]
    },
    {
      getData: (entry): CellValue =>
        insuranceData(
          entry.data.info.aftermarketOptions?.insurances,
          "service warranty",
          "retailPrice"
        ) ?? 0,
      label: "Service Warranty Retail Price",
      total: sumNumbers,
      name: "serviceWarrantyRetailPrice",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.serviceWarrantyRetailPrice?.show;
      },
      filters: [
        {
          path: [
            "data",
            "info",
            "aftermarketOptions",
            "insurances",
            "totalServiceWarrantySellPrice"
          ],
          preview: PriceFilter,
          name: "serviceWarrantyRetailPrice",
          label: "Service Warranty Retail Price"
        }
      ]
    },
    {
      getData: (entry): CellValue =>
        insuranceData(
          entry.data.info.aftermarketOptions?.insurances,
          "service warranty",
          "dealerCostPrice"
        ) ?? 0,
      label: "Service Warranty  Dealer Cost Price",
      total: sumNumbers,
      name: "serviceWarrantyDealerCostPrice",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.serviceWarrantyDealerCostPrice?.show;
      },
      filters: [
        {
          path: [
            "data",
            "info",
            "aftermarketOptions",
            "insurances",
            "totalServiceWarrantyDealerCostPrice"
          ],
          preview: PriceFilter,
          name: "serviceWarrantyDealerCostPrice",
          label: "Service Warranty Dealer Cost Price"
        }
      ]
    },

    {
      getData: (entry): CellValue => entry.data?.info?.taxesAndFees?.totalUCCFees ?? 0,
      label: "Total UCC fees",
      total: sumNumbers,
      name: "totalUCCFees",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.totalUCCFees?.show;
      },
      filters: [
        {
          path: ["data", "info", "taxesAndFees", "totalUCCFees"],
          preview: PriceFilter,
          name: "totalUCCFees",
          label: "Total UCC fees"
        }
      ]
    },
    {
      getData: (entry): CellValue => entry.data?.info?.taxesAndFees?.totalMembershipFees ?? 0,
      label: "Total Membership fees",
      total: sumNumbers,
      name: "totalMembershipFees",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.totalMembershipFees?.show;
      },
      filters: [
        {
          path: ["data", "info", "taxesAndFees", "totalMembershipFees"],
          preview: PriceFilter,
          name: "totalMembershipFees",
          label: "Total Membership fees"
        }
      ]
    },

    {
      getData: (entry): CellValue => entry.data?.info?.payment?.dealershipSplit ?? 0,
      label: "Dealership split",
      name: "dealershipSplit",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.dealershipSplit?.show;
      },
      filters: [
        {
          path: ["data", "info", "payment", "dealershipSplit"],
          preview: PriceFilter,
          name: "dealershipSplit",
          label: "Dealership split"
        }
      ]
    },
    {
      getData: (entry): CellValue => entry.data?.info?.payment?.reserve ?? 0,
      label: "Reserve",
      total: sumNumbers,
      name: "reserve",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.reserve?.show;
      },
      filters: [
        {
          path: ["data", "info", "payment", "reserve"],
          preview: PriceFilter,
          name: "reserve",
          label: "Reserve"
        }
      ]
    },
    {
      getData: (entry): CellValue =>
        entry.data?.info?.profit?.dealershipProfit?.reserveCommission ?? 0,
      label: "Reserve commission",
      total: sumNumbers,
      name: "reserveCommission",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.reserveCommission?.show;
      },
      filters: [
        {
          path: ["data", "info", "profit", "dealershipProfit", "reserveCommission"],
          preview: PriceFilter,
          name: "reserveCommission",
          label: "Reserve commission"
        }
      ]
    },
    {
      getData: (entry): CellValue => entry._id,
      label: "Actions",
      name: "actions",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.actions?.show;
      }
    }
  ];

  return (
    <div style={{ position: "relative" }}>
      <AccessControl requiredPermissions={{ entity: "deal", action: "read" }}>
        <Table
          slice="table"
          tableName="deals_recap"
          entityName="deal"
          listFunction={getDealList}
          listEntity={dealList}
          deleteEntityFunction={deleteDeal}
          recoverEntityFunction={recoverDeal}
          sort={{ createdAt: "asc" }}
          title={"Recap sheet"}
          columns={columns}
          isReport={true}
          showTotalFooter={true}
          projection={projections}
        />
      </AccessControl>
    </div>
  );
}
