import { FormControl, Grid, InputLabel, MenuItem, Paper, Select } from "@material-ui/core";
import { RootState } from "app/rootReducer";
import Deals from "components/Deals/Deals";
import { getDealList } from "components/Deals/listDealSlice";
import Spinner from "components/Loader/Spinner";
import { useStickyState } from "index";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dealership } from "../types";
import DailyProfit from "./DailyProfit";
import styles from "./DealershipShowPage.module.css";
import MonthlyTrends from "./MonthlyTrends";
import PerformanceStatus from "./PerformaceStatus";
import Statistics from "./Statistics";
import TiersChart from "./TiersChart";

export default function Charts({ dealership }: { dealership: Dealership }) {
  const [type, setType] = useStickyState<"monthly" | "yearly">(
    "monthly",
    `dealerShowPageTypeFilter:${dealership._id}`
  );
  const [year, setYear] = useStickyState(
    new Date().getFullYear(),
    `dealerShowPageYearFilter:${dealership._id}`
  );
  const [month, setMonth] = useStickyState(
    new Date().getMonth() + 1,
    `dealerShowPageMonthFilter:${dealership._id}`
  );
  const dealsSlice = `dealershipShowPageDeals:${dealership._id}`;
  const dealList = useSelector((state: RootState) => state.listDealSlice[dealsSlice]);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      getDealList(dealsSlice, {
        options: {
          limit: 10000000,
          projection: {
            "data.info.status": 1,
            "data.info.extractedCreditScores": 1,
            "data.info.aftermarketOptions": 1,
            "data.info.profit": 1,
            "data.info.dealDates.contractDate ": 1,
            createdAt: 1
          }
        },

        query: {
          "data.dealershipId": dealership._id,
          $or:
            type === "monthly"
              ? [
                  {
                    "data.info.dealDates.contractDate": {
                      $gte: new Date(year, month - 1, 1, 0, 0, 0, 0).toISOString(),
                      $lt: new Date(year, month, 1, 0, 0, 0, 0).toISOString()
                    }
                  },
                  {
                    createdAt: {
                      $gte: new Date(year, month - 1, 1, 0, 0, 0, 0).toISOString(),
                      $lt: new Date(year, month, 1, 0, 0, 0, 0).toISOString()
                    }
                  }
                ]
              : [
                  {
                    "data.info.dealDates.contractDate": {
                      $gte: new Date(year, 0, 1, 0, 0, 0, 0).toISOString(),
                      $lt: new Date(year + 1, 0, 1, 0, 0, 0, 0).toISOString()
                    }
                  },
                  {
                    createdAt: {
                      $gte: new Date(year, 0, 1, 0, 0, 0, 0).toISOString(),
                      $lt: new Date(year + 1, 0, 1, 0, 0, 0, 0).toISOString()
                    }
                  }
                ]
        }
      })
    );
  }, [type, year, month]);
  const deals = dealList?.entities?.filter((x) => x.data?.info) || [];
  if (dealList?.status === "waiting")
    return (
      <Spinner
        text="Loading..."
        style={{ position: "relative", zIndex: 5, height: 300, lineHeight: "300px" }}
      />
    );
  return (
    <>
      <Grid xs={12}>
        <Paper elevation={2} style={{ padding: "10px", backgroundColor: "#f5f5f5" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center"
            }}
          >
            <div className={styles.title}>Filters</div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                gap: "20px"
              }}
            >
              <div style={{ width: "150px" }}>
                <FormControl variant="filled" size="small" style={{ width: "100%" }}>
                  <InputLabel> Type:</InputLabel>
                  <Select
                    value={type}
                    onChange={(v) => {
                      setType(v.target.value as "monthly" | "yearly");
                    }}
                  >
                    <MenuItem value={"monthly"}>Monthly</MenuItem>
                    <MenuItem value={"yearly"}>Yearly</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div style={{ width: "150px" }}>
                <FormControl variant="filled" size="small" style={{ width: "100%" }}>
                  <InputLabel> Year:</InputLabel>
                  <Select
                    value={year}
                    onChange={(e) => {
                      setYear(parseInt(e.target.value as string));
                    }}
                  >
                    {Array.from({ length: 10 }, (_, i) => (
                      <MenuItem key={i} value={new Date().getFullYear() - i}>
                        {new Date().getFullYear() - i}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              {type === "monthly" && (
                <div style={{ width: "150px" }}>
                  <FormControl variant="filled" size="small" style={{ width: "100%" }}>
                    <InputLabel> Month:</InputLabel>
                    <Select
                      value={month}
                      onChange={(e) => {
                        setMonth(parseInt(e.target.value as string));
                      }}
                    >
                      {Array.from({ length: 12 }, (_, i) => (
                        <MenuItem key={i} value={i + 1}>
                          {new Date(year, i, 1).toLocaleString("default", {
                            month: "long"
                          })}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              )}
            </div>
          </div>
        </Paper>
      </Grid>
      <Grid
        container
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "stretch"
        }}
      >
        <Grid md={6} xs={12}>
          <Statistics dealership={dealership} year={year} month={month} type={type} deals={deals} />
        </Grid>
        <Grid md={6} xs={12} style={{ height: "100%" }}>
          {type === "monthly" && (
            <PerformanceStatus dealership={dealership} year={year} month={month} deals={deals} />
          )}
          {type === "yearly" && <TiersChart dealership={dealership} year={year} />}
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        direction="row"
        justifyContent="space-between"
        alignItems="stretch"
      >
        {type === "monthly" && <DailyProfit deals={deals} year={year} month={month} />}

        {type === "yearly" && <MonthlyTrends deals={deals} year={year} />}
      </Grid>
      <Grid xs={12}>
        <Deals
          slice={dealsSlice + "table"}
          query={{
            "data.dealershipId": dealership._id,
            $or:
              type === "monthly"
                ? [
                    {
                      "data.info.dealDates.contractDate": {
                        $gte: new Date(year, month - 1, 1, 0, 0, 0, 0).toISOString(),
                        $lt: new Date(year, month, 1, 0, 0, 0, 0).toISOString()
                      }
                    },
                    {
                      createdAt: {
                        $gte: new Date(year, month - 1, 1, 0, 0, 0, 0).toISOString(),
                        $lt: new Date(year, month, 1, 0, 0, 0, 0).toISOString()
                      }
                    }
                  ]
                : [
                    {
                      "data.info.dealDates.contractDate": {
                        $gte: new Date(year, 0, 1, 0, 0, 0, 0).toISOString(),
                        $lt: new Date(year + 1, 0, 1, 0, 0, 0, 0).toISOString()
                      }
                    },
                    {
                      createdAt: {
                        $gte: new Date(year, 0, 1, 0, 0, 0, 0).toISOString(),
                        $lt: new Date(year + 1, 0, 1, 0, 0, 0, 0).toISOString()
                      }
                    }
                  ]
          }}
          title={`${dealership.data.info.name}'s Deals`}
        />
      </Grid>
    </>
  );
}
