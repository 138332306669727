import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";
import { FormState } from "./types";

interface EditDefaultStipulationRequest {
  data: FormState;
}

export interface EditDefaultStipulationResponse {
  message: string;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  EditDefaultStipulationRequest,
  EditDefaultStipulationResponse
>("EditDefaultStipulation", {
  request: "update_default_stipulation"
});

export const editDefaultStipulationActions = socketMessageSlice.actions;
export const editDefaultStipulation = socketAction;
export default socketMessageSlice.reducer;
