import { RootState } from "app/rootReducer";
import DateFilter from "components/Filters/DateFilter";
import TextFilter from "components/Filters/TextFilter";
import { hasPermission } from "components/Roles/permissionCheck";
import React from "react";
import { useSelector } from "react-redux";
import Table, { CellValue, Column } from "../Table";
import { deleteManagerProgram } from "./deleteManagerProgramSlice";
import { getManagerProgramList } from "./listManagerProgramSlice";
import { recoverManagerProgram } from "./recoverManagerProgramSlice";
import { FormState } from "./types";

export const projections = {
  "data.info.name": 1,
  "data.info.tiers": 1,
  "data.info.from": 1,
  "data.info.to": 1,
  approvedByUserId: 1,
  approved: 1,
  approvedByUser: 1,
  _id: 1,
  createdAt: 1,
  creatorId: 1,
  updatedAt: 1,
  deleted: 1
};
export default (): JSX.Element => {
  const managerProgramList = useSelector(
    (state: RootState) => state.listManagerProgramSlice["table"]
  );

  const columns: Column<FormState>[] = [
    {
      label: "Name",
      name: "name",
      getData: (entry): CellValue => entry.data?.info?.name,
      options: {
        sort: true,
        path: ["data", "info", "name"]
      },
      show: (userPermissions) => {
        return (
          userPermissions?.read.hasPermission &&
          hasPermission(userPermissions?.read.dataPermissions, ["data", "info", "name"])
        );
      },
      filters: [
        {
          path: ["data", "info", "name"],
          preview: TextFilter
        }
      ]
    },

    {
      label: "Actions",
      getData: (entry): CellValue => entry._id,
      name: "actions",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.actions?.show;
      }
    }
  ];

  return (
    <div style={{ position: "relative" }}>
      <Table
        slice="table"
        tableName="managerProgram"
        entityName="manager_program"
        listFunction={getManagerProgramList}
        deleteEntityFunction={deleteManagerProgram}
        recoverEntityFunction={recoverManagerProgram}
        sort={{ "data.info.name": "asc" }}
        listEntity={managerProgramList}
        title={"Manager Programs"}
        columns={columns}
        projection={projections}
      />
    </div>
  );
};
