import { Button, DialogActions, DialogContent, Link, Popover } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import { RootState } from "app/rootReducer";
import { XlStyledDialog } from "components/common/StyledDialog";
import formEditContext from "components/Content/FormEditContext";
import { DocumentTemplate } from "components/DocumentTemplates/types";
import DateFilter from "components/Filters/DateFilter";
import TextFilter from "components/Filters/TextFilter";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EntityData, EntityType } from "utils/entitySlice";
import { createOptionsForSelect, Model } from "utils/models/fields";
import {
  fillDefaultsByPath,
  generateDefault,
  generateForm,
  getByPath,
  setByPath,
  StateAccess
} from "utils/models/formGenerator";
import { editRenderSet } from "utils/models/formRenderers";
import { lockEntity } from "utils/models/LockEntity/lockEntitySlice";
import { v4 as uuidv4 } from "uuid";
import DropZone from "../../utils/DropZone";
import AccessControl from "../Access/AccessControl";
import CloseDialogButton from "../common/CloseDialogButton";
import Table, { CellValue, Column } from "../Table";
import { addFundingDocument } from "./addFundingDocumentSlice";
import { deleteFundingDocument } from "./deleteFundingDocumentSlice";
import { editFundingDocument } from "./editFundingDocumentSlice";
import { getFundingDocumentList } from "./listFundingDocumentSlice";
import { FundingDocumentStruct, generateFundingDocumentStruct } from "./model";
import RecognizeDocumentButton from "./RecongnizeDoucmentButton";
import { recoverFundingDocument } from "./recoverFundingDoucmentSlice";
import { FormState, FundingDocument } from "./types";
export const projections = {
  "data.info.name": 1,
  "data.info.url": 1,
  "data.info.description": 1,
  "data.uploadedDocumentStatus": 1,
  "data.info.metadata": 1,
  _id: 1,
  creatorId: 1,
  createdAt: 1,
  updatedAt: 1,
  deleted: 1
};
interface Props<T> {
  type: EntityType;
  struct?: Model<T>;
  types?: DocumentTemplate[] | null | undefined;
  _id: string;
  setFundingDocumentForPreview?: React.Dispatch<React.SetStateAction<FundingDocument | undefined>>;
}

export default function FundingDocumentsPreview({
  _id,
  type,
  struct = FundingDocumentStruct,
  types = null,
  setFundingDocumentForPreview
}: Props<FundingDocument>): JSX.Element {
  const [dropZoneRandomId] = useState(uuidv4());
  const dispatch = useDispatch();
  const sliceId = _id;
  const FundingDocumentList = useSelector(
    (state: RootState) => state.listFundingDocumentSlice[sliceId] || []
  );
  const [openEdit, setOpenEdit] = useState(false);
  const [editFormState, setEditFormState] = useState<FundingDocument | null>(null);

  const snakeToCamel = (str: string): string =>
    str.replace(/([_][a-z])/g, (group) => group.toUpperCase().replace("_", ""));

  const defaultState = generateDefault(struct, {}, fillDefaultsByPath as any) as any;

  const typeColumn: Column<FormState> = {
    getData: (entry): CellValue =>
      (entry.data?.info?.types ?? [])
        .map(
          ({
            data: {
              info: { name }
            }
          }) => name
        )
        .join(", "),
    label: "Type",
    name: "type",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.type?.show;
    },
    filters: [
      {
        path: ["data", "info", "types", "data", "info", "name"],
        preview: TextFilter,
        name: "type",
        label: "Type",
        caseInsensitive: true,
        partialSearch: true
      }
    ]
  };
  const columns: Column<FormState>[] = [
    {
      label: "Document",
      getData: (entry): CellValue => entry.data.info.name,
      options: {
        customBodyRender: (value, entry): JSX.Element | string => (
          <Link
            style={{ cursor: "pointer" }}
            target="_blank"
            draggable={false}
            href={entry.data.info.url}
          >
            {entry.data.info.name}
          </Link>
        )
      },
      name: "name",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.name?.show;
      },
      filters: [
        {
          path: ["data", "info", "name"],
          preview: TextFilter,
          name: "document",
          label: "Document",
          caseInsensitive: true,
          partialSearch: true
        }
      ]
    },
    setFundingDocumentForPreview
      ? {
          getData: (entry): CellValue => entry._id,
          label: "Recognize document",
          name: "Recognize",
          options: {
            customBodyRender: (_: CellValue, fundingDocument): JSX.Element | string => {
              const pagesArray = Object.values(
                fundingDocument?.data?.uploadedDocumentStatus?.pages ?? {}
              );
              // if (pagesArray.length !== 0 && pagesArray.every((page) => page.recognized))
              //   return <></>;
              return (
                <RecognizeDocumentButton
                  setFundingDocumentForPreview={setFundingDocumentForPreview}
                  fundingDocument={fundingDocument}
                />
              );
            }
          }
        }
      : {
          label: "Description",
          getData: (entry): CellValue => entry?.data?.info?.description ?? "",
          name: "description",
          show: (userPermissions, tableSettings) => {
            return tableSettings?.data?.columns?.description?.show;
          },
          filters: [
            {
              path: ["data", "info", "description"],
              preview: TextFilter,
              name: "description",
              label: "Description",
              caseInsensitive: true,
              partialSearch: true
            }
          ]
        },
    ...(types !== null && types !== undefined ? [typeColumn] : []),
    {
      label: "Date Added",
      getData: (entry): CellValue => (entry?.createdAt ? new Date(entry?.createdAt) : ""),
      name: "createdAt",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.createdAt?.show;
      },
      filters: [
        {
          path: ["createdAt"],
          preview: DateFilter,
          name: "createdAt",
          label: "Date Added"
        }
      ]
    },
    {
      label: "Actions",
      getData: (entry): CellValue => entry._id,
      name: "actions",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.actions?.show;
      }
    }
  ];

  const [editRequestId] = useState(uuidv4());
  const addFundingDocumentSlice = useSelector(
    (state: RootState) => state.addFundingDocumentSlice[editRequestId]
  );
  const editFormName = `${type}-FundingDocument-edit`;

  const [editedTrigger, setEditedTrigger] = useState(true);
  const [renderTime, setRenderTime] = useState<undefined | number>();
  const containerRef = useRef<HTMLDivElement>(null);
  const editStateAccess: StateAccess = {
    get: (path) => getByPath(editFormState as any, path),
    set: (path, value): any => setEditFormState(setByPath(editFormState as any, path, value))
  };
  const editHandleSubmit = (state: EntityData<"funding_document">) => (
    event: React.FormEvent
  ): void => {
    event.preventDefault();
    event.stopPropagation();
    setOpenEdit(false);
    const { _id, deleted, createdAt, ...rest } = state;

    dispatch(editFundingDocument({ _id, ...rest, requestId: editRequestId }));
  };
  const editActionState = useSelector(
    (state: RootState) => state.editFundingDocumentSlice[editRequestId]
  );
  const changedEntry = useSelector((state: RootState) => {
    return Object.entries({
      ...state.addFundingDocumentSlice,
      ...state.editFundingDocumentSlice,
      ...state.deleteFundingDocumentSlice,
      ...state.recoverFundingDocumentSlice
    }).some(([, x]) => {
      return (
        x.status === "success" &&
        x?.data?.message?.data?.[`${snakeToCamel(type)}Id` as keyof FundingDocument["data"]] === _id
      );
    });
  });

  useEffect(() => {
    if (editActionState?.status === "success" && editActionState?.data !== null) {
      dispatch(
        lockEntity({
          requestId: uuidv4(),
          data: {
            info: {
              entityId: editActionState?.data.message?._id,
              entityName: "funding_document",
              action: "unlock"
            }
          }
        })
      );
    }
  }, [editActionState, dispatch]);
  useEffect(() => {
    if (addFundingDocumentSlice?.status === "success") {
      setRenderTime(new Date().getTime());
    }
  }, [addFundingDocumentSlice, dispatch]);

  useEffect(() => {
    if (changedEntry) {
      setEditedTrigger((x) => !x);
    }
  }, [changedEntry]);

  const listFunction = useCallback(
    (_, props) => {
      const andQuery = [
        ...[{ [`data.${snakeToCamel(type)}Id`]: _id }],
        ...(Array.isArray(props.query["$and"]) && props.query["$and"].length > 0
          ? props.query["$and"]
          : [])
      ];

      return getFundingDocumentList(sliceId, {
        ...props,
        query:
          andQuery?.length > 0
            ? {
                $and: andQuery
              }
            : {}
      });
    },
    // eslint-disable-next-line
    [_id, sliceId, type, editedTrigger]
  );

  const handleClose = (): void => {
    dispatch(
      lockEntity({
        requestId: uuidv4(),
        data: {
          info: {
            entityId: editFormState?._id ?? "",
            entityName: "funding_document",
            action: "unlock"
          }
        }
      })
    );
    setOpenEdit(false);
  };
  const { enabled: editMode } = useContext(formEditContext);
  return (
    <>
      <AccessControl requiredPermissions={{ entity: "applicant", action: "read" }}>
        <div id="drag-enter-funding-documents" style={{ position: "relative" }} ref={containerRef}>
          {openEdit ? (
            <XlStyledDialog>
              <DialogContent>
                <CloseDialogButton closeFunction={handleClose} />
                <form
                  id={editFormName}
                  autoComplete="off"
                  onSubmit={editHandleSubmit(editFormState as any)}
                >
                  {types !== null
                    ? generateForm(
                        generateFundingDocumentStruct(
                          createOptionsForSelect({
                            possibleValues: () => types,
                            getOptionLabel: ({
                              data: {
                                info: { name }
                              }
                            }) => name
                          })
                        ),
                        editStateAccess,
                        [],
                        editStateAccess,
                        editRenderSet("update_funding_document")
                      )
                    : generateForm(
                        struct,
                        editStateAccess,
                        [],
                        editStateAccess,
                        editRenderSet("update_funding_document")
                      )}
                </form>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary" variant="contained">
                  Cancel
                </Button>

                <Button
                  form={editFormName}
                  type="submit"
                  color="primary"
                  variant="contained"
                  startIcon={<SaveIcon />}
                >
                  Save
                </Button>
              </DialogActions>
            </XlStyledDialog>
          ) : null}
          {editMode && (
            <DropZone
              uniqueId={dropZoneRandomId}
              defaultState={defaultState}
              addFunction={addFundingDocument}
              type={type}
              id={_id}
              containerRef={containerRef}
              requestId={editRequestId}
            />
          )}
          <Table
            slice={sliceId}
            tableName="funding_documents"
            parentId={_id}
            entityName="funding_document"
            listFunction={listFunction}
            listEntity={FundingDocumentList}
            deleteEntityFunction={deleteFundingDocument}
            recoverEntityFunction={recoverFundingDocument}
            title={
              <>
                <div style={{ display: "inline-block", padding: "5px", fontWeight: "bold" }}>
                  Funding documents
                </div>
                <span style={{ paddingLeft: "5px", fontSize: "10px" }}>(Drag file to upload)</span>
              </>
            }
            toolbarStyle={{ minHeight: 0, padding: 0 }}
            hidePagination={true}
            hideSearch={true}
            hidePrintButton={true}
            hideExcelExportButton={true}
            hideFilters={true}
            hideSettings={true}
            hideRecoverButton={true}
            hideAddButton={true}
            openShowEditInModal={true}
            pagination={false}
            columns={columns}
            editAction={(entry): void => {
              dispatch(
                lockEntity({
                  requestId: uuidv4(),
                  data: {
                    info: { entityId: entry._id, entityName: "funding_document", action: "lock" }
                  }
                })
              );
              setEditFormState(entry);
              setOpenEdit(true);
            }}
            projection={projections}
            externalRenderTime={renderTime}
          />
        </div>
      </AccessControl>
    </>
  );
}
