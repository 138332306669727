import { RootState } from "app/rootReducer";
import AccessControl from "components/Access/AccessControl";
import DateFilter from "components/Filters/DateFilter";

import TextFilter from "components/Filters/TextFilter";
import React from "react";
import { useSelector } from "react-redux";
import Table, { CellValue, Column } from "../Table";
import { deleteCreditScoreRange } from "./deleteCreditScoreRangeSlice";
import { getCreditScoreRangesList } from "./listCreditScoreRangeSlice";
import { recoverCreditScoreRange } from "./recoverCreditScoreRangeSlice";
import { FormState } from "./types";
export const projections = {
  "data.info.name": 1,
  "data.info.from": 1,
  "data.info.to": 1,
  _id: 1,
  createdAt: 1,
  creatorId: 1,
  updatedAt: 1,
  deleted: 1
};

export default (): JSX.Element => {
  const rangeList = useSelector((state: RootState) => state.listCreditScoreRangeSlice["table"]);
  const columns: Column<FormState>[] = [
    {
      label: "Name",
      getData: (el): CellValue => el.data.info.name,
      name: "name",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.name?.show;
      },
      filters: [
        {
          path: ["data", "info", "name"],
          preview: TextFilter,
          caseInsensitive: true,
          partialSearch: true,
          label: "Name",
          name: "name"
        }
      ]
    },

    // {
    //   label: "From",
    //   getData: (el): CellValue => el.data.info.from,
    //   name: "From",
    //   show: (userPermissions, tableSettings) => {
    //     return tableSettings?.data?.columns?.from?.show;
    //   },
    //   filters: [
    //     {
    //       path: ["data", "info", "from"],
    //       preview: TextFilter,
    //       caseInsensitive: true,
    //       partialSearch: true,
    //       label: "From",
    //       name: "from"
    //     }
    //   ]
    // },
    // {
    //   label: "To",
    //   getData: (el): CellValue => el.data.info.to,
    //   name: "to",
    //   show: (userPermissions, tableSettings) => {
    //     return tableSettings?.data?.columns?.to?.show;
    //   },
    //   filters: [
    //     {
    //       path: ["data", "info", "to"],
    //       preview: TextFilter,
    //       caseInsensitive: true,
    //       partialSearch: true,
    //       label: "To",
    //       name: "to"
    //     }
    //   ]
    // },
    {
      label: "Date added",
      getData: (el): CellValue => new Date(el.createdAt),
      options: {},
      name: "createdAt",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.createdAt?.show;
      },
      filters: [
        {
          path: ["createdAt"],
          preview: DateFilter,
          label: "Created At",
          name: "createdAt"
        }
      ]
    },
    {
      label: "Actions",
      getData: (el): CellValue => el._id,
      name: "actions",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.actions?.show;
      }
    }
  ];

  return (
    <>
      <AccessControl requiredPermissions={{ entity: "credit_score_range", action: "read" }}>
        <Table
          slice={"table"}
          tableName="Credit Score credit_score_range"
          entityName="credit_score_range"
          listFunction={getCreditScoreRangesList}
          listEntity={rangeList}
          deleteEntityFunction={deleteCreditScoreRange}
          recoverEntityFunction={recoverCreditScoreRange}
          title={"Credit Score Ranges"}
          columns={columns}
          projection={projections}
        />
      </AccessControl>
    </>
  );
};
