import {
  createStyles,
  Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme
} from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import Big from "big.js";
import { HintTooltip } from "components/common/HintTooltip";
import { DealershipProgram, Tier } from "components/DealershipPrograms/types";
import { resolveTier } from "components/Deals/Calculations";
import { formatConditions } from "components/Deals/DealershipProfitPreview";
import { Deal } from "components/Deals/types";
import React from "react";
import { Dealership } from "../types";
import styles from "./DealershipShowPage.module.css";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {},
    cell: {
      color: "black",
      fontSize: "16px"
    },
    greenColor: {
      background: "#254e70",
      color: "#fff"
    },
    grayColor: {
      background: "white"
    }
  })
);
const customProgram: DealershipProgram = {
  _id: "62c54def82bc529272695c21",
  data: {
    info: {
      name: "Custom Program",
      tiers: []
    }
  },
  deleted: false,
  creatorId: "5f17e21d46a2b90e45bb5a91",
  updatedAt: "",
  createdAt: "",
  availableToRolesIds: [],
  availableToRoles: []
};

export const getDealershipProgram = (
  dealership: Dealership,
  year: number,
  month: number
): DealershipProgram | undefined | null => {
  const contractDate = new Date(year, month - 1, 2, 0, 0, 0, 0);
  const foundProgram = dealership?.data?.info?.dealershipPrograms?.find((dealershipProgram) => {
    return (
      contractDate &&
      (!dealershipProgram.endDate ||
        new Date(dealershipProgram.endDate).getTime() >= contractDate.getTime()) &&
      (!dealershipProgram.startDate ||
        new Date(dealershipProgram.startDate).getTime() <= contractDate.getTime())
    );
  })?.dealershipProgram;
  return foundProgram || customProgram;
};

const DealershipTiers = ({
  dealership,
  year,
  month,
  deals
}: {
  dealership: Dealership;
  year: number;
  month: number;
  deals: Deal[];
}) => {
  const fundedDeals = deals?.filter((deal) => deal.data?.info.status === "funded");
  const classes = useStyles();

  const statistics = dealership?.data?.statistics ?? [];
  const dealershipProgram = getDealershipProgram(dealership, year, month);

  const tiers = dealershipProgram?.data?.info?.tiers ?? [];
  const contractDate = new Date(year, month - 1, 1);
  const statisticForTheMonth = statistics.find(
    (statistic) =>
      statistic.month === new Date(contractDate).getMonth() + 1 &&
      statistic.year === new Date(contractDate).getFullYear()
  );

  const foundTierIndex = resolveTier(statisticForTheMonth, tiers, "findIndex");

  const colorClass = "greenColor";

  const totalGrossProfit = fundedDeals?.reduce((acc, deal) => {
    return new Big(acc).add(deal?.data?.info?.profit?.grossProfit || 0);
  }, new Big(0));

  const totalReserveCommission = fundedDeals?.reduce((acc, deal) => {
    return new Big(acc).add(deal?.data?.info?.profit?.wfdProfit?.reserveCommission || 0);
  }, new Big(0));
  const totalGAPProfit = fundedDeals?.reduce((acc, deal) => {
    return new Big(acc).add(deal?.data?.info?.profit?.totalGAPProfit || 0);
  }, new Big(0));
  const totalServiceWarrantyProfit = fundedDeals?.reduce((acc, deal) => {
    return new Big(acc).add(deal?.data?.info?.profit?.totalServiceWarrantyProfit || 0);
  }, new Big(0));

  const formatSplitProfit = (tier: Tier) => {
    return (
      <table style={{ fontSize: "12px" }}>
        <tr>
          <td>Reserve Split:</td>
          <td>
            {new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(
              totalReserveCommission
                .mul(tier?.commissionAmount ?? 0)
                .div(100)
                .toNumber()
            )}
          </td>
        </tr>
        <tr>
          <td>GAP Profit Split:</td>
          <td>
            {new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(
              totalGAPProfit
                .mul(tier?.commissionAmount ?? 0)
                .div(100)
                .toNumber()
            )}
          </td>
        </tr>
        <tr>
          <td>Service Warranty Profit Split:</td>
          <td>
            {new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(
              totalServiceWarrantyProfit
                .mul(tier?.commissionAmount ?? 0)
                .div(100)
                .toNumber()
            )}
          </td>
        </tr>
      </table>
    );
  };

  return dealershipProgram?.data?.info?.name === "Performance Program" ? (
    <>
      <Table className={classes.table} size={"medium"}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.cell} align={"center"}></TableCell>
            {tiers.map((tier, index) => (
              <TableCell
                align={"center"}
                key={index}
                className={foundTierIndex === index ? classes[colorClass] : classes.cell}
              >
                {formatConditions(tier.conditions)}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell className={classes.cell}>Dealer total backend</TableCell>
            {tiers.map((tier, index) => (
              <TableCell
                key={index}
                align={"center"}
                className={foundTierIndex === index ? classes[colorClass] : classes.cell}
              >
                {new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(
                  totalGrossProfit.toNumber()
                )}
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell className={classes.cell}>Dealer Profit %</TableCell>
            {tiers.map((tier, index) => (
              <TableCell
                key={index}
                className={foundTierIndex === index ? classes[colorClass] : classes.cell}
                align={"center"}
              >
                {tier.commissionAmount} {tier.commissionType === "fixed" ? "$" : "%"}
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            <TableCell className={classes.cell}>Total Dealership Profit</TableCell>
            {tiers.map((tier, index) => (
              <TableCell
                key={index}
                className={foundTierIndex === index ? classes[colorClass] : classes.cell}
                align={"center"}
              >
                {new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(
                  tier.commissionType === "fixed"
                    ? tier.commissionAmount
                    : new Big(totalGrossProfit)
                        .mul(tier?.commissionAmount ?? 0)
                        .div(100)
                        .round(2, 0)
                        .toNumber()
                )}
                <HintTooltip title={formatSplitProfit(tier)}>
                  <InfoIcon style={{ color: "#d06c1f" }} />
                </HintTooltip>
              </TableCell>
            ))}
          </TableRow>
        </TableBody>
      </Table>
      <div style={{ fontSize: "12px", marginTop: "5px" }}>
        Dealership progress for {new Date(contractDate).getMonth() + 1}/
        {new Date(contractDate).getFullYear()}: ({statisticForTheMonth?.dealsCount ?? 0})
        transactions
      </div>
    </>
  ) : (
    <div
      style={{
        fontSize: "20px",
        marginTop: "5px",
        height: "100%",
        textAlign: "center",
        margin: "50px 0px"
      }}
    >
      No Performance Program for {new Date(contractDate).getMonth() + 1}/
      {new Date(contractDate).getFullYear()}
    </div>
  );
};

export default ({
  dealership,
  year,
  month,
  deals
}: {
  dealership: Dealership;
  year: number;
  month: number;
  deals: Deal[];
}) => {
  return (
    <Grid xs={12} style={{ height: "100%" }}>
      <Paper
        elevation={2}
        style={{ padding: "10px", margin: "0px 10px", minHeight: "calc(100% - 20px)" }}
      >
        <div className={styles.title}>Performance Status</div>
        <DealershipTiers dealership={dealership} year={year} month={month} deals={deals} />
      </Paper>
    </Grid>
  );
};
