import { Button, Tooltip } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import CheckIcon from "@material-ui/icons/Check";
import ErrorIcon from "@material-ui/icons/Error";
import { RootState } from "app/rootReducer";
import BusinessReportURLFilter from "components/Filters/BusinessReportURLFilter";
import DateFilter from "components/Filters/DateFilter";
import DealershipFilter from "components/Filters/DealershipFilter";
import LenderFilter from "components/Filters/LenderFilter";
import MultiSelectFilter from "components/Filters/MultiSelectFilter";
import TextFilter from "components/Filters/TextFilter";
import UserFilter from "components/Filters/UserFilter";
import React from "react";
import { useSelector } from "react-redux";
import { getUrlExtension } from "utils/functions";
import AccessControl from "../../Access/AccessControl";
import Table, { CellValue, Column } from "../../Table";
import { deleteBussinesReport } from "./deleteBusinessReportSlice";
import { getBusinessReportList } from "./listBusinessReportsSlice";
import PrviewDialog from "./PreviewDialog";
import { recoverBussinesReport } from "./recoverBusinessReportSlice";
import { FormState, TimePeriod } from "./types";

export const projections = {
  "data.info.request": 1,
  "data.info.response": 1,
  "data.status": 1,
  _id: 1,
  creatorId: 1,
  createdAt: 1,
  updatedAt: 1,
  deleted: 1
};
function formatTimePeriod(timePeriod: TimePeriod) {
  switch (timePeriod) {
    case "D":
      return "Daily";
    case "W":
      return "Weekly";
    case "M":
      return "Monthly";
    case "Q":
      return "Quarterly";
    case "Y":
      return "Yearly";
    default:
      return "";
  }
}
export default function BusinessReports(): JSX.Element {
  const businessReportList = useSelector(
    (state: RootState) => state.listBusinessReportSlice["table"]
  );

  const columns: Column<FormState>[] = [
    {
      label: "Type",
      getData: (entry): CellValue => entry?.data?.info?.request?.type,
      name: "type",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.type?.show;
      },
      filters: [
        {
          path: ["data", "info", "request", "type"],
          preview: TextFilter,
          name: "type",
          label: "Type",
          caseInsensitive: true,
          partialSearch: true
        }
      ]
    },
    {
      label: "URL",
      getData: (entry): CellValue => entry?._id,
      options: {
        customBodyRender: (value, entry): JSX.Element | JSX.Element[] | string => {
          return entry?.data?.info?.response?.urls ? (
            entry?.data?.info?.response?.urls?.map((el, index) => {
              const extension = getUrlExtension(el);
              return extension === "html" ? (
                <PrviewDialog url={el} extension={extension} />
              ) : (
                <Button target="_blank" href={el} key={`${index}-${el}`} size="small">
                  {extension === "ppt" ? "Powerpoint" : extension === "xls" ? "Excel" : "File"}
                </Button>
              );
            })
          ) : (
            <></>
          );
        }
      },
      name: "url",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.url?.show;
      },
      filters: [
        {
          path: ["data", "info", "response", "urls"],
          preview: BusinessReportURLFilter
        }
      ]
    },
    {
      label: "Time period",
      getData: (entry): CellValue => {
        const timePeriod = entry?.data?.info?.request?.params?.timePeriod ?? [];

        return Array.isArray(timePeriod)
          ? timePeriod.map((el) => formatTimePeriod(el)).join(", ")
          : "";
      },
      name: "timePeriod",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.timePeriod?.show;
      },
      filters: [
        {
          path: ["data", "info", "request", "params", "timePeriod"],
          preview: MultiSelectFilter,
          name: "timePeriod",
          label: "Time Period",
          valuesForSelect: ["D", "W", "M", "Q", "Y"],
          optionLabelForSelect: (timePeriod) => formatTimePeriod(timePeriod)
        }
      ]
    },
    {
      label: "Date range",
      getData: (entry): CellValue => {
        const dateRange = entry?.data?.info?.request?.params?.dateRange;
        return ` ${
          dateRange?.from ? "From: " + new Date(dateRange.from).toLocaleDateString("en-US") : ""
        }  ${dateRange?.to ? "To: " + new Date(dateRange.to).toLocaleDateString("en-US") : ""} `;
      },
      name: "dateRange",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.dateRange?.show;
      },
      filters: [
        {
          path: ["data", "info", "request", "params", "dateRange", "from"],
          preview: DateFilter,
          name: "dateRangeFrom",
          label: "Date range From"
        },
        {
          path: ["data", "info", "request", "params", "dateRange", "to"],
          preview: DateFilter,
          name: "dateRangeTo",
          label: "Date range To"
        }
      ]
    },
    {
      label: "Lender",
      getData: (entry): CellValue =>
        entry?.data?.info?.request?.params?.lender?.data?.info?.name ?? "",
      name: "lender",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.lender?.show;
      },
      filters: [
        {
          path: ["data", "info", "request", "params", "lenderId"],
          preview: LenderFilter,
          name: "lender",
          label: "Lender"
        }
      ]
    },
    {
      label: "Dealership",
      getData: (entry): CellValue =>
        entry?.data?.info?.request?.params?.dealership?.data?.info?.name ?? "",
      name: "dealership",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.dealership?.show;
      },
      filters: [
        {
          path: ["data", "info", "request", "params", "dealershipId"],
          preview: DealershipFilter,
          name: "dealership",
          label: "Dealership"
        }
      ]
    },
    {
      label: "Representative",
      getData: (entry): CellValue =>
        `${entry?.data?.info?.request?.params?.representative?.data?.info?.firstName ?? ""} ${
          entry?.data?.info?.request?.params?.representative?.data?.info?.lastName ?? ""
        } `,
      name: "representative",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.representative?.show;
      },
      filters: [
        {
          path: ["data", "info", "request", "params", "representativeId"],
          preview: UserFilter,
          name: "representative",
          label: "Representative",
          type: "representative"
        }
      ]
    },
    {
      label: "Created at",
      getData: (entry): CellValue => new Date(entry?.createdAt),
      name: "createdAt",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.createdAt?.show;
      },
      filters: [
        {
          path: ["createdAt"],
          preview: DateFilter,
          name: "createdAt",
          label: "Created At"
        }
      ]
    },
    {
      label: "Created by",
      getData: (entry): CellValue =>
        `${entry?.data?.createdByUser?.data?.info?.firstName ?? ""} ${
          entry?.data?.createdByUser?.data?.info?.lastName ?? ""
        }`,
      name: "createdByUser",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.createdByUser?.show;
      },
      filters: [
        {
          path: ["data", "createdByUser", "_id"],
          preview: UserFilter,
          name: "createdBy",
          label: "Created By"
        }
      ]
    },
    {
      label: "Status",
      getData: (entry): CellValue => entry._id,
      options: {
        customBodyRender: (value, entry): JSX.Element | string => {
          return entry?.data?.status === "success" ? (
            <CheckIcon fontSize="small" style={{ color: "#4caf50" }} />
          ) : entry?.data?.status === "loading" ? (
            <div>
              <CircularProgress size={20} />
            </div>
          ) : (
            <Tooltip placement="top" title={entry?.data?.info?.response?.error ?? ""}>
              <ErrorIcon style={{ color: "#f44336" }} fontSize="small" />
            </Tooltip>
          );
        }
      },
      name: "status",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.status?.show;
      },
      filters: [
        {
          path: ["data", "status"],
          preview: MultiSelectFilter,
          name: "status",
          label: "Status",
          valuesForSelect: ["success", "loading", "error"],
          optionLabelForSelect: (status) =>
            status?.charAt(0).toUpperCase() + status?.substring(1) || status
        }
      ]
    },
    {
      label: "Actions",
      getData: (entry): CellValue => entry._id,
      name: "actions",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.actions?.show;
      }
    }
  ];

  return (
    <div style={{ width: "100%", paddingTop: "20px" }}>
      <AccessControl requiredPermissions={{ entity: "business_report", action: "read" }}>
        <Table
          slice="table"
          tableName="business_reports"
          entityName="business_report"
          listFunction={getBusinessReportList}
          deleteEntityFunction={deleteBussinesReport}
          recoverEntityFunction={recoverBussinesReport}
          hideAddButton={true}
          hideShowButton={true}
          modificationForNewEntries={false}
          listEntity={businessReportList}
          title={"Business reports"}
          columns={columns}
          projection={projections}
        />
      </AccessControl>
    </div>
  );
}
