import { CircularProgress, Typography } from "@material-ui/core";
import { RootState } from "app/rootReducer";
import { Deal } from "components/Deals/types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import store from "../../../app/store";
import { getProductsRatesAction } from "./getProductRatesSlice";
import RateList from "./RateList";
import { DynamicSurcharge } from "./types";

export type handleChooseRateType = (
  providerId: string,
  type: string,
  rate: any,
  monthIndex: number,
  surcharges: DynamicSurcharge[],
  mileIndex?: number,
  deductibleIndex?: number
) => void;

interface Props {
  surcharges: any;
  product: any;
  productState: any;
  requestId: string;
  rateState: any;
  setRateState: any;
  deal: Deal;
}

const defaultState = {
  error: false,
  isLoading: false,
  ratesList: [],
  errorMessage: undefined,
  providerId: ""
};

export default function InsuranceProductRates({
  surcharges,
  product,
  productState,
  requestId,
  rateState,
  setRateState,
  deal
}: Props) {
  const surchargesTemp = Object.entries(surcharges).map(([key, value]) => ({
    SurchargeCode: key,
    Value:
      ["VEHPURDATE", "INSDATE", "CONTRACTDATE"].includes(key) &&
      value !== null &&
      !isNaN(Date.parse(value as string))
        ? new Date(value as Date)?.toISOString()?.slice(0, 10)
        : value
  }));
  const dispatch = useDispatch();
  const actionStateGetRates = useSelector(
    // @ts-ignore
    (state: RootState) => state.getProductRatesSlice[requestId]
  );
  const ascExtraProfit =
    store?.getState()?.listSettingsSlice?.settingsData?.entities?.[0]?.data?.info.ascWarranty ?? 0;
  const [state, setState] = useState<{
    isLoading: boolean;
    error: boolean;
    errorMessage: string | undefined;
    ratesList: any;
    providerId: string;
  }>(defaultState);

  useEffect(() => {
    setState(defaultState);
  }, [productState]);

  useEffect(() => {
    if (actionStateGetRates !== undefined && actionStateGetRates.status !== undefined) {
      switch (actionStateGetRates.status) {
        case "error":
          setState({ ...state, isLoading: false, error: true });
          dispatch({
            type: getProductsRatesAction.none.type,
            payload: { requestId }
          });
          break;
        case "waiting":
          setState({ ...state, isLoading: true, error: false });
          dispatch({
            type: getProductsRatesAction.none.type,
            payload: { requestId }
          });
          break;
        case "success":
          const { EX1RateResponse } = actionStateGetRates.data.message.data;
          const statusCode = EX1RateResponse?.MsgHeader.Message[0].StatusCode || "1000";
          const errorMessage =
            EX1RateResponse?.MsgHeader.Message[0].Description || "Unexpected error";

          if (statusCode === "0000") {
            const propType = EX1RateResponse.RateResponse.ProductCode;
            setState({
              ...state,
              isLoading: false,
              ratesList: EX1RateResponse.RateResponse.Rates.Coverage,
              error: false,
              errorMessage: undefined,
              providerId: EX1RateResponse.RateResponse.EX1ProviderID
            });
          } else {
            setState({ ...state, isLoading: false, error: true, errorMessage });
          }

          dispatch({
            type: getProductsRatesAction.none.type,
            payload: { requestId }
          });
          break;
      }
    }
  }, [actionStateGetRates, dispatch, requestId, state]);
  const handleChooseRate: handleChooseRateType = (
    providerId,
    type,
    rate,
    monthIndex,
    surcharges,
    mileIndex = 0,
    deductibleIndex = 0
  ) => {
    const totalSurchargesPrice = surcharges.reduce(
      (acc, curr) => (curr.Value === "true" ? parseFloat(curr.Price) + acc : acc),
      0
    );
    const totalSurchargesCost = surcharges.reduce(
      (acc, curr) => (curr.Value === "true" ? parseFloat(curr.Cost) + acc : acc),
      0
    );
    type === "GAP"
      ? setRateState({
          ...rateState,
          type: "F&I Express",
          originalRate: {
            ...rate.Term[monthIndex]
          },
          chosenRate: {
            Coverage: rate.Value,
            EX1ProviderID: providerId,
            insuranceType: "gap",
            surcharges: surchargesTemp,
            product: product,
            months: parseInt(rate.Term[monthIndex].Value),
            EX1RateResponseID: rate.Term[monthIndex].EX1RateResponseID,
            ContractNumber: "",
            ContractFormID: "",
            dealerCostPrice: parseFloat(rate.Term[monthIndex].DealerCost) + totalSurchargesCost,
            retailPrice: parseFloat(rate.Term[monthIndex].RetailPrice) + totalSurchargesPrice,
            dynamicSurcharges: surcharges,
            remitPrice: parseFloat(rate.Term[monthIndex].RemitPrice),
            profit: parseFloat(
              (rate.Term[monthIndex].RetailPrice - rate.Term[monthIndex].DealerCost).toFixed(2)
            ),
            soldBy: rateState?.chosenRate?.soldBy
          }
        })
      : setRateState({
          ...rateState,
          type: "F&I Express",
          originalRate: {
            months: rate.Term[monthIndex].Value,
            miles: rate.Term[monthIndex].Miles[mileIndex].Value,
            deductible: rate.Term[monthIndex].Miles[mileIndex].Deductible[deductibleIndex]
          },
          chosenRate: {
            Coverage: rate.Value,
            EX1ProviderID: providerId,
            insuranceType: "service warranty",
            surcharges: surchargesTemp,
            product: product,
            months: parseInt(rate.Term[monthIndex].Value),
            EX1RateResponseID:
              rate.Term[monthIndex].Miles[mileIndex].Deductible[deductibleIndex].EX1RateResponseID,
            ContractNumber: "",
            ContractFormID: "",
            dealerCostPrice:
              parseFloat(
                rate.Term[monthIndex].Miles[mileIndex].Deductible[deductibleIndex].DealerCost
              ) +
              totalSurchargesCost +
              (product?.ProviderName === "ASC Warranty" ? ascExtraProfit : 0),
            retailPrice:
              parseFloat(
                rate.Term[monthIndex].Miles[mileIndex].Deductible[deductibleIndex].RetailPrice
              ) +
              totalSurchargesPrice +
              (product?.ProviderName === "ASC Warranty" ? ascExtraProfit : 0),
            dynamicSurcharges: surcharges,
            remitPrice: parseFloat(
              rate.Term[monthIndex].Miles[mileIndex].Deductible[deductibleIndex].RemitPrice
            ),
            profit: parseFloat(
              (
                rate.Term[monthIndex].Miles[mileIndex].Deductible[deductibleIndex].RetailPrice -
                rate.Term[monthIndex].Miles[mileIndex].Deductible[deductibleIndex].DealerCost
              ).toFixed(2)
            ),
            soldBy: rateState?.chosenRate?.soldBy
          }
        });
  };

  return (
    <>
      {!state.error ? (
        state.isLoading ? (
          <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <CircularProgress />
          </div>
        ) : (
          state.ratesList.length !== 0 &&
          state.ratesList.map((el: any, index: number) => {
            return (
              <RateList
                product={product}
                key={index}
                el={el}
                index={index}
                handleChooseRate={handleChooseRate}
                providerId={state.providerId}
                deal={deal}
              />
            );
          })
        )
      ) : (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Typography variant="h6">{state.errorMessage}</Typography>
        </div>
      )}
    </>
  );
}
