import { useQuery } from "@tanstack/react-query";
import { getDealsForDealMapDashboard } from "Chat/http/deals";

export const useDealsForMap = () => {
  const queryKey = ["deals-for-map"];

  const { data: deals, isLoading, isFetching, error, isSuccess } = useQuery({
    queryKey,
    queryFn: getDealsForDealMapDashboard()
  });

  return {
    isSuccess,
    data: deals,
    isLoading,
    isFetching,
    error
  };
};
