import React from "react";
import LoopIcon from "@material-ui/icons/Loop";
import { createStyles, makeStyles } from "@material-ui/core";
export const useStyles = makeStyles(() =>
  createStyles({
    rotateIcon: {
      animation: "$spin 2s linear infinite"
    },
    "@keyframes spin": {
      "0%": {
        transform: "rotate(360deg)"
      },
      "100%": {
        transform: "rotate(0deg)"
      }
    }
  })
);
export default ({ text = "Loading", style = {} }) => {
  return (
    <span style={style}>
      {text} <LoopIconWrapper />
    </span>
  );
};

export const LoopIconWrapper = () => {
  const classes = useStyles();
  return (
    <LoopIcon fontSize="large" className={classes.rotateIcon} style={{ marginBottom: "-7px" }} />
  );
};
