import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";
import { FormState } from "./types";

export interface AddDefaultStipulationResponse {
  message: {
    data: FormState;
    _id: string;
    createdAt: string;
  };
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  FormState,
  AddDefaultStipulationResponse
>("AddDefaultStipulation", {
  request: "new_default_stipulation"
});

export const addDefaultStipulationActions = socketMessageSlice.actions;
export const addDefaultStipulation = socketAction;
export default socketMessageSlice.reducer;
