import React from "react";
import States from "us-states";
import { Prescreen } from "./types";
import { Deal } from "components/DealerTrack/types";
import { getStateLabelByState } from "utils/functions";
import { Applicant } from "components/Applicants/types";
import FilesPreview from "components/Files/FilesPreview";
import AccessControl from "components/Access/AccessControl";
import { RenderSet, StateAccess } from "utils/models/formGenerator";
import { FormComponent, Model, createOptionsForSelect } from "../../utils/models/fields";
import DealershipPreview from "components/Dealerships/DealershipPreview";
import { dealershipStruct } from "components/Dealerships/model";
import { Typography } from "@material-ui/core";
import PredictionIcon from "components/Deals/PredictionIcon";
import InfoIcon from "@material-ui/icons/Info";
import { formatCreditBureausScores } from "components/CreditScore/CreditPrequalifyView";
import { CreditPrequalify } from "types/credit_prequalify";
import { Prediction } from "components/Deals/DealPredictions/types";

const files: FormComponent<Prescreen> = {
  formComponent: "segment",
  width: "full",
  entities: [
    {
      formComponent: "one-to-many-field",
      component: (
        stateAccess: StateAccess,
        _mainstateAccess: StateAccess,
        _renderSet: RenderSet
      ) => {
        return (
          <AccessControl requiredPermissions={{ entity: "file", action: "read" }}>
            <FilesPreview
              tableName="files"
              type="prescreen"
              _id={stateAccess.get(["_id"])}
              types={stateAccess.get<Deal>(["data", "files"])}
            />
          </AccessControl>
        );
      },
      name: "file",
      label: "Files",
      width: "full",
      path: null,
      valueToString: (el) => el.name
    }
  ]
};

const driverLicense: FormComponent<Applicant> = {
  formComponent: "segment",
  width: "full",
  name: "Driver License",
  entities: [
    {
      formComponent: "license-number-field",
      name: "licenseNumber",
      label: "License number",
      width: "1/4",
      path: ["data", "info", "applicant", "driverLicenseNumber"],
      default: null
    },

    {
      formComponent: "select-field",
      name: "state",
      label: "State",
      width: "1/4",
      path: ["data", "info", "applicant", "drivingLicenseState"],
      options: createOptionsForSelect({
        possibleValues: () => Object.keys(States),
        getOptionLabel: (x) => getStateLabelByState(x),
        getSelectedOption: (x, y) => x === y
      }),
      default: null
    },

    {
      formComponent: "date-field",
      name: "licenseIssueDate",
      label: "License Issue date",
      width: "1/4",
      path: ["data", "info", "applicant", "driverLicenseIssued"],
      disableRegion: "future",
      default: null
    },
    {
      formComponent: "date-field",
      name: "licenseExpiryDate",
      label: "License Expiry date",
      width: "1/4",
      disableRegion: "past",
      path: ["data", "info", "applicant", "driverLicenseExpires"],
      default: null
    }
  ]
};
const vehicle: FormComponent<Prescreen> = {
  formComponent: "segment",
  width: "full",
  name: "Vehicle",
  entities: [
    {
      formComponent: "text-field",
      name: "vin",
      label: "VIN",
      width: "1/4",
      path: ["data", "info", "vehicle", "VIN"],
      default: null
    },
    {
      formComponent: "text-field",
      name: "year",
      label: "Year",
      width: "1/4",
      path: ["data", "info", "vehicle", "year"],
      default: null
    },
    {
      formComponent: "text-field",
      name: "make",
      label: "Make",
      width: "1/4",
      path: ["data", "info", "vehicle", "make"],
      default: null
    },
    {
      formComponent: "text-field",
      name: "model",
      label: "Model",
      width: "1/4",
      path: ["data", "info", "vehicle", "model"],
      default: null
    },
    {
      formComponent: "text-field",
      name: "odometer",
      label: "Odometer",
      width: "1/4",
      path: ["data", "info", "vehicle", "odometer"],
      default: null
    },
    {
      formComponent: "currency-field",
      name: "msrp",
      label: "MSRP",
      width: "1/4",
      path: ["data", "info", "vehicle", "MSRP"],
      default: null
    },
    {
      formComponent: "currency-field",
      name: "dealTotal",
      label: "Amount financed",
      width: "1/4",
      path: ["data", "info", "payment", "dealTotal"],
      default: null
    }
  ]
};

export const getCurrentAddressStruct = (): FormComponent<Applicant> => {
  return {
    formComponent: "segment",
    width: "full",
    name: "Current Address",
    entities: [
      {
        formComponent: "segment",
        width: "2/3",
        entities: [
          {
            formComponent: "text-field",
            name: "currentAddressNumber",
            label: "#",
            path: ["data", "info", "applicant", "currentAddressNumber"],
            width: "1/4",
            default: null
          },
          {
            formComponent: "address-field",
            name: "currentAddress",
            required: true,
            label: "Address",
            path: ["data", "info", "applicant", "currentAddress"],
            width: "3/4",
            default: null
          }
        ]
      },

      {
        formComponent: "zip-code-field",
        name: "zipCode",
        label: "Zip code",
        path: ["data", "info", "applicant", "currentZipCode"],
        width: "1/3",
        default: null
      },
      {
        formComponent: "hidden-field",
        name: "currentCountry",
        label: "Country",
        width: "1/4",
        path: ["data", "info", "applicant", "currentCountry"],
        default: "USA"
      },
      {
        formComponent: "select-field",
        name: "state",
        label: "State",
        width: "1/3",
        path: ["data", "info", "applicant", "currentState"],
        autoSelect: true,
        options: createOptionsForSelect({
          possibleValues: () => Object.keys(States),
          getOptionLabel: (x) => getStateLabelByState(x),
          getSelectedOption: (x, y) => x === y
        }),
        default: null
      },
      {
        formComponent: "select-field",
        name: "county",
        label: "County",
        width: "1/3",
        autoSelect: true,
        path: ["data", "info", "applicant", "currentCounty"],
        options: createOptionsForSelect({
          possibleValues: (stateAccess: StateAccess) =>
            States[stateAccess.get<Prescreen>(["data", "info", "currentState"]) as string]
              ?.counties,
          getOptionLabel: (x) => x ?? " ",
          getSelectedOption: (x, y) => x === y
        }),
        default: null
      },
      {
        formComponent: "city-field",
        name: "city",
        label: "City",
        minLength: 3,
        path: ["data", "info", "applicant", "currentCity"],
        width: "1/3",
        default: null
      }
    ]
  };
};

export const prescreenStruct: Model<Prescreen> = {
  formComponent: "model",
  schema: "new_prescreen",
  name: "prescreen",
  entities: [
    {
      formComponent: "segment",
      name: "Dealership",
      width: "1/2",
      entities: [
        {
          formComponent: "one-to-many-field",
          struct: () => dealershipStruct,
          component: (
            stateAccess: StateAccess,
            mainstateAccess: StateAccess,
            renderSet: RenderSet
          ) => (
            <DealershipPreview
              type="chargeback"
              stateAccess={stateAccess}
              renderSet={renderSet}
              path={["data", "dealership"]}
              name="dealership"
              label="Dealership"
              required={false}
            />
          ),
          name: "dealership",
          label: "Dealership",
          default: null,
          path: ["data", "dealership"],
          width: "1/3"
        },
        {
          width: "1/3",
          formComponent: "one-to-many-field",
          component: (stateAccess: StateAccess) => {
            const prediction = stateAccess.get(["data", "info", "prediction"]) as Prediction;
            if (!prediction?.decision) return null;

            if (prediction?.predictionFailed)
              return (
                <Typography
                  color="primary"
                  style={{
                    fontSize: "13x",
                    fontWeight: "bold"
                  }}
                >
                  <span style={{ display: "flex", alignItems: "center", gap: "2px" }}>
                    Prediction: Not available
                    <PredictionIcon prediction={prediction} />
                  </span>
                </Typography>
              );

            const label = prediction?.decision?.label;
            const probability = (prediction?.decision?.probability * 100).toFixed(2);
            const DTI = prediction?.financial_info?.DTI;
            const LTV = prediction?.financial_info?.LTV;

            return (
              <Typography
                color="primary"
                style={{
                  fontSize: "13x",
                  fontWeight: "bold"
                }}
              >
                <span style={{ display: "flex", alignItems: "center", gap: "2px" }}>
                  Prediction:{" "}
                  <span style={{ color: label === "approved" ? "green" : "red" }}>
                    {`${probability}%`}
                  </span>{" "}
                  {label === "approved" ? "approval chance" : "chance of not being approved."}
                  <PredictionIcon prediction={prediction} />
                </span>
                <Typography>
                  DTI: <span style={{ color: "black", fontWeight: "bold" }}>{`${DTI} `} </span> LTV:
                  <span style={{ color: "black", fontWeight: "bold" }}>{` ${LTV}`} </span>
                </Typography>
              </Typography>
            );
          },
          name: "Prediction",
          label: "Prediction",
          path: null
        },
        {
          width: "1/3",
          formComponent: "one-to-many-field",
          component: (stateAccess: StateAccess) => {
            const creditPrequalify = stateAccess.get([
              "data",
              "creditPrequalify"
            ])?.[0] as CreditPrequalify;
            console.log(creditPrequalify);

            if (creditPrequalify === undefined) return null;
            return (
              <div>
                <Typography variant="h6" color="primary">
                  Credit Score
                </Typography>

                <div style={{ display: "flex", gap: 5 }}>
                  {formatCreditBureausScores(creditPrequalify)}
                  <div style={{ alignItems: "center", display: "flex", gap: 2 }}>
                    (<Typography variant="caption">Soft pull</Typography>
                    <InfoIcon fontSize="small" color="primary" />)
                  </div>
                </div>
              </div>
            );
          },
          name: "creditPrequalify",
          label: "creditPrequalify",
          path: null
        }
      ]
    },

    {
      formComponent: "segment",
      name: "Preescreen Info",
      width: "1/2",
      entities: [
        {
          formComponent: "name-field",
          name: "firstName",
          label: "First name",
          required: true,
          width: "1/4",
          path: ["data", "info", "applicant", "firstName"],
          default: null
        },
        {
          formComponent: "name-field",
          name: "middleName",
          label: "Middle name",
          width: "1/4",
          path: ["data", "info", "applicant", "middleName"],
          default: null
        },
        {
          formComponent: "name-field",
          name: "lastName",
          label: "Last name",
          required: true,
          width: "1/4",
          path: ["data", "info", "applicant", "lastName"],
          default: null
        },
        {
          formComponent: "date-with-age-field",
          name: "birthDate",
          label: "Date of birth",
          width: "1/4",
          path: ["data", "info", "applicant", "birthDate"],
          disableRegion: "future",
          default: null
        },
        getCurrentAddressStruct(),
        driverLicense,
        vehicle,
        files
      ]
    }
  ]
};
