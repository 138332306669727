import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Paper,
  Typography
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Big from "big.js";
import ProgressBar from "components/ProgressBar/ProgressBar";
import {
  LenderAllocationStatistic,
  LenderAllocationStatisticRequest,
  OverviewStatistics,
  PeriodAllocationStatistics
} from "hooks/useLenderAllocationStatistics/types";
import React from "react";
import {
  calculateMonthsBetweenByPeriod,
  formatNumberAsCurrency,
  parseMonth
} from "utils/functions";
import styles from "../../LenderDashboard.module.css";
import { ToggleState } from "../LenderAllocationDashboard";

function monthDiff(dateFrom: Date, dateTo: Date) {
  return (
    dateTo.getMonth() - dateFrom.getMonth() + 12 * (dateTo.getFullYear() - dateFrom.getFullYear())
  );
}
const isPeriodStatistic = (
  statistic: LenderAllocationStatistic
): statistic is PeriodAllocationStatistics => {
  return Object.hasOwn(statistic, "allocatedAmountPastMonths");
};
const YearlyAllocationInformation = ({
  data,
  toggleState,
  state,
  overviewStatistics
}: {
  overviewStatistics: OverviewStatistics[];
  data: LenderAllocationStatistic[] | undefined;
  toggleState: ToggleState;
  state: LenderAllocationStatisticRequest;
}) => {
  return (
    <Paper
      style={{
        padding: "20px 20px"
      }}
      className={styles.dashboardItem}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          marginBottom: 30,
          gap: "10px"
        }}
      >
        <Typography
          style={{
            fontWeight: 500,
            fontSize: 22,
            lineHeight: "20px",
            marginBottom: 10,
            textAlign: "center"
          }}
        >
          Yearly allocation information
        </Typography>
        {(data ?? [])?.reverse()?.map((statistic, index) => {
          const approvedAllocated = statistic?.allocatedAmount?.approved || 0;
          const allocatedAmountWithoutApproved = statistic?.totalAllocation
            ? new Big(statistic?.totalAllocated || 0).sub(new Big(approvedAllocated)).toNumber()
            : 0;
          const percentAllocatedWithoutApproved = statistic?.totalAllocation
            ? new Big(statistic?.totalAllocated || 0)
                .sub(new Big(approvedAllocated))
                .div(new Big(statistic?.totalAllocation || 1))
                .mul(new Big(100))
                .toNumber()
            : 0;
          const percentageAllocated = statistic?.totalAllocation
            ? new Big(approvedAllocated || 0)
                .div(new Big(statistic?.totalAllocation || 1))
                .mul(new Big(100))
                .toNumber()
            : 0;
          let monthsCount = 0;
          let pastMonthsCount = 0;
          monthsCount = monthDiff(
            new Date(
              statistic.allocationPeriod.from.year,
              statistic.allocationPeriod.from.month,
              0
            ),
            new Date(
              statistic.allocationPeriod.to?.year || 2030,
              statistic.allocationPeriod.to?.month || 0,
              31
            )
          );
          monthsCount = monthsCount > 12 ? 12 : monthsCount;
          pastMonthsCount = monthDiff(
            new Date(
              statistic.allocationPeriod.from.year,
              statistic.allocationPeriod.from.month,
              0
            ),
            new Date(
              Math.min(
                new Date(
                  statistic.allocationPeriod.to?.year || 2030,
                  statistic.allocationPeriod.to?.month || 1,
                  31
                ).getTime(),
                new Date().getTime()
              )
            )
          );

          return (
            <Accordion key={index} defaultExpanded={index === 0}>
              <AccordionSummary
                style={{
                  background: "#e3e3e3"
                }}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography
                  style={{
                    //   textAlign: "center",
                    fontWeight: "bold",
                    fontSize: "16px"
                  }}
                >
                  {`${statistic.allocationPeriod.from.year} ${parseMonth(
                    statistic.allocationPeriod.from.month
                  )} - ${statistic.allocationPeriod.to?.year} ${parseMonth(
                    statistic.allocationPeriod.to?.month || 0
                  )} `}{" "}
                  {statistic.allocationPeriod.suggested && "(suggested)"}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div
                  key={index}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    gap: 10,
                    width: "100%"
                  }}
                >
                  <Typography
                    style={{
                      fontWeight: 500,
                      fontSize: 20,
                      lineHeight: "20px",
                      marginBottom: 10,
                      color: "orange",
                      textAlign: "center"
                    }}
                  >
                    {(statistic?.totalAllocation || 0) *
                      ((statistic?.allocationPeriod.softAllocation || 0) / 100) <=
                      statistic?.totalAllocated && "Soft allocation is reached!"}
                  </Typography>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between"
                    }}
                  >
                    <Typography
                      style={{
                        fontWeight: 500,
                        fontSize: 19,
                        lineHeight: "20px",
                        color: "#6A6A6A"
                      }}
                    >
                      Allocation
                    </Typography>

                    <Typography style={{ fontWeight: 500, fontSize: 22, lineHeight: "23px" }}>
                      {formatNumberAsCurrency(statistic?.totalAllocation || 0, "$")}
                    </Typography>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-end",
                      marginBottom: 10
                    }}
                  >
                    <Typography
                      style={{
                        fontWeight: 500,
                        marginRight: 10,
                        fontSize: 15,
                        lineHeight: "20px",
                        color: "#6A6A6A"
                      }}
                    >
                      Monthly (suggested):
                    </Typography>

                    <Typography style={{ fontWeight: 500, fontSize: 15 }}>
                      {formatNumberAsCurrency((statistic?.totalAllocation || 0) / monthsCount, "$")}
                    </Typography>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginBottom: 0
                    }}
                  >
                    <Typography
                      style={{
                        fontWeight: 500,
                        fontSize: 19,
                        lineHeight: "20px",
                        color: "#6A6A6A"
                      }}
                    >
                      Total used amount
                    </Typography>
                    <Typography
                      style={{
                        fontWeight: 500,
                        fontSize: 22,
                        lineHeight: "23px",
                        color:
                          statistic?.totalAllocated > statistic?.totalAllocation ? "red" : "#000"
                      }}
                    >
                      {formatNumberAsCurrency(allocatedAmountWithoutApproved, "$")}
                    </Typography>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      marginBottom: 30
                    }}
                  >
                    <Typography
                      style={{
                        fontWeight: 500,
                        fontSize: 19,
                        lineHeight: "20px",
                        color: "#6A6A6A"
                      }}
                    >
                      Total used amount with approved
                    </Typography>
                    <Typography
                      style={{
                        fontWeight: 500,
                        fontSize: 22,
                        lineHeight: "23px",
                        color:
                          statistic?.totalAllocated > statistic?.totalAllocation ? "red" : "#000"
                      }}
                    >
                      {formatNumberAsCurrency(statistic?.totalAllocated || 0, "$")}
                    </Typography>
                  </div>
                  <div style={{ marginBottom: "10px" }}>
                    <ProgressBar
                      percents={[percentAllocatedWithoutApproved, percentageAllocated]}
                    />
                  </div>
                  <Grid container item xs={12} spacing={2}>
                    <Grid item container xs={12} alignItems="center">
                      <Grid item container xs={9} alignItems="center">
                        <Grid item xs={1}>
                          <div
                            style={{
                              width: "75%",
                              height: "20px",
                              background: "#51BDE3"
                            }}
                          ></div>
                        </Grid>
                        <Grid item xs={11}>
                          <Typography style={{ marginLeft: "10px" }}>
                            Sent to dealer, submitted for funding, funding held, funded
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item xs={3} style={{ fontWeight: "bold" }}>
                        {formatNumberAsCurrency(allocatedAmountWithoutApproved, "$")}
                      </Grid>
                    </Grid>
                    <Grid item container xs={12} alignItems="center">
                      <Grid item container xs={9} alignItems="center">
                        <Grid item xs={1}>
                          <div
                            style={{
                              width: "75%",
                              height: "20px",
                              background: "#F0D855"
                            }}
                          ></div>
                        </Grid>
                        <Grid item xs={11}>
                          <Typography style={{ marginLeft: "10px" }}>Approved</Typography>
                        </Grid>
                      </Grid>
                      <Grid item xs={3} style={{ fontWeight: "bold" }}>
                        {formatNumberAsCurrency(approvedAllocated, "$")}
                      </Grid>
                    </Grid>
                    <Grid item container xs={12} alignItems="center">
                      <Grid item container xs={9} alignItems="center">
                        <Grid item xs={1}>
                          <div
                            style={{
                              width: "75%",
                              height: "20px",
                              background: "#52a543"
                            }}
                          ></div>
                        </Grid>
                        <Grid item xs={11}>
                          <Typography
                            style={{
                              marginLeft: "10px",
                              fontWeight: "bold",
                              fontSize: "20px"
                            }}
                          >{`${
                            new Big(statistic.totalAllocation || 0)
                              .sub(new Big(statistic.totalAllocated || 0))
                              .toNumber() < 0
                              ? "Overallocated"
                              : "Unallocated"
                          }`}</Typography>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        style={{
                          fontWeight: "bold",
                          fontSize: "20px",
                          color:
                            new Big(statistic.totalAllocation || 0)
                              .sub(new Big(allocatedAmountWithoutApproved || 0))
                              .toNumber() < 0
                              ? "red"
                              : "#000"
                        }}
                      >
                        {formatNumberAsCurrency(
                          new Big(statistic.totalAllocation || 0)
                            .sub(new Big(allocatedAmountWithoutApproved || 0))
                            .toNumber(),
                          "$"
                        )}
                      </Grid>
                    </Grid>
                    {isPeriodStatistic(statistic) && (
                      <Grid item container xs={12} alignItems="center">
                        <Grid item container xs={9} alignItems="center">
                          <Grid item xs={12}>
                            <Typography>Average allocated per month</Typography>
                          </Grid>
                        </Grid>
                        <Grid item xs={3} style={{ fontWeight: "bold" }}>
                          {statistic.allocatedAmountPastMonths
                            ? formatNumberAsCurrency(
                                new Big(statistic.allocatedAmountPastMonths || 0)
                                  .div(pastMonthsCount - 1 || 1)
                                  .toNumber(),
                                "$"
                              )
                            : "N/A"}
                        </Grid>
                      </Grid>
                    )}
                    {isPeriodStatistic(statistic) && (
                      <Grid item container xs={12} alignItems="center">
                        <Grid item container xs={9} alignItems="center">
                          <Grid item xs={12}>
                            <Typography>Average unallocated per month left</Typography>
                          </Grid>
                        </Grid>
                        <Grid item xs={3} style={{ fontWeight: "bold" }}>
                          {formatNumberAsCurrency(
                            new Big(statistic?.totalAllocation || 0)
                              .div(monthsCount || 1)
                              .sub(
                                new Big(statistic.allocatedAmountPastMonths || 0)
                                  .div(pastMonthsCount || 1)
                                  .toNumber()
                              )
                              .toNumber(),
                            "$"
                          )}
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </div>
              </AccordionDetails>
            </Accordion>
          );
        })}
        <Typography
          style={{
            fontWeight: 500,
            fontSize: 22,
            lineHeight: "20px",
            marginBottom: 10,
            textAlign: "center"
          }}
        >
          Average funded per period
        </Typography>
        {overviewStatistics?.map((overview, index) => {
          return (
            <>
              <Grid key={index} item container xs={12} alignItems="center">
                <Grid item container xs={9} alignItems="center">
                  <Grid item xs={12}>
                    <Typography>
                      Period:{" "}
                      {`${parseMonth(overview?.funded?.period?.from?.month)}/${
                        overview?.funded?.period?.from?.year
                      } - ${parseMonth(overview?.funded?.period?.to?.month)}/${
                        overview?.funded?.period?.to?.year
                      }`}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={3} style={{ fontWeight: "bold" }}>
                  {formatNumberAsCurrency(
                    new Big(overview?.funded?.amount || 0)
                      .div(
                        overview?.funded?.period?.from && overview?.funded?.period?.to
                          ? calculateMonthsBetweenByPeriod(overview?.funded?.period) || 1
                          : 1
                      )
                      .toNumber(),
                    "$"
                  )}
                </Grid>
              </Grid>
            </>
          );
        })}
      </div>
    </Paper>
  );
};

export default YearlyAllocationInformation;
