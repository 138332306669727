import axios from "axios";
import { CollateralTypes, Deal, DealStatus } from "components/Deals/types";
import { auth } from "../../firebase/firebase";
import { Lender } from "components/Lenders/types";
import { Dealership } from "components/Dealerships/types";

export const getDealsForDealMapDashboard = (): (() => Promise<{
  deals: Deal[];
  lenders: Lender[];
  dealerships: Dealership[];
}>) => {
  return async () => {
    const token = await auth.currentUser?.getIdToken();

    const response = await axios.get(
      `${process.env.REACT_APP_HTTP_URL}/api/v1/deals_for_map_dashboard/`,
      {
        headers: {
          authorization: `Bearer ${token}`
        }
      }
    );
    return response.data.result ?? [];
  };
};
