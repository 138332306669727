import React, { memo } from "react";
import { Feature, Point } from "geojson";
import { DealStatus } from "components/Deals/types";
import { capitalize } from "utils/functions";
import { DealershipsFeaturesProps, DealsFeaturesProps, LendersFeaturesProps } from "./types";
import formatDate from "utils/formatDate";
type SingleFeatureInfoWindowContentProps = {
  feature: Feature<Point>;
};
type DealInfoWindowContentProps = {
  features: Feature<Point>[];
  status: DealStatus | undefined;
  dealership: string | undefined;
};
const numFmt = new Intl.NumberFormat();

export const DealInfoWindowContent = memo(
  ({ features, status, dealership }: DealInfoWindowContentProps) => {
    if (features.length === 1) {
      const f = features[0];
      const props = f.properties as DealsFeaturesProps;
      return (
        <div>
          <h3 style={{ textAlign: "center" }}>Deal Info</h3>
          <p>
            <strong>Applicant:</strong> {props?.applicantNames ?? "N/A"}
          </p>
          <p>
            <strong>Dealership:</strong> {props?.dealershipName ?? "N/A"}
          </p>
          <p>
            <strong>Lender:</strong> {props?.lenderName ?? "N/A"}
          </p>
          <p>
            <strong>Collateral:</strong>{" "}
            {(
              props?.vehicle?.year +
              " " +
              props?.vehicle?.make +
              " " +
              props?.vehicle?.model
            ).trim() ?? "N/A"}
          </p>
          <p>
            <strong>Amount Financed:</strong> ${props?.amountFinanced?.toFixed(2) || 0}
          </p>
          <p>
            <strong>Status:</strong> {capitalize(props?.status) ?? "N/A"}
          </p>
          {props?.statusGroup === "false negative" && (
            <div>
              <h4 style={{ textAlign: "center" }}>Status History</h4>
              {props.statusHistory
                .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
                .map((x, i) => (
                  <div key={i}>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <div
                        style={{
                          width: "70%",
                          textAlign: "center",
                          borderTop: "1px solid rgb(235 235 235)"
                        }}
                      ></div>
                    </div>
                    <p>
                      <strong>Status:</strong> {capitalize(x.status) ?? "N/A"}
                    </p>
                    <p>
                      <strong>Date:</strong> {formatDate(x.date, "medium") ?? "N/A"}
                    </p>
                    <p>
                      <strong>Reason:</strong> {x.reason ?? "N/A"}
                    </p>
                  </div>
                ))}
            </div>
          )}
        </div>
      );
    }

    return (
      <div>
        <h4>
          {numFmt.format(features.length)} {capitalize(status)} deals for {dealership}. Zoom in to
          explore.
        </h4>
      </div>
    );
  }
);

export const DealershipInfoWindowContent = memo(
  ({ feature }: SingleFeatureInfoWindowContentProps) => {
    const props = feature.properties as DealershipsFeaturesProps;
    return (
      <div>
        <div>
          <p>
            <strong>Name:</strong> {props?.name}
          </p>
        </div>
      </div>
    );
  }
);

export const LenderInfoWindowContent = memo(({ feature }: SingleFeatureInfoWindowContentProps) => {
  const props = feature.properties as LendersFeaturesProps;
  return (
    <div>
      <div>
        <p>
          <strong>Name:</strong> {props?.name}
        </p>
      </div>
    </div>
  );
});
