import React, { useCallback } from "react";
import {
  AdvancedMarker,
  AdvancedMarkerAnchorPoint,
  useAdvancedMarkerRef
} from "@vis.gl/react-google-maps";
import { DealsFeaturesProps } from "./types";
import { FaCircle } from "react-icons/fa";
import { FaCircleHalfStroke } from "react-icons/fa6";
import { DealStatus } from "components/Deals/types";
import { NEGATIVE_STATUSES } from "./DealsMapDashboard";

type TreeMarkerProps = {
  position: google.maps.LatLngLiteral;
  featureId: string;
  clusterColor: string;
  featureProperties: DealsFeaturesProps;
  onMarkerClick?: (marker: google.maps.marker.AdvancedMarkerElement, featureId: string) => void;
};

export const FeatureMarkerDeal = ({
  position,
  featureId,
  onMarkerClick,
  featureProperties,
  clusterColor
}: TreeMarkerProps) => {
  const [markerRef, marker] = useAdvancedMarkerRef();
  const handleClick = useCallback(
    () => onMarkerClick && marker && onMarkerClick(marker, featureId),
    [onMarkerClick, marker, featureId]
  );
  return (
    <AdvancedMarker
      ref={markerRef}
      position={position}
      onClick={handleClick}
      anchorPoint={AdvancedMarkerAnchorPoint.CENTER}
    >
      <FaCircle
        size={sizeByAmountFinanced(featureProperties.amountFinanced) * 0.5}
        color={clusterColor}
      />
    </AdvancedMarker>
  );
};

export const sizeByAmountFinanced = (amountFinanced: number) => {
  if (amountFinanced < 10_000) {
    return 20;
  }
  if (amountFinanced >= 10_000 && amountFinanced < 20_000) {
    return 25;
  }
  if (amountFinanced >= 20_000 && amountFinanced < 30_000) {
    return 30;
  }
  if (amountFinanced >= 30_000 && amountFinanced < 40_000) {
    return 35;
  }
  if (amountFinanced >= 40_000 && amountFinanced < 50_000) {
    return 40;
  }
  if (amountFinanced >= 50_000 && amountFinanced < 100_000) {
    return 45;
  }
  if (amountFinanced >= 100_000) {
    return 50;
  }
  return 50;
};
