import Big from "big.js";
import { Column, Entry } from "components/GroupedTable";
import { AllocationPeriod } from "components/Lenders/types";
import { saveAs } from "file-saver";
import { OverviewPeriod } from "hooks/useLenderAllocationStatistics/types";
import States from "../us-states";
import { createOptionsForSelect } from "./models/fields";

export const assertNever = (x: never): never => {
  throw new Error(`Unexpected object ${x}`);
};

export const getTimeFrameMonthsBackToCurrentDate = (monthsBack: number) => {
  const currentDate = new Date();
  const pastDate = new Date();
  pastDate.setMonth(currentDate.getMonth() - monthsBack);

  return {
    from: pastDate,
    to: currentDate
  };
};
//

export const getStateLabelByState = (selectedState: string) => {
  const stateInfo = Object.entries(States).find(([state, _stateData]) => state === selectedState);
  return stateInfo ? `${stateInfo?.[1]?.prefix ?? ""} - ${stateInfo?.[0] ?? ""}` : "";
};
export function calculateMonthsBetweenByPeriod(period: AllocationPeriod | OverviewPeriod) {
  const fromDate = new Date(period.from.year, period.from.month - 1, 1);
  const toDate = period.to ? new Date(period.to.year, period.to.month, 0) : new Date(2030);

  const diffInYears = toDate.getFullYear() - fromDate.getFullYear();
  const diffInMonths = toDate.getMonth() - fromDate.getMonth();
  const totalMonths = diffInYears * 12 + diffInMonths;

  return totalMonths;
}

export const getStateLabelByStatePrefix = (selectedPrefix: string) => {
  const stateInfo = Object.entries(States).find(
    ([_state, stateData]) => stateData?.prefix === selectedPrefix
  );
  return stateInfo ? `${stateInfo?.[1]?.prefix ?? ""} - ${stateInfo?.[0] ?? ""}` : "";
};
export const getStateByPrefix = (selectedPrefix: string): string | null => {
  const stateInfo = Object.entries(States).find(
    ([_state, stateData]) => stateData?.prefix === selectedPrefix
  );
  return stateInfo ? stateInfo[0] : null;
};

export const getPrefixByState = (state: keyof typeof States | undefined) => {
  if (state === undefined) return null;
  return States[state]?.prefix ?? null;
};

export const removeSpaces = (data: string) => {
  return data.replace(/\s/g, "");
};
export const getYearsForSelectField = createOptionsForSelect({
  numeric: true,
  possibleValues: () =>
    new Array(new Date().getFullYear() - 1900)
      .fill(1900)
      .map((x, i) => x + i + 1)
      .reverse(),
  getOptionLabel: (x) => (x === null || x === undefined ? "" : x.toString()),
  getSelectedOption: (x, y) => x === y
});
export const areDatesTheSameDay = (a: Date, b: Date) =>
  a.getDate() === b.getDate() &&
  a.getMonth() === b.getMonth() &&
  a.getFullYear() === b.getFullYear();

export const formatNumberAsCurrency = (
  number: number | undefined | null,
  sign = "",
  fractionDigits = 2
): string | undefined => {
  if (typeof number === "number") {
    return sign === "%"
      ? new Intl.NumberFormat("en-US", {
          minimumFractionDigits: fractionDigits,
          maximumFractionDigits: fractionDigits
        }).format(number) + sign
      : sign +
          new Intl.NumberFormat("en-US", {
            minimumFractionDigits: fractionDigits,
            maximumFractionDigits: fractionDigits
          }).format(number);
  }
  return;
};

export const formatList = (list: any[] | null): string | undefined => {
  if (Array.isArray(list) && list.length > 0) {
    return list.join("\n");
  }
};

export function capitalize(s: string): string;
export function capitalize(s: undefined): undefined;
export function capitalize(s: string | undefined): string | undefined;

export function capitalize(s: string | undefined): string | undefined {
  return typeof s === "string" ? s.charAt(0).toUpperCase() + s.slice(1) : undefined;
}
export const capitalizeFirstLetterLowerCaseOthers = (s: string | undefined) => {
  if (typeof s !== "string") return;
  return s
    .split(" ")
    .map((el) => el.charAt(0).toUpperCase() + el.slice(1).toLowerCase())
    .join(" ");
};

export const removeCountryCodeAndBracesFromPhone = (phone: string) => {
  return phone.replace(/^\+1 ?/, "").replace(/[()]/g, "");
};
export const phoneWithBracesWithoutCode = (phone: string) => {
  const newPhone = phone.replace(/\D/g, "");

  if (newPhone.length === 11 && newPhone[0] === "1") {
    const phoneWithoutCode = newPhone.slice(1);
    return `(${phoneWithoutCode.slice(0, 3)}) ${phoneWithoutCode.slice(
      3,
      6
    )}-${phoneWithoutCode.slice(6, 10)}`;
  }

  return `(${newPhone.slice(0, 3)}) ${newPhone.slice(3, 6)}-${newPhone.slice(6, 10)}`;
};

export const getValidationErrorMessage = (errors: any) => {
  return errors?.reduce((acc: string, curr: any) => {
    const field = curr.instancePath.split("/").slice(-1)[0];
    return acc + ` ${field.charAt(0).toUpperCase() + field.slice(1)} ${curr.message}!`;
  }, "");
};

export const sanitizeAddress = (address?: string) => {
  return typeof address === "string"
    ? address
        .replace(/[^\w-\/. ]/g, "")
        .split(" ")
        .filter((x) => x)
        .map((x) => x.trim())
        .join(" ")
    : address;
};

export const getUrlExtension = (url?: string) => {
  return url?.split(/[#?]/)[0]?.split(".")?.pop()?.trim() ?? "";
};

export function typedKeys<T>(o: T): (keyof T)[] {
  return Object.keys(o) as (keyof T)[];
}

export function typedObjectEntries<T extends Record<string, unknown>, K extends keyof T>(
  obj: T
): [K, T[K]][] {
  return Object.entries(obj) as [K, T[K]][];
}
export const trimString = (str: string) => str.trimStart().trimEnd();

export const getMonthDaysAsAnArray = (date: Date) =>
  [...Array(new Date(date.getFullYear(), date.getMonth(), 0).getDate()).keys()].map((el) => el + 1);

export function truncate(input: string | undefined, length: number): string | undefined {
  if (input && input?.length > length + 3) {
    return input.substring(0, length) + "...";
  }
  return input;
}

export const getBase64 = (file: File) => {
  return new Promise((resolve) => {
    let baseURL: string | ArrayBuffer | null = "";
    // Make new FileReader
    const reader = new FileReader();
    // Convert the file to base64 text
    reader.readAsDataURL(file);

    // on reader load somthing...
    reader.onload = () => {
      // Make a fileInfo Object
      baseURL = reader.result;
      resolve(baseURL);
    };
  });
};

export const convertFileToBase64 = (file: File) => {
  return getBase64(file).then((result) => ({
    name: file.name,
    lastModified: file.lastModified,
    lastModifiedDate: new Date(file.lastModified),
    size: file.size,
    type: file.type,
    webkitRelativePath: file.webkitRelativePath,
    binary: result
  }));
};
export const formatFirstAndLastNames = (
  fName: string | null | undefined,
  lName: string | null | undefined,
  separator = " ",
  fallback?: string
) => {
  return [fName, lName]?.filter((x) => x)?.join(separator) || fallback || "N/A";
};
export const getTabName = (entity: any) => {
  try {
    return (
      `${entity.name ?? "New tab"} ${
        entity.startDate ? new Intl.DateTimeFormat("en-US").format(new Date(entity.startDate)) : ""
      }-${
        entity.endDate
          ? new Intl.DateTimeFormat("en-US").format(new Date(entity.endDate))
          : "Unlimited"
      }` ?? "New tab"
    );
  } catch (e) {
    return "New tab";
  }
};
export function parseMonth(month: number) {
  switch (month) {
    case 1:
      return "January";
    case 2:
      return "February";
    case 3:
      return "March";
    case 4:
      return "April";
    case 5:
      return "May";
    case 6:
      return "June";
    case 7:
      return "July";
    case 8:
      return "August";
    case 9:
      return "September";
    case 10:
      return "October";
    case 11:
      return "November";
    case 12:
      return "December";
  }
}

export function getFirstAndLastDateOfCurrentMonth() {
  // Get first day of current month in UTC
  const firstDayOfMonth = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    1
  ).toISOString();

  // Get last day of current month in UTC
  const lastDayOfMonth = new Date(
    new Date().getFullYear(),
    new Date().getMonth() + 1,
    1
  ).toISOString();
  return {
    from: firstDayOfMonth,
    to: lastDayOfMonth
  };
}
export function getFirstAndLastDateOfGivenYearAndMonth(year: number, month: number) {
  return {
    from: new Date(year, month - 1, 1, 0, 0, 0),
    to: new Date(year, month, 0, 23, 59, 59)
  };
}

export function getFirstAndLastDateOfAYear(year: number) {
  return {
    from: new Date(year, 0, 1, 0, 0, 0),
    to: new Date(year, 12, 0, 23, 59, 59)
  };
}
export const downloadFile = (url: string, fileName: string) => {
  fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      saveAs(blob, fileName);
    })
    .catch((error) => {
      console.error("Error downloading file:", error);
    });
};

export const formatSSN = (ssn?: string) => {
  if (!ssn) return "";
  ssn = ssn.slice(0, 11).replace(/-/g, "");
  if (ssn.length <= 3) {
    return ssn;
  }
  if (ssn.length > 3 && ssn.length <= 5) {
    return `${ssn.slice(0, 3)}-${ssn.slice(3)}`;
  }
  if (ssn.length > 5) {
    return `${ssn.slice(0, 3)}-${ssn.slice(3, 5)}-${ssn.slice(5)}`;
  }
};

export function daysBetweenDateAndNow(dateToCompare: Date) {
  const now = new Date();
  const utcDate1 = Date.UTC(
    dateToCompare.getFullYear(),
    dateToCompare.getMonth(),
    dateToCompare.getDate()
  );
  const utcDate2 = Date.UTC(now.getFullYear(), now.getMonth(), now.getDate());

  const millisecondsPerDay = 24 * 60 * 60 * 1000;
  const timeDifference = utcDate2 - utcDate1;

  const daysDifference = Math.floor(timeDifference / millisecondsPerDay);

  return daysDifference;
}
export function getCurrentQuarterDatesISO() {
  const today = new Date();
  const currentMonth = today.getMonth();
  const quarterStartMonth = Math.floor(currentMonth / 3) * 3;

  const quarterStartDate = new Date(today.getFullYear(), quarterStartMonth, 1);
  const nextQuarterStartDate = new Date(today.getFullYear(), quarterStartMonth + 3, 1);

  const quarterEndDate = new Date(nextQuarterStartDate.getTime() - 1);

  return {
    from: quarterStartDate.toISOString(),
    to: quarterEndDate.toISOString()
  };
}
export function getCurrentQuarterDates() {
  const today = new Date();
  const currentMonth = today.getMonth();
  const quarterStartMonth = Math.floor(currentMonth / 3) * 3;

  const quarterStartDate = new Date(today.getFullYear(), quarterStartMonth, 1);
  const nextQuarterStartDate = new Date(today.getFullYear(), quarterStartMonth + 3, 1);

  const quarterEndDate = new Date(nextQuarterStartDate.getTime() - 1);

  return {
    from: quarterStartDate,
    to: quarterEndDate
  };
}
export function getLastQuarterDates() {
  const today = new Date();
  const currentMonth = today.getMonth();
  const currentQuarter = Math.floor(currentMonth / 3);
  const lastQuarterStartMonth = ((currentQuarter - 1 + 4) % 4) * 3;

  const yearAdjustment = currentQuarter === 0 ? -1 : 0;
  const quarterStartDate = new Date(today.getFullYear() + yearAdjustment, lastQuarterStartMonth, 1);
  const nextQuarterStartDate = new Date(
    quarterStartDate.getFullYear(),
    quarterStartDate.getMonth() + 3,
    1
  );

  const quarterEndDate = new Date(nextQuarterStartDate.getTime() - 1);

  return {
    from: quarterStartDate.toISOString(),
    to: quarterEndDate.toISOString()
  };
}
export const getPercentOfNumber = (value: number, percent: number) =>
  new Big(value || 0).times(new Big(percent || 0).div(100)).toNumber();

export function percentageDifference(current: number, previous: number) {
  const difference = current - previous;
  const percentageDiff = (difference / previous) * 100;

  return percentageDiff;
}
export function removeKeyRecursively(obj: any, keyToRemove: string): any {
  if (typeof obj !== "object" || obj === null) {
    return obj;
  }

  if (keyToRemove in obj) {
    delete obj[keyToRemove];
  }

  for (const prop in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, prop)) {
      if (Array.isArray(obj[prop])) {
        return {
          ...obj,
          [prop]: obj[prop].map((item: any): any => removeKeyRecursively(item, keyToRemove))
        };
      } else {
        return {
          ...obj,
          [prop]: removeKeyRecursively(obj[prop], keyToRemove)
        };
      }
    }
  }

  return obj;
}
export function sanitizeAndConvertToNumber(input: string) {
  const sanitizedInput = input.replace(/[^0-9.]/g, "");

  const number = parseFloat(sanitizedInput);

  return isNaN(number) ? NaN : number;
}
export const totalsDefault = (sign?: string) => (entities: Entry<any>[], column: Column<any>) => {
  if (
    column?.columnAggregation?.totalType === "average" ||
    column.columnAggregation?.funcName === "avg"
  ) {
    const transformedEntities = entities
      .map((entity: any) => {
        const unformattedNumber = (column.getData(entity) as string)
          ?.toString()
          ?.replace(/[^\d.-]/g, "")
          ?.replace(",", "");
        const formattedNumber = parseFloat(unformattedNumber) || 0;
        if (formattedNumber) {
          return {
            count: entity?.data?._ids?.length || 0,
            value: formattedNumber * (entity?.data?._ids?.length || 0)
          };
        }
        return undefined;
      })
      .filter((x) => x) as {
      count: any;
      value: number;
    }[];
    const preResult = transformedEntities.reduce(
      (
        acc: { totalCount: number; totalValue: number },
        entity: { count: number; value: number }
      ) => {
        return {
          totalValue: acc.totalValue + entity.value,
          totalCount: acc.totalCount + entity.count
        };
      },
      { totalCount: 0, totalValue: 0 }
    );
    const result = preResult.totalValue / preResult.totalCount;
    if (sign) {
      return formatNumberAsCurrency(result || 0, sign) ?? "$0.00";
    }
    return formatNumberAsCurrency(result || 0) ?? "0.00";
  } else {
    const total = entities.reduce(function (totalAcc: number, x: Entry<any>) {
      const unformattedNumber = (column.getData(x) as string)
        ?.toString()
        ?.replace(/[^\d.-]/g, "")
        ?.replace(",", "");

      return totalAcc + (parseFloat(unformattedNumber) || 0);
      // return totalAcc + (parseFloat(column.getData(x) as string) || 0);
    }, 0);
    if (sign) {
      return formatNumberAsCurrency(total, sign) ?? "$0.00";
    } else {
      return formatNumberAsCurrency(total) ?? "0.00";
    }
  }
};
