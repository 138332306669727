import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Tooltip
} from "@material-ui/core";
import SettingsIcon from "@material-ui/icons/Settings";
import CloseDialogButton from "components/common/CloseDialogButton";
import React, { useState } from "react";
import {
  fillDefaultsByPath,
  generateDefault,
  generateForm,
  getByPath,
  RenderSet,
  setByPath,
  StateAccess
} from "utils/models/formGenerator";
import { Deal } from "../types";
import { NetsuiteDealStatus } from "./NetsuiteDealStatuses/types";
import { EntityData, getModel } from "utils/entitySlice";
import { useDispatch } from "react-redux";
import { uuid } from "uuidv4";
import { editNetsuiteDealStatus } from "./NetsuiteDealStatuses/editNetsuiteDealStatusSlice";
import { netsuiteDealStatusStruct } from "./NetsuiteDealStatuses/model";
import { addNetsuiteDealStatus } from "./NetsuiteDealStatuses/addNetsuiteDealStatusSlice";
interface Props {
  stateAccess: StateAccess;
  renderSet: RenderSet;
}

export default function NetsuiteIds({ stateAccess, renderSet }: Props) {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const [requestId] = useState(uuid());
  const handleClose = () => {
    setOpen(false);
  };
  const formName = "edit-netsuite-deal-status";
  const deal: Deal = stateAccess.get([]);
  const [formState, setFormState] = useState(
    deal?.data?.netsuiteDealStatus ??
      (generateDefault(
        getModel("netsuite_deal_status") as any,
        {},
        fillDefaultsByPath as any
      ) as NetsuiteDealStatus)
  );
  const netsuiteDealStatusStateAccess: StateAccess = {
    get: (path) => getByPath(formState as any, path),
    set: (path, value): any => {
      setFormState(setByPath(formState as any, path, value));
    }
  };

  const handleSubmit = (state: EntityData<"netsuite_deal_status">) => (event: React.FormEvent) => {
    event.preventDefault();
    event.stopPropagation();
    if (formState?._id) {
      const { _id, createdAt, deleted, ...rest } = state;
      dispatch(editNetsuiteDealStatus({ _id: formState?._id, ...rest, requestId }));
    } else {
      const { _id, createdAt, deleted, ...rest } = state;
      dispatch(
        addNetsuiteDealStatus({
          ...{ data: { ...rest.data, dealId: deal._id } },
          requestId
        })
      );
    }
  };
  return (
    <div style={{ position: "relative" }}>
      <IconButton
        size="small"
        color="primary"
        aria-label="open"
        id={`netsuite-open-settings`}
        style={{ position: "absolute", top: "-33px", left: "90px" }}
        onClick={() => setOpen(true)}
      >
        <Tooltip placement="top" title="Click here to edit the netsuite data.">
          <SettingsIcon />
        </Tooltip>
      </IconButton>

      <Dialog fullWidth onClose={handleClose} open={open}>
        <Grid container alignItems="center" spacing={1} style={{ paddingRight: "4px" }}>
          <Grid item xs={6} key="title">
            <DialogTitle>
              Netsuite Ids
              <CloseDialogButton closeFunction={handleClose} />
            </DialogTitle>
          </Grid>
        </Grid>
        <DialogContent>
          <form id={formName} autoComplete="off" onSubmit={handleSubmit(formState)}>
            {generateForm(
              netsuiteDealStatusStruct,
              netsuiteDealStatusStateAccess,
              [],
              netsuiteDealStatusStateAccess,
              renderSet
            )}
          </form>
        </DialogContent>
        <DialogActions>
          <Button form={formName} autoFocus type="submit" color="primary" variant="contained">
            Save
          </Button>
          <Button autoFocus onClick={handleClose} color="primary" variant="contained">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
