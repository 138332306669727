import {
  RiChat4Line,
  RiChatOffLine,
  RiNotificationLine,
  RiNotificationOffLine
} from "react-icons/ri";
import styles from "./Switch.module.css";

import { Tooltip } from "@material-ui/core";
import React from "react";

type SwitchProps = {
  isActive: boolean;
  toggleFunction: () => void;
};
const Switch = ({ isActive, toggleFunction }: SwitchProps) => {
  return (
    <Tooltip title={isActive ? "Turn off chat" : "Turn on chat"} arrow>
      <div className={styles.switch} onClick={toggleFunction}>
        <div className={styles.ballContainer}>
          <div className={`${styles.ball} ${isActive ? styles.active : styles.inactive}`} />
        </div>
        {isActive ? (
          <RiChat4Line style={{ color: "black" }} size={20} />
        ) : (
          <RiChatOffLine style={{ color: "black" }} size={20} />
        )}
      </div>
    </Tooltip>
  );
};
export const NotificationSwitch = ({ isActive, toggleFunction }: SwitchProps) => {
  return (
    <Tooltip title={isActive ? "Turn off notifications" : "Turn on notifications"} arrow>
      <div className={styles.switch} onClick={toggleFunction}>
        <div className={styles.ballContainer}>
          <div className={`${styles.ball} ${isActive ? styles.active : styles.inactive}`} />
        </div>

        {isActive ? (
          <RiNotificationLine style={{ color: "black" }} size={20} />
        ) : (
          <RiNotificationOffLine style={{ color: "black" }} size={20} />
        )}
      </div>
    </Tooltip>
  );
};

export default Switch;
