import { Grid, Paper } from "@material-ui/core";
import { BarElement, CategoryScale, Chart, LinearScale, LineElement, PointElement } from "chart.js";
import { resolveTier } from "components/Deals/Calculations";
import { Deal } from "components/Deals/types";
import React from "react";
import { Bar, Line } from "react-chartjs-2";
import { Dealership } from "../types";
import styles from "./DealershipShowPage.module.css";
import { getDealershipProgram } from "./PerformaceStatus";
Chart.register(CategoryScale);
Chart.register(LinearScale);
Chart.register(BarElement);
Chart.register(PointElement);
Chart.register(LineElement);

const getMonths = () => {
  return Array.from({ length: 12 }, (_, i) => i + 1);
};

export default ({ dealership, year }: { dealership: Dealership; year: number }) => {
  const statistics = dealership?.data?.statistics ?? [];

  const dataset = getMonths().map((month) => {
    const dealershipProgram = getDealershipProgram(dealership, year, month);

    const tiers = dealershipProgram?.data?.info?.tiers ?? [];
    const contractDate = new Date(year, month - 1, 2);

    const statisticForTheMonth = statistics.find(
      (statistic) =>
        statistic.month === new Date(contractDate).getMonth() + 1 &&
        statistic.year === new Date(contractDate).getFullYear()
    );
    const foundTierIndex = resolveTier(statisticForTheMonth, tiers, "findIndex") as number;

    return Math.max(foundTierIndex || 0, dealershipProgram?.data?.info?.tiers?.length ? 1 : 0);
  });

  const data = {
    labels: getMonths().map((month) => {
      return new Date(year, month - 1, 1).toLocaleString("default", {
        month: "long"
      });
    }),
    datasets: [
      {
        label: "Tier",
        fill: false,
        lineTension: 0.1,
        backgroundColor: "#254e70",
        borderColor: "#254e70",
        borderDash: [],
        borderDashOffset: 0.0,
        pointBorderColor: "#254e70",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "#254e70",
        pointHoverBorderColor: "#254e70",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,

        data: [...dataset, 1, 2, 3]
      }
    ]
  };

  return (
    <Grid lg={12} md={12} sm={12} xs={12} style={{ height: "100%" }}>
      <Paper
        elevation={2}
        style={{ padding: "10px", marginLeft: "10px", height: "calc(100% - 20px)" }}
      >
        <div className={styles.title}>Performance tier by month</div>

        <Bar
          data={data}
          height={100}
          options={{
            scales: {
              y: {
                ticks: {
                  stepSize: 1,
                  // Include a dollar sign in the ticks
                  callback: function (value) {
                    return "Tier: " + value;
                  }
                }
              }
            }
          }}
        />
      </Paper>
    </Grid>
  );
};
