import {
  createSlice,
  PayloadAction,
  SliceCaseReducers,
  ValidateSliceCaseReducers
} from "@reduxjs/toolkit";
import { AppDispatch } from "app/store";
import { TableAggregation } from "components/GroupedTable";
import { TableGroupByItem } from "components/GroupedTable/ColumnGroups/types";
import { PaginateOptions, PaginateResult } from "mongoose";
import { validateSchema } from "validations";
import { RequestsTopics } from "./socketMessageSliceCreator";

interface State<T> {
  [id: string]: T;
}

export interface Normalized {
  createdAt: string;
  _id: string;
  deleted: boolean;
  new?: boolean;
}
export interface CommonListStateData<T> {
  entities?: T[];
  total: number;
  totals?: Record<string, number>;
  totalsAvg?: Record<string, number>;
  totalsListCount?: Record<string, number>;
  totalsListCountAvg?: Record<string, number>;
  totalsListCountUnique?: Record<string, number>;
  totalsListCountUniqueAvg?: Record<string, number>;
  status?: string;
  hash?: string;
  columnAggs?: any;
}
export type ListState<T> = CommonListStateData<T> & {
  comparison?: CommonListStateData<T>;
};

type CommonPaginateResult<T> = {
  totalDocs: number;
  columnAggs: any;
  totalPages: number;
  totals?: Record<string, number>;
  totalsAvg?: Record<string, number>;
  totalsListCount?: Record<string, number>;
  totalsListCountAvg?: Record<string, number>;
  totalsListCountUnique?: Record<string, number>;
  totalsListCountUniqueAvg?: Record<string, number>;
  pagingCounter: number;
  hasPrevPage: boolean;
  hasNextPage: boolean;
  prevPage: null;
  nextPage: null;
};
type PaginatedPayload<T> = Required<PaginateResult<T>> &
  CommonPaginateResult<T> & {
    comparison?: Required<PaginateResult<T>> & CommonPaginateResult<T>;
  };

export interface ListPayload<T> {
  message: PaginatedPayload<
    Exclude<T, "id" | "createdAt"> & {
      _id: string;
      createdAt: string;
      updatedAt: string;
      deleted: boolean;
    }
  >;
  status: string;
  id: string;
}
export interface getListProps {
  options?: PaginateOptions & {
    tableAggregation?: TableAggregation[];
    tableGroupBy?: TableGroupByItem[];
  };
  withDeleted?: boolean;
  queryParams?: Record<string, unknown>;
  query?: Record<string, unknown>;
  search?: { term: string };
  aggregateFirst?: boolean;
}

export const createListSlice = <
  EntityType,
  Reducers extends SliceCaseReducers<State<ListState<EntityType & Normalized>>> = SliceCaseReducers<
    State<ListState<EntityType & Normalized>>
  >
>({
  name,
  reducers,
  request_topic
}: {
  name: string;
  reducers: ValidateSliceCaseReducers<State<ListState<EntityType & Normalized>>, Reducers>;
  request_topic: RequestsTopics;
}) => {
  const initialStateForKey: () => ListState<EntityType & Normalized> = () => ({
    // TODO: some error management
    entities: undefined,
    total: 0,
    totals: undefined,
    status: undefined
  });

  const initialState: State<ListState<EntityType & Normalized>> = { table: initialStateForKey() };

  const ListSlice = createSlice({
    name: name,
    initialState,
    reducers: {
      // TODO: this undefined "data" from the backend should not be empty
      list: {
        reducer: (
          state: State<ListState<EntityType & Normalized>>,
          action: PayloadAction<ListPayload<EntityType>>
        ) => {
          if (
            // @ts-ignore
            action?.hash &&
            // @ts-ignore
            state[action.payload.id]?.hash &&
            // @ts-ignore
            action?.hash !== state[action.payload.id]?.hash
          ) {
            console.log("skipped", action.payload.id);
            return;
          }
          if (state[action.payload.id] === undefined) {
            state[action.payload.id] = initialStateForKey();
          }
          state[action.payload.id].entities = action.payload.message.docs;
          state[action.payload.id].total = action.payload.message.totalDocs;
          state[action.payload.id].totals = action.payload.message.totals;
          state[action.payload.id].totalsAvg = action.payload.message.totalsAvg ?? undefined;
          state[action.payload.id].totalsListCount =
            action.payload.message.totalsListCount ?? undefined;
          state[action.payload.id].totalsListCountAvg =
            action.payload.message.totalsListCountAvg ?? undefined;
          state[action.payload.id].totalsListCountUnique =
            action.payload.message.totalsListCountUnique ?? undefined;
          state[action.payload.id].totalsListCountUniqueAvg =
            action.payload.message.totalsListCountUniqueAvg ?? undefined;
          state[action.payload.id].columnAggs = action.payload.message.columnAggs;
          state[action.payload.id].status = action.payload.status;
          if (action.payload.message.comparison) {
            state[action.payload.id].comparison = {
              entities: action.payload.message?.comparison?.docs,
              total: action.payload.message?.comparison?.totalDocs,
              totals: action.payload.message?.comparison?.totals ?? undefined,
              totalsAvg: action.payload.message?.comparison?.totalsAvg ?? undefined,
              totalsListCount: action.payload.message?.comparison?.totalsListCount ?? undefined,
              totalsListCountAvg:
                action.payload.message?.comparison?.totalsListCountAvg ?? undefined,
              totalsListCountUnique:
                action.payload.message?.comparison?.totalsListCountUnique ?? undefined,
              totalsListCountUniqueAvg:
                action.payload.message?.comparison?.totalsListCountUniqueAvg ?? undefined,
              columnAggs: action.payload.message?.comparison?.columnAggs,
              status: action.payload.status
            };
          }
        },
        prepare(payload: ListPayload<EntityType>) {
          return { payload };
        }
      },
      setWaiting: (
        state: State<ListState<EntityType & Normalized>>,
        action: PayloadAction<string>
      ) => {
        if (state[action.payload] === undefined) {
          state[action.payload] = initialStateForKey();
        }
        state[action.payload].status = "waiting";
        // @ts-ignore
        state[action.payload].hash = action?.hash;
      },
      removeList: (
        state: State<ListState<EntityType & Normalized>>,
        action: PayloadAction<string>
      ) => {
        delete state[action.payload];
      },
      editList: (state: State<ListState<EntityType & Normalized>>, action: any) => {
        if (state[action.payload.listId] && state[action.payload.listId].entities)
          // @ts-ignore
          state[action.payload.listId].entities[action.payload.index] = action.payload.payload;
      },
      addToList: (state: State<ListState<EntityType & Normalized>>, action: any) => {
        if (state[action.payload.listId] && state[action.payload.listId].entities) {
          if (Array.isArray(action.payload.payload)) {
            if (action.payload.payload.length > 200) {
              state[action.payload.listId].entities = [...action.payload.payload.slice(0, 200)];
            } else {
              state[action.payload.listId].entities = [
                ...action.payload.payload,
                // @ts-ignore,
                ...state[action.payload.listId].entities?.slice(
                  action.payload.payload.length,
                  200 - action.payload.payload.length
                )
              ];
            }

            state[action.payload.listId].total += action.payload.payload.length;
          } else if (
            !state[action.payload.listId].entities?.find(
              (entry) => entry._id === action.payload.payload._id
            )
          ) {
            if (action.payload.addToEnd) {
              state[action.payload.listId].entities = [
                // @ts-ignore,
                ...state[action.payload.listId].entities,
                action.payload.payload
              ];
              state[action.payload.listId].total += 1;
            } else {
              state[action.payload.listId].entities = [
                action.payload.payload,
                // @ts-ignore,
                ...state[action.payload.listId].entities
              ];
              state[action.payload.listId].total += 1;
            }
          }
        }
      },
      replaceOrCreateList: (state: State<ListState<EntityType & Normalized>>, action: any) => {
        if (Array.isArray(action.payload.payload)) {
          state[action.payload.listId].total = action.payload.payload.length;
          state[action.payload.listId].entities = [...action.payload.payload];
        }
      },
      removeNewFromList: (state: State<ListState<EntityType & Normalized>>, action: any) => {
        state[action.payload.listId].entities = state[action.payload.listId].entities?.map((x) => ({
          ...x,
          new: undefined
        }));
      },
      updateManyPartial: (state: State<ListState<EntityType & Normalized>>, action: any) => {
        if (state[action.payload.listId].entities) {
          const oldEntities = state[action.payload.listId]?.entities?.slice();
          if (action.payload.listId && oldEntities) {
            action.payload.updateOperations.forEach((element: any) => {
              const foundIndex = oldEntities?.findIndex((el) => element._id === el._id);
              if (foundIndex !== -1 && oldEntities) {
                oldEntities[foundIndex] = element.updateFunction(oldEntities[foundIndex]);
              }
            });
            if (typeof action.payload.sortFunction === "function") {
              oldEntities.sort(action.payload.sortFunction);
              state[action.payload.listId].entities = oldEntities;
            } else {
              state[action.payload.listId].entities = oldEntities;
            }
          }
        }
      },
      clearList: (
        state: State<ListState<EntityType & Normalized>>,
        action: PayloadAction<string>
      ) => {
        state[action.payload] = initialStateForKey();
      },
      listError: {
        reducer: (
          state: State<ListState<EntityType & Normalized>>,
          action: PayloadAction<{ _id: string; error: string }>
        ) => {
          // alert(action.payload);
          console.log(action.payload);
          if (action.payload) state[action.payload._id] = initialStateForKey();
        },
        prepare(payload: string) {
          return { payload };
        }
      },
      ...reducers
    }
  });

  const getListAction = (
    id: string,
    {
      options = {},
      withDeleted = false,
      aggregateFirst,
      query = {},
      queryParams = {},
      search
    }: getListProps = {}
  ) => {
    const request = {
      aggregateFirst,
      with_deleted: withDeleted,
      ...(search ? { search } : { query }),
      ...(options ? { options } : {}),
      ...(Object.keys(queryParams).length > 0 ? { queryParams } : {})
    };
    const { valid, errors } = validateSchema(request_topic as any, request);
    if (valid) {
      return {
        type: `${name}/get`,
        name,
        id,
        payload: request,
        http: {
          path: request_topic,
          success: {
            // @ts-ignore
            type: ListSlice.actions.list.type,
            additionalOptions: { id }
          },
          error: {
            // @ts-ignore
            type: ListSlice.actions.listError.type
          }
        },
        socket: {
          topic: request_topic,
          success: {
            // @ts-ignore
            type: ListSlice.actions.list.type,
            additionalOptions: { id }
          },
          error: {
            // @ts-ignore
            type: ListSlice.actions.listError.type
          }
        }
      };
    }
    return {
      type: (ListSlice.actions.listError as any).type,
      payload: { id, message: errors?.[0].message ?? null }
    };
  };

  const getList = (id: string, getListProps: getListProps = {}) => async (
    dispatch: AppDispatch
  ) => {
    dispatch(getListAction(id, getListProps));
  };

  return { ListSlice, getList, getListAction };
};
