import { Grid, Typography } from "@material-ui/core";
import AccessControl from "components/Access/AccessControl";
import ContractsPreview from "components/Contracts/ContractsPreview";
import CustomerCareNotesPreview from "components/CustomerCareNotes/CustomerCareNotesPreview";
import DealershipProgramPreview from "components/DealershipPrograms/DealershipProgramPreview";
import StatisticsPreview from "components/DealershipStatistics/StatisticsPreview";
import GenerateLink from "components/Dealerships/GenerateLink";
import UploadLogoPreview from "components/Dealerships/Logo/UploadLogoPreview";
import Deals from "components/Deals/Deals";
import { collateralTypes } from "components/Deals/types";
import StaticMap from "components/GoogleStaticMap/StaticMap";
import TitleIssues from "components/TitleIssues/TitleIssues";
import UserPreview from "components/Users/UserPreview";
import WidgetButton from "components/WhiteLabelWidget/WidgetButton";
import React from "react";
import formatDate from "utils/formatDate";
import { phoneWithBracesWithoutCode } from "utils/functions";
import { HTML_VIRTUAL_FIELD_SPLITTER } from "utils/htmlVirtualFieldsSplitter";
import { RenderSet, StateAccess } from "utils/models/formGenerator";
import States from "../../us-states";
import { getStateLabelByState, removeCountryCodeAndBracesFromPhone } from "../../utils/functions";
import { FormComponent, Model, createOptionsForSelect } from "../../utils/models/fields";
import { userStruct } from "../Users/model";
import AddressPreview from "../ZipCodeLookup/AddressPreview";
import AdfSettings from "./AdfSettings";
import DealershipStatusHistory from "./DealershipStatusHistory";
import EmailVerificationLink from "./EmailVerificationLink";
import SendGeneratedLink from "./SendGeneratedLink";
import ShippingAddress from "./ShippingAddress";
import StepsToCreateDealerAccount from "./StepsToCreateDealerAcocunt";
import Suspended from "./Suspended";
import {
  ApplicationParserType,
  Contact,
  Dealership,
  DealershipProgramWithPeriods,
  Note
} from "./types";
import { clearPhone } from "components/DocumentTemplates/PrintTemplate";

const titleManagementRestrictions = (): FormComponent<Dealership> => ({
  formComponent: "segment",
  width: "full",
  name: "Title Management",
  entities: [
    {
      formComponent: "segment",
      width: "1/3",
      name: "Monitoring",
      entities: [
        {
          formComponent: "date-field",
          name: "titleDate",
          label: "Title Date",
          width: "1/2",
          path: ["data", "info", "titleDate"],
          default: null
        },
        {
          formComponent: "number-field",
          name: "Outstanding documents limit",
          label: "Limit for Missing Title Documents (Penalty box)",
          path: ["data", "info", "titleManagement", "outstandingTitlesApplicationsLimit"],
          width: "1/2",
          required: (stateAccess) =>
            stateAccess.get(["data", "info", "titleManagement", "isRestricted"]) === true,
          default: 10
        }
      ]
    },
    {
      formComponent: "segment",
      width: "2/3",
      name: "Restrictions",
      entities: [
        {
          formComponent: "checkbox-field",
          name: "isRestricted",
          label: "Is Restricted?",
          width: "1/4",
          valueType: "boolean",
          path: ["data", "info", "titleManagement", "isRestricted"],
          isDisabled: (): boolean => false,
          isChecked: (stateAccess: StateAccess): boolean =>
            stateAccess.get<Dealership>(["data", "info", "titleManagement", "isRestricted"]),
          toggle: (stateAccess: StateAccess) => {
            const titleManagement = stateAccess.get<Dealership>([
              "data",
              "info",
              "titleManagement"
            ]);

            stateAccess.set<Dealership>(["data", "info", "titleManagement"], {
              ...titleManagement,
              isRestricted: !titleManagement?.isRestricted,
              outstandingTitlesFundingLimit: 2,
              outstandingTitlesApplicationsLimit: 10
            });
          },

          required: false,
          default: false
        },
        {
          formComponent: "one-to-many-field",
          show: (stateAccess) =>
            stateAccess.get(["data", "info", "titleManagement", "isRestricted"]),
          component: (stateAccess: StateAccess) => (
            <Suspended titleManagement={stateAccess.get(["data", "info", "titleManagement"])} />
          ),
          name: "Suspended",
          label: "Suspended",
          width: "1/4",
          path: [],
          default: null
        },
        {
          formComponent: "number-field",
          name: "Outstanding Titles Funding Limit",
          label: "Limit for Missing Title Documents (Halts Funding)",
          show: (stateAccess) =>
            stateAccess.get(["data", "info", "titleManagement", "isRestricted"]),
          path: ["data", "info", "titleManagement", "outstandingTitlesFundingLimit"],

          width: "1/4",
          required: true,
          default: 2
        },

        {
          formComponent: "date-field",
          name: "restrictionDate",
          label: "Restriction Date",
          width: "1/4",
          show: (stateAccess) =>
            stateAccess.get(["data", "info", "titleManagement", "isRestricted"]),
          path: ["data", "info", "titleManagement", "restrictionDate"],
          default: null,
          required: true
        }
      ]
    }
  ]
});
const getLocationStruct = (zipCodeSearch: () => void): FormComponent<Dealership> => ({
  formComponent: "segment",
  entities: [
    {
      formComponent: "zip-code-field",
      name: "zipCode",
      label: "Zip Code",
      width: "1/4",
      path: ["data", "info", "zipCode"],
      zipCodeSearch: zipCodeSearch,
      default: null
    },
    {
      formComponent: "city-field",
      name: "city",
      label: "City",
      width: "1/4",
      path: ["data", "info", "city"],
      default: null
    },
    {
      formComponent: "select-field",
      name: "state",
      label: "State",
      width: "1/4",
      path: ["data", "info", "state"],
      options: createOptionsForSelect({
        possibleValues: () => Object.keys(States),
        getOptionLabel: (x) => getStateLabelByState(x),
        getSelectedOption: (x, y) => x === y
      }),
      default: null
    },
    {
      formComponent: "select-field",
      name: "county",
      label: "County",
      width: "1/4",
      autoSelect: true,
      path: ["data", "info", "county"],
      options: createOptionsForSelect({
        possibleValues: (stateAccess: StateAccess) =>
          States[stateAccess.get<Dealership>(["data", "info", "state"]) as string]?.counties,
        getOptionLabel: (x) => x ?? " ",
        getSelectedOption: (x, y) => x === y
      }),
      default: null
    }
  ]
});
const dealershipInfo = (isNew: boolean): FormComponent<Dealership> => {
  return {
    formComponent: "segment",
    entities: [
      {
        formComponent: "one-to-many-field",
        struct: () => userStruct,
        component: (
          stateAccess: StateAccess,
          mainstateAccess: StateAccess,
          renderSet: RenderSet
        ) => (
          <UserPreview
            type="representative"
            stateAccess={stateAccess}
            renderSet={renderSet}
            path={["data", "representative"]}
            name="representative"
            label="Representative"
          />
        ),
        name: "representative",
        label: "Representative",
        width: "1/6",
        path: ["data", "representative"],
        default: null,
        valueToString: (el) => el.firstName
      },
      ...(isNew
        ? [
            {
              formComponent: "one-to-many-field",
              component: (stateAccess: StateAccess) => <WidgetButton stateAccess={stateAccess} />,
              width: "1/6",
              name: "widgets",
              label: "Widget",
              path: null,
              default: null
            } as FormComponent<Dealership>
          ]
        : []),
      {
        formComponent: "one-to-many-field",
        component: (stateAccess: StateAccess) => <UploadLogoPreview stateAccess={stateAccess} />,
        width: "2/3",
        name: "logo",
        label: "Logo",
        path: ["data", "info", "logo"],
        default: null
      },
      {
        formComponent: "checkbox-field",
        name: "ShowDealershipPlatform",
        label: "Show Dealership Platform",
        width: "1/4",
        valueType: "boolean",
        path: ["data", "info", "isPlatform"],
        isDisabled: (): boolean => false,
        isChecked: (stateAccess: StateAccess): boolean =>
          stateAccess.get<Dealership>(["data", "info", "isPlatform"]),
        toggle: (stateAccess: StateAccess): Dealership =>
          stateAccess.set<Dealership>(
            ["data", "info", "isPlatform"],
            !stateAccess.get<Dealership>(["data", "info", "isPlatform"])
          ),
        required: false,
        default: false
      },
      {
        formComponent: "segment",
        name: "Dealership platform",
        show: (stateAccess: StateAccess) => stateAccess.get(["data", "info", "isPlatform"]),
        width: "full",
        entities: [
          {
            formComponent: "text-field",
            name: "firstName",
            label: "First name",
            path: ["data", "info", "adminFirstName"],
            width: "1/6",
            default: null,
            required: true
          },
          {
            formComponent: "text-field",
            name: "lastName",
            label: "Last name",
            path: ["data", "info", "adminLastName"],
            width: "1/6",
            default: null,
            required: true
          },
          {
            formComponent: "email-field",
            name: "adminEmail",
            label: "Admin email",
            path: ["data", "info", "adminEmail"],
            width: "1/6",
            default: null,
            required: true
          },
          {
            formComponent: "read-only-date-field",
            name: "lastlogin",
            label: "Last login",
            width: "1/6",
            path: ["data", "info", "adminLastLogin"],
            required: false,
            default: null
          },
          {
            formComponent: "text-field",
            name: "dealerPlatformNameForDealershipPlatform",
            label: "Dealership Name For Dealership Platform",
            width: "1/6",
            path: ["data", "info", "dealershipNameForDealershipPlatform"],
            required: false,
            default: null
          },
          {
            formComponent: "segment",
            width: "full",
            entities: [
              {
                formComponent: "segment",
                width: "1/2",
                entities: [
                  {
                    formComponent: "segment",
                    width: "full",
                    entities: [
                      {
                        formComponent: "one-to-many-field",
                        show: (stateAccess: StateAccess) => stateAccess.get(["_id"]),
                        name: "GenerateLink",
                        width: "full",
                        label: "GenerateLink",
                        path: [],
                        component: (stateAccess: StateAccess) => (
                          <GenerateLink stateAccess={stateAccess} />
                        )
                      }
                    ]
                  },
                  {
                    formComponent: "segment",
                    width: "full",
                    entities: [
                      {
                        formComponent: "one-to-many-field",
                        name: "SendGeneratedLink",
                        show: (stateAccess: StateAccess) =>
                          stateAccess.get(["_id"]) &&
                          stateAccess.get(["data", "info", "adminRegistrationLink"]),
                        width: "full",
                        label: "SendGeneratedLink",
                        path: [],
                        component: (stateAccess: StateAccess) => (
                          <SendGeneratedLink stateAccess={stateAccess} />
                        )
                      }
                    ]
                  },
                  {
                    formComponent: "segment",
                    width: "full",
                    entities: [
                      {
                        formComponent: "one-to-many-field",
                        show: (stateAccess: StateAccess) => stateAccess.get(["_id"]),
                        name: "EmailVerificationLink",
                        width: "full",
                        label: "EmailVerificationLink",
                        path: [],
                        component: (stateAccess: StateAccess) => (
                          <EmailVerificationLink stateAccess={stateAccess} />
                        )
                      }
                    ]
                  },
                  {
                    formComponent: "segment",
                    width: "full",
                    entities: [
                      {
                        formComponent: "one-to-many-field",
                        show: (stateAccess: StateAccess) => stateAccess.get(["_id"]),
                        name: "AdfSettings",
                        width: "full",
                        label: "AdfSettings",
                        path: [],
                        component: (stateAccess: StateAccess) => (
                          <AdfSettings
                            dealershipSettings={stateAccess.get(["data", "dealershipSettings"])}
                          />
                        )
                      }
                    ]
                  }
                ]
              },
              {
                formComponent: "segment",
                width: "1/2",
                entities: [
                  {
                    formComponent: "one-to-many-field",
                    name: "stepsToCreateDealerAccount",
                    show: (stateAccess: StateAccess) =>
                      stateAccess.get(["_id"]) &&
                      stateAccess.get(["data", "info", "adminRegistrationLink"]),
                    width: "full",
                    label: "Steps to create dealer account",
                    path: [],
                    component: (stateAccess: StateAccess) => <StepsToCreateDealerAccount />
                  }
                ]
              }
            ]
          },
          {
            formComponent: "select-field",
            options: createOptionsForSelect({
              possibleValues: () => [...Object.values(ApplicationParserType), undefined],
              getOptionLabel: (x) => (!x ? "Parser disabled" : x)
            }),
            name: "Application parser type",
            label: "Application parser type",
            width: "1/2",
            path: ["data", "info", "applicationParserType"],
            default: null
          }
        ]
      },

      {
        formComponent: "segment",
        name: "Dealership Info",
        width: "full",
        entities: [
          {
            formComponent: "segment",
            width: "full",
            entities: [
              {
                formComponent: "text-field",
                name: "name",
                label: "Domain Name",
                width: "1/3",
                path: ["data", "info", "domain"],
                default: null,
                required: true
              }
            ]
          },
          {
            formComponent: "text-field",
            name: "name",
            label: "Legal Name",
            width: "1/3",
            path: ["data", "info", "name"],
            default: null,
            required: true
          },
          {
            formComponent: "checkbox-field",
            name: "uniqueDisplayName",
            label: "Unique display name?",
            width: "1/6",
            valueType: "boolean",
            path: ["data", "info", "uniqueDisplayName"],
            isDisabled: () => false,
            isChecked: (stateAccess: StateAccess) =>
              stateAccess.get<Dealership>(["data", "info", "uniqueDisplayName"]),
            toggle: (stateAccess: StateAccess) =>
              stateAccess.set<Dealership>(
                ["data", "info", "uniqueDisplayName"],
                !stateAccess.get<Dealership>(["data", "info", "uniqueDisplayName"])
              ),
            default: false
          },
          {
            formComponent: "text-field",
            name: "displayName",
            label: "Display Name",
            width: "1/4",
            path: ["data", "info", "displayName"],
            default: null,
            required: false,
            show: (stateAccess) =>
              stateAccess.get<Dealership>(["data", "info", "uniqueDisplayName"])
          },
          {
            formComponent: "read-only-field",
            name: "displayName",
            label: "Display Name",
            width: "1/4",
            path: ["data", "info", "displayName"],
            default: null,
            required: false,
            show: (stateAccess) =>
              !stateAccess.get<Dealership>(["data", "info", "uniqueDisplayName"])
          },
          {
            formComponent: "read-only-field",
            name: "BMO ID",
            label: "BMO ID",
            width: "1/4",
            path: ["data", "info", "abbreviation"],
            default: null,
            required: true
          },
          {
            formComponent: "virtual-field",
            name: "name_webfinancedirect",
            label: "DBA Name / Web Finance Direct",
            value: (stateAccess) =>
              `${stateAccess.get(["data", "info", "displayName"])} / Web Finance Direct`,
            description: "Displays Dealership dba name / Web Finance Direct",
            path: ["data", "info", "dealership_webfinancedirect"],
            default: null
          },
          {
            formComponent: "address-field",
            name: "address",
            label: "Address",
            path: ["data", "info", "address"],
            width: "1/3",
            default: null
          },
          {
            formComponent: "one-to-many-field",
            component: (stateAccess, _mainStateAccess, renderSet): JSX.Element => (
              <ShippingAddress stateAccess={stateAccess} renderSet={renderSet} />
            ),
            name: "Shipping Address preview",
            width: "1/3",
            path: []
          },
          {
            formComponent: "address-field",
            name: "secondaryAddress",
            label: "Address 2",
            path: ["data", "info", "secondaryAddress"],
            width: "1/3",
            default: null
          },
          {
            formComponent: "one-to-many-field",
            component: (stateAccess, _mainStateAccess, renderSet): JSX.Element => (
              <AddressPreview
                stateAccess={stateAccess}
                renderSet={renderSet}
                getStruct={getLocationStruct}
                zipCodePath={["data", "info", "zipCode"]}
                addressSetter={(stateAccess, address) =>
                  stateAccess.set<Dealership>(["data", "info"], {
                    ...stateAccess.get<Dealership>(["data", "info"]),
                    state: address.state,
                    city: address.city
                  })
                }
              />
            ),
            name: "Address preview",
            width: "full",
            path: [],
            struct: getLocationStruct(() => undefined)
          },

          {
            formComponent: "virtual-field",
            name: "fullAddressWithLocation",
            label: "Full Address with Location",
            value: (stateAccess) => {
              const info: Dealership["data"]["info"] = stateAccess.get<Dealership>([
                "data",
                "info"
              ]);

              return [
                info?.address,
                info?.city,
                [States[info?.state]?.prefix, info?.zipCode].filter((x) => x).join(" ")
              ]
                .filter((x) => x)
                .join(", ");
            },
            path: ["data", "info", "fullAddressWithLocation"],
            description:
              "Displays the address on one line with location.<br/>Example: 6370 Concord Blvd S, Heights, La Crosse, WI",
            default: null
          },

          {
            formComponent: "select-field",
            options: createOptionsForSelect({
              possibleValues: () => ["fulltimeF&I", "correspondent"],
              getOptionLabel: (x) => (x === "fulltimeF&I" ? "Full Time F&I" : "Correspondent")
            }),
            name: "relationType",
            label: "Relation type",
            required: true,
            width: "1/2",
            path: ["data", "info", "relationType"],
            default: null
          },

          {
            formComponent: "virtual-field",
            name: "StatePrefix",
            label: "State prefix",
            value: (stateAccess) => {
              const info: Dealership["data"]["info"] = stateAccess.get<Dealership>([
                "data",
                "info"
              ]);

              return States[info?.state]?.prefix ?? "";
            },
            description: "Displays state prefix. Example: WI",
            path: ["data", "info", "statePrefix"],
            default: null
          },
          {
            formComponent: "virtual-field",
            name: "nameAndAddressWithLocationAndPhone",
            label: "DBA Name, address with location and phone",
            value: (stateAccess) => {
              const info: Dealership["data"]["info"] = stateAccess.get<Dealership>([
                "data",
                "info"
              ]);
              const thirdLine = [
                (info?.city ?? "") + ",",
                States[info?.state]?.prefix ?? "",
                info?.zipCode ?? ""
              ].join(" ");
              return `${info?.displayName ?? ""}${HTML_VIRTUAL_FIELD_SPLITTER}${
                info?.address ?? ""
              }${HTML_VIRTUAL_FIELD_SPLITTER}${
                thirdLine ?? ""
              }${HTML_VIRTUAL_FIELD_SPLITTER}${phoneWithBracesWithoutCode(
                info?.phone?.toString() ?? ""
              )}`;
            },
            description:
              "Displays the DBA name, the address and phone on FOUR lines.<br/> Example: <br/><br/> 1st Class Auto Sales LLC<br/> 6370 Concord Blvd S<br/>Heights, La Crosse, WI<br/>(763) 478-8811",
            path: ["data", "info", "nameAndAddressWithLocationAndPhone"],
            default: null
          },
          {
            formComponent: "virtual-field",
            name: "nameAndAddressWithLocation",
            label: "DBA Name and address with location",
            value: (stateAccess) => {
              const info: Dealership["data"]["info"] = stateAccess.get<Dealership>([
                "data",
                "info"
              ]);
              const thirdLine = [info?.city + ",", States[info?.state]?.prefix, info?.zipCode].join(
                " "
              );
              return `${info?.displayName ?? ""}${HTML_VIRTUAL_FIELD_SPLITTER}${
                info?.address ?? ""
              }${HTML_VIRTUAL_FIELD_SPLITTER}${thirdLine ?? ""}`;
            },
            description:
              "Displays the name and the address on THREE lines.<br/> Example: <br/><br/> 1st Class Auto Sales LLC<br/> 6370 Concord Blvd S<br/>Heights, La Crosse, WI",
            path: ["data", "info", "nameAndAddressWithLocation"],
            default: null
          },
          {
            formComponent: "phone-field",
            name: "phone",
            label: "Phone",
            path: ["data", "info", "phone"],
            width: "1/4",
            default: null
          },

          {
            formComponent: "virtual-field",
            name: "phoneWithoutCountryCode",
            label: "Phone without country code",
            value: (stateAccess) => {
              const phoneNumber = stateAccess.get(["data", "info", "phone"]);
              return phoneNumber ? removeCountryCodeAndBracesFromPhone(phoneNumber) : "";
            },
            path: ["data", "info", "phoneWithoutCountryCode"],
            description: "Displays the phone number without country code and ()",
            default: null
          },
          {
            formComponent: "virtual-field",
            name: "mobilePhoneFirstPart",
            label: "Mobile phone first part - (XXX)",
            value: (stateAccess) => {
              const phoneNumber = stateAccess.get(["data", "info", "phone"]);
              const clearedPhone = clearPhone(phoneNumber ?? "");

              return clearedPhone ? clearedPhone.slice(0, 3) : "";
            },
            path: ["data", "info", "mobilePhoneFirstPart"],
            description: "Mobile phone first part - (XXX)",
            default: null
          },
          {
            formComponent: "virtual-field",
            name: "mobilePhoneSecondPart",
            label: "Mobile phone second part - XXX",
            value: (stateAccess) => {
              const phoneNumber = stateAccess.get(["data", "info", "phone"]);
              const clearedPhone = clearPhone(phoneNumber ?? "");
              return clearedPhone ? clearedPhone.slice(3, 6) : "";
            },
            path: ["data", "info", "mobilePhoneSecondPart"],
            description: "Mobile phone second part - XXX",
            default: null
          },
          {
            formComponent: "virtual-field",
            name: "mobilePhoneThirdPart",
            label: "Mobile phone third part - XXXX",
            value: (stateAccess) => {
              const phoneNumber = stateAccess.get(["data", "info", "phone"]);
              const clearedPhone = clearPhone(phoneNumber ?? "");
              return clearedPhone ? clearedPhone.slice(6, 10) : "";
            },
            path: ["data", "info", "mobilePhoneThirdPart"],
            description: "Mobile phone third part - XXXX",
            default: null
          },
          {
            formComponent: "phone-field",
            name: "faxNumber",
            label: "Fax number",
            width: "1/4",
            path: ["data", "info", "faxNumber"],
            default: null
          },

          titleManagementRestrictions(),
          {
            formComponent: "one-to-many-field",
            path: [],
            name: "Map",
            show: (stateAccess) => stateAccess.get(["data", "info", "coordinates"]),
            component: (stateAccess) => {
              const dealership: Dealership = stateAccess.get([]);
              const street = dealership?.data?.info?.address;
              const state = dealership?.data?.info?.state;
              const city = dealership?.data?.info?.city;
              const coordinates = dealership?.data?.info?.coordinates as {
                lat: number;
                lon: number;
              };

              return (
                <StaticMap
                  type="dealership"
                  street={street}
                  state={state}
                  city={city}
                  coordinates={coordinates}
                />
              );
            },
            width: "full"
          },
          {
            formComponent: "one-to-many-field",
            path: [],
            name: "Status",
            component: (stateAccess) => {
              const dealership: Dealership = stateAccess.get([]);
              return <DealershipStatusHistory dealership={dealership} />;
            },
            width: "full",
            show: () => isNew
          }
        ]
      },
      {
        formComponent: "segment",
        width: "full",
        name: "Customer care",
        entities: [
          {
            formComponent: "one-to-many-field",
            struct: () => userStruct,
            component: (
              stateAccess: StateAccess,
              mainstateAccess: StateAccess,
              renderSet: RenderSet
            ) => (
              <UserPreview
                stateAccess={stateAccess}
                renderSet={renderSet}
                path={["data", "customerCareAssignee"]}
                name="customerCareAssignee"
                label="Customer care assignee"
              />
            ),
            name: "customerCareAssignee",
            label: "Customer care assignee",
            width: "1/4",
            path: ["data", "customerCareAssignee"],
            default: null,
            valueToString: (el) => el.firstName
          },
          {
            formComponent: "segment",
            width: "full",
            entities: [
              {
                formComponent: "one-to-many-field",
                component: (stateAccess) => (
                  <AccessControl
                    requiredPermissions={{
                      entity: "dealer_note",
                      action: isNew ? "create" : "update"
                    }}
                  >
                    <CustomerCareNotesPreview
                      customerCareNotes={stateAccess.get(["data", "customerCareNotes"]) ?? []}
                      repEmail={
                        stateAccess.get(["data", "representative", "data", "info", "email"]) ?? []
                      }
                      dealershipId={stateAccess.get(["_id"])}
                    />
                  </AccessControl>
                ),
                path: null,
                name: "Customer care notes",
                width: "1/3"
              }
            ]
          }
        ]
      },
      {
        formComponent: "segment",
        width: "1/4",
        name: "Activity warning settings",
        entities: [
          {
            formComponent: "one-to-many-field",
            name: "labelforNewDealers",
            path: [],
            width: "full",
            component: (stateAccess) => {
              return (
                <Typography>
                  Note: Dealers are being checked only if the <strong>Approved At</strong> date is
                  older than two weeks than the current date.
                </Typography>
              );
            }
          },
          {
            formComponent: "number-field",
            name: "reCheckDays",
            label: "Re-check dealership every X days",
            width: "1/2",
            default: 60,
            path: ["data", "info", "activityWarning", "reCheckDays"]
          },
          {
            formComponent: "one-to-many-field",
            path: [],
            width: "1/2",
            name: "warningInfo",
            component: (stateAccess) => {
              const dealership: Dealership = stateAccess.get([]);
              const lastCheckDateString =
                dealership?.data?.info?.activityWarning?.checkedOrSnoozedDate;
              const snoozeType = dealership?.data?.info?.activityWarning?.snoozeType;
              let snoozedUntil: Date | undefined;
              const getSnoozeDate = (
                checkedDate: Date,
                snooze: "twoWeeks" | "oneMonth" | "twoMonths"
              ) => {
                switch (snooze) {
                  case "twoWeeks":
                    return new Date(checkedDate.setDate(checkedDate.getDate() + 14));
                  case "oneMonth":
                    return new Date(checkedDate.setDate(checkedDate.getDate() + 30));
                  case "twoMonths":
                    return new Date(checkedDate.setDate(checkedDate.getDate() + 60));
                }
              };
              if (lastCheckDateString && snoozeType) {
                snoozedUntil = getSnoozeDate(new Date(lastCheckDateString), snoozeType);
              }
              return (
                <Grid container>
                  <Grid item container xs={12}>
                    <Grid item xs={6}>
                      <Typography>Last marked as checked date:</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography>
                        {lastCheckDateString
                          ? formatDate(lastCheckDateString, "medium")
                          : "Not checked"}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item container xs={12}>
                    <Grid item xs={6}>
                      <Typography>Is snoozed:</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography>{snoozeType ? "Yes" : "No"}</Typography>
                    </Grid>
                  </Grid>
                  {snoozedUntil ? (
                    <Grid item container xs={12}>
                      <Grid item xs={6}>
                        <Typography>Snoozed until:</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography>{formatDate(snoozedUntil, "medium")}</Typography>
                      </Grid>
                    </Grid>
                  ) : null}
                </Grid>
              );
            }
          }
        ]
      },
      {
        formComponent: "segment",
        name: "Activity warning snooze",
        width: "1/4",
        entities: [
          {
            formComponent: "checkbox-field",
            name: "twoWeeks",
            label: "Two weeks",
            width: "1/3",
            valueType: "boolean",
            path: ["data", "info", "activityWarning", "snoozeType"],
            isDisabled: (): boolean => false,
            isChecked: (stateAccess: StateAccess): boolean =>
              stateAccess.get(["data", "info", "activityWarning", "snoozeType"]) === "twoWeeks",
            toggle: (stateAccess: StateAccess) => {
              const activityWarning = stateAccess.get(["data", "info", "activityWarning"]);
              stateAccess.set(["data", "info", "activityWarning"], {
                ...activityWarning,
                snoozeType: "twoWeeks",
                checkedOrSnoozedDate: new Date().toISOString()
              });
            },
            required: true,
            default: false
          },
          {
            formComponent: "checkbox-field",
            name: "oneMonth",
            label: "One month",
            width: "1/3",
            valueType: "boolean",
            path: ["data", "info", "activityWarning", "snoozeType"],
            isDisabled: (): boolean => false,
            isChecked: (stateAccess: StateAccess): boolean =>
              stateAccess.get(["data", "info", "activityWarning", "snoozeType"]) === "oneMonth",
            toggle: (stateAccess: StateAccess) => {
              const activityWarning = stateAccess.get(["data", "info", "activityWarning"]);
              stateAccess.set(["data", "info", "activityWarning"], {
                ...activityWarning,
                snoozeType: "oneMonth",
                checkedOrSnoozedDate: new Date().toISOString()
              });
            },
            required: true,
            default: false
          },
          {
            formComponent: "checkbox-field",
            name: "twoMonths",
            label: "Two months",
            width: "1/3",
            valueType: "boolean",
            path: ["data", "info", "activityWarning", "snoozeType"],
            isDisabled: (): boolean => false,
            isChecked: (stateAccess: StateAccess): boolean =>
              stateAccess.get(["data", "info", "activityWarning", "snoozeType"]) === "twoMonths",
            toggle: (stateAccess: StateAccess) => {
              const activityWarning = stateAccess.get(["data", "info", "activityWarning"]);
              stateAccess.set(["data", "info", "activityWarning"], {
                ...activityWarning,
                snoozeType: "twoMonths",
                checkedOrSnoozedDate: new Date().toISOString()
              });
            },
            required: true,
            default: false
          }
        ]
      },
      {
        formComponent: "segment",
        width: "full",
        name: "Finance",
        entities: [
          {
            formComponent: "number-field",
            name: "businessDaysToFund",
            label: "Business days to fund",
            path: ["data", "info", "funding", "businessDaysToFund"],
            width: "1/3",
            default: 2
          },
          {
            formComponent: "radio-field",
            possibleValues: [
              {
                label: "ACH",
                value: "ACH"
              },
              {
                label: "Check",
                value: "Check"
              }
            ],
            name: "fundingPaymentMethod",
            label: "Funding Payment Method",
            width: "1/6",
            path: ["data", "info", "funding", "paymentMethod"],
            default: "ACH"
          },
          {
            formComponent: "checkbox-field",
            name: "fundingEnabled",
            label: "Funding information enabled in dealer app",
            width: "1/3",
            valueType: "boolean",
            path: ["data", "info", "funding", "enabled"],
            isDisabled: () => false,
            isChecked: (stateAccess: StateAccess) =>
              stateAccess.get<Dealership>(["data", "info", "funding", "enabled"]),
            toggle: (stateAccess: StateAccess) =>
              stateAccess.set<Dealership>(
                ["data", "info", "funding", "enabled"],
                !stateAccess.get<Dealership>(["data", "info", "funding", "enabled"])
              ),
            default: false
          },
          {
            formComponent: "tab-list-model",
            name: "Dealership Program Periods",
            width: "full",
            addRestriction: (stateAccess) => {
              const dealership: Dealership = stateAccess.get([]);
              const dealershipPrograms = dealership.data.info.dealershipPrograms;

              if (dealershipPrograms?.length === 1 && !dealershipPrograms?.[0]?.endDate) {
                return {
                  canAdd: false,
                  message: "Please add end date in the first period before adding new period"
                };
              }
              return { canAdd: true };
            },
            getTabName: (entity: any) => {
              return entity?.dealershipProgram?.data?.info?.name ?? "New Period";
            },
            path: ["data", "info", "dealershipPrograms"],
            entity: {
              formComponent: "segment",
              width: "full",
              entities: [
                {
                  formComponent: "one-to-many-field",
                  component: (stateAccess, _mainStateAccess, renderSet): JSX.Element => (
                    <DealershipProgramPreview
                      stateAccess={stateAccess}
                      path={["dealershipProgram"]}
                      renderSet={renderSet}
                      name={"program"}
                      label={"Program"}
                      required={true}
                    />
                  ),
                  name: "Dealership program preview",
                  width: "1/3",
                  path: ["dealershipProgram"]
                },
                {
                  formComponent: "date-field",
                  name: "startDate",
                  label: "Start date",
                  width: "1/3",
                  path: ["startDate"],
                  default: null
                },
                {
                  formComponent: "date-field",
                  name: "EndDate",
                  label: "End date",
                  width: "1/3",
                  path: ["endDate"],
                  required: false,
                  default: null
                }
              ]
            }
          }
        ]
      },
      {
        formComponent: "segment",
        width: "full",
        name: "Collateral Types",
        entities: collateralTypes.map((collateralType, index) => ({
          formComponent: "checkbox-field",
          name: collateralType,
          label: collateralType,
          width: "1/2",
          valueType: "boolean",
          path: ["data", "info", "collateralTypes", collateralType],
          isDisabled: () => false,
          isChecked: (stateAccess: StateAccess) =>
            stateAccess.get<Dealership>(["data", "info", "collateralTypes", collateralType]),
          toggle: (stateAccess: StateAccess) =>
            stateAccess.set<Dealership>(
              ["data", "info", "collateralTypes", collateralType],
              !stateAccess.get<Dealership>(["data", "info", "collateralTypes", collateralType])
            ),
          default: false
        })) as FormComponent<Dealership>[]
      },
      {
        formComponent: "segment",
        name: "E-Sign",
        width: "1/4",
        entities: [
          {
            formComponent: "checkbox-field",
            name: "eSign",
            label: "E-Sign support",
            width: "full",
            valueType: "boolean",
            path: ["data", "info", "eSign"],
            isDisabled: () => false,
            isChecked: (stateAccess: StateAccess) =>
              stateAccess.get<Dealership>(["data", "info", "eSign"]),
            toggle: (stateAccess: StateAccess) =>
              stateAccess.set<Dealership>(
                ["data", "info", "eSign"],
                !stateAccess.get<Dealership>(["data", "info", "eSign"])
              ),
            default: false
          }
        ]
      },
      {
        formComponent: "segment",
        name: "Split",
        show: (stateAccess) => {
          const currentDate = new Date().getTime();
          const activeCustomProgram = (stateAccess.get<Dealership>([
            "data",
            "info",
            "dealershipPrograms"
          ]) as DealershipProgramWithPeriods[]).find((x) => {
            if (x?.dealershipProgram?.data.info.name === "Custom Program" && x.startDate) {
              if (x.endDate) {
                return (
                  new Date(x.startDate).getTime() < currentDate &&
                  new Date(x.endDate).getTime() > currentDate
                );
              }
              return new Date(x.startDate).getTime() < currentDate;
            }
            return false;
          });

          return activeCustomProgram ? true : false;
        },
        entities: [
          {
            formComponent: "percentage-field",
            name: "wfdSplit",
            label: "WFD split",
            width: "1/6",
            path: ["data", "info", "agreement", "wfdSplit"],
            default: null
          },
          {
            formComponent: "percentage-field",
            name: "dealershipSplit",
            label: "Dealership split",
            width: "1/6",
            path: ["data", "info", "agreement", "dealershipSplit"],
            default: null
          },
          {
            formComponent: "percentage-field",
            name: "wfdGAPSplit",
            label: "WFD GAP split",
            width: "1/6",
            path: ["data", "info", "agreement", "wfdGAPSplit"],
            default: null
          },
          {
            formComponent: "percentage-field",
            name: "dealershipGAPSplit",
            label: "Dealership GAP split",
            width: "1/6",
            path: ["data", "info", "agreement", "dealershipGAPSplit"],
            default: null
          },
          {
            formComponent: "percentage-field",
            name: "wfdWarrantySplit",
            label: "WFD Warranty split",
            width: "1/6",
            path: ["data", "info", "agreement", "wfdWarrantySplit"],
            default: null
          },
          {
            formComponent: "percentage-field",
            name: "dealershipWarrantySplit",
            label: "Dealership Warranty split",
            width: "1/6",
            path: ["data", "info", "agreement", "dealershipWarrantySplit"],
            default: null
          }
        ]
      },
      {
        formComponent: "list-model",
        name: "Contact Info",
        entity: {
          formComponent: "segment",
          entities: [
            {
              formComponent: "text-field",
              name: "title",
              label: "Title",
              path: ["title"],
              width: "1/3",
              default: null
            },
            {
              formComponent: "name-field",
              name: "firstName",
              label: "First name",
              path: ["firstName"],
              required: true,
              width: "1/3",
              default: null
            },
            {
              formComponent: "name-field",
              name: "lastName",
              label: "Last name",
              path: ["lastName"],
              width: "1/3",
              default: null
            },
            {
              formComponent: "phone-field",
              name: "phone",
              label: "Phone",
              path: ["phone"],
              required: true,
              width: "1/4",
              default: undefined
            },
            {
              formComponent: "email-field",
              name: "email",
              label: "Email",
              path: ["email"],
              width: "1/4",
              default: null
            },
            {
              formComponent: "read-only-field",
              name: "contact-hubspotId",
              label: "Contact Hubspot Internal Id",
              path: ["hubspotId"],
              width: "1/4",
              default: null
            },
            {
              formComponent: "select-field",
              name: "role",
              label: "Role",
              width: "1/4",
              path: ["role"],
              options: createOptionsForSelect({
                possibleValues: () => ["Primary Contact", "Finance Contact", "Business Manager"],
                getOptionLabel: (x) => x,
                getSelectedOption: (x, y) => x === y
              }),
              default: null
            },
            {
              formComponent: "checkbox-field",
              name: "notificationOnlineApplication",
              label: "Notification on new Online Applications",
              path: ["notificationOnlineApplication"],
              width: "1/4",
              default: false,
              toggle: (stateAccess: StateAccess) =>
                stateAccess.set<Dealership["data"]["contacts"][number]>(
                  ["notificationOnlineApplication"],
                  !stateAccess.get<Dealership["data"]["contacts"][number]>([
                    "notificationOnlineApplication"
                  ])
                ),
              isDisabled: () => false,
              isChecked: (stateAccess: StateAccess) =>
                stateAccess.get<Dealership["data"]["contacts"][number]>([
                  "notificationOnlineApplication"
                ])
            }
          ] as FormComponent<Contact>[]
        },
        width: "full",
        required: false,
        path: ["data", "contacts"],
        renderOrder: "desc"
      },
      {
        formComponent: "text-field",
        name: "Netsuite Internal Id",
        label: "Netsuite Internal Id",
        path: ["data", "netsuiteId"],
        width: "1/4",
        default: null
      },
      {
        formComponent: "read-only-field",
        name: "Hubspot Internal Id",
        label: "Hubspot Internal Id",
        path: ["data", "hubspotId"],
        width: "1/4",
        default: null
      },
      {
        formComponent: "list-model",
        name: "Notes",
        entity: noteStruct,
        width: "full",
        required: false,
        path: ["data", "notes"]
      },

      {
        formComponent: "segment",
        width: "full",
        show: (stateAccess: StateAccess) => stateAccess.get<Dealership>(["_id"]),
        entities: [
          {
            formComponent: "one-to-many-field",
            name: "Contracts",
            width: "full",
            label: "Contracts",
            path: ["data", "contracts"],
            component: (stateAccess: StateAccess) => (
              <ContractsPreview stateAccess={stateAccess} type="dealership" />
            )
          }
        ]
      },
      {
        formComponent: "one-to-many-field",
        name: "Deals",
        width: "full",
        show: (stateAccess: StateAccess) => stateAccess.get<Dealership>(["_id"]),
        label: "Deals",
        path: [],
        component: (stateAccess: StateAccess) => (
          <Deals
            slice={`deals-dealership-${stateAccess.get<Dealership>(["_id"])}`}
            query={{ "data.dealershipId": stateAccess.get<Dealership>(["_id"]) }}
          />
        )
      },
      {
        formComponent: "one-to-many-field",
        name: "Title Issues",
        width: "full",
        show: (stateAccess: StateAccess) => stateAccess.get<Dealership>(["_id"]),
        label: "Title Issues",
        path: [],
        component: (stateAccess: StateAccess) => (
          <TitleIssues query={{ "data.dealershipId": stateAccess.get(["_id"]) }} />
        )
      },
      {
        formComponent: "segment",
        width: "full",
        show: (stateAccess: StateAccess) => stateAccess.get<Dealership>(["_id"]),
        entities: [
          {
            formComponent: "one-to-many-field",
            name: "Contracts",
            width: "full",
            label: "Contracts",
            path: ["data", "contracts"],
            component: (stateAccess: StateAccess) => <StatisticsPreview stateAccess={stateAccess} />
          }
        ]
      }
    ]
  };
};
const noteStruct: FormComponent<Note> = {
  formComponent: "segment",
  entities: [
    {
      formComponent: "text-field",
      name: "note",
      label: "Note",
      width: "full",
      multiline: true,
      path: ["note"],
      required: true,
      default: null
    }
  ]
};

export const dealershipStruct: Model<Dealership> = {
  formComponent: "model",
  schema: "new_dealership",
  name: "dealership",
  entities: [dealershipInfo(false)]
};
export const editDealershipStruct: Model<Dealership> = {
  formComponent: "model",
  schema: "update_dealership",
  name: "dealership",
  entities: [dealershipInfo(true)]
};
