import { Button, Paper, Typography } from "@material-ui/core";
import { RootState } from "app/rootReducer";
import AccessControl from "components/Access/AccessControl";
import DateFilter from "components/Filters/DateFilter";
import LenderFilter from "components/Filters/LenderFilter";
import StyledBadge from "components/StyledBadge";

import { DmvQuery } from "components/Dashboard/TitleIssues/TitleIssuesDashboard";
import ApplicantFilter from "components/Filters/ApplicantFilter";
import MultiSelectFilter from "components/Filters/MultiSelectFilter";
import TextFilter from "components/Filters/TextFilter";
import { useStickyState } from "index";
import React from "react";
import { useSelector } from "react-redux";
import { capitalize } from "utils/functions";
import Table, { CellValue, Column } from "../Table";
import { deleteLenderTicket } from "./deleteLenderTicketSlice";
import { getLenderTicketList } from "./listLenderTicketSlice";
import { recoverLenderTicket } from "./recoverLenderTicketSlice";
import { FormState, LenderTicketStatus } from "./types";

export const projections = {
  "data.info.subject": 1,
  "data.deal.data.applicant.data.info.firstName": 1,
  "data.deal.data.applicant.data.info.lastName": 1,
  "data.deal.data.coApplicant.data.info.firstName": 1,
  "data.deal.data.coApplicant.data.info.lastName": 1,
  "data.deal.data.info.vehicle.VIN": 1,
  "data.info.status": 1,
  "data.info.refNumber": 1,
  "data.info.priority": 1,
  "data.lender.data.info.name": 1,
  "data.lenderNotes": 1,
  _id: 1,
  createdAt: 1,
  creatorId: 1,
  updatedAt: 1,
  deleted: 1
};

type TicketQueryStatus = "active" | "inactive";
export default ({
  dashboardView = false,
  overallQuery,
  setResetStickyCallback
}: {
  dashboardView?: boolean;
  overallQuery?: Record<string, any>;
  setResetStickyCallback?: React.Dispatch<React.SetStateAction<(() => void) | undefined>>;
}): JSX.Element => {
  const [resetStickyTableCallback, setResetStickyTableCallback] = React.useState<() => void>();

  const lenderTicketList = useSelector((state: RootState) => state.listLenderTicketSlice["table"]);
  const columns: Column<FormState>[] = [
    {
      getData: (el): CellValue => capitalize(el.data.info.status) ?? "",
      label: "Status",
      name: "status",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.status?.show;
      },
      filters: [
        {
          path: ["data", "info", "status"],
          preview: MultiSelectFilter,
          valuesForSelect: Object.values(LenderTicketStatus),
          optionLabelForSelect: (status) => capitalize(status)
        }
      ]
    },
    {
      getData: (entry): CellValue => {
        return entry.data?.info?.refNumber;
      },
      label: "Ref #",
      options: {
        sort: true,
        path: ["data", "info", "refNumber"]
      },
      name: "refNumber",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.refNumber?.show;
      },
      filters: [
        {
          path: ["data", "info", "refNumber"],
          preview: TextFilter,
          caseInsensitive: true,
          partialSearch: true
        }
      ]
    },
    {
      getData: (el): CellValue => el.data.info.subject,
      label: "Subject",
      name: "subject",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.subject?.show;
      },
      filters: [
        {
          path: ["data", "info", "subject"],
          preview: TextFilter,
          caseInsensitive: true,
          partialSearch: true
        }
      ]
    },
    {
      getData: (el): CellValue => el.data.lender?.data?.info?.name,
      label: "Lender",
      name: "Lender",
      show: () => true,
      filters: [
        {
          path: ["data", "lenderId"],
          preview: LenderFilter
        }
      ]
    },
    {
      getData: (entry): CellValue =>
        [
          entry.data?.deal?.data?.applicant
            ? `${entry.data?.deal?.data?.applicant?.data?.info?.firstName ?? ""} ${
                entry.data?.deal?.data?.applicant?.data?.info?.lastName ?? ""
              }`
            : undefined,
          entry.data?.deal?.data?.coApplicant
            ? `${entry.data?.deal?.data?.coApplicant?.data?.info?.firstName ?? ""} ${
                entry.data?.deal?.data?.coApplicant?.data?.info?.lastName ?? ""
              }`
            : undefined
        ]
          .filter((x) => x)
          .join(" / "),
      label: "Applicant",
      name: "applicant",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.applicant?.show;
      },
      filters: [
        {
          path: [
            ["data", "deal", "data", "applicant", "data", "info", "firstName"],
            ["data", "deal", "data", "applicant", "data", "info", "lastName"],
            ["data", "deal", "data", "coApplicant", "data", "info", "firstName"],
            ["data", "deal", "data", "coApplicant", "data", "info", "lastName"]
          ],
          preview: ApplicantFilter
        }
      ]
    },
    {
      getData: (entry): CellValue => entry.data?.deal?.data?.info?.vehicle?.VIN,
      label: "VIN",
      name: "vin",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.vin?.show;
      },
      filters: [
        {
          path: ["data", "deal", "data", "info", "vehicle", "VIN"],
          preview: TextFilter
        }
      ]
    },
    {
      label: "Date updated",
      getData: (el): CellValue => new Date(el.updatedAt),
      options: {
        sort: true,
        path: ["updatedAt"]
      },
      name: "updatedAt",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.updatedAt?.show;
      },
      filters: [
        {
          path: ["updatedAt"],
          preview: DateFilter,
          label: "Updated At",
          name: "updatedAt"
        }
      ]
    },
    {
      label: "Date added",
      getData: (el): CellValue => new Date(el.createdAt),
      options: {
        sort: true,
        path: ["createdAt"]
      },
      name: "createdAt",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.createdAt?.show;
      },
      filters: [
        {
          path: ["createdAt"],
          preview: DateFilter,
          label: "Created At",
          name: "createdAt"
        }
      ]
    },
    {
      label: "Actions",
      getData: (el): CellValue => el._id,
      name: "actions",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.actions?.show;
      }
    }
  ];

  const [ticketsQuery, setTicketsQuery] = useStickyState<
    | {
        active: TicketQueryStatus;
        query: Record<string, LenderTicketStatus | Record<string, LenderTicketStatus>[]>;
      }
    | undefined
  >(
    {
      active: "active",
      query: {
        $or: [
          {
            "data.info.status": LenderTicketStatus.InProgress
          },
          {
            "data.info.status": LenderTicketStatus.Open
          }
        ]
      }
    },
    dashboardView ? "lender_tickets_dashboard_query" : "lender_tickets_query"
  );

  const handleSetQuery = (status: TicketQueryStatus) => {
    switch (status) {
      case "active":
        setTicketsQuery({
          active: status,
          query: {
            $or: [
              {
                "data.info.status": LenderTicketStatus.InProgress
              },
              {
                "data.info.status": LenderTicketStatus.Open
              }
            ]
          }
        });
        return;
      case "inactive":
        setTicketsQuery({
          active: status,
          query: {
            $or: [
              {
                "data.info.status": LenderTicketStatus.Closed
              },
              {
                "data.info.status": LenderTicketStatus.Solved
              }
            ]
          }
        });
    }
  };
  const handleResetStickyState = () => {
    setTicketsQuery({
      active: "active",
      query: {
        $or: [
          {
            "data.info.status": LenderTicketStatus.InProgress
          },
          {
            "data.info.status": LenderTicketStatus.Open
          }
        ]
      }
    });
    resetStickyTableCallback?.();
  };
  React.useEffect(() => {
    setResetStickyCallback?.(() => handleResetStickyState);
  }, [resetStickyTableCallback]);
  return (
    <>
      <AccessControl requiredPermissions={{ entity: "lender_ticket", action: "read" }}>
        <Paper elevation={3} style={{ padding: "10px" }}>
          <Typography variant="h6">Ticket Status</Typography>
          <Button
            style={{ margin: "5px" }}
            variant={"outlined"}
            onClick={() => setTicketsQuery(undefined)}
          >
            Reset
          </Button>
          {["inactive", "active"].map((status, index) => (
            <StyledBadge
              key={index}
              badgeContent={ticketsQuery?.active === status ? 1 : 0}
              color={status === "active" ? "#00800033" : "#fa9b9b66"}
              variant="dot"
            >
              <Button
                style={{
                  margin: "5px",
                  background: status === "active" ? "#00800033" : "#fa9b9b66"
                }}
                variant={"outlined"}
                onClick={() => handleSetQuery(status as TicketQueryStatus)}
              >
                {`${capitalize(status)}`}
              </Button>
            </StyledBadge>
          ))}
          <Table
            slice="table"
            setResetStickyCallback={setResetStickyTableCallback}
            elevation={0}
            tableName="LenderTickets"
            entityName="lender_ticket"
            listFunction={getLenderTicketList}
            aggregateFirst
            listEntity={lenderTicketList}
            query={{
              ...(ticketsQuery?.query ?? {}),
              ...(overallQuery ?? {})
            }}
            deleteEntityFunction={deleteLenderTicket}
            recoverEntityFunction={recoverLenderTicket}
            title={"Lender Tickets"}
            columns={columns}
            projection={projections}
          />
        </Paper>
      </AccessControl>
    </>
  );
};
