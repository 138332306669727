import { Grid } from "@material-ui/core";
import React from "react";
import { Dealership } from "../types";
import Charts from "./Charts";
import Header from "./Header";
export default function DealershipShowPage({ dealership }: { dealership: Dealership }) {
  return (
    <Grid container spacing={2} style={{ gap: "20px" }}>
      <Grid xs={12}>
        <Header dealership={dealership} />
      </Grid>
      <Grid container xs={12} style={{ gap: "20px" }}>
        <Charts dealership={dealership} />
      </Grid>
    </Grid>
  );
}
