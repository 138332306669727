import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";

interface RecoverDefaultStipulationRequest {
  _id: string;
}

export interface RecoverDefaultStipulationResponse {
  message: string;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  RecoverDefaultStipulationRequest,
  RecoverDefaultStipulationResponse
>("RecoverDefaultStipulation", {
  request: "recover_default_stipulation"
});

export const recoverDefaultStipulationActions = socketMessageSlice.actions;
export const recoverDefaultStipulation = socketAction;
export default socketMessageSlice.reducer;
