import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { ErrorBoundary } from "components/ErrorBoundary/ErrorBoundary";
import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import "../node_modules/jsondiffpatch/dist/formatters-styles/annotated.css";
import "../node_modules/jsondiffpatch/dist/formatters-styles/html.css";
import store from "./app/store";
import "./index.css";
import ReactQuerySocketConnection from "components/Middlewares/ReactQuerySocketConnection";
const App = require("./app/App").default;

const theme = createTheme({
  palette: {
    primary: {
      main: "#254e70"
    }
  }
});
export function useStickyState<T>(
  data: T,
  key: string,
  dataModifier?: (data: any) => T
): [T, React.Dispatch<React.SetStateAction<T>>] {
  const [value, setValue] = useState(() => {
    const stickyValue = window.localStorage.getItem(key);
    try {
      return stickyValue !== null && stickyValue !== undefined && stickyValue !== "undefined"
        ? JSON.parse(stickyValue)
        : data;
    } catch (e) {
      return stickyValue;
    }
  });
  useEffect(() => {
    window.localStorage.setItem(key, typeof value === "object" ? JSON.stringify(value) : value);
  }, [key, value]);
  if (dataModifier) {
    return [dataModifier(value), setValue];
  }
  return [value, setValue];
}
const AppWrapper = () => {
  const [showStats, setShowStats] = useStickyState(false, "showStatsForNerds");
  return (
    <>
      <div
        id="fps"
        style={{
          background: "white",
          textAlign: "right",
          position: "fixed",
          bottom: "0px",
          right: "0px",
          color: "black",
          fontSize: "9px",
          zIndex: 999999999999,
          height: "10px",
          display: showStats ? "block" : "none"
        }}
      ></div>
      <div
        id="version"
        onClick={() => {
          setShowStats((x) => !x);
        }}
        style={{
          position: "fixed",
          bottom: "0px",
          left: "5px",
          color: "black",
          fontSize: "10px",
          cursor: "pointer",
          zIndex: 999999999999,
          background: "white"
        }}
      >
        v. {process.env.REACT_APP_VERSION}
      </div>
      <App />
    </>
  );
};

const Render = () => {
  ReactDOM.render(
    <ErrorBoundary>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <ReactQuerySocketConnection>
            <AppWrapper />
          </ReactQuerySocketConnection>
        </Provider>
      </ThemeProvider>
    </ErrorBoundary>,
    document.getElementById("root")
  );
};

Render();

if (process.env.NODE_ENV === "development" && module.hot) {
  module.hot.accept("./app/App", Render);
}
