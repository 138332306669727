import React from "react";
import CloseDialogButton from "components/common/CloseDialogButton";
import {
  Badge,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Radio,
  TextField,
  Tooltip,
  Typography
} from "@material-ui/core";
import { Stipulation, StipulationStatus } from "./types";
import { getStipulationStatusColor } from "./StipulationsPreview";
import { capitalize } from "utils/functions";
import formatDate from "utils/formatDate";
import { ImportContacts, Mail, Visibility } from "@material-ui/icons";
import { HintTooltip } from "components/common/HintTooltip";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { RejectedURL } from "./UploadedFilesDialog";
import { useDispatch } from "react-redux";
import { uuid } from "uuidv4";
import { recoverStipulation } from "./recoverStipulationSlice";

type DeletedStipulationsDialogProps = {
  closeFn: () => void;
  open: boolean;
  deletedStipulations: Stipulation[];
  setSelectedStipulation: React.Dispatch<React.SetStateAction<Stipulation | undefined>>;
  setSelectedRejectedURL: React.Dispatch<React.SetStateAction<RejectedURL | undefined>>;
  hasCoApplicant: boolean;
};
const useStyles = makeStyles((theme: Theme) => ({
  customBadge: () => ({
    fontSize: "14px",
    fontWeight: "bold",
    marginRight: "-3px"
  })
}));
const DeletedStipulationsDialog = ({
  closeFn,
  open,
  deletedStipulations,
  hasCoApplicant,
  setSelectedRejectedURL,
  setSelectedStipulation
}: DeletedStipulationsDialogProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const handleRecoverStipulation = (stipulation: Stipulation) => {
    const requestId = uuid();
    dispatch(
      recoverStipulation({
        requestId,
        _id: stipulation._id
      })
    );
  };
  return (
    <Dialog open={open} fullWidth maxWidth={"md"}>
      <CloseDialogButton closeFunction={closeFn} />
      <DialogTitle>Deleted stipulations</DialogTitle>
      <DialogContent style={{ padding: "0px 20px" }}>
        <Grid container spacing={2}>
          {deletedStipulations.map((stipulation) => (
            <Grid item xs={12} key={stipulation._id}>
              <Paper
                elevation={3}
                style={{
                  background: "rgba(245, 0, 85, 0.16)",
                  position: "relative",
                  padding: "10px",
                  marginBottom: "20px",
                  display: "block"
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleRecoverStipulation(stipulation)}
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    minWidth: "200px",
                    transform: "translate(-50%, -50%)"
                  }}
                >
                  Restore
                </Button>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "5px",
                    borderRadius: "5px",
                    position: "absolute",
                    right: "10px",
                    background: getStipulationStatusColor(
                      stipulation?.data?.info?.status ?? StipulationStatus.AWAITING_UPLOAD
                    )
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    style={{ textAlign: "center", whiteSpace: "nowrap" }}
                  >
                    {capitalize(
                      stipulation?.data?.info?.status ?? StipulationStatus.AWAITING_UPLOAD
                    )}
                  </Typography>
                </div>
                <Typography variant="subtitle2" style={{ textAlign: "left", whiteSpace: "nowrap" }}>
                  Can be uploaded by
                </Typography>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between"
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "row"
                    }}
                  >
                    {(["applicant", ...(hasCoApplicant ? ["coApplicant"] : []), "dealer"] as (
                      | "applicant"
                      | "coApplicant"
                      | "dealer"
                    )[]).map((x) => {
                      return (
                        <FormControlLabel
                          style={{ whiteSpace: "nowrap", fontSize: "12px" }}
                          key={`${stipulation._id}_${x}`}
                          id={`${stipulation._id}_${x}`}
                          control={
                            <Checkbox
                              color="primary"
                              disabled={true}
                              id="ignore-duplicate-checkbox"
                              checked={stipulation?.data?.info?.canBeUploadedBy?.includes(x)}
                            />
                          }
                          label={x === "coApplicant" ? "Co-Applicant" : capitalize(x)}
                        />
                      );
                    })}
                  </div>
                </div>
                <Typography variant="subtitle2" style={{ textAlign: "left", whiteSpace: "nowrap" }}>
                  Document owner
                </Typography>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginBottom: "10px"
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "row"
                    }}
                  >
                    {(["applicant", ...(hasCoApplicant ? ["coApplicant"] : [])] as (
                      | "applicant"
                      | "coApplicant"
                    )[]).map((x) => {
                      return (
                        <FormControlLabel
                          key={`${stipulation._id}_${x}`}
                          id={`${stipulation._id}_${x}`}
                          control={
                            <Radio
                              disabled={true}
                              checked={stipulation?.data?.info?.owner === x}
                              color="primary"
                            />
                          }
                          label={x === "coApplicant" ? "Co-Applicant" : capitalize(x)}
                        />
                      );
                    })}
                  </div>
                </div>

                <Box display="flex" alignContent="flex-start">
                  <Box flexGrow={1}>
                    <Grid container>
                      <Grid item xs={12}>
                        <TextField
                          name="stipulation"
                          autoComplete="off"
                          InputLabelProps={{ shrink: true }}
                          placeholder="Stipulation"
                          fullWidth
                          value={stipulation.data.info.description}
                          label={"Stipulation"}
                          variant={"outlined"}
                          size="small"
                        />
                      </Grid>
                    </Grid>
                    <Box
                      fontSize={12}
                      justifyContent="space-between"
                      display={"flex"}
                      marginTop={"10px"}
                      style={{ maxHeight: 20 }}
                    >
                      <Tooltip
                        title={
                          <span style={{ fontSize: 15, whiteSpace: "nowrap", lineHeight: "20px" }}>
                            {stipulation?.data?.info?.notifiedEmails?.map((x, index) => (
                              <div key={index}>
                                {x.type}: {x.emails?.join(",")}
                              </div>
                            ))}
                            {stipulation.data.info.viewed && (
                              <div>
                                And viewed at{" "}
                                {formatDate(stipulation.data.info.viewed, "short", true)}
                              </div>
                            )}
                          </span>
                        }
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "3px",
                            marginLeft: 10,
                            width: "100%"
                          }}
                        >
                          <Mail
                            style={{
                              color: stipulation?.data?.info?.notificationSendDate
                                ? "#50A538"
                                : "#E34C28"
                            }}
                          />
                          {stipulation?.data?.info?.notificationSendDate && "Sent email at "}
                          {formatDate(stipulation?.data?.info?.notificationSendDate, "short", true)}
                          {stipulation.data.info.viewed && (
                            <ImportContacts
                              style={{
                                color: "rgb(107, 151, 246)"
                              }}
                            />
                          )}
                        </div>
                      </Tooltip>
                      <span style={{ whiteSpace: "nowrap" }}>
                        {[
                          stipulation.data?.info?.user?.firstName,
                          stipulation?.data?.info?.user?.lastName,
                          formatDate(stipulation?.createdAt, "short", true)
                        ]
                          .filter((x) => x)
                          .join(" ")}
                      </span>
                    </Box>
                  </Box>

                  <Box>
                    <Box>
                      <Box
                        style={{
                          display: "flex",
                          alignItems: "start",
                          justifyContent: "space-between"
                        }}
                      >
                        <IconButton
                          disabled={stipulation?.data?.info?.url ? false : true}
                          style={
                            stipulation?.data?.info?.url
                              ? {
                                  color: "#50A538"
                                }
                              : undefined
                          }
                          aria-label={`view stipulation`}
                          onClick={() => {
                            setSelectedRejectedURL(
                              stipulation?.data?.info?.url
                                ? {
                                    url: stipulation?.data?.info?.url as string,
                                    uploadedAt: stipulation?.data?.info?.uploadedAt,
                                    uploadedBy: stipulation?.data?.info?.uploadedBy
                                  }
                                : undefined
                            );
                            setSelectedStipulation(stipulation);
                          }}
                        >
                          <Badge
                            badgeContent={
                              stipulation?.data?.info?.rejectedUrls?.length
                                ? (stipulation?.data?.info?.rejectedUrls?.length || 0) +
                                  (stipulation?.data?.info?.url ? 1 : 0)
                                : 0
                            }
                            color="secondary"
                            showZero={false}
                            classes={{ badge: classes.customBadge }}
                          >
                            <HintTooltip
                              title={`Click here to view the files for the stipulation.`}
                            >
                              <Visibility />
                            </HintTooltip>
                          </Badge>
                        </IconButton>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          type="submit"
          color="primary"
          variant="contained"
          id="submit-trade-button"
          onClick={() => {
            closeFn();
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default DeletedStipulationsDialog;
