import {
  AppBar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  InputBase,
  Paper,
  Radio,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import {
  DealershipPerformanceReportRequest,
  DealershipPerformanceReportResponseData
} from "hooks/useDealershipPerformanceReport/types";
import { useStickyState } from "index";
import React from "react";
import {
  capitalize,
  getCurrentQuarterDates,
  getFirstAndLastDateOfCurrentMonth
} from "utils/functions";
import CloseDialogButton from "../../common/CloseDialogButton";

import VisibilityIcon from "@material-ui/icons/Visibility";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { RootState } from "app/rootReducer";
import useDebounce from "Chat/hooks/useDebounce";
import CustomerCareNotesPreview from "components/CustomerCareNotes/CustomerCareNotesPreview";
import DealsModal from "components/Dashboard/TitleIssues/DealsModal";
import LatestTicketsModal from "components/Dashboard/TitleIssues/LatestTicketsModal";
import LimitedTitleIssuesModal from "components/Dashboard/TitleIssues/LimitedTitleIssuesModal";
import {
  getDealershipList,
  removeDealershipList
} from "components/Dealerships/listDealershipSlice";
import { Dealership } from "components/Dealerships/types";
import { TabContext } from "components/Layout/LayoutWrapper";
import Spinner from "components/Loader/Spinner";
import { getUserList, removeUserList } from "components/Users/listUserSlice";
import { User } from "components/Users/types";
import useDealershipPerformanceReport from "hooks/useDealershipPerformanceReport/useDealershipPerformanceReport";
import { useDispatch, useSelector } from "react-redux";
import States from "us-states";
import { getLockList } from "utils/models/LockEntity/listLockSlice";
import AddCustomerCareAssignee from "./AddCustomerCareAssignee";

type RequestState = DealershipPerformanceReportRequest & {
  fromYear: number | null;
  fromMonth: number | null;
  toYear: number | null;
  toMonth: number | null;
  allCustomerCareAssignees: boolean;
  allDealershipStates: boolean;
};
type ToggleState = "qtd" | "mtd" | "ytd" | undefined;

const generateYearArray = () =>
  Array.from({ length: 5 }, (_, i) => new Date().getFullYear() - 4 + i).reverse();

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}
function a11yProps(index: any) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`
  };
}
const MonthYearSettings = ({
  requestState,
  setRequestState,
  setToggle
}: {
  requestState: RequestState;
  setRequestState: React.Dispatch<React.SetStateAction<RequestState>>;
  setToggle: React.Dispatch<React.SetStateAction<ToggleState>>;
}) => {
  const handlePeriodChange = (
    value: string | null,
    key: "fromYear" | "fromMonth" | "toYear" | "toMonth"
  ) => {
    setRequestState((prevRequestState) => ({
      ...prevRequestState,
      [key]: Number(value) || null
    }));
  };
  const renderPeriodFields = (key: "from" | "to") => {
    return (
      <Grid container item xs={12} spacing={1}>
        <Grid item xs={12}>
          <Typography style={{ fontWeight: "bold", fontSize: "16px" }}>
            {capitalize(key)}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            style={{ zIndex: 6 }}
            value={requestState?.[`${key}Month`]?.toString()}
            getOptionLabel={(month) => {
              return month;
            }}
            getOptionSelected={(x, y) => x === y}
            getOptionDisabled={(option) => {
              if (
                key === "from" &&
                requestState.toMonth &&
                requestState.toYear === requestState.fromYear
              ) {
                return Number(option) >= requestState.toMonth;
              }
              if (
                key === "to" &&
                requestState.fromMonth &&
                requestState.toYear === requestState.fromYear
              ) {
                return Number(option) <= requestState.fromMonth;
              }
              return false;
            }}
            options={Array.from({ length: 12 }, (_, i) => (i + 1).toString())}
            onChange={(event, newValue) => {
              handlePeriodChange(newValue, `${key}Month`);
              setToggle(undefined);
            }}
            openOnFocus
            id={`${key}-month-select`}
            renderInput={(params) => (
              <TextField
                {...params}
                InputLabelProps={{ shrink: true }}
                InputProps={{ ...params.InputProps }}
                label={"Month"}
                variant="filled"
                size="small"
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            style={{ zIndex: 6 }}
            value={requestState?.[`${key}Year`]?.toString()}
            getOptionLabel={(year) => {
              return year;
            }}
            getOptionSelected={(x, y) => x === y}
            options={generateYearArray().map((yr) => yr.toString())}
            getOptionDisabled={(option) => {
              if (key === "from" && requestState.toYear) {
                return Number(option) > requestState.toYear;
              }
              if (key === "to" && requestState.fromYear) {
                return Number(option) < requestState.fromYear;
              }
              return false;
            }}
            onChange={(event, newValue) => {
              handlePeriodChange(newValue, `${key}Year`);
              setToggle(undefined);
            }}
            openOnFocus
            id={`${key}-year-select`}
            renderInput={(params) => (
              <TextField
                {...params}
                InputLabelProps={{ shrink: true }}
                InputProps={{ ...params.InputProps }}
                label={"Year"}
                variant="filled"
                size="small"
              />
            )}
          />
        </Grid>
      </Grid>
    );
  };
  return (
    <Paper style={{ width: "100%", padding: "10px" }} elevation={2}>
      <Grid container item xs={12}>
        <Grid item xs={6}>
          {renderPeriodFields("from")}
        </Grid>
        <Grid item xs={6}>
          {renderPeriodFields("to")}
        </Grid>
      </Grid>
    </Paper>
  );
};
const GroupBy = ({
  requestState,
  setRequestState
}: {
  requestState: RequestState;
  setRequestState: React.Dispatch<React.SetStateAction<RequestState>>;
}) => {
  return (
    <Paper style={{ width: "100%", padding: "10px" }} elevation={2}>
      <Grid container item xs={12}>
        <Grid item xs={12} style={{ marginBottom: "10px" }}>
          <Typography style={{ fontWeight: "bold", fontSize: "16px" }}>Group by</Typography>
        </Grid>
        <Grid item xs={12}>
          {(["monthly", "yearly", "quarterly"] as const).map((groupBy) => {
            return (
              <FormControlLabel
                key={groupBy}
                control={
                  <Radio
                    checked={groupBy === requestState.groupBy}
                    onChange={() => setRequestState((prev) => ({ ...prev, groupBy }))}
                  />
                }
                id={`groupBy`}
                label={<Typography style={{ fontSize: "13px" }}>{capitalize(groupBy)}</Typography>}
              />
            );
          })}
        </Grid>
      </Grid>
    </Paper>
  );
};
const PredefinedRanges = ({
  requestState,
  setRequestState,
  toggleState,
  setToggle
}: {
  requestState: RequestState;
  setRequestState: React.Dispatch<React.SetStateAction<RequestState>>;
  toggleState: ToggleState;
  setToggle: React.Dispatch<React.SetStateAction<ToggleState>>;
}) => {
  const [today] = React.useState(new Date());
  const handleSetToggle = (period: ToggleState) => {
    setToggle(period);
    setRequestState(() => {
      switch (period) {
        case "mtd": {
          const from = new Date(today.getFullYear(), today.getMonth() - 5, 1);
          return {
            ...requestState,
            fromMonth: from.getMonth() + 1,
            fromYear: from.getFullYear(),
            toMonth: today.getMonth() + 1,
            toYear: today.getFullYear()
          };
        }
        case "qtd": {
          const { from } = getCurrentQuarterDates();
          return {
            ...requestState,
            fromMonth: from.getMonth() + 1,
            fromYear: from.getFullYear(),
            toMonth: today.getMonth() + 1,
            toYear: today.getFullYear()
          };
        }
        case "ytd": {
          const from = new Date(today.getFullYear(), 0, 1);
          return {
            ...requestState,
            fromMonth: from.getMonth() + 1,
            fromYear: from.getFullYear(),
            toMonth: today.getMonth() + 1,
            toYear: today.getFullYear()
          };
        }
        default:
          return requestState;
      }
    });
  };

  return (
    <Paper elevation={2} style={{ width: "100%", padding: "10px" }}>
      <Grid container item xs={12} spacing={1}>
        <Grid item xs={12}>
          <Typography style={{ fontWeight: "bold", fontSize: "16px" }}>
            Predefined ranges
          </Typography>
        </Grid>
        <Grid item container xs={12}>
          <Grid item xs={4}>
            <Box
              component={"div"}
              style={{
                display: "flex",
                maxHeight: "20px",
                padding: 15,
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                color: toggleState === "mtd" ? "#fff" : "#000",
                background: toggleState === "mtd" ? "#2B4E70" : "#E8E8E8"
              }}
              onClick={() => handleSetToggle("mtd")}
            >
              <Typography>6MTD</Typography>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box
              component={"div"}
              style={{
                display: "flex",
                maxHeight: "20px",
                padding: 15,
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                color: toggleState === "qtd" ? "#fff" : "#000",
                background: toggleState === "qtd" ? "#2B4E70" : "#E8E8E8"
              }}
              onClick={() => handleSetToggle("qtd")}
            >
              <Typography>QTD</Typography>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box
              component={"div"}
              style={{
                display: "flex",
                maxHeight: "20px",
                padding: 15,
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                color: toggleState === "ytd" ? "#fff" : "#000",
                background: toggleState === "ytd" ? "#2B4E70" : "#E8E8E8"
              }}
              onClick={() => handleSetToggle("ytd")}
            >
              <Typography>YTD</Typography>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};
const generateTable = (
  data: DealershipPerformanceReportResponseData,
  formattedPeriods: string[],
  dealerships: Dealership[] | undefined,
  handleOpenCustomerCareNotes: (dealership: Dealership | undefined) => void
) => {
  const TABLE_CELL_STYLE: React.CSSProperties = {
    border: "1px solid black",
    textAlign: "center",
    padding: "8px"
    // whiteSpace: "nowrap"
  };
  const headers = formattedPeriods.map((period, index) => (
    <TableCell
      key={`headers${index}`}
      colSpan={index === 0 ? 10 : 8}
      style={{
        ...TABLE_CELL_STYLE,
        whiteSpace: "normal",
        borderLeft: "2px solid black",
        borderRight: "2px solid black"
      }}
    >
      {period}
    </TableCell>
  ));
  if (Object.keys(data)?.length < 1) {
    return (
      <TableContainer>
        <Table size="small" style={{ tableLayout: "fixed" }}>
          <TableHead>
            <TableRow>
              <TableCell style={TABLE_CELL_STYLE}></TableCell>
              <TableCell style={TABLE_CELL_STYLE}>Customer care assignee</TableCell>
              <TableCell style={TABLE_CELL_STYLE}>Customer care notes (Internal)</TableCell>
              {headers}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell
                colSpan={formattedPeriods.length * 10 + 3}
                style={{
                  ...TABLE_CELL_STYLE,
                  fontWeight: "bold",
                  fontSize: "16px",
                  padding: "16px"
                }}
              >
                No data
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
  const rows = Object.keys(data).map((dealershipName, i) => {
    const dataLabels = Object.keys(data?.[dealershipName]?.periods).map((_, index) => {
      return (
        <React.Fragment key={index}>
          {index === 0 ? (
            <TableCell
              colSpan={2}
              style={{ ...TABLE_CELL_STYLE, borderLeft: "2px solid black" }}
            ></TableCell>
          ) : null}
          <TableCell colSpan={2} style={TABLE_CELL_STYLE}>
            Deal count
          </TableCell>
          <TableCell colSpan={2} style={TABLE_CELL_STYLE}>
            Avg credit score
          </TableCell>
          <TableCell colSpan={2} style={TABLE_CELL_STYLE}>
            GAP count
          </TableCell>
          <TableCell colSpan={2} style={{ ...TABLE_CELL_STYLE, borderRight: "2px solid black" }}>
            Service warranty count
          </TableCell>
        </React.Fragment>
      );
    });
    const statusRows = (["successful", "unsuccessful", "in process"] as const).map(
      (status, index) => {
        return (
          <TableRow
            key={index}
            style={{ background: i % 2 === 0 ? "#f3f3f3" : "#ffffff", textAlign: "center" }}
          >
            {Object.keys(data?.[dealershipName]?.periods).map((period, periodIndex) => {
              const periodData = data?.[dealershipName]?.periods?.[period];
              return (
                <React.Fragment key={periodIndex}>
                  {periodIndex === 0 ? (
                    <TableCell
                      colSpan={2}
                      style={{ ...TABLE_CELL_STYLE, borderLeft: "2px solid black" }}
                    >
                      {" "}
                      {capitalize(status)}
                    </TableCell>
                  ) : null}
                  <TableCell colSpan={2} style={TABLE_CELL_STYLE}>
                    {periodData?.[status]?.count || 0}
                  </TableCell>
                  <TableCell colSpan={2} style={TABLE_CELL_STYLE}>
                    {periodData?.[status]?.avgCreditScore || 0}
                  </TableCell>
                  <TableCell colSpan={2} style={TABLE_CELL_STYLE}>
                    {periodData?.[status]?.gapCount || 0}
                  </TableCell>
                  <TableCell
                    colSpan={2}
                    style={{ ...TABLE_CELL_STYLE, borderRight: "2px solid black" }}
                  >
                    {periodData?.[status]?.warrantyCount || 0}
                  </TableCell>
                </React.Fragment>
              );
            })}
          </TableRow>
        );
      }
    );
    return (
      <React.Fragment key={`row${i}`}>
        <TableRow style={{ background: i % 2 === 0 ? "#f3f3f3" : "#ffffff" }}>
          <TableCell rowSpan={5} style={TABLE_CELL_STYLE}>
            <div>
              <div style={{ textAlign: "center", fontWeight: "bold" }}>{dealershipName}</div>
              {data[dealershipName].contactEmail ? (
                <div>{data[dealershipName].contactEmail}</div>
              ) : (
                ""
              )}
              {data[dealershipName].contactPhone ? (
                <div>{data[dealershipName].contactPhone}</div>
              ) : (
                ""
              )}
              {data[dealershipName].contactNames ? (
                <div>{data[dealershipName].contactNames}</div>
              ) : (
                ""
              )}
            </div>
          </TableCell>
          <TableCell rowSpan={5} style={{ ...TABLE_CELL_STYLE, minWidth: "200px" }}>
            <AddCustomerCareAssignee
              dealership={dealerships?.find(
                (dealership) => dealership._id === data[dealershipName].dealershipId
              )}
            />
          </TableCell>
          <TableCell rowSpan={5} style={TABLE_CELL_STYLE}>
            <IconButton
              style={{ padding: 0, width: "10px" }}
              onClick={() =>
                handleOpenCustomerCareNotes(
                  dealerships?.find(
                    (dealership) => dealership._id === data[dealershipName].dealershipId
                  )
                )
              }
            >
              <VisibilityIcon />
            </IconButton>
          </TableCell>
          <TableCell rowSpan={5} style={TABLE_CELL_STYLE}>
            <DealsModal
              dealIds={
                dealerships?.find(
                  (dealership) => dealership._id === data[dealershipName].dealershipId
                )?.data.info.titleManagement?.dealsIds
              }
            />
          </TableCell>
          <TableCell rowSpan={5} style={TABLE_CELL_STYLE}>
            <LimitedTitleIssuesModal
              titleIssuesIds={
                dealerships?.find(
                  (dealership) => dealership._id === data[dealershipName].dealershipId
                )?.data.info.titleManagement?.titleIssuesIds
              }
            />
          </TableCell>
          <TableCell rowSpan={5} style={TABLE_CELL_STYLE}>
            <LatestTicketsModal
              titleIssuesIds={
                dealerships?.find(
                  (dealership) => dealership._id === data[dealershipName].dealershipId
                )?.data.info.titleManagement?.titleIssuesIds
              }
            />
          </TableCell>
        </TableRow>
        <TableRow style={{ background: i % 2 === 0 ? "#f3f3f3" : "#ffffff", textAlign: "center" }}>
          {dataLabels}
        </TableRow>
        {statusRows}
      </React.Fragment>
    );
  });

  return (
    <TableContainer style={{ overflowX: "inherit", overflowY: "auto", display: "block" }}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell style={TABLE_CELL_STYLE}></TableCell>
            <TableCell style={TABLE_CELL_STYLE}>Customer care assignee</TableCell>
            <TableCell style={TABLE_CELL_STYLE}>Customer care notes (Internal)</TableCell>
            <TableCell style={TABLE_CELL_STYLE}>Deals</TableCell>
            <TableCell style={TABLE_CELL_STYLE}>Outstanding Title Documents</TableCell>
            <TableCell style={TABLE_CELL_STYLE}>Lender Tickets</TableCell>

            {headers}
          </TableRow>
        </TableHead>
        <TableBody>{rows}</TableBody>
      </Table>
    </TableContainer>
  );
};

export default ({ dealershipId }: { dealershipId?: string }) => {
  const [today] = React.useState(new Date());
  const [states, setStates] = useStickyState<string[]>([], "dealership_performance_report_states");
  const dispatch = useDispatch();
  const listId = "dealershipsPerformance";
  const allLocks = useSelector((state: RootState) => state.listLockSlice["all"]);
  const dealershipList = useSelector((state: RootState) => state.listDealershipSlice[listId]);
  const userList = useSelector((state: RootState) => state.listUserSlice[listId]);
  const [customerCareAssignees, setCustomerCareAssignees] = useStickyState<User[]>(
    [],
    "dealership_performance_report_customer_care_assignees"
  );

  const [tabValue, setTabValue] = React.useState(0);
  const [dealershipName, setDealershipName] = React.useState<string>("");
  const debouncedDealershipName = useDebounce<string>(dealershipName, 1000);
  const [periodToggle, setToggle] = useStickyState<ToggleState>(
    undefined,
    "dealership_performance_report_state_periodToggle"
  );
  const [customerCareNotesDialog, setCustomerCareNotesDialog] = React.useState<{
    open: boolean;
    dealership: Dealership | undefined;
  }>({ open: false, dealership: undefined });
  const fromDefault = new Date(today.getFullYear(), today.getMonth() - 5, 1);
  const [state, setState] = useStickyState<RequestState>(
    {
      ...getFirstAndLastDateOfCurrentMonth(),
      customerCareAssigneeIds: [],
      allCustomerCareAssignees: true,
      dealershipStates: ["All states"],
      allDealershipStates: true,
      fromMonth: fromDefault.getMonth() + 1,
      fromYear: fromDefault.getFullYear(),
      toMonth: today.getMonth() + 1,
      toYear: today.getFullYear(),
      groupBy: "monthly"
    },
    "dealership_performance_report_state"
  );
  const { data, isFetching } = useDealershipPerformanceReport(
    state,
    debouncedDealershipName,
    dealershipId
  );

  React.useEffect(() => {
    if (allLocks?.entities === undefined) dispatch(getLockList("all"));
  }, [dispatch, allLocks]);
  React.useEffect(() => {
    if (!userList)
      dispatch(
        getUserList(listId, {
          options: { pagination: false, sort: { "data.info.firstName": "asc" } }
        })
      );
    return () => {
      dispatch(removeUserList(listId));
      return;
    };
  }, []);
  React.useEffect(() => {
    if (!dealershipList)
      dispatch(
        getDealershipList(listId, {
          options: {
            projection: {
              _id: 1,
              createdAt: 1,
              creatorId: 1,
              updatedAt: 1,
              "data.info.name": 1,
              "data.info.displayName": 1,
              "data.customerCareAssignee": 1,
              "data.customerCareNotes": 1,
              "data.info.titleManagement.dealsIds": 1,
              "data.representative.data.info.email": 1,
              "data.info.titleManagement.titleIssuesIds": 1
            },

            pagination: false
          },
          aggregateFirst: true
        })
      );
    return () => {
      dispatch(removeDealershipList(listId));
      return;
    };
  }, []);
  const handleOpenCustomerCareNotes = (dealership: Dealership | undefined) => {
    if (dealership) {
      setCustomerCareNotesDialog({ open: true, dealership });
    }
  };

  return (
    <React.Fragment>
      <Grid container xs={12} spacing={2}>
        <Grid container xs={12} item spacing={2} style={{ marginBottom: 10, marginTop: 10 }}>
          <Grid item xs={12}>
            <Typography style={{ fontWeight: "bold", fontSize: "19px", color: "rgb(37, 78, 110)" }}>
              Dealership performance report
            </Typography>
          </Grid>

          <Grid item container xs={12} spacing={1}>
            {!dealershipId ? (
              <Grid item xs={4} container>
                <Paper style={{ width: "100%", padding: "10px" }} elevation={2}>
                  <Grid container item xs={12} spacing={2}>
                    <Grid item xs={12}>
                      <Typography style={{ fontWeight: "bold", fontSize: "16px" }}>
                        Dealership state
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Autocomplete
                        style={{ zIndex: 6 }}
                        value={state.allDealershipStates ? ["All states"] : states || null}
                        getOptionLabel={(state) => {
                          return state;
                        }}
                        multiple
                        getOptionSelected={(x, y) => x === y}
                        options={["All states", ...Object.keys(States)]}
                        onChange={(event, newValue) => {
                          if (newValue?.length === 0) {
                            setState((prevState) => ({
                              ...prevState,
                              dealershipStates: ["All states"],
                              allDealershipStates: true
                            }));
                            setStates(["All states"]);
                          } else {
                            setStates(newValue.filter((x) => x !== "All states"));
                            setState((prevState) => ({
                              ...prevState,
                              dealershipStates: newValue.filter((x) => x !== "All states"),
                              allDealershipStates: false
                            }));
                          }
                        }}
                        openOnFocus
                        id="states-select"
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            InputLabelProps={{ shrink: true }}
                            InputProps={{ ...params.InputProps }}
                            label={"State"}
                            variant="filled"
                            size="small"
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            ) : null}
            {!dealershipId ? (
              <Grid item container xs={4}>
                <Paper style={{ width: "100%", padding: "10px" }} elevation={2}>
                  <Grid container item xs={12} spacing={1}>
                    <Grid item xs={12}>
                      <Typography style={{ fontWeight: "bold", fontSize: "16px" }}>
                        Customer care assignee
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Autocomplete
                        style={{ zIndex: 6 }}
                        value={
                          state.allCustomerCareAssignees
                            ? ((["All customer care assignees"] as unknown) as User[])
                            : customerCareAssignees || null
                        }
                        getOptionLabel={(user) => {
                          if (((user as unknown) as string) === "All customer care assignees") {
                            return (user as unknown) as string;
                          }
                          return (
                            [user.data.info.firstName, user.data.info.lastName]
                              .filter((x) => x)
                              .join(" ") ?? ""
                          );
                        }}
                        multiple
                        getOptionSelected={(x, y) => x?._id === y?._id && x === y}
                        options={[
                          ("All customer care assignees" as unknown) as User,
                          ...(userList?.entities ?? [])
                        ]}
                        onChange={(event, newValue) => {
                          if (newValue?.length === 0) {
                            setState((prevState) => ({
                              ...prevState,
                              customerCareAssigneeIds: [],
                              allCustomerCareAssignees: true
                            }));
                            setCustomerCareAssignees(([
                              "All customer care assignees"
                            ] as unknown) as User[]);
                          } else {
                            const filtered = newValue.filter(
                              (x) => x !== (("All customer care assignees" as unknown) as User)
                            );
                            setCustomerCareAssignees(filtered);
                            setState((prevState) => ({
                              ...prevState,
                              customerCareAssigneeIds: filtered.map((user) => user._id),
                              allCustomerCareAssignees: false
                            }));
                          }
                        }}
                        loading={!Array.isArray(userList?.entities)}
                        openOnFocus
                        id="dealership-performance-report-customer-care-assignee-select"
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            InputLabelProps={{ shrink: true }}
                            InputProps={{ ...params.InputProps }}
                            label={"Customer care assignee"}
                            variant="filled"
                            size="small"
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            ) : null}
            {!dealershipId ? (
              <Grid item xs={4} container>
                <Paper style={{ width: "100%", padding: "10px" }}>
                  <Grid item xs={12} style={{ marginBottom: "10px" }}>
                    <Typography style={{ fontWeight: "bold", fontSize: "16px" }}>
                      Search by dealership name
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Box
                      component={"div"}
                      style={{
                        padding: "10px",
                        borderRadius: "5px",
                        border: "1px solid #254e70",
                        gap: 10,
                        display: "flex",
                        alignItems: "center"
                      }}
                    >
                      <Box component="div">
                        <SearchIcon fontSize="small" />
                      </Box>
                      <InputBase
                        style={{
                          width: "100%",
                          fontSize: 14
                        }}
                        id="searchByDealerNameInput"
                        placeholder="Search..."
                        inputProps={{ "aria-label": "search" }}
                        value={dealershipName}
                        autoComplete="search"
                        onChange={(e) => setDealershipName(e.target.value)}
                      />
                    </Box>
                  </Grid>
                </Paper>
              </Grid>
            ) : null}
            <Grid item xs={6} container>
              <MonthYearSettings
                requestState={state}
                setRequestState={setState}
                setToggle={setToggle}
              />
            </Grid>
            <Grid item xs={3} container>
              <PredefinedRanges
                requestState={state}
                setRequestState={setState}
                toggleState={periodToggle}
                setToggle={setToggle}
              />
            </Grid>
            <Grid item xs={3} container>
              <GroupBy requestState={state} setRequestState={setState} />
            </Grid>
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          {!isFetching && dealershipList?.entities ? (
            <Paper style={{ width: "100%", tableLayout: "fixed", display: "table" }}>
              {dealershipId ? (
                data ? (
                  generateTable(
                    data.dealershipDataWithPeriods,
                    data.formattedPeriods,
                    dealershipList?.entities,
                    handleOpenCustomerCareNotes
                  )
                ) : (
                  <Typography style={{ fontSize: "14px", padding: "10px", textAlign: "center" }}>
                    No data
                  </Typography>
                )
              ) : (
                <React.Fragment>
                  <AppBar position="sticky" color="primary">
                    <Tabs
                      value={tabValue}
                      onChange={(_, newValue) => setTabValue(newValue)}
                      variant="scrollable"
                      scrollButtons="auto"
                      aria-label="scrollable auto tabs example"
                    >
                      <Tab label={"Dealerships with deals trend"} {...a11yProps(0)} />
                      <Tab label={"Dealerships without deals trend"} {...a11yProps(1)} />
                    </Tabs>
                  </AppBar>
                  <TabPanel value={tabValue} index={0}>
                    {data ? (
                      generateTable(
                        data.dealershipDataWithPeriods,
                        data.formattedPeriods,
                        dealershipList?.entities,
                        handleOpenCustomerCareNotes
                      )
                    ) : (
                      <Typography
                        style={{ fontSize: "14px", padding: "10px", textAlign: "center" }}
                      >
                        No data
                      </Typography>
                    )}
                  </TabPanel>
                  <TabPanel value={tabValue} index={1}>
                    {data ? (
                      generateTable(
                        data.dealershipDataWithPeriodsFlat,
                        data.formattedPeriods,
                        dealershipList?.entities,
                        handleOpenCustomerCareNotes
                      )
                    ) : (
                      <Typography style={{ textAlign: "center" }}>No data</Typography>
                    )}
                  </TabPanel>
                </React.Fragment>
              )}
            </Paper>
          ) : (
            <Spinner
              style={{
                position: "relative",
                display: "flex",
                height: "10%",
                justifyContent: "center",
                alignItems: "center"
              }}
            />
          )}
        </Grid>
      </Grid>
      {customerCareNotesDialog.open && (
        <CustomerCareNotesDialog
          handleClose={() => setCustomerCareNotesDialog({ open: false, dealership: undefined })}
          dealership={customerCareNotesDialog.dealership as Dealership}
        />
      )}
    </React.Fragment>
  );
};

type CustomerCareNotesDialogProps = {
  handleClose: () => void;
  dealership: Dealership;
};
export const CustomerCareNotesDialog = ({
  dealership,
  handleClose
}: CustomerCareNotesDialogProps) => {
  const notes = dealership.data.customerCareNotes;
  const createOrFocusTab = React.useContext(TabContext);
  const handleShowDealership = (id: string) => {
    createOrFocusTab({
      label: "Show page",
      index: "showPage",
      isForSidebar: false,
      isForQuickAccess: false,
      isPersistent: false,
      props: {
        _id: id,
        type: "dealership"
      }
    });
    handleClose();
  };
  return (
    <Dialog id="customer-care-notes" open={true} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle id="customer-care-notes-dialog-title">
        {dealership.data.info.displayName}
        <CloseDialogButton closeFunction={handleClose} />
      </DialogTitle>
      <DialogContent>
        <CustomerCareNotesPreview
          editable
          dealershipId={dealership._id}
          customerCareNotes={notes}
          repEmail={dealership?.data?.representative?.data?.info?.email}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleShowDealership(dealership._id)}
        >
          Open dealership
        </Button>
        <Button variant="contained" color="primary" onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
