import React, { useCallback } from "react";
import {
  AdvancedMarker,
  AdvancedMarkerAnchorPoint,
  useAdvancedMarkerRef
} from "@vis.gl/react-google-maps";
import { FaCar } from "react-icons/fa";
type TreeMarkerProps = {
  position: google.maps.LatLngLiteral;
  featureId: string;
  onMarkerClick?: (marker: google.maps.marker.AdvancedMarkerElement, featureId: string) => void;
};

export const FeatureMarkerDealership = ({
  position,
  featureId,
  onMarkerClick
}: TreeMarkerProps) => {
  const [markerRef, marker] = useAdvancedMarkerRef();
  const handleClick = useCallback(
    () => onMarkerClick && marker && onMarkerClick(marker, featureId),
    [onMarkerClick, marker, featureId]
  );
  return (
    <AdvancedMarker
      ref={markerRef}
      position={position}
      onClick={handleClick}
      anchorPoint={AdvancedMarkerAnchorPoint.CENTER}
    >
      <FaCar size={18} color={"	#905424"} />
    </AdvancedMarker>
  );
};
