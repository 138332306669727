import { Paper, Typography } from "@material-ui/core";
import { LenderStatistic } from "hooks/useLenderStatistics/types";
import React from "react";
import { formatNumberAsCurrency, percentageDifference } from "utils/functions";
import styles from "../LenderDashboard.module.css";
import { Big } from "big.js";
const AmountFinancedInformation = ({
  statistic,
  comparedStatistic,
  period
}: {
  statistic: LenderStatistic | undefined;
  comparedStatistic: LenderStatistic | undefined;
  period: string;
}) => {
  const potentionalAmountFinanced = new Big(statistic?.totalAmountFinanced || 0)
    .sub(new Big(statistic?.amountFinancedByStatus?.funded || 0))
    .toNumber();
  const comparedPotentionalAmountFinanced = new Big(comparedStatistic?.totalAmountFinanced || 0)
    .sub(new Big(comparedStatistic?.amountFinancedByStatus?.funded || 0))
    .toNumber();
  const avgAmountFinanced =
    new Big(statistic?.totalAmountFinanced || 0)
      .div(new Big(statistic?.totalDeals || 1))
      .round(2, 1)
      .toNumber() || 0;
  const comparedAvgAmountFinanced =
    new Big(comparedStatistic?.totalAmountFinanced || 0)
      .div(new Big(comparedStatistic?.totalDeals || 1))
      .round(2, 1)
      .toNumber() || 0;
  //TOTAL AMOUNT FINANCED
  const totalAmountFinancedDiff = percentageDifference(
    statistic?.totalAmountFinanced || 0,
    comparedStatistic?.totalAmountFinanced || 0
  );
  //TOTAL AMOUNT FINANCED

  //FUNDED AMOUNT FINANCED
  const fundedAmountFinancedDiff = percentageDifference(
    statistic?.amountFinancedByStatus?.funded || 0,
    comparedStatistic?.amountFinancedByStatus?.funded || 0
  );
  //FUNDED AMOUNT FINANCED

  //POTENTIONAL AMOUNT FINANCED
  const potentionalAmountFinancedDiff = percentageDifference(
    potentionalAmountFinanced || 0,
    comparedPotentionalAmountFinanced || 0
  );
  //POTENTIONAL AMOUNT FINANCED

  //AVERAGE AMOUNT FINANCED
  const avgAmountFinancedDiff = percentageDifference(
    avgAmountFinanced || 0,
    comparedAvgAmountFinanced || 0
  );
  //AVERAGE AMOUNT FINANCED

  //TOTAL GROSS REVENUE
  const totalGrossRevenueDiff = percentageDifference(
    statistic?.totalGrossRevenue || 0,
    comparedStatistic?.totalGrossRevenue || 0
  );
  //TOTAL GROSS REVENUE

  //GROSS REVENUE - FUNDED
  const fundedGrossRevenueDiff = percentageDifference(
    statistic?.grossRevenueByStatus?.funded || 0,
    comparedStatistic?.grossRevenueByStatus?.funded || 0
  );
  //TOTAL GROSS REVENUE - FUNDED

  //TOTAL PRODUCT GROSS REVENUE
  const totalProductGrossRevenueDiff = percentageDifference(
    statistic?.totalProductGrossRevenue || 0,
    comparedStatistic?.totalProductGrossRevenue || 0
  );
  //TOTAL PRODUCT GROSS REVENUE

  //PRODUCT GROSS REVENUE - FUNDED
  const fundedProductGrossRevenueDiff = percentageDifference(
    statistic?.grossRevenueByStatus?.funded || 0,
    comparedStatistic?.grossRevenueByStatus?.funded || 0
  );
  //PRODUCT GROSS REVENUE - FUNDED

  //TOTAL DEALS
  const totalDealsDiff = percentageDifference(
    statistic?.totalDeals || 0,
    comparedStatistic?.totalDeals || 0
  );
  //TOTAL DEALS
  return (
    <Paper style={{ padding: "20px 50px" }} className={styles.dashboardItem}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          marginBottom: 30,
          gap: "10px"
        }}
      >
        <Typography
          style={{
            fontWeight: 500,
            fontSize: 22,
            lineHeight: "20px",
            marginBottom: 10,
            textAlign: "center"
          }}
        >
          Amount financed information
        </Typography>

        <Typography style={{ textAlign: "center" }}>{period}</Typography>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: 20
          }}
        >
          <Typography
            style={{ fontWeight: 500, fontSize: 19, lineHeight: "20px", color: "#6A6A6A" }}
          >
            Total Amount Financed (per selection)
          </Typography>

          <div style={{ display: "flex", alignItems: "center", marginLeft: "auto" }}>
            <Typography style={{ fontWeight: 500, fontSize: 22, lineHeight: "23px" }}>
              {formatNumberAsCurrency(statistic?.totalAmountFinanced || 0, "$")}
            </Typography>

            {comparedStatistic?.totalAmountFinanced ? (
              <Typography
                style={{
                  fontSize: "10px",
                  color: totalAmountFinancedDiff > 0 ? "green" : "red"
                }}
              >
                {`(${totalAmountFinancedDiff > 0 ? "+" : "-"}${totalAmountFinancedDiff.toFixed(
                  2
                )}%)`}
              </Typography>
            ) : null}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: 20
          }}
        >
          <Typography
            style={{ fontWeight: 500, fontSize: 19, lineHeight: "20px", color: "#6A6A6A" }}
          >
            Total Amount Financed (Funded)
          </Typography>
          <div style={{ display: "flex", alignItems: "center", marginLeft: "auto" }}>
            <Typography style={{ fontWeight: 500, fontSize: 22, lineHeight: "23px" }}>
              {formatNumberAsCurrency(statistic?.amountFinancedByStatus?.funded || 0, "$")}
            </Typography>

            {comparedStatistic?.amountFinancedByStatus?.funded ? (
              <Typography
                style={{
                  fontSize: "10px",
                  color: fundedAmountFinancedDiff > 0 ? "green" : "red"
                }}
              >
                {`(${fundedAmountFinancedDiff > 0 ? "+" : "-"}${fundedAmountFinancedDiff.toFixed(
                  2
                )}%)`}
              </Typography>
            ) : null}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: 20
          }}
        >
          <Typography
            style={{ fontWeight: 500, fontSize: 19, lineHeight: "20px", color: "#6A6A6A" }}
          >
            Total Potentional Funded Amount
          </Typography>
          <div style={{ display: "flex", alignItems: "center", marginLeft: "auto" }}>
            <Typography style={{ fontWeight: 500, fontSize: 22, lineHeight: "23px" }}>
              {formatNumberAsCurrency(potentionalAmountFinanced || 0, "$")}
            </Typography>

            {comparedPotentionalAmountFinanced ? (
              <Typography
                style={{
                  fontSize: "10px",
                  color: potentionalAmountFinancedDiff > 0 ? "green" : "red"
                }}
              >
                {`(${
                  potentionalAmountFinancedDiff > 0 ? "+" : "-"
                }${potentionalAmountFinancedDiff.toFixed(2)}%)`}
              </Typography>
            ) : null}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: 20
          }}
        >
          <Typography
            style={{ fontWeight: 500, fontSize: 19, lineHeight: "20px", color: "#6A6A6A" }}
          >
            Average amount financed
          </Typography>
          <div style={{ display: "flex", alignItems: "center", marginLeft: "auto" }}>
            <Typography style={{ fontWeight: 500, fontSize: 22, lineHeight: "23px" }}>
              {formatNumberAsCurrency(avgAmountFinanced, "$")}
            </Typography>

            {comparedAvgAmountFinanced ? (
              <Typography
                style={{
                  fontSize: "10px",
                  color: avgAmountFinancedDiff > 0 ? "green" : "red"
                }}
              >
                {`(${avgAmountFinancedDiff > 0 ? "+" : "-"}${avgAmountFinancedDiff.toFixed(2)}%)`}
              </Typography>
            ) : null}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: 20
          }}
        >
          <Typography
            style={{ fontWeight: 500, fontSize: 19, lineHeight: "20px", color: "#6A6A6A" }}
          >
            Total Gross Revenue (per selection)
          </Typography>
          <div style={{ display: "flex", alignItems: "center", marginLeft: "auto" }}>
            <Typography style={{ fontWeight: 500, fontSize: 22, lineHeight: "23px" }}>
              {formatNumberAsCurrency(statistic?.totalGrossRevenue || 0, "$")}
            </Typography>

            {comparedStatistic?.totalGrossRevenue ? (
              <Typography
                style={{
                  fontSize: "10px",
                  color: totalGrossRevenueDiff > 0 ? "green" : "red"
                }}
              >
                {`(${totalGrossRevenueDiff > 0 ? "+" : "-"}${totalGrossRevenueDiff.toFixed(2)}%)`}
              </Typography>
            ) : null}
          </div>
          <Typography style={{ fontWeight: 500, fontSize: 22, lineHeight: "23px" }}></Typography>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: 20
          }}
        >
          <Typography
            style={{ fontWeight: 500, fontSize: 19, lineHeight: "20px", color: "#6A6A6A" }}
          >
            Total Gross Revenue (Funded)
          </Typography>
          <div style={{ display: "flex", alignItems: "center", marginLeft: "auto" }}>
            <Typography style={{ fontWeight: 500, fontSize: 22, lineHeight: "23px" }}>
              {formatNumberAsCurrency(statistic?.grossRevenueByStatus?.funded || 0, "$")}
            </Typography>

            {comparedStatistic?.grossRevenueByStatus?.funded ? (
              <Typography
                style={{
                  fontSize: "10px",
                  color: fundedGrossRevenueDiff > 0 ? "green" : "red"
                }}
              >
                {`(${fundedGrossRevenueDiff > 0 ? "+" : "-"}${fundedGrossRevenueDiff.toFixed(2)}%)`}
              </Typography>
            ) : null}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: 20
          }}
        >
          <Typography
            style={{ fontWeight: 500, fontSize: 19, lineHeight: "20px", color: "#6A6A6A" }}
          >
            Total Product Gross Revenue (per selection)
          </Typography>
          <div style={{ display: "flex", alignItems: "center", marginLeft: "auto" }}>
            <Typography style={{ fontWeight: 500, fontSize: 22, lineHeight: "23px" }}>
              {formatNumberAsCurrency(statistic?.totalProductGrossRevenue || 0, "$")}
            </Typography>

            {comparedStatistic?.totalProductGrossRevenue ? (
              <Typography
                style={{
                  fontSize: "10px",
                  color: totalProductGrossRevenueDiff > 0 ? "green" : "red"
                }}
              >
                {`(${
                  totalProductGrossRevenueDiff > 0 ? "+" : "-"
                }${totalProductGrossRevenueDiff.toFixed(2)}%)`}
              </Typography>
            ) : null}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: 20
          }}
        >
          <Typography
            style={{ fontWeight: 500, fontSize: 19, lineHeight: "20px", color: "#6A6A6A" }}
          >
            Total Product Gross Revenue (Funded)
          </Typography>
          <div style={{ display: "flex", alignItems: "center", marginLeft: "auto" }}>
            <Typography style={{ fontWeight: 500, fontSize: 22, lineHeight: "23px" }}>
              {formatNumberAsCurrency(statistic?.productGrossRevenueByStatus?.funded || 0, "$")}
            </Typography>

            {comparedStatistic?.productGrossRevenueByStatus?.funded ? (
              <Typography
                style={{
                  fontSize: "10px",
                  color: fundedProductGrossRevenueDiff > 0 ? "green" : "red"
                }}
              >
                {`(${
                  fundedProductGrossRevenueDiff > 0 ? "+" : "-"
                }${fundedProductGrossRevenueDiff.toFixed(2)}%)`}
              </Typography>
            ) : null}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: 20
          }}
        >
          <Typography
            style={{ fontWeight: 500, fontSize: 19, lineHeight: "20px", color: "#6A6A6A" }}
          >
            Total deal count
          </Typography>
          <div style={{ display: "flex", alignItems: "center", marginLeft: "auto" }}>
            <Typography style={{ fontWeight: 500, fontSize: 22, lineHeight: "23px" }}>
              {statistic?.totalDeals || 0}
            </Typography>

            {comparedStatistic?.totalDeals ? (
              <Typography
                style={{
                  fontSize: "10px",
                  color: totalDealsDiff > 0 ? "green" : "red"
                }}
              >
                {`(${totalDealsDiff > 0 ? "+" : "-"}${totalDealsDiff.toFixed(2)}%)`}
              </Typography>
            ) : null}
          </div>
          <Typography style={{ fontWeight: 500, fontSize: 22, lineHeight: "23px" }}></Typography>
        </div>
      </div>
    </Paper>
  );
};

export default AmountFinancedInformation;
