export default {
  type: "object",
  properties: {
    prediction: {
      type: "object",
      properties: {
        decision: {
          type: "object",
          properties: {
            label: {
              type: "string",
              nullable: true
            },
            probability: {
              type: "number",
              nullable: true
            }
          }
        },
        financial_info: {
          type: "object",
          properties: {
            amountFinanced: {
              type: "number",
              nullable: true
            },
            DTI: {
              type: "number",
              nullable: true
            },
            LTV: {
              type: "number",
              nullable: true
            },
            NADA: {
              type: "number",
              nullable: true
            }
          }
        },
        vehicle: {
          type: "object",
          properties: {
            make: {
              type: "string",
              nullable: true
            },
            model: {
              type: "string",
              nullable: true
            },
            year: {
              type: "number",
              nullable: true
            }
          }
        },
        isCorrect: {
          type: "boolean",
          nullable: true
        },
        predictionFailed: {
          type: "boolean",
          nullable: true
        }
      }
    },
    lenderPrediction: {
      type: "object",
      additionalProperties: true,
      nullable: true
    }
  }
};
