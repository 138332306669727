import {
  multyPermissionNode,
  permissionNode,
  PermissionStruct,
  relationPermissionNode
} from "components/Roles/types";
import { Deal } from "./types";

export type DealPermissionsStruct = PermissionStruct<Deal>;

export const dealPermissionsStruct: DealPermissionsStruct = {
  _id: permissionNode("Id"),
  createdAt: permissionNode("Created at date"),
  updatedAt: permissionNode("Updated at date"),
  deleted: permissionNode("Is deleted"),
  creatorId: permissionNode("Creator Id"),
  approved: permissionNode("Approved"),
  approvedAt: permissionNode("approvedAt"),
  approvedByUserId: permissionNode("approvedByUserId"),
  approvedByUser: relationPermissionNode("Approved by", "wfd_user"),
  availableToRoles: permissionNode("Available to roles"),
  availableToRolesIds: permissionNode("Available to roles Ids"),
  data: {
    info: {
      wasInStatus: {
        lead: permissionNode("Was in status lead"),
        approved: permissionNode("Was in status approved"),
        cancelled: permissionNode("Was in status cancelled"),
        countered: permissionNode("Was in status countered"),
        "credit check": permissionNode("Was in status credit check"),
        "sent to dealer": permissionNode("Was in status sent to dealer"),
        "submitted for funding": permissionNode("Was in status submitted for funding"),
        funded: permissionNode("Was in status funded"),
        "funding held": permissionNode("Was in status funding held"),
        dead: permissionNode("Was in status dead"),
        denied: permissionNode("Was in status denied"),
        "pending lender decision": permissionNode("Was in status pending lender decision"),
        "waiting for deal details": permissionNode("Was in status waiting for deal details"),
        "did not process": permissionNode("Was in status did not process"),
        "deal created": permissionNode("Was in status deal created")
      },
      isInStatus: {
        lead: permissionNode("Is in status lead"),
        approved: permissionNode("Is in status approved"),
        cancelled: permissionNode("Is in status cancelled"),
        countered: permissionNode("Is in status countered"),
        "credit check": permissionNode("Is in status credit check"),
        "sent to dealer": permissionNode("Is in status sent to dealer"),
        "submitted for funding": permissionNode("Is in status submitted for funding"),
        funded: permissionNode("Is in status funded"),
        "funding held": permissionNode("Is in status funding held"),
        dead: permissionNode("Is in status dead"),
        denied: permissionNode("Is in status denied"),
        "pending lender decision": permissionNode("Is in status pending lender decision"),
        "waiting for deal details": permissionNode("Is in status waiting for deal details"),
        "did not process": permissionNode("Is in status did not process"),
        "deal created": permissionNode("Is in status deal created")
      },
      lastStatusHistory: {
        status: permissionNode("Last status history status"),
        reason: permissionNode("Last status history reason"),
        date: permissionNode("Last status history date"),
        userEmail: permissionNode("Last status history user email")
      },
      statusReasons: {
        manager: {
          marked: permissionNode("Status Reason Manager Marked"),
          status: permissionNode("Status Reason Manager Status"),
          date: permissionNode("Status Reason Manager Date"),
          reason: permissionNode("Status Reason Manager Reason"),
          userId: permissionNode("Status Reason Manager User Id")
        },
        dealership: {
          marked: permissionNode("Status Reason Dealership Marked"),
          status: permissionNode("Status Reason Dealership Status"),
          date: permissionNode("Status Reason Dealership Date"),
          reason: permissionNode("Status Reason Dealership Reason"),
          userId: permissionNode("Status Reason Dealership User Id")
        },
        applicant: {
          marked: permissionNode("Status Reason Applicant Marked"),
          stillConsidering: permissionNode("Status Reason Applicant Still Considering"),
          status: permissionNode("Status Reason Applicant Status"),
          date: permissionNode("Status Reason Applicant Date"),
          reason: permissionNode("Status Reason Applicant Reason")
        }
      },
      extractedCreditScores: {
        applicant: {
          score: permissionNode("Applicant extracted credit score")
        },
        coApplicant: {
          score: permissionNode("Applicant extracted credit score")
        }
      },
      totalIncomes: {
        applicant: {
          monthly: permissionNode("Applicant total monthly income"),
          annual: permissionNode("Applicant total annual income")
        },
        coApplicant: {
          monthly: permissionNode("Applicant total monthly income"),
          annual: permissionNode("Applicant total annual income")
        }
      },
      fundingPaymentMethod: permissionNode("Funding payment method"),
      adfEmailSent: permissionNode("Adf email sent"),
      status: permissionNode("Status"),
      statusHistory: multyPermissionNode("Status History"),
      documentsHistory: multyPermissionNode("Documents History"),
      assignedDealershipEmails: multyPermissionNode("Assigned Dealership Emails"),
      collateralReceivedDate: permissionNode("Collateral received Date"),
      creditCheckDate: permissionNode("Credit check date"),
      type: permissionNode("Type"),
      emailInfo: {
        totalValueOfUnit: permissionNode("Total value of unit"),
        applicant: permissionNode("Applicant"),
        coApplicant: permissionNode("Co applicant")
      },
      refNumber: permissionNode("Ref number"),
      driverlicense: permissionNode("Driver license"),
      vehicle: {
        unitStatus: permissionNode("Unit status"),
        invoice: permissionNode("Invoice"),
        unitType: permissionNode("Unit type"),
        VIN: permissionNode("Vin"),
        isVinDecoded: permissionNode("Is vin decoded"),
        year: permissionNode("Year"),
        make: permissionNode("Make"),
        model: permissionNode("Model"),
        odometer: permissionNode("Odometer"),
        MSRP: permissionNode("Msrp"),
        boat: {
          type: permissionNode("Type"),
          length: permissionNode("Length"),
          engineType: permissionNode("Engine type"),
          numberOfEngines: permissionNode("Number of engines"),
          engine: {
            engineNumber: permissionNode("Engine number"),
            unitStatus: permissionNode("Unit status"),
            engineNumbers: permissionNode("Engine numbers"),
            year: permissionNode("Year"),
            make: permissionNode("Make"),
            model: permissionNode("Model")
          },
          secondEngine: {
            engineNumber: permissionNode("Engine number"),
            unitStatus: permissionNode("Unit status"),
            engineNumbers: permissionNode("Engine numbers"),
            year: permissionNode("Year"),
            make: permissionNode("Make"),
            model: permissionNode("Model")
          },
          hull: {
            hullNumber: permissionNode("Hull number"),
            year: permissionNode("Year"),
            make: permissionNode("Make"),
            model: permissionNode("Model"),
            hullMaterial: permissionNode("Hull material"),
            hullValue: permissionNode("Hull value")
          },
          horsepower: permissionNode("Horsepower"),
          cc: permissionNode("Cc"),
          maxSpeed: permissionNode("Max speed"),
          enginesValue: permissionNode("Engines value")
        },
        hasTrailer: permissionNode("Has trailer"),
        trailer: {
          year: permissionNode("Year"),
          make: permissionNode("Make"),
          model: permissionNode("Model"),
          unitStatus: permissionNode("Unit status"),
          serialNumber: permissionNode("Serial number")
        },

        land: {
          year: permissionNode("Year"),
          make: permissionNode("Make"),
          model: permissionNode("Model"),
          VIN: permissionNode("Vin"),
          unitType: permissionNode("Unit type")
        },
        powerSport: { cc: permissionNode("Cc") }
      },
      user: relationPermissionNode("F&I Manager", "wfd_user"),
      price: {
        price: permissionNode("Price"),

        options: permissionNode("Options"),
        rebates: permissionNode("Rebates"),
        totalCash: permissionNode("Total cash"),
        trade: permissionNode("Trade"),
        totalTrade: permissionNode("totalTrade"),
        payoff: permissionNode("Payoff"),
        netTrade: permissionNode("Net trade")
      },
      payment: {
        type: permissionNode("Type"),
        numberOfPayments: permissionNode("Number of payments"),
        daysToFirstPayment: permissionNode("Days to first payment"),
        interestRate: permissionNode("Interest rate"),
        dealTotal: permissionNode("Deal total"),
        downPayment: permissionNode("Down payment"),
        amountFinanced: permissionNode("Amount financed"),
        totalAftermarket: permissionNode("Total aftermarket"),
        totalTaxes: permissionNode("Total taxes"),
        localTaxes: permissionNode("Local taxes"),
        stateTaxes: permissionNode("State taxes"),
        taxableAmount: permissionNode("Taxable amount"),
        totalFees: permissionNode("Total fees"),
        monthlyPayment: permissionNode("Monthly payment"),
        totalOfPayments: permissionNode("Total of payments"),
        totalSalePrice: permissionNode("Total sale price"),
        fundedAmount: permissionNode("Funded amount"),
        buyPercent: permissionNode("Buy percent"),
        wfdSplit: permissionNode("Wfd split"),
        dealershipSplit: permissionNode("Dealership split"),
        wfdGAPSplit: permissionNode("Wfd GAP split"),
        dealershipGAPSplit: permissionNode("Dealership split"),
        wfdWarrantySplit: permissionNode("Wfd Warranty split"),
        dealershipWarrantySplit: permissionNode("Dealership split"),
        splitMethod: permissionNode("Split method"),
        reserve: permissionNode("Reserve"),
        reserveMethod: permissionNode("Reserve method"),
        correspondentPercent: permissionNode("Correspondent Percent"),

        maxReserve: permissionNode("Max reserve"),
        markupData: {
          isDefault: permissionNode("isDefault"),
          markup: permissionNode("markup"),
          reservePercentage: permissionNode("reservePercentage"),
          dealerSplitPercentage: permissionNode("dealerSplitPercentage"),
          extraReserveFixed: permissionNode("extraReserveFixed"),
          extraReservePercentage: permissionNode("extraReservePercentage")
        }
      },
      profit: {
        grossRevenue: permissionNode("Gross Revenue"),
        productGrossRevenue: permissionNode("Product Gross Revenue"),
        totalServiceWarrantyProfit: permissionNode("Total service warranty profit"),
        totalGAPProfit: permissionNode("Total gap profit"),
        grossProfit: permissionNode("Gross profit"),
        totalExtraProfit: permissionNode("Gross profit"),
        grossProfitWithExtra: permissionNode("WFD Gross profit"),
        dealershipProfit: {
          totalProfitFees: permissionNode("Total Profit Fees"),
          totalDealProfit: permissionNode("Total Deal Profit"),
          reserveCommission: permissionNode("Reserve commission"),
          splitFromDeal: permissionNode("Split from deal"),
          splitTotalFromServiceWarranty: permissionNode("Split total from service warranty"),
          splitTotalFromGap: permissionNode("Split total from gap"),
          totalProfit: permissionNode("Total profit"),
          totalProfitWFD: permissionNode("Total profit wfd")
        },
        wfdProfit: {
          totalProductsProfit: permissionNode("Total Products Profit"),
          totalReserveProfit: permissionNode("Total Reserve Profit"),
          reserveCommission: permissionNode("Reserve commission"),
          splitFromDeal: permissionNode("Split from deal"),
          splitTotalFromServiceWarranty: permissionNode("Split total from service warranty"),
          splitTotalFromGap: permissionNode("Split total from gap"),
          totalProfit: permissionNode("Total profit"),
          splitFromVehicleCoverage: permissionNode("Split from vehicle coverage"),
          extraGAPProfit: permissionNode("Extra gap profit"),
          extraServiceWarrantyProfit: permissionNode("Extra service warranty profit"),
          reserveMethodType: permissionNode("Reserve method type"),
          extraReserveProfit: permissionNode("Extra reserve profit"),
          reserverPercentage: permissionNode("Reserver percentage")
        },
        representativeProfit: { commission: permissionNode("Commission") },
        managerProfit: {
          commission: permissionNode("Commission"),
          chargebackPercentProduct: permissionNode("Product chargeback"),
          chargebackPercentReserve: permissionNode("Reserve chargeback"),
          ascCommission: permissionNode("ASC Commission"),
          gapCommission: permissionNode("Gap Commission"),
          productCommission: permissionNode("Product Commission"),
          reserveCommission: permissionNode("Reserve Commission"),
          commissionableAmount: permissionNode("Commissionable Amount")
        }
      },
      vehicleCoverage: {
        amountOfPremium: permissionNode("Amount of premium"),
        commissionPercent: permissionNode("Commission percent")
      },
      aftermarketOptions: {
        totalGAPSellPrice: permissionNode("Total gap sell price"),
        totalServiceWarrantySellPrice: permissionNode("Total service warranty sell price"),
        totalPaintAndFabricationPrice: permissionNode("Total paint and fabrication price"),
        totalPrePaidMaintenancePrice: permissionNode("Total pre paid maintenance price"),
        totalWheelAndTirePrice: permissionNode("Total wheel and tire price"),
        totalEcoPrice: permissionNode("Total eco price"),
        totalGAPRemitPrice: permissionNode("Total Gap Remit price"),
        totalServiceWarrantyRemitPrice: permissionNode("Total Warranty Remit price"),
        totalGAPDealerCostPrice: permissionNode("Total Gap Dealer Cost price"),
        totalServiceWarrantyDealerCostPrice: permissionNode("Total Warranty Dealer Cost price"),
        insurances: multyPermissionNode("Insurances")
      },
      taxesAndFees: {
        isFixedTax: permissionNode("Is fixed tax"),
        titleTransfer: permissionNode("Title transfer"),
        license: permissionNode("License"),
        documentRegistrationFees: permissionNode("Document registration fees"),
        filingFees: permissionNode("Filing fees"),
        otherFees: permissionNode("Other fees"),
        fundingFees: multyPermissionNode("Funding Fees"),
        totalMembershipFees: permissionNode("Total membership fees"),
        totalUCCFees: permissionNode("Total ucc fees"),
        wfdMinimumFee: permissionNode("WFD minimum fee"),
        acquisitionFee: permissionNode("Acquisition fee"),
        lenderAcquisitionFee: permissionNode("Lender Acquisition fee"),
        detailed: {
          registrationTax: permissionNode("Registration tax"),
          plateFee: permissionNode("Plate fee"),
          tempPlateFee: permissionNode("Temp Plate fee"),
          wheelageTax: permissionNode("Wheelage tax"),
          psVehicleFee: permissionNode("Ps vehicle fee"),
          transferTax: permissionNode("Transfer tax"),
          titleTransferFee: permissionNode("Title transfer fee"),
          techSurchargeFee: permissionNode("Tech surcharge fee"),
          lienFee: permissionNode("Lien fee"),
          deputyFilingFee: permissionNode("Deputy filing fee"),
          exciseTax: permissionNode("Excise tax"),
          licenseAndRegFee: permissionNode("License and reg fee"),
          processingFee: permissionNode("Processing fee"),
          contributionFee: permissionNode("Contribution fee")
        }
      },
      dateAudits: multyPermissionNode("Date Audits"),
      dealDates: {
        submittedForFunding: permissionNode("Contract date"),
        contractDate: permissionNode("Contract date"),
        fundedAt: permissionNode("Funded at"),
        payrollDate: permissionNode("Payroll date"),
        firstPaymentDate: permissionNode("First payment date")
      },
      files: relationPermissionNode("Files", "file"),
      creditScores: multyPermissionNode("creditScores"),
      accounting: {
        copied: permissionNode("copied"),
        fundingNoticeDate: permissionNode("fundingNoticeDate"),
        collateralFundingDate: permissionNode("collateralFundingDate"),
        reserveFundingDate: permissionNode("reserveFundingDate"),
        warrantyPrice: permissionNode("warrantyPrice"),
        warrantyCost: permissionNode("warrantyCost"),
        gapPrice: permissionNode("gapPrice"),
        prePaidMaintenancePrice: permissionNode("prePaidMaintenancePrice"),
        paintAndFabricationPrice: permissionNode("paintAndFabricationPrice"),
        ecoPrice: permissionNode("ecoPrice"),
        wheelAndTirePrice: permissionNode("wheelAndTirePrice"),
        gapRemit: permissionNode("gapRemit"),
        warrantyRemit: permissionNode("warrantyRemit"),
        gapCost: permissionNode("gapCost"),
        payment: {
          type: permissionNode("Type"),
          numberOfPayments: permissionNode("Number of payments"),
          daysToFirstPayment: permissionNode("Days to first payment"),
          interestRate: permissionNode("Interest rate"),
          dealTotal: permissionNode("Deal total"),
          downPayment: permissionNode("Down payment"),
          amountFinanced: permissionNode("Amount financed"),
          totalAftermarket: permissionNode("Total aftermarket"),
          totalTaxes: permissionNode("Total taxes"),
          localTaxes: permissionNode("Local taxes"),
          stateTaxes: permissionNode("State taxes"),
          taxableAmount: permissionNode("Taxable amount"),
          totalFees: permissionNode("Total fees"),
          monthlyPayment: permissionNode("Monthly payment"),
          totalOfPayments: permissionNode("Total of payments"),
          totalSalePrice: permissionNode("Total sale price"),
          fundedAmount: permissionNode("Funded amount"),
          buyPercent: permissionNode("Buy percent"),
          wfdSplit: permissionNode("Wfd split"),
          splitMethod: permissionNode("Split method"),
          reserve: permissionNode("Reserve"),
          reserveMethod: permissionNode("Reserve method"),
          correspondentPercent: permissionNode("Correspondent Percent"),
          dealershipSplit: permissionNode("Dealership split"),
          wfdGAPSplit: permissionNode("Wfd GAP split"),
          dealershipGAPSplit: permissionNode("Dealership split"),
          wfdWarrantySplit: permissionNode("Wfd Warranty split"),
          dealershipWarrantySplit: permissionNode("Dealership split"),
          maxReserve: permissionNode("Max reserve"),
          markupData: {
            isDefault: permissionNode("isDefault"),
            markup: permissionNode("markup"),
            reservePercentage: permissionNode("reservePercentage"),
            dealerSplitPercentage: permissionNode("dealerSplitPercentage"),
            extraReserveFixed: permissionNode("extraReserveFixed"),
            extraReservePercentage: permissionNode("extraReservePercentage")
          }
        },
        profit: {
          grossRevenue: permissionNode("Gross Revenue"),
          productGrossRevenue: permissionNode("Product Gross Revenue"),
          totalServiceWarrantyProfit: permissionNode("Total service warranty profit"),
          totalGAPProfit: permissionNode("Total gap profit"),
          grossProfit: permissionNode("Gross profit"),
          totalExtraProfit: permissionNode("Gross profit"),
          grossProfitWithExtra: permissionNode("WFD Gross profit"),
          dealershipProfit: {
            reserveCommission: permissionNode("Reserve commission"),
            splitFromDeal: permissionNode("Split from deal"),
            totalProfitFees: permissionNode("Total Profit Fees"),
            totalDealProfit: permissionNode("Total Deal Profit"),
            splitTotalFromServiceWarranty: permissionNode("Split total from service warranty"),
            splitTotalFromGap: permissionNode("Split total from gap"),
            totalProfit: permissionNode("Total profit"),
            totalProfitWFD: permissionNode("Total profit wfd")
          },
          wfdProfit: {
            totalProductsProfit: permissionNode("Total Products Profit"),
            totalReserveProfit: permissionNode("Total Reserve Profit"),
            reserveCommission: permissionNode("Reserve commission"),
            splitFromDeal: permissionNode("Split from deal"),
            splitTotalFromServiceWarranty: permissionNode("Split total from service warranty"),
            splitTotalFromGap: permissionNode("Split total from gap"),
            totalProfit: permissionNode("Total profit"),
            splitFromVehicleCoverage: permissionNode("Split from vehicle coverage"),
            extraGAPProfit: permissionNode("Extra gap profit"),
            extraServiceWarrantyProfit: permissionNode("Extra service warranty profit"),
            reserveMethodType: permissionNode("Reserve method type"),
            extraReserveProfit: permissionNode("Extra reserve profit"),
            reserverPercentage: permissionNode("Reserver percentage")
          },
          representativeProfit: { commission: permissionNode("Commission") },
          managerProfit: {
            commissionableAmount: permissionNode("Commissionable Amount"),
            chargebackPercentProduct: permissionNode("Product chargeback"),
            chargebackPercentReserve: permissionNode("Reserve chargeback"),
            gapCommission: permissionNode("Gap Commission"),
            ascCommission: permissionNode("ASC Commission"),
            commission: permissionNode("Commission"),
            productCommission: permissionNode("Product Commission"),
            reserveCommission: permissionNode("Reserve Commission")
          }
        },
        taxesAndFees: {
          isFixedTax: permissionNode("Is fixed tax"),
          titleTransfer: permissionNode("Title transfer"),
          license: permissionNode("License"),
          documentRegistrationFees: permissionNode("Document registration fees"),
          filingFees: permissionNode("Filing fees"),
          otherFees: permissionNode("Other fees"),
          fundingFees: multyPermissionNode("Funding Fees"),
          totalMembershipFees: permissionNode("Total membership fees"),
          totalUCCFees: permissionNode("Total ucc fees"),
          wfdMinimumFee: permissionNode("WFD minimum fee"),
          acquisitionFee: permissionNode("Acquisition fee"),
          lenderAcquisitionFee: permissionNode("Lender Acquisition fee"),
          detailed: {
            registrationTax: permissionNode("Registration tax"),
            plateFee: permissionNode("Plate fee"),
            tempPlateFee: permissionNode("Temp Plate fee"),
            wheelageTax: permissionNode("Wheelage tax"),
            psVehicleFee: permissionNode("Ps vehicle fee"),
            transferTax: permissionNode("Transfer tax"),
            titleTransferFee: permissionNode("Title transfer fee"),
            techSurchargeFee: permissionNode("Tech surcharge fee"),
            lienFee: permissionNode("Lien fee"),
            deputyFilingFee: permissionNode("Deputy filing fee"),
            exciseTax: permissionNode("Excise tax"),
            licenseAndRegFee: permissionNode("License and reg fee"),
            processingFee: permissionNode("Processing fee"),
            contributionFee: permissionNode("Contribution fee")
          }
        }
      }
    },
    source: {
      type: permissionNode("Source Type"),
      applicationId: permissionNode("Source Application Id")
    },
    dealPrediction: relationPermissionNode("Deal Prediction", "deal_prediction"),
    netsuiteDealStatus: relationPermissionNode("Netsuite Deal Status", "netsuite_deal_status"),
    applicant: relationPermissionNode("Applicant", "applicant"),
    coApplicant: relationPermissionNode("Co-applicant", "applicant"),
    applicantId: permissionNode("Applicant id"),
    coApplicantId: permissionNode("Co applicant id"),
    titleIssueId: permissionNode("Title Issue Id"),
    lender: relationPermissionNode("Lender", "lender"),
    payoffBank: relationPermissionNode("Payoff bank", "payoff_bank"),
    stateTax: relationPermissionNode("State tax", "state"),
    user: relationPermissionNode("User", "wfd_user"),
    userId: permissionNode("User id"),
    dealership: relationPermissionNode("Dealership", "dealership"),
    vehicleInsuranceCompany: relationPermissionNode(
      "Vehicle insurance company",
      "vehicle_insurance_company"
    ),
    notes: relationPermissionNode("Notes", "note"),
    dealerNotes: relationPermissionNode("Dealer Notes", "dealer_note"),
    stipulations: relationPermissionNode("Stipulations", "stipulation"),
    appOneApplicationNumber: permissionNode("App one application number"),
    appOneApplicationURL: permissionNode("App one application url"),
    dealertrackDealNumbers: permissionNode("Dealertrack deal numbers"),
    dealershipId: permissionNode("Dealership id"),
    lenderId: permissionNode("Lender id"),
    appOneData: relationPermissionNode("App one data", "appone_import_application"),
    creditSmartsId: permissionNode("Credit smarts id"),
    creditSmartsDate: permissionNode("Credit smarts date"),
    creditBureaus: relationPermissionNode("Credit Bureaus", "credit_score"),
    creditPrequalify: relationPermissionNode("Credit Prequalify", "credit_score"),
    order: permissionNode("Order"),
    //@ts-ignore
    oneSpanSignings: multyPermissionNode("One sspan signigns")
  }
};
