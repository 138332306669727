import DealershipProgramPreview from "components/DealershipPrograms/DealershipProgramPreview";
import DealershipPreview from "components/Dealerships/DealershipPreview";
import LenderPreview from "components/Lenders/LenderPreview";
import ManagerProgramPreview from "components/ManagerPrograms/ManagerProgramPreview";
import RolesPreview from "components/Roles/RolesPreview";
import ResetPassButton from "components/Users/ResetPassButton";
import React from "react";
import formatDate from "utils/formatDate";
import { getTabName } from "utils/functions";
import { createOptionsForSelect, FormComponent, Model } from "utils/models/fields";
import { RenderSet, StateAccess } from "utils/models/formGenerator";
import toNormalCase from "utils/toNormalCase";
import { Condition } from "../../utils/types";
import AllocationPeriods from "./AllocationPeriods";
import DisableUser from "./DisableUser";
import LoginAsDealer from "./LoginAsDealer";
import MFAButton from "./MFAButton";
import { Commission, UnapprovedUser, User, userTypes } from "./types";
import UserPreview from "./UserPreview";

const conditionsStruct: FormComponent<Condition> = {
  formComponent: "segment",
  entities: [
    {
      formComponent: "select-field",
      options: createOptionsForSelect({
        possibleValues: () => [["data", "info", "profit", "wfdProfit", "totalProfit"]],
        getOptionLabel: (x) => toNormalCase(x?.slice(-1)[0])
      }),
      name: "relation",
      label: "Relation",
      width: "1/3",
      path: ["field"],
      default: null
    },
    {
      formComponent: "select-field",
      options: createOptionsForSelect({
        possibleValues: () => ["<", ">", "="],
        getOptionLabel: (x: string | undefined) => {
          switch (x) {
            case "<":
              return "less than";
            case ">":
              return "more than";
            case "=":
              return "equals";
            default:
              return "";
          }
        }
      }),
      name: "type",
      label: "Type",
      width: "1/3",
      path: ["type"],
      default: null
    },
    {
      formComponent: "currency-field",
      name: "value",
      label: "Value",
      width: "1/3",
      path: ["value"],
      default: null
    }
  ]
};

const commissionsStruct: FormComponent<Commission> = {
  formComponent: "segment",
  width: "full",
  entities: [
    {
      formComponent: "segment",
      width: "1/2",
      entities: [
        {
          formComponent: "one-to-many-field",
          component: (stateAccess, _mainStateAccess, renderSet): JSX.Element => (
            <DealershipProgramPreview
              stateAccess={stateAccess}
              path={["dealershipProgram"]}
              renderSet={renderSet}
              name={"program"}
              label={"Dealership Program"}
              required={true}
            />
          ),
          name: "Dealership program preview",
          width: "1/2",
          path: ["dealershipProgram"]
        },

        {
          formComponent: "select-field",
          options: createOptionsForSelect({
            possibleValues: () => ["50% of gross profit", "WFD total profit"],
            getOptionLabel: (x) => x,
            getSelectedOption: (x, y) => x === y
          }),
          name: "commissionableAmountType",
          label: "Commissionable amount type",
          width: "1/2",
          path: ["commissionableAmountType"],
          default: null
        },
        {
          formComponent: "select-field",
          options: createOptionsForSelect({
            possibleValues: () => ["percent", "program"],
            getOptionLabel: (x) => x?.charAt(0).toUpperCase() + x?.slice(1),
            getSelectedOption: (x, y) => x === y
          }),
          name: "type",
          label: "Type",
          width: (stateAccess) => (stateAccess.get(["type"]) === "program" ? "1/2" : "1/3"),
          path: ["type"],
          default: null
        },
        {
          formComponent: "one-to-many-field",
          component: (stateAccess, _mainStateAccess, renderSet): JSX.Element => (
            <ManagerProgramPreview
              stateAccess={stateAccess}
              path={["managerProgram"]}
              renderSet={renderSet}
              name={"program"}
              label={"Manager Program"}
              required={true}
            />
          ),
          show: (stateAccess) => {
            return stateAccess.get(["type"]) === "program";
          },
          name: "Dealership program preview",
          width: "1/2",
          path: ["dealershipProgram"]
        },
        {
          formComponent: "number-field",
          name: "amount",
          label: "Amount",
          show: (stateAccess) => {
            const type = stateAccess.get(["type"]);
            return ["fixed", "percent"].includes(type);
          },
          width: "1/3",
          path: ["amount"],
          default: null
        },
        {
          formComponent: "number-field",
          name: "Chargeback %",
          show: (stateAccess) => {
            const type = stateAccess.get(["type"]);
            return ["fixed", "percent"].includes(type);
          },
          label: "Chargeback %",
          width: "1/3",
          path: ["chargebackPercent"],
          default: null
        }
      ]
    },
    {
      formComponent: "list-model",
      name: "Conditions",
      width: "1/2",
      entity: conditionsStruct,
      path: ["conditions"],
      required: false
    }
  ]
};
const readOnlyCommissionsStruct: FormComponent<Commission> = {
  formComponent: "segment",
  entities: [
    {
      formComponent: "segment",
      width: "1/2",
      entities: [
        {
          formComponent: "read-only-field",
          name: "type",
          label: "Type",
          width: "1/2",
          path: ["type"],
          default: null
        },
        {
          formComponent: "read-only-number-field",
          name: "amount",
          label: "Amount",
          width: "1/2",
          path: ["amount"],
          default: null
        }
      ]
    },
    {
      formComponent: "list-model",
      name: "Conditions",
      width: "1/2",
      entity: conditionsStruct,
      path: ["conditions"],
      required: false
    }
  ]
};
const mfaStruct: FormComponent<User> = {
  formComponent: "segment",
  width: "full",
  entities: [
    {
      formComponent: "segment",
      name: "Two factor authentication",
      width: "full",
      entities: [
        {
          formComponent: "one-to-many-field",
          component: (stateAccess: StateAccess) => <MFAButton stateAccess={stateAccess} />,
          width: "full",
          name: "Enable MFA",
          label: "Enable MFA",
          path: [],
          default: null
        }
      ]
    }
  ]
};

export const userPinStruct = (currentPin: string | undefined): FormComponent<User> => ({
  formComponent: "segment",
  entities: [
    {
      formComponent: "password-field",
      name: "Old PIN",
      label: "Old PIN",
      width: "full",
      path: ["oldPIN"],
      default: null,
      show: () => !!currentPin
    },
    {
      formComponent: "password-field",
      name: "New PIN",
      label: "New PIN",
      width: "full",
      path: ["newPIN"],
      default: null
    },
    {
      formComponent: "password-field",
      name: "Confirm PIN",
      label: "Confirm new PIN",
      width: "full",
      path: ["confirmPIN"],
      default: null
    }
  ]
});
export const userStruct: Model<User> = {
  formComponent: "model",
  schema: "new_wfd_user",
  name: "wfd_user",
  entities: [
    {
      formComponent: "segment",
      name: "User info",
      entities: [
        {
          formComponent: "name-field",
          name: "firstName",
          label: "First name",
          width: "1/2",
          path: ["data", "info", "firstName"],
          default: ""
        },
        {
          formComponent: "name-field",
          name: "lastName",
          label: "Last name",
          width: "1/2",
          path: ["data", "info", "lastName"],
          default: ""
        },
        {
          formComponent: "phone-field",
          name: "Phone number",
          label: "Phone number",
          width: "1/2",
          path: ["data", "info", "phoneNumber"],
          default: null
        },

        {
          formComponent: "virtual-field",
          name: "firstLastName",
          label: "First Last Name",
          description: "Displays first and last name",
          value: (stateAccess) => fullName(stateAccess),
          path: ["data", "info", "firstAndLastName"]
        },
        {
          formComponent: "virtual-field",
          name: "lastAndFirstNames",
          label: "Last, First names",
          description: "Displays last name + first name separated with comma",
          value: (stateAccess) =>
            `${stateAccess.get(["data", "info", "lastName"]) ?? ""}, ${
              stateAccess.get(["data", "info", "firstName"]) ?? ""
            }`,
          path: ["data", "info", "lastAndFirstNames"]
        },
        {
          formComponent: "email-field",
          name: "email",
          label: "Email",
          width: "1/2",
          required: true,
          path: ["data", "info", "email"],
          default: ""
        },
        {
          formComponent: "text-field",
          name: "netsuiteId",
          label: "Netsuite Id",
          width: "1/2",
          required: false,
          path: ["data", "netsuiteId"]
        },
        {
          formComponent: "text-field",
          name: "hubspotId",
          label: "Hubspot Id",
          width: "1/2",
          required: false,
          path: ["data", "hubspotId"]
        }
      ]
    },
    {
      formComponent: "segment",
      name: "User roles",
      entities: [
        {
          formComponent: "one-to-many-field",
          name: "roles",
          label: "roles",
          path: ["data", "roles"],
          component: (stateAccess: StateAccess, mainStateAccess, renderSet: RenderSet) => (
            <RolesPreview
              stateAccess={stateAccess}
              renderSet={renderSet}
              path={["data", "roles"]}
              name="roles"
              label="roles"
            />
          ),
          default: []
        },
        {
          formComponent: "one-to-many-field",
          component: (stateAccess: StateAccess) => <DisableUser user={stateAccess.get([])} />,
          width: "1/2",
          name: "DisableUser",
          label: "DisableUser",
          path: [],
          default: null
        }
      ]
    },
    {
      formComponent: "segment",
      name: "User type",
      entities: [
        {
          formComponent: "select-field",
          name: "userType",
          label: "User type",
          path: ["data", "info", "userType"],
          options: createOptionsForSelect({
            possibleValues: () => userTypes,
            getOptionLabel: (x) => x,
            getSelectedOption: (x, y) => x === y
          }),
          default: null
        },
        {
          formComponent: "one-to-many-field",
          component: (
            stateAccess: StateAccess,
            mainstateAccess: StateAccess,
            renderSet: RenderSet
          ) => (
            <DealershipPreview
              stateAccess={stateAccess}
              renderSet={renderSet}
              path={["data", "dealership"]}
              name="dealership"
              label="Dealership"
              required={true}
              requiredFunction={(stateAccess) =>
                stateAccess.get(["data", "info", "userType"]) === "dealership-admin"
              }
              type="wfd_user"
            />
          ),
          show: (stateAccess) =>
            ["dealership-admin", "dealership-member"].includes(
              stateAccess.get(["data", "info", "userType"])
            ),
          name: "dealership",
          label: "Dealership",
          default: null,
          path: ["data", "dealership"],
          width: "1/2"
        },
        {
          formComponent: "one-to-many-field",
          component: (
            stateAccess: StateAccess,
            mainstateAccess: StateAccess,
            renderSet: RenderSet
          ) => (
            <LenderPreview
              stateAccess={stateAccess}
              renderSet={renderSet}
              path={["data", "lender"]}
              lenderIdPath={["data", "lenderId"]}
              name="lender"
              label="Lender"
              required={(stateAccess) =>
                stateAccess.get(["data", "info", "userType"]) === "lender-admin"
              }
            />
          ),
          show: (stateAccess) =>
            ["lender-admin", "lender-user"].includes(stateAccess.get(["data", "info", "userType"])),
          name: "lender",
          label: "Lender",
          default: null,
          path: ["data", "lender"],
          width: "1/2"
        },
        {
          formComponent: "one-to-many-field",
          show: (stateAccess, formEditContext) => {
            const userRolesNames = (formEditContext?.auth?.databaseData?.data?.roles ?? [])?.map(
              (role) => role?.data?.info?.name
            );

            return userRolesNames.includes("Administrator");
          },
          component: (
            stateAccess: StateAccess,
            mainstateAccess: StateAccess,
            renderSet: RenderSet
          ) => <LoginAsDealer stateAccess={stateAccess} />,
          name: "Login as Dealer",
          label: "Login as user",
          width: "1/2",
          path: ["data", "loginAsUser"],
          default: null,
          valueToString: (el) => el.firstName
        },
        {
          formComponent: "one-to-many-field",
          show: (stateAccess) =>
            (stateAccess.get(["data", "rolesIds"]) ?? []).includes("5f17e21d46a2b90e45bb5a90"),
          component: (
            stateAccess: StateAccess,
            mainstateAccess: StateAccess,
            renderSet: RenderSet
          ) => (
            <UserPreview
              stateAccess={stateAccess}
              renderSet={renderSet}
              path={["data", "loginAsUser"]}
              name="loginAsUser"
              label="Login as user"
            />
          ),
          name: "loginAsUser",
          label: "Login as user",
          width: "1/2",
          path: ["data", "loginAsUser"],
          default: null,
          valueToString: (el) => el.firstName
        }
      ]
    },

    ////////////////////////////////////////////////
    {
      formComponent: "tab-list-model",
      name: `User commissions`,
      width: "full",
      getTabName: (entity) => {
        return `Period: From ${entity.from ? formatDate(entity.from, "short") : "Unlimited"}  To ${
          entity.to ? formatDate(entity.to, "short") : "Unlimited"
        }`;
      },
      show: (_, formEditContext) => {
        const userRolesNames = (formEditContext?.auth?.databaseData?.data?.roles ?? [])?.map(
          (role) => role?.data?.info?.name
        );

        return userRolesNames.includes("Administrator");
      },
      path: ["data", "info", "periodCommissions"],
      entity: {
        formComponent: "segment",
        width: "full",
        entities: [
          {
            formComponent: "segment",
            name: "Period",
            width: "1/2",
            entities: [
              {
                formComponent: "date-field",
                name: "From",
                label: "From",
                width: "1/2",
                path: ["from"],
                default: null
              },
              {
                formComponent: "date-field",
                name: "To",
                label: "To",
                width: "1/2",
                path: ["to"],
                default: null
              }
            ]
          },
          {
            formComponent: "list-model",
            name: "Commissions",
            width: "full",
            entity: commissionsStruct,
            path: ["commissions"]
          }
        ]
      }
    },
    ////////////////////////////////////////////

    {
      formComponent: "segment",
      name: "Dashboard",
      width: "full",
      entities: [
        {
          formComponent: "checkbox-field",
          name: "Accountant",
          label: "Accountant",
          width: "1/4",
          valueType: "boolean",
          path: ["data", "info", "dashboardTypes", "accountant"],
          isDisabled: (): boolean => false,
          isChecked: (stateAccess: StateAccess): boolean =>
            stateAccess.get<User>(["data", "info", "dashboardTypes", "accountant"]),
          toggle: (stateAccess: StateAccess): User =>
            stateAccess.set<User>(
              ["data", "info", "dashboardTypes", "accountant"],
              !stateAccess.get<User>(["data", "info", "dashboardTypes", "accountant"])
            ),
          required: true,
          default: true
        },
        {
          formComponent: "checkbox-field",
          name: "lender",
          label: "Lender",
          width: "1/4",
          valueType: "boolean",
          path: ["data", "info", "dashboardTypes", "lender"],
          isDisabled: (): boolean => false,
          isChecked: (stateAccess: StateAccess): boolean =>
            stateAccess.get<User>(["data", "info", "dashboardTypes", "lender"]),
          toggle: (stateAccess: StateAccess): User =>
            stateAccess.set<User>(
              ["data", "info", "dashboardTypes", "lender"],
              !stateAccess.get<User>(["data", "info", "dashboardTypes", "lender"])
            ),
          required: true,
          default: true
        },
        {
          formComponent: "checkbox-field",
          name: "lenderEstimation",
          label: "Lender Estimation",
          width: "1/4",
          valueType: "boolean",
          path: ["data", "info", "dashboardTypes", "lenderEstimation"],
          isDisabled: (): boolean => false,
          isChecked: (stateAccess: StateAccess): boolean =>
            stateAccess.get<User>(["data", "info", "dashboardTypes", "lenderEstimation"]),
          toggle: (stateAccess: StateAccess): User =>
            stateAccess.set<User>(
              ["data", "info", "dashboardTypes", "lenderEstimation"],
              !stateAccess.get<User>(["data", "info", "dashboardTypes", "lenderEstimation"])
            ),
          required: true,
          default: true
        },
        {
          formComponent: "checkbox-field",
          name: "allocation",
          label: "Lender Allocation",
          width: "1/4",
          valueType: "boolean",
          path: ["data", "info", "dashboardTypes", "allocation"],
          isDisabled: (): boolean => false,
          isChecked: (stateAccess: StateAccess): boolean =>
            stateAccess.get<User>(["data", "info", "dashboardTypes", "allocation"]),
          toggle: (stateAccess: StateAccess): User =>
            stateAccess.set<User>(
              ["data", "info", "dashboardTypes", "allocation"],
              !stateAccess.get<User>(["data", "info", "dashboardTypes", "allocation"])
            ),
          required: true,
          default: true
        },
        {
          formComponent: "checkbox-field",
          name: "F&I",
          label: "F&I",
          width: "1/4",
          valueType: "boolean",
          path: ["data", "info", "dashboardTypes", "f&i"],
          isDisabled: (): boolean => false,
          isChecked: (stateAccess: StateAccess): boolean =>
            stateAccess.get<User>(["data", "info", "dashboardTypes", "f&i"]),
          toggle: (stateAccess: StateAccess): User =>
            stateAccess.set<User>(
              ["data", "info", "dashboardTypes", "f&i"],
              !stateAccess.get<User>(["data", "info", "dashboardTypes", "f&i"])
            ),
          required: true,
          default: true
        },
        {
          formComponent: "checkbox-field",
          name: "titleIssues",
          label: "Title Information",
          width: "1/4",
          valueType: "boolean",
          path: ["data", "info", "dashboardTypes", "titleIssues"],
          isDisabled: (): boolean => false,
          isChecked: (stateAccess: StateAccess): boolean =>
            stateAccess.get<User>(["data", "info", "dashboardTypes", "titleIssues"]),
          toggle: (stateAccess: StateAccess): User =>
            stateAccess.set<User>(
              ["data", "info", "dashboardTypes", "titleIssues"],
              !stateAccess.get<User>(["data", "info", "dashboardTypes", "titleIssues"])
            ),
          required: true,
          default: true
        },
        {
          formComponent: "checkbox-field",
          name: "salesRep",
          label: "Sales Representative",
          width: "1/4",
          valueType: "boolean",
          path: ["data", "info", "dashboardTypes", "salesRep"],
          isDisabled: (): boolean => false,
          isChecked: (stateAccess: StateAccess): boolean =>
            stateAccess.get<User>(["data", "info", "dashboardTypes", "salesRep"]),
          toggle: (stateAccess: StateAccess): User =>
            stateAccess.set<User>(
              ["data", "info", "dashboardTypes", "salesRep"],
              !stateAccess.get<User>(["data", "info", "dashboardTypes", "salesRep"])
            ),
          required: true,
          default: true
        },
        {
          formComponent: "checkbox-field",
          name: "Rep",
          label: "Representative",
          width: "1/4",
          valueType: "boolean",
          path: ["data", "info", "dashboardTypes", "representative"],
          isDisabled: (): boolean => false,
          isChecked: (stateAccess: StateAccess): boolean =>
            stateAccess.get<User>(["data", "info", "dashboardTypes", "representative"]),
          toggle: (stateAccess: StateAccess): User =>
            stateAccess.set<User>(
              ["data", "info", "dashboardTypes", "representative"],
              !stateAccess.get<User>(["data", "info", "dashboardTypes", "representative"])
            ),
          required: true,
          default: true
        },
        {
          formComponent: "checkbox-field",
          name: "coverageStatistics",
          label: "Lender Coverage Statistics",
          width: "1/4",
          valueType: "boolean",
          path: ["data", "info", "dashboardTypes", "coverageStatistics"],
          isDisabled: (): boolean => false,
          isChecked: (stateAccess: StateAccess): boolean =>
            stateAccess.get<User>(["data", "info", "dashboardTypes", "coverageStatistics"]),
          toggle: (stateAccess: StateAccess): User =>
            stateAccess.set<User>(
              ["data", "info", "dashboardTypes", "coverageStatistics"],
              !stateAccess.get<User>(["data", "info", "dashboardTypes", "coverageStatistics"])
            ),
          required: true,
          default: true
        },
        {
          formComponent: "checkbox-field",
          name: "coverageMap",
          label: "Lender Coverage Map",
          width: "1/4",
          valueType: "boolean",
          path: ["data", "info", "dashboardTypes", "coverageMap"],
          isDisabled: (): boolean => false,
          isChecked: (stateAccess: StateAccess): boolean =>
            stateAccess.get<User>(["data", "info", "dashboardTypes", "coverageMap"]),
          toggle: (stateAccess: StateAccess): User =>
            stateAccess.set<User>(
              ["data", "info", "dashboardTypes", "coverageMap"],
              !stateAccess.get<User>(["data", "info", "dashboardTypes", "coverageMap"])
            ),
          required: true,
          default: true
        },
        {
          formComponent: "checkbox-field",
          name: "dealsMap",
          label: "Deals Map",
          width: "1/4",
          valueType: "boolean",
          path: ["data", "info", "dashboardTypes", "dealsMap"],
          isDisabled: (): boolean => false,
          isChecked: (stateAccess: StateAccess): boolean =>
            stateAccess.get<User>(["data", "info", "dashboardTypes", "dealsMap"]),
          toggle: (stateAccess: StateAccess): User =>
            stateAccess.set<User>(
              ["data", "info", "dashboardTypes", "dealsMap"],
              !stateAccess.get<User>(["data", "info", "dashboardTypes", "dealsMap"])
            ),
          required: true,
          default: true
        }
      ]
    },

    // {
    //   formComponent: "segment",

    //   name: "Vehicle Insurance Company",
    //   width: "full",
    //   entities: [
    //     {
    //       formComponent: "one-to-many-field",
    //       component: (stateAccess: StateAccess, mainStateAccess, renderSet: RenderSet) => (
    //         <VehicleInsuranceCompanyPreview
    //           stateAccess={stateAccess}
    //           renderSet={renderSet}
    //           path={["data", "vehicleInsuranceCompany"]}
    //           name="vehicleInsuranceCompany"
    //           label="Vehicle Insurance Company"
    //         />
    //       ),
    //       name: "vehicleInsuranceCompany",
    //       label: "Vehicle Insurance Company",
    //       width: "1/2",
    //       path: ["data", "vehicleInsuranceCompany"],
    //       default: null
    //     }
    //   ]
    // },

    {
      formComponent: "segment",
      name: "Notifications",
      width: "full",
      entities: [
        {
          formComponent: "checkbox-field",
          name: "onlineCreditApplicationNotification",
          label: "Online Credit Application Notification",
          width: "1/4",
          toggle: (stateAccess: StateAccess) =>
            stateAccess.set<User>(
              ["data", "info", "onlineCreditApplicationNotification"],
              !stateAccess.get<User>(["data", "info", "onlineCreditApplicationNotification"])
            ),
          isDisabled: () => false,
          isChecked: (stateAccess: StateAccess) =>
            stateAccess.get<User>(["data", "info", "onlineCreditApplicationNotification"]),
          path: ["data", "info", "onlineCreditApplicationNotification"],
          default: false
        },
        {
          formComponent: "checkbox-field",
          name: "contractNotification",
          label: "Contract Notification",
          width: "1/4",
          toggle: (stateAccess: StateAccess) =>
            stateAccess.set<User>(
              ["data", "info", "contractNotification"],
              !stateAccess.get<User>(["data", "info", "contractNotification"])
            ),
          isDisabled: () => false,
          isChecked: (stateAccess: StateAccess) =>
            stateAccess.get<User>(["data", "info", "contractNotification"]),
          path: ["data", "info", "contractNotification"],
          default: false
        },
        {
          formComponent: "checkbox-field",
          name: "lenderAllocationNotification",
          label: "Lender Allocation Notification",
          width: "1/4",
          toggle: (stateAccess: StateAccess) =>
            stateAccess.set<User>(
              ["data", "info", "lenderAllocationNotification"],
              !stateAccess.get<User>(["data", "info", "lenderAllocationNotification"])
            ),
          isDisabled: () => false,
          isChecked: (stateAccess: StateAccess) =>
            stateAccess.get<User>(["data", "info", "lenderAllocationNotification"]),
          path: ["data", "info", "lenderAllocationNotification"],
          default: false
        }
      ]
    },
    {
      formComponent: "segment",
      name: "Support",
      width: "full",
      entities: [
        {
          formComponent: "checkbox-field",
          name: "lenderSupport",
          label: "Lender Support",
          width: "1/4",
          toggle: (stateAccess: StateAccess) =>
            stateAccess.set<User>(
              ["data", "info", "lenderSupport"],
              !stateAccess.get<User>(["data", "info", "lenderSupport"])
            ),
          isDisabled: () => false,
          isChecked: (stateAccess: StateAccess) =>
            stateAccess.get<User>(["data", "info", "lenderSupport"]),
          path: ["data", "info", "lenderSupport"],
          default: false
        }
      ]
    },
    {
      formComponent: "segment",
      width: "full",
      entities: [
        {
          formComponent: "one-to-many-field",
          width: "full",
          name: "allocation",
          label: "allocation",
          path: [],
          component: (stateAccess: StateAccess, mainStateAccess, renderSet: RenderSet) => (
            <AllocationPeriods stateAccess={stateAccess} renderSet={renderSet} />
          ),
          default: []
        }
      ]
    }
  ]
};
export const addUserStruct: Model<User> = {
  formComponent: "model",
  schema: "new_wfd_user",
  name: "wfd_user",
  entities: [
    {
      formComponent: "segment",
      name: "User info",
      entities: [
        {
          formComponent: "name-field",
          name: "firstName",
          label: "First name",
          width: "1/2",
          path: ["data", "info", "firstName"],
          default: ""
        },
        {
          formComponent: "name-field",
          name: "lastName",
          label: "Last name",
          width: "1/2",
          path: ["data", "info", "lastName"],
          default: ""
        },
        {
          formComponent: "phone-field",
          name: "Phone number",
          label: "Phone number",
          width: "1/2",
          path: ["data", "info", "phoneNumber"],
          default: null
        },

        {
          formComponent: "virtual-field",
          name: "firstLastName",
          label: "First Last Name",
          description: "Displays first and last name",
          value: (stateAccess) =>
            `
             ${stateAccess.get(["data", "info", "firstName"]) ?? ""}  ${
              stateAccess.get(["data", "info", "lastName"]) ?? ""
            } `,
          path: ["data", "info", "firstAndLastName"]
        },
        {
          formComponent: "virtual-field",
          name: "lastAndFirstNames",
          label: "Last, First names",
          description: "Displays last name + first name separated with comma",
          value: (stateAccess) =>
            `${stateAccess.get(["data", "info", "lastName"]) ?? ""}, ${
              stateAccess.get(["data", "info", "firstName"]) ?? ""
            }`,
          path: ["data", "info", "lastAndFirstNames"]
        },
        {
          formComponent: "email-field",
          name: "email",
          label: "Email",
          width: "1/2",
          required: true,
          path: ["data", "info", "email"],
          default: ""
        },
        {
          formComponent: "text-field",
          name: "netsuiteId",
          label: "Netsuite Id",
          width: "1/2",
          required: false,
          path: ["data", "netsuiteId"]
        },
        {
          formComponent: "text-field",
          name: "hubspotId",
          label: "Hubspot Id",
          width: "1/2",
          required: false,
          path: ["data", "hubspotId"]
        }
      ]
    },
    {
      formComponent: "segment",
      name: "User roles",
      entities: [
        {
          formComponent: "one-to-many-field",
          name: "roles",
          label: "roles",
          path: ["data", "roles"],
          component: (stateAccess: StateAccess, mainStateAccess, renderSet: RenderSet) => (
            <RolesPreview
              stateAccess={stateAccess}
              renderSet={renderSet}
              path={["data", "roles"]}
              name="roles"
              label="roles"
            />
          ),
          default: []
        }
      ]
    },
    {
      formComponent: "segment",
      name: "User type",
      entities: [
        {
          formComponent: "select-field",
          name: "userType",
          label: "User type",
          path: ["data", "info", "userType"],
          options: createOptionsForSelect({
            possibleValues: () => userTypes,
            getOptionLabel: (x) => x,
            getSelectedOption: (x, y) => x === y
          }),
          default: null
        },
        {
          formComponent: "one-to-many-field",
          component: (
            stateAccess: StateAccess,
            mainstateAccess: StateAccess,
            renderSet: RenderSet
          ) => (
            <DealershipPreview
              stateAccess={stateAccess}
              renderSet={renderSet}
              path={["data", "dealership"]}
              name="dealership"
              label="Dealership"
              required={true}
              requiredFunction={(stateAccess) =>
                stateAccess.get(["data", "info", "userType"]) === "dealership-admin"
              }
              type="wfd_user"
            />
          ),
          show: (stateAccess) =>
            ["dealership-admin", "dealership-member"].includes(
              stateAccess.get(["data", "info", "userType"])
            ),
          name: "dealership",
          label: "Dealership",
          default: null,
          path: ["data", "dealership"],
          width: "1/2"
        },
        {
          formComponent: "one-to-many-field",
          component: (
            stateAccess: StateAccess,
            mainstateAccess: StateAccess,
            renderSet: RenderSet
          ) => (
            <LenderPreview
              stateAccess={stateAccess}
              renderSet={renderSet}
              path={["data", "lender"]}
              lenderIdPath={["data", "lenderId"]}
              name="lender"
              label="Lender"
              required={(stateAccess) =>
                stateAccess.get(["data", "info", "userType"]) === "lender-admin"
              }
            />
          ),
          show: (stateAccess) =>
            ["lender-admin", "lender-user"].includes(stateAccess.get(["data", "info", "userType"])),
          name: "lender",
          label: "Lender",
          default: null,
          path: ["data", "lender"],
          width: "1/2"
        },
        {
          formComponent: "one-to-many-field",
          show: (stateAccess) =>
            (stateAccess.get(["data", "rolesIds"]) ?? []).includes("5f17e21d46a2b90e45bb5a90"),
          component: (
            stateAccess: StateAccess,
            mainstateAccess: StateAccess,
            renderSet: RenderSet
          ) => (
            <UserPreview
              stateAccess={stateAccess}
              renderSet={renderSet}
              path={["data", "loginAsUser"]}
              name="loginAsUser"
              label="Login as user"
            />
          ),
          name: "loginAsUser",
          label: "Login as user",
          width: "1/2",
          path: ["data", "loginAsUser"],
          default: null,
          valueToString: (el) => el.firstName
        }
      ]
    },
    {
      formComponent: "list-model",
      name: "User commissions",
      width: "full",
      entity: commissionsStruct,
      path: ["data", "info", "commissions"],
      required: false
    },
    // {
    //   formComponent: "segment",

    //   name: "Vehicle Insurance Company",
    //   width: "full",
    //   entities: [
    //     {
    //       formComponent: "one-to-many-field",
    //       component: (stateAccess: StateAccess, mainStateAccess, renderSet: RenderSet) => (
    //         <VehicleInsuranceCompanyPreview
    //           stateAccess={stateAccess}
    //           renderSet={renderSet}
    //           path={["data", "vehicleInsuranceCompany"]}
    //           name="vehicleInsuranceCompany"
    //           label="Vehicle Insurance Company"
    //         />
    //       ),
    //       name: "vehicleInsuranceCompany",
    //       label: "Vehicle Insurance Company",
    //       width: "1/2",
    //       path: ["data", "vehicleInsuranceCompany"],
    //       default: null
    //     }
    //   ]
    // },

    {
      formComponent: "checkbox-field",
      name: "onlineCreditApplicationNotification",
      label: "Online Credit Application Notification",
      width: "1/2",
      toggle: (stateAccess: StateAccess) =>
        stateAccess.set<User>(
          ["data", "info", "onlineCreditApplicationNotification"],
          !stateAccess.get<User>(["data", "info", "onlineCreditApplicationNotification"])
        ),
      isDisabled: () => false,
      isChecked: (stateAccess: StateAccess) =>
        stateAccess.get<User>(["data", "info", "onlineCreditApplicationNotification"]),
      path: ["data", "info", "onlineCreditApplicationNotification"],
      default: false
    },
    {
      formComponent: "checkbox-field",
      name: "contractNotification",
      label: "Contract Notification",
      width: "1/2",
      toggle: (stateAccess: StateAccess) =>
        stateAccess.set<User>(
          ["data", "info", "contractNotification"],
          !stateAccess.get<User>(["data", "info", "contractNotification"])
        ),
      isDisabled: () => false,
      isChecked: (stateAccess: StateAccess) =>
        stateAccess.get<User>(["data", "info", "contractNotification"]),
      path: ["data", "info", "contractNotification"],
      default: false
    }
  ]
};
export const userStructProfile: Model<User> = {
  formComponent: "model",
  schema: "new_wfd_user",
  name: "wfd_user",
  entities: [
    {
      formComponent: "segment",
      name: "User info",
      entities: [
        {
          formComponent: "name-field",
          name: "firstName",
          label: "First name",
          width: "1/2",
          path: ["data", "info", "firstName"],
          default: ""
        },
        {
          formComponent: "name-field",
          name: "lastName",
          label: "Last name",
          width: "1/2",
          path: ["data", "info", "lastName"],
          default: ""
        },
        {
          formComponent: "phone-field",
          name: "Phone number",
          label: "Phone number",
          width: "1/2",
          path: ["data", "info", "phoneNumber"],
          default: null
        }
      ]
    }
  ]
};
export const readOnlyUserStruct: Model<User> = {
  formComponent: "model",
  schema: "new_wfd_user",
  name: "wfd_user",
  entities: [
    {
      formComponent: "segment",
      entities: [
        {
          formComponent: "email-field",
          name: "email",
          label: "Email",
          width: "1/2",
          required: true,
          path: ["data", "info", "email"],
          default: ""
        },

        {
          formComponent: "one-to-many-field",
          component: (stateAccess: StateAccess) => (
            <ResetPassButton email={stateAccess.get(["data", "info", "email"])} />
          ),
          width: "1/2",
          name: "Reset password",
          label: "Reset password",
          path: [],
          default: null
        },
        {
          formComponent: "text-field",
          name: "netsuiteId",
          label: "Netsuite Id",
          width: "1/2",
          required: false,
          path: ["data", "netsuiteId"]
        }
      ]
    },
    {
      formComponent: "segment",
      name: "User roles",
      entities: [
        {
          formComponent: "one-to-many-field",
          name: "roles",
          label: "roles",
          path: ["data", "roles"],
          component: (stateAccess: StateAccess, mainStateAccess, renderSet: RenderSet) => (
            <RolesPreview
              stateAccess={stateAccess}
              renderSet={renderSet}
              path={["data", "roles"]}
              name="roles"
              label="roles"
            />
          ),
          default: []
        }
      ]
    },
    {
      formComponent: "segment",
      name: "User type",
      entities: [
        {
          formComponent: "select-field",
          name: "userType",
          label: "User type",
          path: ["data", "info", "userType"],
          options: createOptionsForSelect({
            possibleValues: () => userTypes,
            getOptionLabel: (x) => x,
            getSelectedOption: (x, y) => x === y
          }),
          default: null
        },
        {
          formComponent: "one-to-many-field",
          component: (
            stateAccess: StateAccess,
            mainstateAccess: StateAccess,
            renderSet: RenderSet
          ) => (
            <DealershipPreview
              stateAccess={stateAccess}
              renderSet={renderSet}
              path={["data", "dealership"]}
              name="dealership"
              label="Dealership"
              required={true}
              requiredFunction={(stateAccess) =>
                stateAccess.get(["data", "info", "userType"]) === "dealership-admin"
              }
              type="wfd_user"
            />
          ),
          show: (stateAccess) =>
            ["dealership-admin", "dealership-member"].includes(
              stateAccess.get(["data", "info", "userType"])
            ),

          name: "dealership",
          label: "Dealership",
          default: null,
          path: ["data", "dealership"],
          width: "1/2"
        },
        {
          formComponent: "one-to-many-field",
          component: (
            stateAccess: StateAccess,
            mainstateAccess: StateAccess,
            renderSet: RenderSet
          ) => (
            <LenderPreview
              stateAccess={stateAccess}
              lenderIdPath={["data", "lenderId"]}
              renderSet={renderSet}
              path={["data", "lender"]}
              name="lender"
              label="Lender"
              required={(stateAccess) =>
                stateAccess.get(["data", "info", "userType"]) === "lender-admin"
              }
            />
          ),
          show: (stateAccess) =>
            ["lender-admin", "lender-user"].includes(stateAccess.get(["data", "info", "userType"])),

          name: "lender",
          label: "Lender",
          default: null,
          path: ["data", "lender"],
          width: "1/2"
        }
      ]
    },
    {
      formComponent: "list-model",
      name: "User commissions",
      width: "full",
      entity: commissionsStruct,
      path: ["data", "info", "commissions"],
      required: false
    },
    {
      formComponent: "radio-field",
      name: "dashboardType",
      label: "Dashboard Type",
      width: "full",
      path: ["data", "info", "dashboardType"],
      possibleValues: [
        { label: "F&I manager dashboard", value: "f&i" },
        { label: "Accountant dashboard", value: "accountant" }
      ],
      default: "f&i"
    },
    {
      formComponent: "checkbox-field",
      name: "onlineCreditApplicationNotification",
      label: "Online Credit Application Notification",
      width: "1/2",
      toggle: () => {},
      isDisabled: () => true,
      isChecked: (stateAccess: StateAccess) =>
        stateAccess.get<User>(["data", "info", "onlineCreditApplicationNotification"]),
      path: ["data", "info", "onlineCreditApplicationNotification"],
      default: false
    },
    {
      formComponent: "checkbox-field",
      name: "contractNotification",
      label: "Contract Notification",
      width: "1/2",
      toggle: () => {},
      isDisabled: () => true,
      isChecked: (stateAccess: StateAccess) =>
        stateAccess.get<User>(["data", "info", "contractNotification"]),
      path: ["data", "info", "contractNotification"],
      default: false
    },
    mfaStruct
  ]
};

export const unapproveUserStruct: Model<UnapprovedUser> = {
  formComponent: "model",
  schema: "new_wfd_user",
  name: "wfd_user",
  entities: [
    {
      formComponent: "segment",
      name: "User info",
      entities: [
        {
          formComponent: "email-field",
          name: "email",
          label: "Email",
          width: "1/2",
          path: ["data", "info", "email"],
          default: ""
        }
      ]
    }
  ]
};
const fullName = (stateAccess: StateAccess) =>
  [
    stateAccess.get<User>(["data", "info", "firstName"]),
    ...(stateAccess.get<User>(["data", "info", "lastName"])
      ? [stateAccess.get<User>(["data", "info", "lastName"])]
      : [])
  ].join(" ");
