import { RootState } from "app/rootReducer";
import TextFilter from "components/Filters/TextFilter";
import React from "react";
import { useSelector } from "react-redux";
import AccessControl from "../Access/AccessControl";
import Table, { CellValue, Column } from "../Table";
import { deleteVehicleInsuranceCompany } from "./deleteVehicleInsuranceCompanySlice";
import { getVehicleInsuranceCompanyList } from "./listVehicleInsuranceCompanySlice";
import { recoverVehicleInsuranceCompany } from "./recoverVehicleInsuranceCompanySlice";
import { FormState } from "./types";
export const projections = {
  "data.info.name": 1,
  _id: 1,
  createdAt: 1,
  updatedAt: 1,
  creatorId: 1,
  deleted: 1
};
export default function InsuranceCompanies(): JSX.Element {
  const vehicleInsuranceCompanyList = useSelector(
    (state: RootState) => state.listVehicleInsuranceCompanySlice["table"]
  );

  const columns: Column<FormState>[] = [
    {
      getData: (entry): CellValue => entry.data.info.name,
      label: "Insurance Company",
      name: "name",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.name?.show;
      },
      filters: [
        {
          path: ["data", "info", "name"],
          preview: TextFilter,
          name: "name",
          label: "Name",
          caseInsensitive: true,
          partialSearch: true
        }
      ]
    },
    {
      getData: (entry): CellValue => entry._id,
      label: "Actions",
      name: "actions",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.actions?.show;
      }
    }
  ];

  return (
    <div style={{ position: "relative" }}>
      <AccessControl requiredPermissions={{ entity: "role", action: "read" }}>
        <Table
          slice="table"
          tableName="vehicle_insurance_companies"
          entityName="vehicle_insurance_company"
          listFunction={getVehicleInsuranceCompanyList}
          listEntity={vehicleInsuranceCompanyList}
          deleteEntityFunction={deleteVehicleInsuranceCompany}
          recoverEntityFunction={recoverVehicleInsuranceCompany}
          title={"Vehicle Insurance Companies"}
          columns={columns}
          projection={projections}
        />
      </AccessControl>
    </div>
  );
}
