import { AddRequest, CrudEntity } from "utils/types";

export type Stipulation = CrudEntity & {
  data: FormState;
};
export enum StipulationStatus {
  PENDING = "pending",
  APPROVED = "approved",
  AWAITING_UPLOAD = "awaiting upload",
  REJECTED = "rejected"
}
export type FormState = {
  dealId: string;
  defaultStipulationId?: string;
  info: Info;
};
export type Info = {
  id: string;
  description: string;
  url?: string | null;
  rejectedUrls?: {
    url: string;
    uploadedAt?: string;
    uploadedBy?: "applicant" | "coApplicant" | "dealer" | "manager";
  }[];
  status: StipulationStatus;
  createdBy: "dealer" | "manager" | "system";
  notificationSendDate: string;
  viewed: string;
  notifiedEmails: {
    emails: string[];
    type: "applicant" | "coApplicant" | "dealer";
  }[];
  uploadedBy?: "applicant" | "coApplicant" | "dealer" | "manager";
  uploadedAt?: string;
  canBeUploadedBy: ("applicant" | "coApplicant" | "dealer")[];
  owner: "applicant" | "coApplicant";
  user: {
    firstName: string | null;
    lastName: string | null;
    email: string | null;
  };
};

export type AddStipulationRequest = AddRequest<Stipulation>;
