import { Grid, Paper } from "@material-ui/core";
import { formatPhoneNumber } from "components/Filters/PhoneFilter";
import React from "react";

import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import { Dealership } from "../types";
import styles from "./DealershipShowPage.module.css";

export default ({ dealership }: { dealership: Dealership }) => {
  return (
    <Paper elevation={2} style={{ padding: "10px", backgroundColor: "#f5f5f5", fontSize: "18px" }}>
      <Grid container xs={12}>
        <Grid md={6} sm={12} container>
          <Grid item xs={12}>
            <div className={styles.mainTitle}>Dealership: {dealership.data.info.name}</div>
          </Grid>
          <Grid item md={3} xs={6}>
            <div className={styles.title}>Address</div>
            <div>
              {dealership.data.info.address}, {dealership.data.info.county},{" "}
              {dealership.data.info.city}, {dealership.data.info.state}{" "}
            </div>
          </Grid>
          <Grid item md={3} xs={6}>
            <div className={styles.title}>Phone</div>
            <div>{formatPhoneNumber(dealership.data.info.phone)}</div>
          </Grid>
          <Grid item md={2} xs={6}>
            <div className={styles.title}>Status</div>
            <div>{dealership.approved ? "approved" : "not approved"}</div>
          </Grid>
          <Grid item md={2} xs={6}>
            <div className={styles.title}>Esign</div>
            <div>{dealership.data.info.eSign ? "enabled" : "disabled"}</div>
          </Grid>
          <Grid item md={2} xs={6}>
            <div className={styles.title}>Platform</div>
            <div>{dealership.data.info.adminEmail ? "Provided" : "Not provided"}</div>
          </Grid>
        </Grid>
        <Grid md={6} sm={12} container>
          <Grid item xs={12}>
            <div className={styles.title} style={{ fontSize: "20px" }}>
              Contacts
            </div>
          </Grid>
          <Grid item xs={12}>
            <Paper elevation={0}>
              <Table size="small" style={{ backgroundColor: "#f5f5f5", fontSize: "18px" }}>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ fontSize: "18px" }}>Name</TableCell>
                    <TableCell style={{ fontSize: "18px" }}>Title</TableCell>
                    <TableCell style={{ fontSize: "18px" }}>Phone</TableCell>
                    <TableCell style={{ fontSize: "18px" }}>email</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dealership.data.contacts.map((contact, index) => (
                    <TableRow key={index}>
                      <TableCell style={{ fontSize: "18px" }}>
                        {contact.firstName} {contact.lastName}
                      </TableCell>
                      <TableCell style={{ fontSize: "18px" }}>{contact.title}</TableCell>
                      <TableCell style={{ fontSize: "18px" }}>
                        {formatPhoneNumber(contact.phone ?? "")}
                      </TableCell>
                      <TableCell style={{ fontSize: "18px" }}>{contact.email}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};
