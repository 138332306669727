import { Button, Typography } from "@material-ui/core";
import LinkIcon from "@material-ui/icons/Link";
import { RootState } from "app/rootReducer";
import formEditContext from "components/Content/FormEditContext";
import { useSnackbar } from "notistack";
import React, { useContext, useEffect, useState } from "react";
import { PiSealWarningDuotone } from "react-icons/pi";
import { useDispatch, useSelector } from "react-redux";
import { StateAccess } from "utils/models/formGenerator";
import { v4 as uuidv4 } from "uuid";
import { GenerateDealershipLink } from "./generateLinkSlice";
import { Dealership } from "./types";

const GenerateLink = ({ stateAccess }: { stateAccess: StateAccess }) => {
  const dispatch = useDispatch();
  const dealership: Dealership = stateAccess.get<Dealership>([]);
  const [requestId] = useState(uuidv4());
  const { enqueueSnackbar } = useSnackbar();
  const { enabled: editMode, edited } = useContext(formEditContext);
  const handleGenerateLink = () => {
    if (
      !dealership?.data?.info?.adminEmail ||
      !dealership?.data?.info?.adminFirstName ||
      !dealership?.data?.info?.adminLastName
    ) {
      return alert(
        "Please fill all the information in dealership platform before generating a link"
      );
    }
    if (edited) {
      return alert("Please save the dealership first.");
    }

    dispatch(GenerateDealershipLink({ requestId, _id: dealership._id }));
  };

  const generateDealershipLinkSlice = useSelector(
    (state: RootState) => state.generateLinkSlice[requestId]
  );
  const [link, setLink] = useState("");
  useEffect(() => {
    if (generateDealershipLinkSlice?.status === "success") {
      enqueueSnackbar("Invitation was send to the dealership via email!", { variant: "success" });
      setLink(generateDealershipLinkSlice.data.message);
    }
  }, [generateDealershipLinkSlice]);

  return editMode ? (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: "5px",
        border: "1px dashed black",
        borderRadius: "3px",
        padding: "10px"
      }}
    >
      <div style={{ flex: 1 }}>
        <Button
          onClick={handleGenerateLink}
          // style={{ width: "100%" }}
          variant="contained"
          component="label"
          color="primary"
          startIcon={<LinkIcon />}
        >
          Generate and send link for admin registration
        </Button>
      </div>
      <div>
        {link && (
          <a
            target="_blank"
            rel="noreferrer"
            style={{ margin: "10px 0px", display: "block" }}
            href={link}
          >
            Open Link
          </a>
        )}
      </div>
      <div style={{ flex: 1 }}>
        <Typography style={{ fontWeight: 500 }} variant="subtitle1">
          Generates a link for registration for the dealership platform using the information from
          the fields above. After generating the link, it automatically sends an email, containing
          the link for registration, to the address specified in the{" "}
          <strong>&#39;Admin email&#39;</strong> field above.
        </Typography>
      </div>
      <div>
        <PiSealWarningDuotone size={50} color="#dec83c" />
      </div>
    </div>
  ) : (
    <></>
  );
};

export default GenerateLink;
