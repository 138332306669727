import { RootState } from "app/rootReducer";
import DateFilter from "components/Filters/DateFilter";
import React from "react";
import { useSelector } from "react-redux";

import { capitalize, Grid, Paper, Typography } from "@material-ui/core";
import AccessControl from "components/Access/AccessControl";
import MultiSelectFilter from "components/Filters/MultiSelectFilter";
import PriceFilter from "components/Filters/PriceFilter";
import TextFilter from "components/Filters/TextFilter";
import Table, { CellValue, Column } from "components/Table";
import { DmvStatus, FormState, TitleIssueStatus } from "components/TitleIssues/types";
import States from "us-states";

import filtersContext from "components/Content/FiltersContext";
import { collateralTypes } from "components/Deals/types";
import ApplicantFilter from "components/Filters/ApplicantFilter";
import DealershipFilter from "components/Filters/DealershipFilter";
import LenderFilter from "components/Filters/LenderFilter";
import { deleteTitleIssue } from "components/TitleIssues/deleteTitleIssueSlice";
import { dmvStatusToChip, titleStatusToChip } from "components/TitleIssues/DocumentsSection";
import { getTitleIssueList } from "components/TitleIssues/listTitleIssueSlice";
import { getDaysLeft, getNextCheckDate } from "components/TitleIssues/Overview";
import { recoverTitleIssue } from "components/TitleIssues/recoverTitleIssueSlice";

export const projections = {
  "data.deal.data.applicant.data.info.currentState": 1,
  "data.deal.data.applicant.data.info.firstName": 1,
  "data.deal.data.applicant.data.info.lastName": 1,
  "data.deal.data.coApplicant.data.info.firstName": 1,
  "data.deal.data.coApplicant.data.info.lastName": 1,
  "data.deal.data.lender.data.info.name": 1,
  "data.deal.data.dealership.data.info.displayName": 1,
  "data.deal.data.lender._id": 1,
  "data.info.titleRegistrationInfo.status": 1,
  "data.info.oldTitleRegistrationInfo.status": 1,
  "data.info.receiptInfo.status": 1,
  "data.deal.data.dealership._id": 1,
  "data.deal.data.info.refNumber": 1,
  "data.deal.data.info.type": 1,
  "data.deal.data.info.vehicle.VIN": 1,
  "data.deal.data.info.dealDates.contractDate": 1,
  "data.deal.data.info.dealDates.submittedForFunding": 1,
  "data.info.dmvInfo.status": 1,
  "data.info.lastCheckedDate": 1,
  "data.info.dmvCheckedCount": 1,
  _id: 1,
  createdAt: 1,
  creatorId: 1,
  deleted: 1
};

type Props = {
  overallQuery: Record<string, any>;
  setResetStickyCallback: React.Dispatch<React.SetStateAction<(() => void) | undefined>>;
};

export default ({ overallQuery, setResetStickyCallback }: Props): JSX.Element => {
  const sliceId = "latest-title-issues";
  const titleIssuesList = useSelector((state: RootState) => state.listTitleIssueSlice[sliceId]);
  const [resetStickyTableCallback, setResetStickyTableCallback] = React.useState<() => void>();
  const { filters } = React.useContext(filtersContext);
  const columns: Column<FormState>[] = [
    {
      getData: (entry): CellValue => getDaysLeft(entry),
      options: {
        customBodyRender: (x: CellValue): JSX.Element | string => {
          return (
            <Typography style={x === "Overdue!" ? { color: "red", fontWeight: "bold" } : {}}>
              {x}
            </Typography>
          );
        },
        sort: true,
        path: ["data", "deal", "data", "info", "dealDates", "submittedForFunding"]
      },
      label: "Days left",
      name: "daysLeft",
      show: (_userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.daysLeft?.show;
      }
    },
    {
      getData: (entry): CellValue => getNextCheckDate(entry),
      label: "Next check date",
      name: "nextCheckDate",
      show: (_userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.nextCheckDate?.show;
      }
    },
    {
      getData: (entry): CellValue => entry.data?.info?.dmvInfo?.status,
      label: "DMV Status",
      options: {
        customBodyRender: (x: CellValue, entry): JSX.Element | string => {
          return dmvStatusToChip(x as DmvStatus);
        },
        sort: true,
        path: ["data", "info", "dmvInfo", "status"]
      },
      name: "dmvStatus",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.dmvStatus?.show;
      },
      filters: [
        {
          path: ["data", "info", "dmvInfo", "status"],
          preview: MultiSelectFilter,
          valuesForSelect: [
            "titled",
            "untitled",
            "error",
            "old_title",
            "not_checked",
            "not_supported"
          ],
          optionLabelForSelect: (x) => {
            switch (x as DmvStatus) {
              case "untitled":
                return "Untitled";
              case "old_title":
                return "Old title";
              case "error":
                return "Error";
              case "not_checked":
                return "Not checked";
              case "not_supported":
                return "Not supported";
              case "titled":
                return "Titled";
            }
          },
          label: "Dmv info status",
          name: "dmvInfoStatus"
        }
      ]
    },
    {
      getData: (entry): CellValue => entry?.data?.info?.dmvCheckedCount,
      label: "DMV Checked count",
      name: "dmvCheckedCount",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.dmvCheckedCount?.show;
      },
      filters: [
        {
          path: ["data", "info", "dmvCheckedCount"],
          preview: PriceFilter
        }
      ]
    },
    {
      getData: (entry): CellValue => entry.data?.info?.titleRegistrationInfo?.status,
      label: "Copy of Completed Title Application",
      options: {
        customBodyRender: (x: CellValue): JSX.Element | string => {
          return titleStatusToChip(x as TitleIssueStatus);
        },
        sort: true,
        path: ["data", "info", "titleRegistrationInfo", "status"]
      },
      name: "titleRegistrationInfoStatus",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.titleRegistrationInfoStatus?.show;
      },
      filters: [
        {
          path: ["data", "info", "titleRegistrationInfo", "status"],
          preview: MultiSelectFilter,
          valuesForSelect: ["awaiting", "pending", "resolved", "rejected"],
          optionLabelForSelect: (x) => capitalize(x),
          label: "Title registration info status",
          name: "titleRegistrationInfoStatus"
        }
      ]
    },
    {
      getData: (entry): CellValue => entry.data?.info?.oldTitleRegistrationInfo?.status,
      label: "Copy of Original Title",
      options: {
        customBodyRender: (x: CellValue): JSX.Element | string => {
          return titleStatusToChip(x as TitleIssueStatus);
        },
        sort: true,
        path: ["data", "info", "oldTitleRegistrationInfo", "status"]
      },
      name: "oldTitleRegistrationInfoStatus",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.oldTitleRegistrationInfoStatus?.show;
      },
      filters: [
        {
          path: ["data", "info", "oldTitleRegistrationInfo", "status"],
          preview: MultiSelectFilter,
          valuesForSelect: ["awaiting", "pending", "resolved", "rejected"],
          optionLabelForSelect: (x) => capitalize(x),
          label: "Original title registration info status",
          name: "oldTitleRegistrationInfoStatus"
        }
      ]
    },
    {
      getData: (entry): CellValue => entry.data?.info?.receiptInfo?.status,
      label: "Paid Receipt",
      options: {
        customBodyRender: (x: CellValue): JSX.Element | string => {
          return titleStatusToChip(x as TitleIssueStatus);
        },
        sort: true,
        path: ["data", "info", "receiptInfo", "status"]
      },
      name: "receiptStatus",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.receiptStatus?.show;
      },
      filters: [
        {
          path: ["data", "info", "receiptInfo", "status"],
          preview: MultiSelectFilter,
          valuesForSelect: ["awaiting", "pending", "resolved", "rejected"],
          optionLabelForSelect: (x) => capitalize(x),
          label: "Paid Receipt status",
          name: "receiptStatus"
        }
      ]
    },
    {
      getData: (entry): CellValue =>
        [
          entry.data?.deal?.data?.applicant
            ? `${entry.data?.deal?.data?.applicant?.data?.info?.firstName ?? ""} ${
                entry.data?.deal?.data?.applicant?.data?.info?.lastName ?? ""
              }`
            : undefined,
          entry.data?.deal?.data?.coApplicant
            ? `${entry.data?.deal?.data?.coApplicant?.data?.info?.firstName ?? ""} ${
                entry.data?.deal?.data?.coApplicant?.data?.info?.lastName ?? ""
              }`
            : undefined
        ]
          .filter((x) => x)
          .join(" / "),
      label: "Applicant",
      name: "applicant",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.applicant?.show;
      },
      filters: [
        {
          path: [
            ["data", "deal", "data", "applicant", "data", "info", "firstName"],
            ["data", "deal", "data", "applicant", "data", "info", "lastName"],
            ["data", "deal", "data", "coApplicant", "data", "info", "firstName"],
            ["data", "deal", "data", "coApplicant", "data", "info", "lastName"]
          ],
          preview: ApplicantFilter
        }
      ]
    },
    {
      getData: (entry): CellValue => entry.data?.deal?.data?.info?.vehicle?.VIN,
      label: "VIN",
      name: "vin",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.vin?.show;
      },
      filters: [
        {
          path: ["data", "deal", "data", "info", "vehicle", "VIN"],
          preview: TextFilter
        }
      ]
    },
    {
      getData: (entry): CellValue => entry?.data?.deal?.data?.dealership?.data?.info?.displayName,
      label: "Dealer",
      name: "dealer",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.dealer?.show;
      },
      filters: [
        {
          path: ["data", "deal", "data", "dealership", "_id"],
          preview: DealershipFilter
        }
      ]
    },
    {
      getData: (entry): CellValue => entry?.data?.deal?.data?.lender?.data?.info?.name,
      label: "Lender",
      name: "lender",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.lender?.show;
      },
      filters: [
        {
          path: ["data", "deal", "data", "lender", "_id"],
          preview: LenderFilter
        }
      ]
    },
    {
      getData: (entry): CellValue => entry?.data?.deal?.data?.info?.refNumber,
      label: "Deal ref.",
      name: "dealRef",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.dealRef?.show;
      },
      filters: [
        {
          path: ["data", "deal", "data", "info", "refNumber"],
          preview: TextFilter
        }
      ]
    },
    {
      label: "State",
      getData: (entry): CellValue =>
        entry?.data?.deal?.data?.applicant?.data?.info?.currentState ?? "",
      options: {
        sort: true,
        path: ["data", "deal", "data", "applicant", "data", "info", "currentState"]
      },
      name: "state",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.state?.show;
      },
      filters: [
        {
          path: ["data", "deal", "data", "applicant", "data", "info", "currentState"],
          preview: MultiSelectFilter,
          valuesForSelect: Object.keys(States).sort()
        }
      ]
    },
    {
      label: "Type",
      getData: (entry): CellValue => entry?.data?.deal?.data?.info?.type,
      options: {
        sort: true,
        path: ["data", "deal", "data", "info", "type"]
      },
      name: "type",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.type?.show;
      },
      filters: [
        {
          path: ["data", "deal", "data", "info", "type"],
          preview: MultiSelectFilter,
          valuesForSelect: [...collateralTypes].sort()
        }
      ]
    },

    {
      getData: (entry): CellValue =>
        entry.data?.deal?.data?.info?.dealDates?.contractDate
          ? new Date(entry.data?.deal?.data?.info?.dealDates?.contractDate)
          : "",
      label: "Contract",
      options: {
        sort: true,
        path: ["data", "deal", "data", "info", "dealDates", "contractDate"]
      },
      name: "contractDate",
      show: (_userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.contractDate?.show;
      },
      filters: [
        {
          path: ["data", "deal", "data", "info", "dealDates", "contractDate"],
          preview: DateFilter,
          label: "Contract Date"
        }
      ]
    },

    {
      label: "Actions",
      getData: (entry): CellValue => entry._id,
      name: "actions",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.actions?.show;
      }
    }
  ];

  const handleResetStickyState = () => {
    resetStickyTableCallback?.();
  };
  React.useEffect(() => {
    setResetStickyCallback?.(() => handleResetStickyState);
  }, [resetStickyTableCallback]);
  return (
    <div style={{ position: "relative" }}>
      <Grid item xs={12} style={{ margin: "5px 0" }}>
        <Paper elevation={3} style={{ padding: "10px" }}>
          <Typography variant="h6">DMV Status</Typography>
          <AccessControl requiredPermissions={{ entity: "title_issue", action: "read" }}>
            <Table
              setResetStickyCallback={setResetStickyTableCallback}
              hideColumns={
                filters?.[sliceId]?.dmvStatus?.[0]?.values?.length === 1 &&
                filters?.[sliceId]?.dmvStatus?.[0]?.values?.[0] === "titled"
                  ? ["daysLeft"]
                  : []
              }
              elevation={0}
              tableContainerOverflowX="scroll"
              rowsPerPage={10}
              tableName={sliceId}
              entityName="title_issue"
              listFunction={getTitleIssueList}
              deleteEntityFunction={deleteTitleIssue}
              recoverEntityFunction={recoverTitleIssue}
              sort={{ "data.deal.data.info.dealDates.contractDate": "desc" }}
              slice={sliceId}
              listEntity={titleIssuesList}
              aggregateFirst={true}
              query={overallQuery}
              projection={projections}
              title={"Title Information"}
              columns={columns}
              hideAddButton
            />
          </AccessControl>
        </Paper>
      </Grid>
    </div>
  );
};
