import {
  Button,
  Checkbox,
  Chip,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  TextField,
  Tooltip
} from "@material-ui/core";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import { Timeline } from "@material-ui/icons";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { RootState } from "app/rootReducer";
import { Big } from "big.js";
import { getStyleByStatus, statusToChipTooltip, statusToLabel } from "components/Deals/Deals";
import { DealData, DealStatus, collateralTypes } from "components/Deals/types";
import ApplicantFilter from "components/Filters/ApplicantFilter";
import DateFilter from "components/Filters/DateFilter";
import DealershipFilter from "components/Filters/DealershipFilter";
import LenderFilter from "components/Filters/LenderFilter";
import MultiSelectFilter from "components/Filters/MultiSelectFilter";
import NetsuiteFilter from "components/Filters/NetsuiteFilter";
import PriceFilter from "components/Filters/PriceFilter";
import TextFilter from "components/Filters/TextFilter";
import UserFilter from "components/Filters/UserFilter";
import { getLenderList, removeLenderList } from "components/Lenders/listLenderSlice";
import { Lender } from "components/Lenders/types";
import Spinner from "components/Loader/Spinner";
import { CellValue, Column } from "components/Table";
import { HintTooltip } from "components/common/HintTooltip";
import { LenderStatisticRequest, Statistics, ValueByStatus } from "hooks/useLenderStatistics/types";
import useLenderStatistics from "hooks/useLenderStatistics/useLenderStatistics";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  capitalize,
  formatNumberAsCurrency,
  getFirstAndLastDateOfCurrentMonth,
  getFirstAndLastDateOfGivenYearAndMonth,
  parseMonth
} from "utils/functions";
import { v4 as uuidv4 } from "uuid";
import LenderChartByStatus from "../AllocationDashboard/LenderChartByStatus";
import LenderChartByType from "../AllocationDashboard/LenderChartByType";
import DealsModal from "../DealsModal";
import AmountFinancedInformation from "./AmountFinancedInformation";
import ManagerTable from "./ManagerTable";
import TrendsDialog from "./TrendsDialog";
import { useStickyState } from "index";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { isValidDate } from "utils/models/formRenderers";
export type ToggleState = "period" | "monthly" | "lifetime";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    checkboxLabel: {
      fontSize: "12px"
    },
    periodToggle: {
      display: "flex",
      width: 47,
      padding: 15,
      justifyContent: "center",
      alignItems: "center",
      cursor: "pointer"
    },
    periodSelect: {
      appearance: "none" as const,
      WebkitAppearance: "none" as const,
      MozAppearance: "none" as const,
      border: "none",
      background: "#ffffff"
    },
    yearlyToggle: {
      color: (props: { selected: ToggleState | undefined }) =>
        props.selected === "period" ? "#fff" : "#000",
      background: (props: { selected: ToggleState | undefined }) =>
        props.selected === "period" ? "#2B4E70" : "#E8E8E8"
    },
    timeframeToggle: {
      color: (props: { selected: ToggleState | undefined }) =>
        props.selected === "monthly" ? "#fff" : "#000",
      background: (props: { selected: ToggleState | undefined }) =>
        props.selected === "monthly" ? "#2B4E70" : "#E8E8E8"
    },
    lifetimeToggle: {
      color: (props: { selected: ToggleState | undefined }) =>
        props.selected === "lifetime" ? "#fff" : "#000",
      background: (props: { selected: ToggleState | undefined }) =>
        props.selected === "lifetime" ? "#2B4E70" : "#E8E8E8"
    }
  })
);
const generateYearArray = () =>
  Array.from({ length: 5 }, (_, i) => new Date().getFullYear() - 4 + i).reverse();
const MonthYearToggle = ({
  selected,
  setSelected,
  type
}: {
  type: "current" | "compare";
  selected: ToggleState | undefined;
  setSelected: (newState: ToggleState) => void;
}) => {
  const classes = useStyles({ selected });

  return (
    <div style={{ display: "flex" }}>
      <div
        className={[classes.periodToggle, classes.lifetimeToggle].join(" ")}
        style={
          type === "compare"
            ? { background: "rgba(0,0,0,0.3)", fontWeight: "bold", cursor: "default" }
            : {}
        }
        onClick={type === "compare" ? undefined : () => setSelected("lifetime")}
      >
        {type === "current" ? "LF" : "Compare"}
      </div>
      <div
        className={[classes.periodToggle, classes.yearlyToggle].join(" ")}
        style={type === "compare" ? { borderTop: "1px solid rgba(0,0,0,0.3)" } : {}}
        onClick={() => setSelected("period")}
      >
        {type === "current" ? "YTD" : "LYTD"}
      </div>
      <div
        className={[classes.periodToggle, classes.timeframeToggle].join(" ")}
        style={type === "compare" ? { borderTop: "1px solid rgba(0,0,0,0.3)" } : {}}
        onClick={() => setSelected("monthly")}
      >
        {type === "current" ? "MTD" : "LMTD"}
      </div>
    </div>
  );
};
type TrendsDialogState = {
  data: ((ValueByStatus | (ValueByStatus & { unallocated?: number })) & { period: string })[];
  title: string;
  currencyFormat: boolean;
  chartBy: "status" | "type";
  open: boolean;
};
export default function LenderDashboard({
  setResetStickyCallback
}: {
  setResetStickyCallback?: React.Dispatch<React.SetStateAction<(() => void) | undefined>>;
}) {
  const [lenders, setLenders] = useStickyState<Lender[]>([], "lender_dashboard_lenders");
  const [periodToggle, setToggle] = useStickyState<ToggleState>(
    "monthly",
    "lender_dashboard_periodToggle"
  );
  const [periodToggleCompare, setToggleCompare] = useStickyState<ToggleState | undefined>(
    undefined,
    "lender_dashboard_periodToggleCompare"
  );
  const classes = useStyles({ selected: periodToggle });
  const defaultTrendsDialogState = {
    title: "",
    open: false,
    currencyFormat: false,
    chartBy: "status" as const,
    data: []
  };
  const [trendsDialogState, setTrendsDialogState] = useState<TrendsDialogState>(
    defaultTrendsDialogState
  );
  const defaultStatuses = [
    DealStatus.Approved,
    DealStatus.Funded,
    DealStatus.FundingHeld,
    DealStatus.SentToDealer,
    DealStatus.SubmittedForFunding
  ];
  const [requestId] = useState(uuidv4());
  const dispatch = useDispatch();
  const listId = `list-autocomplete-lender-statistics-${requestId}`;
  const lenderList = useSelector((state: RootState) => state.listLenderSlice[listId]);
  const [showDealsModal, setShowDealsModal] = useState(false);
  const [state, setState] = useStickyState<LenderStatisticRequest>(
    {
      year: new Date().getUTCFullYear(),
      lenderIds: [],
      allLenders: true,
      month: new Date().getMonth() + 1,
      dealsFromLenderDecisions: false,
      collateralTypes: ["Automotive", "Marine", "Power Sport", "Recreational Vehicles"],
      statuses: defaultStatuses,
      ...getFirstAndLastDateOfCurrentMonth()
    },
    "lender_dashboard_state"
  );

  const handleSetToggle = (period: ToggleState) => {
    setToggle(period);
    const today = new Date();
    let dateQuery = {};
    switch (period) {
      case "lifetime":
        dateQuery = {
          from: new Date(2021, 0, 1, 0, 0, 0).toISOString(),
          to: today.toISOString(),
          compareFrom: undefined,
          compareTo: undefined
        };
        break;
      case "monthly":
        dateQuery = {
          from: new Date(today.getFullYear(), today.getMonth(), 1).toISOString(),
          to: today.toISOString()
        };
        break;
      case "period":
        dateQuery = {
          from: new Date(state.year, 0, 1, 0, 0, 0).toISOString(),
          to: today.toISOString()
        };
        break;
    }
    setState({
      ...state,
      ...dateQuery
    });
  };
  const handleSetToggleCompare = (period: ToggleState | undefined) => {
    const today = new Date();

    let dateQuery = {};

    switch (period) {
      case "monthly":
        const { from: monthlyFrom, to: monthlyTo } = getFirstAndLastDateOfGivenYearAndMonth(
          today.getFullYear(),
          today.getMonth()
        );
        dateQuery = {
          compareFrom: monthlyFrom?.toISOString(),
          compareTo: new Date(new Date(monthlyTo).setDate(today.getDate())).toISOString()
        };
        break;
      case "period":
        const yearlyFrom = new Date(today.getFullYear() - 1, 0, 1, 0, 0, 0);
        dateQuery = {
          compareFrom: yearlyFrom.toISOString(),
          compareTo: new Date(
            today.getFullYear() - 1,
            today.getMonth(),
            today.getDate()
          ).toISOString()
        };
        break;
    }
    setToggleCompare(period);
    setState({
      ...state,
      ...dateQuery
    });
  };
  const { data, isLoading } = useLenderStatistics({
    ...state,
    includeTrends: periodToggle === "period",
    lenderIds: lenders?.map((lender) => lender?._id ?? "")?.filter((x) => x)
  });
  React.useEffect(() => {
    if (!lenderList)
      dispatch(
        getLenderList(listId, {
          options: { pagination: false, sort: { "data.info.name": "asc" } }
        })
      );
    return () => {
      dispatch(removeLenderList(listId));
      return;
    };
  }, []);
  const handleResetStickyState = () => {
    setLenders([]);
    setToggle("monthly");
    setToggleCompare(undefined);
    setState({
      year: new Date().getUTCFullYear(),
      lenderIds: [],
      allLenders: true,
      month: new Date().getMonth() + 1,
      dealsFromLenderDecisions: false,
      collateralTypes: ["Automotive", "Marine", "Power Sport", "Recreational Vehicles"],
      statuses: defaultStatuses,
      ...getFirstAndLastDateOfCurrentMonth()
    });
  };
  React.useEffect(() => {
    setResetStickyCallback?.(() => handleResetStickyState);
  }, []);
  return (
    <>
      {setResetStickyCallback === undefined && (
        <Button
          onClick={() => handleResetStickyState()}
          style={{ margin: "5px 0px", float: "right" }}
          color="primary"
          variant="contained"
        >
          Reset state
        </Button>
      )}
      {trendsDialogState.open && (
        <TrendsDialog
          chartBy={trendsDialogState.chartBy}
          currencyFormat={trendsDialogState.currencyFormat}
          data={trendsDialogState.data}
          selectedCollateralTypes={state.collateralTypes}
          selectedStatuses={state.statuses}
          closeFunction={() => setTrendsDialogState(defaultTrendsDialogState)}
          title={trendsDialogState.title}
        />
      )}
      <Grid container xs={12} spacing={2} style={{ margin: 10 }}>
        <Grid container xs={12} spacing={2} style={{ marginBottom: 10, marginTop: 10 }}>
          <Grid item container xs={12}>
            <Grid item xs={2}>
              <Paper elevation={3} style={{ width: "100%" }}>
                <Tooltip
                  title={
                    state?.allLenders
                      ? "This option is available only when specific lender or lenders are selected."
                      : ""
                  }
                >
                  <div
                    style={{
                      fontWeight: "500",
                      display: "inline-flex",
                      paddingLeft: "14px",
                      width: "calc(100% - 14px)",
                      verticalAlign: "middle",
                      alignItems: "center",
                      background: "rgb(224 224 224)",
                      borderRadius: "4px"
                    }}
                  >
                    <FormControlLabel
                      disabled={state.allLenders}
                      classes={{ label: classes.checkboxLabel }}
                      control={
                        <Checkbox
                          checked={state?.dealsFromLenderDecisions}
                          onChange={(event) => {
                            if (event.target.checked) {
                              setState((prevState) => ({
                                ...prevState,
                                dealsFromLenderDecisions: true
                              }));
                            } else {
                              setState((prevState) => ({
                                ...prevState,
                                dealsFromLenderDecisions: false
                              }));
                            }
                          }}
                          name="dealsFromLenderDecisions"
                          color="primary"
                        />
                      }
                      label={`Add deals from lender decisions`}
                    />
                  </div>
                </Tooltip>
              </Paper>
            </Grid>
          </Grid>
          <Grid item xs={5}>
            <Paper elevation={3}>
              <div
                style={{
                  fontWeight: "500",
                  display: "inline-flex",
                  padding: "0px 14px",
                  marginRight: "10px",
                  width: "115px",
                  verticalAlign: "middle",
                  alignItems: "center",
                  background: "rgb(224 224 224)",
                  borderRadius: "4px 0px 0px 4px "
                }}
              >
                <FormControlLabel
                  classes={{ label: classes.checkboxLabel }}
                  control={
                    <Checkbox
                      indeterminate={
                        state?.collateralTypes?.length > 0 &&
                        state?.collateralTypes?.length !== collateralTypes.length
                      }
                      checked={state?.collateralTypes?.length === collateralTypes.length}
                      onChange={(event) => {
                        if (event.target.checked) {
                          setState((prevState) => ({
                            ...prevState,
                            collateralTypes: [
                              "Automotive",
                              "Marine",
                              "Power Sport",
                              "Recreational Vehicles"
                            ]
                          }));
                        } else {
                          setState((prevState) => ({
                            ...prevState,
                            collateralTypes: []
                          }));
                        }
                      }}
                      name="All"
                      color="primary"
                    />
                  }
                  label={`All types`}
                />
              </div>

              {Object.values(collateralTypes).map((collateralType, index) => (
                <FormControlLabel
                  classes={{ label: classes.checkboxLabel }}
                  key={index}
                  control={
                    <Checkbox
                      onChange={(event) => {
                        if (event.target.checked) {
                          setState((prevState) => ({
                            ...prevState,
                            collateralTypes: [...(prevState.collateralTypes ?? []), collateralType]
                          }));
                        } else {
                          setState((prevState) => ({
                            ...prevState,
                            collateralTypes: prevState.collateralTypes.filter(
                              (prevCollateralType) => prevCollateralType !== collateralType
                            )
                          }));
                        }
                      }}
                      checked={
                        state?.collateralTypes?.findIndex(
                          (prevCollateralType) => prevCollateralType === collateralType
                        ) > -1
                      }
                      name={collateralType}
                      color="primary"
                    />
                  }
                  label={collateralType}
                />
              ))}
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper elevation={3}>
              <div
                style={{
                  fontWeight: "500",
                  display: "inline-flex",
                  padding: "0px 14px",
                  marginRight: "10px",
                  width: "115px",
                  verticalAlign: "middle",
                  alignItems: "center",
                  background: "rgb(224 224 224)",
                  borderRadius: "4px 0px 0px 4px "
                }}
              >
                <FormControlLabel
                  classes={{ label: classes.checkboxLabel }}
                  control={
                    <Checkbox
                      indeterminate={
                        state?.statuses?.length > 0 &&
                        state?.statuses?.length !== Object.keys(DealStatus).length
                      }
                      checked={state?.statuses?.length === Object.keys(DealStatus).length}
                      onChange={(event) => {
                        if (event.target.checked) {
                          setState((prevState) => ({
                            ...prevState,
                            statuses: Object.values(DealStatus)
                          }));
                        } else {
                          setState((prevState) => ({
                            ...prevState,
                            statuses: []
                          }));
                        }
                      }}
                      name="All"
                      color="primary"
                    />
                  }
                  label={`All statuses`}
                />
              </div>

              {Object.values(DealStatus).map((status, index) => (
                <FormControlLabel
                  classes={{ label: classes.checkboxLabel }}
                  key={index}
                  control={
                    <Checkbox
                      onChange={(event) => {
                        if (event.target.checked) {
                          setState((prevState) => ({
                            ...prevState,
                            statuses: [...(prevState.statuses ?? []), status]
                          }));
                        } else {
                          setState((prevState) => ({
                            ...prevState,
                            statuses: prevState.statuses.filter(
                              (prevStatus) => prevStatus !== status
                            )
                          }));
                        }
                      }}
                      checked={
                        state?.statuses?.findIndex((prevStatus) => prevStatus === status) > -1
                      }
                      name={capitalize(status)}
                      color="primary"
                    />
                  }
                  label={capitalize(status)}
                />
              ))}
            </Paper>
          </Grid>
          <Grid item xs={2}>
            <Autocomplete
              style={{ zIndex: 6 }}
              value={
                state.allLenders ? ((["All lenders"] as unknown) as Lender[]) : lenders || null
              }
              getOptionLabel={(lender) => {
                if (((lender as unknown) as string) === "All lenders") {
                  return (lender as unknown) as string;
                }
                return lender.data.info.name ?? "";
              }}
              multiple
              getOptionSelected={(x, y) => x?._id === y?._id && x === y}
              options={[("All lenders" as unknown) as Lender, ...(lenderList?.entities ?? [])]}
              onChange={(event, newValue) => {
                if (newValue?.length === 0) {
                  setState((prevState) => ({
                    ...prevState,
                    dealsFromLenderDecisions: false,
                    allLenders: true
                  }));
                  setLenders((["All lenders"] as unknown) as Lender[]);
                } else {
                  setLenders(newValue.filter((x) => x !== (("All lenders" as unknown) as Lender)));
                  setState((prevState) => ({
                    ...prevState,
                    allLenders: false
                  }));
                }
              }}
              loading={!Array.isArray(lenderList?.entities)}
              openOnFocus
              id="lender-statistics-select"
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{ ...params.InputProps }}
                  label={"Lender"}
                  variant="filled"
                  size="small"
                />
              )}
            />
          </Grid>

          {/* <Grid item xs={2} style={{ marginTop: 5 }}>
            <Button color="primary" variant="contained" onClick={() => setShowDealsModal(true)}>
              Show deals
            </Button>
          </Grid> */}
          <Grid item xs={2}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                id={"from"}
                name={"from"}
                error={!isValidDate(state.from)}
                helperText={!isValidDate(state.from) ? "Invalid date" : undefined}
                autoOk
                fullWidth
                InputLabelProps={{ shrink: true }}
                inputVariant="filled"
                variant="inline"
                label={"From"}
                required={true}
                format="MM/dd/yyyy"
                KeyboardButtonProps={{
                  "aria-label": "change date"
                }}
                size="medium"
                value={state.from ?? null}
                onChange={(value) => {
                  if (isValidDate(value) && value) {
                    value.setHours(0);
                    value.setMinutes(0);
                    value.setSeconds(0);
                    value.setMilliseconds(0);
                    setState((prevState) => ({
                      ...prevState,
                      from: value.toISOString()
                    }));
                  } else {
                    setState((prevState) => ({
                      ...prevState,
                      from: value?.toString()
                    }));
                  }
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={2}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                id={"to"}
                name={"to"}
                error={!isValidDate(state.to)}
                helperText={!isValidDate(state.to) ? "Invalid date" : undefined}
                autoOk
                fullWidth
                InputLabelProps={{ shrink: true }}
                inputVariant="filled"
                variant="inline"
                label={"To"}
                required={true}
                format="MM/dd/yyyy"
                KeyboardButtonProps={{
                  "aria-label": "change date"
                }}
                size="medium"
                value={state.to ?? null}
                onChange={(value) => {
                  if (isValidDate(value) && value) {
                    value.setHours(0);
                    value.setMinutes(0);
                    value.setSeconds(0);
                    value.setMilliseconds(0);
                    setState((prevState) => ({
                      ...prevState,
                      to: value.toISOString()
                    }));
                  } else {
                    setState((prevState) => ({
                      ...prevState,
                      to: value?.toString()
                    }));
                  }
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={2}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                id={"compareFrom"}
                name={"compareFrom"}
                error={state.compareFrom ? !isValidDate(state.compareFrom) : false}
                helperText={
                  state.compareFrom
                    ? !isValidDate(state.compareFrom)
                      ? "Invalid date"
                      : undefined
                    : undefined
                }
                autoOk
                fullWidth
                InputLabelProps={{ shrink: true }}
                inputVariant="filled"
                variant="inline"
                disabled={periodToggle === "lifetime"}
                label={"From (Compare)"}
                format="MM/dd/yyyy"
                KeyboardButtonProps={{
                  "aria-label": "change date"
                }}
                size="medium"
                value={state.compareFrom ?? null}
                onChange={(value) => {
                  if (isValidDate(value) && value) {
                    value.setHours(0);
                    value.setMinutes(0);
                    value.setSeconds(0);
                    value.setMilliseconds(0);
                    setState((prevState) => ({
                      ...prevState,
                      compareFrom: value.toISOString()
                    }));
                  } else {
                    setState((prevState) => ({
                      ...prevState,
                      compareFrom: value?.toString()
                    }));
                  }
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={2}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                id={"compareTo"}
                name={"compareTo"}
                error={state.compareTo ? !isValidDate(state.compareTo) : false}
                helperText={
                  state.compareTo
                    ? !isValidDate(state.compareTo)
                      ? "Invalid date"
                      : undefined
                    : undefined
                }
                autoOk
                fullWidth
                InputLabelProps={{ shrink: true }}
                inputVariant="filled"
                variant="inline"
                disabled={periodToggle === "lifetime"}
                label={"To (Compare)"}
                format="MM/dd/yyyy"
                KeyboardButtonProps={{
                  "aria-label": "change date"
                }}
                size="medium"
                value={state.compareTo ?? null}
                onChange={(value) => {
                  if (isValidDate(value) && value) {
                    value.setHours(0);
                    value.setMinutes(0);
                    value.setSeconds(0);
                    value.setMilliseconds(0);
                    setState((prevState) => ({
                      ...prevState,
                      compareTo: value.toISOString()
                    }));
                  } else {
                    setState((prevState) => ({
                      ...prevState,
                      compareTo: value?.toString()
                    }));
                  }
                }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid
            item
            xs={2}
            style={{
              // marginLeft: "auto",
              // justifyContent: "flex-end",
              alignItems: "flex-end",
              flexDirection: "column",
              display: "flex"
            }}
          >
            <MonthYearToggle selected={periodToggle} type="current" setSelected={handleSetToggle} />
            <MonthYearToggle
              selected={periodToggleCompare}
              type="compare"
              setSelected={handleSetToggleCompare}
            />
          </Grid>
        </Grid>

        {isLoading ? (
          <Spinner
            text={`Loading: ${
              state.allLenders
                ? `All lenders${periodToggle === "lifetime" ? " (May take a minute)" : ""}`
                : lenders?.map((lender) => lender?.data?.info?.name).join(" ,")
            }`}
            style={{ position: "relative", zIndex: 5 }}
          />
        ) : (
          data &&
          data?.data && (
            <>
              <Grid container xs={12} spacing={2} style={{ marginBottom: 10 }}>
                <Grid item xs={3} style={{ position: "relative" }}>
                  {periodToggle === "period" && (
                    <HintTooltip title="Click here to view trends.">
                      <IconButton
                        style={{
                          position: "absolute",
                          top: 20,
                          left: 20,
                          padding: 0,
                          zIndex: 100
                        }}
                        onClick={() =>
                          setTrendsDialogState({
                            open: true,
                            currencyFormat: true,
                            chartBy: "status",
                            title: "Amount financed by status trends",
                            data: data?.data?.trendsStatistics?.map((trendStatistic) => ({
                              ...trendStatistic.amountFinancedByStatus,
                              period: trendStatistic.period
                            }))
                          })
                        }
                      >
                        <Timeline fontSize="large" />
                      </IconButton>
                    </HintTooltip>
                  )}
                  <LenderChartByStatus
                    selectedStatuses={state.statuses}
                    currencyFormat={true}
                    data={data?.data?.statistics?.amountFinancedByStatus}
                    comparedData={data?.data?.comparedStatistics?.amountFinancedByStatus}
                    chartType="bar"
                    title="Amount financed by status"
                  />
                </Grid>
                <Grid item xs={3} style={{ position: "relative" }}>
                  {periodToggle === "period" && (
                    <HintTooltip title="Click here to view trends.">
                      <IconButton
                        style={{
                          position: "absolute",
                          top: 20,
                          left: 20,
                          padding: 0,
                          zIndex: 100
                        }}
                        onClick={() =>
                          setTrendsDialogState({
                            open: true,
                            currencyFormat: false,
                            chartBy: "status",
                            title: "Average credit score trends",
                            data: data?.data?.trendsStatistics?.map((trendStatistic) => ({
                              ...calculateAvgCreditScoreByStatus(trendStatistic),
                              period: trendStatistic.period
                            }))
                          })
                        }
                      >
                        <Timeline fontSize="large" />
                      </IconButton>
                    </HintTooltip>
                  )}

                  <div style={{ position: "absolute", left: 20, bottom: 10 }}>
                    Total average credit score: {data?.data?.statistics?.avgCreditScore ?? 0}
                  </div>
                  <LenderChartByStatus
                    selectedStatuses={state.statuses}
                    data={calculateAvgCreditScoreByStatus(data?.data?.statistics)}
                    comparedData={calculateAvgCreditScoreByStatus(data?.data?.comparedStatistics)}
                    chartType="bar"
                    title="Average credit score by status"
                  />
                </Grid>
                <Grid item xs={3} style={{ position: "relative" }}>
                  {periodToggle === "period" && (
                    <HintTooltip title="Click here to view trends.">
                      <IconButton
                        style={{
                          position: "absolute",
                          top: 20,
                          left: 20,
                          padding: 0,
                          zIndex: 100
                        }}
                        onClick={() =>
                          setTrendsDialogState({
                            open: true,
                            currencyFormat: false,
                            chartBy: "type",
                            title: "Deal count by type trends",
                            data: data?.data?.trendsStatistics?.map((trendStatistic) => ({
                              ...trendStatistic.dealCountByType,
                              period: trendStatistic.period
                            }))
                          })
                        }
                      >
                        <Timeline fontSize="large" />
                      </IconButton>
                    </HintTooltip>
                  )}
                  <div style={{ position: "absolute", left: 20, bottom: 10 }}>
                    Total count: {data?.data?.statistics?.totalDeals || 0}
                  </div>
                  <LenderChartByType
                    selectedCollateralTypes={state.collateralTypes}
                    data={data?.data?.statistics?.dealCountByType}
                    comparedData={data?.data?.comparedStatistics?.dealCountByType}
                    chartType="pie"
                    title={"Deal count by type"}
                  />
                </Grid>
                <Grid item xs={3} style={{ position: "relative" }}>
                  {periodToggle === "period" && (
                    <HintTooltip title="Click here to view trends.">
                      <IconButton
                        style={{
                          position: "absolute",
                          top: 20,
                          left: 20,
                          padding: 0,
                          zIndex: 100
                        }}
                        onClick={() =>
                          setTrendsDialogState({
                            open: true,
                            currencyFormat: false,
                            chartBy: "status",
                            title: "Deal count by status trends",
                            data: data?.data?.trendsStatistics?.map((trendStatistic) => ({
                              ...trendStatistic.dealCountByStatus,
                              period: trendStatistic.period
                            }))
                          })
                        }
                      >
                        <Timeline fontSize="large" />
                      </IconButton>
                    </HintTooltip>
                  )}
                  <LenderChartByStatus
                    selectedStatuses={state.statuses}
                    data={data?.data?.statistics?.dealCountByStatus}
                    comparedData={data?.data?.comparedStatistics?.dealCountByStatus}
                    chartType="bar"
                    title={"Deal count by status"}
                  />
                </Grid>
              </Grid>
              <Grid container xs={12} spacing={2}>
                <Grid
                  item
                  xs={8}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: " flex-start"
                  }}
                >
                  <ManagerTable
                    data={data?.data?.statistics}
                    comparedData={data?.data?.comparedStatistics}
                  />
                </Grid>

                <Grid
                  item
                  xs={4}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: " flex-start"
                  }}
                >
                  <AmountFinancedInformation
                    comparedStatistic={data?.data?.comparedStatistics}
                    statistic={data?.data?.statistics}
                    period={getPeriod(state, periodToggle)}
                  />
                </Grid>
              </Grid>
            </>
          )
        )}
      </Grid>
      {showDealsModal && data ? (
        <DealsModal
          columns={
            [
              {
                getData: (entry): CellValue =>
                  [
                    entry.data?.applicant
                      ? `${entry.data?.applicant?.data?.info?.firstName ?? ""} ${
                          entry.data?.applicant?.data?.info?.lastName ?? ""
                        }`
                      : undefined,
                    entry.data?.coApplicant
                      ? `${entry.data?.coApplicant?.data?.info?.firstName ?? ""} ${
                          entry.data?.coApplicant?.data?.info?.lastName ?? ""
                        }`
                      : undefined
                  ]
                    .filter((x) => x)
                    .join(" / "),
                label: "Customer",
                name: "customer",
                show: (userPermissions, tableSettings) => {
                  return tableSettings?.data?.columns?.customer?.show;
                },
                filters: [
                  {
                    path: [
                      ["data", "applicant", "data", "info", "firstName"],
                      ["data", "applicant", "data", "info", "lastName"],
                      ["data", "coApplicant", "data", "info", "firstName"],
                      ["data", "coApplicant", "data", "info", "lastName"]
                    ],
                    preview: ApplicantFilter
                  }
                ]
              },
              {
                getData: (entry): CellValue => entry.data?.info?.refNumber,
                label: "Ref #",
                options: {
                  sort: true,
                  path: ["data", "info", "refNumber"]
                },
                name: "refNumber",
                show: (userPermissions, tableSettings) => {
                  return tableSettings?.data?.columns?.refNumber?.show;
                },
                filters: [
                  {
                    path: ["data", "info", "refNumber"],
                    preview: TextFilter
                  }
                ]
              },
              {
                getData: (entry): CellValue =>
                  entry.data?.dealership?.data?.info?.displayName ?? "",
                label: "Dealership",
                name: "dealership",
                show: (userPermissions, tableSettings) => {
                  return tableSettings?.data?.columns?.dealership?.show;
                },
                filters: [
                  {
                    path: ["data", "dealershipId"],
                    preview: DealershipFilter
                  }
                ]
              },
              {
                getData: (entry): CellValue => `${entry.data?.dealership?.data?.info?.state ?? ""}`,
                label: "Dealer state",
                name: "dealerState",
                show: (userPermissions, tableSettings) => {
                  return tableSettings?.data?.columns?.dealerState?.show;
                },
                filters: [
                  {
                    path: ["data", "dealership", "data", "info", "state"],
                    preview: TextFilter,
                    partialSearch: true,
                    caseInsensitive: true
                  }
                ]
              },
              {
                getData: (entry): CellValue => entry.data?.info?.vehicle?.make,
                label: "Make",
                name: "make",
                show: (userPermissions, tableSettings) => {
                  return tableSettings?.data?.columns?.make?.show;
                },
                filters: [
                  {
                    path: ["data", "info", "vehicle", "make"],
                    preview: TextFilter
                  }
                ]
              },
              {
                getData: (entry): CellValue => entry.data?.info?.vehicle?.model,
                label: "Model",
                name: "model",
                show: (userPermissions, tableSettings) => {
                  return tableSettings?.data?.columns?.model?.show;
                },
                filters: [
                  {
                    path: ["data", "info", "vehicle", "model"],
                    preview: TextFilter
                  }
                ]
              },
              {
                getData: (entry): CellValue => entry.data?.info?.vehicle?.VIN,
                label: "VIN",
                name: "vin",
                show: (userPermissions, tableSettings) => {
                  return tableSettings?.data?.columns?.vin?.show;
                },
                filters: [
                  {
                    path: ["data", "info", "vehicle", "VIN"],
                    preview: TextFilter
                  }
                ]
              },
              {
                getData: (entry): CellValue => entry.data?.lender?.data?.info?.name,
                label: "Lender",
                name: "lender",
                show: (userPermissions, tableSettings) => {
                  return tableSettings?.data?.columns?.lender?.show;
                },
                filters: [
                  {
                    path: ["data", "lenderId"],
                    preview: LenderFilter
                  }
                ]
              },
              {
                getData: (entry): CellValue =>
                  `${entry.data?.user?.data?.info?.firstName ?? ""} ${
                    entry.data?.user?.data?.info?.lastName ?? ""
                  }`,
                label: "F&I mgr",
                name: "f&iManager",
                show: (userPermissions, tableSettings) => {
                  return tableSettings?.data?.columns?.["f&iManager"]?.show;
                },
                filters: [
                  {
                    path: ["data", "userId"],
                    preview: UserFilter,
                    type: "f&i manager"
                  }
                ]
              },
              {
                getData: (entry): CellValue =>
                  `${entry.data?.dealership?.data?.representative?.data?.info?.firstName ?? ""} ${
                    entry.data?.dealership?.data?.representative?.data?.info?.lastName ?? ""
                  }`,
                label: "Rep",
                name: "rep",
                show: (userPermissions, tableSettings) => {
                  return tableSettings?.data?.columns?.rep?.show;
                },
                filters: [
                  {
                    path: ["data", "dealership", "data", "representativeId"],
                    preview: UserFilter,
                    type: "representative"
                  }
                ]
              },
              {
                getData: (entry): CellValue =>
                  entry.data?.info?.dealDates?.contractDate
                    ? new Date(entry.data?.info?.dealDates?.contractDate)
                    : "",
                label: "Contract",
                options: {
                  sort: true,
                  path: ["data", "info", "dates", "contractDate"]
                },
                name: "contractDate",
                show: (_userPermissions, tableSettings) => {
                  return tableSettings?.data?.columns?.contractDate?.show;
                },
                filters: [
                  {
                    path: ["data", "info", "dealDates", "contractDate"],
                    preview: DateFilter,
                    label: "Contract Date"
                  }
                ]
              },
              {
                getData: (entry): CellValue => entry.data?.info?.status,
                label: "Status",
                options: {
                  customBodyRender: (x: CellValue, deal): JSX.Element | string => {
                    const dealStatus = x as DealStatus;
                    return statusToChipTooltip(dealStatus, deal, getStyleByStatus(dealStatus));
                  },
                  sort: true,
                  path: ["data", "info", "status"]
                },
                name: "status",
                show: (userPermissions, tableSettings) => {
                  return tableSettings?.data?.columns?.status?.show;
                },
                filters: [
                  {
                    path: ["data", "info", "status"],
                    preview: MultiSelectFilter,
                    valuesForSelect: Object.values(DealStatus),
                    optionLabelForSelect: (status) => statusToLabel(status)
                  }
                ]
              },

              {
                getData: (entry): CellValue =>
                  entry.data?.info?.dealDates?.submittedForFunding
                    ? new Date(entry.data?.info?.dealDates?.submittedForFunding)
                    : "",
                label: "Submitted at",
                options: {
                  sort: true,
                  path: ["data", "info", "dates", "submittedForFunding"]
                },
                name: "fundedAt",
                show: () => {
                  return true;
                },
                filters: [
                  {
                    path: ["data", "info", "dealDates", "submittedForFunding"],
                    preview: DateFilter,
                    label: "Submitted for funding"
                  }
                ]
              },
              {
                getData: (entry): CellValue => (entry as unknown) as string,
                label: "Netsuite",
                options: {
                  customBodyRender: (x: CellValue, deal): JSX.Element | string => {
                    return (
                      <div style={{ whiteSpace: "nowrap" }}>
                        {deal?.data?.netsuiteDealStatus?.data?.info?.netsuiteEstimateId && (
                          <Chip size="small" label="E" style={{ fontFamily: "monospace" }} />
                        )}
                        {deal?.data?.netsuiteDealStatus?.data?.info?.netsuiteSalesOrderId && (
                          <Chip
                            size="small"
                            label="S"
                            style={{ backgroundColor: "#eba93f", fontFamily: "monospace" }}
                          />
                        )}
                        {deal?.data?.netsuiteDealStatus?.data?.info?.netsuiteInvoiceId && (
                          <Chip
                            size="small"
                            label="I"
                            style={{ backgroundColor: "#4caf50", fontFamily: "monospace" }}
                          />
                        )}
                        {(deal?.data?.netsuiteDealStatus?.data?.info?.netsuiteVendorBillIds ?? [])
                          .length > 0 && (
                          <Chip
                            size="small"
                            label="B"
                            style={{ backgroundColor: "#4caf50", fontFamily: "monospace" }}
                          />
                        )}
                      </div>
                    );
                  },
                  sort: true,
                  path: ["data"]
                },
                name: "netsuite",
                show: (userPermissions, tableSettings) => {
                  return tableSettings?.data?.columns?.netsuite?.show;
                },
                filters: [
                  {
                    path: ["data", "netsuiteDealStatus", "data", "info", "netsuiteEstimateId"],
                    name: "netsuiteEstimateId",
                    label: "Netsuite Estimate Id",
                    preview: NetsuiteFilter
                  },
                  {
                    path: ["data", "netsuiteDealStatus", "data", "info", "netsuiteSalesOrderId"],
                    name: "netsuiteSalesOrderId",
                    label: "Netsuite Sales Order Id",
                    preview: NetsuiteFilter
                  },
                  {
                    path: ["data", "netsuiteDealStatus", "data", "info", "netsuiteInvoiceId"],
                    name: "netsuiteInvoiceId",
                    label: "Netsuite Invoice Id",
                    preview: NetsuiteFilter
                  },
                  {
                    path: ["data", "netsuiteDealStatus", "data", "info", "netsuiteVendorBillIds"],
                    name: "netsuiteVendorBillIds",
                    label: "Netsuite Vendor Bill Ids",
                    preview: NetsuiteFilter
                  },
                  {
                    path: ["data", "info", "payment", "fundedAmount"],
                    name: "fundedAmount",
                    label: "Funded Amount",
                    preview: NetsuiteFilter
                  }
                ]
              },
              {
                getData: (entry): CellValue =>
                  formatNumberAsCurrency(entry.data?.info?.payment?.dealTotal ?? 0),
                label: "Amount Financed",
                name: "amountFinanced",
                show: (userPermissions, tableSettings) => {
                  return tableSettings?.data?.columns?.amountFinanced?.show;
                },
                options: {
                  sort: true,
                  path: ["data", "info", "payment", "dealTotal"]
                },
                filters: [
                  {
                    path: ["data", "info", "payment", "dealTotal"],
                    preview: PriceFilter
                  }
                ]
              },
              {
                getData: (entry): CellValue =>
                  formatNumberAsCurrency(entry.data?.info?.payment?.fundedAmount ?? 0),
                label: "Funded",
                name: "fundedAmount",
                show: (userPermissions, tableSettings) => {
                  return tableSettings?.data?.columns?.fundedAmount?.show;
                },
                options: {
                  sort: true,
                  path: ["data", "info", "payment", "fundedAmount"]
                },
                filters: [
                  {
                    path: ["data", "info", "payment", "fundedAmount"],
                    preview: PriceFilter
                  }
                ]
              },
              {
                getData: (entry): CellValue =>
                  entry.data?.info?.dealDates?.fundedAt
                    ? new Date(entry.data?.info?.dealDates?.fundedAt)
                    : "",
                label: "Funded At",
                options: {
                  sort: true,
                  path: ["data", "info", "dealDates", "fundedAt"]
                },
                name: "fundedAt",
                show: (userPermissions, tableSettings) => {
                  return tableSettings?.data?.columns?.fundedAt?.show;
                },
                filters: [
                  {
                    path: ["data", "info", "dealDates", "fundedAt"],
                    preview: DateFilter,
                    label: "Funded At"
                  }
                ]
              },
              {
                getData: (entry): CellValue => entry._id,
                label: "Approver",
                name: "approver",
                show: (userPermissions, tableSettings) => {
                  return tableSettings?.data?.columns?.approver?.show;
                },
                filters: [
                  {
                    path: ["approvedByUserId"],
                    preview: UserFilter,
                    label: "Approver"
                  }
                ]
              },
              {
                getData: (entry): CellValue => entry._id,
                label: "Actions",
                name: "actions",
                show: (userPermissions, tableSettings) => {
                  return tableSettings?.data?.columns?.actions?.show;
                }
              }
            ] as Column<DealData>[]
          }
          lenderNames={
            lenders?.length
              ? lenders?.map((lender) => lender?.data?.info?.name)?.join(" ,")
              : "All Lenders"
          }
          lenderIds={lenders?.map((lender) => lender?._id)}
          data={[
            {
              period: getPeriod(state, periodToggle),
              dealIds: data?.data?.statistics?.dealIds ?? []
            }
          ]}
          closeFunction={() => setShowDealsModal(false)}
        />
      ) : null}
    </>
  );
}

const getPeriod = (state: LenderStatisticRequest, toggleState: ToggleState) => {
  return toggleState === "monthly"
    ? `${state.year}/${parseMonth(state.month)}`
    : state?.from && state?.to
    ? `${new Date(state?.from).getFullYear()}/${parseMonth(
        new Date(state?.from).getMonth() + 1
      )} - ${new Date(state?.to).getFullYear()}/${parseMonth(new Date(state?.to).getMonth() + 1)}`
    : "No period";
};

const calculateAvgCreditScoreByStatus = (statistics: Statistics | undefined): ValueByStatus => {
  return Object.entries(statistics?.creditScoreByStatus ?? {}).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]: new Big(value)
        .div(new Big(statistics?.dealCountByStatus?.[key as DealStatus] || 1))
        .round(2, 1)
        .toNumber()
    }),
    Object.fromEntries(Object.values(DealStatus).map((v) => [v, 0]))
  );
};
