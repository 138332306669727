import React from "react";
import ReactApexChart from "react-apexcharts";
import Paper from "@material-ui/core/Paper";
import { formatNumberAsCurrency, percentageDifference } from "utils/functions";
import { CollateralTypes } from "components/Deals/types";
import { ValueByType } from "hooks/useLenderStatistics/types";
import { useMediaQuery } from "@material-ui/core";

interface Props {
  data: ValueByType | undefined;
  comparedData?: ValueByType | undefined;
  chartType: "bar" | "pie";
  selectedCollateralTypes: CollateralTypes[];
  title: string;
  currencyFormat?: boolean;
}
const addSelectedCollateralTypesToData = (
  data: ValueByType | undefined,
  selectedCollateralTypes: CollateralTypes[]
): ValueByType => {
  const dataWithAllCollateralTypes = {
    Automotive: data?.Automotive ?? 0,
    Marine: data?.Marine ?? 0,
    ["Recreational Vehicles"]: data?.["Recreational Vehicles"] ?? 0,
    ["Power Sport"]: data?.["Power Sport"] ?? 0
  };
  return Object.fromEntries(
    Object.entries(dataWithAllCollateralTypes).filter(([key, _]) =>
      selectedCollateralTypes.includes(key as CollateralTypes)
    )
  );
};

const sortData = (
  data: ValueByType | undefined,
  selectedCollateralTypes: CollateralTypes[]
): Record<CollateralTypes, { data: number; color: string }> => {
  const dataWithSelectedCollateralTypes = addSelectedCollateralTypesToData(
    data,
    selectedCollateralTypes
  );
  const order = ["Automotive", "Marine", "Recreational Vehicles", "Power Sport"];
  const orderMap = new Map();
  order.forEach((value, index) => {
    orderMap.set(value, index);
  });

  const sortedDataEntries = Object.entries(dataWithSelectedCollateralTypes ?? {}).sort(
    ([keyA, valueA], [keyB, valueB]) => {
      const indexA = orderMap.get(keyA);
      const indexB = orderMap.get(keyB);

      if (indexA === undefined && indexB === undefined) {
        return 0;
      } else if (indexA === undefined) {
        return 1;
      } else if (indexB === undefined) {
        return -1;
      }

      return indexA - indexB;
    }
  );
  const sortedData = {
    ...Object.fromEntries(sortedDataEntries)
  } as ValueByType;
  const sortedDataWithColors = addColors(sortedData);
  return sortedDataWithColors;
};
const addColors = (obj: ValueByType): Record<CollateralTypes, { data: number; color: string }> => ({
  ...Object.entries(obj).reduce(
    (acc: Record<CollateralTypes, { data: number; color: string }>, [key, value]) => ({
      ...acc,
      [key]: { data: value || 0, color: getColorByCollateralType(key as CollateralTypes) }
    }),
    {} as Record<CollateralTypes, { data: number; color: string }>
  )
});
const getColorByCollateralType = (collateralType: CollateralTypes) => {
  switch (collateralType) {
    case "Automotive":
      return "#f0d855";
    case "Marine":
      return "#51bde3";
    case "Recreational Vehicles":
      return "#52a543";
    case "Power Sport":
      return "#ffa500";
  }
};

export default function LenderChartByType({
  data,
  comparedData,
  chartType,
  selectedCollateralTypes,
  title,
  currencyFormat = false
}: Props) {
  const matches = useMediaQuery("(min-width:800px)");
  const sortedData = sortData(data, selectedCollateralTypes);
  const categories = Object.keys(sortedData);
  const items =
    chartType === "bar"
      ? [{ data: Object.values(sortedData).map((x) => parseFloat(x?.data?.toFixed(2))) }]
      : Object.values(sortedData).map((x) => parseFloat(x?.data?.toFixed(2)));

  return (
    <Paper
      style={
        matches
          ? { height: "255px" }
          : {
              display: "flex",
              width: "100%",
              height: "370px",
              overflowX: "scroll",
              overflowY: "auto",
              position: "relative"
            }
      }
    >
      <ReactApexChart
        options={{
          chart: {
            toolbar: {
              show: false
            }
          },
          legend: {
            formatter: (legendName, opts) => {
              const value =
                chartType === "bar"
                  ? opts.w.config.series[0].data[opts.seriesIndex]
                  : opts.w.config.series[opts.seriesIndex];
              const category = opts.w.config.xaxis.categories[opts.seriesIndex];
              const comparedValue = comparedData?.[category as CollateralTypes];
              const diff = percentageDifference(value || 0, comparedValue || 0);
              const formattedValue = currencyFormat ? formatNumberAsCurrency(value, "$") : value;
              return `${category} - ${formattedValue}${
                comparedValue ? `(${diff > 0 ? "+" : "-"}${diff.toFixed(2)}%)` : ""
              }`;
            },
            offsetY: 50,
            position: "right"
          },
          plotOptions: {
            ...(chartType === "pie" ? { pie: { customScale: 0.9 } } : {}),
            bar: { barHeight: "100%", distributed: true, horizontal: false }
          },
          labels: categories,
          colors: Object.values(sortedData).map((x) => x.color),
          dataLabels: {
            dropShadow: { enabled: false },
            style: {
              colors: ["black"]
            },
            ...(currencyFormat
              ? {
                  formatter: (val, opts) => {
                    return (
                      formatNumberAsCurrency(opts.w.config.series[opts.seriesIndex], "$") ?? ""
                    );
                  }
                }
              : {
                  formatter: (val, opts) => {
                    return typeof opts.w.config.series[opts.seriesIndex] === "number"
                      ? opts.w.config.series[opts.seriesIndex]
                      : "";
                  }
                })
          },
          stroke: { width: 1, colors: ["#fff"] },
          xaxis: { categories },
          yaxis: {
            labels: {
              show: true,
              ...(currencyFormat
                ? {
                    formatter: (val: any) => {
                      return formatNumberAsCurrency(val, "$") ?? "";
                    }
                  }
                : {})
            }
          },
          title: { text: title, align: "center", style: { fontSize: "22px", fontWeight: 600 } }
        }}
        series={items}
        type={chartType}
        height={250}
      />
    </Paper>
  );
}
