import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  Typography
} from "@material-ui/core";
import React from "react";
import { Stipulation } from "./types";
import { capitalize } from "utils/functions";
import formatDate from "utils/formatDate";
import { HintTooltip } from "components/common/HintTooltip";
import { Info, KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";
import CloseDialogButton from "components/common/CloseDialogButton";

export type RejectedURL = {
  url: string;
  uploadedAt?: string;
  uploadedBy?: "applicant" | "coApplicant" | "dealer" | "manager";
};
type UploadedFilesDialogProps = {
  closeFn: () => void;
  open: boolean;
  selectedStipulation: Stipulation | undefined;
  setSelectedStipulation: React.Dispatch<React.SetStateAction<Stipulation | undefined>>;
  setSelectedRejectedURL: React.Dispatch<React.SetStateAction<RejectedURL | undefined>>;
  selectedRejectedURL: RejectedURL | undefined;
};

const UploadedFilesDialog = ({
  open,
  selectedStipulation,
  setSelectedStipulation,
  setSelectedRejectedURL,
  selectedRejectedURL,
  closeFn
}: UploadedFilesDialogProps) => {
  return (
    <Dialog open={open} fullWidth maxWidth={"xl"}>
      <CloseDialogButton closeFunction={closeFn} />
      <DialogTitle>Stipulation: {selectedStipulation?.data?.info?.description}</DialogTitle>
      <DialogContent style={{ padding: "0px 20px" }}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <List
              style={{ maxHeight: "700px", overflowY: "auto" }}
              component={"div"}
              disablePadding
              subheader={
                <ListSubheader
                  disableSticky
                  component="div"
                  style={{ fontSize: "18px" }}
                  id="nested-list-subheader"
                >
                  Uploaded files list
                </ListSubheader>
              }
            >
              <ListItem
                divider
                button
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectedRejectedURL(
                    selectedStipulation?.data?.info?.url
                      ? {
                          url: selectedStipulation?.data?.info?.url as string,
                          uploadedAt: selectedStipulation?.data?.info?.uploadedAt,
                          uploadedBy: selectedStipulation?.data?.info?.uploadedBy
                        }
                      : undefined
                  );
                }}
                style={{
                  ...(selectedRejectedURL?.url === selectedStipulation?.data?.info?.url
                    ? { background: "#f3f3f3" }
                    : {})
                }}
              >
                <ListItemText
                  primary={
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography variant="subtitle1">Current file</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="subtitle1">
                          Uploaded by: {capitalize(selectedStipulation?.data?.info?.uploadedBy)}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="subtitle1">
                          Uploaded at:{" "}
                          {formatDate(selectedStipulation?.data?.info?.uploadedAt, "medium", true)}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <a
                          href={selectedStipulation?.data?.info?.url as string}
                          rel="noreferrer"
                          target="_blank"
                        >
                          Link
                        </a>
                      </Grid>
                    </Grid>
                  }
                />
                <ListItemSecondaryAction>
                  <div style={{ display: "flex", gap: "0px", alignItems: "center" }}>
                    <HintTooltip
                      placement="top"
                      title={
                        <Typography variant="subtitle1">
                          This is the latest uploaded file
                        </Typography>
                      }
                    >
                      <Info style={{ color: "#0077b6" }} />
                    </HintTooltip>
                    <IconButton
                      onClick={() => {
                        setSelectedRejectedURL(
                          selectedStipulation?.data?.info?.url
                            ? {
                                url: selectedStipulation?.data?.info?.url as string,
                                uploadedAt: selectedStipulation?.data?.info?.uploadedAt,
                                uploadedBy: selectedStipulation?.data?.info?.uploadedBy
                              }
                            : undefined
                        );
                      }}
                    >
                      {selectedRejectedURL?.url === selectedStipulation?.data?.info?.url ? (
                        <KeyboardArrowLeft />
                      ) : (
                        <KeyboardArrowRight />
                      )}
                    </IconButton>
                  </div>
                </ListItemSecondaryAction>
              </ListItem>

              {selectedStipulation?.data.info?.rejectedUrls?.map((x, index) => (
                <ListItem
                  key={index}
                  button
                  onClick={() => setSelectedRejectedURL(x)}
                  style={{
                    ...(selectedRejectedURL?.url === x?.url ? { background: "#f3f3f3" } : {})
                  }}
                >
                  <ListItemText
                    primary={
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography variant="subtitle1">Past or rejected file</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="subtitle1">
                            Uploaded by: {capitalize(x?.uploadedBy)}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="subtitle1">
                            Uploaded at: {formatDate(x?.uploadedAt, "medium", true)}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <a href={x?.url} rel="noreferrer" target="_blank">
                            Link
                          </a>
                        </Grid>
                      </Grid>
                    }
                  />
                  <ListItemSecondaryAction>
                    <IconButton onClick={() => setSelectedRejectedURL(x)}>
                      {selectedRejectedURL?.url === x?.url ? (
                        <KeyboardArrowLeft />
                      ) : (
                        <KeyboardArrowRight />
                      )}
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          </Grid>
          <Grid item xs={9}>
            <iframe
              title="Stipulation"
              src={selectedRejectedURL?.url ?? selectedStipulation?.data?.info?.url ?? ""}
              style={{ width: "100%", height: "700px" }}
            ></iframe>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          type="submit"
          color="primary"
          variant="contained"
          id="submit-trade-button"
          onClick={() => {
            setSelectedRejectedURL(undefined);
            setSelectedStipulation(undefined);
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default UploadedFilesDialog;
