import { RootState } from "app/rootReducer";
import { getStyleByStatus, statusToChipTooltip, statusToLabel } from "components/Deals/Deals";
import DateFilter from "components/Filters/DateFilter";
import MultiSelectFilter from "components/Filters/MultiSelectFilter";
import TextFilter from "components/Filters/TextFilter";
import React from "react";
import { useSelector } from "react-redux";
import AccessControl from "../../Access/AccessControl";
import { deleteDeal } from "../../Deals/deleteDealSlice";
import { getDealList } from "../../Deals/listDealSlice";
import { recoverDeal } from "../../Deals/recoverDealSlice";
import { DealData, DealStatus } from "../../Deals/types";
import Table, { CellValue, Column, Entry } from "../../Table";
export const projections = {
  "data.info.status": 1,
  "data.info.vehicle.unitType": 1,
  "data.info.dealDates": 1,
  _id: 1,
  createdAt: 1,
  creatorId: 1,
  updatedAt: 1,
  deleted: 1
};
const fundedCount = <T extends any>(entities: Entry<T>[], column: Column<T>): string => {
  return `Total Deals: ${entities.length} / Funded: ${
    (entities?.filter((el) => column.getData(el))).length
  }`;
};

export default function DealerActivity(): JSX.Element {
  const dealList = useSelector((state: RootState) => state.listDealSlice["table"]);

  const columns: Column<DealData>[] = [
    {
      getData: (entry): CellValue => (entry?.createdAt ? new Date(entry?.createdAt) : ""),
      label: "Deal Date",
      name: "createdAt",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.createdAt?.show;
      },
      filters: [
        {
          path: ["createdAt"],
          preview: DateFilter,
          name: "createdAt",
          label: "Created At"
        }
      ]
    },
    {
      getData: (entry): CellValue => entry.data?.info?.status,
      label: "Status",
      options: {
        customBodyRender: (x: CellValue, deal): JSX.Element | string => {
          const dealStatus = x as DealStatus;
          return statusToChipTooltip(dealStatus, deal, getStyleByStatus(dealStatus));
        },
        sort: true,
        path: ["data", "info", "status"]
      },
      name: "status",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.status?.show;
      },
      filters: [
        {
          path: ["data", "info", "status"],
          preview: MultiSelectFilter,
          valuesForSelect: Object.values(DealStatus),
          optionLabelForSelect: (status) => statusToLabel(status)
        }
      ]
    },
    {
      getData: (entry): CellValue => entry.data?.info?.vehicle?.unitType ?? "",
      label: "Vehicle Type",
      name: "vehicleType",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.vehicleType?.show;
      },
      filters: [
        {
          path: ["data", "info", "vehicle", "unitType"],
          preview: TextFilter,
          name: "unitType",
          label: "Unit Type",
          caseInsensitive: true,
          partialSearch: true
        }
      ]
    },
    {
      getData: (entry): CellValue =>
        entry.data?.info?.dealDates?.contractDate
          ? new Date(entry.data?.info?.dealDates?.contractDate)
          : "",
      label: "Delivery Date",
      name: "deliveryDate",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.deliveryDate?.show;
      },
      filters: [
        {
          path: ["data", "info", "dealDates", "signedAt"],
          preview: DateFilter,
          name: "deliveryDate",
          label: "Delivery Date"
        }
      ]
    },
    {
      getData: (entry): CellValue =>
        entry.data?.info?.dealDates?.fundedAt
          ? new Date(entry.data?.info?.dealDates?.fundedAt)
          : "",
      label: "Funded Date",
      total: fundedCount,
      name: "fundedAt",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.fundedAt?.show;
      },
      filters: [
        {
          path: ["data", "info", "dealDates", "fundedAt"],
          preview: DateFilter,
          name: "fundedAt",
          label: "Funded Date"
        }
      ]
    },
    {
      getData: (entry): CellValue => entry._id,
      label: "Actions",
      name: "actions",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.actions?.show;
      }
    }
  ];

  return (
    <div style={{ position: "relative" }}>
      <AccessControl requiredPermissions={{ entity: "deal", action: "read" }}>
        <Table
          slice="table"
          tableName="dealer_activities"
          entityName="deal"
          listFunction={getDealList}
          listEntity={dealList}
          deleteEntityFunction={deleteDeal}
          recoverEntityFunction={recoverDeal}
          sort={{ createdAt: "asc" }}
          title={"Dealer Activity"}
          columns={columns}
          isReport={true}
          showTotalFooter={true}
          projection={projections}
        />
      </AccessControl>
    </div>
  );
}
