import {
  PermissionStruct,
  permissionNode,
  multyPermissionNode,
  relationPermissionNode
} from "components/Roles/types";
import { DefaultStipulation } from "./types";

export type DefaultStipulationPermissionsStruct = PermissionStruct<DefaultStipulation>;

export const defaultStipulationPermissionsStruct: DefaultStipulationPermissionsStruct = {
  _id: permissionNode(" id"),
  createdAt: permissionNode("Created at"),
  updatedAt: permissionNode("Updated at"),
  deleted: permissionNode("Deleted"),
  creatorId: permissionNode("Creator Id"),
  approved: permissionNode("Approved"),
  approvedAt: permissionNode("approvedAt"),
  approvedByUserId: permissionNode("approvedByUserId"),
  approvedByUser: relationPermissionNode("Approved by", "wfd_user"),
  availableToRoles: permissionNode("Available to roles"),
  availableToRolesIds: permissionNode("Available to roles Ids"),
  data: {
    info: {
      addOnDealCreated: permissionNode("Add on deal created"),
      canBeUploadedBy: multyPermissionNode("Should be uploaded by"),
      owner: permissionNode("Owner"),
      description: permissionNode("Description"),
      systemName: permissionNode("System Name")
    }
  }
};
