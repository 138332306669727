import React, { useState, useContext, useEffect } from "react";

import {
  Button,
  DialogTitle,
  Dialog,
  Grid,
  DialogContent,
  DialogActions,
  Typography
} from "@material-ui/core";
import { StateAccess } from "utils/models/formGenerator";
import { Deal } from "../types";
import CloseDialogButton from "../../common/CloseDialogButton";

import { useSnackbar } from "notistack";
import PrintedDocumentsPreview from "./PrintedDocumentsPreview";
import EsigningDocuments from "./EsigningDocuments";
import PreviewAndRecognizeFundingDocument from "./PreviewAndRecognizeFundingDocument";
import PreviewAndApprovePrintedDocument from "./PreviewAndApprovePrintedDocument";
import { RootState } from "app/rootReducer";
import { FundingDocument } from "components/FundingDocuments/types";

import { useDispatch, useSelector } from "react-redux";
import { sendApprovedDocuments } from "../Documents/sendApprovedDocumentsSlice";

import { hideLoader, showLoader } from "components/Loader/loaderSlice";

import { PrintedDocument } from "components/PrintedDocuments/types";
import formEditContext from "components/Content/FormEditContext";
import IFrameURLModal from "../Documents/IFrameURLModal";
import { v4 as uuidv4 } from "uuid";

export default function ({
  stateAccess,
  handleClose
}: {
  stateAccess: StateAccess;
  handleClose: () => void;
}) {
  const dispatch = useDispatch();
  const deal = stateAccess.get([]) as Deal;
  const [requestId] = useState(uuidv4());

  const [fundingDocumentForPreview, setFundingDocumentForPreview] = useState<
    FundingDocument | undefined
  >();
  const [printedDocumentForPreview, setPrintedDocumentForPreview] = useState<
    PrintedDocument | undefined
  >();
  const [allDocumentsAreApproved, setAllDocumentsAreApproved] = useState(false);

  const [previewType, setPreviewType] = useState<
    undefined | "printed_document" | "funding_document" | "approved_document"
  >();
  const fundingDocuments =
    useSelector((state: RootState) => state.listFundingDocumentSlice[deal._id] || []).entities ??
    [];
  const fundingDocument = fundingDocuments?.find((x) => x._id === fundingDocumentForPreview?._id);
  const eSignings = deal?.data?.oneSpanSignings ?? [];

  const { edited: dealEdited } = useContext(formEditContext);
  const handlePreviewApprovedDocuments = () => {
    if (!dealEdited) {
      dispatch(showLoader());
      dispatch(
        sendApprovedDocuments({
          requestId,
          _id: deal._id
        })
      );
    } else {
      alert("Please save the deal before generating a pack!");
    }
  };

  const sendApprovedDocumentsSlice = useSelector(
    (state: RootState) => state.sendApprovedDocumentsSlice[requestId]
  );

  const { enqueueSnackbar } = useSnackbar();

  const [lenderPack, setLenderPack] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (
      sendApprovedDocumentsSlice !== undefined &&
      sendApprovedDocumentsSlice.status !== undefined
    ) {
      switch (sendApprovedDocumentsSlice.status) {
        case "error":
          dispatch(hideLoader());
          enqueueSnackbar(`Error sending approved documents to lender.`, {
            variant: "error"
          });

          break;
        case "success":
          dispatch(hideLoader());

          setLenderPack(sendApprovedDocumentsSlice.data.message);
          enqueueSnackbar(`Successfuly sent approved docs to lender.`, {
            variant: "success"
          });
          break;
      }
    }
  }, [sendApprovedDocumentsSlice?.status]);
  return (
    <>
      {lenderPack && (
        <IFrameURLModal url={lenderPack} handleClose={() => setLenderPack(undefined)} />
      )}
      <Dialog
        fullWidth
        maxWidth={"xl"}
        id="confirm-delete-dialog"
        open={true}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <CloseDialogButton closeFunction={handleClose} />
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            spacing={2}
            style={{ minHeight: 800, maxHeight: 800, position: "relative" }}
          >
            <Grid item xs={6}>
              {(eSignings?.length || 0) > 0 && eSignings && (
                <EsigningDocuments deal={deal} eSignings={eSignings} />
              )}
              <PrintedDocumentsPreview
                deal={deal}
                setFundingDocumentForPreview={(x) => {
                  setPreviewType("funding_document");
                  setPrintedDocumentForPreview(undefined);
                  setFundingDocumentForPreview(x);
                  setAllDocumentsAreApproved(false);
                }}
                printedDocumentForPreview={printedDocumentForPreview}
                setPrintedDocumentForPreview={(x) => {
                  console.log("printed_document", x);
                  setPreviewType("printed_document");
                  setPrintedDocumentForPreview(x);
                  setAllDocumentsAreApproved(false);
                }}
              />
              <Button
                variant="contained"
                color="primary"
                style={{ marginTop: 20 }}
                onClick={handlePreviewApprovedDocuments}
                disabled={fundingDocuments.length === 0}
              >
                Generate pack for lender
              </Button>
            </Grid>
            <Grid item xs={6}>
              {previewType === "funding_document" && (
                <PreviewAndRecognizeFundingDocument fundingDocument={fundingDocument} deal={deal} />
              )}
              {previewType === "printed_document" && !allDocumentsAreApproved && (
                <PreviewAndApprovePrintedDocument
                  printedDocument={printedDocumentForPreview}
                  setPrintedDocument={setPrintedDocumentForPreview}
                  setAllDocumentsAreApproved={setAllDocumentsAreApproved}
                  deal={deal}
                />
              )}
              {allDocumentsAreApproved && (
                <div
                  style={{
                    height: "100%",
                    backgroundColor: "rgb(67, 160, 71)",
                    display: "flex",
                    alignItems: "center"
                  }}
                >
                  <Typography style={{ width: "100%", textAlign: "center" }} variant="h5">
                    All documents are approved
                  </Typography>
                </div>
              )}
              {!previewType && !allDocumentsAreApproved && (
                <div
                  style={{
                    height: "100%",
                    backgroundColor: "#dedede",
                    display: "flex",
                    alignItems: "center"
                  }}
                >
                  <Typography style={{ width: "100%", textAlign: "center" }} variant="h5">
                    Select document for preview
                  </Typography>
                </div>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained" color="primary" id="cancel">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
