import { RootState } from "app/rootReducer";
import TextFilter from "components/Filters/TextFilter";
import React from "react";
import { useSelector } from "react-redux";
import AccessControl from "../Access/AccessControl";
import Table, { CellValue, Column } from "../Table";
import { deleteRole } from "./deleteRoleSlice";
import { getRoleList } from "./listRoleSlice";
import { recoverRole } from "./recoverRoleSlice";
import { FormState } from "./types";
export const projections = {
  "data.info.name": 1,
  _id: 1,
  createdAt: 1,
  updatedAt: 1,
  creatorId: 1,
  deleted: 1
};
export default function Users(): JSX.Element {
  const roleList = useSelector((state: RootState) => state.listRoleSlice["table"]);

  const columns: Column<FormState>[] = [
    {
      label: "Role",
      getData: (entry): CellValue => entry.data.info.name,
      name: "name",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.name?.show;
      },
      filters: [
        {
          path: ["data", "info", "name"],
          preview: TextFilter,
          caseInsensitive: true,
          partialSearch: true
        }
      ]
    },
    {
      label: "Actions",
      getData: (entry): CellValue => entry._id,
      name: "actions",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.actions?.show;
      }
    }
  ];

  return (
    <div style={{ position: "relative" }}>
      <AccessControl requiredPermissions={{ entity: "role", action: "read" }}>
        <Table
          slice="table"
          tableName="roles"
          entityName="role"
          listFunction={getRoleList}
          listEntity={roleList}
          deleteEntityFunction={deleteRole}
          recoverEntityFunction={recoverRole}
          title={"Roles"}
          columns={columns}
          projection={projections}
        />
      </AccessControl>
    </div>
  );
}
