import {
  Table,
  Grid,
  Paper,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  TableContainer,
  CircularProgress,
  TableSortLabel
} from "@material-ui/core";
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import Autocomplete from "@material-ui/lab/Autocomplete";

import formatDate from "utils/formatDate";
import { RootState } from "app/rootReducer";
import { HttpQuery } from "Chat/types/http";
import {
  getDealershipList,
  removeDealershipList
} from "components/Dealerships/listDealershipSlice";
import { Dealership } from "components/Dealerships/types";
import { useDealershipUsersForReps } from "Chat/hooks/useDealershipUsers";
import DateFilter from "components/LenderDecisions/LenderDecisionsStatistics/DateFilterLenderDecisions";
import { DateState } from "components/LenderDecisions/LenderDecisionsStatistics/DateFilterLenderDecisions";
import { getFirstAndLastDateOfCurrentMonth } from "utils/functions";
import { GroupedDealership, MatchedUser } from "types/dealeship_users";
import { useStickyState } from "index";

const useStyles = makeStyles({
  table: {
    minWidth: 650
  },
  root: {
    display: "flex",
    gap: 5
  },
  header: {
    backgroundColor: "#f5f5f5",
    fontWeight: "bold"
  },
  sortLabel: {
    '&:hover:not([class*="active"])': {
      "& svg": {
        opacity: "0 !important"
      }
    }
  }
});

export default function RepresentativeDashboard() {
  const userListId = "representativeDealershipUsers";

  const classes = useStyles();
  const dispatch = useDispatch();
  const currentUser = useSelector((state: RootState) => state?.authSlice);
  const [sortColumn, setSortColumn] = useState<string | null>(null);
  const [sortOrder, setSortOrder] = useState<"asc" | "desc" | null>(null);
  const [dealerships, setDealerships] = useState<Dealership[]>([]);
  const dealershipList = useSelector((state: RootState) => state.listDealershipSlice[userListId]);
  const [dateFilter, setDateFilter] = useStickyState<DateState>(
    {
      from: new Date(getFirstAndLastDateOfCurrentMonth()?.from),
      to: new Date()
    },
    "representativeDashboardDateFilter",
    (data: { from: string; to: string }) => {
      return {
        from: new Date(data.from),
        to: new Date(data.to)
      };
    }
  );
  const currentUserRolesIds = currentUser?.user?.databaseData?.data?.rolesIds?.map(
    (roleId) => roleId
  );
  const isUserAdmin = currentUserRolesIds?.includes("5f17e21d46a2b90e45bb5a90");
  const [state, setState] = useState<{
    dealershipIds: string[];
    allDealerships: boolean;
  }>({
    allDealerships: true,
    dealershipIds: []
  });

  const { data, isFetching } = useDealershipUsersForReps({
    dealershipIds: state.dealershipIds ?? [],
    from: dateFilter?.from
      ? `${dateFilter?.from?.getFullYear?.()}-${
          dateFilter?.from?.getMonth?.() + 1
        }-${dateFilter?.from?.getDate?.()}`
      : undefined,
    to: dateFilter?.to
      ? `${dateFilter?.to?.getFullYear?.()}-${
          dateFilter?.to?.getMonth?.() + 1
        }-${dateFilter?.to?.getDate?.()}`
      : undefined
  } as HttpQuery);

  React.useEffect(() => {
    if (!dealershipList)
      dispatch(
        getDealershipList(userListId, {
          ...(!isUserAdmin
            ? { query: { "data.representativeId": currentUser?.user?.databaseData?._id } }
            : {}),
          options: {
            projection: {
              _id: 1,

              "data.info.name": 1,
              "data.info.displayName": 1,
              insensitiveName: {
                $toLower: "$data.info.name"
              }
            },
            pagination: false,
            sort: { insensitiveName: "asc" }
          }
        })
      );
    return () => {
      dispatch(removeDealershipList(userListId));
      return;
    };
  }, []);
  const handleSort = (column: string) => {
    if (sortColumn === column) {
      if (sortOrder === "asc") {
        setSortOrder("desc");
      } else if (sortOrder === "desc") {
        setSortOrder(null);
        setSortColumn(null);
      }
    } else {
      // Sort by new column, default to ascending order
      setSortColumn(column);
      setSortOrder("asc");
    }
  };
  const getSortingValue = (
    dealership: Dealership | null,
    user: MatchedUser & {
      eventsWithoutDealer: { webApp: Record<string, number>; mobileApp: Record<string, number> };
    },
    column: string
  ): number | string => {
    const webApp = user.mixpanelData?.webApp;
    const mobileApp = user.mixpanelData?.mobileApp;
    const blackBird = user?.blackbirdData;
    const webAppEventsWithoutDealer = user.eventsWithoutDealer?.webApp;
    const mobileAppEventsWithoutDealer = user.eventsWithoutDealer?.mobileApp;
    switch (column) {
      case "Dealership Name":
        return dealership?.data?.info?.displayName?.toLowerCase() || "";
      case "Email":
        return user?.firebaseUser?.email?.toLowerCase() || "";
      case "Created At":
        return new Date(user?.firebaseUser?.metadata?.creationTime as string).getTime() || 0;
      case "Last Login":
        return new Date(user?.firebaseUser?.metadata?.lastSignInTime as string).getTime() || 0;
      case "All Events Count (Web)":
        return webApp?.totalEvents || 0;
      case "Deal Created (Web)":
        return webApp?.eventOccurences["Deal created"] || 0;
      case "AI Parser Used (Web)":
        return webApp?.eventOccurences["AI Parser used"] || 0;
      case "Document uploaded before signing (Web)":
        return webApp?.eventOccurences["Document uploaded before signing"] || 0;
      case "Login Count (Web)":
        return (
          (webApp?.eventOccurences?.["Login"] || 0) + (webAppEventsWithoutDealer?.["Login"] || 0) ||
          0
        );
      case "Last Event Name (Web)":
        return webApp?.events?.sort((a, b) => b.time - a.time)?.[0]?.eventName || "";

      case "Last Event Time (Web)":
        return webApp?.events?.sort((a, b) => b.time - a.time)?.[0]?.time || 0;

      case "All Events Count (Mobile)":
        return mobileApp?.totalEvents || 0;
      case "Deal Created (Mobile)":
        return mobileApp?.eventOccurences["Deal created"] || 0;
      case "Document uploaded before signing (Mobile)":
        return mobileApp?.eventOccurences["Document uploaded before signing"] || 0;
      case "Login Count (Mobile)":
        return (
          (mobileApp?.eventOccurences["Login"] || 0) +
            (mobileAppEventsWithoutDealer?.["Login"] || 0) || 0
        );
      case "Last Event Name (Mobile)":
        return mobileApp?.events?.sort((a, b) => b.time - a.time)?.[0]?.eventName || "";
      case "Last Event Time (Mobile)":
        return mobileApp?.events?.sort((a, b) => b.time - a.time)?.[0]?.time || 0;
      case "Deals from widget (BB)":
        return blackBird?.online_application || 0;
      case "Deals created by managers (BB)":
        return blackBird?.web_finance_direct || 0;
      case "AI Parser (BB)":
        return blackBird?.["AI-Parser"] || 0;
      default:
        return 0;
    }
  };
  const sortData = (
    groupedDealerships: GroupedDealership[],
    eventsWithoutDealerships: Record<
      string,
      Record<
        "webApp" | "mobileApp",
        {
          events: string[];
          totalEvents: number;
          eventOccurences: Record<string, number>;
        }
      >
    >,
    column: string | null,
    order: "asc" | "desc" | null
  ) => {
    // Flatten users across dealerships
    const flattenedUsers = groupedDealerships.flatMap((group) =>
      group.users.map((user) => ({
        dealership: group.dealership,
        user: {
          ...user,
          eventsWithoutDealer: {
            webApp:
              eventsWithoutDealerships?.[user.firebaseUser?.email as string]?.webApp
                ?.eventOccurences,
            mobileApp:
              eventsWithoutDealerships?.[user.firebaseUser?.email as string]?.mobileApp
                ?.eventOccurences
          }
        }
      }))
    );
    if (!column || !order) return flattenedUsers;

    // Sort flattened users
    return flattenedUsers.sort((a, b) => {
      const valA = getSortingValue(a.dealership, a.user, column);
      const valB = getSortingValue(b.dealership, b.user, column);

      if (valA < valB) return order === "asc" ? -1 : 1;
      if (valA > valB) return order === "asc" ? 1 : -1;
      return 0; // Stable fallback
    });
  };

  return (
    <Grid container className={classes.root}>
      <Grid item container xs={12} spacing={2}>
        <Grid item md={3} sm={6}>
          <Autocomplete
            style={{ zIndex: 6 }}
            value={
              state.allDealerships
                ? ((["All dealerships"] as unknown) as Dealership[])
                : dealerships || null
            }
            getOptionLabel={(dealership) => {
              if (((dealership as unknown) as string) === "All dealerships") {
                return (dealership as unknown) as string;
              }
              return dealership?.data?.info?.displayName;
            }}
            multiple
            getOptionSelected={(x, y) => x?._id === y?._id && x === y}
            options={[
              ("All dealerships" as unknown) as Dealership,
              ...(dealershipList?.entities ?? [])
            ]}
            onChange={(event, newValue) => {
              if (newValue?.length === 0) {
                setState((prevState) => ({
                  ...prevState,
                  dealershipIds: [],
                  allDealerships: true
                }));
                setDealerships((["All dealerships"] as unknown) as Dealership[]);
              } else {
                setDealerships(
                  newValue.filter((x) => x !== (("All dealerships" as unknown) as Dealership))
                );
                setState((prevState) => ({
                  ...prevState,
                  dealershipIds: newValue
                    .filter((x) => x !== (("All dealerships" as unknown) as Dealership))
                    .map((x) => x._id),
                  allDealerships: false
                }));
              }
            }}
            loading={!Array.isArray(dealershipList?.entities)}
            openOnFocus
            id="rep-dashboard-dealerships-select"
            renderInput={(params) => (
              <TextField
                {...params}
                InputLabelProps={{ shrink: true }}
                InputProps={{ ...params.InputProps }}
                label={"Dealership"}
                variant="filled"
                size="small"
              />
            )}
          />
        </Grid>
        <Grid item md={4} sm={6}>
          <Typography style={{ fontSize: "18px", fontWeight: "bold", lineHeight: "10px" }}>
            Period from/to
          </Typography>
          <DateFilter
            maxDate={new Date()}
            filter={dateFilter}
            setFilter={setDateFilter}
            justifyEnd={false}
          />
        </Grid>
      </Grid>
      <Paper style={{ width: "100%", tableLayout: "fixed", display: "table" }}>
        <TableContainer style={{ overflowX: "scroll", overflowY: "auto", display: "block" }}>
          <Table className={classes.table} aria-label="dealership users table">
            <TableHead>
              <TableRow>
                <TableCell colSpan={4} className={classes.header}></TableCell>
                <TableCell colSpan={7} className={classes.header}>
                  Dealer Web App
                </TableCell>
                <TableCell colSpan={6} className={classes.header}>
                  Dealer Mobile App
                </TableCell>
                <TableCell colSpan={3} className={classes.header}>
                  Blackbird
                </TableCell>
              </TableRow>
              <TableRow>
                {/* common */}
                <TableCell className={classes.header}>
                  <TableSortLabel
                    className={classes.sortLabel}
                    active={sortColumn === "Dealership Name"}
                    direction={sortOrder ?? undefined}
                    onClick={() => handleSort("Dealership Name")}
                  >
                    Dealership Name
                  </TableSortLabel>
                </TableCell>
                <TableCell className={classes.header}>
                  <TableSortLabel
                    className={classes.sortLabel}
                    active={sortColumn === "Email"}
                    direction={sortOrder ?? undefined}
                    onClick={() => handleSort("Email")}
                  >
                    Email
                  </TableSortLabel>
                </TableCell>
                <TableCell className={classes.header}>
                  <TableSortLabel
                    className={classes.sortLabel}
                    active={sortColumn === "Created At"}
                    direction={sortOrder ?? undefined}
                    onClick={() => handleSort("Created At")}
                  >
                    Created At
                  </TableSortLabel>
                </TableCell>
                <TableCell className={classes.header}>
                  <TableSortLabel
                    className={classes.sortLabel}
                    active={sortColumn === "Last Login"}
                    direction={sortOrder ?? undefined}
                    onClick={() => handleSort("Last Login")}
                  >
                    Last Login
                  </TableSortLabel>
                </TableCell>
                {/* dealer app */}
                <TableCell className={classes.header}>
                  <TableSortLabel
                    className={classes.sortLabel}
                    active={sortColumn === "All Events Count (Web)"}
                    direction={sortOrder ?? undefined}
                    onClick={() => handleSort("All Events Count (Web)")}
                  >
                    All Events Count
                  </TableSortLabel>
                </TableCell>
                <TableCell className={classes.header}>
                  <TableSortLabel
                    className={classes.sortLabel}
                    active={sortColumn === "Deal Created (Web)"}
                    direction={sortOrder ?? undefined}
                    onClick={() => handleSort("Deal Created (Web)")}
                  >
                    Deal Created
                  </TableSortLabel>
                </TableCell>
                <TableCell className={classes.header}>
                  <TableSortLabel
                    className={classes.sortLabel}
                    active={sortColumn === "AI Parser Used (Web)"}
                    direction={sortOrder ?? undefined}
                    onClick={() => handleSort("AI Parser Used (Web)")}
                  >
                    AI Parser Used
                  </TableSortLabel>
                </TableCell>
                <TableCell className={classes.header}>
                  <TableSortLabel
                    className={classes.sortLabel}
                    active={sortColumn === "Document uploaded before signing (Web)"}
                    direction={sortOrder ?? undefined}
                    onClick={() => handleSort("Document uploaded before signing (Web)")}
                  >
                    Documents Uploaded
                  </TableSortLabel>
                </TableCell>
                <TableCell className={classes.header}>
                  <TableSortLabel
                    className={classes.sortLabel}
                    active={sortColumn === "Login Count (Web)"}
                    direction={sortOrder ?? undefined}
                    onClick={() => handleSort("Login Count (Web)")}
                  >
                    Login count
                  </TableSortLabel>
                </TableCell>
                <TableCell className={classes.header}>
                  <TableSortLabel
                    className={classes.sortLabel}
                    active={sortColumn === "Last Event Name (Web)"}
                    direction={sortOrder ?? undefined}
                    onClick={() => handleSort("Last Event Name (Web)")}
                  >
                    Last event name
                  </TableSortLabel>
                </TableCell>
                <TableCell className={classes.header}>
                  <TableSortLabel
                    className={classes.sortLabel}
                    active={sortColumn === "Last Event Time (Web)"}
                    direction={sortOrder ?? undefined}
                    onClick={() => handleSort("Last Event Time (Web)")}
                  >
                    Last event time
                  </TableSortLabel>
                </TableCell>
                {/* mobile app */}
                <TableCell className={classes.header}>
                  <TableSortLabel
                    className={classes.sortLabel}
                    active={sortColumn === "All Events Count (Mobile)"}
                    direction={sortOrder ?? undefined}
                    onClick={() => handleSort("All Events Count (Mobile)")}
                  >
                    All Events Count
                  </TableSortLabel>
                </TableCell>
                <TableCell className={classes.header}>
                  <TableSortLabel
                    className={classes.sortLabel}
                    active={sortColumn === "Deal Created (Mobile)"}
                    direction={sortOrder ?? undefined}
                    onClick={() => handleSort("Deal Created (Mobile)")}
                  >
                    Deal Created
                  </TableSortLabel>
                </TableCell>
                <TableCell className={classes.header}>
                  <TableSortLabel
                    className={classes.sortLabel}
                    active={sortColumn === "Document uploaded before signing (Mobile)"}
                    direction={sortOrder ?? undefined}
                    onClick={() => handleSort("Document uploaded before signing (Mobile)")}
                  >
                    Documents Uploaded
                  </TableSortLabel>
                </TableCell>
                <TableCell className={classes.header}>
                  <TableSortLabel
                    className={classes.sortLabel}
                    active={sortColumn === "Login Count (Mobile)"}
                    direction={sortOrder ?? undefined}
                    onClick={() => handleSort("Login Count (Mobile)")}
                  >
                    Login count
                  </TableSortLabel>
                </TableCell>
                <TableCell className={classes.header}>
                  <TableSortLabel
                    className={classes.sortLabel}
                    active={sortColumn === "Last Event Name (Mobile)"}
                    direction={sortOrder ?? undefined}
                    onClick={() => handleSort("Last Event Name (Mobile)")}
                  >
                    Last event name
                  </TableSortLabel>
                </TableCell>
                <TableCell className={classes.header}>
                  <TableSortLabel
                    className={classes.sortLabel}
                    active={sortColumn === "Last Event Time (Mobile)"}
                    direction={sortOrder ?? undefined}
                    onClick={() => handleSort("Last Event Time (Mobile)")}
                  >
                    Last event time
                  </TableSortLabel>
                </TableCell>
                <TableCell className={classes.header}>
                  <TableSortLabel
                    className={classes.sortLabel}
                    active={sortColumn === "Deals from widget (BB)"}
                    direction={sortOrder ?? undefined}
                    onClick={() => handleSort("Deals from widget (BB)")}
                  >
                    Deals From Widget
                  </TableSortLabel>
                </TableCell>
                <TableCell className={classes.header}>
                  <TableSortLabel
                    className={classes.sortLabel}
                    active={sortColumn === "Deals created by managers (BB)"}
                    direction={sortOrder ?? undefined}
                    onClick={() => handleSort("Deals created by managers (BB)")}
                  >
                    Deals Created By Manager
                  </TableSortLabel>
                </TableCell>
                <TableCell className={classes.header}>
                  <TableSortLabel
                    className={classes.sortLabel}
                    active={sortColumn === "AI Parser (BB)"}
                    direction={sortOrder ?? undefined}
                    onClick={() => handleSort("AI Parser (BB)")}
                  >
                    AI Parser
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data && data.groupedDealerships?.length ? (
                sortData(
                  data.groupedDealerships,
                  data?.eventsWithoutDealership,
                  sortColumn,
                  sortOrder
                )?.map((userData, index) => {
                  const latestWebEvent = userData?.user?.mixpanelData?.webApp?.events?.sort(
                    (a, b) => b.time - a.time
                  )?.[0];
                  const latestMobileEvent = userData?.user?.mixpanelData?.mobileApp?.events?.sort(
                    (a, b) => b.time - a.time
                  )?.[0];
                  const eventOccurencesWeb = userData?.user?.mixpanelData?.webApp?.eventOccurences;
                  const eventOccurencesMobile =
                    userData?.user?.mixpanelData?.mobileApp?.eventOccurences;
                  const eventOccurencesWithoutDealershipWeb =
                    data?.eventsWithoutDealership?.[userData?.user.firebaseUser?.email as string]
                      ?.webApp?.eventOccurences;
                  const eventOccurencesWithoutDealershipMobile =
                    data?.eventsWithoutDealership?.[userData?.user.firebaseUser?.email as string]
                      ?.mobileApp?.eventOccurences;
                  const blackbirdData = userData?.user?.blackbirdData;
                  return (
                    <TableRow key={`${userData?.dealership?._id}-${index}`}>
                      <TableCell>
                        {userData?.dealership?.data.info.displayName || "Unknown Dealership"}
                      </TableCell>
                      <TableCell>
                        {`${userData?.user.firebaseUser?.email}${
                          userData?.user.firebaseUser?.firebaseMissing
                            ? "(Deleted/disabled account)"
                            : ""
                        }`}
                      </TableCell>

                      <TableCell>
                        {formatDate(userData?.user.firebaseUser?.metadata?.creationTime)}
                      </TableCell>
                      <TableCell>
                        {formatDate(userData?.user.firebaseUser?.metadata?.lastSignInTime)}
                      </TableCell>
                      {/* dealer app */}
                      <TableCell>
                        {userData?.user?.mixpanelData?.webApp?.totalEvents ?? 0}
                      </TableCell>
                      <TableCell>{eventOccurencesWeb?.["Deal created"] ?? 0}</TableCell>
                      <TableCell>{eventOccurencesWeb?.["AI Parser used"] ?? 0}</TableCell>
                      <TableCell>
                        {eventOccurencesWeb?.["Document uploaded before signing"] ?? 0}
                      </TableCell>
                      <TableCell>
                        {(eventOccurencesWithoutDealershipWeb?.["Login"] ?? 0) +
                          (eventOccurencesWeb?.["Login"] ?? 0)}
                      </TableCell>

                      <TableCell>
                        {latestWebEvent?.eventName?.includes("Visited")
                          ? "Page visit"
                          : latestWebEvent?.eventName ?? "-"}
                      </TableCell>
                      <TableCell>
                        {typeof latestWebEvent?.time === "number"
                          ? formatDate(new Date(latestWebEvent?.time * 1000))
                          : "-"}
                      </TableCell>
                      {/* mobile app */}
                      <TableCell>
                        {userData?.user?.mixpanelData?.mobileApp?.totalEvents ?? 0}
                      </TableCell>
                      <TableCell>{eventOccurencesMobile?.["Deal created"] ?? 0}</TableCell>
                      <TableCell>
                        {eventOccurencesMobile?.["Document uploaded before signing"] ?? 0}
                      </TableCell>
                      <TableCell>
                        {(eventOccurencesWithoutDealershipMobile?.["Login"] ?? 0) +
                          (eventOccurencesMobile?.["Login"] ?? 0)}
                      </TableCell>
                      <TableCell>
                        {latestMobileEvent?.eventName?.includes("Visited")
                          ? "Page visit"
                          : latestMobileEvent?.eventName ?? "-"}
                      </TableCell>
                      <TableCell>
                        {typeof latestMobileEvent?.time === "number"
                          ? formatDate(new Date(latestMobileEvent?.time * 1000))
                          : "-"}
                      </TableCell>
                      <TableCell>{blackbirdData?.online_application ?? 0}</TableCell>
                      <TableCell>{blackbirdData?.web_finance_direct ?? 0}</TableCell>
                      <TableCell>{blackbirdData?.["AI-Parser"] ?? 0}</TableCell>
                    </TableRow>
                  );
                })
              ) : isFetching ? (
                <TableRow key={"loading-row"}>
                  <TableCell
                    colSpan={20}
                    style={{
                      textAlign: "center",
                      padding: "10px"
                    }}
                    key={"loading-row"}
                  >
                    <CircularProgress style={{ margin: "auto" }} size="1.8rem" />
                  </TableCell>
                </TableRow>
              ) : (
                <TableRow key={"no-results"}>
                  <TableCell key="no-results" colSpan={20}>
                    <Typography
                      style={{ fontStyle: "italic", margin: "auto", textAlign: "center" }}
                    >
                      No matching records found
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Grid>
  );
}
