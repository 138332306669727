import { Grid, Paper } from "@material-ui/core";
import { Deal, DealStatus } from "components/Deals/types";
import React from "react";
import { formatNumberAsCurrency } from "utils/functions";
import { Dealership } from "../types";

import Big from "big.js";
import { Tier } from "components/DealershipPrograms/types";
import { resolveTier } from "components/Deals/Calculations";
import { RestrictedWarnings } from "../DealershipPreview";
import styles from "./DealershipShowPage.module.css";
import { getDealershipProgram } from "./PerformaceStatus";
const YTDProfit = ({ dealership, year }: { dealership: Dealership; year: number }) => {
  const statistics = dealership.data.statistics;
  const currentYearProfit =
    statistics?.reduce((acc, stat) => {
      if (stat.year === year) {
        return acc + (stat?.dealershipProfit || 0);
      }
      return acc;
    }, 0) || 0;

  const previousYearProfit =
    statistics?.reduce((acc, stat) => {
      if (stat.year === year - 1) {
        return acc + (stat.dealershipProfit || 0);
      }
      return acc;
    }, 0) || 0;
  const profitChangeInpercentages =
    ((currentYearProfit - previousYearProfit) / previousYearProfit) * 100;

  return (
    <Grid md={4} sm={6} xs={12}>
      <Paper
        elevation={2}
        style={{
          margin: "0px 10px",
          padding: "10px",
          minHeight: "calc(100% - 20px)",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between"
        }}
      >
        <div className={styles.statisticTitle}>YTD Profit</div>
        <div className={styles.statisticNumber}>
          {formatNumberAsCurrency(currentYearProfit, "$")}{" "}
          <span
            style={{
              color: profitChangeInpercentages > 0 ? "green" : "red"
            }}
          >
            ({formatNumberAsCurrency(profitChangeInpercentages)}%)
          </span>
        </div>
        <div className={styles.textMuted}>* for {year}</div>
      </Paper>
    </Grid>
  );
};
const MTDProfit = ({
  dealership,
  year,
  month
}: {
  dealership: Dealership;
  year: number;
  month: number;
}) => {
  const statistics = dealership?.data?.statistics ?? [];

  const currentYearMonthProfit =
    statistics?.reduce((acc, stat) => {
      if (stat.year === year && stat.month === month) {
        return acc + stat.dealershipProfit;
      }
      return acc;
    }, 0) || 0;

  const previousMonth = new Date(year, month - 2, 1);

  const previousYearMonthProfit =
    statistics?.reduce((acc, stat) => {
      if (
        stat.year === previousMonth.getFullYear() &&
        stat.month === previousMonth.getMonth() + 1
      ) {
        return acc + stat.dealershipProfit;
      }
      return acc;
    }, 0) || 0;

  const profitChangeInpercentages =
    ((currentYearMonthProfit - previousYearMonthProfit) / previousYearMonthProfit) * 100;
  return (
    <Grid md={4} sm={6} xs={12}>
      <Paper
        elevation={2}
        style={{
          margin: "0px 10px",
          padding: "10px",
          minHeight: "calc(100% - 20px)",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between"
        }}
      >
        <div className={styles.statisticTitle}>MTD Profit</div>
        <div className={styles.statisticNumber}>
          {formatNumberAsCurrency(currentYearMonthProfit, "$")}{" "}
          <span
            style={{
              color: profitChangeInpercentages > 0 ? "green" : "red"
            }}
          >
            ({formatNumberAsCurrency(profitChangeInpercentages)}%)
          </span>
        </div>
        <div className={styles.textMuted}>
          * for{" "}
          {new Date(year, month - 1, 1).toLocaleString("default", {
            month: "long"
          })}
        </div>
      </Paper>
    </Grid>
  );
};

const DealsCount = ({
  deals,
  year,
  month,
  type
}: {
  deals: Deal[];
  year: number;
  month: number;
  type: "monthly" | "yearly";
}) => {
  const dealsCountByPositiveNegaativeOrInProcess = deals.reduce(
    (acc, deal) => {
      if (
        [DealStatus.Funded, DealStatus.SubmittedForFunding, DealStatus.FundingHeld].includes(
          deal.data.info.status
        )
      ) {
        acc.positive++;
      } else if (
        [
          DealStatus.Cancelled,
          DealStatus.Dead,
          DealStatus.Denied,
          DealStatus.DidNotProcess
        ].includes(deal.data.info.status)
      ) {
        acc.negative++;
      } else {
        acc.inProcess++;
      }
      return acc;
    },
    { positive: 0, negative: 0, inProcess: 0 }
  );
  return (
    <Grid md={4} sm={6} xs={12}>
      <Paper
        elevation={2}
        style={{
          margin: "0px 10px",
          padding: "10px",
          minHeight: "calc(100% - 20px)",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between"
        }}
      >
        <div className={styles.statisticTitle}>Deals submitted count</div>
        <div
          className={styles.statisticNumber}
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "baseline",
            justifyContent: "left"
          }}
        >
          <div
            style={{
              marginRight: 10
            }}
          >
            {deals?.length || 0}
          </div>
          {deals?.length ? (
            <div style={{ whiteSpace: "nowrap", fontSize: "14px" }}>
              (
              {dealsCountByPositiveNegaativeOrInProcess.positive ? (
                <>
                  <span
                    style={{
                      color: "green",
                      fontWeight: "bold",

                      whiteSpace: "nowrap"
                    }}
                  >
                    {dealsCountByPositiveNegaativeOrInProcess.positive} pos.
                  </span>{" "}
                </>
              ) : null}
              {dealsCountByPositiveNegaativeOrInProcess.inProcess ? (
                <>
                  <span
                    style={{
                      color: "orange",
                      fontWeight: "bold",
                      whiteSpace: "nowrap"
                    }}
                  >
                    {dealsCountByPositiveNegaativeOrInProcess.inProcess} in prog.
                  </span>{" "}
                </>
              ) : null}
              {dealsCountByPositiveNegaativeOrInProcess.negative ? (
                <>
                  <span
                    style={{
                      color: "red",
                      fontWeight: "bold",
                      whiteSpace: "nowrap"
                    }}
                  >
                    {dealsCountByPositiveNegaativeOrInProcess.negative} neg.
                  </span>
                </>
              ) : null}
              )
            </div>
          ) : null}
        </div>

        <div className={styles.textMuted}>
          * for{" "}
          {type === "monthly"
            ? new Date(year, month - 1, 1).toLocaleString("default", {
                month: "long"
              })
            : year}
        </div>
      </Paper>
    </Grid>
  );
};
const AverageProfitPerDealForMonth = ({
  dealership,
  year,
  month
}: {
  dealership: Dealership;
  year: number;
  month: number;
}) => {
  const statistics = dealership?.data?.statistics ?? [];

  const currentYearMonthStats = statistics?.find(
    (stat) => stat.year === year && stat.month === month
  );
  const currentAverageProfitPerDeal =
    (currentYearMonthStats?.dealershipProfit || 0) / (currentYearMonthStats?.dealsCount || 0) || 0;
  const previousMonth = new Date(year, month - 2, 1);
  const previousYearMonthStats = statistics?.find(
    (stat) =>
      stat.year === previousMonth.getFullYear() && stat.month === previousMonth.getMonth() + 1
  );
  const previousAverageProfitPerDeal =
    (previousYearMonthStats?.dealershipProfit || 0) / (previousYearMonthStats?.dealsCount || 0) ||
    0;
  const profitChangeInpercentages =
    ((currentAverageProfitPerDeal - previousAverageProfitPerDeal) / previousAverageProfitPerDeal) *
      100 || 0;
  return (
    <Grid md={4} sm={6} xs={12}>
      <Paper
        elevation={2}
        style={{
          margin: "0px 10px",
          padding: "10px",
          minHeight: "calc(100% - 20px)",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between"
        }}
      >
        <div className={styles.statisticTitle}>Average Profit Per Deal</div>
        <div className={styles.statisticNumber}>
          {formatNumberAsCurrency(currentAverageProfitPerDeal || 0, "$")}{" "}
          <span
            style={{
              color: profitChangeInpercentages > 0 ? "green" : "red"
            }}
          >
            ({formatNumberAsCurrency(profitChangeInpercentages || 0)}%)
          </span>
        </div>
        <div className={styles.textMuted}>
          * for{" "}
          {new Date(year, month - 1, 1).toLocaleString("default", {
            month: "long"
          })}
        </div>
      </Paper>
    </Grid>
  );
};
const AverageProfitPerDealForYear = ({
  dealership,
  year
}: {
  dealership: Dealership;
  year: number;
}) => {
  const statistics = dealership?.data?.statistics ?? [];

  const currentYearStats = statistics?.filter((stat) => stat.year === year);
  const previousYearStats = statistics?.filter((stat) => stat.year === year - 1);

  const currentYearProfit =
    currentYearStats.reduce((acc, stat) => {
      return acc + stat.dealershipProfit;
    }, 0) || 0;

  const previousYearProfit =
    previousYearStats?.reduce((acc, stat) => {
      return acc + stat.dealershipProfit;
    }, 0) || 0;

  const currentYearDealsCount =
    currentYearStats.reduce((acc, stat) => {
      return acc + stat.dealsCount;
    }, 0) || 0;

  const previousYearDealsCount =
    previousYearStats.reduce((acc, stat) => {
      return acc + stat.dealsCount;
    }, 0) || 0;

  const currentAverageProfitPerDeal = currentYearProfit / currentYearDealsCount;

  const previousAverageProfitPerDeal = previousYearProfit / previousYearDealsCount;

  const profitChangeInpercentages =
    ((currentAverageProfitPerDeal - previousAverageProfitPerDeal) / previousAverageProfitPerDeal) *
      100 || 0;
  return (
    <Grid md={4} sm={6} xs={12}>
      <Paper
        elevation={2}
        style={{
          margin: "0px 10px",
          padding: "10px",
          minHeight: "calc(100% - 20px)",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between"
        }}
      >
        <div className={styles.statisticTitle}>Average Profit Per Deal</div>
        <div className={styles.statisticNumber}>
          {formatNumberAsCurrency(currentAverageProfitPerDeal || 0, "$")}{" "}
          <span
            style={{
              color: profitChangeInpercentages > 0 ? "green" : "red"
            }}
          >
            ({formatNumberAsCurrency(profitChangeInpercentages || 0)}%)
          </span>
        </div>
        <div className={styles.textMuted}>* for {year}</div>
      </Paper>
    </Grid>
  );
};

const TitleIssueInformation = ({
  dealership,
  year,
  month,
  type
}: {
  dealership: Dealership;
  year: number;
  month: number;
  type: "monthly" | "yearly";
}) => {
  return (
    <Grid xs={12}>
      <Paper
        elevation={2}
        style={{
          margin: "0px 10px",
          padding: "10px",
          minHeight: "calc(100% - 20px)",
          display: "flex",

          flexDirection: "column",
          justifyContent: "space-between"
        }}
      >
        <div className={styles.statisticTitle}>Title Issue Information</div>
        <div>
          {!dealership.data.info.titleManagement?.isRestricted && (
            <div>
              <div
                style={{ color: "green", fontWeight: "bold", fontSize: "20px", margin: "20px 0px" }}
              >
                Not Restricted
              </div>
            </div>
          )}

          {dealership.data.info.titleManagement && (
            <RestrictedWarnings titleManagement={dealership.data.info.titleManagement} view="f&i" />
          )}
        </div>
      </Paper>
    </Grid>
  );
};

const AverageApplicantScore = ({
  deals,
  year,
  type,
  month
}: {
  deals: Deal[];
  type: "monthly" | "yearly";
  year: number;
  month: number;
}) => {
  const creditScoreApplicantDeals = deals.filter(
    (d) => d.data.info.extractedCreditScores?.applicant.score
  );
  const creditScoreCoApplicantDeals = deals.filter(
    (d) => d.data.info.extractedCreditScores?.coApplicant.score
  );
  const averageApplicantScore =
    creditScoreApplicantDeals.reduce(
      (acc, d) => acc + (d.data?.info?.extractedCreditScores?.applicant?.score || 0),
      0
    ) / creditScoreApplicantDeals.length || 0;
  const averageCoApplicantScore =
    deals
      .filter((d) => d.data.info.extractedCreditScores?.coApplicant)
      .reduce((acc, d) => acc + (d.data?.info?.extractedCreditScores?.coApplicant?.score || 0), 0) /
      creditScoreCoApplicantDeals.length || 0;
  return (
    <Grid md={4} sm={6} xs={12}>
      <Paper
        elevation={2}
        style={{
          margin: "0px 10px",
          padding: "10px",
          minHeight: "calc(100% - 20px)",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between"
        }}
      >
        <div className={styles.statisticTitle}>Average Applicants Score</div>
        <div className={styles.statisticNumber}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "stretch",

              textAlign: "center"
            }}
          >
            <div>
              <div
                style={{
                  fontSize: "14px",
                  fontWeight: "bold",
                  marginBottom: 10
                }}
              >
                Applicant
              </div>
              {parseInt(averageApplicantScore.toFixed(2))}
            </div>
            <div
              style={{
                width: 2,
                backgroundColor: "rgb(200, 200, 200)",
                margin: "0px 10px"
              }}
            ></div>
            <div>
              <div
                style={{
                  fontSize: "14px",
                  fontWeight: "bold",
                  marginBottom: 10
                }}
              >
                Co-Applicant
              </div>
              {parseInt(averageCoApplicantScore.toFixed())}
            </div>
          </div>
        </div>
        <div className={styles.textMuted}>
          * for{" "}
          {type === "monthly"
            ? new Date(year, month - 1, 1).toLocaleString("default", {
                month: "long"
              })
            : year}
        </div>
      </Paper>
    </Grid>
  );
};

const ProductsSold = ({
  deals,
  year,
  month,
  type
}: {
  deals: Deal[];
  year: number;
  month: number;
  type: "monthly" | "yearly";
}) => {
  const positiveDeals = deals.filter((deal) =>
    [DealStatus.Funded, DealStatus.SubmittedForFunding, DealStatus.FundingHeld].includes(
      deal.data?.info.status
    )
  );
  const gapSoldCount = positiveDeals.reduce((acc, deal) => {
    if (deal.data.info.aftermarketOptions.totalGAPSellPrice) {
      return acc + 1;
    }
    return acc;
  }, 0);

  const warrantySoldCount = positiveDeals.reduce((acc, deal) => {
    if (deal.data.info.aftermarketOptions.totalServiceWarrantySellPrice) {
      return acc + 1;
    }
    return acc;
  }, 0);

  return (
    <Grid md={4} sm={6} xs={12}>
      <Paper
        elevation={2}
        style={{
          margin: "0px 10px",
          padding: "10px",
          minHeight: "calc(100% - 20px)",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between"
        }}
      >
        <div className={styles.statisticTitle}>Products Sold</div>
        <div className={styles.statisticNumber}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "stretch",

              textAlign: "center"
            }}
          >
            <div>
              <div
                style={{
                  fontSize: "18px",
                  fontWeight: "bold",
                  marginBottom: 10
                }}
              >
                GAP
              </div>
              {gapSoldCount}
            </div>
            <div
              style={{
                width: 2,
                backgroundColor: "rgb(200, 200, 200)",
                margin: "0px 10px"
              }}
            ></div>
            <div>
              <div
                style={{
                  fontSize: "18px",
                  fontWeight: "bold",
                  marginBottom: 10
                }}
              >
                Warranty
              </div>
              {warrantySoldCount}
            </div>
          </div>
        </div>
        <div className={styles.textMuted}>
          * for{" "}
          {type === "monthly"
            ? new Date(year, month - 1, 1).toLocaleString("default", {
                month: "long"
              })
            : year}
        </div>
      </Paper>
    </Grid>
  );
};

export default ({
  dealership,
  year,
  month,
  deals,
  type
}: {
  dealership: Dealership;
  year: number;
  month: number;
  deals: Deal[];
  type: "monthly" | "yearly";
}) => {
  return (
    <Grid
      spacing={2}
      style={{
        gap: "10px 0px",
        margin: 0,
        marginLeft: -8
      }}
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="stretch"
    >
      <YTDProfit dealership={dealership} year={year} />
      {type === "monthly" && <MTDProfit dealership={dealership} year={year} month={month} />}
      <DealsCount deals={deals} year={year} month={month} type={type} />
      {type === "monthly" && (
        <AverageProfitPerDealForMonth dealership={dealership} year={year} month={month} />
      )}
      {type === "yearly" && <AverageProfitPerDealForYear dealership={dealership} year={year} />}
      <AverageApplicantScore deals={deals} year={year} type={type} month={month} />
      <ProductsSold deals={deals} year={year} type={type} month={month} />
      <TitleIssueInformation dealership={dealership} year={year} month={month} type={type} />
    </Grid>
  );
};
