import info_properties from "./info_properties";
import allQuerySchemaProperties from "../common/get_all_query_schema_properties";
import allSearchSchemaProperties from "../common/get_all_search_schema_properties";

const data = {
  type: "object",
  required: ["info"],
  properties: {
    info: {
      type: "object",
      required: ["description", "systemName"],
      properties: info_properties
    }
  }
};
export default {
  new_default_stipulation: {
    $id: "newDmv",
    type: "object",
    required: ["data"],
    properties: {
      data: data,
      availableToRolesIds: {
        type: "array",
        nullable: true,
        items: {
          type: "string"
        }
      }
    }
  },
  update_default_stipulation: {
    $id: "updateDefaultStipulation",
    type: "object",
    required: ["_id", "data"],
    properties: {
      _id: { type: "string" },
      data: data,
      availableToRolesIds: {
        type: "array",
        nullable: true,
        items: {
          type: "string"
        }
      },
      approved: {
        type: "boolean",
        nullable: true
      },
      approvedAt: {
        type: "string",
        nullable: true
      },
      approvedByUserId: {
        type: "string",
        nullable: true
      }
    }
  },
  delete_default_stipulation: {
    $id: "deleteDefaultStipulation",
    type: "object",
    required: ["_id"],
    properties: {
      _id: { type: "string" }
    }
  },
  recover_default_stipulation: {
    $id: "recoverDefaultStipulation",
    type: "object",
    required: ["_id"],
    properties: {
      _id: { type: "string" }
    }
  },
  get_default_stipulation: {
    $id: "getDefaultStipulation",
    type: "object",
    required: ["_id"],
    properties: {
      _id: { type: "string" }
    }
  },
  all_default_stipulations: {
    $id: "allDefaultStipulations",
    anyOf: [
      { type: "object", properties: allQuerySchemaProperties },
      { type: "object", properties: allSearchSchemaProperties }
    ]
  }
};
