import React from "react";
import {
  DialogTitle,
  DialogContent,
  Dialog,
  DialogActions,
  Grid,
  Paper,
  Button,
  Table,
  TableBody,
  TableContainer,
  TableHead
} from "@material-ui/core";
import CloseDialogButton from "components/common/CloseDialogButton";
import formatDate from "utils/formatDate";
import { useSnackbar } from "notistack";
import { Applicant } from "components/Applicants/types";
import { PersonaVerification, Verification } from "../../types/internal/personaVerifications";
import { isAAMVAVerificationValid } from "../../FraudValidationPreview";
import { getColorByStatus, renderVerificationOrReportHeader } from "../common";
import { VerificationOrReportDialogActions } from "../common";
import {
  VerificationCheckName,
  VerificationCheckStatus
} from "../../types/external/verifications/common";
import { renderTableHeadRow, renderTableRowWithData } from "./common";
type Props = {
  verifications: PersonaVerification[];
  applicant: Applicant;
  isLoading: boolean;
  retryFunction: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    selectedVerificationType: keyof Verification | "all"
  ) => void;
  closeFunction: () => void;
};

const AAMVAVerificationsHistoryDialog = ({
  verifications,
  applicant,
  isLoading,
  closeFunction,
  retryFunction
}: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const [rawJSONDialogOpen, setRawJSONDialogOpen] = React.useState(false);
  const [rawJSON, setRawJSON] = React.useState("");
  const [copied, setCopied] = React.useState(false);

  React.useEffect(() => {
    if (copied) {
      enqueueSnackbar("Successfully copied!", {
        variant: "success"
      });
    }
  }, [copied]);
  const closeRawDialogFunction = () => {
    setRawJSONDialogOpen(false);
    setCopied(false);
  };
  const copyRawJSON = async () => {
    await navigator.clipboard.writeText(rawJSON);
    setCopied(true);
  };
  const getPropertyNameAndValueByCheckName = (
    checkName: VerificationCheckName,
    verification: PersonaVerification
  ) => {
    switch (checkName) {
      case VerificationCheckName.AAMVA_Identification_Number_Comparison:
        return {
          name: "Identification Number",
          value:
            verification?.data?.info?.verification?.request?.data?.attributes?.identification_number
        };
      case VerificationCheckName.AAMVA_Name_Comparison:
        return {
          name: "Name",
          value: `${verification?.data?.info?.verification?.request?.data?.attributes?.name_first} ${verification?.data?.info?.verification?.request?.data?.attributes?.name_last}`
        };
      case VerificationCheckName.AAMVA_Birthdate_Comparison:
        const [
          year,
          month,
          day
        ] = verification?.data?.info?.verification?.request?.data?.attributes?.birthdate
          ?.split("-")
          ?.map((x) => parseInt(x)) as number[];
        return {
          name: "Birthdate",
          value: formatDate(new Date(year, month - 1, day, 0, 0, 0, 0), "medium")
        };
      case VerificationCheckName.AAMVA_Address_Comparison:
        return {
          name: "Postal Code",
          value:
            verification?.data?.info?.verification?.request?.data?.attributes?.address_postal_code
        };
      case VerificationCheckName.AAMVA_Issue_Date_Comparison:
        const [
          yearIssueDate,
          monthIssueDate,
          dayIssueDate
        ] = verification?.data?.info?.verification?.request?.data?.attributes?.issue_date
          ?.split("-")
          ?.map((x) => parseInt(x)) as number[];
        return {
          name: "Issue Date",
          value: formatDate(
            new Date(yearIssueDate, monthIssueDate - 1, dayIssueDate, 0, 0, 0, 0),
            "medium"
          )
        };
      case VerificationCheckName.AAMVA_Expiration_Date_Comparison:
        const [
          yearExpirationDate,
          monthExpirationDate,
          dayExpirationDate
        ] = verification?.data?.info?.verification?.request?.data?.attributes?.expiration_date
          ?.split("-")
          ?.map((x) => parseInt(x)) as number[];
        return {
          name: "Expiration Date",
          value: formatDate(
            new Date(yearExpirationDate, monthExpirationDate - 1, dayExpirationDate, 0, 0, 0, 0),
            "medium"
          )
        };
      default:
        return {
          name: "Unknown Property Name",
          value: "No information"
        };
    }
  };
  return (
    <React.Fragment>
      <Dialog
        maxWidth="md"
        id="raw-json-dialog"
        open={rawJSONDialogOpen}
        onClose={closeRawDialogFunction}
      >
        <DialogTitle id="raw-json-dialog-title">
          <CloseDialogButton closeFunction={closeRawDialogFunction} />
        </DialogTitle>
        <DialogContent>
          <pre>{rawJSON}</pre>
        </DialogContent>
        <DialogActions style={{ justifyContent: "space-between" }}>
          <Button
            style={copied ? { background: "green" } : {}}
            onClick={copyRawJSON}
            variant="contained"
            autoFocus
            color="primary"
            id="copy"
          >
            Copy
          </Button>
          <Button onClick={closeRawDialogFunction} variant="contained" color="primary" id="cancel">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        maxWidth="md"
        id="fraud-validation-reports-dialog"
        open={true}
        onClose={closeFunction}
      >
        <DialogTitle id="fraud-validation-reports-dialog-title">
          {`AAMVA Verification History - ${applicant.data.info.firstName} ${applicant.data.info.lastName}`}
          <CloseDialogButton closeFunction={closeFunction} />
        </DialogTitle>
        <DialogContent>
          <Grid container xs={12}>
            {verifications.reverse().map((verification, index) => {
              const status = isAAMVAVerificationValid(verification) ? "Valid" : "Invalid";

              const statusColor = getColorByStatus(status);
              return (
                <Paper key={index} elevation={3} style={{ width: "100%", marginBottom: "10px" }}>
                  <Grid container spacing={1} item xs={12} style={{ padding: "5px", margin: 0 }}>
                    {renderVerificationOrReportHeader(
                      status,
                      statusColor,
                      verification?.createdAt,
                      verification?.data?.info?.performedByUserEmail,
                      () => {
                        setRawJSON(JSON.stringify(verification, null, 2));
                        setRawJSONDialogOpen(true);
                      }
                    )}
                    <Grid item xs={12}>
                      <TableContainer style={{ marginTop: "10px" }} component={Paper}>
                        <Table size="medium">
                          <TableHead>{renderTableHeadRow()}</TableHead>
                          <TableBody>
                            {verification?.data?.info?.verification?.minnesotaDMVresponse ? (
                              <React.Fragment>
                                {renderTableRowWithData(
                                  "DL Status",
                                  undefined,
                                  verification?.data?.info?.verification?.minnesotaDMVresponse
                                    ?.dl_status,
                                  verification?.data?.info?.verification?.minnesotaDMVresponse?.dl_status?.includes(
                                    "Valid"
                                  )
                                )}
                                {renderTableRowWithData(
                                  "Commercial DL Status",
                                  undefined,
                                  verification?.data?.info?.verification?.minnesotaDMVresponse
                                    ?.commercial_dl_status,
                                  undefined
                                )}
                                {renderTableRowWithData(
                                  "DL Class",
                                  undefined,
                                  verification?.data?.info?.verification?.minnesotaDMVresponse
                                    ?.dl_class,
                                  undefined
                                )}
                                {renderTableRowWithData(
                                  "Expiration date",
                                  undefined,
                                  verification?.data?.info?.verification?.minnesotaDMVresponse
                                    ?.expiration_date,
                                  new Date(
                                    verification?.data?.info?.verification?.minnesotaDMVresponse?.expiration_date
                                  )?.getTime() > new Date().getTime()
                                )}
                                {renderTableRowWithData(
                                  "ID",
                                  verification?.data?.info?.verification?.minnesotaDMVrequest?.id,
                                  verification?.data?.info?.verification?.minnesotaDMVresponse?.id,
                                  undefined
                                )}
                                {renderTableRowWithData(
                                  "ID Type",
                                  undefined,
                                  verification?.data?.info?.verification?.minnesotaDMVresponse
                                    ?.id_type,
                                  undefined
                                )}
                                {renderTableRowWithData(
                                  "Issue Date",
                                  undefined,
                                  verification?.data?.info?.verification?.minnesotaDMVresponse
                                    ?.issue_date,
                                  undefined
                                )}
                              </React.Fragment>
                            ) : (
                              (
                                verification?.data?.info?.verification?.response?.data?.attributes
                                  ?.checks ?? []
                              )?.map((check) => {
                                const { name, value } = getPropertyNameAndValueByCheckName(
                                  check.name,
                                  verification
                                );
                                return renderTableRowWithData(
                                  name,
                                  value,
                                  check?.metadata?.match_result,
                                  check?.status === VerificationCheckStatus.Passed
                                );
                              })
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  </Grid>
                </Paper>
              );
            })}
          </Grid>
        </DialogContent>
        <VerificationOrReportDialogActions
          recordsLength={verifications?.length || 0}
          retryFunction={retryFunction}
          selectedVerificationType="aamvaVerification"
          isLoading={isLoading}
          closeFunction={closeFunction}
        />
      </Dialog>
    </React.Fragment>
  );
};
export default AAMVAVerificationsHistoryDialog;
