import { Grid, Typography } from "@material-ui/core";
import AccessControl from "components/Access/AccessControl";
import FilesPreview from "components/Files/FilesPreview";
import FundingDocumentsPreview from "components/FundingDocuments/FundingDocumentPreview";
import { FundingDocument } from "components/FundingDocuments/types";
import { PrintedDocument } from "components/PrintedDocuments/types";
import React from "react";
import RequiredDocumentsPreview from "../RequiredDocumentsPreview";
import { Deal } from "../types";
export default function PrintedDocumentsPreview({
  deal,
  setFundingDocumentForPreview,
  setPrintedDocumentForPreview,
  printedDocumentForPreview
}: {
  deal: Deal;
  setFundingDocumentForPreview: React.Dispatch<React.SetStateAction<FundingDocument | undefined>>;
  setPrintedDocumentForPreview: React.Dispatch<React.SetStateAction<PrintedDocument | undefined>>;
  printedDocumentForPreview: PrintedDocument | undefined;
}) {
  return (
    <Grid container>
      <Grid item xs={8}>
        <Typography
          variant="h5"
          style={{
            color: "#254e6e",
            fontSize: "19px",
            fontWeight: "bold",
            marginBottom: 10
          }}
        >
          Wet signing document status
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <RequiredDocumentsPreview
          deal={deal}
          setPrintedDocumentForPreview={setPrintedDocumentForPreview}
          printedDocumentForPreview={printedDocumentForPreview}
        />
      </Grid>
      <Grid item xs={12} style={{ marginBottom: 10 }}>
        <AccessControl requiredPermissions={{ entity: "file", action: "read" }}>
          <FilesPreview tableName="requiredDocuments" type="deal" _id={deal._id} />
        </AccessControl>
      </Grid>
      <Grid item xs={12}>
        <AccessControl requiredPermissions={{ entity: "funding_document", action: "read" }}>
          <FundingDocumentsPreview
            type="deal"
            _id={deal._id}
            setFundingDocumentForPreview={setFundingDocumentForPreview}
          />
        </AccessControl>
      </Grid>
    </Grid>
  );
}
