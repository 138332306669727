import Box from "@material-ui/core/Box";
import { RootState } from "app/rootReducer";
import { Filters } from "components/Filters/types";
import { ActiveTab } from "components/Layout/LayoutWrapper";
import { Tab } from "components/Tabs/types";
import { useStickyState } from "index";
import React from "react";
import { useSelector } from "react-redux";
import { pages } from "../Drawer/DrawerLinks";
import filtersContext from "./FiltersContext";
import modalContainer from "./modalContainer";
const screenIdToInt = (screenId: string) => (screenId === "leftScreen" ? 1 : 2);

export default function ContentWrapper({ activeTab }: { activeTab: ActiveTab }) {
  const tabSlice = useSelector((state: RootState) => state.tabSlice);

  return (
    <div
      style={{
        display: activeTab["leftScreen"] && activeTab["rightScreen"] ? "flex" : "block"
      }}
    >
      {[...tabSlice]
        .sort((a, b) => screenIdToInt(a.screenId) - screenIdToInt(b.screenId))
        .map((page) => {
          return <DisplayTab key={page._id} activeTab={activeTab} {...page} />;
        })}
    </div>
  );
}
const render = (tab: Tab): JSX.Element => {
  console.log(tab);
  switch (tab.index) {
    case "showPage": {
      const Component = pages[tab.index].component;

      return <Component {...tab.props} />;
    }

    case "addPage": {
      const Component = pages[tab.index].component;

      return <Component {...tab.props} />;
    }

    default: {
      const Component = pages[tab.index].component;

      return <Component {...(tab.props ?? {})} />;
    }
  }
};
const DisplayTab = (page: Tab & { activeTab: ActiveTab; key: string }) => {
  const modal = document.createElement("div");
  const [filters, setFilters] = useStickyState<{ [key: string]: Filters }>(
    {} as { [key: string]: Filters },
    "filters"
  );
  return page._id === page.activeTab["leftScreen"] || page._id === page.activeTab["rightScreen"] ? (
    <Box
      component="div"
      display={
        "block"
        // page._id === page.activeTab["leftScreen"] || page._id === page.activeTab["rightScreen"]
        //   ? "block"
        //   : "none"
      }
      key={page._id}
      style={{
        flex: 1,
        margin: "10px 2px",
        position: "relative"
      }}
    >
      <filtersContext.Provider value={{ filters, setFilters }}>
        <modalContainer.Provider value={modal}>
          {render(page)}
          <div
            ref={(nodeElement) => {
              nodeElement && nodeElement.appendChild(modal);
            }}
          />
        </modalContainer.Provider>
      </filtersContext.Provider>
    </Box>
  ) : (
    <></>
  );
};
