import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography
} from "@material-ui/core";
import { UserStatistic, LenderStatistic } from "hooks/useLenderStatistics/types";
import React from "react";
import Big from "big.js";
import { formatNumberAsCurrency, percentageDifference } from "utils/functions";
import styles from "../LenderDashboard.module.css";

const calculateTotalAmountFinanced = (statistics: UserStatistic[]) => {
  return statistics.reduce((acc, curr) => curr.totalAmountFinanced + acc, 0);
};

const ManagerTable = ({
  data,
  comparedData
}: {
  data: LenderStatistic | undefined;
  comparedData?: LenderStatistic | undefined;
}) => {
  const statistics2 = Object.values(data?.usersStatistics?.statistics ?? {})
    .map((x) =>
      Object.values(x).reduce(
        (acc: UserStatistic, curr) => ({
          id: curr.id,
          names: curr.names,
          totalCreditScore: new Big(acc.totalCreditScore).add(curr.totalCreditScore).toNumber(),
          totalAmountFinanced: new Big(acc.totalAmountFinanced)
            .add(curr.totalAmountFinanced)
            .toNumber(),
          dealCount: new Big(acc.dealCount).add(curr.dealCount).toNumber()
        }),
        {
          id: "",
          names: "",
          totalCreditScore: 0,
          totalAmountFinanced: 0,
          dealCount: 0
        }
      )
    )
    .sort((a, b) => a.names.localeCompare(b.names));
  const comparedStatistics = Object.values(comparedData?.usersStatistics?.statistics ?? {}).map(
    (x) =>
      Object.values(x).reduce(
        (acc: UserStatistic, curr) => ({
          id: curr.id,
          names: curr.names,
          totalCreditScore: new Big(acc.totalCreditScore).add(curr.totalCreditScore).toNumber(),
          totalAmountFinanced: new Big(acc.totalAmountFinanced)
            .add(curr.totalAmountFinanced)
            .toNumber(),
          dealCount: new Big(acc.dealCount).add(curr.dealCount).toNumber()
        }),
        {
          id: "",
          names: "",
          totalCreditScore: 0,
          totalAmountFinanced: 0,
          dealCount: 0
        }
      )
  );
  const totals = statistics2.reduce(
    (acc, x) => ({
      totalAveragedCreditScore: new Big(acc.totalAveragedCreditScore)
        .add(new Big(x.totalCreditScore).div(x.dealCount || 1).round(2, 1))
        .toNumber(),
      totalAveragedAmountFinanced: new Big(acc.totalAveragedAmountFinanced)
        .add(new Big(x.totalAmountFinanced).div(x.dealCount || 1).round(2, 1))
        .toNumber()
    }),
    { totalAveragedCreditScore: 0, totalAveragedAmountFinanced: 0 }
  );
  const totalsCompared = comparedStatistics.reduce(
    (acc, x) => ({
      totalAveragedCreditScore: new Big(acc.totalAveragedCreditScore)
        .add(new Big(x.totalCreditScore).div(x.dealCount || 1).round(2, 1))
        .toNumber(),
      totalAveragedAmountFinanced: new Big(acc.totalAveragedAmountFinanced)
        .add(new Big(x.totalAmountFinanced).div(x.dealCount || 1).round(2, 1))
        .toNumber()
    }),
    { totalAveragedCreditScore: 0, totalAveragedAmountFinanced: 0 }
  );
  //DEAL COUNT
  const dealCount = data?.totalDeals;
  const comparedDealCount = comparedData?.totalDeals;
  const dealCountDiff = percentageDifference(dealCount || 0, comparedDealCount || 0);
  //DEAL COUNT
  //TOTAL AMOUNT FINANCED
  const totalAmountFinanced = calculateTotalAmountFinanced(statistics2);
  const comparedTotalAmountFinanced = calculateTotalAmountFinanced(comparedStatistics);
  const totalAmountFinancedDiff = percentageDifference(
    totalAmountFinanced || 0,
    comparedTotalAmountFinanced || 0
  );
  //TOTAL AMOUNT FINANCED

  //AVG AMOUNT FINANCED
  const avgAmountFinanced = new Big(totals.totalAveragedAmountFinanced)
    .div(new Big(statistics2.length || 1))
    .round(2, 1)
    .toNumber();
  const comparedAvgAmountFinanced = new Big(totalsCompared.totalAveragedAmountFinanced)
    .div(new Big(comparedStatistics?.length || 1))
    .round(2, 1)
    .toNumber();
  const avgAmountFinancedDiff = percentageDifference(
    avgAmountFinanced || 0,
    comparedAvgAmountFinanced || 0
  );
  //AVG AMOUNT FINANCED

  //AVG CREDIT SCORE
  const avgCreditScore = new Big(totals.totalAveragedCreditScore)
    .div(new Big(statistics2.length || 1))
    .round(2, 1)
    .toNumber();
  const comparedAvgCreditScore = new Big(totalsCompared.totalAveragedCreditScore)
    .div(new Big(comparedStatistics.length || 1))
    .round(2, 1)
    .toNumber();
  const avgCreditScoreDiff = percentageDifference(avgCreditScore || 0, comparedAvgCreditScore || 0);
  //AVG CREDIT SCORE
  return (
    <Paper className={styles.dashboardItem}>
      <Grid container spacing={3} style={{ padding: 15, height: "100%" }}>
        <Grid item xs={12} style={{ display: "flex", justifyContent: "center", height: "10%" }}>
          <Typography style={{ fontWeight: 500, fontSize: 22, lineHeight: "20px" }}>
            Amount financed by manager
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ height: "90%" }}>
          <TableContainer>
            <Table stickyHeader aria-label="able">
              <TableHead style={{ whiteSpace: "nowrap", textAlign: "center" }}>
                <TableRow>
                  <TableCell align="left">Name</TableCell>
                  <TableCell align="left">Total amount financed</TableCell>
                  <TableCell align="left">Average amount financed</TableCell>
                  <TableCell align="left">Average credit score</TableCell>
                  <TableCell align="left">Lender selected count</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {statistics2?.map((row, index) => {
                  const comparedRow = comparedStatistics?.find((x) => x.names === row.names);
                  //DEAL COUNT
                  const dealCount = row.dealCount;
                  const comparedDealCount = comparedRow?.dealCount;
                  const dealCountDiff = percentageDifference(
                    dealCount || 0,
                    comparedDealCount || 0
                  );
                  //DEAL COUNT
                  //TOTAL AMOUNT FINANCED
                  const totalAmountFinanced = row.totalAmountFinanced;
                  const comparedTotalAmountFinanced = comparedRow?.totalAmountFinanced;
                  const totalAmountFinancedDiff = percentageDifference(
                    totalAmountFinanced || 0,
                    comparedTotalAmountFinanced || 0
                  );

                  //TOTAL AMOUNT FINANCED

                  //AVG AMOUNT FINANCED
                  const avgAmountFinanced = new Big(totalAmountFinanced)
                    .div(dealCount || 1)
                    .round(2, 1)
                    .toNumber();
                  const comparedAvgAmountFinanced = new Big(comparedTotalAmountFinanced || 0)
                    .div(comparedDealCount || 1)
                    .round(2, 1)
                    .toNumber();
                  const avgAmountFinancedDiff = percentageDifference(
                    avgAmountFinanced || 0,
                    comparedAvgAmountFinanced || 0
                  );
                  //AVG AMOUNT FINANCED

                  //AVG CREDIT SCORE
                  const avgCreditScore = new Big(row.totalCreditScore)
                    .div(new Big(row.dealCount || 1))
                    .round(2, 1)
                    .toNumber();
                  const comparedAvgCreditScore = new Big(comparedRow?.totalCreditScore || 0)
                    .div(comparedDealCount || 1)
                    .round(2, 1)
                    .toNumber();
                  const avgCreditScoreDiff = percentageDifference(
                    avgCreditScore || 0,
                    comparedAvgCreditScore || 0
                  );
                  //AVG CREDIT SCORE

                  return (
                    <TableRow key={index}>
                      <TableCell style={{ fontWeight: 500, fontSize: 19 }} align="left">
                        {row.names ?? ""}
                      </TableCell>
                      <TableCell style={{ fontWeight: 400, fontSize: 16 }} align="left">
                        <div style={{ display: "flex", alignItems: "center", marginLeft: "auto" }}>
                          <Typography style={{ fontWeight: 500, fontSize: 22, lineHeight: "23px" }}>
                            {formatNumberAsCurrency(totalAmountFinanced, "$")}
                          </Typography>
                          {comparedTotalAmountFinanced ? (
                            <Typography
                              style={{
                                fontSize: "10px",
                                color: totalAmountFinancedDiff > 0 ? "green" : "red"
                              }}
                            >
                              {`(${
                                totalAmountFinancedDiff > 0 ? "+" : "-"
                              }${totalAmountFinancedDiff.toFixed(2)}%)`}
                            </Typography>
                          ) : null}
                        </div>
                      </TableCell>
                      <TableCell style={{ fontWeight: 400, fontSize: 16 }} align="left">
                        <div style={{ display: "flex", alignItems: "center", marginLeft: "auto" }}>
                          <Typography style={{ fontWeight: 500, fontSize: 22, lineHeight: "23px" }}>
                            {formatNumberAsCurrency(avgAmountFinanced, "$")}
                          </Typography>
                          {comparedAvgAmountFinanced ? (
                            <Typography
                              style={{
                                fontSize: "10px",
                                color: avgAmountFinancedDiff > 0 ? "green" : "red"
                              }}
                            >
                              {`(${
                                avgAmountFinancedDiff > 0 ? "+" : "-"
                              }${avgAmountFinancedDiff.toFixed(2)}%)`}
                            </Typography>
                          ) : null}
                        </div>
                      </TableCell>
                      <TableCell style={{ fontWeight: 400, fontSize: 16 }} align="left">
                        <div style={{ display: "flex", alignItems: "center", marginLeft: "auto" }}>
                          <Typography style={{ fontWeight: 500, fontSize: 22, lineHeight: "23px" }}>
                            {avgCreditScore}
                          </Typography>
                          {comparedAvgCreditScore ? (
                            <Typography
                              style={{
                                fontSize: "10px",
                                color: avgCreditScoreDiff > 0 ? "green" : "red"
                              }}
                            >
                              {`(${avgCreditScoreDiff > 0 ? "+" : "-"}${avgCreditScoreDiff.toFixed(
                                2
                              )}%)`}
                            </Typography>
                          ) : null}
                        </div>
                      </TableCell>
                      <TableCell style={{ fontWeight: 400, fontSize: 16 }} align="left">
                        <div style={{ display: "flex", alignItems: "center", marginLeft: "auto" }}>
                          <Typography style={{ fontWeight: 500, fontSize: 22, lineHeight: "23px" }}>
                            {dealCount}
                          </Typography>
                          {comparedDealCount ? (
                            <Typography
                              style={{
                                fontSize: "10px",
                                color: dealCountDiff > 0 ? "green" : "red"
                              }}
                            >
                              {`(${dealCountDiff > 0 ? "+" : "-"}${dealCountDiff.toFixed(2)}%)`}
                            </Typography>
                          ) : null}
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>

              <TableFooter>
                <TableRow key={"totals"} style={{ background: "#E8E8E8", marginTop: "auto" }}>
                  <TableCell style={{ fontWeight: 500, fontSize: 19 }} align="left">
                    Total
                  </TableCell>

                  <TableCell style={{ fontWeight: 400, fontSize: 16 }} align="left">
                    <div style={{ display: "flex", alignItems: "center", marginLeft: "auto" }}>
                      <Typography style={{ fontWeight: 500, fontSize: 22, lineHeight: "23px" }}>
                        {formatNumberAsCurrency(totalAmountFinanced, "$")}
                      </Typography>
                      {comparedTotalAmountFinanced ? (
                        <Typography
                          style={{
                            fontSize: "10px",
                            color: totalAmountFinancedDiff > 0 ? "green" : "red"
                          }}
                        >
                          {`(${
                            totalAmountFinancedDiff > 0 ? "+" : "-"
                          }${totalAmountFinancedDiff.toFixed(2)}%)`}
                        </Typography>
                      ) : null}
                    </div>
                  </TableCell>
                  <TableCell style={{ fontWeight: 400, fontSize: 16 }} align="left">
                    <div style={{ display: "flex", alignItems: "center", marginLeft: "auto" }}>
                      <Typography style={{ fontWeight: 500, fontSize: 22, lineHeight: "23px" }}>
                        {formatNumberAsCurrency(avgAmountFinanced, "$")}
                      </Typography>
                      {comparedAvgAmountFinanced ? (
                        <Typography
                          style={{
                            fontSize: "10px",
                            color: avgAmountFinancedDiff > 0 ? "green" : "red"
                          }}
                        >
                          {`(${
                            avgAmountFinancedDiff > 0 ? "+" : "-"
                          }${avgAmountFinancedDiff.toFixed(2)}%)`}
                        </Typography>
                      ) : null}
                    </div>
                  </TableCell>
                  <TableCell style={{ fontWeight: 400, fontSize: 16 }} align="left">
                    <div style={{ display: "flex", alignItems: "center", marginLeft: "auto" }}>
                      <Typography style={{ fontWeight: 500, fontSize: 22, lineHeight: "23px" }}>
                        {avgCreditScore}
                      </Typography>
                      {comparedAvgCreditScore ? (
                        <Typography
                          style={{
                            fontSize: "10px",
                            color: avgCreditScoreDiff > 0 ? "green" : "red"
                          }}
                        >
                          {`(${avgCreditScoreDiff > 0 ? "+" : "-"}${avgCreditScoreDiff.toFixed(
                            2
                          )}%)`}
                        </Typography>
                      ) : null}
                    </div>
                  </TableCell>
                  <TableCell style={{ fontWeight: 400, fontSize: 16 }} align="left">
                    <div style={{ display: "flex", alignItems: "center", marginLeft: "auto" }}>
                      <Typography style={{ fontWeight: 500, fontSize: 22, lineHeight: "23px" }}>
                        {dealCount}
                      </Typography>
                      {comparedDealCount ? (
                        <Typography
                          style={{
                            fontSize: "10px",
                            color: dealCountDiff > 0 ? "green" : "red"
                          }}
                        >
                          {`(${dealCountDiff > 0 ? "+" : "-"}${dealCountDiff.toFixed(2)}%)`}
                        </Typography>
                      ) : null}
                    </div>
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ManagerTable;
