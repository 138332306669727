import { Schema } from "ajv";
import { dealNotes as notes } from "../common/notes_schema";
import { dealInfoProperties } from "../deals/info_properties";

const unrequiredExternalId = { type: "string", nullable: true };

const data: Schema = {
  type: "object",
  properties: {
    info: {
      type: "object",
      nullable: true,
      required: [],
      properties: dealInfoProperties
    },
    source: {
      type: "object",
      properties: {
        type: {
          type: ["string", "null"],
          nullable: true,
          enum: [
            "dealership_application",
            "mobile_application",
            "online_application",
            "web_finance_direct",
            null
          ],
          errorMessage: {
            enum: `must be one of the following: 
            'dealership_application', 'mobile_application', 'online_application', 'web_finance_direct'`
          }
        },
        applicationId: {
          type: "string",
          nullable: true
        }
      }
    },
    additionalRequiredDocuments: {
      type: "array",
      items: {
        type: "object",
        properties: {
          name: {
            type: "string",
            nullable: true
          },
          value: {
            type: "boolean",
            nullable: true
          }
        }
      },
      nullable: true
    },
    GAPCompanyId: unrequiredExternalId,
    insuranceCompanyId: unrequiredExternalId,
    vehicleInsuranceCompanyId: unrequiredExternalId,
    lenderId: unrequiredExternalId,
    dealershipId: unrequiredExternalId,
    applicantId: unrequiredExternalId,
    coApplicantId: unrequiredExternalId,
    userId: unrequiredExternalId,
    payoffBankId: unrequiredExternalId,
    creditSmartsId: unrequiredExternalId,
    creditSmartsDate: {
      type: "string",
      format: "date-time",
      errorMessage: { format: "must be a valid date" },
      nullable: true
    },
    dealertrackDealNumbers: {
      type: "array",
      nullable: true,
      items: {
        type: "object",
        required: [],
        nullable: true,
        properties: {
          dealertrackNumber: { type: "string", nullable: true },
          dateSubmitted: {
            type: "string",
            format: "date-time",
            errorMessage: { format: "must be a valid date" },
            nullable: true
          }
        }
      }
    },
    order: { type: "integer", nullable: true },
    appOneApplicationNumber: { type: "string", nullable: true },
    appOneApplicationURL: { type: "string", nullable: true },
    notes: notes,
    stateTaxId: unrequiredExternalId,
    appOneData: {
      type: "object",
      nullable: true,
      properties: {
        dealId: { type: "string", nullable: true },
        refNumber: { type: "string", nullable: true },
        data: { type: "object", additionalProperties: true }
      }
    }
  }
};

export default {
  new_external_credit_application_deal: {
    $id: "newExternalCreditApplicationDeal",
    type: "object",
    required: ["data"],
    properties: {
      data: data,
      availableToRolesIds: {
        type: "array",
        nullable: true,
        items: {
          type: "string"
        }
      }
    }
  }
};
