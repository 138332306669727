import { uuid as uuidv4 } from "uuidv4";
import { RootState } from "app/rootReducer";
import CheckIcon from "@material-ui/icons/Check";
import ErrorIcon from "@material-ui/icons/Error";
import React, { useEffect, useState } from "react";
import WarningIcon from "@material-ui/icons/Warning";
import { useDispatch, useSelector } from "react-redux";

import { HintTooltip } from "components/common/HintTooltip";

import { monthlyChargebacks } from "./model";
import { getChargebackList } from "../listChargebackSlice";

import {
  Table,
  Button,
  TableRow,
  Checkbox,
  TableHead,
  TableBody,
  TableCell,
  IconButton,
  FormControlLabel
} from "@material-ui/core";
import { setByPath, getByPath, StateAccess, generateForm } from "utils/models/formGenerator";
import { editRenderSet } from "utils/models/formRenderers";
import {
  getDealershipList,
  removeDealershipList
} from "components/Dealerships/listDealershipSlice";
import CircularProgress from "@material-ui/core/CircularProgress";
import { GroupedChargebacks } from "../types";
import { getFirstAndLastDateOfGivenYearAndMonth } from "utils/functions";
import { addChargebackBills } from "./addChargebackBillsSlice";

const MonthlyChargebacks = () => {
  const dispatch = useDispatch();
  const [sliceId] = useState(uuidv4());
  const [dealershipsSliceId] = useState(uuidv4());
  const [state, setState] = useState<{
    month?: number | null;
    year?: number | null;
    dealershipIds: string[];
  }>({ month: null, year: null, dealershipIds: [] });

  const chargebackList = useSelector(
    (rootState: RootState) => rootState.listChargebackSlice[sliceId]
  );

  const dealershipList = useSelector(
    (state: RootState) => state.listDealershipSlice[dealershipsSliceId]
  );

  const grouped =
    chargebackList?.entities?.reduce((acc: GroupedChargebacks, chargeback) => {
      if (chargeback.data.dealershipId && chargeback.data.dealership) {
        return {
          ...acc,
          [chargeback.data.dealershipId]: {
            dealership: chargeback.data.dealership,
            chargebacks: [...(acc?.[chargeback.data.dealershipId]?.chargebacks ?? []), chargeback]
          }
        };
      }
      return acc;
    }, {} as GroupedChargebacks) ?? {};

  useEffect(() => {
    if (chargebackList && chargebackList?.entities && chargebackList?.entities?.length > 0) {
      dispatch(
        getDealershipList(dealershipsSliceId, {
          query: {
            _id: { $in: Object.keys(grouped) }
          },
          options: {
            pagination: false
          }
        })
      );
    }
    return () => {
      dispatch(removeDealershipList(dealershipsSliceId));
    };
  }, [chargebackList?.entities?.length]);

  useEffect(() => {
    if (chargebackList && chargebackList.entities) {
      setState((state) => ({
        ...state,
        data: { ...state, dealershipIds: Object.keys(grouped) }
      }));
    }
  }, [chargebackList?.entities?.length]);

  useEffect(() => {
    if (state.month && state.year) {
      const { from, to } = getFirstAndLastDateOfGivenYearAndMonth(state.year, state.month);
      dispatch(
        getChargebackList(sliceId, {
          query: {
            "data.info.chargebackDate": {
              $gte: from,
              $lte: to
            }
          },
          options: { limit: 100 }
        })
      );
    }
  }, [state.month, state.year, dispatch]);

  const stateAccess: StateAccess = {
    get: (path) => getByPath(state as any, path),
    set: (path, value): any => setState(setByPath(state as any, path, value))
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (state.month && state.year)
      dispatch(
        addChargebackBills({
          requestId: sliceId,
          data: { month: state.month, year: state.year, dealershipIds: state.dealershipIds }
        })
      );
  };

  return (
    <>
      <form id="chargeback-form" onSubmit={handleSubmit}>
        {generateForm(monthlyChargebacks, stateAccess, [], stateAccess, editRenderSet(false))}
      </form>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state?.dealershipIds?.length === Object.keys(grouped)?.length}
                    indeterminate={
                      state?.dealershipIds?.length !== Object.keys(grouped)?.length &&
                      state?.dealershipIds?.length > 0
                    }
                    onChange={(_, checked) => {
                      if (checked) {
                        console.log(grouped);

                        setState((state) => ({
                          ...state,
                          dealershipIds: Object.keys(grouped)
                        }));
                      } else {
                        setState((state) => ({
                          ...state,
                          data: { ...state, dealershipIds: [] }
                        }));
                      }
                    }}
                  />
                }
                label={""}
              />
            </TableCell>
            <TableCell>Dealership</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Applicant(s)</TableCell>
            <TableCell> Funded Date</TableCell>
            <TableCell> Payoff/Cancellation Date</TableCell>
            <TableCell>Chargeback date</TableCell>
            <TableCell>Total Chargeback amount</TableCell>
            <TableCell>Dealer Chargeback amount</TableCell>
            <TableCell>WFD Chargeback amount</TableCell>
            <TableCell>Manager Chargeback amount</TableCell>
            <TableCell>NET WFD Chargeback amount</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.entries(grouped).map(([dealershipId, group]) => {
            const billData = dealershipList?.entities?.find(
              (dealership) => dealership._id === dealershipId
            )?.data?.chargebackBills?.[`${state.year}-${state.month}`];

            return group.chargebacks.map((chargeback, index) => {
              return (
                <TableRow key={index}>
                  {index === 0 ? (
                    <>
                      <TableCell rowSpan={index === 0 ? group.chargebacks?.length : 1}>
                        {editRenderSet(false).checkboxInputRenderer(
                          {
                            formComponent: "checkbox-field",
                            name: index?.toString(),
                            required: false,
                            path: ["dealershipIds"],
                            isChecked: (stateAccess: StateAccess) =>
                              stateAccess
                                .get(["dealershipIds"])
                                ?.find((id: string) => id === dealershipId)
                                ? true
                                : false,
                            toggle: (stateAccess: StateAccess, checked: boolean) => {
                              const restIds: string[] = stateAccess.get(["dealershipIds"]);
                              if (checked) {
                                stateAccess.set(
                                  ["dealershipIds"],
                                  [...(restIds ?? []), dealershipId]
                                );
                              } else {
                                stateAccess.set(
                                  ["dealershipIds"],
                                  restIds.filter((id) => id !== dealershipId)
                                );
                              }
                            }
                          },
                          stateAccess,
                          stateAccess,
                          editRenderSet(false),
                          []
                        )}
                      </TableCell>
                      <TableCell rowSpan={index === 0 ? group.chargebacks?.length : 1}>
                        {group?.dealership?.data.info.name}
                      </TableCell>
                      <TableCell
                        rowSpan={index === 0 ? group.chargebacks?.length : 1}
                        align="center"
                      >
                        {billData?.status === "success" ? (
                          <IconButton
                            component="span"
                            color="primary"
                            onClick={() =>
                              window.open(
                                `${process.env.REACT_APP_NETSUITE_LINK}/app/accounting/transactions/custinvc.nl?id=${billData?.internalId}`,
                                "_blank"
                              )
                            }
                          >
                            <CheckIcon style={{ color: "green" }} />
                          </IconButton>
                        ) : billData?.status === "waiting" ? (
                          <CircularProgress size="1.5em" />
                        ) : billData?.status === "warning" ? (
                          <HintTooltip title={billData.message}>
                            <WarningIcon style={{ color: "#eed202" }} />
                          </HintTooltip>
                        ) : billData?.status === "error" ? (
                          <HintTooltip title={billData.message}>
                            <ErrorIcon color="error" />
                          </HintTooltip>
                        ) : (
                          ""
                        )}
                      </TableCell>
                    </>
                  ) : (
                    <></>
                  )}

                  <TableCell>{`${chargeback.data.deal?.applicantFirstName ?? ""} ${
                    chargeback.data?.deal?.applicantLastName ?? ""
                  }`}</TableCell>
                  <TableCell>
                    {chargeback.data?.deal?.fundingDate
                      ? new Date(chargeback.data?.deal?.fundingDate)?.toLocaleDateString()
                      : "N/A"}
                  </TableCell>
                  <TableCell>
                    {chargeback.data?.info?.payoffDate
                      ? new Date(chargeback.data?.info?.payoffDate)?.toLocaleDateString()
                      : "N/A"}
                  </TableCell>
                  <TableCell>
                    {chargeback.data.info?.chargebackDate
                      ? new Date(chargeback.data.info?.chargebackDate)?.toLocaleDateString()
                      : "N/A"}
                  </TableCell>
                  <TableCell>
                    {new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(
                      Number(chargeback.data.info?.totalChargebackAmount ?? 0)
                    )}
                  </TableCell>
                  <TableCell>
                    {new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(
                      Number(chargeback.data.info?.dealerChargebackAmount ?? 0)
                    )}
                  </TableCell>
                  <TableCell>
                    {new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(
                      Number(chargeback.data.info?.wfdChargebackAmount ?? 0)
                    )}
                  </TableCell>
                  <TableCell>
                    {new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(
                      Number(chargeback.data.info?.managerChargebackAmount ?? 0)
                    )}
                  </TableCell>
                  <TableCell>
                    {new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(
                      Number(chargeback.data.info?.netWFDChargeBackAmount ?? 0)
                    )}
                  </TableCell>
                </TableRow>
              );
            });
          })}
        </TableBody>
      </Table>
      <div>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          form={"chargeback-form"}
          disabled={state.dealershipIds.length < 1}
          style={{ float: "right", margin: "1em 0em 0em 0em" }}
        >
          SEND INVOICES
        </Button>
      </div>
    </>
  );
};
export default MonthlyChargebacks;
