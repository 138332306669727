import { Box, Grid, Paper } from "@material-ui/core";
import { Deal } from "components/Deals/types";
import React from "react";
import { Bar, Chart } from "react-chartjs-2";

import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Tooltip
} from "chart.js";
import styles from "./DealershipShowPage.module.css";

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend);

const getMonths = () => {
  return Array.from({ length: 12 }, (_, i) => i + 1);
};

export default ({ deals, year }: { deals: Deal[]; year: number }) => {
  const fundedDeals = deals?.filter((deal) => deal.data.info.status === "funded");

  const groupedByContractDateCreditScore = fundedDeals.reduce((acc, deal) => {
    const contractDate = new Date(deal.data.info.dealDates.contractDate || deal.createdAt);
    const key = `${contractDate.getFullYear()}-${contractDate.getMonth()}`;
    let score = deal?.data?.info?.extractedCreditScores?.applicant?.score || 0;
    if (deal?.data?.info?.extractedCreditScores?.coApplicant?.score) {
      score = (score + deal?.data?.info?.extractedCreditScores?.coApplicant?.score) / 2;
    }
    acc[key] = acc[key] || 0;
    acc[key] += score;
    return acc;
  }, {} as { [key: string]: number });

  const groupedByContractDateGrossProfit = fundedDeals.reduce((acc, deal) => {
    const contractDate = new Date(deal.data.info.dealDates.contractDate || deal.createdAt);
    const key = `${contractDate.getFullYear()}-${contractDate.getMonth()}`;
    acc[key] = acc[key] || 0;
    acc[key] += deal.data.info.profit?.grossProfit || 0;
    return acc;
  }, {} as { [key: string]: number });

  const groupedByContractDatecount = fundedDeals.reduce((acc, deal) => {
    const contractDate = new Date(deal.data.info.dealDates.contractDate || deal.createdAt);
    const key = `${contractDate.getFullYear()}-${contractDate.getMonth()}`;
    acc[key] = acc[key] || 0;
    acc[key] += 1;
    return acc;
  }, {} as { [key: string]: number });

  const groupedByContractDateGAPSold = fundedDeals.reduce((acc, deal) => {
    const contractDate = new Date(deal.data.info.dealDates.contractDate || deal.createdAt);
    if (deal.data.info.aftermarketOptions.totalGAPSellPrice) {
      const key = `${contractDate.getFullYear()}-${contractDate.getMonth()}`;
      acc[key] = acc[key] || 0;
      acc[key] += 1;
    }
    return acc;
  }, {} as { [key: string]: number });

  const groupedByContractDateWarrantySold = fundedDeals.reduce((acc, deal) => {
    const contractDate = new Date(deal.data.info.dealDates.contractDate || deal.createdAt);
    if (deal.data.info.aftermarketOptions.totalServiceWarrantySellPrice) {
      const key = `${contractDate.getFullYear()}-${contractDate.getMonth()}`;
      acc[key] = acc[key] || 0;
      acc[key] += 1;
    }
    return acc;
  }, {} as { [key: string]: number });

  const datasetProfit = getMonths().map((month) => {
    const key = `${year}-${month - 1}`;
    return groupedByContractDateGrossProfit[key] || 0;
  });

  const datasetCount = getMonths().map((month) => {
    const key = `${year}-${month - 1}`;
    return groupedByContractDatecount[key] ? groupedByContractDatecount[key] : 0;
  });
  const datasetScore = getMonths().map((month) => {
    const key = `${year}-${month - 1}`;
    return groupedByContractDateCreditScore[key]
      ? groupedByContractDateCreditScore[key] / groupedByContractDatecount[key]
      : 0;
  });

  const datasetGap = getMonths().map((month) => {
    const key = `${year}-${month - 1}`;
    return groupedByContractDateGAPSold[key] ? groupedByContractDateGAPSold[key] : 0;
  });

  const datasetWarranty = getMonths().map((month) => {
    const key = `${year}-${month - 1}`;
    return groupedByContractDateWarrantySold[key] ? groupedByContractDateWarrantySold[key] : 0;
  });
  const data = {
    labels: getMonths().map((month) => {
      return new Date(year, month - 1, 1).toLocaleString("default", {
        month: "long"
      });
    }),
    datasets: [
      {
        type: "bar" as const,
        label: "Count",
        fill: false,
        lineTension: 0.1,
        backgroundColor: "green",
        borderColor: "green",
        borderDash: [],
        borderDashOffset: 0.0,
        pointBorderColor: "green",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "green",
        pointHoverBorderColor: "green",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        yAxisID: "yCount",
        data: datasetCount
      },
      {
        type: "bar" as const,
        label: "Profit",
        fill: false,
        lineTension: 0.1,
        backgroundColor: "#254e70",
        borderColor: "#254e70",
        borderDash: [],
        borderDashOffset: 0.0,
        pointBorderColor: "#254e70",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "#254e70",
        pointHoverBorderColor: "#254e70",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        yAxisID: "yProfit",
        data: datasetProfit
      },
      {
        type: "bar" as const,
        label: "Average Credit Score",
        fill: false,
        lineTension: 0.1,
        backgroundColor: "purple",
        borderColor: "purple",
        borderDash: [],
        borderDashOffset: 0.0,
        pointBorderColor: "purple",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "purple",
        pointHoverBorderColor: "purple",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        yAxisID: "yScore",
        data: datasetScore
      },
      {
        type: "bar" as const,
        label: "GAP Sold",
        fill: false,
        lineTension: 0.1,
        backgroundColor: "blue",
        borderColor: "blue",
        borderDash: [],
        borderDashOffset: 0.0,
        pointBorderColor: "blue",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "blue",
        pointHoverBorderColor: "blue",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        yAxisID: "yGap",
        data: datasetGap
      },
      {
        type: "bar" as const,
        label: "Warranty Sold",
        fill: false,
        lineTension: 0.1,
        backgroundColor: "red",
        borderColor: "red",
        borderDash: [],
        borderDashOffset: 0.0,
        pointBorderColor: "red",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "red",
        pointHoverBorderColor: "red",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        yAxisID: "yWarranty",
        data: datasetWarranty
      }
    ]
  };
  const dataProfit = {
    labels: getMonths().map((month) => {
      return new Date(year, month - 1, 1).toLocaleString("default", {
        month: "long"
      });
    }),
    datasets: [
      {
        type: "bar" as const,
        label: "Profit",
        fill: false,
        lineTension: 0.1,
        backgroundColor: "#254e70",
        borderColor: "#254e70",
        borderDash: [],
        borderDashOffset: 0.0,
        pointBorderColor: "#254e70",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "#254e70",
        pointHoverBorderColor: "#254e70",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        yAxisID: "yProfit",
        data: datasetProfit
      }
    ]
  };
  const dataCount = {
    labels: getMonths().map((month) => {
      return new Date(year, month - 1, 1).toLocaleString("default", {
        month: "long"
      });
    }),
    datasets: [
      {
        type: "bar" as const,
        label: "Count",
        fill: false,
        lineTension: 0.1,
        backgroundColor: "green",
        borderColor: "green",
        borderDash: [],
        borderDashOffset: 0.0,
        pointBorderColor: "green",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "green",
        pointHoverBorderColor: "green",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        yAxisID: "yCount",
        data: datasetCount
      }
    ]
  };
  const dataScore = {
    labels: getMonths().map((month) => {
      return new Date(year, month - 1, 1).toLocaleString("default", {
        month: "long"
      });
    }),
    datasets: [
      {
        type: "bar" as const,
        label: "Average Credit Score",
        fill: false,
        lineTension: 0.1,
        backgroundColor: "purple",
        borderColor: "purple",
        borderDash: [],
        borderDashOffset: 0.0,
        pointBorderColor: "purple",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "purple",
        pointHoverBorderColor: "purple",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        yAxisID: "yScore",
        data: datasetScore
      }
    ]
  };
  const dataGap = {
    labels: getMonths().map((month) => {
      return new Date(year, month - 1, 1).toLocaleString("default", {
        month: "long"
      });
    }),
    datasets: [
      {
        type: "bar" as const,
        label: "GAP Sold",
        fill: false,
        lineTension: 0.1,
        backgroundColor: "blue",
        borderColor: "blue",
        borderDash: [],
        borderDashOffset: 0.0,
        pointBorderColor: "blue",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "blue",
        pointHoverBorderColor: "blue",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        yAxisID: "yGap",
        data: datasetGap
      }
    ]
  };
  const dataWarranty = {
    labels: getMonths().map((month) => {
      return new Date(year, month - 1, 1).toLocaleString("default", {
        month: "long"
      });
    }),
    datasets: [
      {
        type: "bar" as const,
        label: "Warranty Sold",
        fill: false,
        lineTension: 0.1,
        backgroundColor: "red",
        borderColor: "red",
        borderDash: [],
        borderDashOffset: 0.0,
        pointBorderColor: "red",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "red",
        pointHoverBorderColor: "red",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        yAxisID: "yWarranty",
        data: datasetWarranty
      }
    ]
  };

  return (
    <Grid lg={12} md={12} sm={12} xs={12}>
      <Paper elevation={2} style={{ padding: "10px", margin: "10px", height: "calc(100% - 40px)" }}>
        <div className={styles.title}>Monthly trends</div>

        <Box
          display={{
            xs: "none",
            sm: "none",
            md: "block",
            lg: "block"
          }}
        >
          <Chart
            type="bar"
            data={data}
            height={50}
            options={{
              plugins: {
                legend: {
                  display: true,
                  position: "top"
                }
              },
              scales: {
                yProfit: {
                  border: {
                    color: "black",
                    width: 3,
                    display: true
                  },
                  title: {
                    text: "Profit",
                    color: "#254E70",
                    display: true
                  },
                  grace: "50%",
                  beginAtZero: true,
                  ticks: {
                    color: "black",

                    stepSize: 1,
                    // Include a dollar sign in the ticks
                    callback: function (value) {
                      return "$ " + value;
                    }
                  }
                },
                yCount: {
                  border: {
                    color: "black",
                    width: 3,
                    display: true
                  },
                  beginAtZero: true,
                  grace: "50%",
                  title: {
                    text: "Count",
                    color: "green",
                    display: true
                  },
                  ticks: {
                    color: "black",
                    padding: 10,
                    align: "center",
                    stepSize: 1

                    // Include a dollar sign in the ticks
                  }
                },
                yScore: {
                  border: {
                    color: "black",
                    width: 3,
                    display: true
                  },
                  beginAtZero: true,
                  grace: "50%",
                  title: {
                    text: "Score",
                    color: "purple",
                    display: true
                  },
                  ticks: {
                    color: "black",
                    padding: 10,
                    align: "center",
                    stepSize: 1

                    // Include a dollar sign in the ticks
                  }
                },
                yGap: {
                  border: {
                    color: "black",
                    width: 3,
                    display: true
                  },
                  beginAtZero: true,
                  grace: "50%",
                  title: {
                    text: "GAP Sold",
                    color: "blue",
                    display: true
                  },
                  ticks: {
                    color: "black",
                    padding: 10,
                    align: "center",
                    stepSize: 1

                    // Include a dollar sign in the ticks
                  }
                },
                yWarranty: {
                  border: {
                    color: "black",
                    width: 3,
                    display: true
                  },
                  beginAtZero: true,
                  grace: "50%",
                  title: {
                    text: "Warranty Sold",
                    color: "red",
                    display: true
                  },
                  ticks: {
                    color: "black",
                    padding: 10,
                    align: "center",
                    stepSize: 1

                    // Include a dollar sign in the ticks
                  }
                }
              }
            }}
          />
        </Box>
        <Box
          display={{
            xs: "block",
            sm: "block",
            md: "none",
            lg: "none"
          }}
        >
          <Chart
            type="bar"
            data={dataProfit}
            //height={50}
            options={{
              plugins: {
                legend: {
                  display: true,
                  position: "top"
                }
              },
              scales: {
                yProfit: {
                  border: {
                    color: "black",
                    width: 3,
                    display: true
                  },
                  title: {
                    text: "Profit",
                    color: "#254E70",
                    display: true
                  },
                  grace: "50%",
                  beginAtZero: true,
                  ticks: {
                    color: "black",

                    stepSize: 1,
                    // Include a dollar sign in the ticks
                    callback: function (value) {
                      return "$ " + value;
                    }
                  }
                }
              }
            }}
          />
          <Chart
            type="bar"
            data={dataCount}
            //height={50}
            options={{
              plugins: {
                legend: {
                  display: true,
                  position: "top"
                }
              },
              scales: {
                yCount: {
                  border: {
                    color: "black",
                    width: 3,
                    display: true
                  },
                  beginAtZero: true,
                  grace: "50%",
                  title: {
                    text: "Count",
                    color: "green",
                    display: true
                  },
                  ticks: {
                    color: "black",
                    padding: 10,
                    align: "center",
                    stepSize: 1

                    // Include a dollar sign in the ticks
                  }
                }
              }
            }}
          />
          <Chart
            type="bar"
            data={dataScore}
            //height={50}
            options={{
              plugins: {
                legend: {
                  display: true,
                  position: "top"
                }
              },
              scales: {
                yScore: {
                  border: {
                    color: "black",
                    width: 3,
                    display: true
                  },
                  beginAtZero: true,
                  grace: "50%",
                  title: {
                    text: "Score",
                    color: "purple",
                    display: true
                  },
                  ticks: {
                    color: "black",
                    padding: 10,
                    align: "center",
                    stepSize: 1

                    // Include a dollar sign in the ticks
                  }
                }
              }
            }}
          />
          <Chart
            type="bar"
            data={dataGap}
            //height={50}
            options={{
              plugins: {
                legend: {
                  display: true,
                  position: "top"
                }
              },
              scales: {
                yGap: {
                  border: {
                    color: "black",
                    width: 3,
                    display: true
                  },
                  beginAtZero: true,
                  grace: "50%",
                  title: {
                    text: "GAP Sold",
                    color: "blue",
                    display: true
                  },
                  ticks: {
                    color: "black",
                    padding: 10,
                    align: "center",
                    stepSize: 1

                    // Include a dollar sign in the ticks
                  }
                }
              }
            }}
          />
          <Chart
            type="bar"
            data={dataWarranty}
            //height={50}
            options={{
              plugins: {
                legend: {
                  display: true,
                  position: "top"
                }
              },
              scales: {
                yGap: {
                  border: {
                    color: "black",
                    width: 3,
                    display: true
                  },
                  beginAtZero: true,
                  grace: "50%",
                  title: {
                    text: "Warranty Sold",
                    color: "blue",
                    display: true
                  },
                  ticks: {
                    color: "black",
                    padding: 10,
                    align: "center",
                    stepSize: 1

                    // Include a dollar sign in the ticks
                  }
                }
              }
            }}
          />
        </Box>
      </Paper>
    </Grid>
  );
};
