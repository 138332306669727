import { Box } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { UploadedFile } from "components/Files/FilesPreview";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useDispatch } from "react-redux";
import { EntityType } from "utils/entitySlice";
import { v4 as uuidv4 } from "uuid";
import TextLoop from "../components/Loader/TextLoop";
import { storage } from "../firebase/firebase";
import { devlog } from "./logging";

const style = {
  display: "flex",
  position: "absolute",
  right: "0px",
  bottom: "0px",
  left: "0px",
  top: "0px",
  cursor: "pointer",
  zIndex: 1200,
  justifyContent: "center",
  alignItems: "center",
  borderWidth: "2px",
  borderColor: "rgb(102, 102, 102)",
  borderStyle: "dashed",
  borderRadius: "5px",
  backgroundColor: "rgba(240,248,255,0.5)"
} as React.CSSProperties;

interface Props {
  uniqueId: string;
  type: EntityType;
  id: string;
  containerRef: React.RefObject<HTMLDivElement>;
  addFunction: Function;
  defaultState?: any;
  requestId?: string;
  isInAddFileDialog?: boolean;
  contractFileAddFunction?: (file: UploadedFile) => void;
}

export default function DropZone({
  uniqueId,
  type,
  id,
  containerRef,
  addFunction,
  defaultState,
  requestId,
  isInAddFileDialog,
  contractFileAddFunction
}: Props): JSX.Element {
  const fileSizeLimit = 2147483648; //2GB
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [showProgressBar, setShowProgressBar] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [progress, setProgress] = useState(0);
  const [showDropZone, setShowDropZone] = useState(isInAddFileDialog ?? false);

  useEffect(() => {
    const container = containerRef?.current;
    if (container !== null) {
      let lastTarget: EventTarget | null = null;
      container.addEventListener("drop", (e) => {
        // get files from drop event
        const uniqueIdFromData = e.dataTransfer?.getData("uniqueId");
        const attachment = e.dataTransfer?.getData("attachment");
        const hasAttachment = e?.dataTransfer?.types.includes("attachment");
        if (hasAttachment && attachment && uniqueIdFromData !== uniqueId) {
          e.preventDefault();
          const { url, name, metadata } = JSON.parse(attachment);
          dispatch(
            addFunction({
              ...defaultState,
              data: {
                ...defaultState.data,
                info: {
                  url,
                  name,
                  metadata: {
                    ...(metadata ?? {}),
                    size: metadata?.size ? parseInt(metadata.size) : 0
                  }
                },
                [type]: { _id: id }
              },
              requestId: requestId ?? uuidv4()
            })
          );
          setShowDropZone(false);
        } else if (hasAttachment) {
          setShowDropZone(false);
        }
      });

      container.addEventListener("dragenter", function (e) {
        if (
          (e?.dataTransfer?.items?.[0]?.kind === "file" ||
            e?.dataTransfer?.types.includes("attachment")) &&
          !e?.dataTransfer?.types.includes(uniqueId)
        ) {
          lastTarget = e.target;
          setShowDropZone(true);
        }
      });
      container.addEventListener("dragleave", function (e) {
        if (e.target === lastTarget || e.target === document) {
          setShowDropZone(false);
        }
      });
    }
  }, [setShowDropZone, containerRef]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (droppedfiles) => {
      for (const droppedfile of droppedfiles) {
        if (droppedfile.size < fileSizeLimit) {
          const file = droppedfile;
          const randomID = uuidv4();
          const firebaseFileName = `${randomID}-${file.name}`;
          const uploadTask = storage.ref(`/files/${type}/${id}/${firebaseFileName}`).put(file);
          uploadTask.on(
            "state_changed",
            (snapShot) => {
              setShowLoader(true);
              setShowProgressBar(true);
              setProgress((snapShot.bytesTransferred / snapShot.totalBytes) * 100);
            },
            (err) => {
              devlog(err);
              enqueueSnackbar(err.message, {
                variant: "error"
              });
              setShowLoader(false);
              setShowDropZone(false);
              setShowProgressBar(false);
            },
            () => {
              const ref = storage.ref(`/files/${type}/${id}`).child(firebaseFileName);
              isInAddFileDialog
                ? addFunction(file.name, ref)
                : ref.getDownloadURL().then((fireBaseUrl) => {
                    ref.getMetadata().then((metadata) => {
                      contractFileAddFunction
                        ? contractFileAddFunction({ url: fireBaseUrl, name: file.name, metadata })
                        : dispatch(
                            addFunction({
                              ...defaultState,
                              data: {
                                ...defaultState.data,
                                info: {
                                  url: fireBaseUrl,
                                  name: file.name,
                                  metadata
                                },
                                [type]: { _id: id }
                              },
                              requestId: requestId ?? randomID
                            })
                          );
                    });
                  });

              setShowLoader(false);
              setShowDropZone(false);
              setShowProgressBar(false);
            }
          );
        } else {
          if (!isInAddFileDialog) setShowDropZone(false);
          enqueueSnackbar(`File of type ${droppedfile.type} is not supported.`, {
            variant: "error"
          });
        }
      }
    }
  });

  return (
    <section>
      {showLoader ? (
        <Box display="flex" style={{ position: "relative" }}>
          <TextLoop
            text=""
            style={{
              position: "absolute",
              background: "rgba(0 ,0 , 0 , 0.01)",
              width: "100%",
              height: "100%",
              textAlign: "center",
              marginTop: "35px"
            }}
          />
        </Box>
      ) : (
        <> </>
      )}
      {showDropZone ? (
        <div {...getRootProps({ style })} id="drop">
          <input {...getInputProps()} id="upload_input" draggable="true" />
          {showProgressBar ? (
            <Box display="flex" alignItems="center">
              <Box minWidth={35}>
                <Typography variant="h4" color="primary">
                  Loading {progress !== undefined && `${Math.round(progress)}%`}
                </Typography>
              </Box>
            </Box>
          ) : (
            <Typography variant="h4" color="primary">
              Drag and drop a file here to upload.
            </Typography>
          )}
        </div>
      ) : (
        <></>
      )}
    </section>
  );
}
