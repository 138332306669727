import { Button, Typography } from "@material-ui/core";
import LinkIcon from "@material-ui/icons/Link";
import { RootState } from "app/rootReducer";
import formEditContext from "components/Content/FormEditContext";
import { useSnackbar } from "notistack";
import React, { useContext, useEffect, useState } from "react";
import { PiSealWarningDuotone } from "react-icons/pi";
import { useDispatch, useSelector } from "react-redux";
import { StateAccess } from "utils/models/formGenerator";
import { v4 as uuidv4 } from "uuid";
import { DealershipEmailVerificationLink } from "./emailVerificationLinkSlice";
import { Dealership } from "./types";

const EmailVerificationLink = ({ stateAccess }: { stateAccess: StateAccess }) => {
  const dispatch = useDispatch();
  const dealership: Dealership = stateAccess.get<Dealership>([]);
  const [requestId] = useState(uuidv4());
  const { enqueueSnackbar } = useSnackbar();
  const { enabled: editMode, edited } = useContext(formEditContext);
  const handleEmailVerificationLink = () => {
    if (!dealership?.data?.info?.adminEmail) {
      return alert("Please enter 'Admin email' before generate the link");
    }
    if (edited) {
      return alert("Please save the dealership first.");
    }

    dispatch(DealershipEmailVerificationLink({ requestId, _id: dealership._id }));
  };

  const emailVerificationLinkSlice = useSelector(
    (state: RootState) => state.emailVerificationLinkSlice[requestId]
  );
  const [link, setLink] = useState("");
  useEffect(() => {
    if (emailVerificationLinkSlice?.status === "success") {
      enqueueSnackbar("Email validation link was generated successfully!", { variant: "success" });
      setLink(emailVerificationLinkSlice?.data?.message);
    } else if (emailVerificationLinkSlice?.status === "error") {
      enqueueSnackbar(emailVerificationLinkSlice?.message, { variant: "error" });
    }
  }, [emailVerificationLinkSlice]);

  return editMode ? (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: "5px",
        border: "1px dashed black",
        borderRadius: "3px",
        padding: "10px"
      }}
    >
      <div style={{ flex: 1 }}>
        {editMode && (
          <Button
            onClick={handleEmailVerificationLink}
            variant="contained"
            component="label"
            color="primary"
            startIcon={<LinkIcon />}
          >
            Email verification link for admin registration
          </Button>
        )}
      </div>
      <div>
        {link && (
          <a
            target="_blank"
            rel="noreferrer"
            style={{ margin: "10px 0px", display: "block" }}
            href={link}
          >
            Open Email verification link
          </a>
        )}
      </div>
      <div style={{ flex: 1 }}>
        <Typography style={{ fontWeight: 500 }} variant="subtitle1">
          Generates an email verification link. Can be completed either through this platform or can
          be copied and sent to the dealership platform user if he did not receive the automatic one
          from Firebase.
        </Typography>
      </div>
      <div>
        <PiSealWarningDuotone size={50} color="#dec83c" />
      </div>
    </div>
  ) : (
    <></>
  );
};

export default EmailVerificationLink;
