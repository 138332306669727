import { Chip } from "@material-ui/core";
import { RootState } from "app/rootReducer";
import ContractDateFilter from "components/Filters/ContractDateFilter";
import ContractStatusFilter from "components/Filters/ContractStatusFilter";
import DealershipFilter from "components/Filters/DealershipFilter";
import LenderFilter from "components/Filters/LenderFilter";
import MultiSelectFilter from "components/Filters/MultiSelectFilter";
import OtherVendorFilter from "components/Filters/OtherVendorFilter";
import TextFilter from "components/Filters/TextFilter";
import { TabContext } from "components/Layout/LayoutWrapper";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { lockEntityFunction } from "utils/models/ShowForm";
import { v4 as uuidv4 } from "uuid";
import AccessControl from "../Access/AccessControl";
import Table, { CellValue, Column } from "../Table";
import { getLatestContractFile } from "./ContractDatesPreview";
import { deleteContract } from "./deleteContractSlice";
import { getContractList } from "./listContractSlice";
import { recoverContract } from "./recoverContractSlice";
import RenewContractDialog from "./RenewContactDialog";
import { ContractData } from "./types";

const projection = {
  _id: 1,
  createdAt: 1,
  creatorId: 1,
  updatedAt: 1,
  deleted: 1,
  "data.files": 1,
  "data.info.name": 1,
  "data.info.startDate": 1,
  "data.info.signedDate": 1,
  "data.info.endDate": 1,
  "data.lenders.data.info.name": 1,
  "data.dealerships.data.info.displayName": 1,
  "data.info.notifyRenewalDate": 1
};
export default function (): JSX.Element {
  const slice = "table";
  const contractList = useSelector((state: RootState) => state.listContractSlice[slice]);
  const dispatch = useDispatch();
  const [requestId] = useState(uuidv4());
  const addContractState = useSelector((state: RootState) => state.addContractSlice[requestId]);
  const createOrFocusTab = useContext(TabContext);

  const [open, setOpen] = useState(false);
  const [renew, setRenew] = useState(false);
  useEffect(() => {
    if (
      addContractState !== undefined &&
      addContractState.status === "success" &&
      addContractState.data !== null
    ) {
      lockEntityFunction(dispatch, addContractState.data.message._id, "contract", requestId);
      createOrFocusTab({
        label: "Show page",
        index: "showPage",
        isForSidebar: false,
        isForQuickAccess: false,
        isPersistent: false,
        props: {
          _id: addContractState.data.message._id,
          type: "contract"
        }
      });
    }
  }, [addContractState, createOrFocusTab, dispatch, requestId]);
  const columns: Column<ContractData>[] = [
    {
      name: "name",
      getData: (entry): CellValue => entry.data.info.name ?? "",
      label: "Name",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.name?.show;
      },
      filters: [
        {
          path: ["data", "info", "name"],
          preview: TextFilter,
          label: "Name",
          name: "name",
          partialSearch: true,
          caseInsensitive: true
        }
      ]
    },
    {
      getData: (entry): CellValue => {
        const lastestContractFile = getLatestContractFile(entry?.data?.files);
        return lastestContractFile &&
          lastestContractFile?.data.info?.contractDates &&
          lastestContractFile?.data.info?.contractDates?.signedDate
          ? new Date(lastestContractFile?.data.info.contractDates.signedDate).toLocaleDateString()
          : "—";
      },
      label: "Signed Date",
      name: "signedDate",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.signedDate?.show;
      },
      filters: [
        {
          path: ["data", "files", "data", "info", "contractDates", "signedDate"],
          preview: ContractDateFilter,
          label: "Contract Signed Date",
          name: "contractSignedDate"
        }
      ]
    },
    {
      getData: (entry): CellValue => {
        const lastestContractFile = getLatestContractFile(entry?.data?.files);
        return lastestContractFile &&
          lastestContractFile?.data.info?.contractDates &&
          lastestContractFile?.data.info?.contractDates?.startDate
          ? new Date(lastestContractFile?.data.info.contractDates.startDate).toLocaleDateString()
          : "—";
      },
      label: "Start Date",
      name: "startDate",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.startDate?.show;
      },
      filters: [
        {
          path: ["data", "files", "data", "info", "contractDates", "startDate"],
          preview: ContractDateFilter,
          label: "Contract Start Date",
          name: "contractStartDate"
        }
      ]
    },
    {
      getData: (entry): CellValue => {
        const lastestContractFile = getLatestContractFile(entry?.data?.files);
        return lastestContractFile &&
          lastestContractFile?.data.info?.contractDates &&
          lastestContractFile?.data.info?.contractDates?.endDate
          ? new Date(lastestContractFile?.data.info.contractDates.endDate).toLocaleDateString()
          : "—";
      },
      label: "End Date",
      name: "endDate",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.endDate?.show;
      },
      filters: [
        {
          path: ["data", "files", "data", "info", "contractDates", "endDate"],
          preview: ContractDateFilter,
          label: "Contract End Date",
          name: "contractEndDate"
        }
      ]
    },

    {
      getData: (entry): CellValue =>
        entry.data.info.notifyRenewal
          ? entry.data.info.notifyRenewal === 1
            ? entry.data.info.notifyRenewal + " Week"
            : entry.data.info.notifyRenewal + " Weeks"
          : "—",
      label: "Notify renewal",
      name: "notifyRenewal",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.notifyRenewal?.show;
      },
      filters: [
        {
          path: ["data", "info", "notifyRenewal"],
          preview: MultiSelectFilter,
          label: "Notify Renewal",
          name: "notifyRenewal",
          valuesForSelect: [...Array(53).keys()].slice(1),
          optionLabelForSelect: (x) => (x ? `${x} ${x > 1 ? "Weeks" : "Week"}` : "")
        }
      ]
    },
    {
      getData: (entry): CellValue => {
        const lenders = entry.data?.lenders?.map((lender) => lender?.data?.info?.name) || [];
        const dealerships =
          entry.data?.dealerships?.map((dealership) => dealership?.data?.info?.displayName) || [];
        const otherVendors =
          entry.data?.otherVendors?.map((otherVendor) => otherVendor?.data?.info?.name) || [];

        const products = entry.data?.products || [];
        const externalProviders = entry.data?.externalProviders || [];
        const lendersText = lenders.length > 0 ? `Lenders: ${lenders.join(",")}` : false;
        const dealershipsText =
          dealerships.length > 0 ? `Dealerships: ${dealerships.join(",")}` : false;
        const otherVendorsText =
          otherVendors.length > 0 ? `Other Vendors: ${otherVendors.join(",")}` : false;
        const productsText = products.length > 0 ? `Products: ${products.join(",")}` : false;
        const externalProvidersText =
          externalProviders.length > 0
            ? `External Providers: ${externalProviders.join(",")}`
            : false;

        return [lendersText, dealershipsText, otherVendorsText, productsText, externalProvidersText]
          .filter((x) => x)
          .join(" | ");
      },
      label: "Vendors",
      name: "vendors",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.vendors?.show;
      },
      filters: [
        {
          path: ["data", "dealershipIds"],
          preview: DealershipFilter,
          label: "Dealerships",
          name: "dealerships"
        },
        {
          path: ["data", "lenderIds"],
          preview: LenderFilter,
          label: "Lenders",
          name: "lenders"
        },
        {
          path: ["data", "otherVendorIds"],
          preview: OtherVendorFilter,
          label: "Other Vendors",
          name: "otherVendors"
        },
        {
          path: ["data", "products"],
          preview: MultiSelectFilter,
          label: "Products",
          valuesForSelect: ["F&I Express", "Protective", "ASC"],
          name: "products"
        },
        {
          path: ["data", "externalProviders"],
          preview: MultiSelectFilter,
          label: "External Providers",
          name: "externalProviders",
          valuesForSelect: ["Dealertrack", "Appone"]
        }
      ]
    },

    {
      getData: (entry): CellValue => entry._id,
      options: {
        customBodyRender: (contract: CellValue, contractData): JSX.Element | string => {
          const lastestContractFile = getLatestContractFile(contractData?.data?.files);
          switch (lastestContractFile?.data?.info?.status ?? "Inactive") {
            case "Active":
              return (
                <Chip
                  size="small"
                  label="Active"
                  style={{ backgroundColor: "#50a538", margin: "2px 0 2px 0" }}
                />
              );
            case "Inactive":
              return (
                <Chip
                  size="small"
                  label="Inactive"
                  style={{ backgroundColor: "#f44336", margin: "5px 0 5px 0" }}
                />
              );
            default:
              return "N/A";
          }
        }
      },
      label: "Status",
      name: "status",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.status?.show;
      },
      filters: [
        {
          path: ["data", "files", "data", "info", "status"],
          preview: ContractStatusFilter,
          label: "Contract Status",
          name: "contractStatus"
        }
      ]
    },
    {
      getData: (entry): CellValue => entry._id,
      label: "Actions",
      name: "actions",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.actions?.show;
      }
    }
  ];

  const openDialog = () => {
    setOpen(true);
  };

  const addFunction = () => {
    setOpen(false);
    createOrFocusTab({
      label: "Add page",
      index: "addPage",
      isForSidebar: false,
      isForQuickAccess: false,
      isPersistent: false,
      props: { type: "contract" }
    });
  };
  const renewFunction = () => {
    setOpen(false);
    setRenew(true);
  };

  return (
    <>
      <RenewContractDialog
        renewFunction={renewFunction}
        addFunction={addFunction}
        open={open}
        setOpen={setOpen}
      />
      <div style={{ position: "relative" }}>
        <AccessControl requiredPermissions={{ entity: "contract", action: "read" }}>
          <Table
            tableName="contracts"
            slice={slice}
            entityName="contract"
            addEntityFunction={openDialog}
            listFunction={getContractList}
            listEntity={contractList}
            deleteEntityFunction={deleteContract}
            recoverEntityFunction={recoverContract}
            sort={{ createdAt: "desc" }}
            title={"Contracts"}
            columns={columns}
            projection={projection}
            renewMode={renew}
          />
        </AccessControl>
      </div>
    </>
  );
}
