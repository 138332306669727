import { Box, IconButton } from "@material-ui/core";
import { Save } from "@material-ui/icons";
import { HintTooltip } from "components/common/HintTooltip";
import * as ExcelJS from "exceljs";
import * as FileSaver from "file-saver";
import * as React from "react";
import formatDate from "utils/formatDate";
import { EntityType } from "../../utils/entitySlice";
import AccessControl from "../Access/AccessControl";
import { Column, Entry } from "./index";

export interface Props<T> {
  entityName: EntityType;
  entries: Entry<T>[];
  columns: Column<T>[];
}

const createExcelWorkbook = <T extends unknown>(
  sheetTitle: string,
  entries: Entry<T>[],
  columns: Column<T>[],
  withId: boolean
): ExcelJS.Workbook => {
  const filteredColumns = columns.filter((column) => column.label !== "Actions");
  const workbook = new ExcelJS.Workbook();
  const now = new Date();
  const wfd = "WFD";
  workbook.created = now;
  workbook.modified = now;
  workbook.lastPrinted = now;
  workbook.lastModifiedBy = wfd;
  workbook.creator = wfd;

  const sheet = workbook.addWorksheet(sheetTitle);
  sheet.addRow(
    [
      ...(withId ? ["id"] : []),
      ...filteredColumns.map(({ label, excelColumns }) => {
        if (excelColumns) return excelColumns;
        return label;
      })
    ].flat()
  );
  sheet.addRows(
    entries.map((entry) => {
      return [
        ...(withId ? [entry._id] : []),
        ...filteredColumns.map((column) => {
          const value = column.getData(entry, true);
          if (value instanceof Date) {
            return formatDate(value, "short");
          }
          if (typeof value === "number") {
            return parseFloat(value.toFixed(2));
          }
          return value;
        })
      ].flat();
    })
  );

  return workbook;
};

const saveExcelBuffer = async <T extends unknown>(
  title: string,
  entries: Entry<T>[],
  columns: Column<T>[],
  withId: boolean
): Promise<void> => {
  const workbook = createExcelWorkbook(title, entries, columns, withId);
  const date = new Date().toISOString();
  const filename = `${title}_export_${date}.xlsx`;
  const buffer = await workbook.xlsx.writeBuffer({ filename });
  const file = new File([buffer], filename, { type: "application/vnd.ms-excel" });
  FileSaver.saveAs(file);
};

export interface ExcelRenderingSettings<T> {
  enabled: boolean;
  headers: string[];
  renderRow: (entry: Entry<T>) => string[];
}

export default <T extends unknown>({ entityName, entries, columns }: Props<T>): JSX.Element => {
  return (
    <AccessControl
      key={`export-${entityName}-button`}
      requiredPermissions={{ entity: entityName, action: "read" }}
    >
      <Box component="span">
        <HintTooltip title="Export to excel file. Right click if you want to export with internal ids.">
          <IconButton
            aria-label={`add-${entityName}`}
            id={`export-${entityName}`}
            onClick={async (): Promise<void> => {
              await saveExcelBuffer(entityName, entries, columns, false);
            }}
            onContextMenu={async (e): Promise<void> => {
              e.preventDefault();
              await saveExcelBuffer(entityName, entries, columns, true);
            }}
          >
            <Save />
          </IconButton>
        </HintTooltip>
      </Box>
    </AccessControl>
  );
};
