import { DateState } from "components/LenderDecisions/LenderDecisionsStatistics/DateFilterLenderDecisions";
import DateFilter from "components/LenderDecisions/LenderDecisionsStatistics/DateFilterLenderDecisions";
import useGetMixPanelReports from "hooks/getMixPanelReports/getMixPanelReports";
import { BBDeals, MixPanelQuery } from "hooks/getMixPanelReports/types";
import { TableRow, TableSortLabel, withStyles } from "@material-ui/core";
import { getFirstAndLastDateOfCurrentMonth } from "utils/functions";
import "./styles.css";
import { useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import Paper from "@material-ui/core/Paper";
import React from "react";
import { eventsCountType } from "./types";
import { makeStyles } from "@material-ui/core/styles";

import { useStickyState } from "index";
import { LoopIconWrapper } from "components/Loader/TextLoop";

const useStyles = makeStyles({
  sortLabel: {
    '&:hover:not([class*="active"])': {
      "& svg": {
        opacity: "0 !important"
      }
    }
  }
});

export const MixPanelReportsTable = (): any => {
  const [dateFilter, setDateFilter] = useStickyState<DateState>(
    {
      from: new Date(getFirstAndLastDateOfCurrentMonth()?.from),
      to: new Date()
    },
    "mixpanel-reports-date-filter",
    (data: { from: string; to: string }) => {
      return {
        from: new Date(data.from),
        to: new Date(data.to)
      };
    }
  );
  const { data, isFetching, error } = useGetMixPanelReports({
    fromDate: dateFilter?.from
      ? `${dateFilter?.from?.getFullYear?.()}-${
          dateFilter?.from?.getMonth?.() + 1
        }-${dateFilter?.from?.getDate?.()}`
      : undefined,
    toDate: dateFilter?.to
      ? `${dateFilter?.to?.getFullYear?.()}-${
          dateFilter?.to?.getMonth?.() + 1
        }-${dateFilter?.to?.getDate?.()}`
      : undefined
  });
  return (
    <div>
      <div
        style={{
          display: "flex",
          gap: "25px",
          alignItems: "center",
          justifyContent: "space-between"
        }}
      >
        <div style={{ display: "flex" }}>
          <h2>Mixpanel Reports</h2>
        </div>
        <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
          <div style={{ width: "auto" }}>
            <DateFilter filter={dateFilter} setFilter={setDateFilter} />
          </div>
        </div>
      </div>
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <TableComponent data={data} isFetching={isFetching} error={error} />
      </div>
    </div>
  );
};
const applicationsRegexWeb = [
  "Visited individual Applications page",
  "Visited page Applications",
  "Visited page Dashboard",
  "Deals checked",
  "Visited dashboard",
  "Visited application"
];
const applicationsRegexMobile = [
  "Visited: /application",
  "Visited: /dashboard",
  "Visited: /applications",
  "Visited: /application/progress"
];
const TableComponent = ({
  data,
  isFetching,
  error
}: {
  data: MixPanelQuery | undefined;
  isFetching: boolean;
  error: Error | null;
}) => {
  const classes = useStyles();

  const [sortColumn, setSortColumn] = useState<string | null>(null);
  const [sortOrder, setSortOrder] = useState<"asc" | "desc" | null>(null);
  const dealersArray = data
    ? Object.keys(data?.allApps).map((dealer) => ({
        dealer,
        ...data?.allApps[dealer]
      }))
    : [];

  const mergeEvents = (events: eventsCountType, eventFilter: string[]) => {
    const regex = new RegExp(`^(${eventFilter.join("|")})`, "i");
    const dynamicEvents = Object?.keys(events ?? {})?.filter((x) => regex.test(x));
    const allMatchingEventsCount = dynamicEvents.reduce(
      (acc, curr) => acc + (events?.[curr] ?? 0),
      0
    );

    return allMatchingEventsCount;
  };

  const handleSort = (column: string) => {
    if (sortColumn === column) {
      if (sortOrder === "asc") {
        setSortOrder("desc");
      } else if (sortOrder === "desc") {
        setSortOrder(null);
        setSortColumn(null);
      }
    } else {
      // Sort by new column, default to ascending order
      setSortColumn(column);
      setSortOrder("asc");
    }
  };
  const sortData = (
    data: {
      mobileApp: {
        events: string[];
        totalEvents: number;
        eventOccurences: {
          [key: string]: number;
        };
      };
      webApp: {
        events: string[];
        totalEvents: number;
        eventOccurences: {
          [key: string]: number;
        };
      };
      blackBird: BBDeals;
      dealer: string;
    }[],
    column: string | null,
    order: "asc" | "desc" | null
  ) => {
    if (!column || !order) return data;
    // Flatten users across dealerships
    // Sort flattened users
    return data.sort((a, b) => {
      const valA = getSortingValue(a, column);
      const valB = getSortingValue(b, column);

      if (valA < valB) return order === "asc" ? -1 : 1;
      if (valA > valB) return order === "asc" ? 1 : -1;
      return 0; // Stable fallback
    });
  };
  const getSortingValue = (
    data: {
      mobileApp: {
        events: string[];
        totalEvents: number;
        eventOccurences: {
          [key: string]: number;
        };
      };
      webApp: {
        events: string[];
        totalEvents: number;
        eventOccurences: {
          [key: string]: number;
        };
      };
      blackBird: BBDeals;
      dealer: string;
    },
    column: string
  ): number | string => {
    const webAppOccurences = data?.webApp?.eventOccurences;
    const mobileAppOccurences = data?.mobileApp?.eventOccurences;
    const blackBird = data?.blackBird;
    switch (column) {
      case "Dealership Name":
        return data?.dealer?.toLowerCase() || "";
      case "All Events Count (Web)":
        return data?.webApp?.totalEvents || 0;
      case "Deals Checked (Web)":
        return mergeEvents(webAppOccurences, applicationsRegexWeb);
      case "Deal Created (Web)":
        return webAppOccurences?.["Deal created"] || 0;
      case "AI Parser Used (Web)":
        return webAppOccurences?.["AI Parser used"] || 0;
      case "Document uploaded before signing (Web)":
        return webAppOccurences?.["Document uploaded before signing"] || 0;
      case "All Events Count (Mobile)":
        return data?.mobileApp?.totalEvents || 0;
      case "Deals Checked (Mobile)":
        return mergeEvents(mobileAppOccurences, applicationsRegexMobile);
      case "Deal Created (Mobile)":
        return mobileAppOccurences?.["Deal created"] || 0;
      case "Document uploaded before signing (Mobile)":
        return mobileAppOccurences?.["Document uploaded before signing"] || 0;
      case "Push notification clicked (Mobile)":
        return mobileAppOccurences?.["Push notification clicked."] || 0;
      case "Deals from widget (BB)":
        return blackBird?.online_application || 0;
      case "Deals created by managers (BB)":
        return blackBird?.web_finance_direct || 0;
      case "AI Parser (BB)":
        return blackBird?.["AI-Parser"] || 0;
      default:
        return 0;
    }
  };
  const returnDataRows = () => {
    return isFetching && !data ? (
      <TableCell
        className="cell-styles"
        colSpan={14}
        style={{ fontSize: "20px", fontWeight: "800", textAlign: "center" }}
      >
        Loading... <LoopIconWrapper />
      </TableCell>
    ) : !error ? (
      sortData(dealersArray, sortColumn, sortOrder).map((arr) => {
        const eventOccurencesWeb = arr?.webApp?.eventOccurences;
        const eventOccurencesMobile = arr?.mobileApp?.eventOccurences;
        return (
          <StyledTableRow key={arr.dealer} className="cell-styles">
            <TableCell className="cell-styles">{arr?.dealer}</TableCell>
            <TableCell className="cell-styles">{arr?.webApp?.totalEvents ?? 0}</TableCell>
            <TableCell className="cell-styles">
              {mergeEvents(eventOccurencesWeb, applicationsRegexWeb)}
            </TableCell>
            <TableCell className="cell-styles">
              {eventOccurencesWeb?.["Deal created"] ?? 0}
            </TableCell>
            <TableCell className="cell-styles">
              {eventOccurencesWeb?.["AI Parser used"] ?? 0}
            </TableCell>
            <TableCell className="cell-styles">
              {eventOccurencesWeb?.["Document uploaded before signing"] ?? 0}
            </TableCell>

            <TableCell className="cell-styles">{arr?.mobileApp?.totalEvents ?? 0}</TableCell>
            <TableCell className="cell-styles">
              {mergeEvents(eventOccurencesMobile, applicationsRegexMobile)}
            </TableCell>
            <TableCell className="cell-styles">
              {eventOccurencesMobile?.["Deal created"] ?? 0}
            </TableCell>
            <TableCell className="cell-styles">
              {eventOccurencesMobile?.["Document uploaded before signing"] ?? 0}
            </TableCell>
            <TableCell className="cell-styles">
              {eventOccurencesWeb?.["Push notification clicked."] ?? 0}
            </TableCell>
            <TableCell className="cell-styles">{arr?.blackBird?.online_application ?? 0}</TableCell>
            <TableCell className="cell-styles">{arr?.blackBird?.web_finance_direct ?? 0}</TableCell>
            <TableCell className="cell-styles">{arr?.blackBird?.["AI-Parser"] ?? 0}</TableCell>
          </StyledTableRow>
        );
      })
    ) : (
      <TableRow>
        <TableCell
          className="cell-styles"
          colSpan={14}
          style={{ fontSize: "20px", fontWeight: "800", textAlign: "center" }}
        >
          No data found for this period
        </TableCell>
      </TableRow>
    );
  };
  return (
    <TableContainer
      component={Paper}
      style={{ overflowX: "visible", maxHeight: "auto", position: "relative" }}
    >
      <Table
        style={{
          tableLayout: "fixed",
          width: "100%",
          fontWeight: "bold"
        }}
        stickyHeader
      >
        <TableHead
          style={{
            position: "sticky",
            top: 0,
            padding: "10px",
            borderBottom: "1px solid #ddd",
            width: "100%",
            zIndex: 10
          }}
        >
          <TableRow>
            <TableCell colSpan={1} style={{ width: "auto" }}></TableCell>
            <TableCell colSpan={5} align="left" style={{ width: "auto" }}>
              Dealer Web App
            </TableCell>
            <TableCell colSpan={5} align="left" style={{ width: "auto" }}>
              Dealer Mobile App
            </TableCell>
            <TableCell colSpan={3} align="left" style={{ width: "auto" }}>
              Blackbird
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <TableSortLabel
                className={classes.sortLabel}
                active={sortColumn === "Dealership Name"}
                direction={sortOrder ?? undefined}
                onClick={() => handleSort("Dealership Name")}
              >
                Dealership Name
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                className={classes.sortLabel}
                active={sortColumn === "All Events Count (Web)"}
                direction={sortOrder ?? undefined}
                onClick={() => handleSort("All Events Count (Web)")}
              >
                All Events Count
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                className={classes.sortLabel}
                active={sortColumn === "Deals Checked (Web)"}
                direction={sortOrder ?? undefined}
                onClick={() => handleSort("Deals Checked (Web)")}
              >
                Deals Checked
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                className={classes.sortLabel}
                active={sortColumn === "Deal Created (Web)"}
                direction={sortOrder ?? undefined}
                onClick={() => handleSort("Deal Created (Web)")}
              >
                Deals Created
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                className={classes.sortLabel}
                active={sortColumn === "AI Parser Used (Web)"}
                direction={sortOrder ?? undefined}
                onClick={() => handleSort("AI Parser Used (Web)")}
              >
                AI Parser
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                className={classes.sortLabel}
                active={sortColumn === "Document uploaded before signing (Web)"}
                direction={sortOrder ?? undefined}
                onClick={() => handleSort("Document uploaded before signing (Web)")}
              >
                Documents Uploaded
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                className={classes.sortLabel}
                active={sortColumn === "All Events Count (Mobile)"}
                direction={sortOrder ?? undefined}
                onClick={() => handleSort("All Events Count (Mobile)")}
              >
                All Events Count
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                className={classes.sortLabel}
                active={sortColumn === "Deals Checked (Mobile)"}
                direction={sortOrder ?? undefined}
                onClick={() => handleSort("Deals Checked (Mobile)")}
              >
                Deal Checked
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                className={classes.sortLabel}
                active={sortColumn === "Deal Created (Mobile)"}
                direction={sortOrder ?? undefined}
                onClick={() => handleSort("Deal Created (Mobile)")}
              >
                Deal Created
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                className={classes.sortLabel}
                active={sortColumn === "Document uploaded before signing (Mobile)"}
                direction={sortOrder ?? undefined}
                onClick={() => handleSort("Document uploaded before signing (Mobile)")}
              >
                Documents Uploaded
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                className={classes.sortLabel}
                active={sortColumn === "Push notification clicked (Mobile)"}
                direction={sortOrder ?? undefined}
                onClick={() => handleSort("Push notification clicked (Mobile)")}
              >
                Opened Push Notification
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                className={classes.sortLabel}
                active={sortColumn === "Deals from widget (BB)"}
                direction={sortOrder ?? undefined}
                onClick={() => handleSort("Deals from widget (BB)")}
              >
                Deals From Widget
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                className={classes.sortLabel}
                active={sortColumn === "Deals created by managers (BB)"}
                direction={sortOrder ?? undefined}
                onClick={() => handleSort("Deals created by managers (BB)")}
              >
                Deals Created By Managers
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                className={classes.sortLabel}
                active={sortColumn === "AI Parser (BB)"}
                direction={sortOrder ?? undefined}
                onClick={() => handleSort("AI Parser (BB)")}
              >
                AI Parser
              </TableSortLabel>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{returnDataRows()}</TableBody>
      </Table>
    </TableContainer>
  );
};
const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover
    }
  }
}))(TableRow);

export default MixPanelReportsTable;
