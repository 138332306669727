import { createSocketMessageSlice } from "components/Middlewares/socketMessageSliceCreator";
import { FormState } from "./types";

interface NetsuiteDealStatusRequest {
  _id: string;
  data: FormState;
}

export interface EditNetsuiteDealStatusResponse {
  message: string;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  NetsuiteDealStatusRequest,
  EditNetsuiteDealStatusResponse
>("EditNetsuiteDealStatus", {
  request: "update_netsuite_deal_status"
});

export const editNetsuiteDealStatusActions = socketMessageSlice.actions;
export const editNetsuiteDealStatus = socketAction;
export default socketMessageSlice.reducer;
