import React from "react";

export default ({
  clusterColor,
  sizeOfCluster
}: {
  clusterColor: string;
  sizeOfCluster: string;
}) => {
  return (
    <div style={{ height: "60px", width: "60px" }}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240">
        <circle fill={clusterColor} cx="120" cy="120" r="80" opacity="0.3" />
        <circle fill={clusterColor} cx="120" cy="120" r="70" opacity="0.5" />
        <circle fill={clusterColor} cx="120" cy="120" r="55" opacity="0.8" />
        <text x="120" y="140" fontSize="50" fontWeight="bold" textAnchor="middle" fill="white">
          {sizeOfCluster}
        </text>
      </svg>
    </div>
  );
};
