import {
  Badge,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  MenuItem,
  Paper,
  Radio,
  Select,
  TextField,
  Tooltip,
  Typography
} from "@material-ui/core";
import { Help, History } from "@material-ui/icons";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import CancelCircleIcon from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ImportContacts from "@material-ui/icons/ImportContacts";
import InfoIcon from "@material-ui/icons/Info";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import MailIcon from "@material-ui/icons/Mail";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { RootState } from "app/rootReducer";
import CloseDialogButton from "components/common/CloseDialogButton";
import { HintTooltip } from "components/common/HintTooltip";
import formEditContext from "components/Content/FormEditContext";
import { Deal } from "components/Deals/types";
import { getDefaultStipulationList } from "components/DefaultStipulations/listDefaultStipulationSlice";
import { DefaultStipulation } from "components/DefaultStipulations/types";
import { useNotifyDealerStipulations } from "hooks/useNotifyDealerEmails/useNotifyDealerEmails";
import { useSnackbar } from "notistack";
import React from "react";
import { MdOutlineCloudUpload } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import formatDate from "utils/formatDate";
import { capitalize } from "utils/functions";
import { v4 as uuidv4 } from "uuid";
import { getEnv } from "validations/src/helpers";
import { storage } from "../../firebase/firebase";
import { addStipulation } from "./addStipulationSlice";
import { deleteStipulation } from "./deleteStipulationSlice";
import { editStipulation } from "./editStipulationSlice";
import { getStipulationsList, listStipulationsActions } from "./listStipulationsSlice";
import { Stipulation, StipulationStatus } from "./types";
import { makeStyles, Theme } from "@material-ui/core/styles";
import DeletedStipulationsDialog from "./DeletedStipulationsDialog";
import UploadedFilesDialog from "./UploadedFilesDialog";

const useStyles = makeStyles((theme: Theme) => ({
  customBadge: () => ({
    fontSize: "14px",
    fontWeight: "bold",
    marginRight: "-3px"
  })
}));
export const getStipulationStatusColor = (status: StipulationStatus) => {
  switch (status) {
    case StipulationStatus.PENDING:
    case StipulationStatus.AWAITING_UPLOAD:
      return "#ffcc00";
    case StipulationStatus.REJECTED:
      return "#cc3300";
    case StipulationStatus.APPROVED:
      return "#339900";
  }
};
const getWidgetURL = (dealId: string, applicantPath: "applicant" | "coApplicant") => {
  switch (getEnv()) {
    case "production":
      return `https://widget.webfinancedirect.com/stipulations/${dealId}/${applicantPath}`;
    case "stable":
      return `https://wfd-white-label-stable.web.app/stipulations/${dealId}/${applicantPath}`;
    case "development":
      return `https://wfd-white-label-dev.web.app/stipulations/${dealId}/${applicantPath}`;
    default:
      return `https://widget.webfinancedirect.com/stipulations/${dealId}/${applicantPath}`;
  }
};
interface Props {
  deal: Deal;
}

export default function StipulationsPreview({ deal }: Props): JSX.Element {
  const assignedDealershipEmails = deal?.data?.info?.assignedDealershipEmails || [];
  const hasCoApplicant = deal?.data?.coApplicant?._id !== undefined;
  const classes = useStyles();

  const dealId = deal._id;
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [addRequestId, setAddRequestId] = React.useState(uuidv4());
  const [deleteRequestId] = React.useState(uuidv4());
  const [uploadsInProgress, setUploadsInProgress] = React.useState<{ [id: string]: boolean }>({});
  const [timeout, setTimeoutState] = React.useState<undefined | NodeJS.Timeout>();
  const [deletedDialogOpen, setDeletedDialogOpen] = React.useState(false);
  const {
    notifyDealerStipulations,
    loading: notifyDealerStipulationsLoading
  } = useNotifyDealerStipulations();
  const addStipulationState = useSelector(
    (state: RootState) => state.addStipulationSlice[addRequestId]
  );
  const listStipulationsState = useSelector(
    (state: RootState) => state.listStipulationSlice[dealId]
  );
  const listDefaultStipulationsSlice = useSelector(
    (state: RootState) => state.listDefaultStipulationSlice[dealId]
  );
  const allEditStipulationStates = useSelector((state: RootState) => state.editStipulationSlice);
  const editStipulationStates = listStipulationsState?.entities?.map((stipulation) => ({
    _id: stipulation._id,
    state: allEditStipulationStates[stipulation._id]
  }));
  const deleteStipulationState = useSelector(
    (state: RootState) => state.deleteStipulationSlice[deleteRequestId]
  );
  const currentUser = useSelector((state: RootState) => state?.authSlice?.user?.databaseData);

  const isLoading =
    addStipulationState?.status === "waiting" || deleteStipulationState?.status === "waiting";

  const { enabled: editMode, edited: dealEdited } = React.useContext(formEditContext);

  const [selectedStipulation, setSelectedStipulation] = React.useState<Stipulation | undefined>();

  const [selectedRejectedURL, setSelectedRejectedURL] = React.useState<
    | {
        url: string;
        uploadedAt?: string;
        uploadedBy?: "applicant" | "coApplicant" | "dealer" | "manager";
      }
    | undefined
  >();
  React.useEffect(() => {
    if (!listStipulationsState)
      dispatch(
        getStipulationsList(dealId, {
          query: { "data.dealId": dealId },
          options: { limit: 1000, sort: { createdAt: -1 } },
          withDeleted: true
        })
      );
  }, [dispatch]);
  React.useEffect(() => {
    if (!listDefaultStipulationsSlice)
      dispatch(
        getDefaultStipulationList(dealId, {
          query: { deleted: false },
          options: { sort: { "data.info.description": 1 }, pagination: false }
        })
      );
  }, [dispatch]);

  const handleAdd = () => {
    const defaultStipulation = {
      data: {
        dealId,
        info: {
          description: "",
          canBeUploadedBy: ["applicant", ...(hasCoApplicant ? ["coApplicant"] : []), "dealer"],
          status: StipulationStatus.AWAITING_UPLOAD,
          createdBy: "manager",
          user: {
            firstName: currentUser?.data?.info?.firstName || null,
            lastName: currentUser?.data?.info?.lastName || null,
            email: currentUser?.data?.info?.email || null
          }
        }
      }
    };
    setAddRequestId(uuidv4());
    dispatch(addStipulation({ requestId: addRequestId, ...(defaultStipulation as Stipulation) }));
    setExpanded(true);
  };

  const handleDelete = (id: string) => {
    dispatch(deleteStipulation({ requestId: deleteRequestId, _id: id }));
  };
  const handleChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    stipulation: Stipulation & { index: number }
  ) => {
    const newStipulation = {
      ...stipulation,
      data: {
        ...stipulation.data,
        info: { ...stipulation.data.info, description: event.target.value }
      },
      updatedAt: new Date().toISOString()
    };
    dispatch({
      type: listStipulationsActions.editList.type,
      payload: { index: stipulation.index, payload: newStipulation, listId: dealId }
    });
    return newStipulation as Stipulation & { index: number };
  };

  const handleRadioChange = (value: string, stipulation: Stipulation & { index: number }) => {
    const canBeUploadedBy = stipulation.data.info.canBeUploadedBy?.find((x) => x === value)
      ? stipulation.data.info.canBeUploadedBy.filter((x) => x !== value)
      : ([...(stipulation.data.info.canBeUploadedBy || []), value] as (
          | "applicant"
          | "coApplicant"
          | "dealer"
        )[]);

    const newStipulation = {
      ...stipulation,
      data: {
        ...stipulation.data,
        info: { ...stipulation.data.info, canBeUploadedBy }
      },
      updatedAt: new Date().toISOString()
    };
    dispatch({
      type: listStipulationsActions.editList.type,
      payload: { index: stipulation.index, payload: newStipulation, listId: dealId }
    });
    return newStipulation;
  };

  const handleUpdate = (stipulation: Stipulation) => {
    dispatch(editStipulation({ requestId: stipulation._id, ...stipulation }));
  };
  const handleUploadStipulation = (
    event: React.ChangeEvent<HTMLInputElement>,
    stipulation: Stipulation & { index: number }
  ) => {
    setUploadsInProgress((prevUploads) => ({
      ...prevUploads,
      [stipulation._id]: true
    }));
    const uploadedFiles = event.target.files ?? [];

    if (uploadedFiles.length !== 0) {
      const file = uploadedFiles[0];
      const firebaseFileName = `${uuidv4()}-${file.name}`;

      const uploadTask = storage.ref(`/files/Deal/${dealId}/${firebaseFileName}`).put(file);

      uploadTask.on(
        "state_changed",
        (_snapShot) => {},
        (err) => {
          setUploadsInProgress((prevUploads) => ({
            ...prevUploads,
            [stipulation._id]: false
          }));
          enqueueSnackbar(err.message, {
            variant: "error"
          });
        },
        () => {
          const ref = storage.ref(`/files/Deal/${dealId}`).child(firebaseFileName);

          ref.getDownloadURL().then((fireBaseUrl) => {
            ref.getMetadata().then((metadata) => {
              setUploadsInProgress((prevUploads) => ({
                ...prevUploads,
                [stipulation._id]: false
              }));
              const editedStipulation = {
                ...stipulation,
                fileName: file.name,
                metadata,
                data: {
                  ...stipulation.data,
                  info: {
                    ...stipulation.data.info,
                    uploadedAt: new Date().toISOString(),
                    ...(stipulation?.data?.info?.url
                      ? {
                          rejectedUrls: [
                            ...(stipulation?.data?.info?.rejectedUrls || []),
                            {
                              url: stipulation?.data?.info?.url,
                              uploadedAt: stipulation?.data?.info?.uploadedAt,
                              uploadedBy: stipulation?.data?.info?.uploadedBy
                            }
                          ]
                        }
                      : {}),
                    uploadedBy: "manager" as const,
                    url: fireBaseUrl,
                    status: StipulationStatus.PENDING
                  }
                }
              };

              dispatch({
                type: listStipulationsActions.editList.type,
                payload: { index: stipulation.index, payload: editedStipulation, listId: dealId }
              });
              dispatch(editStipulation({ requestId: stipulation._id, ...editedStipulation }));
            });
          });
        }
      );
    }
  };

  const acceptOrRejectStupulation = (stipulation: Stipulation, action: StipulationStatus) => {
    if (stipulation) {
      const newStipulation = {
        ...stipulation,
        data: {
          ...stipulation.data,
          info: {
            ...stipulation.data.info,
            url: action === StipulationStatus.APPROVED ? stipulation.data.info.url : null,
            rejectedUrls:
              action === StipulationStatus.REJECTED && stipulation.data.info.url
                ? [
                    ...(stipulation.data.info.rejectedUrls || []),
                    {
                      url: stipulation.data.info.url,
                      uploadedAt: stipulation.data.info.uploadedAt,
                      uploadedBy: stipulation.data.info.uploadedBy
                    }
                  ]
                : stipulation.data.info.rejectedUrls,
            status: action
          }
        }
      };
      handleUpdate(newStipulation);
    }
  };

  const handleNotify = () => {
    const stipulationsForDealer = (listStipulationsState?.entities?.filter(
      (s) =>
        s?.data?.info?.canBeUploadedBy?.includes("dealer") && !s?.data?.info?.notificationSendDate
    ) || []) as Stipulation[];

    if (!assignedDealershipEmails?.length && stipulationsForDealer.length > 0) {
      enqueueSnackbar("Please choose emails in the dealership section first!", {
        variant: "error"
      });
      return;
    }
    if (dealEdited) {
      enqueueSnackbar("Please save the deal before sending the email!", {
        variant: "error"
      });
      return;
    }
    const stipulationsForApplicant = (listStipulationsState?.entities?.filter(
      (s) =>
        s?.data?.info?.canBeUploadedBy?.includes("applicant") &&
        !s?.data?.info?.notificationSendDate
    ) || []) as Stipulation[];

    const stipulationsForCoApplicant = (listStipulationsState?.entities?.filter(
      (s) =>
        s?.data?.info?.canBeUploadedBy?.includes("coApplicant") &&
        !s?.data?.info?.notificationSendDate
    ) || []) as Stipulation[];

    if (stipulationsForApplicant.length > 0 && !deal.data.applicant?.data.info.email) {
      enqueueSnackbar("Please add email to the applicant info before sending emails.", {
        variant: "error"
      });
      return;
    }

    if (stipulationsForCoApplicant.length > 0 && !deal.data.coApplicant?.data.info.email) {
      enqueueSnackbar("Please add email to the applicant info before sending emails.", {
        variant: "error"
      });
      return;
    }

    notifyDealerStipulations({
      dealId
    });
  };

  const handleShowLink = () => {
    enqueueSnackbar(
      <div>
        Applicant: {getWidgetURL(dealId, "applicant")}
        <br />
        {hasCoApplicant && `Co-Applicant: ${getWidgetURL(dealId, "coApplicant")}`}
      </div>,
      {
        variant: "info"
      }
    );
  };
  const [expanded, setExpanded] = React.useState<boolean>(false);

  // count by status
  const counts = listStipulationsState?.entities?.reduce((acc, s) => {
    if (s.deleted) {
      return acc;
    }
    acc[s.data.info.status] = (acc[s.data.info.status] || 0) + 1;
    return acc;
  }, {} as { [key: string]: number });

  const filterAndSortStipulations = (stipulations: Stipulation[], onlyDeleted = false) =>
    stipulations
      .map((s, index) => ({ ...s, index }))
      .filter((s) => (onlyDeleted ? s.deleted : !s.deleted))
      .sort((a, b) => new Date(b?.createdAt).getTime() - new Date(a?.createdAt).getTime());

  const resolveStipulationStatusColor = (status: string) => {
    switch (status) {
      case StipulationStatus.PENDING:
        return "#515151";
      case StipulationStatus.AWAITING_UPLOAD:
        return "#c59e00";
      case StipulationStatus.REJECTED:
        return "#cc3300";
      case StipulationStatus.APPROVED:
        return "#339900";
    }
  };
  return (
    <>
      <DeletedStipulationsDialog
        deletedStipulations={filterAndSortStipulations(listStipulationsState?.entities || [], true)}
        setSelectedRejectedURL={setSelectedRejectedURL}
        setSelectedStipulation={setSelectedStipulation}
        hasCoApplicant={hasCoApplicant}
        open={deletedDialogOpen}
        closeFn={() => setDeletedDialogOpen(false)}
      />
      <UploadedFilesDialog
        selectedRejectedURL={selectedRejectedURL}
        setSelectedRejectedURL={setSelectedRejectedURL}
        selectedStipulation={selectedStipulation}
        setSelectedStipulation={setSelectedStipulation}
        open={!!selectedStipulation}
        closeFn={() => setSelectedStipulation(undefined)}
      />

      <Paper
        elevation={0}
        style={{
          breakInside: "avoid",
          pageBreakInside: "avoid",
          transform: "translateZ(1)"
        }}
      >
        <Box style={{ display: "flex", alignItems: "center" }} key={"Stipulation"}>
          <DialogContentText
            style={{
              color: "#254e6e",
              fontSize: "19px",
              margin: "5px 0px 5px 0px",
              fontWeight: "bold"
            }}
          >
            Stipulations
          </DialogContentText>
          <HintTooltip
            style={{ color: "#254e6e", fontSize: "20px", marginLeft: 4 }}
            title={
              <div style={{ fontSize: "12px", lineHeight: "15px", marginLeft: "auto" }}>
                Autosaved / Visible to dealer / Notofication is autosend
              </div>
            }
          >
            <Help />
          </HintTooltip>

          {editMode && (
            <>
              <IconButton
                style={{
                  color: "#50A538"
                }}
                aria-label={`add new Stipulation`}
                onClick={handleAdd}
                disabled={isLoading}
              >
                <HintTooltip title={`Click here to add new Stipulation.`}>
                  {isLoading ? <CircularProgress size={19} /> : <AddCircleIcon />}
                </HintTooltip>
              </IconButton>
              <IconButton onClick={() => setDeletedDialogOpen(true)}>
                <HintTooltip title={`Click here see deleted stipulations.`}>
                  <History />
                </HintTooltip>
              </IconButton>
              <div style={{ flex: 1 }}></div>

              <HintTooltip
                title={
                  <div style={{ fontSize: "12px", lineHeight: "15px", marginLeft: "auto" }}>
                    Send this link to the applicant so he can upload the docs
                  </div>
                }
              >
                <Button
                  variant="outlined"
                  style={{ fontSize: "10px", marginLeft: "auto", padding: 5, height: 30 }}
                  onClick={handleShowLink}
                >
                  Show applicant link
                </Button>
              </HintTooltip>
              <HintTooltip
                title={
                  <div style={{ fontSize: "12px", lineHeight: "15px", marginLeft: "auto" }}>
                    You should first select emails to notify in order to send emails to the
                    dealership
                  </div>
                }
              >
                <Button
                  variant="outlined"
                  style={{ fontSize: "10px", marginLeft: "10px", padding: 5, height: 30 }}
                  onClick={handleNotify}
                >
                  {notifyDealerStipulationsLoading ? "Loading" : "Notify selected persons"}
                </Button>
              </HintTooltip>
            </>
          )}
        </Box>
        {filterAndSortStipulations(listStipulationsState?.entities || []).length > 0 && (
          <HintTooltip
            title={
              <div style={{ fontSize: "12px", lineHeight: "15px", marginLeft: "auto" }}>
                Expand / Collapse
              </div>
            }
          >
            <Button
              style={{
                alignItems: "center",
                justifyContent: "center",
                background: "#f5f5f5",
                fontSize: "14px",
                color: "#254e6e",
                padding: "5px 20px",
                fontWeight: "bold",
                marginBottom: 10,
                display: "flex",
                width: "100%"
              }}
              onClick={() => {
                setExpanded(!expanded);
              }}
            >
              {Object.entries(counts || {})
                // Order: AWAITING_UPLOAD, PENDING ,REJECTED, APPROVED
                .sort((a, b) =>
                  a[0] === StipulationStatus.AWAITING_UPLOAD
                    ? -1
                    : a[0] === StipulationStatus.PENDING
                    ? b[0] === StipulationStatus.AWAITING_UPLOAD
                      ? 1
                      : -1
                    : a[0] === StipulationStatus.REJECTED
                    ? b[0] === StipulationStatus.APPROVED
                      ? -1
                      : 1
                    : 1
                )
                .map(([key, value]) => (
                  <span
                    key={key}
                    style={{
                      color: resolveStipulationStatusColor(key)
                    }}
                  >
                    {capitalize(key)}: {value}
                  </span>
                ))
                /// add separator between elements
                .reduce((acc, x, index, arr) => {
                  acc.push(x);
                  if (index < arr.length - 1) {
                    acc.push(
                      <span style={{ margin: "0px 5px" }} key={index}>
                        |
                      </span>
                    );
                  }
                  return acc;
                }, [] as JSX.Element[])}

              {expanded ? (
                <>
                  <span
                    style={{
                      fontSize: 16,
                      marginLeft: "auto",
                      display: "block",
                      float: "right"
                    }}
                  >
                    ˄
                  </span>
                </>
              ) : (
                <>
                  <span
                    style={{
                      fontSize: 16,
                      marginLeft: "auto",
                      display: "block",
                      float: "right"
                    }}
                  >
                    ˅
                  </span>
                </>
              )}
            </Button>
          </HintTooltip>
        )}
        {expanded &&
          filterAndSortStipulations(listStipulationsState?.entities || [])?.map(
            (stipulation, index) => {
              const state = editStipulationStates?.find((state) => state._id === stipulation._id)
                ?.state;
              const error = !stipulation?.data?.info?.description;
              return (
                <Paper
                  key={stipulation._id}
                  style={{
                    position: "relative",
                    padding: "10px",
                    marginBottom: "20px",
                    display: "block",
                    background: state?.status === "error" ? "#ffe3e3" : undefined
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "5px",
                      borderRadius: "5px",
                      position: "absolute",
                      right: "10px",
                      background: getStipulationStatusColor(
                        stipulation?.data?.info?.status ?? StipulationStatus.AWAITING_UPLOAD
                      )
                    }}
                  >
                    <Typography
                      variant="subtitle2"
                      style={{ textAlign: "center", whiteSpace: "nowrap" }}
                    >
                      {capitalize(
                        stipulation?.data?.info?.status ?? StipulationStatus.AWAITING_UPLOAD
                      )}
                    </Typography>
                  </div>
                  <Typography
                    variant="subtitle2"
                    style={{ textAlign: "left", whiteSpace: "nowrap" }}
                  >
                    Can be uploaded by
                  </Typography>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between"
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "row"
                      }}
                    >
                      {(["applicant", ...(hasCoApplicant ? ["coApplicant"] : []), "dealer"] as (
                        | "applicant"
                        | "coApplicant"
                        | "dealer"
                      )[]).map((x) => {
                        return (
                          <FormControlLabel
                            style={{ whiteSpace: "nowrap", fontSize: "12px" }}
                            key={`${stipulation._id}_${x}`}
                            id={`${stipulation._id}_${x}`}
                            control={
                              <Checkbox
                                color="primary"
                                disabled={(editMode !== undefined && !editMode) || isLoading}
                                id="ignore-duplicate-checkbox"
                                checked={stipulation?.data?.info?.canBeUploadedBy?.includes(x)}
                                onChange={() => {
                                  const newStipulation = handleRadioChange(x, stipulation);
                                  handleUpdate(newStipulation);
                                }}
                              />
                            }
                            label={x === "coApplicant" ? "Co-Applicant" : capitalize(x)}
                          />
                        );
                      })}
                    </div>
                  </div>
                  <Typography
                    variant="subtitle2"
                    style={{ textAlign: "left", whiteSpace: "nowrap" }}
                  >
                    Document owner
                  </Typography>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      marginBottom: "10px"
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "row"
                      }}
                    >
                      {(["applicant", ...(hasCoApplicant ? ["coApplicant"] : [])] as (
                        | "applicant"
                        | "coApplicant"
                      )[]).map((x) => {
                        return (
                          <FormControlLabel
                            key={`${stipulation._id}_${x}`}
                            id={`${stipulation._id}_${x}`}
                            control={
                              <Radio
                                disabled={(editMode !== undefined && !editMode) || isLoading}
                                checked={stipulation?.data?.info?.owner === x}
                                color="primary"
                                onChange={() => {
                                  handleUpdate({
                                    ...stipulation,
                                    data: {
                                      ...stipulation.data,
                                      info: {
                                        ...stipulation.data.info,
                                        owner: x
                                      }
                                    }
                                  });
                                }}
                              />
                            }
                            label={x === "coApplicant" ? "Co-Applicant" : capitalize(x)}
                          />
                        );
                      })}
                    </div>
                  </div>

                  <Box display="flex" alignContent="flex-start">
                    <Box flexGrow={1}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Autocomplete
                            options={(listDefaultStipulationsSlice?.entities ?? [])
                              .filter((s) =>
                                s.data.info.owner === "coApplicant" && !deal?.data?.coApplicant?._id
                                  ? false
                                  : true
                              )
                              .filter(
                                (defaultStipulation) =>
                                  !filterAndSortStipulations(listStipulationsState?.entities || [])
                                    .map((x) => x.data.defaultStipulationId)
                                    .includes(defaultStipulation._id) ||
                                  defaultStipulation.data.info.systemName === "other"
                              )}
                            value={listDefaultStipulationsSlice?.entities?.find(
                              (x) => x._id === stipulation.data.defaultStipulationId
                            )}
                            getOptionLabel={(option) => option.data.info.description}
                            onChange={(event, newValue) => {
                              let newStipulation = stipulation as Stipulation;
                              const canBeUploadedBy = newValue?.data?.info?.canBeUploadedBy ?? [];
                              if (newValue) {
                                newStipulation = handleChange(
                                  {
                                    target: {
                                      value:
                                        newValue?.data?.info?.systemName === "other"
                                          ? ""
                                          : newValue.data.info.description
                                    }
                                  } as React.ChangeEvent<HTMLInputElement>,
                                  {
                                    ...stipulation,
                                    data: {
                                      ...stipulation.data,
                                      defaultStipulationId: newValue._id,
                                      info: {
                                        ...stipulation.data.info,
                                        canBeUploadedBy: hasCoApplicant
                                          ? canBeUploadedBy
                                          : canBeUploadedBy?.filter((x) => x !== "coApplicant"),
                                        owner: newValue?.data?.info?.owner ?? "applicant"
                                      }
                                    }
                                  }
                                );
                              }
                              if (newValue === null) {
                                newStipulation = handleChange(
                                  {
                                    target: { value: undefined as unknown }
                                  } as React.ChangeEvent<HTMLInputElement>,
                                  {
                                    ...stipulation,
                                    data: {
                                      ...stipulation.data,
                                      defaultStipulationId: undefined
                                    }
                                  }
                                );
                              }
                              handleUpdate(newStipulation);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                multiline
                                name="stipulation"
                                autoComplete="off"
                                error={error}
                                helperText={error ? "Stipulation cannot be empty" : ""}
                                inputProps={{
                                  ...params.inputProps,
                                  ...(editMode && listStipulationsState?.status !== "waiting"
                                    ? {}
                                    : { readOnly: true })
                                }}
                                InputLabelProps={{ shrink: true }}
                                placeholder="Stipulation"
                                fullWidth
                                label={"Stipulation"}
                                variant={
                                  editMode && listStipulationsState?.status !== "waiting"
                                    ? "filled"
                                    : "outlined"
                                }
                                size="small"
                              />
                            )}
                          />
                        </Grid>
                        {listDefaultStipulationsSlice?.entities?.find(
                          (x) => x?.data?.info?.systemName === "other"
                        )?._id === stipulation?.data?.defaultStipulationId && (
                          <TextField
                            style={{ marginTop: "10px" }}
                            multiline
                            onChange={(event) => {
                              if (timeout) {
                                clearTimeout(timeout);
                              }
                              const newStipulation = handleChange(event, stipulation);
                              setTimeoutState(
                                setTimeout(() => {
                                  handleUpdate(newStipulation);
                                }, 500)
                              );
                            }}
                            value={stipulation?.data?.info?.description}
                            name="stipulation"
                            error={error}
                            helperText={error ? "Stipulation cannot be empty" : ""}
                            inputProps={
                              editMode && listStipulationsState?.status !== "waiting"
                                ? {}
                                : { readOnly: true }
                            }
                            InputLabelProps={{ shrink: true }}
                            placeholder="Stipulation"
                            fullWidth
                            label={"Stipulation"}
                            variant={
                              editMode && listStipulationsState?.status !== "waiting"
                                ? "filled"
                                : "outlined"
                            }
                            size="small"
                            onBlur={(event) => {
                              if (event.target.value !== stipulation?.data?.info?.description) {
                                const newStipulation = handleChange(event, stipulation);
                                handleUpdate(newStipulation);
                              }
                            }}
                          />
                        )}
                      </Grid>
                      <Box
                        fontSize={12}
                        justifyContent="space-between"
                        display={"flex"}
                        marginTop={"10px"}
                        style={{ maxHeight: 20 }}
                      >
                        <div style={{ display: "flex", alignItems: "center", columnGap: "10px" }}>
                          {(() => {
                            switch (state?.status) {
                              case "waiting":
                                return (
                                  <div
                                    style={{ display: "flex", alignItems: "center", gap: "3px" }}
                                  >
                                    <CircularProgress
                                      size={17}
                                      style={{
                                        color: "#50A538",
                                        minHeight: 20.5,
                                        maxHeight: 20.5
                                      }}
                                    />
                                    Saving
                                  </div>
                                );
                              case "error":
                                return (
                                  <button
                                    className="pulse hover"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      border: "none",
                                      background: "#ffe3e3",
                                      fontWeight: "bold",
                                      columnGap: "3px",
                                      borderRadius: 3
                                    }}
                                    onClick={() => {
                                      handleUpdate(stipulation);
                                    }}
                                  >
                                    <CancelCircleIcon
                                      style={{
                                        color: "#E34C28"
                                      }}
                                    />
                                    Error! Click here to retry!
                                  </button>
                                );
                              default:
                                return (
                                  <div
                                    style={{ display: "flex", alignItems: "center", gap: "3px" }}
                                  >
                                    <CheckCircleIcon
                                      style={{
                                        color: "#50A538"
                                      }}
                                    />
                                    Saved
                                  </div>
                                );
                            }
                          })()}
                        </div>
                        <Tooltip
                          title={
                            <span
                              style={{ fontSize: 15, whiteSpace: "nowrap", lineHeight: "20px" }}
                            >
                              {stipulation?.data?.info?.notifiedEmails?.map((x, index) => (
                                <div key={index}>
                                  {x.type}: {x.emails?.join(",")}
                                </div>
                              ))}
                              {stipulation.data.info.viewed && (
                                <div>
                                  And viewed at{" "}
                                  {formatDate(stipulation.data.info.viewed, "short", true)}
                                </div>
                              )}
                            </span>
                          }
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "3px",
                              marginLeft: 10,
                              width: "100%"
                            }}
                          >
                            <MailIcon
                              style={{
                                color: stipulation?.data?.info?.notificationSendDate
                                  ? "#50A538"
                                  : "#E34C28"
                              }}
                            />
                            {stipulation?.data?.info?.notificationSendDate && "Sent email at "}
                            {formatDate(
                              stipulation?.data?.info?.notificationSendDate,
                              "short",
                              true
                            )}
                            {stipulation.data.info.viewed && (
                              <ImportContacts
                                style={{
                                  color: "rgb(107, 151, 246)"
                                }}
                              />
                            )}
                          </div>
                        </Tooltip>
                        <span style={{ whiteSpace: "nowrap" }}>
                          {[
                            stipulation.data?.info?.user?.firstName,
                            stipulation?.data?.info?.user?.lastName,
                            formatDate(stipulation?.createdAt, "short", true)
                          ]
                            .filter((x) => x)
                            .join(" ")}
                        </span>
                      </Box>
                    </Box>

                    <Box>
                      <Box>
                        {editMode && (
                          <Box
                            style={{
                              display: "flex",
                              alignItems: "start",
                              justifyContent: "space-between"
                            }}
                          >
                            {uploadsInProgress?.[stipulation._id] ? (
                              <CircularProgress
                                size={20}
                                style={{
                                  padding: 12,
                                  color: "#50A538"
                                }}
                              />
                            ) : (
                              <React.Fragment>
                                <IconButton
                                  disabled={stipulation?.data?.info?.url ? false : true}
                                  style={
                                    stipulation?.data?.info?.url
                                      ? {
                                          color: "#50A538"
                                        }
                                      : undefined
                                  }
                                  aria-label={`view stipulation`}
                                  onClick={() => {
                                    setSelectedRejectedURL(
                                      stipulation?.data?.info?.url
                                        ? {
                                            url: stipulation?.data?.info?.url as string,
                                            uploadedAt: stipulation?.data?.info?.uploadedAt,
                                            uploadedBy: stipulation?.data?.info?.uploadedBy
                                          }
                                        : undefined
                                    );
                                    setSelectedStipulation(stipulation);
                                  }}
                                >
                                  <Badge
                                    badgeContent={
                                      stipulation?.data?.info?.rejectedUrls?.length
                                        ? (stipulation?.data?.info?.rejectedUrls?.length || 0) +
                                          (stipulation?.data?.info?.url ? 1 : 0)
                                        : 0
                                    }
                                    color="secondary"
                                    showZero={false}
                                    classes={{ badge: classes.customBadge }}
                                  >
                                    <HintTooltip
                                      title={`Click here to view the files for the stipulation.`}
                                    >
                                      <VisibilityIcon />
                                    </HintTooltip>
                                  </Badge>
                                </IconButton>
                                <label
                                  htmlFor={`upload-stipulation-${stipulation._id}`}
                                  style={{ display: "flex" }}
                                >
                                  <input
                                    accept=".pdf"
                                    id={`upload-stipulation-${stipulation._id}`}
                                    type="file"
                                    onChange={(e) => handleUploadStipulation(e, stipulation)}
                                    hidden
                                  />
                                  <IconButton
                                    component="span"
                                    style={{
                                      color: stipulation?.data?.info?.url ? "green" : "#E34C28"
                                    }}
                                    aria-label={`edit stipulation`}
                                    disabled={state?.status === "waiting"}
                                  >
                                    <HintTooltip
                                      title={`Click here to upload the file for the stipulation.`}
                                    >
                                      <AttachFileIcon />
                                    </HintTooltip>
                                  </IconButton>
                                </label>
                              </React.Fragment>
                            )}
                            {editMode && (
                              <IconButton
                                style={{
                                  color: "#E34C28"
                                }}
                                aria-label={`remove stipulation`}
                                onClick={() => handleDelete(stipulation._id)}
                                disabled={deleteStipulationState?.status === "waiting"}
                              >
                                <HintTooltip title={`Click here to remove the stipulation.`}>
                                  <RemoveCircleIcon />
                                </HintTooltip>
                              </IconButton>
                            )}
                          </Box>
                        )}
                      </Box>
                      {stipulation.data.info.url && (
                        <Box
                          style={{
                            marginTop: -10,
                            display: "flex",
                            justifyContent: "space-between"
                          }}
                        >
                          <IconButton
                            style={{
                              color: "green"
                            }}
                            aria-label={`approve stipulation`}
                            onClick={() =>
                              acceptOrRejectStupulation(stipulation, StipulationStatus.APPROVED)
                            }
                            disabled={deleteStipulationState?.status === "waiting"}
                          >
                            <HintTooltip title={`Click here to approve the stipulation.`}>
                              <>
                                <CheckCircleIcon />
                                <span style={{ fontSize: "12px", marginLeft: "5px" }}>Approve</span>
                              </>
                            </HintTooltip>
                          </IconButton>
                          <IconButton
                            style={{
                              color: "#E34C28"
                            }}
                            aria-label={`reject stipulation`}
                            onClick={() =>
                              acceptOrRejectStupulation(stipulation, StipulationStatus.REJECTED)
                            }
                            disabled={deleteStipulationState?.status === "waiting"}
                          >
                            <HintTooltip
                              title={`Click here to reject the stipulation. This will automatically send an email to the applicant.`}
                            >
                              <>
                                <CancelCircleIcon />
                                <span style={{ fontSize: "12px", marginLeft: "5px" }}>Reject</span>
                              </>
                            </HintTooltip>
                          </IconButton>
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Paper>
              );
            }
          )}
      </Paper>
    </>
  );
}
