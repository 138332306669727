import React, { useCallback } from "react";
import {
  AdvancedMarker,
  AdvancedMarkerAnchorPoint,
  useAdvancedMarkerRef
} from "@vis.gl/react-google-maps";
import CustomClusterSvg from "./CustomClusterSvg";

type TreeClusterMarkerProps = {
  clusterId: number;
  onMarkerClick?: (marker: google.maps.marker.AdvancedMarkerElement, clusterId: number) => void;
  position: google.maps.LatLngLiteral;
  size: number;
  clusterColor: string;
  sizeAsText: string;
};

export const FeaturesClusterMarker = ({
  position,
  size,
  sizeAsText,
  onMarkerClick,
  clusterColor,
  clusterId
}: TreeClusterMarkerProps) => {
  const [markerRef, marker] = useAdvancedMarkerRef();
  const handleClick = useCallback(
    () => onMarkerClick && marker && onMarkerClick(marker, clusterId),
    [onMarkerClick, marker, clusterId]
  );
  const markerSize = Math.floor(48 + Math.sqrt(size) * 2);
  return (
    <AdvancedMarker
      ref={markerRef}
      position={position}
      zIndex={size}
      onClick={handleClick}
      style={{ width: markerSize, height: markerSize, position: "absolute" }}
      anchorPoint={AdvancedMarkerAnchorPoint.CENTER}
    >
      <CustomClusterSvg sizeOfCluster={sizeAsText} clusterColor={clusterColor} />
    </AdvancedMarker>
  );
};
