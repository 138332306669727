import axios from "axios";

export const notifyWebhooks = async (error: any) => {
  try {
    console.error(error);
    // Send message to Discord webhook
    if (process.env.REACT_APP_DISCORD_WEBHOOK_URL) {
      const discordPayload = {
        content: (typeof error === "string"
          ? error
          : error instanceof Error
          ? JSON.stringify({ error: error.toString(), trace: error.stack?.toString() })
          : JSON.stringify(error)
        ).slice(0, 1000),
        username: process.env.REACT_APP_FIREBASE_PROJECT_ID ?? ""
      };

      await fetch(process.env.REACT_APP_DISCORD_WEBHOOK_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(discordPayload)
      });
    }

    if (process.env.REACT_APP_FIREBASE_PROJECT_ID === "wfd-frontend") {
      axios.post(
        process.env.REACT_APP_SLACK_WEBHOOK_URL ?? "",
        JSON.stringify({
          username: process.env.REACT_APP_FIREBASE_PROJECT_ID ?? "",
          text: JSON.stringify(error).slice(0, 100000)
        })
      );
    }
  } catch (error) {
    console.error(error);
  }
};

export const devlog = (...loggingInfo: any[]) => {
  if (process.env.NODE_ENV === "development" && module.hot) {
    console.log(...loggingInfo);
  }
};
