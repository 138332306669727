import { RootState } from "app/rootReducer";
import ApplicantFilter from "components/Filters/ApplicantFilter";
import PriceFilter from "components/Filters/PriceFilter";
import React from "react";
import { useSelector } from "react-redux";
import { sumNumbers } from "utils/calcReportTotal";
import AccessControl from "../../Access/AccessControl";
import { deleteDeal } from "../../Deals/deleteDealSlice";
import { getDealList } from "../../Deals/listDealSlice";
import { recoverDeal } from "../../Deals/recoverDealSlice";
import { DealData } from "../../Deals/types";
import Table, { CellValue, Column } from "../../Table";
export const projections = {
  "data.applicant.data.info.firstName": 1,
  "data.applicant.data.info.lastName": 1,
  "data.dealership.data.info.displayName": 1,
  "data.info.taxesAndFees": 1,
  "data.info.aftermarketOptions": 1,
  "data.info.payment": 1,
  _id: 1,
  creatorId: 1,
  createdAt: 1,
  updatedAt: 1,
  deleted: 1
};

export default function FundingNotification(): JSX.Element {
  const dealList = useSelector((state: RootState) => state.listDealSlice["table"]);

  const columns: Column<DealData>[] = [
    {
      getData: (entry): CellValue =>
        `${entry.data?.applicant?.data?.info?.firstName ?? ""} ${
          entry.data?.applicant?.data?.info?.lastName ?? ""
        }`,
      label: "Customer Name",
      total: () => "Total:",
      name: "customer",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.customer?.show;
      },
      filters: [
        {
          path: [
            ["data", "applicant", "data", "info", "firstName"],
            ["data", "applicant", "data", "info", "lastName"]
          ],
          preview: ApplicantFilter,
          caseInsensitive: true,
          label: "Applicant",
          name: "applicant"
        }
      ]
    },
    {
      getData: (entry): CellValue => entry.data?.info?.payment?.amountFinanced ?? 0,
      label: "Amount Financed",
      total: sumNumbers,
      name: "amountFinanced",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.amountFinanced?.show;
      },
      filters: [
        {
          path: ["data", "info", "payment", "amountFinanced"],
          preview: PriceFilter,
          name: "amountFinanced",
          label: "Amount Financed"
        }
      ]
    },
    {
      getData: (entry): CellValue => entry.data?.info?.payment?.reserve ?? 0,
      label: "Dealer Reserve/Profit",
      total: sumNumbers,
      name: "reserve",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.reserve?.show;
      },
      filters: [
        {
          path: ["data", "info", "payment", "reserve"],
          preview: PriceFilter,
          name: "dealerReserveOrProfit",
          label: "Dealer Reserve/Profit"
        }
      ]
    },
    {
      getData: (entry): CellValue => entry.data?.info?.taxesAndFees?.totalMembershipFees ?? 0,
      label: "Membership Fee",
      total: sumNumbers,
      name: "totalMembershipFees",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.totalMembershipFees?.show;
      },
      filters: [
        {
          path: ["data", "info", "taxesAndFees", "totalMembershipFees"],
          preview: PriceFilter,
          name: "membershipFee",
          label: "Membership Fee"
        }
      ]
    },
    {
      getData: (entry): CellValue => entry.data?.info?.aftermarketOptions?.totalGAPSellPrice ?? 0,
      label: "Gap",
      total: sumNumbers,
      name: "totalGAPSellPrice",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.totalGAPSellPrice?.show;
      },
      filters: [
        {
          path: ["data", "info", "aftermarketOptions", "totalGAPSellPrice"],
          preview: PriceFilter,
          name: "gap",
          label: "Gap"
        }
      ]
    },
    {
      getData: (entry): CellValue =>
        entry.data?.info?.aftermarketOptions?.totalServiceWarrantySellPrice ?? 0,
      label: "Warranty",
      total: sumNumbers,
      name: "totalServiceWarrantySellPrice",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.totalServiceWarrantySellPrice?.show;
      },
      filters: [
        {
          path: ["data", "info", "aftermarketOptions", "totalServiceWarrantySellPrice"],
          preview: PriceFilter,
          name: "warranty",
          label: "Warranty"
        }
      ]
    },
    {
      getData: (entry): CellValue => entry.data?.info?.taxesAndFees?.totalUCCFees ?? 0,
      label: "UCC",
      total: sumNumbers,
      name: "totalUCCFees",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.totalUCCFees?.show;
      },
      filters: [
        {
          path: ["data", "info", "taxesAndFees", "totalUCCFees"],
          preview: PriceFilter,
          name: "ucc",
          label: "UCC"
        }
      ]
    },
    {
      getData: (entry): CellValue => entry.data?.info?.taxesAndFees?.documentRegistrationFees ?? 0,
      label: "Web Finance Doc Fee",
      total: sumNumbers,
      name: "documentRegistrationFees",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.documentRegistrationFees?.show;
      },
      filters: [
        {
          path: ["data", "info", "taxesAndFees", "documentRegistrationFees"],
          preview: PriceFilter,
          name: "wfdDocFee",
          label: "Web Finance Doc Fee"
        }
      ]
    },
    {
      getData: (entry): CellValue =>
        (entry.data?.info?.payment?.amountFinanced || 0) +
        (entry.data?.info?.payment?.reserve || 0) -
        (entry.data?.info?.taxesAndFees?.totalMembershipFees ||
          0 + entry.data?.info?.aftermarketOptions?.totalGAPSellPrice ||
          0 + entry.data?.info?.aftermarketOptions?.totalServiceWarrantySellPrice ||
          0 + entry.data?.info?.taxesAndFees?.totalUCCFees ||
          0 + entry.data?.info?.taxesAndFees?.documentRegistrationFees ||
          0),
      label: "Net Proceeds",
      total: sumNumbers,
      name: "netProceeds",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.netProceeds?.show;
      }
      //NO FIELD IN DB
    },
    {
      getData: (entry): CellValue => entry._id,
      label: "Actions",
      name: "actions",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.actions?.show;
      }
    }
  ];

  return (
    <div style={{ position: "relative" }}>
      <AccessControl requiredPermissions={{ entity: "deal", action: "read" }}>
        <Table
          slice="table"
          tableName="funding_notifications"
          entityName="deal"
          listFunction={getDealList}
          listEntity={dealList}
          deleteEntityFunction={deleteDeal}
          recoverEntityFunction={recoverDeal}
          sort={{ createdAt: "asc" }}
          title={"Funding notification"}
          columns={columns}
          isReport={true}
          showTotalFooter={true}
          projection={projections}
        />
      </AccessControl>
    </div>
  );
}
