import { Condition, MachineSchema, ResolvedCondition } from "./stateMachine";

export const getLatestActiveContractFile = (files: any[] | undefined) => {
  return files?.reduce((acc: any | undefined, curr: any) => {
    const signedDate = curr?.data?.info?.contractDates?.signedDate;
    const accSignedDate = acc?.data?.info?.contractDates?.signedDate;
    if (!acc && curr.data.info.status === "Active") return curr;
    return accSignedDate &&
      signedDate &&
      curr.data.info.status === "Active" &&
      new Date(signedDate).getTime() > new Date(accSignedDate).getTime()
      ? curr
      : acc;
  }, undefined);
};

const filterContractsByContractDates = (contract: any) => {
  const latestActiveContractFile = getLatestActiveContractFile(contract?.data?.files);
  if (latestActiveContractFile) {
    const contractDates = latestActiveContractFile.data.info.contractDates;
    if (contractDates?.endDate) {
      const today = new Date();
      return (
        new Date(contractDates?.startDate).getTime() <= today.getTime() &&
        today.getTime() <= new Date(contractDates.endDate).getTime()
      );
    } else {
      return true;
    }
  }
  return false;
};

export enum DealStatus {
  Lead = "lead",
  CreditCheck = "credit check",
  WaitingForDealDetails = "waiting for deal details",
  DealCreated = "deal created",
  PendingLenderDecision = "pending lender decision",
  Approved = "approved",
  Countered = "countered",
  SentToDealer = "sent to dealer",
  SubmittedForFunding = "submitted for funding",
  FundingHeld = "funding held",
  Funded = "funded",
  Cancelled = "cancelled",
  Denied = "denied",
  Dead = "dead",
  DidNotProcess = "did not process"
}
/// TODO: replace with deal type
type Deal = Record<string, any>;

const canResolver = (conditions: Condition[]): ResolvedCondition =>
  conditions.reduce(
    (acc: any, current: any) => ({
      can: acc.can && current.can,
      reasons: current.can ? acc.reasons : [...acc.reasons, current.reason]
    }),
    { can: true, reasons: [] }
  );

const canGoToCancelled = <T extends Deal>(deal: T) =>
  canResolver([
    {
      can:
        deal?.data?.info?.status === DealStatus.Funded ||
        deal?.data?.info?.status === DealStatus.FundingHeld ||
        deal?.data?.info?.status === DealStatus.SubmittedForFunding,
      reason: `Status should be ${DealStatus.Funded} or ${DealStatus.FundingHeld} or ${DealStatus.SubmittedForFunding}!`
    }
  ]);

const canGoToDenied = <T extends Deal>(deal: T) =>
  canResolver([
    {
      can: deal?.data?.info?.status === DealStatus.PendingLenderDecision,
      reason: `Status should be ${DealStatus.PendingLenderDecision}!`
    }
  ]);
const canGoToDead = <T extends Deal>(deal: T) =>
  canResolver([
    {
      can:
        deal?.data?.info?.status === DealStatus.WaitingForDealDetails ||
        deal?.data?.info?.status === DealStatus.Approved ||
        deal?.data?.info?.status === DealStatus.SentToDealer ||
        deal?.data?.info?.status === DealStatus.Countered,
      reason: `Status should be ${DealStatus.WaitingForDealDetails} or ${DealStatus.Approved} or ${DealStatus.SentToDealer} or ${DealStatus.Countered}!`
    }
  ]);

const canGoToFundingHeld = <T extends Deal>(deal: T) =>
  canResolver([
    {
      can: deal?.data?.info?.status === DealStatus.SubmittedForFunding,
      reason: `Status should be ${DealStatus.SubmittedForFunding}!`
    }
  ]);

const canGoToFunded = <T extends Deal>(deal: T) =>
  canResolver([
    {
      can:
        deal?.data?.info?.status === DealStatus.SubmittedForFunding ||
        deal?.data?.info?.status === DealStatus.FundingHeld,
      reason: `Status should be ${DealStatus.SubmittedForFunding} or ${DealStatus.FundingHeld}!`
    },
    {
      can: deal?.data?.info?.payment?.fundedAmount,
      reason: `Deal should have funded amount!`
    },
    {
      can: deal?.data?.info?.dealDates?.fundedAt,
      reason: `Deal should have funded at date!`
    }
  ]);

const canGoToSubmittedForFundng = <T extends Deal>(deal: T) => {
  const missingContractTypes = (deal?.data?.lender?.data?.requiredContractTypes ?? [])?.filter(
    (contractType: any) => {
      const filteredContracts = deal?.data?.dealership?.data?.contracts ?? [];
      // deal?.data?.dealership?.data?.contracts?.filter(
      //   (contract: any) =>
      //     getLatestActiveContractFile(contract.data.files) !== undefined
      // ) ?? [];
      if (filteredContracts?.length === 0) {
        return true;
      }
      return !filteredContracts
        ?.map((contract: any) => contract.data.contractTypeIds)
        .flat()
        .map((contractTypeId: any) => contractTypeId?.toString())
        .includes(contractType._id?.toString());
    }
  );
  return canResolver([
    {
      can: deal?.data?.info?.status === DealStatus.SentToDealer,
      reason: `Status should be ${DealStatus.SentToDealer}!`
    },
    {
      can: missingContractTypes?.length < 1,
      reason: `Dealership has to sign: ${missingContractTypes
        ?.map((contract: any) => `'${contract?.data?.info?.type}'`)
        ?.join(" / ")}!`
    }
  ]);
};

const canGoToLead = <T extends Deal>(deal: T) =>
  canResolver([
    {
      can: !deal?.data?.info?.status,
      reason: `Deal should not have a status!`
    }
  ]);

const canGoToCreditCheck = <T extends Deal>(deal: T) =>
  canResolver([
    {
      can: deal?.data?.info?.status === DealStatus.Lead,
      reason: `Status should be ${DealStatus.Lead}!`
    },
    {
      can: Array.isArray(deal?.data?.creditBureaus) || Array.isArray(deal?.data?.creditPrequalify),
      reason: `Please perform a credit score check before switching to ${DealStatus.CreditCheck} status!`
    },
    {
      can: deal?.data?.creditBureaus?.length > 0 || deal?.data?.creditPrequalify?.length > 0,
      reason: `Please perform a credit score check before switching to ${DealStatus.CreditCheck} status!`
    }
  ]);
const canGoToWaitingForDealDetails = <T extends Deal>(deal: T) =>
  canResolver([
    {
      can:
        deal?.data?.info?.status === DealStatus.CreditCheck ||
        deal?.data?.info?.status === DealStatus.Lead,
      reason: `Status should be ${DealStatus.CreditCheck}!`
    }
  ]);

const canGoToDealCreated = <T extends Deal>(deal: T) =>
  canResolver([
    {
      can: deal?.data?.info?.status === DealStatus.WaitingForDealDetails,
      reason: `Status should be ${DealStatus.WaitingForDealDetails}!`
    },
    {
      can: deal?.data?.user?._id,
      reason: `F&I manager be filled!`
    },
    {
      can: deal?.data?.info?.vehicle?.make && deal?.data?.info?.vehicle?.model,
      reason: `Collateral data should be filled (make, model)!`
    }
  ]);

const canGoToPendingLenderDecision = <T extends Deal>(deal: T) =>
  canResolver([
    {
      can:
        deal?.data?.info?.status === DealStatus.DealCreated ||
        deal?.data?.info?.status === DealStatus.Denied ||
        deal?.data?.info?.status === DealStatus.Countered ||
        deal?.data?.info?.status === DealStatus.Approved ||
        deal?.data?.info?.status === DealStatus.Dead ||
        deal?.data?.info?.status === DealStatus.SentToDealer,
      reason: `Status should be 
          ${DealStatus.DealCreated},
          ${DealStatus.Denied},
          ${DealStatus.Countered},
          ${DealStatus.Approved},
          ${DealStatus.Dead},
          or ${DealStatus.SentToDealer}!`
    }
  ]);

const canGoToApproved = <T extends Deal>(deal: T) =>
  canResolver([
    {
      can: deal?.data?.info?.status === DealStatus.PendingLenderDecision,
      reason: `Status should be ${DealStatus.PendingLenderDecision}!`
    },
    {
      can: deal?.data?.lenderId,
      reason: `Deal should have a lender!`
    }
  ]);

const canGoToCountered = <T extends Deal>(deal: T) =>
  canResolver([
    {
      can: deal?.data?.info?.status === DealStatus.PendingLenderDecision,
      reason: `Status should be ${DealStatus.PendingLenderDecision}!`
    },
    {
      can: deal?.data?.lenderId,
      reason: `Deal should have a lender!`
    }
  ]);

const canGoToSentToDealer = <T extends Deal>(deal: T) =>
  canResolver([
    {
      can:
        deal?.data?.info?.status === DealStatus.Countered ||
        deal?.data?.info?.status === DealStatus.Approved,
      reason: `Status should be ${DealStatus.Countered} or ${DealStatus.Approved}!`
    },
    {
      can: deal?.data?.info?.dealDates?.contractDate,
      reason: `Deal should have a contract date!`
    }
  ]);

const canGoToDidNotProcess = <T extends Deal>(deal: T) =>
  canResolver([
    {
      can:
        deal?.data?.info?.status === DealStatus.Lead ||
        deal?.data?.info?.status === DealStatus.CreditCheck ||
        deal?.data?.info?.status === DealStatus.WaitingForDealDetails,
      reason: `Status should be 
      ${DealStatus.CreditCheck},
      ${DealStatus.Lead},
      or ${DealStatus.WaitingForDealDetails}!`
    }
  ]);

export const getDealMachineSchema = <T extends Deal>(): MachineSchema<T, DealStatus[]> => {
  return {
    [DealStatus.Lead]: {
      possibleStates: [
        DealStatus.CreditCheck,
        DealStatus.WaitingForDealDetails,
        DealStatus.DidNotProcess
      ],
      can: canGoToLead
    },
    [DealStatus.CreditCheck]: {
      possibleStates: [DealStatus.WaitingForDealDetails, DealStatus.DidNotProcess],
      can: canGoToCreditCheck
    },
    [DealStatus.WaitingForDealDetails]: {
      possibleStates: [DealStatus.DealCreated, DealStatus.Dead, DealStatus.DidNotProcess],
      can: canGoToWaitingForDealDetails
    },
    [DealStatus.DealCreated]: {
      possibleStates: [DealStatus.PendingLenderDecision],
      can: canGoToDealCreated
    },
    [DealStatus.PendingLenderDecision]: {
      possibleStates: [DealStatus.Approved, DealStatus.Countered, DealStatus.Denied],
      can: canGoToPendingLenderDecision
    },
    [DealStatus.Approved]: {
      possibleStates: [DealStatus.SentToDealer, DealStatus.Dead, DealStatus.PendingLenderDecision],
      can: canGoToApproved
    },
    [DealStatus.Countered]: {
      possibleStates: [DealStatus.SentToDealer, DealStatus.Dead, DealStatus.PendingLenderDecision],
      can: canGoToCountered
    },
    [DealStatus.SentToDealer]: {
      possibleStates: [
        DealStatus.SubmittedForFunding,
        DealStatus.Dead,
        DealStatus.PendingLenderDecision
      ],
      can: canGoToSentToDealer
    },
    [DealStatus.SubmittedForFunding]: {
      possibleStates: [DealStatus.Funded, DealStatus.FundingHeld, DealStatus.Cancelled],
      can: canGoToSubmittedForFundng
    },
    [DealStatus.Funded]: {
      possibleStates: [DealStatus.Cancelled],
      can: canGoToFunded
    },
    [DealStatus.FundingHeld]: {
      possibleStates: [DealStatus.Funded, DealStatus.Cancelled],
      can: canGoToFundingHeld
    },
    [DealStatus.Dead]: {
      possibleStates: [DealStatus.PendingLenderDecision],
      can: canGoToDead
    },
    [DealStatus.Denied]: {
      possibleStates: [DealStatus.PendingLenderDecision],
      can: canGoToDenied
    },
    [DealStatus.Cancelled]: {
      possibleStates: [],
      can: canGoToCancelled
    },
    [DealStatus.DidNotProcess]: {
      possibleStates: [],
      can: canGoToDidNotProcess
    }
  };
};

export const getDealState = (data: any) => data?.data?.info?.status;
export const setDealState = (data: any, newState: DealStatus) => ({
  ...data,
  data: { ...data.data, info: { ...data.data.info, status: newState } }
});
export const disaggregateDeal = (deal: any) => {
  if (deal?.data) {
    delete deal.data.stateTax;
    delete deal.data.lender;
    delete deal.data.dealership;
    delete deal.data.payoffBank;
    delete deal.data.vehicleInsuranceCompany;
    delete deal.data.applicant;
    delete deal.data.coApplicant;
    delete deal.data.user;
    delete deal.data.coordinator;
    delete deal.data.oneSpanSignings;
    delete deal.data.printedDocuments;
    delete deal.data.order;
    delete deal.data.GAPCompany;
    delete deal.data.insuranceCompany;
    delete deal.availableToRoles;
    delete deal.data.creditBureaus;
    delete deal.data.managerPrograms;
    delete deal.data.dealerNotes;
    delete deal.data.notes;
    delete deal.data.stipulations;
  }
  return deal;
};
