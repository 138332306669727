export default {
  note: { type: "string" },
  type: {
    type: ["string"],
    enum: ["dealer", "f&i manager", "funding_held"],
    errorMessage: {
      enum: `must be one of the following: 
        'dealer', 'f&i manager', 'funding_held'`
    }
  }
};
