import { Grid, Paper, Typography } from "@material-ui/core";

import { FundingDocument } from "components/FundingDocuments/types";
import React, { useState } from "react";

import { Deal } from "../types";
import RecognizeFundingDocumentPageForm from "./RecognizeFundingDocumentPageForm";
import { Document, Page } from "react-pdf";
import { PDFDocumentProxy } from "pdfjs-dist";

const RenderPDFPreview = ({
  fundingDocument,
  deal
}: {
  fundingDocument: FundingDocument;
  deal: Deal;
}) => {
  const [showPages, setShowPages] = useState<{ [key: number]: boolean }>({});
  const [numPages, setNumPages] = useState(0);

  const onDocumentLoadSuccess = ({ numPages }: PDFDocumentProxy): void => {
    setNumPages(numPages);
  };
  return (
    <div style={{ height: 765, overflowY: "auto", backgroundColor: "#dedede", padding: 10 }}>
      <Document file={fundingDocument.data.info.url} onLoadSuccess={onDocumentLoadSuccess}>
        {Array.from(new Array(numPages), (el, index) => {
          const pageNumber = index + 1;
          const pageInfo = fundingDocument?.data?.uploadedDocumentStatus?.pages[pageNumber];
          const printedDocument = deal.data?.printedDocuments?.find(
            (pd) => pd._id === pageInfo?.printedDocumentId
          );
          const isLastPage =
            (printedDocument &&
              Object.keys(printedDocument?.data?.info?.pages || {}).length === pageInfo?.page) ||
            (!printedDocument?.data?.info?.pages && pageInfo?.recognized);
          const recognized = pageInfo?.recognized;
          const unuseful = pageInfo?.unuseful;
          return (
            <Paper
              style={{
                marginBottom: 10,
                display: "flex",
                justifyContent: "center",
                // background:
                //   printedDocument?.data?.info?.status === RequiredDocumentStatus.rejected
                //     ? "#ff7d7d"
                //     : recognized
                //     ? "#eed238"
                //     : "white",
                color: "black"
              }}
              key={`page_${pageNumber}`}
            >
              <Grid container>
                {(!recognized || showPages[pageNumber]) && (
                  <Grid
                    item
                    xs={12}
                    style={{ display: "flex", justifyContent: "center", padding: 5 }}
                  >
                    <Page
                      className="full-page"
                      renderTextLayer={false}
                      renderAnnotationLayer={false}
                      width={1500}
                      height={1027}
                      pageNumber={pageNumber}
                    />
                  </Grid>
                )}
                <Grid
                  style={{
                    padding: 0,
                    textAlign: "center",
                    position: "sticky",
                    bottom: -10,
                    background: "#fa9b9b66",
                    borderTop: "1px solid #ccc"
                  }}
                  item
                  container
                  xs={12}
                >
                  {recognized && (
                    <Grid item xs={12}>
                      <Typography variant="h5" style={{ padding: "20px 0px 25px 0" }}>
                        Page {pageNumber} has been
                        {unuseful ? (
                          <>
                            marked as <strong>unuseful</strong>
                          </>
                        ) : (
                          <>
                            recognized as <strong>{pageInfo.name}</strong>!
                          </>
                        )}
                        <u
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            setShowPages((oldShowPages) => ({
                              ...oldShowPages,
                              [pageNumber]: !oldShowPages[pageNumber]
                            }))
                          }
                        >{`${showPages[pageNumber] ? "Hide page." : "Show page."}`}</u>
                      </Typography>
                    </Grid>
                  )}

                  <Grid
                    item
                    xs={12}
                    style={{
                      background: "#f9f9f9",
                      padding: "5px 29px",
                      borderTop: "1px dashed #c9c9c9",
                      borderRadius: "0px 0px 4px 4px"
                    }}
                  >
                    {
                      <RecognizeFundingDocumentPageForm
                        pageNumber={pageNumber}
                        deal={deal}
                        fundingDocument={fundingDocument}
                        isLastPage={isLastPage}
                        recognized={recognized}
                      />
                    }
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          );
        })}
      </Document>
    </div>
  );
};

const RenderImagePreview = ({
  fundingDocument,
  deal
}: {
  fundingDocument: FundingDocument;
  deal: Deal;
}) => {
  const pageInfo = fundingDocument?.data?.uploadedDocumentStatus?.pages[1];
  const recognized = fundingDocument?.data?.uploadedDocumentStatus?.pages[1]?.recognized;
  const [showPage, setShowPage] = useState<boolean>(true);

  return (
    <div style={{ height: 765, overflowY: "auto", backgroundColor: "#dedede", padding: 10 }}>
      <Paper
        style={{
          marginBottom: 10,
          display: "flex",
          justifyContent: "center",
          color: "black"
        }}
      >
        <Grid container>
          <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }}>
            {(!recognized || showPage) && (
              <img style={{ width: "100%" }} src={fundingDocument.data.info.url} />
            )}
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              textAlign: "center",
              position: "sticky",
              bottom: -10,
              background: "#f9f9f9",
              borderTop: "1px solid #ccc"
            }}
          >
            {recognized && (
              <Grid item xs={12} style={{ textAlign: "center" }}>
                <Typography variant="h5" style={{ padding: "20px 0px 25px 0" }}>
                  Document 1 has been recognized as <strong>{pageInfo.name}</strong>!{" "}
                  <u
                    style={{ cursor: "pointer" }}
                    onClick={() => setShowPage((oldShowPages) => !oldShowPages)}
                  >{`${showPage ? "Hide page." : "Show page."}`}</u>
                </Typography>
              </Grid>
            )}
            <Grid
              item
              xs={12}
              style={{
                background: "#f9f9f9",
                padding: "5px 29px",
                borderTop: "1px dashed #c9c9c9",
                borderRadius: "0px 0px 4px 4px"
              }}
            >
              {
                <RecognizeFundingDocumentPageForm
                  pageNumber={1}
                  deal={deal}
                  fundingDocument={fundingDocument}
                  isLastPage={true}
                  recognized={recognized}
                />
              }
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};
export default function PreviewAndRecognizeFundingDocument({
  deal,
  fundingDocument
}: {
  deal: Deal;
  fundingDocument: FundingDocument | undefined;
}) {
  const fundingDocumentType = fundingDocument?.data?.info?.metadata?.contentType;

  return fundingDocument ? (
    fundingDocumentType === "application/pdf" ? (
      <RenderPDFPreview fundingDocument={fundingDocument} deal={deal} />
    ) : (
      <RenderImagePreview fundingDocument={fundingDocument} deal={deal} />
    )
  ) : null;
}
