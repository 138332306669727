import React from "react";
import {
  DialogTitle,
  DialogContent,
  Dialog,
  DialogActions,
  Grid,
  Paper,
  Button,
  Table,
  TableBody,
  TableContainer,
  TableHead
} from "@material-ui/core";
import CloseDialogButton from "components/common/CloseDialogButton";
import formatDate from "utils/formatDate";
import { useSnackbar } from "notistack";
import { Applicant } from "components/Applicants/types";
import { PersonaVerification, Verification } from "../../types/internal/personaVerifications";
import { isDatabaseVerificationValid } from "../../FraudValidationPreview";
import {
  VerificationOrReportDialogActions,
  getColorByStatus,
  renderVerificationOrReportHeader
} from "../common";
import {
  CheckRequirement,
  VerificationCheckName,
  VerificationCheckStatus
} from "../../types/external/verifications/common";
import { formatSimpleSSN } from "utils/models/formRenderers";
import { renderTableHeadRow, renderTableRowWithData } from "./common";
type Props = {
  verifications: PersonaVerification[];
  applicant: Applicant;
  isLoading: boolean;
  retryFunction: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    selectedVerificationType: keyof Verification | "all"
  ) => void;
  closeFunction: () => void;
};

const DatabaseVerificationsHistoryDialog = ({
  verifications,
  applicant,
  isLoading,
  closeFunction,
  retryFunction
}: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const [rawJSONDialogOpen, setRawJSONDialogOpen] = React.useState(false);
  const [rawJSON, setRawJSON] = React.useState("");
  const [copied, setCopied] = React.useState(false);

  React.useEffect(() => {
    if (copied) {
      enqueueSnackbar("Successfully copied!", {
        variant: "success"
      });
    }
  }, [copied]);
  const closeRawDialogFunction = () => {
    setRawJSONDialogOpen(false);
    setCopied(false);
  };
  const copyRawJSON = async () => {
    await navigator.clipboard.writeText(rawJSON);
    setCopied(true);
  };
  const getPropertyNameValueMatchResultAndStatusByCheckRequirement = (
    checkRequirement: CheckRequirement,
    verification: PersonaVerification
  ) => {
    let name: string | undefined = undefined;
    let value: string | undefined = undefined;
    switch (checkRequirement.name) {
      case "name_full":
        name = "Full Name";
        value = `${verification.data.info.verification.request?.data.attributes.name_first} ${verification.data.info.verification.request?.data.attributes.name_last}`;
        break;

      case "birthdate":
        name = "Birthdate";
        const [
          year,
          month,
          day
        ] = verification?.data?.info?.verification?.request?.data?.attributes?.birthdate
          ?.split("-")
          ?.map((x) => parseInt(x)) as number[];
        value = formatDate(new Date(year, month - 1, day, 0, 0, 0, 0), "medium");
        break;
      case "social_security_number":
        name = "Social Security Number";
        value = verification?.data?.info?.verification?.request?.data?.attributes
          ?.identification_number
          ? formatSimpleSSN(
              verification?.data?.info?.verification?.request?.data?.attributes
                ?.identification_number
            )
          : undefined;
        break;
      case "address_street_1":
        name = "Street";
        value = verification?.data?.info?.verification?.request?.data?.attributes?.address_street_1;
        break;
      case "address_city":
        name = "City";
        value = verification?.data?.info?.verification?.request?.data?.attributes?.address_city;
        break;
      case "address_subdivision":
        name = "Subdivision/State";
        value =
          verification?.data?.info?.verification?.request?.data?.attributes?.address_subdivision;
        break;
      case "address_postal_code":
        name = "Postal Code";
        value =
          verification?.data?.info?.verification?.request?.data?.attributes?.address_postal_code;
    }
    return {
      name,
      value,
      matchResult: checkRequirement?.match_result,
      status: checkRequirement?.status
    };
  };
  return (
    <React.Fragment>
      <Dialog
        maxWidth="md"
        id="raw-json-dialog"
        open={rawJSONDialogOpen}
        onClose={closeRawDialogFunction}
      >
        <DialogTitle id="raw-json-dialog-title">
          <CloseDialogButton closeFunction={closeRawDialogFunction} />
        </DialogTitle>
        <DialogContent>
          <pre>{rawJSON}</pre>
        </DialogContent>
        <DialogActions style={{ justifyContent: "space-between" }}>
          <Button
            style={copied ? { background: "green" } : {}}
            onClick={copyRawJSON}
            variant="contained"
            autoFocus
            color="primary"
            id="copy"
          >
            Copy
          </Button>
          <Button onClick={closeRawDialogFunction} variant="contained" color="primary" id="cancel">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        maxWidth="md"
        id="fraud-validation-reports-dialog"
        open={true}
        onClose={closeFunction}
      >
        <DialogTitle id="fraud-validation-reports-dialog-title">
          {`Database Verification History - ${applicant.data.info.firstName} ${applicant.data.info.lastName}`}
          <CloseDialogButton closeFunction={closeFunction} />
        </DialogTitle>
        <DialogContent>
          <Grid container xs={12}>
            {verifications.reverse().map((verification, index) => {
              const status = isDatabaseVerificationValid(
                verification?.data?.info?.verification?.response
              )
                ? "Valid"
                : "Invalid";
              const databaseIdentityComparisonCheck = verification?.data?.info?.verification?.response?.data?.attributes?.checks?.find(
                (check) => check.name === VerificationCheckName.Database_Identity_Comparison
              );
              const statusColor = getColorByStatus(status);
              return (
                <Paper key={index} elevation={3} style={{ width: "100%", marginBottom: "10px" }}>
                  <Grid container spacing={1} item xs={12} style={{ padding: "5px", margin: 0 }}>
                    {renderVerificationOrReportHeader(
                      status,
                      statusColor,
                      verification?.createdAt,
                      verification?.data?.info?.performedByUserEmail,
                      () => {
                        setRawJSON(JSON.stringify(verification, null, 2));
                        setRawJSONDialogOpen(true);
                      }
                    )}
                    <Grid item xs={12}>
                      <TableContainer style={{ marginTop: "10px" }} component={Paper}>
                        <Table size="medium">
                          <TableHead>{renderTableHeadRow()}</TableHead>
                          <TableBody>
                            {(
                              databaseIdentityComparisonCheck?.metadata?.check_requirements ?? []
                            )?.map((checkRequirement) => {
                              const {
                                name,
                                value,
                                matchResult,
                                status
                              } = getPropertyNameValueMatchResultAndStatusByCheckRequirement(
                                checkRequirement,
                                verification
                              );
                              return renderTableRowWithData(
                                name,
                                value,
                                matchResult,
                                status === VerificationCheckStatus.Passed
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  </Grid>
                </Paper>
              );
            })}
          </Grid>
        </DialogContent>
        <VerificationOrReportDialogActions
          recordsLength={verifications?.length || 0}
          retryFunction={retryFunction}
          selectedVerificationType="databaseVerification"
          isLoading={isLoading}
          closeFunction={closeFunction}
        />
      </Dialog>
    </React.Fragment>
  );
};
export default DatabaseVerificationsHistoryDialog;
