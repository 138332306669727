import { createSocketMessageSlice } from "components/Middlewares/socketMessageSliceCreator";
import { FormState, NetsuiteDealStatus } from "./types";
import { AddRequest } from "utils/types";

export interface AddNetsuiteDealStatusResponse {
  message: {
    data: FormState;
    _id: string;
    createdAt: string;
  };
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  Omit<AddRequest<NetsuiteDealStatus>, "createdAt" | "updatedAt">,
  AddNetsuiteDealStatusResponse
>("AddNetsuiteDealStatus", {
  request: "new_netsuite_deal_status"
});

export const addNetsuiteDealStatusActions = socketMessageSlice.actions;
export const addNetsuiteDealStatus = socketAction;
export default socketMessageSlice.reducer;
