import React, { useState } from "react";
import { APIProvider, Map, InfoWindow } from "@vis.gl/react-google-maps";
import { collateralTypes, CollateralTypes, Deal, DealStatus } from "components/Deals/types";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "app/rootReducer";
import {
  getDealershipList,
  removeDealershipList
} from "components/Dealerships/listDealershipSlice";
import { useDealsForMap } from "Chat/hooks/useDealsForMap";
import {
  Checkbox,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  Paper,
  Radio,
  TextField,
  Tooltip,
  Typography
} from "@material-ui/core";
import { Dealership } from "components/Dealerships/types";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  DealershipsFeaturesProps,
  DealershipsGeojson,
  DealsFeaturesProps,
  DealsGeojson,
  LendersFeaturesProps,
  LendersGeojson
} from "./types";
import { Feature, Point } from "geojson";
import { ClusteredMarkers } from "./ClusteredMarkers";
import {
  DealershipInfoWindowContent,
  DealInfoWindowContent,
  LenderInfoWindowContent
} from "./InfoWindow";
import "./DealsMapDashboard.module.css";
import {
  capitalize,
  formatNumberAsCurrency,
  getFirstAndLastDateOfCurrentMonth
} from "utils/functions";
import { useStickyState } from "index";
import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import { isValidDate } from "utils/models/formRenderers";
import { LoopIconWrapper } from "components/Loader/TextLoop";
import { FeatureMarkerDealership } from "./FeatureMarkerDealership";
import { Lender } from "components/Lenders/types";
import { getLenderList, removeLenderList } from "components/Lenders/listLenderSlice";
import { FeatureMarkerLender } from "./FeatureMarkerLender";
import { FaCar, FaCircle } from "react-icons/fa";
import { AiFillBank } from "react-icons/ai";
import { sizeByAmountFinanced } from "./FeatureMarkerDeal";
import { IoMdHelpCircle } from "react-icons/io";
import ExportToExcel from "./ExportToExcel";
import States from "us-states";
const getClusterColor = (
  status: "in progress" | "positive" | "negative" | "false negative"
): string => {
  switch (status) {
    case "positive":
      return " #00CA4E";
    case "negative":
      return " #FF605C";
    case "in progress":
      return " #FFBD44";
    case "false negative":
      return " #880ED4";
  }
};

const IN_PROGRESS_STATUSES = [
  DealStatus.Lead,
  DealStatus.CreditCheck,
  DealStatus.DealCreated,
  DealStatus.WaitingForDealDetails,
  DealStatus.Approved,
  DealStatus.SentToDealer,
  DealStatus.PendingLenderDecision,
  DealStatus.Countered
];
const POSITIVE_STATUSES = [
  DealStatus.FundingHeld,
  DealStatus.SubmittedForFunding,
  DealStatus.Funded
];
export const MID_PROGRESS_STATUSES = [
  DealStatus.Approved,
  DealStatus.SentToDealer,
  DealStatus.Countered
];
export const NEGATIVE_STATUSES = [
  DealStatus.Denied,
  DealStatus.Cancelled,
  DealStatus.Dead,
  DealStatus.DidNotProcess
];
export const negativeButWasInMidProgress = (
  currentStatus: DealStatus,
  statusHistory: {
    status: DealStatus;
    reason: string;
    date: string;
    userEmail: string;
  }[],
  selectedInProgressStatuses: DealStatus[]
) =>
  (NEGATIVE_STATUSES.filter((x) => x !== DealStatus.DidNotProcess) as DealStatus[]).includes(
    currentStatus
  ) && statusHistory?.some((status) => selectedInProgressStatuses.includes(status.status));

const dealStatusToGroup = (
  status: DealStatus,
  statusHistory: {
    status: DealStatus;
    reason: string;
    date: string;
    userEmail: string;
  }[],
  selectedStatuses: DealStatus[]
) => {
  switch (status) {
    case DealStatus.Lead:
    case DealStatus.CreditCheck:
    case DealStatus.DealCreated:
    case DealStatus.WaitingForDealDetails:
    case DealStatus.Approved:
    case DealStatus.SentToDealer:
    case DealStatus.PendingLenderDecision:
    case DealStatus.Countered:
      return "in progress";
    case DealStatus.FundingHeld:
    case DealStatus.SubmittedForFunding:
    case DealStatus.Funded:
      return "positive";
    case DealStatus.Denied:
    case DealStatus.Cancelled:
    case DealStatus.Dead:
    case DealStatus.DidNotProcess:
      return negativeButWasInMidProgress(
        status,
        statusHistory,
        selectedStatuses.filter((status) => MID_PROGRESS_STATUSES.includes(status))
      )
        ? "false negative"
        : "negative";
  }
};
const reduceDealsByStatusGroup = (deals: Deal[], selectedStatuses: DealStatus[]) => {
  return deals.reduce(
    (acc, deal) => {
      const groupedStatus = dealStatusToGroup(
        deal.data.info.status,
        deal.data.info.statusHistory ?? [],
        selectedStatuses
      );
      acc[groupedStatus].push(deal);
      return acc;
    },
    {
      "in progress": [] as Deal[],
      positive: [] as Deal[],
      negative: [] as Deal[],
      "false negative": [] as Deal[]
    }
  );
};
const filterDealsByRequest = (
  deals: Deal[],
  request: {
    dealershipIds: string[];
    lenderIds: string[];
    allDealerships: boolean;
    allStates: boolean;
    allLenders: boolean;
    from: string;
    states: string[];
    counties?: string[];
    to: string;
    statuses: DealStatus[];
    showDealerships: boolean;
    showLenders: boolean;
    collateralTypes: CollateralTypes[];
  },
  setCenter: React.Dispatch<React.SetStateAction<{ lat: number; lng: number }>>
) => {
  const filteredDeals = deals?.filter((deal) => {
    return (
      new Date(deal.createdAt).getTime() >= new Date(request.from).getTime() &&
      new Date(deal.createdAt).getTime() <= new Date(request.to).getTime() &&
      request.statuses.includes(deal.data.info.status) &&
      request.collateralTypes.includes(deal.data.info.type) &&
      (request.allDealerships ||
        request.dealershipIds.includes(deal.data.dealershipId as string)) &&
      (request.allLenders || request.lenderIds.includes(deal.data.lenderId as string)) &&
      (request.allStates ||
        (request.states.includes(deal.data.applicant?.data?.info.currentState ?? "") &&
          ((request.counties?.length ?? 0) === 0 ||
            request?.counties?.includes(deal.data.applicant?.data?.info.currentCounty as string))))
    );
  });
  setCenter({
    lat:
      (deals ?? [])?.reduce(
        (a, b) => a + Number(b?.data?.applicant?.data?.info?.coordinates?.lat),
        0
      ) / (deals?.length || 1),
    lng:
      (deals ?? [])?.reduce(
        (a, b) => a + Number(b?.data?.applicant?.data?.info?.coordinates?.lon),
        0
      ) / (deals?.length || 1)
  });
  return filteredDeals;
};

const filterLenders = (lenders: Lender[], lenderIds: string[]) =>
  lenders.filter(
    (lender) =>
      typeof lender.data.info.coordinates?.lat === "number" &&
      typeof lender.data.info.coordinates?.lon === "number" &&
      (lenderIds?.length > 0 ? lenderIds.includes(lender._id) : true)
  );
const filterDealerships = (dealerships: Dealership[], dealershipIds: string[]) =>
  dealerships.filter(
    (dealership) =>
      typeof dealership.data.info.coordinates?.lat === "number" &&
      typeof dealership.data.info.coordinates?.lon === "number" &&
      (dealershipIds?.length > 0 ? dealershipIds.includes(dealership._id) : true)
  );

export default function DealsMapDashboard() {
  const listId = "deals_map_dashboard";
  const dispatch = useDispatch();
  const currentUser = useSelector((state: RootState) => state?.authSlice);
  const dealershipList = useSelector((state: RootState) => state.listDealershipSlice[listId]);
  const lenderList = useSelector((state: RootState) => state.listLenderSlice[listId]);

  const currentUserRolesIds = currentUser?.user?.databaseData?.data?.rolesIds?.map(
    (roleId) => roleId
  );
  const isUserAdmin = currentUserRolesIds?.includes("5f17e21d46a2b90e45bb5a90");

  const [today] = React.useState(new Date());
  const [state, setState] = useStickyState<{
    dealershipIds: string[];
    lenderIds: string[];
    allDealerships: boolean;
    allLenders: boolean;
    allStates: boolean;
    from: string;
    to: string;
    statuses: DealStatus[];
    showDealerships: boolean;
    showLenders: boolean;
    states: string[];
    counties?: string[];
    collateralTypes: CollateralTypes[];
  }>(
    {
      allDealerships: true,
      allLenders: true,
      allStates: true,
      collateralTypes: ["Automotive", "Marine", "Power Sport", "Recreational Vehicles"],
      from: getFirstAndLastDateOfCurrentMonth()?.from,
      to: today.toISOString(),
      showDealerships: true,
      showLenders: true,
      states: [],
      counties: [],
      statuses: Object.values(DealStatus) as DealStatus[],
      dealershipIds: [],
      lenderIds: []
    },
    "deals_map_dashboard_state"
  );

  //autocomplete states
  const [dealershipsAutocomplete, setDealershipsAutocomplete] = useStickyState<Dealership[]>(
    [],
    "deals_map_dashboard_dealerships"
  );
  const [lendersAutocomplete, setLendersAutocomplete] = useStickyState<Lender[]>(
    [],
    "deals_map_dashboard_lenders"
  );
  const [statesAutocomplete, setStatesAutocomplete] = useStickyState<string[]>(
    [],
    "deals_map_dashboard_states"
  );
  const [countiesAutocomplete, setCountiesAutocomplete] = useStickyState<string[]>(
    [],
    "deals_map_dashboard_counties"
  );
  //map components states
  const [statusByDealershipGeojsons, setStatusByDealershipGeojsons] = useState<
    | Record<
        string,
        Record<"in progress" | "positive" | "negative" | "false negative", DealsGeojson>
      >
    | undefined
  >();
  const [lenderGeojsons, setLenderGeojsons] = useState<
    { lender: string; geojson: LendersGeojson }[] | undefined
  >();
  const [dealershipGeojsons, setDealershipGeojsons] = useState<
    { dealership: string; geojson: DealershipsGeojson }[] | undefined
  >();
  //info window states
  const [dealInfowindowData, setDealInfowindowData] = useState<{
    anchor: google.maps.marker.AdvancedMarkerElement;
    features: Feature<Point>[];
  } | null>(null);
  const [dealershipInfoWindowData, setDealershipInfoWindowData] = useState<{
    anchor: google.maps.marker.AdvancedMarkerElement;
    feature: Feature<Point>;
  } | null>(null);
  const [lenderInfoWindowData, setLenderInfoWindowData] = useState<{
    anchor: google.maps.marker.AdvancedMarkerElement;
    feature: Feature<Point>;
  } | null>(null);
  const handleDealInfoWindowClose = React.useCallback(() => setDealInfowindowData(null), [
    setDealInfowindowData
  ]);
  const handleDealershipInfoWindowClose = React.useCallback(
    () => setDealershipInfoWindowData(null),
    [setDealershipInfoWindowData]
  );
  const handleLenderInfoWindowClose = React.useCallback(() => setLenderInfoWindowData(null), [
    setLenderInfoWindowData
  ]);
  const [status, setStatus] = useState<DealStatus>();
  const [dealershipForInfoWindow, setDealershipForInfoWindow] = useState<string>();

  const { data, isFetching } = useDealsForMap();

  const dealerships = React.useMemo(
    () => filterDealerships(data?.dealerships ?? [], state.dealershipIds),
    [data?.dealerships, state]
  );
  const lenders = React.useMemo(() => filterLenders(data?.lenders ?? [], state.lenderIds), [
    data?.lenders,
    state
  ]);
  const [zoom, setZoom] = React.useState(5);
  const [center, setCenter] = React.useState({
    lat: 0,
    lng: 0
  });
  const deals = React.useMemo(() => filterDealsByRequest(data?.deals ?? [], state, setCenter), [
    data?.deals,
    state
  ]);
  React.useEffect(() => {
    const statusByDealershipGeojsons = deals.reduce((acc, deal) => {
      const groupedStatus = dealStatusToGroup(
        deal.data.info.status,
        deal.data.info.statusHistory ?? [],
        state.statuses
      );
      const feature: Feature<Point, DealsFeaturesProps> = {
        type: "Feature",
        id: deal._id,
        geometry: {
          type: "Point",
          coordinates: [
            Number(deal?.data?.applicant?.data?.info?.coordinates?.lon),
            Number(deal?.data?.applicant?.data?.info?.coordinates?.lat)
          ]
        },
        properties: {
          statusGroup: groupedStatus,
          status: deal.data.info.status,
          applicantNames: `${deal?.data?.applicant?.data?.info?.firstName} ${deal?.data?.applicant?.data?.info?.lastName}`,
          dealershipName: deal?.data?.dealership?.data?.info?.displayName,
          statusHistory: deal?.data?.info?.statusHistory ?? [],
          // .filter((x) =>
          //   state.statuses
          //     .filter((status) => MID_PROGRESS_STATUSES.includes(status))
          //     .includes(x.status)
          // ),
          lenderName: deal?.data?.lender?.data?.info?.name,
          amountFinanced: deal?.data?.info?.payment?.dealTotal || 0,
          vehicle: {
            year: deal?.data?.info?.vehicle?.year,
            make: deal?.data?.info?.vehicle?.make,
            model: deal?.data?.info?.vehicle?.model
          }
        }
      };
      if (!acc?.[deal.data.dealership?.data?.info?.displayName as string]) {
        acc[deal.data.dealership?.data?.info?.displayName as string] = {
          [groupedStatus]: {
            type: "FeatureCollection",
            features: [feature]
          }
        } as Record<"in progress" | "positive" | "negative" | "false negative", DealsGeojson>;
      } else {
        acc[deal.data.dealership?.data?.info?.displayName as string] = {
          ...acc[deal.data.dealership?.data?.info?.displayName as string],
          [groupedStatus]: {
            type: "FeatureCollection",
            features: [
              ...(acc?.[deal.data.dealership?.data?.info?.displayName as string]?.[groupedStatus]
                ?.features ?? []),
              feature
            ]
          }
        };
      }
      return acc;
    }, {} as Record<string, Record<"in progress" | "positive" | "negative" | "false negative", DealsGeojson>>);
    setStatusByDealershipGeojsons(statusByDealershipGeojsons);
  }, [deals]);
  React.useEffect(() => {
    if (lenders?.length) {
      const lenderGeojsons = lenders.map((lender) => {
        const feature: Feature<Point, LendersFeaturesProps> = {
          type: "Feature",
          id: lender._id,
          geometry: {
            type: "Point",
            coordinates: [
              Number(lender?.data?.info?.coordinates?.lon),
              Number(lender?.data?.info?.coordinates?.lat)
            ]
          },
          properties: {
            name: lender?.data?.info?.name
          }
        };
        return {
          lender: lender?.data?.info?.name,
          geojson: {
            type: "FeatureCollection" as const,
            features: [feature]
          }
        };
      });

      setLenderGeojsons(lenderGeojsons);
    }
  }, [lenders]);
  React.useEffect(() => {
    if (dealerships?.length) {
      const dealershipGeojsons = dealerships.map((dealership) => {
        const feature: Feature<Point, DealershipsFeaturesProps> = {
          type: "Feature",
          id: dealership._id,
          geometry: {
            type: "Point",
            coordinates: [
              Number(dealership?.data?.info?.coordinates?.lon),
              Number(dealership?.data?.info?.coordinates?.lat)
            ]
          },
          properties: {
            name: dealership?.data?.info?.displayName
          }
        };
        return {
          dealership: dealership?.data?.info?.displayName,
          geojson: {
            type: "FeatureCollection" as const,
            features: [feature]
          }
        };
      });

      setDealershipGeojsons(dealershipGeojsons);
    }
  }, [dealerships]);
  React.useEffect(() => {
    if (!dealershipList)
      dispatch(
        getDealershipList(listId, {
          ...(!isUserAdmin
            ? { query: { "data.representativeId": currentUser?.user?.databaseData?._id } }
            : {}),
          options: {
            projection: {
              _id: 1,
              "data.info.name": 1,
              "data.info.displayName": 1,
              insensitiveName: {
                $toLower: "$data.info.displayName"
              }
            },
            pagination: false,
            sort: { insensitiveName: "asc" }
          }
        })
      );
    return () => {
      dispatch(removeDealershipList(listId));
      return;
    };
  }, []);
  React.useEffect(() => {
    if (!lenderList)
      dispatch(
        getLenderList(listId, {
          options: {
            projection: {
              _id: 1,
              "data.info.name": 1,
              insensitiveName: {
                $toLower: "$data.info.name"
              }
            },
            pagination: false,
            sort: { insensitiveName: "asc" }
          }
        })
      );
    return () => {
      dispatch(removeLenderList(listId));
      return;
    };
  }, []);

  return (
    <Grid item container xs={12} spacing={2}>
      <Grid item xs={5}>
        <Paper elevation={3}>
          <div
            style={{
              fontWeight: "500",
              display: "inline-flex",
              padding: "0px 14px",
              marginRight: "10px",
              width: "115px",
              verticalAlign: "middle",
              alignItems: "center",
              background: "rgb(224 224 224)",
              borderRadius: "4px 0px 0px 4px "
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  indeterminate={
                    state?.collateralTypes?.length > 0 &&
                    state?.collateralTypes?.length !== collateralTypes.length
                  }
                  checked={state?.collateralTypes?.length === collateralTypes.length}
                  onChange={(event) => {
                    if (event.target.checked) {
                      setState((prevState) => ({
                        ...prevState,
                        collateralTypes: [
                          "Automotive",
                          "Marine",
                          "Power Sport",
                          "Recreational Vehicles"
                        ]
                      }));
                    } else {
                      setState((prevState) => ({
                        ...prevState,
                        collateralTypes: []
                      }));
                    }
                  }}
                  name="All"
                  color="primary"
                />
              }
              label={<Typography style={{ fontSize: "12px" }}>All types</Typography>}
            />
          </div>
          {Object.values(collateralTypes).map((collateralType, index) => (
            <FormControlLabel
              key={index}
              control={
                <Checkbox
                  onChange={(event) => {
                    if (event.target.checked) {
                      setState((prevState) => ({
                        ...prevState,
                        collateralTypes: [...(prevState.collateralTypes ?? []), collateralType]
                      }));
                    } else {
                      setState((prevState) => ({
                        ...prevState,
                        collateralTypes: prevState.collateralTypes.filter(
                          (prevCollateralType) => prevCollateralType !== collateralType
                        )
                      }));
                    }
                  }}
                  checked={
                    state?.collateralTypes?.findIndex(
                      (prevCollateralType) => prevCollateralType === collateralType
                    ) > -1
                  }
                  name={collateralType}
                  color="primary"
                />
              }
              label={<Typography style={{ fontSize: "12px" }}>{collateralType}</Typography>}
            />
          ))}
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper elevation={3}>
          <div
            style={{
              fontWeight: "500",
              display: "inline-flex",
              padding: "0px 14px",
              width: "115px",
              verticalAlign: "middle",
              alignItems: "center",
              background: "rgb(224 224 224)",
              borderRadius: "4px 0px 0px 4px "
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  indeterminate={
                    state?.statuses?.length > 0 &&
                    state?.statuses?.length !== Object.keys(DealStatus).length
                  }
                  checked={state?.statuses?.length === Object.keys(DealStatus).length}
                  onChange={(event) => {
                    if (event.target.checked) {
                      setState((prevState) => ({
                        ...prevState,
                        statuses: Object.values(DealStatus)
                      }));
                    } else {
                      setState((prevState) => ({
                        ...prevState,
                        statuses: []
                      }));
                    }
                  }}
                  name="All"
                  color="primary"
                />
              }
              label={<Typography style={{ fontSize: "12px" }}>All statuses</Typography>}
            />
          </div>
          <div
            style={{
              display: "inline-flex",
              verticalAlign: "middle",
              background: "#00CA4E30",
              width: "full"
            }}
          >
            <div
              style={{
                fontWeight: "500",
                display: "inline-flex",
                padding: "0px 14px",
                marginRight: "10px",
                width: "115px",
                verticalAlign: "middle",
                alignItems: "center",
                background: "rgb(224 224 224)",
                borderRadius: "4px 0px 0px 4px "
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    indeterminate={
                      state?.statuses?.filter((status) => POSITIVE_STATUSES.includes(status))
                        ?.length > 0 &&
                      state?.statuses?.filter((status) => POSITIVE_STATUSES.includes(status))
                        ?.length !== POSITIVE_STATUSES.length
                    }
                    checked={
                      state?.statuses?.length > 0 &&
                      state?.statuses?.filter((status) => POSITIVE_STATUSES.includes(status))
                        .length === POSITIVE_STATUSES.length
                    }
                    onChange={(event) => {
                      if (event.target.checked) {
                        setState((prevState) => ({
                          ...prevState,
                          statuses: POSITIVE_STATUSES
                        }));
                      } else {
                        setState((prevState) => ({
                          ...prevState,
                          statuses: prevState.statuses.filter(
                            (status) => !POSITIVE_STATUSES.includes(status)
                          )
                        }));
                      }
                    }}
                    name="Positive"
                    color="primary"
                  />
                }
                label={<Typography style={{ fontSize: "12px" }}>Positive statuses</Typography>}
              />
            </div>
            {POSITIVE_STATUSES.map((status, index) => (
              <FormControlLabel
                key={index}
                control={
                  <Checkbox
                    onChange={(event) => {
                      if (event.target.checked) {
                        setState((prevState) => ({
                          ...prevState,
                          statuses: [...(prevState.statuses ?? []), status]
                        }));
                      } else {
                        setState((prevState) => ({
                          ...prevState,
                          statuses: prevState.statuses.filter((prevStatus) => prevStatus !== status)
                        }));
                      }
                    }}
                    checked={state?.statuses?.includes(status)}
                    name={capitalize(status)}
                    color="primary"
                  />
                }
                label={<Typography style={{ fontSize: "12px" }}>{capitalize(status)}</Typography>}
              />
            ))}
          </div>
          <div
            style={{
              display: "inline-flex",
              verticalAlign: "middle",
              background: "#FFBD4430"
            }}
          >
            <div
              style={{
                fontWeight: "500",
                display: "inline-flex",
                padding: "0px 14px",
                marginRight: "10px",
                width: "115px",
                verticalAlign: "middle",
                alignItems: "center",
                background: "rgb(224 224 224)",
                borderRadius: "4px 0px 0px 4px "
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    indeterminate={
                      state?.statuses?.filter((status) => IN_PROGRESS_STATUSES.includes(status))
                        ?.length > 0 &&
                      state?.statuses?.filter((status) => IN_PROGRESS_STATUSES.includes(status))
                        ?.length !== IN_PROGRESS_STATUSES.length
                    }
                    checked={
                      state?.statuses?.length > 0 &&
                      state?.statuses?.filter((status) => IN_PROGRESS_STATUSES.includes(status))
                        .length === IN_PROGRESS_STATUSES.length
                    }
                    onChange={(event) => {
                      if (event.target.checked) {
                        setState((prevState) => ({
                          ...prevState,
                          statuses: IN_PROGRESS_STATUSES
                        }));
                      } else {
                        setState((prevState) => ({
                          ...prevState,
                          statuses: prevState.statuses.filter(
                            (status) => !IN_PROGRESS_STATUSES.includes(status)
                          )
                        }));
                      }
                    }}
                    name="In Progress"
                    color="primary"
                  />
                }
                label={<Typography style={{ fontSize: "12px" }}>In progress statuses</Typography>}
              />
            </div>
            {IN_PROGRESS_STATUSES.map((status, index) => (
              <FormControlLabel
                key={index}
                control={
                  <Checkbox
                    onChange={(event) => {
                      if (event.target.checked) {
                        setState((prevState) => ({
                          ...prevState,
                          statuses: [...(prevState.statuses ?? []), status]
                        }));
                      } else {
                        setState((prevState) => ({
                          ...prevState,
                          statuses: prevState.statuses.filter((prevStatus) => prevStatus !== status)
                        }));
                      }
                    }}
                    checked={state?.statuses?.includes(status)}
                    name={capitalize(status)}
                    color="primary"
                  />
                }
                label={<Typography style={{ fontSize: "12px" }}>{capitalize(status)}</Typography>}
              />
            ))}
          </div>
          <div
            style={{
              display: "inline-flex",
              verticalAlign: "middle",
              background: "#FF605C30"
            }}
          >
            <div
              style={{
                fontWeight: "500",
                display: "inline-flex",
                padding: "0px 14px",
                marginRight: "10px",
                width: "115px",
                verticalAlign: "middle",
                alignItems: "center",
                background: "rgb(224 224 224)",
                borderRadius: "4px 0px 0px 4px "
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    indeterminate={
                      state?.statuses?.filter((status) => NEGATIVE_STATUSES.includes(status))
                        ?.length > 0 &&
                      state?.statuses?.filter((status) => NEGATIVE_STATUSES.includes(status))
                        ?.length !== NEGATIVE_STATUSES.length
                    }
                    checked={
                      state?.statuses?.length > 0 &&
                      state?.statuses?.filter((status) => NEGATIVE_STATUSES.includes(status))
                        .length === NEGATIVE_STATUSES.length
                    }
                    onChange={(event) => {
                      if (event.target.checked) {
                        setState((prevState) => ({
                          ...prevState,
                          statuses: NEGATIVE_STATUSES
                        }));
                      } else {
                        setState((prevState) => ({
                          ...prevState,
                          statuses: prevState.statuses.filter(
                            (status) => !NEGATIVE_STATUSES.includes(status)
                          )
                        }));
                      }
                    }}
                    name="Negative"
                    color="primary"
                  />
                }
                label={<Typography style={{ fontSize: "12px" }}>Negative statuses</Typography>}
              />
            </div>
            {NEGATIVE_STATUSES.map((status, index) => (
              <FormControlLabel
                key={index}
                control={
                  <Checkbox
                    onChange={(event) => {
                      if (event.target.checked) {
                        setState((prevState) => ({
                          ...prevState,
                          statuses: [...(prevState.statuses ?? []), status]
                        }));
                      } else {
                        setState((prevState) => ({
                          ...prevState,
                          statuses: prevState.statuses.filter((prevStatus) => prevStatus !== status)
                        }));
                      }
                    }}
                    checked={state?.statuses?.includes(status)}
                    name={capitalize(status)}
                    color="primary"
                  />
                }
                label={<Typography style={{ fontSize: "12px" }}>{capitalize(status)}</Typography>}
              />
            ))}
          </div>
        </Paper>
      </Grid>
      <Grid item md={3} sm={6}>
        <Autocomplete
          style={{ zIndex: 6 }}
          value={
            state.allDealerships
              ? ((["All dealerships"] as unknown) as Dealership[])
              : dealershipsAutocomplete || null
          }
          getOptionLabel={(dealership) => {
            if (((dealership as unknown) as string) === "All dealerships") {
              return (dealership as unknown) as string;
            }
            return dealership?.data?.info?.displayName;
          }}
          multiple
          getOptionSelected={(x, y) => x?._id === y?._id && x === y}
          options={[
            ("All dealerships" as unknown) as Dealership,
            ...(dealershipList?.entities ?? [])
          ]}
          onChange={(event, newValue) => {
            if (newValue?.length === 0) {
              setState((prevState) => ({
                ...prevState,
                dealershipIds: [],
                allDealerships: true
              }));
              setDealershipsAutocomplete((["All dealerships"] as unknown) as Dealership[]);
            } else {
              setDealershipsAutocomplete(
                newValue.filter((x) => x !== (("All dealerships" as unknown) as Dealership))
              );
              setState((prevState) => ({
                ...prevState,
                dealershipIds: newValue
                  .filter((x) => x !== (("All dealerships" as unknown) as Dealership))
                  .map((x) => x._id),
                allDealerships: false
              }));
            }
          }}
          loading={!Array.isArray(dealershipList?.entities)}
          openOnFocus
          id="rep-dashboard-dealerships-select"
          renderInput={(params) => (
            <TextField
              {...params}
              InputLabelProps={{ shrink: true }}
              InputProps={{ ...params.InputProps }}
              label={"Dealerships (All time)"}
              variant="filled"
              size="small"
            />
          )}
        />
      </Grid>
      <Grid item md={3} sm={6}>
        <Autocomplete
          style={{ zIndex: 6 }}
          value={
            state.allLenders
              ? ((["All lenders"] as unknown) as Lender[])
              : lendersAutocomplete || null
          }
          getOptionLabel={(lender) => {
            if (((lender as unknown) as string) === "All lenders") {
              return (lender as unknown) as string;
            }
            return lender?.data?.info?.name;
          }}
          multiple
          getOptionSelected={(x, y) => x?._id === y?._id && x === y}
          options={[("All lenders" as unknown) as Lender, ...(lenderList?.entities ?? [])]}
          onChange={(event, newValue) => {
            if (newValue?.length === 0) {
              setState((prevState) => ({
                ...prevState,
                lenderIds: [],
                allLenders: true
              }));
              setLendersAutocomplete((["All lenders"] as unknown) as Lender[]);
            } else {
              setLendersAutocomplete(
                newValue.filter((x) => x !== (("All lenders" as unknown) as Lender))
              );
              setState((prevState) => ({
                ...prevState,
                lenderIds: newValue
                  .filter((x) => x !== (("All lenders" as unknown) as Lender))
                  .map((x) => x._id),
                allLenders: false
              }));
            }
          }}
          loading={!Array.isArray(lenderList?.entities)}
          openOnFocus
          id="rep-dashboard-lenders-select"
          renderInput={(params) => (
            <TextField
              {...params}
              InputLabelProps={{ shrink: true }}
              InputProps={{ ...params.InputProps }}
              label={"Lenders (All time)"}
              variant="filled"
              size="small"
            />
          )}
        />
      </Grid>
      <Grid item md={3} sm={6}>
        <Autocomplete
          style={{ zIndex: 6 }}
          value={state.allStates ? ["All states"] : statesAutocomplete || null}
          getOptionLabel={(state) => {
            return state;
          }}
          multiple
          getOptionSelected={(x, y) => x === y}
          options={["All states", ...(Object.keys(States) ?? [])]}
          onChange={(event, newValue) => {
            if (newValue?.length === 0) {
              setState((prevState) => ({
                ...prevState,
                states: [],
                allStates: true,
                counties: []
              }));
              setStatesAutocomplete(["All states"]);
            } else {
              setStatesAutocomplete(newValue.filter((x) => x !== "All states"));
              setState((prevState) => ({
                ...prevState,
                states: newValue.filter((x) => x !== "All states"),
                allStates: false
              }));
            }
          }}
          openOnFocus
          id="rep-dashboard-states-select"
          renderInput={(params) => (
            <TextField
              {...params}
              InputLabelProps={{ shrink: true }}
              InputProps={{ ...params.InputProps }}
              label={"States (Deals only)"}
              variant="filled"
              size="medium"
            />
          )}
        />
      </Grid>
      <Grid item md={3} sm={6}>
        <Autocomplete
          style={{ zIndex: 6 }}
          value={countiesAutocomplete || null}
          getOptionLabel={(county) => {
            return county;
          }}
          multiple
          getOptionSelected={(x, y) => x === y}
          options={(Object.entries(States) ?? []).reduce(
            (acc, [key, value]) => (state.states.includes(key) ? [...acc, ...value.counties] : acc),
            [] as string[]
          )}
          onChange={(event, newValue) => {
            setCountiesAutocomplete(newValue);
            setState((prevState) => ({
              ...prevState,
              counties: newValue
            }));
          }}
          openOnFocus
          disabled={state.allStates}
          id="rep-dashboard-counties-select"
          renderInput={(params) => (
            <TextField
              {...params}
              disabled={state.allStates}
              InputLabelProps={{ shrink: true }}
              InputProps={{ ...params.InputProps }}
              label={"Counties (Deals only)"}
              variant="filled"
              size="medium"
            />
          )}
        />
      </Grid>
      <Grid md={3} xs={6} item>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            id={"from"}
            name={"from"}
            error={!isValidDate(state.from)}
            helperText={!isValidDate(state.from) ? "Invalid date" : undefined}
            autoOk
            fullWidth
            InputLabelProps={{ shrink: true }}
            inputVariant="filled"
            variant="inline"
            label={"From (Deals only)"}
            required={true}
            format="MM/dd/yyyy"
            KeyboardButtonProps={{
              "aria-label": "change date"
            }}
            size="medium"
            value={state.from ?? null}
            onChange={(value) => {
              if (isValidDate(value) && value) {
                value.setHours(0);
                value.setMinutes(0);
                value.setSeconds(0);
                value.setMilliseconds(0);
                setState((prevState) => ({
                  ...prevState,
                  from: value.toISOString()
                }));
              } else {
                setState((prevState) => ({
                  ...prevState,
                  from: value?.toString() as string
                }));
              }
            }}
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid md={3} xs={6} item>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            id={"to"}
            name={"to"}
            error={!isValidDate(state.to)}
            helperText={!isValidDate(state.to) ? "Invalid date" : undefined}
            autoOk
            fullWidth
            InputLabelProps={{ shrink: true }}
            inputVariant="filled"
            variant="inline"
            label={"To (Deals only)"}
            required={true}
            format="MM/dd/yyyy"
            KeyboardButtonProps={{
              "aria-label": "change date"
            }}
            size="medium"
            value={state.to ?? null}
            onChange={(value) => {
              if (isValidDate(value) && value) {
                value.setHours(23);
                value.setMinutes(59);
                value.setSeconds(59);
                value.setMilliseconds(59);
                setState((prevState) => ({
                  ...prevState,
                  to: value.toISOString()
                }));
              } else {
                setState((prevState) => ({
                  ...prevState,
                  to: value?.toString() as string
                }));
              }
            }}
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item md={2} sm={6}>
        <div style={{ display: "flex" }}>
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={state?.showDealerships}
                  onChange={(_, checked) => {
                    setState((state) => ({
                      ...state,
                      showDealerships: !state?.showDealerships
                    }));
                  }}
                />
              }
              label={"Show dealerships"}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={state?.showLenders}
                  onChange={(_, checked) => {
                    setState((state) => ({
                      ...state,
                      showLenders: !state?.showLenders
                    }));
                  }}
                />
              }
              label={"Show lenders"}
            />
          </div>
        </div>
      </Grid>
      <Grid item md={2}></Grid>
      <Grid item md={2} style={{ display: "flex", justifyContent: "flex-end" }}>
        <ExportToExcel deals={deals} />
      </Grid>
      <Grid item xs={12}>
        {isFetching ? (
          <Typography style={{ textAlign: "center", fontSize: "24px", fontWeight: "bold" }}>
            Loading...(this may take a while) <LoopIconWrapper />
          </Typography>
        ) : !process.env.REACT_APP_GOOGLE_MAPS_API_KEY ? (
          <Typography>No api key for maps</Typography>
        ) : !isValidDate(state.from) || !isValidDate(state.to) ? (
          <Typography
            style={{ textAlign: "center", fontSize: "24px", fontWeight: "bold", color: "red" }}
          >
            Invalid date range
          </Typography>
        ) : (data?.deals?.length || 0) > 0 && statusByDealershipGeojsons ? (
          <APIProvider
            key={JSON.stringify(deals?.map((x) => x._id))}
            apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
          >
            <Map
              id="map"
              style={{ height: 600 }}
              defaultZoom={zoom}
              mapId="40a79edf4d537bf1" // monochrome map id (gcp dev)
              mapTypeId="roadmap"
              onCenterChanged={(center) => setCenter(center.detail.center)}
              onZoomChanged={(zoomEvent) => setZoom(zoomEvent.detail.zoom)}
              defaultCenter={center}
              gestureHandling={"greedy"}
              disableDefaultUI
            >
              {Object.entries(statusByDealershipGeojsons).map(([dealership, data]) =>
                Object.entries(data).map(([status, geojson], index) => (
                  <ClusteredMarkers
                    key={`${dealership}-${status}-${index}`}
                    clusterColor={getClusterColor(
                      status as "positive" | "negative" | "in progress" | "false negative"
                    )}
                    geojson={geojson}
                    clusterClickCallback={(infowindowData) => {
                      setStatus(status as DealStatus);
                      setDealershipForInfoWindow(dealership);
                      setDealInfowindowData(infowindowData);
                    }}
                    markerClickCallback={(infowindowData) => setDealInfowindowData(infowindowData)}
                  />
                ))
              )}
              {state?.showDealerships &&
                dealershipGeojsons &&
                dealershipGeojsons.map((dealershipGeojsonData, index) => {
                  const dealershipFeature = dealershipGeojsonData.geojson.features[0];
                  const [lng, lat] = dealershipFeature.geometry.coordinates;
                  return (
                    <FeatureMarkerDealership
                      key={`${dealershipGeojsonData.dealership}-${index}`}
                      featureId={dealershipFeature.id as string}
                      position={{ lat, lng }}
                      onMarkerClick={(marker) => {
                        setDealershipInfoWindowData({
                          anchor: marker,
                          feature: dealershipFeature
                        });
                      }}
                    />
                  );
                })}
              {state?.showLenders &&
                lenderGeojsons &&
                lenderGeojsons.map((lenderGeojsonData, index) => {
                  const lenderFeature = lenderGeojsonData.geojson.features[0];
                  const [lng, lat] = lenderFeature.geometry.coordinates;
                  return (
                    <FeatureMarkerLender
                      key={`${lenderGeojsonData.lender}-${index}`}
                      featureId={lenderFeature.id as string}
                      position={{ lat, lng }}
                      onMarkerClick={(marker) => {
                        setLenderInfoWindowData({
                          anchor: marker,
                          feature: lenderFeature
                        });
                      }}
                    />
                  );
                })}

              {dealInfowindowData && (
                <InfoWindow
                  style={{ minWidth: "230px" }}
                  onCloseClick={handleDealInfoWindowClose}
                  anchor={dealInfowindowData.anchor}
                >
                  <DealInfoWindowContent
                    features={dealInfowindowData.features}
                    status={status}
                    dealership={dealershipForInfoWindow}
                  />
                </InfoWindow>
              )}
              {dealershipInfoWindowData && (
                <InfoWindow
                  onCloseClick={handleDealershipInfoWindowClose}
                  anchor={dealershipInfoWindowData.anchor}
                >
                  <DealershipInfoWindowContent feature={dealershipInfoWindowData.feature} />
                </InfoWindow>
              )}
              {lenderInfoWindowData && (
                <InfoWindow
                  onCloseClick={handleLenderInfoWindowClose}
                  anchor={lenderInfoWindowData.anchor}
                >
                  <LenderInfoWindowContent feature={lenderInfoWindowData.feature} />
                </InfoWindow>
              )}
            </Map>

            <Grid container item xs={12} style={{ marginTop: "10px" }}>
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "5px"
                }}
              >
                <Typography variant="h6">Type Legend</Typography>
                <Tooltip
                  title={
                    <div style={{ padding: 5 }}>
                      <Typography style={{ padding: 0, fontSize: "15px", lineHeight: 1.2 }}>
                        {
                          "The map’s markers and clusters are annotated with specific data. By clicking on them, you can initiate interactions and access pop-up windows that display the relevant information."
                        }
                      </Typography>
                    </div>
                  }
                  arrow
                  placement="top"
                >
                  <div>
                    <IoMdHelpCircle cursor={"pointer"} color="#706f70" size={25} />
                  </div>
                </Tooltip>
              </Grid>
              <Grid item xs={12} style={{ justifyContent: "center", display: "flex" }}>
                <div
                  style={{
                    width: "70%",
                    textAlign: "center",
                    borderTop: "1px solid rgb(235 235 235)"
                  }}
                ></div>
              </Grid>
              {Object.entries(reduceDealsByStatusGroup(deals, state.statuses))
                .sort(([status, _]) =>
                  status === "positive" ? -1 : status === "in progress" ? 0 : 1
                )
                .map(([status, deals]) => (
                  <Grid
                    item
                    xs={status === "false negative" ? 3 : 2}
                    key={status}
                    style={{ display: "flex", alignItems: "center", padding: "10px", gap: "15px" }}
                  >
                    <div
                      style={{
                        width: 25,
                        height: 25,
                        borderRadius: "100%",
                        background: getClusterColor(
                          status as "positive" | "negative" | "in progress"
                        ),
                        display: "inline-block"
                      }}
                    ></div>
                    <Typography>
                      {capitalize(status)} applications - {deals.length}
                    </Typography>
                    {status === "false negative" && (
                      <Tooltip
                        title={
                          <div style={{ padding: 5 }}>
                            <Typography style={{ padding: 0, fontSize: "15px", lineHeight: 1.2 }}>
                              Applications that have current negative status, except{" "}
                              <strong>&quot;{capitalize(DealStatus.DidNotProcess)}&quot;</strong>:
                            </Typography>
                            <List dense>
                              {NEGATIVE_STATUSES.filter((x) => x !== DealStatus.DidNotProcess).map(
                                (x, i) => (
                                  <ListItem style={{ padding: "0px 10px" }} key={i}>
                                    <Typography style={{ fontWeight: "bold", fontSize: "12px" }}>
                                      {capitalize(x)}
                                    </Typography>
                                  </ListItem>
                                )
                              )}
                            </List>
                            <Typography style={{ padding: 0, fontSize: "15px", lineHeight: 1.2 }}>
                              but were in mid progress:
                            </Typography>
                            <List dense>
                              {MID_PROGRESS_STATUSES.map((x, i) => (
                                <ListItem style={{ padding: "0px 10px" }} key={i}>
                                  <Typography style={{ fontWeight: "bold", fontSize: "12px" }}>
                                    {capitalize(x)}
                                  </Typography>
                                </ListItem>
                              ))}
                            </List>
                          </div>
                        }
                        arrow
                        placement="top"
                      >
                        <div>
                          <IoMdHelpCircle cursor={"pointer"} color="#706f70" size={25} />
                        </div>
                      </Tooltip>
                    )}
                  </Grid>
                ))}
              <Grid
                item
                xs={2}
                key={status}
                style={{ display: "flex", alignItems: "center", padding: "10px", gap: "15px" }}
              >
                <FaCar size={25} color={"	#905424"} />
                <Typography>Dealerships - {dealerships.length}</Typography>
              </Grid>
              <Grid
                item
                xs={1}
                key={status}
                style={{ display: "flex", alignItems: "center", padding: "10px", gap: "15px" }}
              >
                <AiFillBank size={25} color={" #68BBE3"} />
                Lenders - {lenders.length}
              </Grid>
            </Grid>
            <Grid container item xs={12} style={{ marginTop: "10px" }}>
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "5px"
                }}
              >
                <Typography variant="h6">Size Legend</Typography>
                <Tooltip
                  title={
                    <div style={{ padding: 5 }}>
                      <Typography style={{ padding: 0, fontSize: "15px", lineHeight: 1.2 }}>
                        {
                          "Shows the distribution of deals by the amount financed. The size of the circles represents the number of deals in the given range."
                        }
                      </Typography>
                    </div>
                  }
                  arrow
                  placement="top"
                >
                  <div>
                    <IoMdHelpCircle cursor={"pointer"} color="#706f70" size={25} />
                  </div>
                </Tooltip>
              </Grid>
              <Grid item xs={12} style={{ justifyContent: "center", display: "flex" }}>
                <div
                  style={{
                    width: "70%",
                    textAlign: "center",
                    borderTop: "1px solid rgb(235 235 235)"
                  }}
                ></div>
              </Grid>
              <Grid
                item
                xs={2}
                key={status}
                style={{ display: "flex", alignItems: "center", padding: "10px", gap: "15px" }}
              >
                <FaCircle
                  style={{ minWidth: "25px" }}
                  size={sizeByAmountFinanced(0) * 0.5}
                  color={" #00CA4E"}
                />
                {"0$ - $10,000"}
              </Grid>
              {[
                [10_000, 20_000],
                [20_000, 30_000],
                [30_000, 40_000],
                [40_000, 50_000],
                [50_000, 100_000]
              ].map(([min, max], index) => (
                <Grid
                  item
                  xs={2}
                  key={`${status}-${index}`}
                  style={{ display: "flex", alignItems: "center", padding: "10px", gap: "15px" }}
                >
                  <FaCircle
                    size={sizeByAmountFinanced(min + 1_000) * 0.5}
                    color={" #00CA4E"}
                    style={{ minWidth: "25px" }}
                  />
                  <Typography>
                    {`${formatNumberAsCurrency(min, "$")} - ${formatNumberAsCurrency(max, "$")}`}
                  </Typography>
                </Grid>
              ))}
              <Grid
                item
                xs={2}
                key={status}
                style={{ display: "flex", alignItems: "center", padding: "10px", gap: "15px" }}
              >
                <FaCircle
                  size={sizeByAmountFinanced(101_000) * 0.5}
                  color={" #00CA4E"}
                  style={{ minWidth: "25px" }}
                />
                <Typography>{"$100,000+"}</Typography>
              </Grid>
            </Grid>
          </APIProvider>
        ) : (
          <Typography style={{ textAlign: "center", fontSize: "24px", fontWeight: "bold" }}>
            No data
          </Typography>
        )}
      </Grid>
    </Grid>
  );
}
