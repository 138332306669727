import { RootState } from "app/rootReducer";
import { getLatestActiveContractFile } from "components/Contracts/ContractDatesPreview";
import { collateralTypes } from "components/Deals/types";
import DateFilter from "components/Filters/DateFilter";
import DealershipProgramFilter from "components/Filters/DealershipProgramFilter";
import DealershipSignedDateFilter from "components/Filters/DealershipSignedDateFilter";
import MultiSelectFilter from "components/Filters/MultiSelectFilter";
import MultipleCheckboxFilter from "components/Filters/MultipleCheckboxFilter";
import PhoneFilter from "components/Filters/PhoneFilter";
import RelationTypeFilter from "components/Filters/RelationTypeFilter";
import TextFilter from "components/Filters/TextFilter";
import UserFilter from "components/Filters/UserFilter";
import { hasPermission } from "components/Roles/permissionCheck";
import React from "react";
import { useSelector } from "react-redux";
import States from "us-states";
import Table, { CellValue, Column } from "../Table";
import { deleteDealership } from "./deleteDealershipSlice";
import { getDealershipList } from "./listDealershipSlice";
import { recoverDealership } from "./recoverDealershipSlice";
import { FormState } from "./types";
export const projections = {
  "data.info.displayName": 1,
  "data.info.address": 1,
  "data.info.city": 1,
  "data.info.state": 1,
  "data.info.county": 1,
  "data.info.zipCode": 1,
  "data.info.faxNumber": 1,
  "data.info.collateralTypes": 1,
  "data.contacts": 1,
  "data.info.relationType": 1,
  "data.info.program.type": 1,
  "data.representative.data.info.firstName": 1,
  "data.representative.data.info.lastName": 1,
  "data.info.abbreviation": 1,
  "data.dealershipProgram.data.info.name": 1,
  approvedByUserId: 1,
  approved: 1,
  approvedByUser: 1,
  "data.contracts": 1,
  _id: 1,
  createdAt: 1,
  creatorId: 1,
  approvedAt: 1,
  updatedAt: 1,
  deleted: 1
};
export default (): JSX.Element => {
  const dealershipList = useSelector((state: RootState) => state.listDealershipSlice["table"]);

  const columns: Column<FormState>[] = [
    {
      label: "Display Name",
      name: "displayName",
      getData: (entry): CellValue => entry.data?.info?.displayName,
      options: {
        sort: true,
        path: ["data", "info", "displayName"]
      },
      show: (userPermissions) => {
        return (
          userPermissions?.read.hasPermission &&
          hasPermission(userPermissions?.read.dataPermissions, ["data", "info", "displayName"])
        );
      },
      filters: [
        {
          path: ["data", "info", "displayName"],
          preview: TextFilter,
          caseInsensitive: true,
          partialSearch: false
        }
      ]
    },
    {
      label: "Address",
      getData: (entry): CellValue => entry.data?.info?.address,
      name: "address",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.address?.show;
      },
      filters: [
        {
          path: ["data", "info", "address"],
          preview: TextFilter,
          caseInsensitive: true,
          partialSearch: true
        }
      ]
    },
    {
      label: "City",
      getData: (entry): CellValue => entry.data?.info?.city,
      options: {
        sort: true,
        path: ["data", "info", "city"]
      },
      name: "city",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.city?.show;
      },
      filters: [
        {
          path: ["data", "info", "city"],
          preview: TextFilter,
          caseInsensitive: true,
          partialSearch: true
        }
      ]
    },
    {
      label: "State",
      getData: (entry): CellValue => entry?.data?.info?.state ?? "",
      options: {
        sort: true,
        path: ["data", "info", "state"]
      },
      name: "state",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.state?.show;
      },
      filters: [
        {
          path: ["data", "info", "state"],
          preview: MultiSelectFilter,
          valuesForSelect: Object.keys(States).sort()
        }
      ]
    },
    {
      label: "County",
      getData: (entry): CellValue => entry?.data?.info?.county ?? "",
      options: {
        sort: true,
        path: ["data", "info", "county"]
      },
      name: "county",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.county?.show;
      },
      filters: [
        {
          path: ["data", "info", "county"],
          preview: TextFilter,
          caseInsensitive: true,
          partialSearch: true
        }
      ]
    },
    {
      label: "Zip code",
      getData: (entry): CellValue => entry?.data?.info?.zipCode?.toString() ?? "",
      options: {
        sort: true,
        path: ["data", "info", "zipCode"]
      },
      name: "zipCode",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.zipCode?.show;
      },
      filters: [
        {
          path: ["data", "info", "zipCode"],
          preview: TextFilter,
          partialSearch: true
        }
      ]
    },
    {
      label: "Collateral types",
      getData: (entry): CellValue =>
        Object.entries(entry?.data?.info?.collateralTypes ?? {})
          .filter(([key, value]) => {
            return value;
          })
          .map(([key]) => key)
          .join(","),
      filters: [
        {
          path: collateralTypes.map((t) => ["data", "info", "collateralTypes", t]),
          preview: MultipleCheckboxFilter
        }
      ],
      name: "collateralTypes",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.collateralTypes?.show;
      }
    },
    {
      label: "Contact",
      getData: (entry): CellValue =>
        entry.data?.contacts?.[0]
          ? `${entry.data?.contacts?.[0]?.firstName ?? ""} ${
              entry.data?.contacts?.[0]?.lastName ?? ""
            }`
          : "",
      name: "contact",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.contact?.show;
      },
      filters: [
        {
          path: ["data", "info", "zipCode"],
          preview: TextFilter
        }
      ]
    },
    {
      name: "contactPhone",
      label: "Contact Phone",
      getData: (entry): CellValue =>
        entry.data?.contacts?.[0]
          ? entry.data?.contacts?.[0]?.phone
              ?.toString()
              .replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3") ?? "No phone!"
          : "",
      filters: [
        {
          path: ["data", "contacts", "0", "phone"],
          preview: PhoneFilter
        }
      ]
    },
    {
      name: "faxNumber",
      label: "Fax Number",
      getData: (entry): CellValue =>
        entry.data?.info?.faxNumber?.toString()?.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3") ??
        "No fax number!",
      filters: [
        {
          path: ["data", "info", "faxNumber"],
          preview: PhoneFilter
        }
      ]
    },
    {
      name: "contactEmail",
      label: "Contact Email",
      getData: (entry): CellValue =>
        entry.data?.contacts?.[0] ? entry.data.contacts[0].email ?? "No email!" : "",
      filters: [
        {
          path: ["data", "contacts", "0", "email"],
          preview: TextFilter
        }
      ]
    },
    {
      label: "Relation type",
      getData: (entry): CellValue =>
        entry?.data?.info?.relationType === "fulltimeF&I"
          ? "Full Time F&I"
          : entry?.data?.info?.relationType === "correspondent"
          ? "Correspondent"
          : "",
      name: "relationType",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.relationType?.show;
      },
      filters: [
        {
          path: ["data", "info", "relationType"],
          preview: RelationTypeFilter
        }
      ]
    },
    // {
    //   label: "Program type",
    //   getData: (entry): CellValue => {
    //     return entry?.data?.dealershipProgram?.data?.info?.name ?? "";
    //   },
    //   name: "programType",
    //   show: (userPermissions, tableSettings) => {
    //     return tableSettings?.data?.columns?.programType?.show;
    //   },
    //   filters: [
    //     {
    //       path: ["data", "dealershipProgramId"],
    //       preview: DealershipProgramFilter
    //     }
    //   ]
    // },
    {
      label: "Rep",
      getData: (entry): CellValue =>
        `${entry.data?.representative?.data?.info?.firstName ?? ""} ${
          entry.data?.representative?.data?.info?.lastName ?? ""
        }`,
      name: "rep",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.rep?.show;
      },
      filters: [
        {
          path: ["data", "representativeId"],
          type: "representative",
          preview: UserFilter
        }
      ]
    },
    {
      getData: (entry): CellValue => entry._id,
      label: "Approver",
      name: "approver",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.approver?.show;
      },
      filters: [
        {
          path: ["approvedByUserId"],
          preview: UserFilter,
          label: "Approver"
        }
      ]
    },
    {
      getData: (entry): CellValue => {
        const agreementContracts =
          entry.data?.contracts?.filter((contract) =>
            contract.data.contractTypes?.find(
              (contractType) => contractType.data.info.type === "Dealer Agreement"
            )
          ) ?? [];
        const latestDealerAgreementContract = agreementContracts.map((contract) => ({
          ...contract,
          data: {
            ...contract.data,
            files: contract.data?.files?.filter((file) => file.data.info.status === "Active")
          }
        }));
        const lastestContractFile = getLatestActiveContractFile(
          latestDealerAgreementContract[0]?.data?.files
        );
        return lastestContractFile &&
          lastestContractFile?.data.info?.contractDates &&
          lastestContractFile?.data.info?.contractDates?.signedDate
          ? new Date(lastestContractFile?.data.info.contractDates.signedDate).toLocaleDateString()
          : "—";
      },
      label: "Signed Date",
      name: "signedDate",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.signedDate?.show;
      },
      filters: [
        {
          path: [
            "data",
            "contracts",
            "data",
            "files",
            "data",
            "info",
            "contractDates",
            "signedDate"
          ],
          preview: DealershipSignedDateFilter,
          label: "Signed Date",
          name: "signedDate"
        }
      ]
    },
    {
      getData: (entry): CellValue => (entry.createdAt ? new Date(entry.createdAt) : ""),
      label: "Created At",
      options: {
        sort: true,
        path: ["createdAt"]
      },
      name: "createdAt",
      show: (userPermissions, tableSettings) => {
        return true;
      },
      filters: [
        {
          path: ["createdAt"],
          preview: DateFilter,
          label: "Created At"
        }
      ]
    },
    {
      getData: (entry): CellValue => (entry.approvedAt ? new Date(entry.approvedAt) : ""),
      label: "Approved At",
      options: {
        sort: true,
        path: ["approvedAt"]
      },
      name: "createdAt",
      show: (userPermissions, tableSettings) => {
        return true;
      },
      filters: [
        {
          path: ["approvedAt"],
          preview: DateFilter,
          label: "Approved At"
        }
      ]
    },
    {
      label: "Actions",
      getData: (entry): CellValue => entry._id,
      name: "actions",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.actions?.show;
      }
    }
  ];

  return (
    <div style={{ position: "relative" }}>
      {/* <AccessControl requiredPermissions={{ entity: "dealership", action: "read" }}> */}
      <Table
        slice="table"
        tableName="dealerships"
        entityName="dealership"
        listFunction={getDealershipList}
        deleteEntityFunction={deleteDealership}
        recoverEntityFunction={recoverDealership}
        sort={{ "data.info.displayName": "asc" }}
        listEntity={dealershipList}
        title={"Dealerships"}
        columns={columns}
        projection={projections}
      />
      {/* </AccessControl> */}
    </div>
  );
};
