import React, { useState } from "react";
import { Dialog, DialogContent, DialogTitle, IconButton, Tooltip } from "@material-ui/core";
import BorderColorIcon from "@material-ui/icons/BorderColor";
import WarningIcon from "@material-ui/icons/Warning";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { useSelector } from "react-redux";
import { RootState } from "app/rootReducer";
import { getDealListByIds } from "components/Deals/listDealSliceByIds";
import { columns, projections } from "components/Deals/Deals";
import CloseDialogButton from "components/common/CloseDialogButton";
import Table from "components/Table";

export default function ({ dealIds }: { dealIds: string[] }) {
  const [tableDialog, setTableDialog] = useState(false);

  return (
    <div style={{ position: "relative" }}>
      <IconButton
        onClick={() => {
          setTableDialog(true);
        }}
        size={"small"}
        color="primary"
      >
        <Tooltip title={"View deals"}>
          <VisibilityIcon />
        </Tooltip>
      </IconButton>
      {tableDialog && <DealsModal dealIds={dealIds} closeFunction={() => setTableDialog(false)} />}
    </div>
  );
}
type DealsModalProps = {
  closeFunction: () => void;
  dealIds: string[];
};
const DealsModal = ({ closeFunction, dealIds }: DealsModalProps) => {
  const slice = `credit-application-deals-${dealIds?.join("-")}`;
  const dealList = useSelector((state: RootState) => state.listDealSliceByIds);

  return (
    <Dialog
      id="credit-application-deals-dialog"
      onClose={closeFunction}
      aria-labelledby="customized-dialog-title"
      open={true}
      fullWidth
      maxWidth="xl"
    >
      <DialogTitle id="customized-dialog-title">Credit application deals</DialogTitle>
      <CloseDialogButton closeFunction={closeFunction} />
      <DialogContent>
        <Table
          tableContainerOverflowX="scroll"
          projection={projections}
          tableName={`Credit application deals`}
          entityName="deal"
          listFunction={getDealListByIds}
          listEntity={dealList[slice]}
          query={{ _id: { $in: dealIds } }}
          sort={{ createdAt: "desc" }}
          title={"Credit application deals"}
          slice={slice}
          columns={columns}
          hideDeleteButton={true}
          hideAddButton={true}
          hideRecoverButton={true}
        />
      </DialogContent>
    </Dialog>
  );
};
