import { Button, Dialog, DialogContent, DialogTitle, Grid, Tooltip } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import { Send } from "@material-ui/icons";
import ErrorIcon from "@material-ui/icons/Error";
import MotorcycleIcon from "@material-ui/icons/Motorcycle";
import StarRateIcon from "@material-ui/icons/StarRate";
import { RootState } from "app/rootReducer";
import AlertDialog from "components/common/AlertDialog";
import AddEntity from "components/Content/addEntity";
import { Dealership } from "components/Dealerships/types";
import React, { useContext, useState } from "react";
import ReactDOM from "react-dom";
import { useSelector } from "react-redux";
import { capitalize, getPrefixByState } from "utils/functions";
import { StateAccess, setByPath } from "utils/models/formGenerator";
import { Applicant, Info as ApplicantInfo } from "../Applicants/types";
import CloseDialogButton from "../common/CloseDialogButton";
import formEditContext from "../Content/FormEditContext";
import modalContainerContext from "../Content/modalContainer";
import { CollateralTypes, Deal, Payment, Price, TradeIn, Vehicle } from "./types";
type OurEmploymentStatus = "Employee" | "Business owner" | "Retired" | undefined;
type DealertrackEmploymentStatus =
  | "Employed"
  | "SelfEmployed"
  | "Student"
  | "Retired"
  | "ActiveMilitary"
  | "Unemployed"
  | "RetiredMilitary"
  | "Other"
  | undefined;

export const dealerTrackCollateralTypes = [
  "Auto",
  "Motorcycle",
  "Industrial",
  "Marine",
  "RV",
  "Consumer",
  "Leisure",
  "HomeImp",
  "Generic",
  "ATV/Snowmobile",
  "PersonalWatercraft"
] as const;
export type DealerTrackCollateralType = typeof dealerTrackCollateralTypes[number];

const resolveEmploymentStatus = (status: OurEmploymentStatus): DealertrackEmploymentStatus => {
  switch (status) {
    case "Employee":
      return "Employed";
    case "Business owner":
      return "SelfEmployed";
    case "Retired":
      return "Retired";
  }
};

const resolveHousingStatus = (
  status: "Owner" | "Rent" | "Family" | "Other" | undefined
): "Mortgage" | "Rent" | "Family" | "OwnOutright" | "Other" | "Military" | undefined => {
  switch (status) {
    case "Owner":
      return "Mortgage";
    case "Rent":
      return "Rent";
    case "Family":
      return "Family";
    case "Other":
      return "Other";
  }
};

const formatPhone = (phone: string | undefined): string | undefined => {
  if (typeof phone !== "string" || phone?.length < 5) return;
  return phone.replace(/\+1|\D+/g, "");
};

const resolveVehicleType = (type: CollateralTypes): DealerTrackCollateralType | null => {
  switch (type) {
    case "Automotive":
      return "Auto";
    case "Recreational Vehicles":
      return "RV";
    case "Marine":
      return "Marine";
    default:
      return null;
  }
};
const roundOrNull = (x: unknown) => (typeof x === "number" ? Math.round(x) : null);
const resolveMaritalStatus = (status: Applicant["data"]["info"]["maritalStatus"] | undefined) => {
  switch (status) {
    case "Married":
      return "Married";
    case "Not married":
      return "Unmarried";
    default:
      return null;
  }
};
export const initializeDealerTrackData = (stateAccess: StateAccess) => (initialState: any) => {
  const { _id, data: dealData }: Deal = stateAccess.get([]);
  const applicantInfo: ApplicantInfo | null = dealData?.applicant?.data?.info ?? null;
  const coApplicantInfo: ApplicantInfo | null = dealData?.coApplicant?.data?.info ?? null;
  const vehicle: Vehicle | null = dealData?.info?.vehicle ?? null;
  const price: Price | null = dealData?.info?.price ?? null;
  const payment: Payment | null = dealData?.info?.payment ?? null;
  const dealership: Dealership | null = dealData?.dealership ?? null;
  const partyId =
    dealData?.lender?.data?.info?.routes?.[dealData?.info?.type]?.dealertrack?.partyId ?? null;

  return [
    { value: dealership, path: ["data", "dealership"] },
    { value: _id, path: ["data", "dealId"] },
    { value: "JRB", path: ["data", "sourcePartnerId"] },
    {
      value: [
        {
          id: "DTC",
          partyId:
            process.env.REACT_APP_FIREBASE_PROJECT_ID !== "wfd-frontend"
              ? "Web Finance Direct"
              : partyId ?? "Web Finance Direct"
        }
      ],
      path: ["data", "targetPlatforms"]
    },
    { value: "Finance", path: ["data", "financeMethod"] },
    //Applicant
    {
      value: applicantInfo?.firstName?.replace(/[^A-Za-z\s]/gi, "")?.trim() ?? null,
      path: ["data", "applicant", "firstName"]
    },
    {
      value: applicantInfo?.lastName?.replace(/[^A-Za-z\s]/gi, "")?.trim() ?? null,
      path: ["data", "applicant", "lastName"]
    },
    {
      value: applicantInfo?.middleName?.[0]?.trim() ?? null,
      path: ["data", "applicant", "middleName"]
    },
    {
      value: applicantInfo?.suffix ?? null,
      path: ["data", "applicant", "suffix"]
    },
    {
      value: formatPhone(applicantInfo?.mobilePhone) ?? null,
      path: ["data", "applicant", "phone"]
    },
    {
      value: applicantInfo?.socialSecurityNumber ?? null,
      path: ["data", "applicant", "ssn"]
    },
    {
      value: applicantInfo?.birthDate ?? null,
      path: ["data", "applicant", "dateOfBirth"]
    },
    {
      value: applicantInfo?.currentAddress
        ? [applicantInfo?.currentAddressNumber, applicantInfo?.currentAddress].join(" ")
        : null,
      path: ["data", "applicant", "address", "line1"]
    },

    {
      value: applicantInfo?.currentCity ?? null,
      path: ["data", "applicant", "address", "city"]
    },
    {
      value: getPrefixByState(applicantInfo?.currentState) ?? null,
      path: ["data", "applicant", "address", "state"]
    },
    {
      value: applicantInfo?.currentZipCode ?? null,
      path: ["data", "applicant", "address", "postalCode"]
    },
    {
      value: applicantInfo?.driverLicenseNumber ?? null,
      path: ["data", "applicant", "driversLicenseNumber"]
    },
    {
      value: getPrefixByState(applicantInfo?.drivingLicenseState) ?? null,
      path: ["data", "applicant", "driversLicenseState"]
    },
    {
      value: applicantInfo?.yearsAtCurrentAddress ?? null,
      path: ["data", "applicant", "yearsAtCurrentAddress"]
    },
    {
      value: applicantInfo?.monthsAtCurrentAddress ?? null,
      path: ["data", "applicant", "monthsAtCurrentAddress"]
    },
    {
      value: applicantInfo?.yearsAtPreviousAddress ?? null,
      path: ["data", "applicant", "yearsAtPreviousAddress"]
    },
    {
      value: applicantInfo?.monthsAtPreviousAddress ?? null,
      path: ["data", "applicant", "monthsAtPreviousAddress"]
    },
    {
      value: applicantInfo?.email ?? null,
      path: ["data", "applicant", "email"]
    },
    {
      value: applicantInfo?.employer ?? null,
      path: ["data", "applicant", "currentEmployment", "employerName"]
    },
    {
      value: applicantInfo?.yearsAtCurrentJob ?? null,
      path: ["data", "applicant", "currentEmployment", "totalYearsEmployed"]
    },
    {
      value: applicantInfo?.monthsAtCurrentJob ?? null,
      path: ["data", "applicant", "currentEmployment", "totalMonthsEmployed"]
    },
    {
      value: applicantInfo?.jobOccupation ?? null,
      path: ["data", "applicant", "currentEmployment", "occupation"]
    },
    {
      value: formatPhone(applicantInfo?.businessPhone) ?? null,
      path: ["data", "applicant", "currentEmployment", "workPhone"]
    },
    {
      value: resolveEmploymentStatus(applicantInfo?.employmentStatus) ?? "Employed",
      path: ["data", "applicant", "currentEmployment", "status"]
    },
    {
      value:
        applicantInfo && applicantInfo?.annualIncome > 0
          ? roundOrNull(applicantInfo?.annualIncome)
          : applicantInfo?.monthlyIncome
          ? roundOrNull(applicantInfo?.monthlyIncome)
          : roundOrNull(applicantInfo?.retirementIncome),
      path: ["data", "applicant", "income"]
    },
    {
      value: applicantInfo && applicantInfo?.annualIncome > 0 ? "Annually" : "Monthly",
      path: ["data", "applicant", "incomeFrequency"]
    },
    {
      value:
        roundOrNull(
          (applicantInfo?.additionalIncomes ?? [])?.reduce(
            (acc, additionalIncome) => acc + (additionalIncome.value || 0),
            0
          )
        ) || null,
      path: ["data", "applicant", "otherMonthlyIncome"]
    },
    {
      value:
        (applicantInfo?.additionalIncomes ?? [])
          ?.filter((x) => x.source)
          .map((x) => x.source)
          .join(", ") ?? null,
      path: ["data", "applicant", "otherMonthlyIncomeSource"]
    },
    {
      value: resolveMaritalStatus(applicantInfo?.maritalStatus) ?? null,
      path: ["data", "applicant", "maritalStatus"]
    },
    {
      value: resolveHousingStatus(applicantInfo?.propertyOwnership) ?? null,
      path: ["data", "applicant", "housingStatus"]
    },
    {
      value:
        roundOrNull(applicantInfo?.mortgagePerMonth) ||
        roundOrNull(applicantInfo?.rentPerMonth) ||
        0,

      path: ["data", "applicant", "mortgageOrRentAmount"]
    },
    {
      value: applicantInfo?.previousAddress
        ? [applicantInfo?.previousAddressNumber, applicantInfo?.previousAddress].join(" ")
        : null,
      path: ["data", "applicant", "previousAddress", "line1"]
    },
    {
      value: applicantInfo?.previousCity ?? null,
      path: ["data", "applicant", "previousAddress", "city"]
    },

    {
      value: getPrefixByState(applicantInfo?.previousState) ?? null,
      path: ["data", "applicant", "previousAddress", "state"]
    },
    {
      value: applicantInfo?.previousZipCode ?? null,
      path: ["data", "applicant", "previousAddress", "postalCode"]
    },
    {
      value: applicantInfo?.previousEmployer ?? null,
      path: ["data", "applicant", "previousEmployment", "employerName"]
    },
    {
      value: applicantInfo?.previousOccupation ?? null,
      path: ["data", "applicant", "previousEmployment", "occupation"]
    },
    {
      value: applicantInfo?.yearsAtPreviousJob ?? null,
      path: ["data", "applicant", "previousEmployment", "totalYearsEmployed"]
    },
    {
      value: applicantInfo?.monthsAtPreviousJob ?? null,
      path: ["data", "applicant", "previousEmployment", "totalMonthsEmployed"]
    },

    {
      value: "English",
      path: ["data", "applicant", "preferredLanguage"]
    },
    //CoApplicant
    ...(coApplicantInfo !== null
      ? [
          {
            value: coApplicantInfo !== null,
            path: ["data", "includeCoApplicant"]
          },
          {
            value: "English",
            path: ["data", "coApplicant", "preferredLanguage"]
          },
          {
            value: coApplicantInfo?.firstName?.replace(/[^A-Za-z\s]/gi, "")?.trim() ?? null,
            path: ["data", "coApplicant", "firstName"]
          },
          {
            value: coApplicantInfo?.lastName?.replace(/[^A-Za-z\s]/gi, "")?.trim() ?? null,
            path: ["data", "coApplicant", "lastName"]
          },
          {
            value: coApplicantInfo?.middleName?.[0] ?? null,
            path: ["data", "coApplicant", "middleName"]
          },
          {
            value: coApplicantInfo?.suffix ?? null,
            path: ["data", "coApplicant", "suffix"]
          },
          {
            value: formatPhone(coApplicantInfo?.mobilePhone) ?? null,
            path: ["data", "coApplicant", "phone"]
          },
          {
            value: coApplicantInfo?.socialSecurityNumber ?? null,
            path: ["data", "coApplicant", "ssn"]
          },
          {
            value: coApplicantInfo?.birthDate ?? null,
            path: ["data", "coApplicant", "dateOfBirth"]
          },
          {
            value: coApplicantInfo?.currentAddress
              ? [coApplicantInfo?.currentAddressNumber, coApplicantInfo?.currentAddress].join(" ")
              : null,
            path: ["data", "coApplicant", "address", "line1"]
          },
          {
            value: coApplicantInfo?.currentCity ?? null,
            path: ["data", "coApplicant", "address", "city"]
          },
          {
            value: getPrefixByState(coApplicantInfo?.currentState) ?? null,
            path: ["data", "coApplicant", "address", "state"]
          },
          {
            value: coApplicantInfo?.currentZipCode ?? null,
            path: ["data", "coApplicant", "address", "postalCode"]
          },
          {
            value: coApplicantInfo?.driverLicenseNumber ?? null,
            path: ["data", "coApplicant", "driversLicenseNumber"]
          },
          {
            value: getPrefixByState(coApplicantInfo?.drivingLicenseState) ?? null,
            path: ["data", "coApplicant", "driversLicenseState"]
          },
          {
            value: coApplicantInfo?.yearsAtCurrentAddress ?? null,
            path: ["data", "coApplicant", "yearsAtCurrentAddress"]
          },
          {
            value: coApplicantInfo?.monthsAtPreviousAddress ?? null,
            path: ["data", "coApplicant", "monthsAtCurrentAddress"]
          },
          {
            value: coApplicantInfo?.yearsAtPreviousAddress ?? null,
            path: ["data", "coApplicant", "yearsAtPreviousAddress"]
          },
          {
            value: coApplicantInfo?.monthsAtPreviousAddress ?? null,
            path: ["data", "coApplicant", "monthsAtPreviousAddress"]
          },
          {
            value: coApplicantInfo?.email ?? null,
            path: ["data", "coApplicant", "email"]
          },
          {
            value: coApplicantInfo?.employer ?? null,
            path: ["data", "coApplicant", "currentEmployment", "employerName"]
          },
          {
            value: coApplicantInfo?.yearsAtCurrentJob ?? null,
            path: ["data", "coApplicant", "currentEmployment", "totalYearsEmployed"]
          },
          {
            value: coApplicantInfo?.monthsAtCurrentJob ?? null,
            path: ["data", "coApplicant", "currentEmployment", "totalMonthsEmployed"]
          },
          {
            value: coApplicantInfo?.jobOccupation ?? null,
            path: ["data", "coApplicant", "currentEmployment", "occupation"]
          },
          {
            value: formatPhone(coApplicantInfo?.businessPhone) ?? null,
            path: ["data", "coApplicant", "currentEmployment", "workPhone"]
          },
          {
            value: resolveEmploymentStatus(coApplicantInfo?.employmentStatus) ?? "Employed",
            path: ["data", "coApplicant", "currentEmployment", "status"]
          },
          {
            value:
              coApplicantInfo && coApplicantInfo?.annualIncome > 0
                ? roundOrNull(coApplicantInfo?.annualIncome)
                : coApplicantInfo?.monthlyIncome
                ? roundOrNull(coApplicantInfo?.monthlyIncome)
                : roundOrNull(coApplicantInfo?.retirementIncome),
            path: ["data", "coApplicant", "income"]
          },
          {
            value: coApplicantInfo?.annualIncome > 0 ? "Annually" : "Monthly",
            path: ["data", "coApplicant", "incomeFrequency"]
          },
          {
            value:
              roundOrNull(
                (coApplicantInfo?.additionalIncomes ?? [])?.reduce(
                  (acc, additionalIncome) => acc + (additionalIncome.value || 0),
                  0
                )
              ) || null,
            path: ["data", "coApplicant", "otherMonthlyIncome"]
          },
          {
            value:
              (coApplicantInfo?.additionalIncomes ?? [])
                ?.filter((x) => x.source)
                .map((x) => x.source)
                .join(", ") ?? null,
            path: ["data", "coApplicant", "otherMonthlyIncomeSource"]
          },
          {
            value: resolveMaritalStatus(coApplicantInfo?.maritalStatus),
            path: ["data", "coApplicant", "maritalStatus"]
          },
          {
            value: resolveHousingStatus(coApplicantInfo?.propertyOwnership) ?? null,
            path: ["data", "coApplicant", "housingStatus"]
          },
          {
            value: coApplicantInfo?.mortgagePerMonth ?? null,
            path: ["data", "coApplicant", "mortgageOrRentAmount"]
          },
          {
            value: coApplicantInfo?.previousAddress
              ? [coApplicantInfo?.previousAddressNumber, coApplicantInfo?.previousAddress].join(" ")
              : "",
            path: ["data", "coApplicant", "previousAddress", "line1"]
          },

          {
            value: coApplicantInfo?.previousCity ?? null,
            path: ["data", "coApplicant", "previousAddress", "city"]
          },

          {
            value: getPrefixByState(coApplicantInfo?.previousState) ?? null,
            path: ["data", "coApplicant", "previousAddress", "state"]
          },
          {
            value: coApplicantInfo?.previousZipCode ?? null,
            path: ["data", "coApplicant", "previousAddress", "postalCode"]
          },
          {
            value: coApplicantInfo?.previousEmployer ?? null,
            path: ["data", "coApplicant", "previousEmployment", "employerName"]
          },
          {
            value: coApplicantInfo?.previousOccupation ?? null,
            path: ["data", "coApplicant", "previousEmployment", "occupation"]
          },
          {
            value: coApplicantInfo?.yearsAtPreviousJob ?? null,
            path: ["data", "coApplicant", "previousEmployment", "totalYearsEmployed"]
          },
          {
            value: coApplicantInfo?.monthsAtPreviousJob ?? null,
            path: ["data", "coApplicant", "previousEmployment", "totalMonthsEmployed"]
          }
        ]
      : [
          {
            value: undefined,
            path: ["data", "coApplicant"]
          }
        ]),
    //Vehicle
    {
      value: resolveVehicleType(dealData?.info.type),
      path: ["data", "vehicle", "vehicleType"]
    },
    {
      value: capitalize(vehicle?.unitStatus) ?? null,
      path: ["data", "vehicle", "inventoryVehicleCondition"]
    },
    {
      value: vehicle?.VIN ?? null,
      path: ["data", "vehicle", "vin"]
    },
    {
      value: vehicle?.year ?? null,
      path: ["data", "vehicle", "otherYear"]
    },

    {
      value: vehicle?.make ?? null,
      path: ["data", "vehicle", "otherMake"]
    },
    {
      value: vehicle?.model ?? null,
      path: ["data", "vehicle", "otherModel"]
    },
    {
      value: vehicle?.odometer ?? null,
      path: ["data", "vehicle", "odometerReading"]
    },
    //TradeIn
    ...(price?.trade && price.trade.length > 0
      ? price.trade.reduce(
          (acc: { value: any; path: any[] }[], trade, index) => [
            ...acc,
            ...[
              {
                value: trade?.VIN ?? null,
                path: ["data", "tradeIns", index, "vin"]
              },
              {
                value: trade?.year ?? null,
                path: ["data", "tradeIns", index, "otherYear"]
              },
              {
                value: trade?.make ?? null,
                path: ["data", "tradeIns", index, "otherMake"]
              },
              {
                value: trade?.model ?? null,
                path: ["data", "tradeIns", index, "otherModel"]
              },
              {
                value: roundOrNull(trade?.value),
                path: ["data", "tradeIns", index, "actualCashValue"]
              }
            ]
          ],
          []
        )
      : []),
    {
      value: roundOrNull(price?.price),
      path: ["data", "financeSummary", "vehicleSellingPrice"]
    },
    {
      value: roundOrNull(vehicle?.MSRP),
      path: ["data", "financeSummary", "msrp"]
    },
    {
      value: roundOrNull(vehicle?.invoice),
      path: ["data", "financeSummary", "invoiceAmount"]
    },
    {
      value: roundOrNull(payment?.totalTaxes),
      path: ["data", "financeSummary", "salesTax"]
    },
    {
      value: roundOrNull(dealData?.info?.taxesAndFees?.titleTransfer),
      path: ["data", "financeSummary", "governmentFees"]
    },
    {
      value: roundOrNull(price?.rebates),
      path: ["data", "financeSummary", "rebate"]
    },
    {
      value: roundOrNull(price?.totalCash),
      path: ["data", "financeSummary", "cashDown"]
    },
    {
      value: roundOrNull(payment?.numberOfPayments),
      path: ["data", "financeSummary", "term"]
    },
    {
      value: roundOrNull(dealData?.info?.aftermarketOptions?.totalServiceWarrantySellPrice),
      path: ["data", "financeSummary", "warranty"]
    },
    {
      value: roundOrNull(dealData?.info?.aftermarketOptions?.totalGAPSellPrice),

      path: ["data", "financeSummary", "gap"]
    },
    {
      value: roundOrNull(dealData?.info?.taxesAndFees?.documentRegistrationFees),
      path: ["data", "financeSummary", "frontEndFees"]
    },

    {
      value: roundOrNull(dealData?.info?.taxesAndFees?.otherFees),
      path: ["data", "financeSummary", "otherFees"]
    },
    {
      value: roundOrNull(price?.netTrade),
      path: ["data", "financeSummary", "netTrade"]
    }
  ].reduce((acc, { path, value }) => setByPath(acc, path as any, value), initialState);
};
export default function ({
  stateAccess,
  isDefault
}: {
  stateAccess: StateAccess;
  isDefault: boolean;
}) {
  const { enabled: editMode, edited: dealEdited } = useContext(formEditContext);
  const dealertrackStatus = useSelector(
    (state: RootState) => state.listApiStatusSlice["table"]
  )?.entities?.find((el) => el.id === process.env.REACT_APP_DEALERTRACK_API_ID);

  const [alertState, setAlertState] = useState(false);
  const [open, setOpen] = useState(false);
  const {
    data: { dealertrackDealNumbers }
  } = stateAccess.get([] as any) as Deal;
  const handleClickOpen = () => {
    if (dealEdited) {
      return setAlertState(true);
    }
    setOpen(true);
  };
  const modalContainer = useContext(modalContainerContext);
  const handleClose = () => {
    setOpen(false);
  };
  const isDealerTrackOperational =
    dealertrackStatus?.status === "operational" || dealertrackStatus === undefined;

  return (
    <div>
      <AlertDialog
        closeFunc={setAlertState}
        open={alertState}
        content="Please save the deal before sending it to Dealertrack in order to not lose the changed data!"
      />
      <Grid container>
        <Grid item xs={isDealerTrackOperational ? 12 : 10}>
          <Button
            variant="contained"
            style={{
              backgroundColor:
                dealertrackDealNumbers && dealertrackDealNumbers?.length > 0
                  ? "#50a538"
                  : editMode
                  ? "#254e70"
                  : "#0000001f",
              color: editMode ? "#fff" : "black"
            }}
            fullWidth
            id="dealertrack-button"
            startIcon={<MotorcycleIcon />}
            endIcon={isDefault && <StarRateIcon />}
            disabled={!editMode}
            disableElevation
            onClick={handleClickOpen}
          >
            Dealertrack
          </Button>
        </Grid>
        {!isDealerTrackOperational && (
          <Grid container item xs={2} justifyContent="center" alignItems="center">
            <Tooltip placement="top" title="Dealertrack service is unavailable!">
              <ErrorIcon color="secondary" />
            </Tooltip>
          </Grid>
        )}
      </Grid>
      {open
        ? ReactDOM.createPortal(
            <Dialog
              fullWidth
              maxWidth="lg"
              onClose={handleClose}
              open={open}
              style={{ minHeight: "50vh" }}
            >
              <Grid container alignItems="center" spacing={1} style={{ paddingRight: "4px" }}>
                <Grid item xs={6} key="title">
                  <DialogTitle>
                    Dealertrack
                    {dealertrackDealNumbers?.map((el, index) => (
                      <Chip
                        key={index}
                        size="small"
                        label={el.dealertrackNumber}
                        clickable
                        style={{
                          marginLeft: "10px",
                          backgroundColor: "#50a538",
                          color: "#fff"
                        }}
                        onClick={() => window.open("https://www.uat1.dealertrack.com")}
                      />
                    ))}
                    <CloseDialogButton closeFunction={handleClose} />
                  </DialogTitle>
                </Grid>
              </Grid>
              <DialogContent>
                <AddEntity
                  type="dealertrack_deal"
                  openInNewTab={false}
                  initialStateModifier={initializeDealerTrackData(stateAccess)}
                  callback={() => {
                    setOpen(false);
                  }}
                  buttonProps={{ text: "Send", startIcon: <Send /> }}
                />
              </DialogContent>
            </Dialog>,
            modalContainer
          )
        : null}
    </div>
  );
}
