import Button from "@material-ui/core/Button";
import { RootState } from "app/rootReducer";
import { Info } from "components/Applicants/types";
import { addDealStruct } from "components/Deals/model";
import { DealSourceTypes } from "components/Deals/types";
import { TabContext } from "components/Layout/LayoutWrapper";
import { VerificationContext } from "components/Layout/Main";
import { hideLoader, showLoader } from "components/Loader/loaderSlice";
import { performLocalCheck } from "components/ZipCodeLookup/AddressPreview";
import { useSnackbar } from "notistack";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getStateByPrefix, sanitizeAddress } from "utils/functions";
import {
  RenderSet,
  StateAccess,
  fillDefaultsByPath,
  generateDefault
} from "utils/models/formGenerator";
import { v4 as uuidv4 } from "uuid";
import { resolvePropertyOwnership } from "./Print";
import { addExternalApplicationDeal } from "./addExternalApplicationDealSlice";
import { ApplicantInfo, ExternalCreditApplication } from "./types";

const convertApplicantFields = (applicant: ApplicantInfo): Partial<Info> => {
  return {
    firstName: applicant?.firstName,
    middleName: applicant?.middleName,
    lastName: applicant?.lastName,
    socialSecurityNumber: applicant?.ssn?.replace(/[- ]/g, ""),
    birthDate: applicant?.dateOfBirth && new Date(applicant?.dateOfBirth).toISOString(),
    mobilePhone: applicant?.primaryPhone?.replace(/\s|\+1|\(|\)|-/g, "") || undefined,
    homePhone: applicant?.secondaryPhone?.replace(/\s|\+1|\(|\)|-/g, "") || undefined,
    driverLicenseNumber: applicant?.idNumber,
    drivingLicenseState: getStateByPrefix(applicant?.idIssuedBy) ?? undefined,
    driverLicenseIssued: applicant?.idIssuedDate && new Date(applicant?.idIssuedDate).toISOString(),
    driverLicenseExpires: applicant?.idExpDate && new Date(applicant?.idExpDate).toISOString(),
    email: applicant?.email,
    usCitizen: applicant?.isUSCitizen,
    maritalStatus: applicant?.maritalStatus === "Married" ? "Married" : "Not married",
    currentAddress: sanitizeAddress(applicant?.currentAddress?.address) ?? "",
    currentAddressNumber: applicant?.currentAddress?.addressNumber,
    currentCity: applicant?.currentAddress?.city,
    currentState: applicant?.currentAddress?.state,
    currentZipCode: applicant?.currentAddress?.zip,
    monthsAtCurrentAddress: parseInt(applicant?.currentAddress?.time?.months) || 0,
    yearsAtCurrentAddress: parseInt(applicant?.currentAddress?.time?.years) || 0,
    previousAddress: sanitizeAddress(applicant?.previousAddress?.address),
    previousAddressNumber: applicant?.previousAddress?.addressNumber,
    previousCity: applicant?.previousAddress?.city,
    previousState: applicant?.previousAddress?.state,
    previousZipCode: applicant?.previousAddress?.zip,
    monthsAtPreviousAddress: parseInt(applicant?.previousAddress?.time?.months) || 0,
    yearsAtPreviousAddress: parseInt(applicant?.previousAddress?.time?.years) || 0,
    propertyOwnership: resolvePropertyOwnership(applicant?.residentialStatus),
    rentPerMonth: applicant?.rentOrMortgageAmount,
    employmentStatus: applicant?.employmentStatus,
    employer: applicant?.currentEmployer?.name,
    jobOccupation: applicant?.currentEmployer?.occupationOrJobTitle,
    businessPhone:
      applicant?.currentEmployer?.workPhone?.replace(/\s|\+1|\(|\)|-/g, "") || undefined,
    monthsAtCurrentJob: parseInt(applicant?.currentEmployer?.time?.months) || 0,
    yearsAtCurrentJob: parseInt(applicant?.currentEmployer?.time?.years) || 0,
    previousEmployer: applicant?.prevEmployer?.name,
    previousOccupation: applicant?.prevEmployer?.occupationOrJobTitle,
    monthsAtPreviousJob: parseInt(applicant?.prevEmployer?.time?.months) || 0,
    yearsAtPreviousJob: parseInt(applicant?.prevEmployer?.time?.years) || 0,
    monthlyIncome: applicant?.currentEmployer?.grossMonthlySalary,
    annualIncome: applicant?.currentEmployer?.grossMonthlySalary
      ? applicant?.currentEmployer?.grossMonthlySalary * 12
      : undefined,
    additionalIncome: applicant?.grossMonthlyOtherIncome,
    additionalIncomes: [
      {
        value: applicant?.grossMonthlyOtherIncome || null,
        source: applicant?.otherIncomeSource || null
      }
    ],
    sourceOfAdditionalIncome: applicant?.otherIncomeSource,
    mortgagePerMonth: applicant?.rentOrMortgageAmount,
    retirementIncome: applicant?.retirementIncome,
    sourceOfRetirementIncome: applicant?.sourceOfRetirementIncome
  };
};

export default function ({
  stateAccess,
  renderSet
}: {
  stateAccess: StateAccess;
  renderSet: RenderSet;
}) {
  const dispatch = useDispatch();
  const createOrFocusTab = useContext(TabContext);
  const externalCreditApplication: ExternalCreditApplication = stateAccess.get([]);
  const [addDealRequestId] = useState(uuidv4());
  const addDealSelectorData = useSelector(
    (state: RootState) => state.addExternalApplicationDealSlice[addDealRequestId]
  );
  const checkDataValidity = useContext(VerificationContext);

  const [deal] = useState(generateDefault(addDealStruct, {}, fillDefaultsByPath as any) as any);
  const { enqueueSnackbar } = useSnackbar();
  const applicant = convertApplicantFields(externalCreditApplication.data.info.applicant);
  const coApplicant = externalCreditApplication.data.info?.coApplicant
    ? convertApplicantFields(externalCreditApplication.data.info.coApplicant)
    : undefined;
  const applicantNeededData = (applicantType: "applicant" | "coApplicant") => [
    {
      name: `${applicantType} -> First name `,
      value: externalCreditApplication.data?.info[applicantType]?.firstName
    },
    {
      name: `${applicantType} -> Last name `,
      value: externalCreditApplication.data?.info[applicantType]?.lastName
    },
    {
      name: `${applicantType} -> SSN `,
      value: externalCreditApplication.data?.info[applicantType]?.ssn
    },
    {
      name: `${applicantType} -> Birth date `,
      value: externalCreditApplication.data?.info[applicantType]?.dateOfBirth
    },
    {
      name: `${applicantType} -> Current address `,
      value: externalCreditApplication.data?.info[applicantType]?.currentAddress?.address
    }
  ];
  const handleCheckDataAndCreateDeal = () => {
    if (
      externalCreditApplication?.data?.info?.creditType === "joint" &&
      externalCreditApplication?.data?.info?.coApplicant
    ) {
      checkDataValidity(
        [...applicantNeededData("applicant"), ...applicantNeededData("coApplicant")],
        handleCreateDeal
      );
    } else checkDataValidity(applicantNeededData("applicant"), handleCreateDeal);
  };
  const handleCreateDeal = async () => {
    dispatch(showLoader());

    const currentCountyApplicant = applicant?.currentZipCode
      ? await performLocalCheck(applicant?.currentZipCode).then((result) => {
          return (
            result?.google?.[0]?.county?.long_name?.replace(" County", "") ??
            result?.local?.county?.replace(" County", "") ??
            null
          );
        })
      : null;
    const currentCountyCoApplicant = coApplicant?.currentZipCode
      ? await performLocalCheck(coApplicant?.currentZipCode).then((result) => {
          return (
            result?.google?.[0]?.county?.long_name?.replace(" County", "") ??
            result?.local?.county?.replace(" County", "") ??
            null
          );
        })
      : null;
    dispatch(
      addExternalApplicationDeal({
        externalApplicationId: externalCreditApplication._id,
        requestId: addDealRequestId,
        data: {
          ...deal.data,
          source: {
            type: DealSourceTypes.Online_Application,
            applicationId: externalCreditApplication._id
          },
          applicant: {
            ...applicant,
            currentCounty: currentCountyApplicant
          },
          ...(externalCreditApplication.data.info.coApplicant
            ? {
                coApplicant: {
                  ...coApplicant,
                  currentCounty: currentCountyCoApplicant
                }
              }
            : {}),
          dealership: externalCreditApplication.data.dealership,
          info: {
            ...deal.data.info,
            adfEmailSent: externalCreditApplication?.data?.info.adfEmailSent,
            type: "Automotive",
            taxesAndFees: {
              ...deal.data.info.taxesAndFees,
              isFixedTax: Number(externalCreditApplication?.data?.info?.taxes?.stateTax ?? 0) > 0,
              titleTransfer: externalCreditApplication?.data?.info?.fees?.titleFees,
              documentRegistrationFees: externalCreditApplication?.data?.info?.fees?.docFee
            },
            payment: {
              ...deal.data.info.payment,
              totalTaxes: externalCreditApplication?.data?.info?.taxes?.stateTax
            },
            price: {
              ...deal.data.info.price,
              price: externalCreditApplication?.data?.info?.price?.price,
              totalCash: externalCreditApplication?.data?.info?.price?.totalCash,
              payoff: externalCreditApplication?.data?.info?.payoff?.payoff,
              payoffBank: externalCreditApplication?.data?.info?.payoff?.payoffBank,
              totalTrade: externalCreditApplication?.data?.info?.price?.tradeAllowance,
              trade: externalCreditApplication?.data?.info?.tradeIn?.make
                ? [
                    {
                      VIN: externalCreditApplication?.data?.info?.tradeIn?.vin,
                      year: externalCreditApplication?.data?.info?.tradeIn?.year
                        ? parseInt(externalCreditApplication?.data?.info?.tradeIn?.year)
                        : null,
                      make: externalCreditApplication?.data?.info?.tradeIn?.make,
                      model: externalCreditApplication?.data?.info?.tradeIn?.model,
                      value: externalCreditApplication?.data?.info?.price?.tradeAllowance,
                      odometer:
                        parseFloat(externalCreditApplication?.data?.info?.tradeIn?.miles) || 0
                    }
                  ]
                : []
            },
            vehicle: {
              VIN: externalCreditApplication?.data?.info?.collateral?.vin,
              odometer: parseFloat(externalCreditApplication?.data?.info?.collateral?.miles) || 0,
              year: externalCreditApplication?.data?.info?.collateral?.year
                ? parseInt(externalCreditApplication?.data?.info?.collateral?.year)
                : null,
              make: externalCreditApplication?.data?.info?.collateral?.make,
              model: externalCreditApplication?.data?.info?.collateral?.model,
              MSRP: externalCreditApplication?.data?.info?.collateral?.MSRP,
              unitStatus: externalCreditApplication?.data?.info?.collateral?.unitStatus,
              isVinDecoded: externalCreditApplication?.data?.info?.collateral?.isVinDecoded
            }
          }
        }
      })
    );
  };
  useEffect(() => {
    if (
      addDealSelectorData !== undefined &&
      addDealSelectorData?.status === "success" &&
      addDealSelectorData?.data !== null
    ) {
      dispatch(hideLoader());
      enqueueSnackbar("Successfully created a deal.", {
        variant: "success"
      });
      createOrFocusTab({
        label: "Show page",
        index: "showPage",
        isForSidebar: false,
        isForQuickAccess: false,
        isPersistent: false,
        props: {
          _id: addDealSelectorData.data.message._id,
          type: "deal"
        }
      });
    }
    if (addDealSelectorData?.status === "error") {
      dispatch(hideLoader());
      enqueueSnackbar(addDealSelectorData.message, {
        variant: "error"
      });
    }
  }, [addDealSelectorData, createOrFocusTab, dispatch]);
  // return (
  //   <div>
  //     <Button
  //       disabled={renderSet.type !== "edit"}
  //       id="create-deal-button"
  //       style={{ position: "absolute", right: "130px" }}
  //       variant="contained"
  //       color="primary"
  //       onClick={handleCheckDataAndCreateDeal}
  //     >
  //       Create Deal
  //     </Button>
  //   </div>
  // );
  return <></>;
}
