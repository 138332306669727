import { RootState } from "app/rootReducer";
import React from "react";
import { useSelector } from "react-redux";

import AccessControl from "../Access/AccessControl";

import { Chip } from "@material-ui/core";
import ApplicantFilter from "components/Filters/ApplicantFilter";
import DateFilter from "components/Filters/DateFilter";
import DealershipFilter from "components/Filters/DealershipFilter";
import MultiSelectFilter from "components/Filters/MultiSelectFilter";
import TextFilter from "components/Filters/TextFilter";
import { capitalizeFirstLetterLowerCaseOthers } from "utils/functions";
import Table, { CellValue, Column } from "../Table";
import { deleteDealershipTicket } from "./deleteDealershipTicketSlice";
import { getDealershipTicketList } from "./listDealershipTicketSlice";
import { recoverDealershipTicket } from "./recoverDealershipTicketSlice";
import { FormState as DealershipTicketData, DealershipTicketStatus } from "./types";

export const getDealershipTicketStatusColor = (status: DealershipTicketStatus) => {
  switch (status) {
    case DealershipTicketStatus.NEW:
      return "#6b97f6";
    case DealershipTicketStatus.IN_PROGRESS:
      return "#fce303";
    case DealershipTicketStatus.RESOLVED:
      return "#15c969";
    case DealershipTicketStatus.CLOSED:
      return "#a5aec1";
  }
};

export default function (): JSX.Element {
  const dealershipTicketList = useSelector(
    (state: RootState) => state.listDealershipTicketSlice["table"]
  );
  const columns: Column<DealershipTicketData>[] = [
    {
      getData: (entry): CellValue =>
        [entry.data.info.user.firstName, entry.data.info.user.lastName].filter((x) => x).join(" "),
      label: "User",
      name: "user",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.user?.show;
      },
      filters: [
        {
          path: [
            ["data", "info", "user", "firstName"],
            ["data", "info", "user", "lastName"]
          ],
          preview: ApplicantFilter,
          caseInsensitive: true,
          partialSearch: true,
          name: "user",
          label: "User"
        }
      ]
    },
    {
      getData: (entry): CellValue => entry.data.info.user?.email ?? "N/A",
      label: "User email",
      name: "userEmail",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.userEmail?.show;
      },
      filters: [
        {
          path: ["data", "info", "user", "email"],
          preview: TextFilter,
          caseInsensitive: true,
          partialSearch: true
        }
      ]
    },
    {
      getData: (entry): CellValue => entry.data?.dealership?.data?.info?.displayName ?? "",
      label: "Dealership",
      name: "dealership",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.dealership?.show;
      },
      filters: [
        {
          path: ["data", "dealershipId"],
          preview: DealershipFilter
        }
      ],
      options: {
        sort: true,
        path: ["data", "dealershipId"]
      }
    },
    {
      getData: (dealershipTicket): CellValue => dealershipTicket.data.info.status,
      label: "Status",
      options: {
        customBodyRender: (x: CellValue, ticket): JSX.Element | string => {
          return (
            <div style={{ whiteSpace: "nowrap" }}>
              <Chip
                size="small"
                label={capitalizeFirstLetterLowerCaseOthers(x as string)}
                style={{
                  fontFamily: "monospace",
                  background: getDealershipTicketStatusColor(x as DealershipTicketStatus),
                  color: "#ffffff"
                }}
              />
            </div>
          );
        },
        sort: true,
        path: ["data", "info", "status"]
      },
      filters: [
        {
          path: ["data", "info", "status"],
          preview: MultiSelectFilter,
          valuesForSelect: Object.values(DealershipTicketStatus),
          optionLabelForSelect: (status) => capitalizeFirstLetterLowerCaseOthers(status)
        }
      ],
      name: "status",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.status?.show;
      }
    },
    {
      label: "Date added",
      getData: (el): CellValue => new Date(el.createdAt),
      options: {},
      name: "createdAt",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.createdAt?.show;
      },
      filters: [
        {
          path: ["createdAt"],
          preview: DateFilter,
          label: "Created At",
          name: "createdAt"
        }
      ]
    },
    {
      getData: (entry): CellValue => entry._id,
      label: "Actions",
      name: "actions",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.actions?.show;
      }
    }
  ];
  const projections = {
    "data.dealership.data.info.displayName": 1,
    "data.dealershipId": 1,
    "data.info": 1,
    _id: 1,
    createdAt: 1,
    creatorId: 1,
    updatedAt: 1,
    deleted: 1
  };
  return (
    <div style={{ position: "relative" }}>
      <AccessControl
        requiredPermissions={[
          { entity: "dealership_ticket", action: "read" },
          { entity: "dealership_ticket", action: "menu" }
        ]}
      >
        <Table
          slice="table"
          tableName="dealershipTickets"
          entityName="dealership_ticket"
          listFunction={getDealershipTicketList}
          listEntity={dealershipTicketList}
          rowsPerPage={200}
          deleteEntityFunction={deleteDealershipTicket}
          hideAddButton
          recoverEntityFunction={recoverDealershipTicket}
          sort={{ createdAt: "desc" }}
          title={"Dealership Tickets"}
          columns={columns}
          query={{
            deleted: false
          }}
          projection={projections}
          hideAddButtonByDocument
        />
      </AccessControl>
    </div>
  );
}
