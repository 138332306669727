import { RootState } from "app/rootReducer";
import React from "react";
import { useSelector } from "react-redux";
import AccessControl from "../Access/AccessControl";
import ApplicantFilter from "../Filters/ApplicantFilter";
import DateFilter from "../Filters/DateFilter";
import LenderFilter from "../Filters/LenderFilter";
import PhoneFilter from "../Filters/PhoneFilter";
import TextFilter from "../Filters/TextFilter";
import Table, { CellValue, Column } from "../Table";
import { deleteLender } from "./deleteLenderSlice";
import { getLenderList } from "./listLenderSlice";
import { recoverLender } from "./recoverLenderSlice";
import { FormState } from "./types";
export const projections = {
  "data.info.name": 1,
  "data.representatives": 1,
  "data.requirements": 1,
  "data.notes": 1,
  _id: 1,
  createdAt: 1,
  creatorId: 1,
  updatedAt: 1,
  deleted: 1
};
export default (): JSX.Element => {
  const lenderList = useSelector((state: RootState) => state.listLenderSlice["table"]);

  const columns: Column<FormState>[] = [
    {
      getData: (el): CellValue => el.data.info.name,
      label: "Name",
      name: "name",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.name?.show;
      },
      filters: [
        {
          path: ["_id"],
          preview: LenderFilter
        }
      ]
    },
    {
      label: "Representative",
      getData: (el): CellValue =>
        el.data.representatives?.[0]
          ? [el.data.representatives[0].firstName, el.data.representatives[0].lastName].join(" ")
          : "",
      name: "representative",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.representative?.show;
      },
      filters: [
        {
          path: [
            ["data", "representatives", "0", "firstName"],
            ["data", "representatives", "0", "lastName"]
          ],
          preview: ApplicantFilter
        }
      ]
    },
    {
      label: "Phone",
      getData: (el): CellValue =>
        el.data.representatives?.[0]
          ? el.data.representatives[0].phone + " / " + el.data.representatives[0].email
          : "",
      name: "info",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.info?.show;
      },
      filters: [
        {
          path: ["data", "representatives", "0", "phone"],
          preview: PhoneFilter
        }
      ]
    },
    {
      label: "Email",
      getData: (el): CellValue =>
        el.data.requirements?.[0] ? el.data.requirements[0].requirement : "",
      name: "requirement",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.info?.show;
      },
      filters: [
        {
          path: ["data", "representatives", "0", "email"],
          preview: TextFilter
        }
      ]
    },
    // {
    //   label: "Requirement",
    //   getData: (el): CellValue =>
    //     el.data.requirements?.[0] ? el.data.requirements[0].requirement : ""
    // },
    {
      label: "Notes",
      getData: (el): CellValue => (el.data.notes?.[0] ? el.data.notes[0].note : ""),
      name: "notes",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.notes?.show;
      },
      filters: [
        {
          path: ["data", "notes", "0", "note"],
          preview: TextFilter,
          partialSearch: true,
          caseInsensitive: true
        }
      ]
    },
    {
      label: "Date created",
      getData: (el): CellValue => new Date(el.createdAt),
      options: {
        sort: true,
        path: ["createdAt"]
      },
      name: "createdAt",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.createdAt?.show;
      },
      filters: [
        {
          path: ["createdAt"],
          preview: DateFilter
        }
      ]
    },
    {
      label: "Actions",
      getData: (el): CellValue => el._id,
      name: "actions",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.actions?.show;
      }
    }
  ];

  return (
    <AccessControl requiredPermissions={{ entity: "lender", action: "read" }}>
      <Table
        slice="table"
        tableName="lenders"
        entityName="lender"
        listFunction={getLenderList}
        listEntity={lenderList}
        deleteEntityFunction={deleteLender}
        sort={{ "data.info.name": "asc" }}
        recoverEntityFunction={recoverLender}
        title={"Lenders"}
        columns={columns}
        projection={projections}
      />
    </AccessControl>
  );
};
