import { createListSlice } from "../Middlewares/listSliceCreator";
import { DefaultStipulation } from "./types";

const { ListSlice, getList } = createListSlice<DefaultStipulation>({
  name: "DefaultStipulationList",
  request_topic: "all_default_stipulations",
  reducers: {}
});

export default ListSlice.reducer;
export const getDefaultStipulationList = getList;
export const listDefaultStipulationActions = ListSlice.actions;
