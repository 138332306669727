import { RootState } from "app/rootReducer";
import { Deal, DealStatus } from "components/Deals/types";
import { HintTooltip } from "components/common/HintTooltip";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { uuid as uuidv4 } from "uuidv4";
import { getDealershipBillsList, removeDealershipBillsList } from "./listDealershipBillsSlice";

import {
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import CheckIcon from "@material-ui/icons/Check";
import ErrorIcon from "@material-ui/icons/Error";
import WarningIcon from "@material-ui/icons/Warning";
import {
  getDealershipList,
  removeDealershipList
} from "components/Dealerships/listDealershipSlice";
import { getStyleByStatus, statusToChipTooltip } from "components/Deals/Deals";
import { TabContext } from "components/Layout/LayoutWrapper";
import {
  StateAccess,
  fillDefaultsByPath,
  generateDefault,
  generateForm,
  getByPath,
  setByPath
} from "utils/models/formGenerator";
import { editRenderSet } from "utils/models/formRenderers";
import { addDealershipBills } from "./addDealershipBillsSlice";
import { dealershipBillsStruct } from "./model";
import { DealershipBills, GroupedDeals } from "./types";

const formatApplicantsNameFromDeal = (deal: Deal) => {
  const applicant = deal.data.applicant;
  if (!applicant) return "";
  const coApplicant = deal.data.coApplicant;

  let applicantsNames = `${applicant.data.info.firstName} ${applicant.data.info.lastName}`;

  if (coApplicant && coApplicant?.data?.info?.firstName) {
    applicantsNames =
      applicantsNames +
      " / " +
      `${coApplicant?.data.info.firstName} ${coApplicant?.data.info.lastName}`;
  }
  return applicantsNames;
};
export default () => {
  const dispatch = useDispatch();
  const createOrFocusTab = useContext(TabContext);
  const [sliceId] = useState(uuidv4());
  const [dealershipsSliceId] = useState(uuidv4());

  const dealershipBillsList = useSelector(
    (rootState: RootState) => rootState.listDealershipBillsSlice[sliceId]
  );

  const dealershipList = useSelector(
    (state: RootState) => state.listDealershipSlice[dealershipsSliceId]
  );

  const [state, setState] = useState<DealershipBills>(
    generateDefault(dealershipBillsStruct, {}, fillDefaultsByPath as any) as any
  );
  const grouped =
    dealershipBillsList?.entities?.reduce((acc: GroupedDeals, deal) => {
      if (deal.data.dealershipId && deal.data.dealership) {
        return {
          ...acc,
          [deal.data.dealershipId]: {
            dealership: deal.data.dealership,
            deals: [...(acc?.[deal.data.dealershipId]?.deals ?? []), deal]
          }
        };
      }
      return acc;
    }, {} as GroupedDeals) ?? {};
  useEffect(() => {
    if (
      dealershipBillsList &&
      dealershipBillsList?.entities &&
      dealershipBillsList?.entities?.length > 0
    ) {
      dispatch(
        getDealershipList(dealershipsSliceId, {
          query: {
            _id: { $in: Object.keys(grouped) }
          },
          options: {
            pagination: false
          }
        })
      );
    }

    return () => {
      dispatch(removeDealershipList(dealershipsSliceId));
    };
  }, [dealershipBillsList]);

  useEffect(() => {
    if (dealershipBillsList && dealershipBillsList.entities) {
      setState((state) => ({
        ...state,
        data: { ...state.data, dealershipIds: Object.keys(grouped) }
      }));
    }
  }, [dealershipBillsList]);
  useEffect(() => {
    if (state.data.dealershipProgram?._id && state.data.month && state.data.year) {
      dispatch(
        getDealershipBillsList(sliceId, {
          query: {
            month: state.data.month,
            year: state.data.year,
            dealershipProgramId: state.data.dealershipProgram._id
          }
        })
      );
    }

    return () => {
      dispatch(removeDealershipBillsList(sliceId));
    };
  }, [state.data.dealershipProgram?._id, state.data.month, state.data.year, dispatch]);

  const stateAccess: StateAccess = {
    get: (path) => getByPath(state as any, path),
    set: (path, value): any => setState(setByPath(state as any, path, value))
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(
      addDealershipBills({
        requestId: sliceId,
        data: {
          dealershipIds: state.data.dealershipIds,
          year: state.data.year,
          month: state.data.month,
          dealershipProgramId: state.data.dealershipProgramId,
          dealershipProgram: state.data.dealershipProgram
        }
      })
    );
  };
  return (
    <>
      <form id="report-form" onSubmit={handleSubmit}>
        {generateForm(dealershipBillsStruct, stateAccess, [], stateAccess, editRenderSet(false))}
      </form>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={state?.data?.dealershipIds?.length === Object.keys(grouped)?.length}
                    indeterminate={
                      state?.data?.dealershipIds?.length !== Object.keys(grouped)?.length &&
                      state?.data?.dealershipIds?.length > 0
                    }
                    onChange={(_, checked) => {
                      if (checked) {
                        setState((state) => ({
                          ...state,
                          data: { ...state.data, dealershipIds: Object.keys(grouped) }
                        }));
                      } else {
                        setState((state) => ({
                          ...state,
                          data: { ...state.data, dealershipIds: [] }
                        }));
                      }
                    }}
                  />
                }
                label={""}
              />
            </TableCell>
            <TableCell>Dealership</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Deal Reference Number</TableCell>
            <TableCell>Deal Status</TableCell>
            <TableCell>Applicant(s)</TableCell>
            <TableCell>Contract Date</TableCell>
            <TableCell>Funded date</TableCell>
            <TableCell>Dealer Reserve Split</TableCell>
            <TableCell>Dealer GAP Split</TableCell>
            <TableCell>Dealer Warranty Split</TableCell>
            <TableCell>Total Dealership Profit</TableCell>
            <TableCell>Amount Financed</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.entries(grouped).map(([dealershipId, group]) => {
            const billData = dealershipList?.entities?.find(
              (dealership) => dealership._id === dealershipId
            )?.data?.bills?.[`${state.data.year}-${state.data.month}`];

            return group.deals.map((deal, index) => {
              const cellStyle = ![DealStatus.SubmittedForFunding, DealStatus.Funded].includes(
                deal.data.info.status
              )
                ? { background: "#ff8282" }
                : {};
              return (
                <TableRow key={index}>
                  {index === 0 ? (
                    <>
                      <TableCell rowSpan={index === 0 ? group.deals?.length : 1}>
                        {editRenderSet(false).checkboxInputRenderer(
                          {
                            formComponent: "checkbox-field",
                            name: index?.toString(),
                            required: false,
                            path: ["data", "dealershipIds"],
                            isChecked: (stateAccess: StateAccess) =>
                              stateAccess
                                .get<DealershipBills>(["data", "dealershipIds"])
                                ?.find((id: string) => id === dealershipId)
                                ? true
                                : false,
                            toggle: (stateAccess: StateAccess, checked: boolean) => {
                              const restIds: string[] = stateAccess.get<DealershipBills>([
                                "data",
                                "dealershipIds"
                              ]);
                              if (checked) {
                                stateAccess.set<DealershipBills>(
                                  ["data", "dealershipIds"],
                                  [...(restIds ?? []), dealershipId]
                                );
                              } else {
                                stateAccess.set<DealershipBills>(
                                  ["data", "dealershipIds"],
                                  restIds.filter((id) => id !== dealershipId)
                                );
                              }
                            }
                          },
                          stateAccess,
                          stateAccess,
                          editRenderSet(false),
                          []
                        )}
                      </TableCell>
                      <TableCell rowSpan={index === 0 ? group.deals?.length : 1}>
                        {group?.dealership?.data.info.name}
                      </TableCell>
                      <TableCell rowSpan={index === 0 ? group.deals?.length : 1} align="center">
                        {billData?.status === "success" ? (
                          <IconButton
                            component="span"
                            color="primary"
                            onClick={() =>
                              window.open(
                                `${process.env.REACT_APP_NETSUITE_LINK}/app/accounting/transactions/vendbill.nl?id=${billData?.internalId}`,
                                "_blank"
                              )
                            }
                          >
                            <CheckIcon style={{ color: "green" }} />
                          </IconButton>
                        ) : billData?.status === "waiting" ? (
                          <CircularProgress size="1.5em" />
                        ) : billData?.status === "warning" ? (
                          <HintTooltip title={billData.message}>
                            <WarningIcon style={{ color: "#eed202" }} />
                          </HintTooltip>
                        ) : billData?.status === "error" ? (
                          <HintTooltip title={billData.message}>
                            <ErrorIcon color="error" />
                          </HintTooltip>
                        ) : (
                          ""
                        )}
                      </TableCell>
                    </>
                  ) : (
                    <></>
                  )}
                  <TableCell style={cellStyle}>
                    <Button
                      size="small"
                      variant="outlined"
                      onClick={() =>
                        createOrFocusTab({
                          label: "Show page",
                          index: "showPage",
                          isForSidebar: false,
                          isForQuickAccess: false,
                          isPersistent: false,
                          props: {
                            _id: deal._id,
                            type: "deal"
                          }
                        })
                      }
                    >
                      {deal.data.info.refNumber}
                    </Button>
                  </TableCell>
                  <TableCell style={cellStyle}>
                    {statusToChipTooltip(
                      deal.data.info.status,
                      deal,
                      getStyleByStatus(deal.data.info.status)
                    )}
                  </TableCell>
                  <TableCell style={cellStyle}>{formatApplicantsNameFromDeal(deal)}</TableCell>
                  <TableCell style={cellStyle}>
                    {deal.data.info?.dealDates?.contractDate
                      ? new Date(deal.data.info.dealDates.contractDate)?.toLocaleDateString()
                      : "N/A"}
                  </TableCell>
                  <TableCell style={cellStyle}>
                    {deal.data.info?.dealDates?.fundedAt
                      ? new Date(deal.data.info.dealDates.fundedAt)?.toLocaleDateString()
                      : "N/A"}
                  </TableCell>
                  <TableCell style={cellStyle}>
                    {new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(
                      deal.data.info.profit?.dealershipProfit?.splitFromDeal ?? 0
                    )}
                  </TableCell>
                  <TableCell style={cellStyle}>
                    {new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(
                      deal.data.info.profit?.dealershipProfit?.splitTotalFromGap ?? 0
                    )}
                  </TableCell>
                  <TableCell style={cellStyle}>
                    {new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(
                      deal.data.info.profit?.dealershipProfit?.splitTotalFromServiceWarranty ?? 0
                    )}
                  </TableCell>
                  <TableCell style={cellStyle}>
                    {new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(
                      deal.data.info.profit?.dealershipProfit?.totalProfit ?? 0
                    )}
                  </TableCell>
                  <TableCell style={cellStyle}>
                    {new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(
                      deal.data.info.payment.dealTotal ?? 0
                    )}
                  </TableCell>
                </TableRow>
              );
            });
          })}
        </TableBody>
      </Table>
      <div>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          form={"report-form"}
          disabled={Object.keys(grouped)?.length === 0}
          style={{ float: "right", margin: "1em 0em 0em 0em" }}
        >
          SEND BILLS
        </Button>
      </div>
    </>
  );
};
