import { Grid } from "@material-ui/core";
import { RootState } from "app/rootReducer";
import AccessControl from "components/Access/AccessControl";
import ApplicantFilter from "components/Filters/ApplicantFilter";
import DateFilter from "components/Filters/DateFilter";
import DealershipFilter from "components/Filters/DealershipFilter";
import MultiSelectFilter from "components/Filters/MultiSelectFilter";
import React from "react";
import { useSelector } from "react-redux";
import formatDate from "utils/formatDate";
import Table, { CellValue, Column } from "../Table";
import { deleteExternalCreditApplication } from "./deleteExternalCreditApplicationSlice";
import { getExternalCreditApplicationList } from "./listExternalCreditApplicationSlice";
import { recoverExternalCreditApplication } from "./recoverExternalCreditApplicationSlice";
import { FormState } from "./types";
import ViewCreditApplicationDeals from "./ViewCreditApplicationDeals";
export const projections = {
  "data.dealIds": 1,
  "data.info.creditType": 1,
  "data.dealership.data.info.displayName": 1,
  "data.info.applicant.firstName": 1,
  "data.info.applicant.lastName": 1,
  "data.info.coApplicant.firstName": 1,
  "data.info.coApplicant.lastName": 1,
  _id: 1,
  createdAt: 1,
  creatorId: 1,
  updatedAt: 1,
  deleted: 1
};
export default () => {
  const externalCreditApplicationsList = useSelector(
    (state: RootState) => state.listExternalCreditApplicationSlice["table"]
  );

  const columns: Column<FormState>[] = [
    {
      label: "Credit application type",
      getData: (el) => `${el.data?.info?.creditType ?? ""}`,
      name: "creditType",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.actions?.show;
      },
      filters: [
        {
          path: ["data", "info", "creditType"],
          preview: MultiSelectFilter,
          valuesForSelect: ["joint", "individual"],
          optionLabelForSelect: (creditType) => {
            switch (creditType) {
              case "joint":
                return "Joint";
              case "individual":
                return "Individual";
              default:
                return "";
            }
          }
        }
      ]
    },
    {
      label: "Dealership",
      getData: (el) => `${el.data?.dealership?.data?.info?.displayName ?? ""}`,
      options: {
        filter: true
      },
      name: "dealership",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.dealership?.show;
      },
      filters: [
        {
          path: ["data", "dealershipId"],
          preview: DealershipFilter
        }
      ]
    },
    {
      label: "Applicant",
      getData: (el) =>
        `${el.data?.info?.applicant?.firstName ?? ""} ${el.data?.info?.applicant?.lastName ?? ""}`,
      options: {
        filter: true
      },
      name: "applicant",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.applicant?.show;
      },
      filters: [
        {
          path: [
            ["data", "info", "applicant", "firstName"],
            ["data", "info", "applicant", "lastName"]
          ],
          preview: ApplicantFilter
        }
      ]
    },
    {
      label: "CoApplicant",
      getData: (el) =>
        `${el.data?.info?.coApplicant?.firstName ?? ""} ${
          el.data?.info?.coApplicant?.lastName ?? ""
        }`,
      options: {
        filter: true
      },
      name: "coApplicant",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.coApplicant?.show;
      },
      filters: [
        {
          path: [
            ["data", "info", "coApplicant", "firstName"],
            ["data", "info", "coApplicant", "lastName"]
          ],
          preview: ApplicantFilter
        }
      ]
    },
    {
      getData: (entry): CellValue => entry.data.dealIds,
      label: "Deals",
      options: {
        customBodyRender: (x: CellValue, creditApplication): JSX.Element | string => {
          const dealIds = x as string[];
          return dealIds?.length > 0 ? (
            <Grid container alignItems="center" style={{ minWidth: "100px" }} spacing={1}>
              <Grid item xs={8}>
                Deal count: {dealIds?.length || 0}
              </Grid>
              <Grid item xs={4}>
                <ViewCreditApplicationDeals dealIds={dealIds} />
              </Grid>
            </Grid>
          ) : (
            <></>
          );
        },
        sort: true,
        path: ["data", "info", "dealIds"]
      },
      name: "dealIds",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.dealIds?.show;
      }
    },
    {
      label: "Created at",
      getData: (el) => formatDate(el.createdAt, "medium", true),
      options: {
        filter: true
      },
      name: "createdAt",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.createdAt?.show;
      },
      filters: [
        {
          path: ["createdAt"],
          preview: DateFilter
        }
      ]
    },
    {
      getData: (el) => el._id ?? "",
      label: "Actions",
      options: {
        filter: false
      },
      name: "actions",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.actions?.show;
      }
    }
  ];

  return (
    <AccessControl requiredPermissions={{ entity: "external_credit_application", action: "read" }}>
      <Table
        slice="table"
        tableName="external_credit_applications"
        title={"Online credit applications"}
        entityName="external_credit_application"
        columns={columns}
        listEntity={externalCreditApplicationsList}
        listFunction={getExternalCreditApplicationList}
        recoverEntityFunction={recoverExternalCreditApplication}
        deleteEntityFunction={deleteExternalCreditApplication}
        projection={projections}
      />
    </AccessControl>
  );
};
