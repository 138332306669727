import { RootState } from "app/rootReducer";
import AccessControl from "components/Access/AccessControl";
import DateFilter from "components/Filters/DateFilter";
import MultiSelectFilter from "components/Filters/MultiSelectFilter";
import PhoneFilter from "components/Filters/PhoneFilter";
import TextFilter from "components/Filters/TextFilter";
import React from "react";
import { useSelector } from "react-redux";
import States from "us-states";
import Table, { CellValue, Column } from "../Table";
import { deleteDefaultStipulation } from "./deleteDefaultStipulationSlice";
import { getDefaultStipulationList } from "./listDefaultStipulationSlice";
import { recoverDefaultStipulation } from "./recoverDefaultStipulationSlice";
import { FormState } from "./types";

export default (): JSX.Element => {
  const defaultStipulationList = useSelector(
    (state: RootState) => state.listDefaultStipulationSlice["table"]
  );
  const columns: Column<FormState>[] = [
    {
      label: "Description",
      getData: (el): CellValue => el.data.info.description,
      name: "description",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.description?.show;
      },
      filters: [
        {
          path: ["data", "info", "description"],
          preview: TextFilter,
          caseInsensitive: true,
          partialSearch: true,
          label: "Description",
          name: "description"
        }
      ]
    },
    {
      label: "System Name",
      getData: (el): CellValue => el.data.info.systemName,
      name: "systemName",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.systemName?.show;
      },
      filters: [
        {
          path: ["data", "info", "systemName"],
          preview: TextFilter,
          caseInsensitive: true,
          partialSearch: true,
          label: "System Name",
          name: "systemName"
        }
      ]
    },
    {
      label: "Date added",
      getData: (el): CellValue => new Date(el.createdAt),
      options: {},
      name: "createdAt",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.createdAt?.show;
      },
      filters: [
        {
          path: ["createdAt"],
          preview: DateFilter,
          label: "Created At",
          name: "createdAt"
        }
      ]
    },
    {
      label: "Actions",
      getData: (el): CellValue => el._id,
      name: "actions",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.actions?.show;
      }
    }
  ];

  return (
    <>
      <AccessControl requiredPermissions={{ entity: "dmv", action: "read" }}>
        <Table
          slice="table"
          tableName="Default Stipulations"
          entityName="default_stipulation"
          listFunction={getDefaultStipulationList}
          listEntity={defaultStipulationList}
          deleteEntityFunction={deleteDefaultStipulation}
          recoverEntityFunction={recoverDefaultStipulation}
          title={"Default Stipulations"}
          columns={columns}
        />
      </AccessControl>
    </>
  );
};
