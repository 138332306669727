import {
  Chip,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography
} from "@material-ui/core";
import { getStyleByStatus, statusToChipTooltip, statusToLabel } from "components/Deals/Deals";
import { DealData, DealStatus } from "components/Deals/types";
import ApplicantFilter from "components/Filters/ApplicantFilter";
import DateFilter from "components/Filters/DateFilter";
import DealershipFilter from "components/Filters/DealershipFilter";
import LenderFilter from "components/Filters/LenderFilter";
import MultiSelectFilter from "components/Filters/MultiSelectFilter";
import NetsuiteFilter from "components/Filters/NetsuiteFilter";
import PriceFilter from "components/Filters/PriceFilter";
import TextFilter from "components/Filters/TextFilter";
import UserFilter from "components/Filters/UserFilter";
import { CellValue, Column } from "components/Table";
import { EstimatedData, StatisticsData } from "hooks/useLenderEstimationStatistics/types";
import React, { useState } from "react";
import { capitalize, formatNumberAsCurrency, percentageDifference } from "utils/functions";
import DealsModal from "../DealsModal";
import classes from "../LenderDashboard.module.css";
import Big from "big.js";
const BORDER = "1px solid rgba(224, 224, 224, 1)";

export const columns = [
  {
    getData: (entry): CellValue =>
      [
        entry.data?.applicant
          ? `${entry.data?.applicant?.data?.info?.firstName ?? ""} ${
              entry.data?.applicant?.data?.info?.lastName ?? ""
            }`
          : undefined,
        entry.data?.coApplicant
          ? `${entry.data?.coApplicant?.data?.info?.firstName ?? ""} ${
              entry.data?.coApplicant?.data?.info?.lastName ?? ""
            }`
          : undefined
      ]
        .filter((x) => x)
        .join(" / "),
    label: "Customer",
    name: "customer",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.customer?.show;
    },
    filters: [
      {
        path: [
          ["data", "applicant", "data", "info", "firstName"],
          ["data", "applicant", "data", "info", "lastName"],
          ["data", "coApplicant", "data", "info", "firstName"],
          ["data", "coApplicant", "data", "info", "lastName"]
        ],
        preview: ApplicantFilter
      }
    ]
  },
  {
    getData: (entry): CellValue => entry.data?.info?.refNumber,
    label: "Ref #",
    options: {
      sort: true,
      path: ["data", "info", "refNumber"]
    },
    name: "refNumber",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.refNumber?.show;
    },
    filters: [
      {
        path: ["data", "info", "refNumber"],
        preview: TextFilter
      }
    ]
  },
  {
    getData: (entry): CellValue => entry.data?.dealership?.data?.info?.displayName ?? "",
    label: "Dealership",
    name: "dealership",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.dealership?.show;
    },
    filters: [
      {
        path: ["data", "dealershipId"],
        preview: DealershipFilter
      }
    ]
  },
  {
    getData: (entry): CellValue => `${entry.data?.dealership?.data?.info?.state ?? ""}`,
    label: "Dealer state",
    name: "dealerState",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.dealerState?.show;
    },
    filters: [
      {
        path: ["data", "dealership", "data", "info", "state"],
        preview: TextFilter,
        partialSearch: true,
        caseInsensitive: true
      }
    ]
  },
  {
    getData: (entry): CellValue => entry.data?.info?.vehicle?.make,
    label: "Make",
    name: "make",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.make?.show;
    },
    filters: [
      {
        path: ["data", "info", "vehicle", "make"],
        preview: TextFilter
      }
    ]
  },
  {
    getData: (entry): CellValue => entry.data?.info?.vehicle?.model,
    label: "Model",
    name: "model",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.model?.show;
    },
    filters: [
      {
        path: ["data", "info", "vehicle", "model"],
        preview: TextFilter
      }
    ]
  },
  {
    getData: (entry): CellValue => entry.data?.info?.vehicle?.VIN,
    label: "VIN",
    name: "vin",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.vin?.show;
    },
    filters: [
      {
        path: ["data", "info", "vehicle", "VIN"],
        preview: TextFilter
      }
    ]
  },
  {
    getData: (entry): CellValue => entry.data?.lender?.data?.info?.name,
    label: "Lender",
    name: "lender",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.lender?.show;
    },
    filters: [
      {
        path: ["data", "lenderId"],
        preview: LenderFilter
      }
    ]
  },
  {
    getData: (entry): CellValue =>
      `${entry.data?.user?.data?.info?.firstName ?? ""} ${
        entry.data?.user?.data?.info?.lastName ?? ""
      }`,
    label: "F&I mgr",
    name: "f&iManager",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.["f&iManager"]?.show;
    },
    filters: [
      {
        path: ["data", "userId"],
        preview: UserFilter,
        type: "f&i manager"
      }
    ]
  },
  {
    getData: (entry): CellValue =>
      `${entry.data?.dealership?.data?.representative?.data?.info?.firstName ?? ""} ${
        entry.data?.dealership?.data?.representative?.data?.info?.lastName ?? ""
      }`,
    label: "Rep",
    name: "rep",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.rep?.show;
    },
    filters: [
      {
        path: ["data", "dealership", "data", "representativeId"],
        preview: UserFilter,
        type: "representative"
      }
    ]
  },
  {
    getData: (entry): CellValue =>
      entry.data?.info?.dealDates?.contractDate
        ? new Date(entry.data?.info?.dealDates?.contractDate)
        : "",
    label: "Contract",
    options: {
      sort: true,
      path: ["data", "info", "dates", "contractDate"]
    },
    name: "contractDate",
    show: (_userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.contractDate?.show;
    },
    filters: [
      {
        path: ["data", "info", "dealDates", "contractDate"],
        preview: DateFilter,
        label: "Contract Date"
      }
    ]
  },
  {
    getData: (entry): CellValue => entry.data?.info?.status,
    label: "Status",
    options: {
      customBodyRender: (x: CellValue, deal): JSX.Element | string => {
        const dealStatus = x as DealStatus;
        return statusToChipTooltip(dealStatus, deal, getStyleByStatus(dealStatus));
      },
      sort: true,
      path: ["data", "info", "status"]
    },
    name: "status",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.status?.show;
    },
    filters: [
      {
        path: ["data", "info", "status"],
        preview: MultiSelectFilter,
        valuesForSelect: Object.values(DealStatus),
        optionLabelForSelect: (status) => statusToLabel(status)
      }
    ]
  },

  {
    getData: (entry): CellValue =>
      entry.data?.info?.dealDates?.submittedForFunding
        ? new Date(entry.data?.info?.dealDates?.submittedForFunding)
        : "",
    label: "Submitted at",
    options: {
      sort: true,
      path: ["data", "info", "dates", "submittedForFunding"]
    },
    name: "fundedAt",
    show: () => {
      return true;
    },
    filters: [
      {
        path: ["data", "info", "dealDates", "submittedForFunding"],
        preview: DateFilter,
        label: "Submitted for funding"
      }
    ]
  },
  {
    getData: (entry): CellValue => (entry as unknown) as string,
    label: "Netsuite",
    options: {
      customBodyRender: (x: CellValue, deal): JSX.Element | string => {
        return (
          <div style={{ whiteSpace: "nowrap" }}>
            {deal?.data?.netsuiteDealStatus?.data?.info?.netsuiteEstimateId && (
              <Chip size="small" label="E" style={{ fontFamily: "monospace" }} />
            )}
            {deal?.data?.netsuiteDealStatus?.data?.info?.netsuiteSalesOrderId && (
              <Chip
                size="small"
                label="S"
                style={{ backgroundColor: "#eba93f", fontFamily: "monospace" }}
              />
            )}
            {deal?.data?.netsuiteDealStatus?.data?.info?.netsuiteInvoiceId && (
              <Chip
                size="small"
                label="I"
                style={{ backgroundColor: "#4caf50", fontFamily: "monospace" }}
              />
            )}
            {(deal?.data?.netsuiteDealStatus?.data?.info?.netsuiteVendorBillIds ?? []).length >
              0 && (
              <Chip
                size="small"
                label="B"
                style={{ backgroundColor: "#4caf50", fontFamily: "monospace" }}
              />
            )}
          </div>
        );
      },
      sort: true,
      path: ["data"]
    },
    name: "netsuite",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.netsuite?.show;
    },
    filters: [
      {
        path: ["data", "netsuiteDealStatus", "data", "info", "netsuiteEstimateId"],
        name: "netsuiteEstimateId",
        label: "Netsuite Estimate Id",
        preview: NetsuiteFilter
      },
      {
        path: ["data", "netsuiteDealStatus", "data", "info", "netsuiteSalesOrderId"],
        name: "netsuiteSalesOrderId",
        label: "Netsuite Sales Order Id",
        preview: NetsuiteFilter
      },
      {
        path: ["data", "netsuiteDealStatus", "data", "info", "netsuiteInvoiceId"],
        name: "netsuiteInvoiceId",
        label: "Netsuite Invoice Id",
        preview: NetsuiteFilter
      },
      {
        path: ["data", "netsuiteDealStatus", "data", "info", "netsuiteVendorBillIds"],
        name: "netsuiteVendorBillIds",
        label: "Netsuite Vendor Bill Ids",
        preview: NetsuiteFilter
      },
      {
        path: ["data", "info", "payment", "fundedAmount"],
        name: "fundedAmount",
        label: "Funded Amount",
        preview: NetsuiteFilter
      }
    ]
  },
  {
    getData: (entry): CellValue =>
      formatNumberAsCurrency(entry.data?.info?.payment?.dealTotal ?? 0),
    label: "Amount Financed",
    name: "amountFinanced",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.amountFinanced?.show;
    },
    options: {
      sort: true,
      path: ["data", "info", "payment", "dealTotal"]
    },
    filters: [
      {
        path: ["data", "info", "payment", "dealTotal"],
        preview: PriceFilter
      }
    ]
  },
  {
    getData: (entry): CellValue =>
      formatNumberAsCurrency(entry.data?.info?.payment?.fundedAmount ?? 0),
    label: "Funded",
    name: "fundedAmount",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.fundedAmount?.show;
    },
    options: {
      sort: true,
      path: ["data", "info", "payment", "fundedAmount"]
    },
    filters: [
      {
        path: ["data", "info", "payment", "fundedAmount"],
        preview: PriceFilter
      }
    ]
  },
  {
    getData: (entry): CellValue =>
      entry.data?.info?.dealDates?.fundedAt ? new Date(entry.data?.info?.dealDates?.fundedAt) : "",
    label: "Funded At",
    options: {
      sort: true,
      path: ["data", "info", "dealDates", "fundedAt"]
    },
    name: "fundedAt",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.fundedAt?.show;
    },
    filters: [
      {
        path: ["data", "info", "dealDates", "fundedAt"],
        preview: DateFilter,
        label: "Funded At"
      }
    ]
  },
  {
    getData: (entry): CellValue => entry._id,
    label: "Approver",
    name: "approver",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.approver?.show;
    },
    filters: [
      {
        path: ["approvedByUserId"],
        preview: UserFilter,
        label: "Approver"
      }
    ]
  },
  {
    getData: (entry): CellValue => entry._id,
    label: "Actions",
    name: "actions",
    show: (userPermissions, tableSettings) => {
      return tableSettings?.data?.columns?.actions?.show;
    }
  }
] as Column<DealData>[];

type Props = {
  type: "current" | "compare";
  selectedStatuses: DealStatus[];
  truthfulDataAfterRollback: StatisticsData;
  truthfulData: StatisticsData;
  comparedTruthfulDataAfterRollback?: StatisticsData;
  comparedTruthfulData?: StatisticsData;
  totalChargebackAmountForStatisticsPeriod?: number;
  comparedTotalChargebackAmount?: number;
  estimatedData: EstimatedData;
  statusToCompareTo: DealStatus;
  comparedEstimatedData?: EstimatedData;
};

export default ({
  type,
  selectedStatuses,
  totalChargebackAmountForStatisticsPeriod,
  comparedTotalChargebackAmount,
  comparedEstimatedData,
  comparedTruthfulData,
  comparedTruthfulDataAfterRollback,
  truthfulDataAfterRollback,
  estimatedData,
  truthfulData,
  statusToCompareTo
}: Props) => {
  const statuses = [
    ...new Set([
      ...Object.keys(truthfulData.amountFinancedByStatus ?? {}),
      ...Object.keys(truthfulData.dealCountByStatus ?? {})
    ])
  ] as DealStatus[];

  const [dealIdsForModal, setDealIdsForModal] = useState<string[]>([]);

  //ESTIMATED COMPARE OVERALL
  const overallRollbackAmountFinancedPercentageDiff = percentageDifference(
    truthfulDataAfterRollback?.overall?.amountFinanced || 0,
    comparedTruthfulDataAfterRollback?.overall?.amountFinanced || 0
  );

  const overallEstimatedAmountFinancedPercentageDiff = percentageDifference(
    estimatedData?.overall?.amountFinanced || 0,
    comparedEstimatedData?.overall?.amountFinanced || 0
  );

  const overallEstimatedGrossRevenuePercentageDiff = percentageDifference(
    estimatedData?.overall?.grossRevenue || 0,
    comparedEstimatedData?.overall?.grossRevenue || 0
  );
  //ESTIMATED COMPARE OVERALL

  //TRUTHFUL OVERALL COMPARE
  const overallTruthfulAmountFinancedPercentageDiff = percentageDifference(
    truthfulData?.overall?.amountFinanced || 0,
    comparedTruthfulData?.overall?.amountFinanced || 0
  );

  const overallTruthfulGrossRevenuePercentageDiff = percentageDifference(
    truthfulData?.overall?.grossRevenue || 0,
    comparedTruthfulData?.overall?.grossRevenue || 0
  );

  const overallTotalChargebackAmountPercentageDiff = percentageDifference(
    totalChargebackAmountForStatisticsPeriod || 0,
    comparedTotalChargebackAmount || 0
  );

  //TRUTHFUL OVERALL COMPARE

  return (
    <Grid container item xs={12}>
      <Paper elevation={3} style={{ width: "100%" }}>
        <Grid item xs={12} style={{ padding: "5px" }}>
          <Typography style={{ fontSize: "22px", textAlign: "center", fontWeight: "bold" }}>
            {type === "compare" ? "(Compared) Estimated data" : "Estimated data"}
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ margin: "5px" }}>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{ borderTop: BORDER, borderRight: BORDER, padding: "10px" }}
                    align="center"
                  >
                    Status
                  </TableCell>
                  <TableCell
                    style={{ borderTop: BORDER, borderRight: BORDER }}
                    align="center"
                    colSpan={2}
                  >
                    Truthful data (with rollbacked statuses)
                  </TableCell>
                  <TableCell
                    style={{ borderTop: BORDER, borderRight: BORDER }}
                    align="center"
                    colSpan={7}
                  >
                    Estimated data
                  </TableCell>
                  <TableCell style={{ borderTop: BORDER }} align="center" colSpan={7}>
                    Chargebacks
                  </TableCell>
                </TableRow>
                <TableRow>
                  {/* STATUS HEAD */}
                  <TableCell
                    style={{ borderTop: BORDER, borderRight: BORDER }}
                    align="center"
                  ></TableCell>
                  {/* STATUS HEAD */}

                  {/* TRUTHFUL DATA HEAD*/}
                  <TableCell
                    style={{ borderTop: BORDER, borderRight: BORDER, padding: "10px" }}
                    align="center"
                  >
                    Count
                  </TableCell>
                  <TableCell style={{ borderTop: BORDER, borderRight: BORDER }} align="center">
                    Amount
                  </TableCell>
                  {/* TRUTHFUL DATA HEAD*/}

                  {/* ESTIMATED DATA HEAD*/}
                  <TableCell style={{ borderTop: BORDER, borderRight: BORDER }} align="center">
                    Average days
                  </TableCell>
                  <TableCell style={{ borderTop: BORDER, borderRight: BORDER }} align="center">
                    Could become {capitalize(statusToCompareTo)} %
                  </TableCell>
                  <TableCell style={{ borderTop: BORDER, borderRight: BORDER }} align="center">
                    Deal count(below average days)
                  </TableCell>
                  <TableCell style={{ borderTop: BORDER, borderRight: BORDER }} align="center">
                    Estimated amount %
                  </TableCell>
                  <TableCell style={{ borderTop: BORDER, borderRight: BORDER }} align="center">
                    Deal count(estimated to be funded)
                  </TableCell>
                  <TableCell style={{ borderTop: BORDER, borderRight: BORDER }} align="center">
                    Estimated amount
                  </TableCell>
                  <TableCell style={{ borderTop: BORDER, borderRight: BORDER }} align="center">
                    Estimated gross revenue
                  </TableCell>
                  {/* ESTIMATED DATA HEAD*/}
                  {/* CHARGEBACKS DATA HEAD*/}
                  <TableCell style={{ borderTop: BORDER }} align="center">
                    Total chargeback amount
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {selectedStatuses.map((status, index) => {
                  //ROLLBACK AMOUNT FINANCED
                  const rollbackAmountFinancedPercentageDiff = percentageDifference(
                    truthfulDataAfterRollback?.amountFinancedByStatus?.[status] || 0,
                    comparedTruthfulDataAfterRollback?.amountFinancedByStatus?.[status] || 0
                  );
                  //ROLLBACK AMOUNT FINANCED

                  //ESTIMATED AMOUNT FINANCED
                  const estimatedAmountFinancedPercentageDiff = percentageDifference(
                    estimatedData?.amountFinancedByStatus?.[status]?.amount || 0,
                    comparedEstimatedData?.amountFinancedByStatus?.[status]?.amount || 0
                  );
                  //ESTIMATED AMOUNT FINANCED

                  //ESTIMATED GROSS REVENUE
                  const estimatedGrossRevenuePercentageDiff = percentageDifference(
                    estimatedData?.grossRevenueByStatus?.[status]?.amount || 0,
                    comparedEstimatedData?.grossRevenueByStatus?.[status]?.amount || 0
                  );
                  //ESTIMATED GROSS REVENUE

                  return (
                    <TableRow key={index}>
                      {/* STATUS BODY(padding here grows the rest of the cells)*/}
                      <TableCell
                        style={{ borderRight: BORDER, padding: "5px", whiteSpace: "nowrap" }}
                        align="center"
                      >
                        {capitalize(status)}
                      </TableCell>
                      {/* STATUS BODY*/}

                      {/* TRUTHFUL DATA BODY*/}
                      <TableCell
                        style={{ borderRight: BORDER }}
                        align="center"
                        className={classes.clickableTableCell}
                        onClick={() => {
                          setDealIdsForModal(
                            truthfulDataAfterRollback?.dealIdsByStatus?.[status] || []
                          );
                        }}
                      >
                        {truthfulDataAfterRollback?.dealCountByStatus?.[status] || 0}
                      </TableCell>
                      <TableCell style={{ borderRight: BORDER }} align="center">
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {formatNumberAsCurrency(
                            truthfulDataAfterRollback?.amountFinancedByStatus?.[status] || 0,
                            "$"
                          )}

                          {comparedTruthfulDataAfterRollback?.amountFinancedByStatus?.[status] ? (
                            <Typography
                              style={{
                                fontSize: "10px",
                                color: rollbackAmountFinancedPercentageDiff > 0 ? "green" : "red"
                              }}
                            >
                              {`(${
                                rollbackAmountFinancedPercentageDiff > 0 ? "+" : "-"
                              }${rollbackAmountFinancedPercentageDiff.toFixed(2)}%)`}
                            </Typography>
                          ) : null}
                        </div>
                      </TableCell>
                      {/* TRUTHFUL DATA BODY*/}

                      {/* ESTIMATED DATA BODY*/}
                      <TableCell style={{ borderRight: BORDER }} align="center">
                        {estimatedData?.avgDaysByStatus?.[status] || 0}
                      </TableCell>
                      <TableCell style={{ borderRight: BORDER }} align="center">
                        {estimatedData?.dealCountByStatus?.[status]?.percentage || 0}%
                      </TableCell>
                      <TableCell
                        style={{ borderRight: BORDER }}
                        align="center"
                        className={classes.clickableTableCell}
                        onClick={() => {
                          setDealIdsForModal(estimatedData?.dealIdsByStatus?.[status] || []);
                        }}
                      >
                        {estimatedData?.dealCountByStatus?.[status]?.amount || 0}
                      </TableCell>
                      <TableCell style={{ borderRight: BORDER }} align="center">
                        {estimatedData?.amountFinancedByStatus?.[status]?.percentage || 0}%
                      </TableCell>
                      <TableCell
                        style={{ borderRight: BORDER }}
                        align="center"
                        className={classes.clickableTableCell}
                        onClick={() => {
                          setDealIdsForModal(estimatedData?.dealIdsFundedByStatus?.[status] || []);
                        }}
                      >
                        {estimatedData?.dealCountFundedByStatus?.[status]?.amount || 0}
                      </TableCell>
                      <TableCell align="center" style={{ borderRight: BORDER }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {formatNumberAsCurrency(
                            estimatedData?.amountFinancedByStatus?.[status]?.amount || 0,
                            "$"
                          )}
                          {comparedEstimatedData?.amountFinancedByStatus?.[status]?.amount ? (
                            <Typography
                              style={{
                                fontSize: "10px",
                                color: estimatedAmountFinancedPercentageDiff > 0 ? "green" : "red"
                              }}
                            >
                              {`(${
                                estimatedAmountFinancedPercentageDiff > 0 ? "+" : "-"
                              }${estimatedAmountFinancedPercentageDiff.toFixed(2)}%)`}
                            </Typography>
                          ) : null}
                        </div>
                      </TableCell>
                      <TableCell style={{ borderRight: BORDER }} align="center">
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {formatNumberAsCurrency(
                            estimatedData?.grossRevenueByStatus?.[status]?.amount || 0,
                            "$"
                          )}
                          {comparedEstimatedData?.grossRevenueByStatus?.[status]?.amount ? (
                            <Typography
                              style={{
                                fontSize: "10px",
                                color: estimatedGrossRevenuePercentageDiff > 0 ? "green" : "red"
                              }}
                            >
                              {`(${
                                estimatedGrossRevenuePercentageDiff > 0 ? "+" : "-"
                              }${estimatedGrossRevenuePercentageDiff.toFixed(2)}%)`}
                            </Typography>
                          ) : null}
                        </div>
                      </TableCell>
                      {/* ESTIMATED DATA BODY*/}
                      {/* CHARGEBACKS DATA BODY*/}
                      <TableCell align="center">-</TableCell>
                      {/* CHARGEBACKS DATA BODY*/}
                    </TableRow>
                  );
                })}
              </TableBody>

              <TableFooter>
                <TableRow key={"totals"} style={{ background: "#E8E8E8", marginTop: "auto" }}>
                  {/* STATUS FOOTER*/}
                  <TableCell
                    style={{ borderRight: BORDER, padding: "5px", fontSize: "14px" }}
                    align="center"
                  >
                    Overall
                  </TableCell>
                  {/* STATUS FOOTER*/}

                  {/* TRUTHFUL DATA FOOTER*/}
                  <TableCell
                    style={{ borderRight: BORDER, fontSize: "14px" }}
                    align="center"
                    className={classes.clickableTableCell}
                    onClick={() => {
                      setDealIdsForModal(truthfulDataAfterRollback?.overall?.dealIds || []);
                    }}
                  >
                    {truthfulDataAfterRollback?.overall?.dealCount || 0}
                  </TableCell>
                  <TableCell style={{ borderRight: BORDER, fontSize: "14px" }} align="center">
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {formatNumberAsCurrency(
                        truthfulDataAfterRollback?.overall?.amountFinanced || 0,
                        "$"
                      )}
                      {comparedTruthfulDataAfterRollback?.overall?.amountFinanced ? (
                        <Typography
                          style={{
                            fontSize: "10px",
                            color: overallRollbackAmountFinancedPercentageDiff > 0 ? "green" : "red"
                          }}
                        >
                          {`(${
                            overallRollbackAmountFinancedPercentageDiff > 0 ? "+" : "-"
                          }${overallRollbackAmountFinancedPercentageDiff.toFixed(2)}%)`}
                        </Typography>
                      ) : null}
                    </div>
                  </TableCell>
                  {/* TRUTHFUL DATA FOOTER*/}

                  {/* ESTIMATED DATA FOOTER*/}
                  <TableCell style={{ borderRight: BORDER, fontSize: "14px" }} align="center">
                    {estimatedData?.overall?.avgDays || 0}
                  </TableCell>
                  <TableCell style={{ borderRight: BORDER, fontSize: "14px" }} align="center">
                    {estimatedData?.overall?.percentageDealCount || 0}%
                  </TableCell>
                  <TableCell
                    style={{ borderRight: BORDER, fontSize: "14px" }}
                    align="center"
                    className={classes.clickableTableCell}
                    onClick={() => {
                      setDealIdsForModal(estimatedData?.overall?.dealIds || []);
                    }}
                  >
                    {estimatedData?.overall?.dealCount || 0}
                  </TableCell>
                  <TableCell style={{ borderRight: BORDER, fontSize: "14px" }} align="center">
                    {estimatedData?.overall?.percentageAmountFinanced || 0}%
                  </TableCell>
                  <TableCell
                    style={{ borderRight: BORDER, fontSize: "14px" }}
                    align="center"
                    className={classes.clickableTableCell}
                    onClick={() => {
                      setDealIdsForModal(estimatedData?.overall?.dealIdsFunded || []);
                    }}
                  >
                    {estimatedData?.overall?.dealCountFunded || 0}
                  </TableCell>
                  <TableCell align="center" style={{ borderRight: BORDER, fontSize: "14px" }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {formatNumberAsCurrency(estimatedData?.overall?.amountFinanced || 0, "$")}
                      {comparedEstimatedData?.overall?.amountFinanced ? (
                        <Typography
                          style={{
                            fontSize: "10px",
                            color:
                              overallEstimatedAmountFinancedPercentageDiff > 0 ? "green" : "red"
                          }}
                        >
                          {`(${
                            overallEstimatedAmountFinancedPercentageDiff > 0 ? "+" : "-"
                          }${overallEstimatedAmountFinancedPercentageDiff.toFixed(2)}%)`}
                        </Typography>
                      ) : null}
                    </div>
                  </TableCell>
                  <TableCell align="center" style={{ borderRight: BORDER, fontSize: "14px" }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {formatNumberAsCurrency(estimatedData?.overall?.grossRevenue || 0, "$")}
                      {comparedEstimatedData?.overall?.grossRevenue ? (
                        <Typography
                          style={{
                            fontSize: "10px",
                            color: overallEstimatedGrossRevenuePercentageDiff > 0 ? "green" : "red"
                          }}
                        >
                          {`(${
                            overallEstimatedGrossRevenuePercentageDiff > 0 ? "+" : "-"
                          }${overallEstimatedGrossRevenuePercentageDiff.toFixed(2)}%)`}
                        </Typography>
                      ) : null}
                    </div>
                  </TableCell>
                  {/* ESTIMATED DATA FOOTER*/}
                  {/* CHARGEBACKS DATA FOOTER*/}
                  <TableCell align="center" style={{ fontSize: "14px" }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {formatNumberAsCurrency(totalChargebackAmountForStatisticsPeriod || 0, "$")}
                      {comparedTotalChargebackAmount ? (
                        <Typography
                          style={{
                            fontSize: "10px",
                            color: overallTotalChargebackAmountPercentageDiff > 0 ? "green" : "red"
                          }}
                        >
                          {`(${
                            overallTotalChargebackAmountPercentageDiff > 0 ? "+" : "-"
                          }${overallTotalChargebackAmountPercentageDiff.toFixed(2)}%)`}
                        </Typography>
                      ) : null}
                    </div>
                  </TableCell>
                  {/* CHARGEBACKS DATA FOOTER*/}
                </TableRow>
                {/* FOOTER SUBTRACTION OF CHARGEBACKS  */}

                {(totalChargebackAmountForStatisticsPeriod || 0) > 0 && (
                  <React.Fragment>
                    <TableRow key="chargeback_subtraction_info" style={{ marginTop: "auto" }}>
                      <TableCell colSpan={9} style={{ border: "none" }}></TableCell>
                      <TableCell
                        align="center"
                        style={{
                          borderRight: BORDER,
                          borderLeft: BORDER,
                          fontSize: "14px",
                          background: "#E8E8E8"
                        }}
                      >
                        - {formatNumberAsCurrency(totalChargebackAmountForStatisticsPeriod, "$")}
                      </TableCell>

                      <TableCell style={{ border: "none" }}></TableCell>
                    </TableRow>
                    <TableRow key="chargeback_subtraction" style={{ marginTop: "auto" }}>
                      <TableCell colSpan={9} style={{ border: "none" }}></TableCell>
                      <TableCell
                        align="center"
                        style={{
                          borderRight: BORDER,
                          borderLeft: BORDER,
                          fontSize: "14px",
                          background: "#E8E8E8"
                        }}
                      >
                        {formatNumberAsCurrency(
                          new Big(estimatedData?.overall?.grossRevenue)
                            .sub(totalChargebackAmountForStatisticsPeriod as number)
                            .round(2, 1)
                            .toNumber(),
                          "$"
                        )}
                      </TableCell>
                      <TableCell style={{ border: "none" }}></TableCell>
                    </TableRow>
                  </React.Fragment>
                )}
                {/* FOOTER SUBTRACTION OF CHARGEBACKS */}
              </TableFooter>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={12} style={{ padding: "5px" }}>
          <Typography style={{ fontSize: "22px", textAlign: "center", fontWeight: "bold" }}>
            {type === "compare" ? "(Compared) Truthful (actual) data" : "Truthful (actual) data"}
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ margin: "5px " }}>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{ borderTop: BORDER, borderRight: BORDER, padding: "10px" }}
                    align="center"
                  >
                    Status
                  </TableCell>
                  <TableCell style={{ borderTop: BORDER }} align="center" colSpan={3}>
                    Actual data
                  </TableCell>
                </TableRow>
                <TableRow>
                  {/* STATUS HEAD */}
                  <TableCell
                    style={{ borderTop: BORDER, borderRight: BORDER }}
                    align="center"
                  ></TableCell>
                  {/* STATUS HEAD */}

                  {/* TRUTHFUL DATA HEAD*/}
                  <TableCell
                    style={{ borderTop: BORDER, borderRight: BORDER, padding: "10px" }}
                    align="center"
                  >
                    Count
                  </TableCell>
                  <TableCell style={{ borderTop: BORDER, borderRight: BORDER }} align="center">
                    Amount
                  </TableCell>
                  <TableCell style={{ borderTop: BORDER }} align="center">
                    Gross revenue
                  </TableCell>
                  {/* TRUTHFUL DATA HEAD*/}
                </TableRow>
              </TableHead>
              <TableBody>
                {statuses.map((status, index) => {
                  //TRUTHFUL AMOUNT FINANCED
                  const truthfulAmountFinancedPercentageDiff = percentageDifference(
                    truthfulData?.amountFinancedByStatus?.[status] || 0,
                    comparedTruthfulData?.amountFinancedByStatus?.[status] || 0
                  );
                  //TRUTHFUL AMOUNT FINANCED

                  //TRUTHFUL GROSS REVENUE
                  const truthfulGrossRevenuePercentageDiff = percentageDifference(
                    truthfulData?.grossRevenueByStatus?.[status] || 0,
                    comparedTruthfulData?.grossRevenueByStatus?.[status] || 0
                  );
                  //TRUTHFUL GROSS REVENUE
                  return (
                    <TableRow key={index}>
                      {/* STATUS BODY(padding here grows the rest of the cells)*/}
                      <TableCell style={{ borderRight: BORDER, padding: "5px" }} align="center">
                        {capitalize(status as DealStatus)}
                      </TableCell>
                      {/* STATUS BODY*/}

                      {/* TRUTHFUL DATA BODY*/}
                      <TableCell
                        style={{ borderRight: BORDER }}
                        align="center"
                        className={classes.clickableTableCell}
                        onClick={() => {
                          setDealIdsForModal(truthfulData?.dealIdsByStatus?.[status] || []);
                        }}
                      >
                        {truthfulData?.dealCountByStatus?.[status] || 0}
                      </TableCell>
                      <TableCell align="center" style={{ borderRight: BORDER }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {formatNumberAsCurrency(
                            truthfulData?.amountFinancedByStatus?.[status] || 0,
                            "$"
                          )}
                          {comparedTruthfulData?.grossRevenueByStatus?.[status] ? (
                            <Typography
                              style={{
                                fontSize: "10px",
                                color: truthfulAmountFinancedPercentageDiff > 0 ? "green" : "red"
                              }}
                            >
                              {`(${
                                truthfulAmountFinancedPercentageDiff > 0 ? "+" : "-"
                              }${truthfulAmountFinancedPercentageDiff.toFixed(2)}%)`}
                            </Typography>
                          ) : null}
                        </div>
                      </TableCell>
                      <TableCell align="center">
                        <div style={{ display: "flex", alignItems: "center" }}>
                          {formatNumberAsCurrency(
                            truthfulData?.grossRevenueByStatus?.[status] || 0,
                            "$"
                          )}
                          {comparedTruthfulData?.grossRevenueByStatus?.[status] ? (
                            <Typography
                              style={{
                                fontSize: "10px",
                                color: truthfulGrossRevenuePercentageDiff > 0 ? "green" : "red"
                              }}
                            >
                              {`(${
                                truthfulGrossRevenuePercentageDiff > 0 ? "+" : "-"
                              }${truthfulGrossRevenuePercentageDiff.toFixed(2)}%)`}
                            </Typography>
                          ) : null}
                        </div>
                      </TableCell>
                      {/* TRUTHFUL DATA BODY*/}
                    </TableRow>
                  );
                })}
              </TableBody>
              <TableFooter>
                <TableRow key={"totals"} style={{ background: "#E8E8E8", marginTop: "auto" }}>
                  {/* STATUS FOOTER*/}
                  <TableCell
                    style={{ borderRight: BORDER, padding: "5px", fontSize: "14px" }}
                    align="center"
                  >
                    Overall
                  </TableCell>
                  {/* STATUS FOOTER*/}

                  {/* TRUTHFUL DATA FOOTER*/}
                  <TableCell
                    style={{ borderRight: BORDER, fontSize: "14px" }}
                    align="center"
                    className={classes.clickableTableCell}
                    onClick={() => {
                      setDealIdsForModal(truthfulData?.overall?.dealIds || []);
                    }}
                  >
                    {truthfulData?.overall?.dealCount || 0}
                  </TableCell>
                  <TableCell style={{ borderRight: BORDER, fontSize: "14px" }} align="center">
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {formatNumberAsCurrency(truthfulData?.overall?.amountFinanced || 0, "$")}
                      {comparedTruthfulData?.overall?.amountFinanced ? (
                        <Typography
                          style={{
                            fontSize: "10px",
                            color: overallTruthfulAmountFinancedPercentageDiff > 0 ? "green" : "red"
                          }}
                        >
                          {`(${
                            overallTruthfulAmountFinancedPercentageDiff > 0 ? "+" : "-"
                          }${overallTruthfulAmountFinancedPercentageDiff.toFixed(2)}%)`}
                        </Typography>
                      ) : null}
                    </div>
                  </TableCell>
                  <TableCell style={{ fontSize: "14px" }} align="center">
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {formatNumberAsCurrency(truthfulData?.overall?.grossRevenue || 0, "$")}
                      {comparedTruthfulData?.overall?.grossRevenue ? (
                        <Typography
                          style={{
                            fontSize: "10px",
                            color: overallTruthfulGrossRevenuePercentageDiff > 0 ? "green" : "red"
                          }}
                        >
                          {`(${
                            overallTruthfulGrossRevenuePercentageDiff > 0 ? "+" : "-"
                          }${overallTruthfulGrossRevenuePercentageDiff.toFixed(2)}%)`}
                        </Typography>
                      ) : null}
                    </div>
                  </TableCell>
                  {/* TRUTHFUL DATA FOOTER*/}
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Grid>
      </Paper>
      {dealIdsForModal.length > 0 ? (
        <DealsModal
          columns={columns}
          data={[
            {
              period: "Deals",
              dealIds: dealIdsForModal
            }
          ]}
          closeFunction={() => setDealIdsForModal([])}
        />
      ) : null}
    </Grid>
  );
};
