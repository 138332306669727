import { StateAccess } from "utils/models/formGenerator";
import { createOptionsForSelect, Model } from "../../utils/models/fields";
import { DefaultStipulation } from "./types";
import { capitalize } from "utils/functions";

export const defaultStipulationStruct: Model<DefaultStipulation> = {
  formComponent: "model",
  schema: "new_default_stipulation",
  name: "default_stipulation",
  entities: [
    {
      formComponent: "segment",
      name: "Default Stipulation Info",
      entities: [
        {
          formComponent: "text-field",
          name: "description",
          label: "Description",
          required: true,
          width: "1/4",
          path: ["data", "info", "description"],
          default: null
        },
        {
          formComponent: "text-field",
          name: "systemName",
          label: "System Name",
          required: true,
          width: "1/4",
          path: ["data", "info", "systemName"],
          default: null
        },
        {
          formComponent: "multiselect-field",
          name: "canBeUploadedBy",
          label: "Can be uploaded by",
          width: "1/4",
          required: true,
          path: ["data", "info", "canBeUploadedBy"],
          options: createOptionsForSelect({
            possibleValues: () => ["applicant", "coApplicant", "dealer"],
            getOptionLabel: (x) => (x === "coApplicant" ? "Co-Applicant" : capitalize(x)),
            getSelectedOption: (x, y) => x === y
          }),
          default: null
        },
        {
          formComponent: "select-field",
          name: "owner",
          label: "Owner",
          width: "1/4",
          path: ["data", "info", "owner"],
          options: createOptionsForSelect({
            possibleValues: () => ["applicant", "coApplicant"],
            getOptionLabel: (x) => (x === "coApplicant" ? "Co-Applicant" : capitalize(x)),
            getSelectedOption: (x, y) => x === y
          }),
          default: null
        },
        {
          formComponent: "checkbox-field",
          name: "multipleAddresses",
          label: "Add on deal created status?",
          required: false,
          width: "1/4",
          valueType: "boolean",
          path: ["data", "info", "addOnDealCreated"],
          isDisabled: () => false,
          isChecked: (stateAccess: StateAccess) =>
            stateAccess.get<DefaultStipulation>(["data", "info", "addOnDealCreated"]),
          toggle: (stateAccess: StateAccess) =>
            stateAccess.set<DefaultStipulation>(
              ["data", "info", "addOnDealCreated"],
              !stateAccess.get<DefaultStipulation>(["data", "info", "addOnDealCreated"])
            ),
          default: false
        }
      ]
    }
  ]
};
