import React, { useCallback } from "react";
import {
  AdvancedMarker,
  AdvancedMarkerAnchorPoint,
  useAdvancedMarkerRef
} from "@vis.gl/react-google-maps";
import { AiFillBank } from "react-icons/ai";

type TreeMarkerProps = {
  position: google.maps.LatLngLiteral;
  featureId: string;
  onMarkerClick?: (marker: google.maps.marker.AdvancedMarkerElement, featureId: string) => void;
};

export const FeatureMarkerLender = ({ position, featureId, onMarkerClick }: TreeMarkerProps) => {
  const [markerRef, marker] = useAdvancedMarkerRef();
  const handleClick = useCallback(
    () => onMarkerClick && marker && onMarkerClick(marker, featureId),
    [onMarkerClick, marker, featureId]
  );
  return (
    <AdvancedMarker
      ref={markerRef}
      position={position}
      onClick={handleClick}
      anchorPoint={AdvancedMarkerAnchorPoint.CENTER}
    >
      <AiFillBank size={18} color={"#68BBE3"} />
    </AdvancedMarker>
  );
};
