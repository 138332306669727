import React, { Ref, useCallback, useEffect } from "react";
import Supercluster, { ClusterProperties } from "supercluster";
import { Feature, FeatureCollection, GeoJsonProperties, Point } from "geojson";
import { FeatureMarkerDeal } from "./FeatureMarkerDeal";
import { FeaturesClusterMarker } from "./FeaturesClusterMarker";
import { useSupercluster } from "./useSupercluster";
import { DealStatus } from "components/Deals/types";
import { DealsFeaturesProps } from "./types";

type ClusteredMarkersProps = {
  geojson: FeatureCollection<Point>;
  clusterColor: string;
  clusterClickCallback: (
    data: {
      anchor: google.maps.marker.AdvancedMarkerElement;
      features: Feature<Point>[];
    } | null
  ) => void;
  markerClickCallback: (
    data: {
      anchor: google.maps.marker.AdvancedMarkerElement;
      features: Feature<Point>[];
    } | null
  ) => void;
};
const superclusterOptions: Supercluster.Options<GeoJsonProperties, ClusterProperties> = {
  extent: 256,
  maxZoom: 12
};

export const ClusteredMarkers = ({
  clusterColor,
  geojson,
  clusterClickCallback,
  markerClickCallback
}: ClusteredMarkersProps) => {
  const { clusters, getLeaves } = useSupercluster(geojson, superclusterOptions);
  const handleClusterClick = useCallback(
    (marker: google.maps.marker.AdvancedMarkerElement, clusterId: number) => {
      const leaves = getLeaves(clusterId);
      clusterClickCallback({ anchor: marker, features: leaves });
    },
    [getLeaves]
  );

  const handleMarkerClick = useCallback(
    (marker: google.maps.marker.AdvancedMarkerElement, featureId: string) => {
      const feature = clusters.find((feat) => feat.id === featureId) as Feature<Point>;

      markerClickCallback({ anchor: marker, features: [feature] });
    },
    [clusters]
  );

  return (
    <>
      {clusters.map((feature) => {
        const [lng, lat] = feature.geometry.coordinates;

        const clusterProperties = feature.properties as DealsFeaturesProps & ClusterProperties;
        const isCluster: boolean = clusterProperties.cluster;
        return isCluster ? (
          <FeaturesClusterMarker
            key={feature.id}
            clusterId={clusterProperties.cluster_id}
            position={{ lat, lng }}
            size={clusterProperties.point_count}
            clusterColor={clusterColor}
            sizeAsText={String(clusterProperties.point_count_abbreviated)}
            onMarkerClick={handleClusterClick}
          />
        ) : (
          <FeatureMarkerDeal
            key={feature.id}
            featureId={feature.id as string}
            position={{ lat, lng }}
            clusterColor={clusterColor}
            featureProperties={feature.properties as DealsFeaturesProps}
            onMarkerClick={handleMarkerClick}
          />
        );
      })}
    </>
  );
};
