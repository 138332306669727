import {
  permissionNode,
  relationPermissionNode,
  PermissionStruct,
  multyPermissionNode
} from "components/Roles/types";
import { NetsuiteDealStatus } from "./types";

export type NesuitePermissionStruct = PermissionStruct<NetsuiteDealStatus>;

export const netsuiteDealStatusPermissionsStruct: NesuitePermissionStruct = {
  _id: permissionNode("Id"),
  createdAt: permissionNode("Created at date"),
  updatedAt: permissionNode("Updated at date"),
  deleted: permissionNode("Is deleted"),
  creatorId: permissionNode("Creator Id"),
  approved: permissionNode("Approved"),
  approvedAt: permissionNode("approvedAt"),
  approvedByUserId: permissionNode("approvedByUserId"),
  approvedByUser: relationPermissionNode("Approved by", "wfd_user"),
  availableToRoles: permissionNode("Available to roles"),
  availableToRolesIds: permissionNode("Available to roles Ids"),
  data: {
    dealId: permissionNode("Data"),
    info: {
      netsuiteInvoiceId: permissionNode("Netsuite Invoice Id"),
      netsuiteEstimateId: permissionNode("Netsuite Estimate Id"),
      netsuiteSalesOrderId: permissionNode("Netsuite Sales Order Id"),
      netsuiteVendorBillIds: multyPermissionNode("Netsuite Vendor Bill Ids"),
      netsuiteStatus: {
        estimate: {
          status: permissionNode("Estimate Status"),
          message: permissionNode("Estimate Message")
        },
        invoiceVendorBill: {
          status: permissionNode("Invoice Vendor Bill Status"),
          message: permissionNode("Invoice Vendor Bill Message")
        },
        salesOrder: {
          status: permissionNode("Sales Order Status"),
          message: permissionNode("Sales Order Message")
        }
      }
    }
  }
};
