import { Button, Step, StepLabel, Stepper, Tooltip } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import StepIcon from "@material-ui/core/StepIcon";
import Error from "@material-ui/icons/Error";
import AccessControl from "components/Access/AccessControl";
import formEditContext from "components/Content/FormEditContext";
import { Deal } from "components/Deals/types";
import { VerificationContext } from "components/Layout/Main";
import { useSnackbar } from "notistack";
import React, { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { RenderSet, StateAccess } from "utils/models/formGenerator";
import { createInvoiceAndVendorBill } from "./createInvoiceAndVendorBillSlice";
import CreateInvoiceAndVendorBillsModal from "./CreateInvoiceAndVendorBillsModal";

type NetsuiteStatus = "success" | "error" | "none" | "loading";
export default ({ stateAccess, renderSet }: { stateAccess: StateAccess; renderSet: RenderSet }) => {
  const { enqueueSnackbar } = useSnackbar();
  const requestId = "createInvoiceAndVendorBill";
  const [open, setOpen] = useState(false);

  const deal: Deal = stateAccess.get<Deal>([]);
  const dealId = deal?._id;
  const checkDataValidity = useContext(VerificationContext);
  const { edited } = useContext(formEditContext);
  const dispatch = useDispatch();

  const estimationId = deal?.data?.netsuiteDealStatus?.data?.info?.netsuiteEstimateId ?? "";
  const salesOrderId = deal?.data?.netsuiteDealStatus?.data?.info?.netsuiteSalesOrderId ?? "";
  const invoiceId = deal?.data?.netsuiteDealStatus?.data?.info?.netsuiteInvoiceId ?? "";
  const netsuiteVendorBillIds =
    deal?.data?.netsuiteDealStatus?.data?.info?.netsuiteVendorBillIds ?? [];
  const activeStep = estimationId ? 0 : -1;
  const requiredFields = [
    { name: "Ref number", value: deal?.data?.info?.refNumber },
    { name: "Dealership Id", value: deal?.data?.dealership?.data?.netsuiteId },
    { name: "Applicant firstName", value: deal?.data?.applicant?.data?.info?.firstName },
    { name: "Applicant last name", value: deal?.data?.applicant?.data?.info?.lastName },
    {
      name: "Funded amount should be equal to Amount financed",
      value:
        deal?.data?.info?.payment?.fundedAmount === deal?.data?.info?.payment?.dealTotal
          ? true
          : undefined
    },
    {
      name: "Sold by in the products",
      value: (deal?.data?.info?.aftermarketOptions?.insurances ?? []).every(
        (insurance) => typeof insurance.chosenRate.soldBy === "string"
      )
        ? true
        : undefined
    },
    { name: "Signed at", value: deal?.data?.info?.dealDates?.contractDate },
    { name: "Employee id", value: deal?.data?.user?.data?.netsuiteId },
    { name: "Accounting section", value: deal?.data?.info?.accounting?.copied ? true : undefined }
  ];

  const stepLabelProps = (status: NetsuiteStatus | undefined, id: string | undefined) => {
    const defaultProps = {
      StepIconComponent: StepIcon,
      StepIconProps: {
        size: 25
      }
    };
    switch (status) {
      case "error":
        return {
          StepIconComponent: Error,
          StepIconProps: {
            size: 25
          },
          error: true
        };
      case "loading":
        return {
          StepIconComponent: CircularProgress,
          StepIconProps: {
            size: 25
          }
        };
      case "none":
        return defaultProps;

      case "success":
        if (id) {
          return {
            StepIconComponent: StepIcon,
            StepIconProps: {
              size: 25
            },
            completed: true
          };
        } else return defaultProps;
      default:
        return defaultProps;
    }
  };
  const createInvoiceAndVendorBills = (
    invoice: boolean,
    gapBill: boolean,
    warrantyBill: boolean,
    dealerBill: boolean
  ) => {
    checkDataValidity(requiredFields, () =>
      dispatch(
        createInvoiceAndVendorBill({
          requestId,
          data: { dealId, invoice, gapBill, warrantyBill, dealerBill }
        })
      )
    );
  };

  return (
    <>
      <CreateInvoiceAndVendorBillsModal
        open={open}
        setOpen={setOpen}
        createInvoiceAndVendorBills={createInvoiceAndVendorBills}
        renderSet={renderSet}
        netsuiteDealStatus={deal?.data?.netsuiteDealStatus}
        stateAccess={stateAccess}
      />
      <Stepper activeStep={activeStep} alternativeLabel>
        <Step
          completed={
            estimationId?.length > 0 &&
            deal?.data?.netsuiteDealStatus?.data?.info?.netsuiteStatus?.estimate?.status ===
              "success"
          }
          key={"Estimation"}
        >
          {/* @ts-ignore */}
          <StepLabel
            //@ts-ignore
            {...stepLabelProps(
              deal?.data?.netsuiteDealStatus?.data?.info?.netsuiteStatus?.estimate?.status,
              deal?.data?.netsuiteDealStatus?.data?.info?.netsuiteEstimateId
            )}
          >
            {/* {statuses.estimate.message} */}
            Estimate
          </StepLabel>
          {estimationId && (
            <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
              <Button
                variant="contained"
                target="_blank"
                href={`${process.env.REACT_APP_NETSUITE_LINK}/app/accounting/transactions/estimate.nl?id=${estimationId}`}
              >
                Open in netsuite
              </Button>
            </div>
          )}
        </Step>
        <Step completed={salesOrderId?.length > 0} key={"Sales Order"}>
          {/* @ts-ignore */}
          <StepLabel
            //@ts-ignore
            {...stepLabelProps(
              deal?.data?.netsuiteDealStatus?.data?.info?.netsuiteStatus?.salesOrder?.status,
              deal?.data?.netsuiteDealStatus?.data?.info?.netsuiteSalesOrderId
            )}
          >
            {/* {statuses.salesOrder.message} */}
            Sales Order
          </StepLabel>
          {salesOrderId && (
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                position: "relative"
              }}
            >
              <Button
                variant="contained"
                target="_blank"
                href={`${process.env.REACT_APP_NETSUITE_LINK}/app/accounting/transactions/salesord.nl?id=${salesOrderId}`}
              >
                Open in netsuite
              </Button>
              <AccessControl requiredPermissions={{ entity: "netsuite", action: "create" }}>
                <Tooltip
                  placement="top"
                  open={edited}
                  title="Save the deal to create vendor bills and an invoice."
                >
                  <Button
                    disabled={edited}
                    style={{
                      position: "absolute",
                      right: "-100px",
                      top: "-66px",
                      width: "150px",
                      zIndex: 1
                    }}
                    variant="contained"
                    onClick={() => {
                      if (renderSet.type === "edit") {
                        setOpen(true);
                      } else {
                        enqueueSnackbar("Please enter edit mode!", { variant: "error" });
                      }
                    }}
                  >
                    Create Invoice and Vendor Bills
                  </Button>
                </Tooltip>
              </AccessControl>
            </div>
          )}
        </Step>
        <Step completed={invoiceId?.length > 0} key={"Invoice/Vendor Bill"}>
          {/* @ts-ignore */}
          <StepLabel
            //@ts-ignore
            {...stepLabelProps(
              deal?.data?.netsuiteDealStatus?.data?.info?.netsuiteStatus?.invoiceVendorBill?.status,
              deal?.data?.netsuiteDealStatus?.data?.info?.netsuiteInvoiceId
            )}
          >
            {/* {statuses.invoiceVendorBill.message} */}
            Invoice/Vendor Bill
          </StepLabel>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              marginBottom: "10px"
            }}
          >
            {invoiceId && (
              <Button
                variant="contained"
                target="_blank"
                href={`${process.env.REACT_APP_NETSUITE_LINK}/app/accounting/transactions/custinvc.nl?id=${invoiceId}`}
              >
                Open invoice in netsuite
              </Button>
            )}
          </div>
          {netsuiteVendorBillIds
            .filter((x) => x)
            .map(({ vendorBillId, type }: any, index: any) => (
              <div
                key={index}
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  marginBottom: "10px"
                }}
              >
                <Button
                  variant="contained"
                  target="_blank"
                  href={`${process.env.REACT_APP_NETSUITE_LINK}/app/accounting/transactions/vendbill.nl?id=${vendorBillId}`}
                >
                  Open {type} bill
                </Button>
              </div>
            ))}
        </Step>
      </Stepper>
    </>
  );
};
