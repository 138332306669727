import React from "react";
import { useSelector } from "react-redux";

import { RootState } from "app/rootReducer";
import AccessControl from "components/Access/AccessControl";
import DateFilter from "components/Filters/DateFilter";
import DealershipFilter from "components/Filters/DealershipFilter";
import LenderFilter from "components/Filters/LenderFilter";
import MultiSelectFilter from "components/Filters/MultiSelectFilter";
import PriceFilter from "components/Filters/PriceFilter";
import UserFilter from "components/Filters/UserFilter";
import { averageNumbers, sumNumbersWithSign } from "utils/calcReportTotal";
import formatDate from "utils/formatDate";

import { capitalize, formatNumberAsCurrency } from "utils/functions";
import Table, { CellValue, Column } from "../Table";
import { deleteChargeback } from "./deleteChargebackSlice";
import { getChargebackList } from "./listChargebackSlice";
import { recoverChargeback } from "./recoverChargebackSlice";
import { FormState } from "./types";

export const projections = {
  "data.info.type": 1,
  "data.info.netsuiteStatus": 1,
  "data.deal.contractDate": 1,
  "data.deal.applicantFirstName": 1,
  "data.deal.applicantLastName": 1,
  "data.manager": 1,
  "data.managerId": 1,
  "data.deal.term": 1,
  "data.deal.interestRate": 1,
  "data.deal.fundingDate": 1,
  "data.dealership.data.info.displayName": 1,
  "data.info.chargebackDate": 1,
  "data.info.refId": 1,
  "data.lender.data.info.name": 1,
  "data.info.totalChargebackAmount": 1,
  "data.info.dealerChargebackAmount": 1,
  "data.info.wfdChargebackAmount": 1,
  "data.info.netWFDChargeBackAmount": 1,
  "data.info.managerChargebackAmount": 1,
  "data.info.dealerChargebackCalculation": 1,
  "data.info.wfdChargebackCalculation": 1,
  "data.info.managerChargebackCalculation": 1,
  _id: 1,
  createdAt: 1,
  creatorId: 1,
  updatedAt: 1,
  deleted: 1
};

export default ({
  query,
  listId
}: {
  query?: Record<string, unknown>;
  listId?: string;
}): JSX.Element => {
  const slice = listId ?? "table";
  const chargebackList = useSelector((state: RootState) => state.listChargebackSlice[slice]);
  const columns: Column<FormState>[] = [
    {
      label: "Ref ID",
      getData: (el): CellValue => `${el?.data?.info?.refId ?? formatDate(el?.createdAt, "short")}`,
      name: "Ref",
      truncate: 15,
      show: () => true,
      total: () => "Total:"
    },

    {
      label: "Total Amount",
      total: sumNumbersWithSign,
      name: "amount",
      getData: (el): CellValue =>
        formatNumberAsCurrency(Number(el.data.info.totalChargebackAmount), "$"),
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.amount?.show;
      },
      filters: [
        {
          path: ["data", "info", "totalChargebackAmount"],
          preview: PriceFilter,
          name: "totalChargebackAmount"
        }
      ]
    },
    {
      label: "Dealer Amount",
      name: "dealerAmount",
      total: sumNumbersWithSign,
      getData: (el): CellValue =>
        formatNumberAsCurrency(Number(el.data.info.dealerChargebackAmount), "$"),
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.dealerAmount?.show;
      },
      filters: [
        {
          path: ["data", "info", "dealerChargebackAmount"],
          preview: PriceFilter,
          name: "dealerAmount"
        }
      ],
      onHover: (entry) => entry.data.info.dealerChargebackCalculation ?? ""
    },
    {
      label: "WFD Total Amount",
      name: "wfdTotalAmount",
      total: sumNumbersWithSign,
      getData: (el): CellValue =>
        formatNumberAsCurrency(Number(el.data.info.wfdChargebackAmount), "$"),
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.wfdTotalAmount?.show;
      },
      filters: [
        {
          path: ["data", "info", "wfdChargebackAmount"],
          preview: PriceFilter,
          name: "wfdTotalAmount"
        }
      ],
      onHover: (entry) => entry.data.info.wfdChargebackCalculation ?? ""
    },
    {
      label: "Manager Amount",
      name: "managerAmount",
      total: sumNumbersWithSign,
      getData: (el): CellValue =>
        formatNumberAsCurrency(Number(el.data.info.managerChargebackAmount), "$"),
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.managerAmount?.show;
      },
      filters: [
        {
          path: ["data", "info", "managerChargebackAmount"],
          preview: PriceFilter,
          name: "managerAmount"
        }
      ],
      onHover: (entry) => {
        return entry.data.info.managerChargebackCalculation ?? "";
      }
    },
    {
      label: "Net Amount",
      total: sumNumbersWithSign,
      name: "netAmount",
      getData: (el): CellValue =>
        formatNumberAsCurrency(Number(el.data.info.netWFDChargeBackAmount), "$"),
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.netAmount?.show;
      },
      filters: [
        {
          path: ["data", "info", "netWFDChargeBackAmount"],
          preview: PriceFilter,
          name: "netAmount"
        }
      ]
    },
    {
      label: "Type",
      getData: (el): CellValue => capitalize(el.data.info.type),
      name: "type",
      total: () => "Average",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.type?.show;
      },

      filters: [
        {
          path: ["data", "info", "type"],
          preview: MultiSelectFilter,
          valuesForSelect: ["Reserve", "Warranty", "GAP"],
          label: "Type",
          name: "type"
        }
      ]
    },
    {
      label: "Interest Rate",
      name: "rate",
      total: averageNumbers,
      getData: (el): CellValue => el.data?.deal?.interestRate ?? "",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.rate?.show;
      },
      filters: [
        {
          path: ["data", "deal", "interestRate"],
          preview: PriceFilter,
          name: "rate"
        }
      ]
    },
    {
      getData: (entry): CellValue =>
        `${entry?.data?.manager?.data?.info?.firstName ?? ""} ${
          entry?.data?.manager?.data?.info?.lastName ?? ""
        } `,
      label: "F&I Manager",
      name: "manager",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.manager?.show;
      },
      filters: [
        {
          path: ["data", "managerId"],
          preview: UserFilter,
          type: "f&i manager"
        }
      ]
    },
    {
      getData: (entry): CellValue =>
        `${entry.data?.deal?.applicantFirstName ?? ""} ${
          entry.data?.deal?.applicantLastName ?? ""
        } `,
      label: "Customer",
      name: "customer",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.customer?.show;
      }
    },
    {
      getData: (entry): CellValue => entry.data?.lender?.data?.info?.name,
      label: "Lender",
      name: "lender",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.lender?.show;
      },
      filters: [
        {
          path: ["data", "lenderId"],
          preview: LenderFilter
        }
      ]
    },
    {
      getData: (entry): CellValue => entry.data?.dealership?.data?.info?.displayName ?? "",
      label: "Dealership",
      name: "dealership",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.dealership?.show;
      },
      filters: [
        {
          path: ["data", "dealershipId"],
          preview: DealershipFilter
        }
      ]
    },
    {
      label: "Funding date",
      name: "fundingDate",
      getData: (el): CellValue =>
        el?.data?.deal?.fundingDate ? new Date(el.data.deal.fundingDate) : "",
      show: (_, tableSettings) => {
        return tableSettings?.data?.columns?.chargebackDate?.show;
      },
      filters: [
        {
          path: ["data", "deal", "fundingDate"],
          preview: DateFilter
        }
      ]
    },
    {
      label: "Chargeback date",
      name: "chargebackDate",
      getData: (el): CellValue =>
        el?.data?.info?.chargebackDate ? new Date(el.data.info.chargebackDate) : "",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.chargebackDate?.show;
      },
      filters: [
        {
          path: ["data", "info", "chargebackDate"],
          preview: DateFilter
        }
      ]
    },
    {
      getData: (entry): CellValue => {
        switch (entry.data?.info?.netsuiteStatus) {
          case "success":
            return "Yes";
          case "error":
            return "No";
          default:
            return "Not pushed";
        }
      },
      label: "Pushed to netsuite",
      truncate: 40,
      options: {
        sort: true,
        path: ["data", "info", "netsuiteStatus"]
      },
      name: "pushedToNetsuite",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.pushedToNetsuite?.show;
      },
      filters: [
        {
          path: ["data", "info", "netsuiteStatus"],
          preview: MultiSelectFilter,
          valuesForSelect: ["success", "error", null],
          optionLabelForSelect: (x) => {
            switch (x) {
              case "success":
                return "Yes";
              case "error":
                return "No";
              default:
                return "Not pushed";
            }
          }
        }
      ]
    },
    {
      label: "Actions",
      getData: (el): CellValue => el._id,
      name: "actions",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.actions?.show;
      }
    }
  ];

  return (
    <>
      <AccessControl requiredPermissions={{ entity: "chargeback", action: "read" }}>
        <Table
          tableName="chargebacks"
          entityName="chargeback"
          listFunction={getChargebackList}
          slice={slice}
          listEntity={chargebackList}
          query={query}
          deleteEntityFunction={deleteChargeback}
          recoverEntityFunction={recoverChargeback}
          title={"Chargebacks"}
          columns={columns}
          showTotalFooter={true}
          projection={projections}
        />
      </AccessControl>
    </>
  );
};
