import React from "react";
import ReactApexChart from "react-apexcharts";
import Paper from "@material-ui/core/Paper";
import { ValueByStatus } from "hooks/useLenderAllocationStatistics/types";
import styles from "../LenderDashboard.module.css";
import { formatNumberAsCurrency, percentageDifference } from "utils/functions";
import { DealStatus } from "components/Deals/types";
import { useMediaQuery } from "@material-ui/core";

interface Props {
  data: ValueByStatus | (ValueByStatus & { unallocated: number }) | undefined;
  comparedData?: ValueByStatus | (ValueByStatus & { unallocated: number }) | undefined;
  chartType: "bar" | "pie";
  title: string;
  unallocated?: boolean;
  currencyFormat?: boolean;
  selectedStatuses: DealStatus[];
}

const addSelectedStatusesToData = (
  data: ValueByStatus | (ValueByStatus & { unallocated: number }) | undefined,
  selectedStatuses: DealStatus[]
): ValueByStatus => {
  const dataWithAllStatuses = {
    [DealStatus.Lead]: data?.[DealStatus.Lead] ?? 0,
    [DealStatus.DealCreated]: data?.[DealStatus.DealCreated] ?? 0,
    [DealStatus.WaitingForDealDetails]: data?.[DealStatus.WaitingForDealDetails] ?? 0,
    [DealStatus.CreditCheck]: data?.[DealStatus.CreditCheck] ?? 0,
    [DealStatus.PendingLenderDecision]: data?.[DealStatus.PendingLenderDecision] ?? 0,
    [DealStatus.Approved]: data?.[DealStatus.Approved] ?? 0,
    [DealStatus.SentToDealer]: data?.[DealStatus.SentToDealer] ?? 0,
    [DealStatus.SubmittedForFunding]: data?.[DealStatus.SubmittedForFunding] ?? 0,
    [DealStatus.FundingHeld]: data?.[DealStatus.FundingHeld] ?? 0,
    [DealStatus.Funded]: data?.[DealStatus.Funded] ?? 0,
    [DealStatus.Countered]: data?.[DealStatus.Countered] ?? 0,
    [DealStatus.Cancelled]: data?.[DealStatus.Cancelled] ?? 0,
    [DealStatus.Denied]: data?.[DealStatus.Denied] ?? 0,
    [DealStatus.Dead]: data?.[DealStatus.Dead] ?? 0
  };
  return Object.fromEntries(
    Object.entries(dataWithAllStatuses).filter(([key, value]) =>
      selectedStatuses.includes(key as DealStatus)
    )
  );
};

const dataHasPropertyUnallocated = (
  data: ValueByStatus | (ValueByStatus & { unallocated: number }) | undefined
): data is ValueByStatus & { unallocated: number } => Object.hasOwn(data ?? {}, "unallocated");
const sortData = (
  data: ValueByStatus | (ValueByStatus & { unallocated: number }) | undefined,
  selectedStatuses: DealStatus[],
  unallocated: boolean
): Record<DealStatus | "unallocated", { data: number; color: string }> => {
  const dataWithSelectedStatuses = addSelectedStatusesToData(data, selectedStatuses);
  const order = [
    DealStatus.Lead,
    DealStatus.CreditCheck,
    DealStatus.WaitingForDealDetails,
    DealStatus.DealCreated,
    DealStatus.PendingLenderDecision,
    DealStatus.Approved,
    DealStatus.SentToDealer,
    DealStatus.SubmittedForFunding,
    DealStatus.FundingHeld,
    DealStatus.Funded,
    DealStatus.Countered,
    DealStatus.Cancelled,
    DealStatus.Denied,
    DealStatus.Dead
  ];
  const orderMap = new Map();
  order.forEach((value, index) => {
    orderMap.set(value, index);
  });

  const sortedDataEntries = Object.entries(dataWithSelectedStatuses ?? {}).sort(
    ([keyA, valueA], [keyB, valueB]) => {
      const indexA = orderMap.get(keyA);
      const indexB = orderMap.get(keyB);

      if (indexA === undefined && indexB === undefined) {
        return 0;
      } else if (indexA === undefined) {
        return 1;
      } else if (indexB === undefined) {
        return -1;
      }

      return indexA - indexB;
    }
  );
  const sortedData = {
    ...Object.fromEntries(sortedDataEntries),
    ...(unallocated
      ? { unallocated: dataHasPropertyUnallocated(data) ? data?.unallocated : 0 }
      : {})
  } as ValueByStatus & { unallocated?: number };
  const sortedDataWithColors = addColors(sortedData);
  return sortedDataWithColors;
};
const addColors = (
  obj: ValueByStatus & {
    unallocated?: number | undefined;
  }
): Record<DealStatus | "unallocated", { data: number; color: string }> => ({
  ...Object.entries(obj).reduce(
    (acc: Record<DealStatus | "unallocated", { data: number; color: string }>, [key, value]) => ({
      ...acc,
      [key]: { data: value || 0, color: getColorByStatus(key as DealStatus | "unallocated") }
    }),
    {} as Record<DealStatus | "unallocated", { data: number; color: string }>
  )
});
const getColorByStatus = (status: DealStatus | "unallocated") => {
  switch (status) {
    case DealStatus.Lead:
      return "#a7b7d1";
    case DealStatus.Cancelled:
      return "#ed621c";
    case DealStatus.Dead:
      return "#383736";
    case DealStatus.Countered:
      return "#173de6";
    case DealStatus.CreditCheck:
      return "#40e3d3";
    case DealStatus.DealCreated:
      return "#82e0ad";
    case DealStatus.PendingLenderDecision:
      return "#aec973";
    case DealStatus.WaitingForDealDetails:
      return "#8642a8";
    case DealStatus.Denied:
      return "#b81c1a";
    case DealStatus.Approved:
      return "#f0d855";
    case DealStatus.Funded:
      return "#51bde3";
    case DealStatus.FundingHeld:
      return "#a54343";
    case DealStatus.SentToDealer:
      return "#ffa500";
    case DealStatus.SubmittedForFunding:
      return "#e0c0f0";
    case "unallocated":
      return "#52a543";
  }
};

export default function LenderChart({
  unallocated = false,
  data,
  chartType,
  selectedStatuses,
  comparedData,
  title,
  currencyFormat = false
}: Props) {
  const matches = useMediaQuery("(min-width:800px)");

  const sortedData = sortData(data, selectedStatuses, unallocated);
  const categories = Object.keys(sortedData);
  const items =
    chartType === "bar"
      ? [{ data: Object.values(sortedData).map((x) => parseFloat(x?.data?.toFixed(2))) }]
      : Object.values(sortedData).map((x) => parseFloat(x?.data?.toFixed(2)));

  return (
    <Paper
      style={
        matches
          ? { height: "255px" }
          : {
              display: "flex",
              width: "100%",
              height: "370px",
              overflowX: "scroll",
              overflowY: "auto",
              position: "relative"
            }
      }
      //className={styles.dashboardItem}
    >
      <div style={matches ? {} : { width: "100%" }}>
        <ReactApexChart
          options={{
            chart: {
              toolbar: {
                show: false
              }
            },
            legend: {
              formatter: (legendName, opts) => {
                const value =
                  chartType === "bar"
                    ? opts.w.config.series[0].data[opts.seriesIndex]
                    : opts.w.config.series[opts.seriesIndex];
                const category = opts.w.config.xaxis.categories[opts.seriesIndex];
                const comparedValue = comparedData?.[category as DealStatus];
                const diff = percentageDifference(value || 0, comparedValue || 0);
                const formattedValue = currencyFormat ? formatNumberAsCurrency(value, "$") : value;
                return `${category} - ${formattedValue}${
                  comparedValue ? `(${diff > 0 ? "+" : "-"}${diff.toFixed(2)}%)` : ""
                }`;
              },
              offsetY: matches ? 50 : 0,
              position: matches ? "right" : "bottom"
            },
            plotOptions: {
              bar: { barHeight: "100%", distributed: true, horizontal: false }
            },
            labels: categories,
            colors: Object.values(sortedData).map((x) => x.color),
            dataLabels: {
              dropShadow: { enabled: false },
              style: {
                colors: ["black"]
              },
              ...(currencyFormat
                ? {
                    formatter: (val, opts) => {
                      return (
                        formatNumberAsCurrency(opts.w.config.series[opts.seriesIndex], "$") ?? ""
                      );
                    }
                  }
                : {
                    formatter: (val, opts) => {
                      return typeof opts.w.config.series[opts.seriesIndex] === "number"
                        ? opts.w.config.series[opts.seriesIndex]
                        : "";
                    }
                  })
            },
            stroke: { width: 1, colors: ["#fff"] },
            xaxis: { categories },
            yaxis: {
              labels: {
                show: true,
                ...(currencyFormat
                  ? {
                      formatter: (val: any) => {
                        return formatNumberAsCurrency(val, "$") ?? "";
                      }
                    }
                  : {})
              }
            },
            title: {
              text: title,
              align: "center",
              style: { fontSize: matches ? "22px" : "16px", fontWeight: 600 }
            }
          }}
          series={items}
          type={chartType}
          height={matches ? 250 : 350}
        />
      </div>
    </Paper>
  );
}
