import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Tooltip
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import { RootState } from "app/rootReducer";
import CloseDialogButton from "components/common/CloseDialogButton";
import formEditContext from "components/Content/FormEditContext";
import { addDealerNote } from "components/DealerNotes/addDealerNoteSlice";
import { DealerNote } from "components/DealerNotes/types";
import TextLoop from "components/Loader/TextLoop";
import { addNote } from "components/Notes/addNoteSlice";
import { Note } from "components/Notes/types";
import { StipulationStatus } from "components/Stipulations/types";
import { User } from "components/Users/types";
import { useSnackbar } from "notistack";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import "treeflex/dist/css/treeflex.css";
import { ResolvedCondition } from "utils/dealStateMachine/stateMachine";
import { StateAccess } from "utils/models/formGenerator";
import { statusToChip } from "./Deals";
import { dealStateMachine } from "./DealsStateMachine";
import StatusHistoryButton from "./StatusHistoryButton";
import StatusReasonsButton from "./StatusReasonsButton";
import { Deal, DealStatus, ReasonsDead, ReasonsDidNotProcess } from "./types";

interface Props {
  stateAccess: StateAccess;
}
type MachineType = {
  can: (state: DealStatus) => ResolvedCondition;
  goto: <CT>(state: DealStatus, customParams?: CT | undefined) => Promise<Deal>;
  getPossibleStates: () => (DealStatus | undefined)[];
};
type StatusReasonsProps = {
  stateAccess: StateAccess;
  currentUser: User | undefined;
  closeFunction: () => void;
  stateMachine: MachineType;
  buttonRef: React.MutableRefObject<HTMLButtonElement | null>;
  dealStatus:
    | DealStatus.Dead
    | DealStatus.DidNotProcess
    | DealStatus.Approved
    | DealStatus.Countered
    | DealStatus.SentToDealer
    | null;
};

export const getReasonsByStatus = (
  status: DealStatus | null
): (ReasonsDead | ReasonsDidNotProcess)[] => {
  switch (status) {
    case DealStatus.Dead:
    case DealStatus.Approved:
    case DealStatus.Countered:
    case DealStatus.SentToDealer:
      return Object.values(ReasonsDead);
    case DealStatus.DidNotProcess:
      return Object.values(ReasonsDidNotProcess);
    default:
      return [];
  }
};
const StatusReasons = ({
  stateAccess,
  currentUser,
  closeFunction,
  stateMachine,
  buttonRef,
  dealStatus
}: StatusReasonsProps) => {
  const [reason, setReason] = React.useState<string | null>(null);
  return (
    <Dialog fullWidth onClose={closeFunction} open={true}>
      <Grid container alignItems="center" spacing={1} style={{ paddingRight: "4px" }}>
        <Grid item xs={6} key="title">
          <DialogTitle>
            Status Reasons
            <CloseDialogButton closeFunction={closeFunction} />
          </DialogTitle>
        </Grid>
      </Grid>
      <DialogContent>
        <Grid container xs={12}>
          <Grid item xs={12}>
            <Autocomplete
              style={{ zIndex: 6 }}
              value={reason || null}
              getOptionLabel={(reason) => reason ?? ""}
              getOptionSelected={(x, y) => x === y}
              options={getReasonsByStatus(dealStatus)}
              onChange={(event, newValue) => {
                setReason(newValue);
              }}
              openOnFocus
              id="reason-select"
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{ ...params.InputProps }}
                  label={"Status Reason"}
                  variant="filled"
                  size="small"
                />
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={!reason}
          autoFocus
          onClick={async () => {
            if (
              buttonRef &&
              buttonRef.current &&
              dealStatus &&
              reason &&
              stateMachine.can(dealStatus).can
            ) {
              const newState = await stateMachine.goto(dealStatus);
              stateAccess.set<Deal>([], {
                ...newState,
                data: {
                  ...newState.data,
                  info: {
                    ...newState.data.info,
                    statusReasons: {
                      ...(newState.data.info?.statusReasons ?? {}),
                      manager: {
                        userId: currentUser?._id as string,
                        status: dealStatus,
                        date: new Date().toISOString(),
                        marked: true,
                        reason
                      }
                    }
                  }
                }
              } as Deal);
              buttonRef.current.click();
            }
            closeFunction();
            setReason(null);
          }}
          color="primary"
          variant="contained"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const DealStatuses = ({ stateAccess }: Props) => {
  const dispatch = useDispatch();
  const deal: Deal = stateAccess.get<Deal>([]);
  const machine = dealStateMachine(deal);
  const [statusChanged, setStatusChanged] = React.useState(false);
  const [fundingHeldDialogOpen, setFundingHeldDialogOpen] = React.useState(false);
  const [reasonsDialogOpen, setReasonsDialogOpen] = React.useState(false);
  const [newStatus, setNewStatus] = React.useState<DealStatus | null>(null);
  const [fundingHeldNote, setFundingHeldNote] = React.useState("");
  const [fundingHeldNoteDealer, setFundingHeldNoteDealer] = React.useState("");

  const currentUser = useSelector((state: RootState) => state?.authSlice?.user?.databaseData);
  const { loaderSlice } = useSelector((state: RootState) => state);
  const buttonRef = React.useRef<HTMLButtonElement | null>(null);
  const { enabled: editMode } = React.useContext(formEditContext);
  React.useEffect(() => {
    if (!loaderSlice.waiting) setStatusChanged(false);
  }, [loaderSlice.waiting]);

  const handleAddNotes = async () => {
    if (buttonRef && buttonRef.current) {
      const newState = await machine.goto(DealStatus.FundingHeld);

      const createNote = (noteContent: string) => ({
        data: {
          dealId: newState._id,
          info: {
            type: "funding_held",
            note: noteContent,
            user: {
              firstName: currentUser?.data?.info?.firstName || null,
              lastName: currentUser?.data?.info?.lastName || null,
              email: currentUser?.data?.info?.email || null
            }
          }
        }
      });

      const dealerNote = createNote(fundingHeldNoteDealer);
      const note = createNote(fundingHeldNote);

      stateAccess.set<Deal>([], newState);

      dispatch(addNote({ requestId: "add-note", ...(note as Note) }));
      //"add-note" requestId is required so that changes are monitored and updated in NotesPreview
      dispatch(addDealerNote({ requestId: "add-dealer-note", ...(dealerNote as DealerNote) }));

      buttonRef.current.click();
    }
    setFundingHeldDialogOpen(false);
    setFundingHeldNote("");
    setFundingHeldNoteDealer("");
  };

  return (
    <>
      {reasonsDialogOpen &&
        (newStatus === DealStatus.Dead ||
          newStatus === DealStatus.DidNotProcess ||
          newStatus === DealStatus.Approved ||
          newStatus === DealStatus.Countered ||
          newStatus === DealStatus.SentToDealer) && (
          <StatusReasons
            stateAccess={stateAccess}
            currentUser={currentUser}
            buttonRef={buttonRef}
            dealStatus={newStatus}
            closeFunction={() => setReasonsDialogOpen(false)}
            stateMachine={machine as MachineType}
          />
        )}
      {fundingHeldDialogOpen ? (
        <Dialog fullWidth onClose={() => setFundingHeldDialogOpen(false)} open={true}>
          <Grid container alignItems="center" spacing={1} style={{ paddingRight: "4px" }}>
            <Grid item xs={6} key="title">
              <DialogTitle>
                Funding held note
                <CloseDialogButton closeFunction={() => setFundingHeldDialogOpen(false)} />
              </DialogTitle>
            </Grid>
          </Grid>
          <DialogContent>
            <Grid container xs={12}>
              <Grid item xs={12}>
                <TextField
                  label="Funding held note(Internal)"
                  size="small"
                  variant="filled"
                  required={true}
                  margin="dense"
                  multiline
                  maxRows={10}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  name="fundingHeldNote"
                  value={fundingHeldNote}
                  type="text"
                  onChange={(e) => {
                    setFundingHeldNote(e.target.value);
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  label="Funding held note(For Dealer)"
                  size="small"
                  variant="filled"
                  margin="dense"
                  multiline
                  maxRows={10}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  name="fundingHeldNoteDealer"
                  value={fundingHeldNoteDealer}
                  type="text"
                  onChange={(e) => {
                    setFundingHeldNoteDealer(e.target.value);
                  }}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              disabled={fundingHeldNote.length === 0}
              autoFocus
              onClick={handleAddNotes}
              color="primary"
              variant="contained"
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}
      {loaderSlice.waiting && statusChanged ? (
        <TextLoop text="Changing status..." />
      ) : (
        <StatusTree
          buttonRef={buttonRef}
          activeStatus={deal.data.info.status}
          setFundingHeldDialogOpen={setFundingHeldDialogOpen}
          setReasonsDialogOpen={setReasonsDialogOpen}
          stateAccess={stateAccess}
          setNewStatus={setNewStatus}
          setStatusChanged={setStatusChanged}
          editMode={editMode}
        />
      )}
    </>
  );
};
const StatusTree = ({
  activeStatus,
  setStatusChanged,
  setNewStatus,
  setFundingHeldDialogOpen,
  setReasonsDialogOpen,
  stateAccess,
  editMode,
  buttonRef
}: {
  activeStatus: DealStatus;
  setFundingHeldDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setReasonsDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setStatusChanged: React.Dispatch<React.SetStateAction<boolean>>;
  setNewStatus: React.Dispatch<React.SetStateAction<DealStatus | null>>;
  stateAccess: StateAccess;
  editMode: boolean | undefined;
  buttonRef: React.MutableRefObject<HTMLButtonElement | null>;
}) => {
  const deal = stateAccess.get<Deal>([]);
  const machine = dealStateMachine(deal);
  const availableStatuses = machine.getPossibleStates();
  const { enqueueSnackbar } = useSnackbar();
  const listStipulationsState = useSelector(
    (state: RootState) => state.listStipulationSlice[stateAccess.get(["_id"])]
  );
  const handleChipClick = (state: DealStatus) => async (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    {
      setNewStatus(state);
      const resolvedCan = machine.can(state);
      if (resolvedCan.can) {
        if ([DealStatus.Dead, DealStatus.DidNotProcess].includes(state)) {
          setReasonsDialogOpen(true);
        } else {
          if (state === DealStatus.FundingHeld) {
            setFundingHeldDialogOpen(true);
          }
          if (buttonRef && buttonRef.current && state !== DealStatus.FundingHeld) {
            stateAccess.set<Deal>([], await machine.goto(state));
            buttonRef.current.click();
          }
        }
        setStatusChanged(true);
      } else {
        enqueueSnackbar(
          <span
            dangerouslySetInnerHTML={{
              __html: resolvedCan.reasons.join("<br/>")
            }}
          />,
          {
            variant: "error"
          }
        );
      }
    }
  };
  return (
    <div style={{ position: "relative", display: "grid", margin: "0 -6px", height: "150px" }}>
      {[
        DealStatus.Dead,
        DealStatus.DidNotProcess,
        DealStatus.Countered,
        DealStatus.Approved,
        DealStatus.SentToDealer
      ].includes(activeStatus) && <StatusReasonsButton stateAccess={stateAccess} />}
      <StatusHistoryButton stateAccess={stateAccess} />
      <button type="submit" style={{ display: "none" }} ref={buttonRef}>
        HiddenButton
      </button>
      <Box
        component="span"
        fontSize="10px"
        style={{
          position: "absolute",
          left: "0px",
          right: "0px",
          top: "0px",
          height: "55px",
          width: "calc(100% - 20px)",
          textAlign: "left",
          paddingLeft: "5px",
          paddingTop: "2px",
          fontSize: "12px",
          border: "0.5px dashed green",
          margin: "auto",
          borderRadius: "6px",
          backgroundColor: "#0080001a"
        }}
      >
        Current status
      </Box>
      <Box
        component="span"
        fontSize="10px"
        style={{
          position: "absolute",
          left: "0px",
          top: "70px",
          height: "80px",
          width: "calc(100% - 20px)",
          textAlign: "left",
          paddingLeft: "5px",
          right: "0",
          paddingTop: "2px",
          fontSize: "12px",
          border: "1px dashed blue",
          margin: "auto",
          borderRadius: "6px",
          backgroundColor: "#007eff24"
        }}
      >
        Available statuses
      </Box>
      {availableStatuses?.length === 0 ? (
        <Box
          component="span"
          fontSize="10px"
          style={{
            position: "absolute",
            left: "0px",
            top: "100px",
            height: "80px",
            width: "calc(100% - 20px)",
            textAlign: "center",
            right: "0",
            paddingTop: "2px",
            fontSize: "16px",
            margin: "auto",
            fontWeight: "bold",
            color: "#494949"
          }}
        >
          There are no available statuses!
        </Box>
      ) : null}
      <div
        className="tf-tree  tf-custom"
        style={{ fontSize: "10px", marginTop: "25px", textAlign: "center" }}
      >
        <ul>
          <li>
            <span className="tf-nc active-status">{statusToChip(activeStatus, false)}</span>
            {availableStatuses && availableStatuses?.length > 0 ? (
              <ul>
                {availableStatuses?.map((state, index) => {
                  if (!state) return <></>;
                  const resolvedCan = machine.can(state);
                  if (state === DealStatus.SubmittedForFunding) {
                    const allStipulationsAreGood = listStipulationsState?.entities
                      ?.filter((s) => s.deleted === false)
                      ?.every(
                        (stipulation) => stipulation.data.info.status === StipulationStatus.APPROVED
                      );
                    if (!allStipulationsAreGood) {
                      resolvedCan.can = false;
                      resolvedCan.reasons.push(
                        "All stipulations must be verified before submitting for funding. You can't submit for funding until all stipulations are fixed. If you received the document and it is not in the system, please delete the stipulaton or upload the document to the system."
                      );
                    }
                  }
                  return (
                    <li key={`${index}`}>
                      <span className="tf-nc">
                        {state ? (
                          resolvedCan.can ? (
                            statusToChip(state, !editMode, handleChipClick(state))
                          ) : (
                            <Tooltip
                              title={
                                <ul style={{ fontSize: "14px", lineHeight: "16px" }}>
                                  {resolvedCan.reasons.map((x, index) => (
                                    <li key={index}>{x}</li>
                                  ))}
                                </ul>
                              }
                            >
                              <div>{statusToChip(state, true)}</div>
                            </Tooltip>
                          )
                        ) : null}
                      </span>
                    </li>
                  );
                })}
              </ul>
            ) : null}
          </li>
        </ul>
      </div>
    </div>
  );
};
export default DealStatuses;
