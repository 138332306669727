import { Schema } from "ajv";
import info_properties from "./info_properties";

const data: Schema = {
  type: "object",
  properties: {
    info: { type: "object", nullable: true, properties: info_properties },
    dealId: {
      type: "string",
      nullable: true
    }
  }
};
export default {
  new_deal_prediction: {
    $id: "newDealPrediction",
    type: "object",
    properties: {
      data: data,
      availableToRolesIds: {
        type: "array",
        nullable: true,
        items: {
          type: "string"
        }
      }
    }
  },
  update_deal_prediction: {
    $id: "updateDealPrediction",
    type: "object",
    properties: {
      _id: { type: "string" },
      data,
      availableToRolesIds: {
        type: "array",
        nullable: true,
        items: {
          type: "string"
        }
      },
      approved: {
        type: "boolean",
        nullable: true
      },
      approvedAt: {
        type: "string",
        nullable: true
      },
      approvedByUserId: {
        type: "string",
        nullable: true
      }
    }
  }
};
