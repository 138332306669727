import EqualizerIcon from "@material-ui/icons/Equalizer";
import ViewCompactIcon from "@material-ui/icons/ViewCompact";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import React from "react";

export type View = "form" | "statistics" | null;
export default function DealershipView({
  view,
  setView
}: {
  setView: React.Dispatch<React.SetStateAction<View>>;
  view: View;
}) {
  const handleChange = (event: React.MouseEvent<HTMLElement>, value: View) => {
    setView(value);
  };

  return (
    <ToggleButtonGroup size="small" value={view} exclusive color="primary" onChange={handleChange}>
      <ToggleButton value="form">
        <ViewCompactIcon color={view === "form" ? "primary" : "inherit"} fontSize="small" />
      </ToggleButton>
      <ToggleButton value="statistics">
        <EqualizerIcon color={view === "statistics" ? "primary" : "inherit"} fontSize="small" />
      </ToggleButton>
    </ToggleButtonGroup>
  );
}
