import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grid,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
  useMediaQuery
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { RootState } from "app/rootReducer";
import { DashboardType } from "components/Users/types";
import { useStickyState } from "index";
import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { assertNever, typedObjectEntries } from "utils/functions";
import AccountantDashboard from "./Accountant/AccountantDashboard";
import CustomDashboard from "./CustomDashboard";
import FIManagerDashboard from "./FIManager/FIManagerDashboard";
import LenderAllocationDashboard from "./Lender/AllocationDashboard/LenderAllocationDashboard";
import LenderDashbord from "./Lender/LenderDashboard/LenderDashboard";
import LenderEstimationDashboard from "./Lender/LenderEstimationDashboard/LenderEstimationDashboard";
import SalesRepresentativeDashboard from "./SalesRepresentative/SalesRepresentativeDashboard";
import TitleIssuesDashboard from "./TitleIssues/TitleIssuesDashboard";
import LenderCreditStatisticsTable from "components/LenderDecisions/LenderDecisionsStatistics/LenderDecisionsStatistics";
import useGetDealershipActivity from "hooks/useDealershipActivity/useDealershipActivity";
import WarningIcon from "@material-ui/icons/Warning";
import { HintTooltip } from "components/common/HintTooltip";
import { TabContext } from "components/Layout/LayoutWrapper";
import RepresentativeDashboard from "./RepresentativeDashboard/RepresentativeDashboard";
import LenderCoverageMapHOC from "components/Reports/LenderCoverageMapReport/LenderCoverageMap";
import DealsMapDashboard from "./DealsMapDashboard/DealsMapDashboard";

function renderDashboard(
  dashboardType: DashboardType | null,
  setResetStickyCallback: React.Dispatch<React.SetStateAction<(() => void) | undefined>>
) {
  switch (dashboardType) {
    case "accountant":
      return <AccountantDashboard setResetStickyCallback={setResetStickyCallback} />;
    case "f&i":
      return <FIManagerDashboard setResetStickyCallback={setResetStickyCallback} />;
    case "salesRep":
      return <SalesRepresentativeDashboard setResetStickyCallback={setResetStickyCallback} />;
    case "allocation":
      return <LenderAllocationDashboard setResetStickyCallback={setResetStickyCallback} />;
    case "lender":
      return <LenderDashbord setResetStickyCallback={setResetStickyCallback} />;
    case "lenderEstimation":
      return <LenderEstimationDashboard setResetStickyCallback={setResetStickyCallback} />;
    case "representative":
      return <RepresentativeDashboard />;
    case "titleIssues":
      return <TitleIssuesDashboard setResetStickyCallback={setResetStickyCallback} />;
    case "custom":
      return <CustomDashboard />;
    case "coverageStatistics":
      return (
        <LenderCreditStatisticsTable
          hideTitle={true}
          setResetStickyCallback={setResetStickyCallback}
        />
      );
    case "coverageMap":
      return <LenderCoverageMapHOC />;
    case "dealsMap":
      return <DealsMapDashboard />;
    case null:
      return <></>;
    default:
      return assertNever(dashboardType);
  }
}
const getDashboardTitle = (dashboardType: DashboardType | null) => {
  switch (dashboardType) {
    case "allocation":
      return "Lender Allocation Dashboard";
    case "lender":
      return "Lender Dashboard (deals where lender was selected)";
    case "lenderEstimation":
      return "Sales Funnel Estimation Dashboard";
    case "f&i":
      return "F&I Manager Dashboard";
    case "accountant":
      return "Accountant Dashboard";
    case "salesRep":
      return "Sales Representative Dashboard";
    case "custom":
      return "Custom Dashboard";
    case "titleIssues":
      return "Title Information Dashboard";
    case "coverageStatistics":
      return "Lender Coverage Statistics";
    case "representative":
      return "Representative";
    case "coverageMap":
      return "Lender Coverage Map Dashboard";
    case "dealsMap":
      return "Deals Map Dashboard";
    case null:
    case undefined:
      return "";
    default:
      return assertNever(dashboardType);
  }
};

export function DashboardSwitcher({
  dealershipActivityWarning,
  options,
  dashboard,
  setDashboard
}: {
  dealershipActivityWarning: boolean;
  options: DashboardType[];
  dashboard: DashboardType | null;
  setDashboard: React.Dispatch<React.SetStateAction<DashboardType | null>>;
}) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = React.useState(
    options.findIndex((x) => x === dashboard)
  );
  const createOrFocusTab = useContext(TabContext);

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number
  ) => {
    setSelectedIndex(index);
    setDashboard(options[index]);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  const handleOpenDealerActivityWarning = () =>
    createOrFocusTab({
      label: "dealer_activity_warning",
      index: "dealer_activity_warning",
      isForSidebar: false,
      isForQuickAccess: false,
      isPersistent: false
    });

  return (
    <Grid container direction="column" alignItems="center">
      <Grid item xs={12}>
        <ButtonGroup
          style={{ whiteSpace: "nowrap" }}
          variant="contained"
          color="primary"
          ref={anchorRef}
          aria-label="split button"
        >
          <Button>{getDashboardTitle(options[selectedIndex])}</Button>
          {dealershipActivityWarning && (
            <Button onClick={handleOpenDealerActivityWarning}>
              <HintTooltip title="There are dealerships with activity warning!">
                <WarningIcon style={{ color: "#f20a0a" }} />
              </HintTooltip>
            </Button>
          )}
          <Button
            color="primary"
            size="small"
            aria-controls={open ? "split-button-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-label="select merge strategy"
            aria-haspopup="menu"
            onClick={handleToggle}
          >
            <ArrowDropDownIcon />
          </Button>
        </ButtonGroup>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
          placement="bottom-end"
          style={{ zIndex: 999 }}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === "bottom" ? "center top" : "center bottom"
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu">
                    {options.map((option, index) => (
                      <MenuItem
                        key={option}
                        disabled={index === selectedIndex}
                        selected={index === selectedIndex}
                        onClick={(event) => handleMenuItemClick(event, index)}
                      >
                        {getDashboardTitle(option)}
                        {dealershipActivityWarning && option === "f&i" && (
                          <HintTooltip
                            title="There are dealerships with activity warning!"
                            onClick={handleOpenDealerActivityWarning}
                          >
                            <WarningIcon style={{ color: "#f20a0a" }} />
                          </HintTooltip>
                        )}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Grid>
    </Grid>
  );
}

export default function Dashboard() {
  const matches = useMediaQuery("(min-width:800px)");
  const [resetStickyCallback, setResetStickyCallback] = React.useState<() => void>();

  const user = useSelector((state: RootState) => state?.authSlice?.user?.databaseData);
  const isUserAdministartor = user?.data?.roles?.find(
    (role) => role.data.info.name === "Administrator"
  );
  const { data: dealershipActivityData } = useGetDealershipActivity(
    !isUserAdministartor ? { customerCareAssigneeId: user?._id } : {},
    user?._id !== undefined
  );
  const allAvailableDashboards = typedObjectEntries(user?.data?.info?.dashboardTypes ?? {})
    ?.filter(([name, selected]) => selected)
    ?.map(([name]) => name);
  const defaultDashboard = allAvailableDashboards.at(-1) || null;
  const [dashboard, setDashboard] = useStickyState(defaultDashboard, "dashboardType");
  const dashboards = typedObjectEntries(user?.data?.info?.dashboardTypes ?? {})
    .filter(([, value]) => value)
    .map(([key]) => key);

  const dashboardToLoad =
    dashboard && allAvailableDashboards.includes(dashboard) ? dashboard : defaultDashboard;

  return (
    <>
      <Grid container style={{ display: "flex" }}>
        <Grid>
          <Typography style={{ fontSize: 20, fontWeight: 500, margin: 5 }}>
            {getDashboardTitle(dashboardToLoad)}
          </Typography>
        </Grid>
        <Grid
          style={
            !matches
              ? {}
              : {
                  marginLeft: "auto",
                  display: "flex",
                  justifyContent: "center",
                  gap: "10px",
                  alignItems: "center"
                }
          }
        >
          <Button
            onClick={() => {
              resetStickyCallback?.();
            }}
            style={{ whiteSpace: "nowrap", width: "100%" }}
            color="primary"
            variant="contained"
          >
            Reset state
          </Button>
          {dashboards?.length > 1 ? (
            <DashboardSwitcher
              dealershipActivityWarning={(dealershipActivityData?.dealershipIds?.length || 0) > 0}
              options={dashboards}
              dashboard={dashboard}
              setDashboard={setDashboard}
            />
          ) : null}
        </Grid>
      </Grid>
      {renderDashboard(dashboardToLoad, setResetStickyCallback)}
    </>
  );
}
