import {
  permissionNode,
  relationPermissionNode,
  PermissionStruct,
  multyPermissionNode
} from "components/Roles/types";
import { DealPrediction } from "./types";

export type DealPredictionPermissionStruct = PermissionStruct<DealPrediction>;

export const dealPredictionPermissionsStruct: DealPredictionPermissionStruct = {
  _id: permissionNode("Id"),
  createdAt: permissionNode("Created at date"),
  updatedAt: permissionNode("Updated at date"),
  deleted: permissionNode("Is deleted"),
  creatorId: permissionNode("Creator Id"),
  approved: permissionNode("Approved"),
  approvedAt: permissionNode("approvedAt"),
  approvedByUserId: permissionNode("approvedByUserId"),
  approvedByUser: relationPermissionNode("Approved by", "wfd_user"),
  availableToRoles: permissionNode("Available to roles"),
  availableToRolesIds: permissionNode("Available to roles Ids"),
  data: {
    dealId: permissionNode("Data"),
    info: {
      prediction: {
        decision: {
          label: permissionNode("Decision Label"),
          probability: permissionNode("Decision Probability")
        },
        financial_info: {
          amountFinanced: permissionNode("Amount Financed"),
          DTI: permissionNode("DTI"),
          LTV: permissionNode("LTV"),
          NADA: permissionNode("NADA")
        },
        vehicle: {
          make: permissionNode("Vehicle Make"),
          model: permissionNode("Vehicle Model"),
          year: permissionNode("Vehicle Year")
        },
        isCorrect: permissionNode("Is Correct"),
        predictionFailed: permissionNode("Prediction Failed")
      },
      //   @ts-ignore
      lenderPrediction: {}
    }
  }
};
