import React, { useState, useContext } from "react";

import { Button, Grid } from "@material-ui/core";
import { StateAccess } from "utils/models/formGenerator";
import DocumentsToBeReturnedModal from "./DocumentsToBeReturnedModal";
import formEditContext from "../../Content/FormEditContext";

import BorderColorIcon from "@material-ui/icons/BorderColor";

export default function ({ stateAccess }: { stateAccess: StateAccess }) {
  const [open, setOpen] = useState(false);
  const { enabled: editMode } = useContext(formEditContext);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            id="documents-to-be-returned-modal-button"
            startIcon={<BorderColorIcon />}
            disabled={!editMode}
            disableElevation
            onClick={handleClickOpen}
          >
            Funding documents
          </Button>
        </Grid>
      </Grid>
      {open && <DocumentsToBeReturnedModal stateAccess={stateAccess} handleClose={handleClose} />}
    </>
  );
}
