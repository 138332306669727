import { Box, IconButton } from "@material-ui/core";
import { Save } from "@material-ui/icons";
import { HintTooltip } from "components/common/HintTooltip";
import * as ExcelJS from "exceljs";
import * as FileSaver from "file-saver";
import * as React from "react";
import formatDate from "utils/formatDate";
import { DealsFeaturesProps } from "./types";
import { Deal } from "components/Deals/types";
import { capitalize } from "utils/functions";

export interface Props {
  deals: Deal[];
}

const createExcelWorkbook = (
  sheetTitle: string,
  entries: Omit<DealsFeaturesProps, "statusHistory" | "statusGroup">[],
  columns: string[]
): ExcelJS.Workbook => {
  const workbook = new ExcelJS.Workbook();
  const now = new Date();
  const wfd = "WFD";
  workbook.created = now;
  workbook.modified = now;
  workbook.lastPrinted = now;
  workbook.lastModifiedBy = wfd;
  workbook.creator = wfd;

  const sheet = workbook.addWorksheet(sheetTitle);
  sheet.addRow(columns);
  sheet.eachRow((row, rowNumber) => {
    row.eachCell((cell) => {
      cell.font = { bold: true, size: 12 };
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "C7C7C7" }
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" }
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center"
      };
    });
  });
  sheet.addRows(
    entries.map((entry) => {
      let value: string | number | Date | undefined;
      return [
        ...columns.map((column) => {
          switch (column) {
            case "Status":
              value = capitalize(entry.status);
              break;
            case "Applicant":
              value = entry.applicantNames;
              break;
            case "Dealership":
              value = entry.dealershipName;
              break;
            case "Lender":
              value = entry.lenderName;
              break;
            case "Amount Financed":
              value = entry.amountFinanced;
              break;
            case "Vehicle Year":
              value = entry?.vehicle?.year;
              break;
            case "Vehicle Make":
              value = entry?.vehicle?.make;
              break;
            case "Vehicle Model":
              value = entry?.vehicle?.model;
              break;
          }
          if (value instanceof Date) {
            value = formatDate(value, "short");
          }
          if (typeof value === "number") {
            value = parseFloat(value.toFixed(2));
          }
          return value;
        })
      ].flat();
    })
  );
  sheet.columns.forEach((column) => {
    const lengths = column.values.map((v) => v?.toString()?.length || 0);
    const maxLength = Math.max(...lengths.filter((v) => typeof v === "number"));
    column.width = maxLength;
  });
  return workbook;
};

const saveExcelBuffer = async (
  entries: Omit<DealsFeaturesProps, "statusHistory" | "statusGroup">[],
  columns: string[]
): Promise<void> => {
  const title = "Deals Map Dashboard Export";
  const workbook = createExcelWorkbook(title, entries, columns);
  const date = new Date().toISOString();
  const filename = `${title}_export_${date}.xlsx`;
  const buffer = await workbook.xlsx.writeBuffer({ filename });
  const file = new File([buffer], filename, { type: "application/vnd.ms-excel" });
  FileSaver.saveAs(file);
};

export default ({ deals }: Props): JSX.Element => {
  const columns = [
    "Status",
    "Applicant",
    "Dealership",
    "Lender",
    "Amount Financed",
    "Vehicle Make",
    "Vehicle Model",
    "Vehicle Year"
  ];
  const entries = deals.map((deal) => ({
    status: deal.data.info.status,
    applicantNames: `${deal?.data?.applicant?.data?.info?.firstName} ${deal?.data?.applicant?.data?.info?.lastName}`,
    dealershipName: deal?.data?.dealership?.data?.info?.displayName,
    lenderName: deal?.data?.lender?.data?.info?.name,
    amountFinanced: deal?.data?.info?.payment?.dealTotal || 0,
    vehicle: {
      year: deal?.data?.info?.vehicle?.year,
      make: deal?.data?.info?.vehicle?.make,
      model: deal?.data?.info?.vehicle?.model
    }
  }));
  return (
    <Box component="span">
      <HintTooltip title="Export deal data to excel file.">
        <IconButton
          id={`maps-dashboard-export-deals`}
          onClick={async (): Promise<void> => {
            await saveExcelBuffer(entries, columns);
          }}
        >
          <Save />
        </IconButton>
      </HintTooltip>
    </Box>
  );
};
