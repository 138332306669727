import { RootState } from "app/rootReducer";
import OtherVendorFilter from "components/Filters/OtherVendorFilter";
import TextFilter from "components/Filters/TextFilter";
import React from "react";
import { useSelector } from "react-redux";
import AccessControl from "../Access/AccessControl";
import Table, { CellValue, Column } from "../Table";
import { deleteOtherVendor } from "./deleteOtherVendorsSlice";
import { getOtherVendorList } from "./listOtheVendorsSlice";
import { recoverOtherVendor } from "./recoverOtherVendoSlicer";
import { FormState } from "./types";
export const projections = {
  "data.info.name": 1,
  "data.info.email": 1,
  creatorId: 1,
  _id: 1,
  createdAt: 1,
  updatedAt: 1
};
export default function Vendors(): JSX.Element {
  const vendorList = useSelector((state: RootState) => state.listOtherVendorSlice["table"]);

  const columns: Column<FormState>[] = [
    {
      getData: (entry): CellValue => entry.data.info.name,
      label: "Vendor",
      name: "vendor",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.vendor?.show;
      },
      filters: [
        {
          path: ["_id"],
          preview: OtherVendorFilter,
          name: "name",
          label: "Name"
        }
      ]
    },
    {
      getData: (entry): CellValue => entry.data.info.email ?? "—",
      label: "Email",
      name: "email",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.email?.show;
      },
      filters: [
        {
          path: ["data", "info", "email"],
          preview: TextFilter,
          name: "email",
          label: "Email",
          caseInsensitive: true,
          partialSearch: true
        }
      ]
    },
    {
      label: "Actions",
      getData: (entry): CellValue => entry._id,
      name: "actions",
      show: (userPermissions, tableSettings) => {
        return tableSettings?.data?.columns?.actions?.show;
      }
    }
  ];

  return (
    <div style={{ position: "relative" }}>
      <AccessControl requiredPermissions={{ entity: "other_vendor", action: "read" }}>
        <Table
          slice="table"
          tableName="other_vendors"
          entityName="other_vendor"
          listFunction={getOtherVendorList}
          listEntity={vendorList}
          deleteEntityFunction={deleteOtherVendor}
          recoverEntityFunction={recoverOtherVendor}
          title={"Vendors"}
          columns={columns}
        />
      </AccessControl>
    </div>
  );
}
