import { Button, Typography } from "@material-ui/core";
import LinkIcon from "@material-ui/icons/Link";
import { RootState } from "app/rootReducer";
import formEditContext from "components/Content/FormEditContext";
import { useSnackbar } from "notistack";
import React, { useContext, useEffect, useState } from "react";
import { PiSealWarningDuotone } from "react-icons/pi";
import { useDispatch, useSelector } from "react-redux";
import { StateAccess } from "utils/models/formGenerator";
import { v4 as uuidv4 } from "uuid";
import { DealershipSendGeneratedLink } from "./sendGeneratedLinkSlice";
import { Dealership } from "./types";

const SendGeneratedLink = ({ stateAccess }: { stateAccess: StateAccess }) => {
  const dispatch = useDispatch();
  const dealership: Dealership = stateAccess.get<Dealership>([]);
  const [requestId] = useState(uuidv4());
  const { enqueueSnackbar } = useSnackbar();
  const { enabled: editMode, edited } = useContext(formEditContext);
  const handleSendGeneratedLink = () => {
    if (!dealership?.data?.info?.adminRegistrationLink) {
      return alert("Please generate the link before sending it");
    }
    if (edited) {
      return alert("Please save the dealership first.");
    }

    dispatch(DealershipSendGeneratedLink({ requestId, _id: dealership._id }));
  };

  const sendGeneratedLinkSlice = useSelector(
    (state: RootState) => state.sendGeneratedLinkSlice[requestId]
  );

  useEffect(() => {
    if (sendGeneratedLinkSlice?.status === "success") {
      enqueueSnackbar(sendGeneratedLinkSlice?.data?.message, { variant: "success" });
    } else if (sendGeneratedLinkSlice?.status === "error") {
      enqueueSnackbar(sendGeneratedLinkSlice?.message, { variant: "error" });
    }
  }, [sendGeneratedLinkSlice]);

  return editMode ? (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: "5px",
        border: "1px dashed black",
        borderRadius: "3px",
        padding: "10px"
      }}
    >
      <div style={{ flex: 1 }}>
        {editMode && (
          <Button
            onClick={handleSendGeneratedLink}
            variant="contained"
            component="label"
            color="primary"
            startIcon={<LinkIcon />}
          >
            Send email for admin registration
          </Button>
        )}
      </div>
      <div style={{ flex: 1 }}>
        <Typography style={{ fontWeight: 500 }} variant="subtitle1">
          Sends the generated link for registration for the dealership platform to the address
          specified in the <strong>&#39;Admin email&#39;</strong> field above.
        </Typography>
      </div>
      <div>
        <PiSealWarningDuotone size={50} color="#dec83c" />
      </div>
    </div>
  ) : (
    <></>
  );
};

export default SendGeneratedLink;
