import { createSocketMessageSlice } from "../Middlewares/socketMessageSliceCreator";

interface DeleteDefaultStipulationRequest {
  _id: string;
}

export interface DeleteDefaultStipulationResponse {
  message: string;
}

const { socketMessageSlice, socketAction } = createSocketMessageSlice<
  DeleteDefaultStipulationRequest,
  DeleteDefaultStipulationResponse
>("DeleteDefaultStipulation", {
  request: "delete_default_stipulation"
});

export const deleteDefaultStipulationActions = socketMessageSlice.actions;
export const deleteDefaultStipulation = socketAction;
export default socketMessageSlice.reducer;
