import { NetsuiteDealStatus, NetsuiteVendorBillId } from "./types";
import { FormComponent, Model } from "utils/models/fields";

const netsuiteVendorBill: FormComponent<NetsuiteVendorBillId> = {
  formComponent: "segment",
  entities: [
    {
      formComponent: "radio-field",
      name: "type",
      label: "Type",
      width: "1/2",
      path: ["type"],
      possibleValues: [
        { label: "Gap", value: "gap" },
        { label: "Service warranty", value: "service warranty" },
        { label: "Dealer", value: "dealer" }
      ],
      default: "gap"
    },
    {
      formComponent: "text-field",
      name: "vendorBillId",
      label: "Vendor Bill Id",
      width: "1/2",
      path: ["vendorBillId"],
      required: true
    }
  ]
};
export const netsuiteDealStatusStruct: Model<NetsuiteDealStatus> = {
  formComponent: "model",
  schema: "new_netsuite_deal_status",
  name: "netsuite_deal_status",
  entities: [
    {
      formComponent: "segment",
      width: "1/2",
      entities: [
        {
          formComponent: "text-field",
          name: "netsuiteEstimateId",
          label: "Netsuite Estimate Id",
          width: "1/2",
          path: ["data", "info", "netsuiteEstimateId"],
          default: ""
        },
        {
          formComponent: "text-field",
          name: "netsuiteSalesOrderId",
          label: "Netsuite Sales Order Id",
          width: "1/2",
          path: ["data", "info", "netsuiteSalesOrderId"],
          default: ""
        },
        {
          formComponent: "text-field",
          name: "netsuiteInvoiceId",
          label: "Netsuite Invoice Id",
          width: "1/2",
          path: ["data", "info", "netsuiteInvoiceId"],
          default: ""
        },
        {
          formComponent: "list-model",
          name: "Netsuite Vendor Bill Ids",
          entity: netsuiteVendorBill,
          width: "full",
          path: ["data", "info", "netsuiteVendorBillIds"]
        }
      ]
    }
  ]
};
