import React, { useEffect, useState } from "react";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  TextField,
  Tooltip,
  Typography
} from "@material-ui/core";
import { History, Warning } from "@material-ui/icons";
import CreateIcon from "@material-ui/icons/Create";
import EmailIcon from "@material-ui/icons/Email";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import { RootState } from "app/rootReducer";
import { hideLoader, showLoader } from "components/Loader/loaderSlice";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import formatDate from "utils/formatDate";
import CloseDialogButton from "components/common/CloseDialogButton";
import { capitalize } from "utils/functions";
import { v4 as uuidv4 } from "uuid";
import { getChecklistList, removeChecklistList } from "../Checklist/listChecklistSlice";
import IFrameURLModal from "../Documents/IFrameURLModal";
import { Deal } from "../types";
import { editOneSpanSigning } from "./editOneSpanSigningSlice";
import { resendEsignEmail } from "./resendEmailSlice";
import { OneSpanSigning, OneSpanStatus } from "./types";

export const oneSpanStatus = (status: OneSpanStatus | undefined) =>
  status === OneSpanStatus.notSigned
    ? "Not viewed"
    : status === OneSpanStatus.partiallySigned
    ? "Partially signed"
    : status === OneSpanStatus.signed
    ? "Signed"
    : status === OneSpanStatus.viewed
    ? "Viewed"
    : "";

export const oneSpanStatusToStyle = (status: OneSpanStatus | undefined): React.CSSProperties => {
  switch (status) {
    case OneSpanStatus.notSigned:
      return {
        color: "#f50057"
      };
    case OneSpanStatus.partiallySigned:
      return {
        color: "#777777"
      };
    case OneSpanStatus.viewed:
      return {
        color: "#eed202"
      };
    case OneSpanStatus.signed:
      return {
        color: "#50a538"
      };
    default:
      return {};
  }
};
const getNamesAndEmail = (type: "applicant" | "dealership" | "coApplicant", deal: Deal) => {
  switch (type) {
    case "applicant":
      return {
        names:
          deal.data?.applicant?.data?.info?.firstName +
          " " +
          deal.data?.applicant?.data?.info?.lastName,
        email: deal?.data?.applicant?.data?.info?.email
      };
    case "coApplicant":
      return {
        names:
          deal.data?.coApplicant?.data?.info?.firstName +
          " " +
          deal.data?.coApplicant?.data?.info?.lastName,
        email: deal?.data?.coApplicant?.data?.info?.email
      };
    case "dealership":
      return {
        names:
          deal.data?.dealership?.data?.info?.displayName || deal.data?.dealership?.data?.info?.name,
        email: deal?.data?.dealership?.data?.contacts?.[0]?.email
      };
  }
};
const renderSigning = (
  order: number,
  type: "applicant" | "dealership" | "coApplicant",
  deal: Deal,
  eSigning: OneSpanSigning
) => {
  const [requestId] = React.useState(uuidv4());
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const resendEsignEmailState = useSelector(
    (state: RootState) => state.resendEsignEmailSlice[requestId]
  );
  const checklistListId = deal._id;
  useEffect(() => {
    dispatch(
      getChecklistList(checklistListId, {
        options: { pagination: false },
        query: { "data.dealId": deal._id }
      })
    );
    return () => {
      dispatch(removeChecklistList(checklistListId));
      return;
    };
  }, [dispatch, checklistListId]);

  const checklistSlice = useSelector(
    (state: RootState) => state.listChecklistSlice[checklistListId]
  );
  const [email, setEmail] = React.useState<string | undefined>(undefined);
  useEffect(() => {
    if (checklistSlice?.status === "success") {
      setEmail(checklistSlice?.entities?.[0]?.data?.info?.eSignEmailData?.[type]);
    }
  }, [checklistSlice]);

  const { names: signerNames, email: signerEmail } = getNamesAndEmail(type, deal);
  const [signingStatus, setSigningStatus] = React.useState(eSigning?.data?.info?.status?.[type]);
  const isEmailInvalid = (eSigning?.data?.info?.invalidEmails ?? []).includes(
    signerEmail as string
  );
  const [open, setOpen] = React.useState(false);
  const camelCaseToLabel = (input: string) =>
    input
      .replace(/([a-z])([A-Z])/g, "$1 $2")
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");

  const handleResendEmail = () => {
    if (email) {
      dispatch(
        resendEsignEmail({
          packageId: eSigning.data.oneSpanPackageId,
          email,
          role: type,
          oneSpanSigningId: eSigning._id,
          requestId
        })
      );
      dispatch(showLoader());
    } else {
      enqueueSnackbar(`${capitalize(type)} has no email!`, {
        variant: "error"
      });
    }
  };
  const handleEditOneSpanSigning = (type: "dealership" | "applicant" | "coApplicant") => {
    dispatch(
      editOneSpanSigning({
        requestId,
        ...{
          ...eSigning,
          data: {
            ...eSigning.data,
            updateEmail: {
              _id: deal.data[type]?._id,
              type,
              isChanged: email !== signerEmail,
              email
            },
            info: {
              ...eSigning.data.info,
              status: {
                ...eSigning.data.info.status,
                [type]: signingStatus
              }
            }
          }
        }
      })
    );
  };

  React.useEffect(() => {
    if (resendEsignEmailState?.status) {
      if (resendEsignEmailState?.status === "success") {
        enqueueSnackbar(`Successfully resent an email to the ${capitalize(type)}`, {
          variant: "success"
        });
        dispatch(hideLoader());
      } else if (resendEsignEmailState?.status === "error") {
        //@ts-ignore
        enqueueSnackbar(resendEsignEmailState?.message, { variant: "error" });
        dispatch(hideLoader());
      }
    }
  }, [resendEsignEmailState?.status]);
  if (checklistSlice?.status !== "success") return <div>Loading</div>;
  return (
    <React.Fragment>
      {open && (
        <Dialog maxWidth="sm" open={true} fullWidth onClose={() => setOpen(false)}>
          <DialogTitle>Edit one span signing</DialogTitle>
          <DialogContent>
            <Grid container xs={12} spacing={2}>
              <Grid item xs={12}>
                <Typography
                  style={{ color: "red", fontWeight: "bold", padding: "10px" }}
                  variant="subtitle2"
                >
                  Changing the email of the recipient and resending an email to the new one would
                  void the current e-sign process and resend invitations for signing to all the
                  recipients.
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  value={signingStatus}
                  getOptionLabel={(x) => camelCaseToLabel(x) ?? ""}
                  getOptionSelected={(x, y) => x === y}
                  options={Object.keys(OneSpanStatus)}
                  onChange={(event, newValue) => {
                    if (newValue !== null) {
                      setSigningStatus(newValue as OneSpanStatus);
                    }
                  }}
                  openOnFocus
                  id="signing-status-select"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputLabelProps={{ shrink: true }}
                      InputProps={{ ...params.InputProps }}
                      label={"Signing status"}
                      variant="filled"
                      size="small"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Email"
                  fullWidth
                  size="small"
                  value={email}
                  type="text"
                  variant="filled"
                  placeholder="Change email"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={(e) => handleEditOneSpanSigning(type)}>Save</Button>
            <Button onClick={(e) => setOpen(false)}>Close</Button>
          </DialogActions>
        </Dialog>
      )}
      <Paper>
        <Grid
          container
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: 5,
            margin: 2
          }}
        >
          <Grid item xs={6}>
            <Typography variant="body1" style={{ fontWeight: "500" }}>
              {order}. {capitalize(type)} - {capitalize(signerNames)}
            </Typography>
          </Grid>
          <Grid item xs={!isEmailInvalid ? 4 : 2}>
            <Typography style={oneSpanStatusToStyle(eSigning?.data?.info?.status?.[type])}>
              {oneSpanStatus(eSigning?.data?.info?.status?.[type])}
            </Typography>
          </Grid>
          {isEmailInvalid && (
            <Grid item xs={2}>
              <Tooltip arrow title="Email is incorrect">
                <Warning style={{ color: "#eed202" }} />
              </Tooltip>
            </Grid>
          )}
          <Grid item xs={1}>
            <Tooltip arrow title={`Click here to re-send an email with documents for signing.`}>
              <IconButton style={{ float: "right" }} size="small" onClick={handleResendEmail}>
                <EmailIcon color="primary" />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={1}>
            <Tooltip arrow title={`Click here to change esigning status for ${type}`}>
              <IconButton style={{ float: "right" }} size="small" onClick={() => setOpen(true)}>
                <CreateIcon color="primary" />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Paper>
    </React.Fragment>
  );
};

export default function ({ eSignings, deal }: { deal: Deal; eSignings: OneSpanSigning[] }) {
  const latestESigning = eSignings?.reduce((a, b) =>
    new Date(a.createdAt).getTime() > new Date(b.createdAt).getTime() ? a : b
  );
  const hasCoApplicant = deal?.data?.coApplicant !== undefined && deal?.data?.coApplicant !== null;

  const [preview, setPreview] = useState(false);
  const [url, setUrl] = useState<string | undefined>();
  const handlePreviewDocument = (url: string) => {
    setPreview(true);
    setUrl(url);
  };
  const [openHistoryDialog, setOpenHistoryDialog] = React.useState(false);
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
          <Typography
            variant="h5"
            style={{
              color: "#254e6e",
              fontSize: "19px",
              fontWeight: "bold"
            }}
          >
            Esigning document status
          </Typography>
          {eSignings?.length > 0 && (
            <IconButton onClick={() => setOpenHistoryDialog(true)}>
              <History />
            </IconButton>
          )}
        </div>
      </Grid>
      <Grid item xs={8}>
        {renderSigning(1, "applicant", deal, latestESigning)}
        {hasCoApplicant &&
          latestESigning.data.info.status?.coApplicant &&
          renderSigning(2, "coApplicant", deal, latestESigning)}
        {(deal?.data?.dealership?.data?.info?.displayName ||
          deal?.data?.dealership?.data?.info?.name) &&
          latestESigning.data.info.status?.dealership &&
          renderSigning(hasCoApplicant ? 3 : 2, "dealership", deal, latestESigning)}
      </Grid>
      <Grid item xs={4}>
        <Grid
          container
          spacing={1}
          component={Paper}
          style={{
            display: "flex",
            padding: 5,
            margin: 2
          }}
        >
          <Grid item xs={6}>
            Generated At
          </Grid>
          <Grid item xs={6}>
            Generated By
          </Grid>
          <Grid item xs={6} style={{ fontSize: "12px" }}>
            {formatDate(new Date(latestESigning.createdAt), "long")}
          </Grid>
          <Grid item xs={6} style={{ fontSize: "12px" }}>
            {latestESigning.data.info?.generatedBy ?? "No Data"}
          </Grid>
          <Grid item xs={12}>
            <Button
              onClick={() => handlePreviewDocument(latestESigning?.data?.info?.generatedPackUrl)}
              variant="contained"
              color="primary"
              fullWidth
            >
              Preview
            </Button>
          </Grid>
        </Grid>
      </Grid>
      {preview && url && <IFrameURLModal url={url} handleClose={() => setPreview(false)} />}
      <Dialog
        open={openHistoryDialog}
        onClose={() => setOpenHistoryDialog(false)}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle>E-sign History</DialogTitle>
        <CloseDialogButton closeFunction={() => setOpenHistoryDialog(false)} />
        <DialogContent>
          <Grid container spacing={1}>
            {eSignings.map((eSigning, index) => (
              <Paper key={eSigning._id} style={{ padding: 10, marginBottom: 10, width: "100%" }}>
                <Grid container spacing={1}>
                  <Grid item xs={8}>
                    {renderSigning(1, "applicant", deal, eSigning)}
                    {hasCoApplicant &&
                      eSigning.data.info.status?.coApplicant &&
                      renderSigning(2, "coApplicant", deal, eSigning)}
                    {(deal?.data?.dealership?.data?.info?.displayName ||
                      deal?.data?.dealership?.data?.info?.name) &&
                      eSigning.data.info.status?.dealership &&
                      renderSigning(hasCoApplicant ? 3 : 2, "dealership", deal, eSigning)}
                  </Grid>
                  <Grid item xs={4}>
                    <Grid
                      container
                      spacing={1}
                      component={Paper}
                      style={{
                        padding: 5
                      }}
                    >
                      <Grid item xs={6}>
                        Generated At
                      </Grid>
                      <Grid item xs={6}>
                        Generated By
                      </Grid>
                      <Grid item xs={6} style={{ fontSize: "12px" }}>
                        {formatDate(new Date(latestESigning.createdAt), "long")}
                      </Grid>
                      <Grid item xs={6} style={{ fontSize: "12px" }}>
                        {latestESigning.data.info?.generatedBy ?? "No Data"}
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          onClick={() =>
                            handlePreviewDocument(eSigning?.data?.info?.generatedPackUrl)
                          }
                          variant="contained"
                          color="primary"
                          fullWidth
                        >
                          Preview
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" onClick={() => setOpenHistoryDialog(false)}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}
