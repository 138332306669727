import { Schema } from "ajv";

export default {
  netsuiteInvoiceId: { type: "string", nullable: true },
  netsuiteEstimateId: { type: "string", nullable: true },
  netsuiteSalesOrderId: { type: "string", nullable: true },
  netsuiteVendorBillIds: {
    type: "array",
    items: {
      type: "object",
      properties: {
        vendorBillId: { type: "string", nullable: true },
        type: { type: "string", nullable: true }
      }
    }
  },
  netsuiteStatus: {
    type: "object",
    properties: {
      estimate: {
        type: "object",
        nullable: true
      },
      invoiceVendorBill: {
        type: "object",
        nullable: true
      },
      salesOrder: {
        type: "object",
        nullable: true
      }
    }
  }
} as { [p: string]: Schema };
