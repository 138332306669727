import { Collapse, Divider, List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import {
  AccountBalance,
  Assessment,
  AssignmentInd,
  AssignmentTurnedIn,
  Beenhere,
  Create,
  DashboardSharp,
  Description,
  DescriptionOutlined,
  DoubleArrow,
  DriveEta,
  EmojiTransportation,
  Error,
  ExpandLess,
  ExpandMore,
  LibraryAddCheck,
  ListAlt,
  MoneyOff,
  PeopleAlt,
  Public,
  Room,
  Settings as SettingsIcon,
  ThumbsUpDown,
  ImportExport,
  VerifiedUser,
  Work,
  PieChart,
  TrendingUp,
  BugReport,
  AccountBalanceWallet,
  Map,
  Group,
  Timeline,
  PauseCircleFilled,
  Assignment
} from "@material-ui/icons";
import { RootState } from "app/rootReducer";
import AdditionalRequiredDocument from "components/AdditionalRequiredDocuments/AdditionalRequiredDocument";
import AddEntity from "components/Content/addEntity";
import ShowEditEntity from "components/Content/showEditEntity";
import ContractsType from "components/Contracts/ContractTypes/ContractType";
import Contracts from "components/Contracts/Contracts";
import CreditScores from "components/CreditScore/CreditScores";
import TitleIssuesDashboard from "components/Dashboard/TitleIssues/TitleIssuesDashboard";
import DealershipPrograms from "components/DealershipPrograms/DealershipPrograms";
import Deal from "components/Deals/Deals";
import DealsLog from "components/Deals/DealsLog/DealsLog";
import Dmv from "components/Dmv/Dmv";
import Leads from "components/Leads/Leads";
import LenderDecisions from "components/LenderDecisions/LenderDecisions";
import LenderTickets from "components/LenderTickets/LenderTickets";
import ManagerPrograms from "components/ManagerPrograms/ManagerPrograms";
import Vendors from "components/OtherVendors/Vendors";
import BusinessReports from "components/Reports/BusinessReports/BusinessReports";
import CustomReports from "components/Reports/CustomReports/CustomReports";
import DealerActivity from "components/Reports/DealerActivity/DealerActivity";
import DealershipBillsReport from "components/Reports/DealershipBillsReport/DealershipBillsReport";
import DealsRecap from "components/Reports/DealsRecap/DealsRecap";
import FundingNotification from "components/Reports/FundingNotification/FundingNotification";
import IncomeReport from "components/Reports/IncomeReports/IncomeReport";
import Payroll from "components/Reports/Payroll/Payroll";
import ThirdPartyDocumentsUsage from "components/Reports/ThirdPartyDocumentsUsage/ThirdPartyDocumentsUsage";
import Settings from "components/Settings/Settings";
import { QuickAccessTab, TabTypesPages } from "components/Tabs/types";
import DocumentValidations from "components/Validations/DocumentValidations";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as LendIcon } from "../../icons/lenders.svg";
import { isAllowed } from "../Access/AccessControl";
import Applicants from "../Applicants/Applicants";
import Dashboard from "../Dashboard/Dashboard";
import Dealerships from "../Dealerships/Dealerships";
import DocumentTemplates from "../DocumentTemplates/index";
import ExternalCreditApplication from "../ExternalCreditApplications/ExternalCreditApplications";
import Lenders from "../Lenders/Lenders";
import PayoffBank from "../PayoffBanks/PayoffBanks";
import Roles from "../Roles/Roles";
import States from "../States/States";
import TitleIssues from "../TitleIssues/TitleIssues";
import Users from "../Users/Users";
import VehicleInsuranceCompanies from "../VehicleInsuranceCompany/VehicleInsuranceCompanies";
import { closeDrawer } from "./drawerSlice";
import { Pages, SidebarLinks } from "./types";
import StatusReasonsTable from "components/Deals/StatusReasonsTable/StatusReasonsTable";
import Chargebacks from "components/Chargebacks/Chargebacks";
import MonthlyChargebacks from "components/Chargebacks/MonthlyChargebacks/MonthlyChargebacks";
import ChargebackDashboard from "components/Chargebacks/ChargebackDashboard/ChargebackDashboard";
import LenderDecisionsStatistics from "components/LenderDecisions/LenderDecisionsStatistics/LenderDecisionsStatistics";
import CreditScoreRanges from "components/CreditScoreRanges/CreditScoreRanges";
import LenderDashboard from "components/Dashboard/Lender/LenderDashboard/LenderDashboard";
import LenderAllocationDashbord from "components/Dashboard/Lender/AllocationDashboard/LenderAllocationDashboard";
import SalesRepresentativeDashboard from "components/Dashboard/SalesRepresentative/SalesRepresentativeDashboard";
import LenderEstimationDashboard from "components/Dashboard/Lender/LenderEstimationDashboard/LenderEstimationDashboard";
import AccountantDashboard from "components/Dashboard/Accountant/AccountantDashboard";
import FIManagerDashboard from "components/Dashboard/FIManager/FIManagerDashboard";
import { User } from "components/Users/types";
import ReportIcon from "@material-ui/icons/Report";
import MixPanelReportsTable from "components/MixPanelReports/MixPanelReportsTable";
import DealershipTickets from "components/DealershipTickets/DealershipTickets";
import LenderTicketTypes from "components/LenderTicketTypes/LenderTicketTypes";
import DealershipPerformanceReport from "components/Reports/DealershipPerformanceReport/DealershipPerformanceReport";
import LenderCoverageMap from "components/Reports/LenderCoverageMapReport/LenderCoverageMap";
import Prescreens from "components/Prescreens/Prescreens";
import SuspendedDealsTable from "components/Deals/SuspendedDealsTable/SuspendedDealsTable";
import DealershipsWithActivityWarningTable from "components/Dashboard/FIManager/DealershipsWithActivityWarningTable";
import DealershipsWithActivityWarningReport from "components/Dashboard/FIManager/DealershipsWithActivityWarningReport";
import RepresentativeDashboard from "components/Dashboard/RepresentativeDashboard/RepresentativeDashboard";
import DealsMapDashboard from "components/Dashboard/DealsMapDashboard/DealsMapDashboard";
import DefaultStipulations from "components/DefaultStipulations/DefaultStipulations";

const dealsLog = {
  type: "deals_log",
  index: "deals_log",
  label: "Deal log",
  component: DealsLog,
  icon: <ListAlt />,
  isForSidebar: true,
  isForQuickAccess: true,
  isPersistent: false,
  show: () => isAllowed({ entity: "deal", action: "menu" })
} as const;

const applicants = {
  type: "applicants",
  index: "applicants",
  label: "Applicants",
  component: Applicants,
  icon: <PeopleAlt />,
  isForSidebar: true,
  isForQuickAccess: true,
  isPersistent: false,
  show: () => isAllowed({ entity: "applicant", action: "read" })
} as const;
const contracts = {
  type: "contracts",
  index: "contracts",
  label: "Contracts",
  component: Contracts,
  icon: <Create />,
  isForSidebar: true,
  isForQuickAccess: true,
  isPersistent: false,
  show: () => isAllowed({ entity: "contract", action: "read" })
} as const;

const chargebacksTable = {
  type: "chargeback_table",
  index: "chargeback_table",
  label: "Chargebacks",
  component: Chargebacks,
  icon: <MoneyOff />,
  isForSidebar: true,
  isForQuickAccess: true,
  isPersistent: false,
  show: () => isAllowed({ entity: "chargeback", action: "read" })
} as const;
const chargebacksBills = {
  type: "chargeback_monthly_bills",
  index: "chargeback_monthly_bills",
  label: "Monthly Chargebacks",
  component: MonthlyChargebacks,
  icon: <Assessment />,
  isForSidebar: true,
  isForQuickAccess: true,
  isPersistent: false,
  show: () => isAllowed({ entity: "chargeback", action: "read" })
} as const;

const chargebacksDashboard = {
  type: "chargeback_dashboard",
  index: "chargeback_dashboard",
  label: "Chargeback Dashboard",
  component: ChargebackDashboard,
  icon: <DashboardSharp />,
  isForSidebar: true,
  isForQuickAccess: true,
  isPersistent: false,
  show: () => isAllowed({ entity: "chargeback", action: "read" })
} as const;

const contractTypes = {
  type: "contract_types",
  index: "contract_types",
  label: "Contract types",
  component: ContractsType,
  icon: <Create />,
  isForSidebar: true,
  isForQuickAccess: true,
  isPersistent: false,
  show: () => isAllowed({ entity: "contract_type", action: "read" })
} as const;

const additionalRequiredDocuments = {
  type: "additional_required_documents",
  index: "additional_required_documents",
  label: "Additional required documents",
  component: AdditionalRequiredDocument,
  icon: <DescriptionOutlined />,
  isForSidebar: true,
  isForQuickAccess: true,
  isPersistent: false,
  show: () => isAllowed({ entity: "additional_required_document", action: "read" })
} as const;

const externalCreditApplication = {
  type: "external_credit_application",
  index: "external_credit_application",
  label: "Online credit apps",
  component: ExternalCreditApplication,
  icon: <Public />,
  isForSidebar: true,
  isForQuickAccess: true,
  isPersistent: false,
  show: () => isAllowed({ entity: "external_credit_application", action: "read" })
} as const;

const lenders = {
  type: "lenders",
  index: "lenders",
  label: "Lenders",
  component: Lenders,
  icon: <LendIcon style={{ fill: "#606060" }} />,
  isForSidebar: true,
  isForQuickAccess: true,
  isPersistent: false,
  show: () => isAllowed({ entity: "lender", action: "read" })
} as const;

const deals = {
  type: "deals",
  index: "deals",
  label: "Deals",
  component: Deal,
  icon: <AssignmentTurnedIn />,
  isForSidebar: true,
  isForQuickAccess: true,
  isPersistent: false,
  show: () => isAllowed({ entity: "deal", action: "menu" })
} as const;

const status_reasons = {
  type: "status_reasons",
  index: "status_reasons",
  label: "Status Reasons",
  component: StatusReasonsTable,
  icon: <AssignmentTurnedIn />,
  isForSidebar: true,
  isForQuickAccess: true,
  isPersistent: false,
  show: () => isAllowed({ entity: "deal", action: "menu" })
} as const;

const dealerships = {
  type: "dealerships",
  index: "dealerships",
  label: "Dealerships",
  component: Dealerships,
  icon: <DriveEta />,
  isForSidebar: true,
  isForQuickAccess: true,
  isPersistent: false,
  show: () => isAllowed({ entity: "dealership", action: "read" })
} as const;

const suspendedDealsLog = {
  type: "suspended_deals_log",
  index: "suspended_deals_log",
  label: "Suspended Deals Log",
  component: SuspendedDealsTable,
  icon: <PauseCircleFilled />,
  isForSidebar: true,
  isForQuickAccess: true,
  isPersistent: false,
  show: () => isAllowed({ entity: "deal", action: "read" })
} as const;

const lenderDashboard = {
  type: "lender_dashboard",
  index: "lender_dashboard",
  label: "Lender Dashboard",
  component: LenderDashboard,
  icon: <AccountBalance />,
  isForSidebar: true,
  isForQuickAccess: true,
  isPersistent: false,
  show: (user?: User | undefined) => user?.data?.info?.dashboardTypes?.lender || false
} as const;

const representativeDashboard = {
  type: "representative_dashboard",
  index: "representative_dashboard",
  label: "Representatve Dashboard",
  component: RepresentativeDashboard,
  icon: <Work />,
  isForSidebar: true,
  isForQuickAccess: true,
  isPersistent: false,
  show: (user?: User | undefined) => user?.data?.info?.dashboardTypes?.representative || false
} as const;

const lenderAllocationDashboard = {
  type: "lender_allocation_dashboard",
  index: "lender_allocation_dashboard",
  label: "Allocation Dashboard",
  component: LenderAllocationDashbord,
  icon: <PieChart />,
  isForSidebar: true,
  isForQuickAccess: true,
  isPersistent: false,
  show: (user?: User | undefined) => user?.data?.info?.dashboardTypes?.allocation || false
} as const;
const lenderEstimationDashboard = {
  type: "lender_estimation_dashboard",
  index: "lender_estimation_dashboard",
  label: "Estimation Dashboard",
  component: LenderEstimationDashboard,
  icon: <TrendingUp />,
  isForSidebar: true,
  isForQuickAccess: true,
  isPersistent: false,
  show: (user?: User | undefined) => user?.data?.info?.dashboardTypes?.lenderEstimation || false
} as const;
const salesRepresentativeDashboard = {
  type: "sales_representative_dashboard",
  index: "sales_representative_dashboard",
  label: "Sales Representative Dashboard",
  component: SalesRepresentativeDashboard,
  icon: <Work />,
  isForSidebar: true,
  isForQuickAccess: true,
  isPersistent: false,
  show: (user?: User | undefined) => user?.data?.info?.dashboardTypes?.salesRep || false
} as const;
const accountantDashboard = {
  type: "accountant_dashboard",
  index: "accountant_dashboard",
  label: "Accountant Dashboard",
  component: AccountantDashboard,
  icon: <AccountBalanceWallet />,
  isForSidebar: true,
  isForQuickAccess: true,
  isPersistent: false,
  show: (user?: User | undefined) => user?.data?.info?.dashboardTypes?.accountant || false
} as const;
const managerDashboard = {
  type: "manager_dashboard",
  index: "manager_dashboard",
  label: "Manager Dashboard",
  component: FIManagerDashboard,
  icon: <Group />,
  isForSidebar: true,
  isForQuickAccess: true,
  isPersistent: false,
  show: (user?: User | undefined) => user?.data?.info?.dashboardTypes?.["f&i"] || false
} as const;
const dealsMapDashboard = {
  type: "deals_map_dashboard",
  index: "deals_map_dashboard",
  label: "Deals Map Dashboard",
  component: DealsMapDashboard,
  icon: <Map />,
  isForSidebar: true,
  isForQuickAccess: true,
  isPersistent: false,
  show: (user?: User | undefined) => user?.data?.info?.dashboardTypes?.["dealsMap"] || false
} as const;
const dealsRecap = {
  type: "deals_recap",
  index: "deals_recap",
  label: "Deals recap",
  component: DealsRecap,
  icon: <Assessment />,
  isForSidebar: true,
  isForQuickAccess: true,
  isPersistent: false,
  show: () => isAllowed({ entity: "custom_report", action: "read" })
} as const;
const dealershipPerformanceReport = {
  type: "dealership_performance_report",
  index: "dealership_performance_report",
  label: "Dealership Performance Report",
  component: DealershipPerformanceReport,
  icon: <Timeline />,
  isForSidebar: true,
  isForQuickAccess: true,
  isPersistent: false,
  show: () => isAllowed({ entity: "custom_report", action: "read" })
} as const;
const fundingNotification = {
  type: "funding_notification",
  index: "funding_notification",
  label: "Funding Notification",
  component: FundingNotification,
  icon: <Assessment />,
  isForSidebar: true,
  isForQuickAccess: true,
  isPersistent: false,
  show: () => isAllowed({ entity: "custom_report", action: "read" })
} as const;

const settings = {
  type: "settings",
  index: "settings",
  label: "Settings",
  component: Settings,
  icon: <SettingsIcon />,
  isForSidebar: true,
  isForQuickAccess: true,
  isPersistent: false,
  show: () =>
    isAllowed([
      { entity: "settings", action: "read" },
      { entity: "settings", action: "update" }
    ])
} as const;

const payroll = {
  type: "payroll",
  index: "payroll",
  label: "Payroll",
  component: Payroll,
  icon: <Assessment />,
  isForSidebar: true,
  isForQuickAccess: true,
  isPersistent: false,
  show: () => isAllowed({ entity: "custom_report", action: "read" })
} as const;

const thirdPartyDocumentsUsage = {
  type: "third_party_documents_usage",
  index: "third_party_documents_usage",
  label: "Third Party Documents Usage",
  component: ThirdPartyDocumentsUsage,
  icon: <Assessment />,
  isForSidebar: true,
  isForQuickAccess: true,
  isPersistent: false,
  show: () => isAllowed({ entity: "checklist", action: "read" })
} as const;

const incomeReport = {
  type: "income_report",
  index: "income_report",
  label: "Income Report",
  component: IncomeReport,
  icon: <Assessment />,
  isForSidebar: true,
  isForQuickAccess: true,
  isPersistent: false,
  show: () => isAllowed({ entity: "custom_report", action: "read" })
} as const;

const dealerActivity = {
  type: "dealer_activity",
  index: "dealer_activity",
  label: "Dealer Activity",
  component: DealerActivity,
  icon: <Assessment />,
  isForSidebar: true,
  isForQuickAccess: true,
  isPersistent: false,
  show: () => isAllowed({ entity: "custom_report", action: "read" })
} as const;

const dealerActivityWarning = {
  type: "dealer_activity_warning",
  index: "dealer_activity_warning",
  label: "Dealer Activity Warning",
  component: DealershipsWithActivityWarningReport,
  icon: <Assessment />,
  isForSidebar: true,
  isForQuickAccess: true,
  isPersistent: false,
  show: () => isAllowed({ entity: "custom_report", action: "read" })
} as const;
const dealershipBillsReport = {
  type: "dealership_bills_report",
  index: "dealership_bills_report",
  label: "Monthly bills for Netsuite",
  component: DealershipBillsReport,
  icon: <Assessment />,
  isForSidebar: true,
  isForQuickAccess: true,
  isPersistent: false,
  show: () => isAllowed({ entity: "dealership_bills_report", action: "read" })
} as const;

const businessReports = {
  type: "business_report",
  index: "business_report",
  label: "Business Reports",
  component: BusinessReports,
  icon: <Assessment />,
  isForSidebar: true,
  isForQuickAccess: true,
  isPersistent: false,
  show: () => isAllowed({ entity: "business_report", action: "read" })
} as const;

const lenderCoverageStatistics = {
  type: "lender_coverage_statistics",
  index: "lender_coverage_statistics",
  label: "Lender Coverage Statistics",
  component: LenderDecisionsStatistics,
  icon: <Assessment />,
  isForSidebar: true,
  isForQuickAccess: true,
  isPersistent: false,
  show: (user?: User | undefined) => user?.data?.info?.dashboardTypes?.coverageStatistics || false
} as const;
const lenderCoverageMap = {
  type: "lender_coverage_map",
  index: "lender_coverage_map",
  label: "Lender Coverage Map",
  component: LenderCoverageMap,
  icon: <Assessment />,
  isForSidebar: true,
  isForQuickAccess: true,
  isPersistent: false,
  show: (user?: User | undefined) => user?.data?.info?.dashboardTypes?.coverageMap || false
} as const;
const customReports = {
  type: "custom_report",
  index: "custom_report",
  label: "Custom Reports",
  component: CustomReports,
  icon: <Assessment />,
  isForSidebar: true,
  isForQuickAccess: true,
  isPersistent: false,
  show: () => isAllowed({ entity: "custom_report", action: "read" })
} as const;

const states = {
  type: "states",
  index: "states",
  label: "States taxes",
  component: States,
  icon: <Room />,
  isForSidebar: true,
  isForQuickAccess: true,
  isPersistent: false,
  show: () => isAllowed({ entity: "state", action: "read" })
} as const;

const dashboard = {
  type: "dashboard",
  index: "dashboard",
  label: "Dashboard",
  component: Dashboard,
  icon: <DashboardSharp />,
  isForSidebar: true,
  isForQuickAccess: false,
  isPersistent: true,
  show: () => false
} as const;

const wfd_users = {
  type: "wfd_users",
  index: "wfd_users",
  label: "Users",
  component: Users,
  icon: <Work />,
  isForSidebar: true,
  isForQuickAccess: true,
  isPersistent: false,
  show: () => isAllowed({ entity: "wfd_user", action: "read" })
} as const;

const roles = {
  type: "roles",
  index: "roles",
  label: "Roles",
  component: Roles,
  icon: <AssignmentInd style={{ fill: "#606060" }} />,
  isForSidebar: true,
  isForQuickAccess: true,
  isPersistent: false,
  show: () => isAllowed({ entity: "role", action: "read" })
} as const;

const dmv = {
  type: "dmv",
  index: "dmv",
  label: "Dmv",
  component: Dmv,
  icon: <EmojiTransportation />,
  isForSidebar: true,
  isForQuickAccess: true,
  isPersistent: false,
  show: () => isAllowed({ entity: "dmv", action: "read" })
} as const;

const prescreen = {
  type: "prescreen",
  index: "prescreen",
  label: "Prescreen",
  component: Prescreens,
  icon: <EmojiTransportation />,
  isForSidebar: true,
  isForQuickAccess: true,
  isPersistent: false,
  show: () => isAllowed({ entity: "prescreen", action: "read" })
} as const;

const creditScoreRange = {
  type: "credit_score_range",
  index: "credit_score_range",
  label: "Credit score ranges",
  component: CreditScoreRanges,
  icon: <ImportExport />,
  isForSidebar: true,
  isForQuickAccess: true,
  isPersistent: false,
  show: () => isAllowed({ entity: "credit_score_range", action: "read" })
} as const;
const lenderTickets = {
  type: "lender_tickets",
  index: "lender_tickets",
  label: "Lender Tickets",
  component: LenderTickets,
  icon: <BugReport />,
  isForSidebar: true,
  isForQuickAccess: true,
  isPersistent: false,
  show: () => isAllowed({ entity: "dmv", action: "read" })
} as const;
const lenderTicketsTypes = {
  type: "lender_ticket_types",
  index: "lender_ticket_types",
  label: "Lender Tickets Types",
  component: LenderTicketTypes,
  icon: <SettingsIcon />,
  isForSidebar: true,
  isForQuickAccess: true,
  isPersistent: false,
  show: () => isAllowed({ entity: "dmv", action: "read" })
} as const;

const documentTemplates = {
  type: "document_templates",
  index: "document_templates",
  label: "Document templates",
  component: DocumentTemplates,
  icon: <DescriptionOutlined />,
  isForSidebar: true,
  isForQuickAccess: true,
  isPersistent: false,
  show: () => isAllowed({ entity: "document_template", action: "read" })
} as const;

const titleIssues = {
  type: "title_issues",
  index: "title_issues",
  label: "Title Information",
  component: TitleIssues,
  icon: <Error />,
  isForSidebar: true,
  isForQuickAccess: true,
  isPersistent: false,
  show: () => isAllowed({ entity: "title_issue", action: "read" })
} as const;

const titleDashboard = {
  type: "title_dashboard",
  index: "title_dashboard",
  label: "Title Dashboard",
  component: TitleIssuesDashboard,
  icon: <DashboardSharp />,
  isForSidebar: true,
  isForQuickAccess: true,
  isPersistent: false,
  show: (user?: User | undefined) =>
    isAllowed({ entity: "title_issue", action: "read" }) &&
    (user?.data?.info?.dashboardTypes?.titleIssues || false)
} as const;

const dealershipPrograms = {
  type: "dealership_programs",
  index: "dealership_programs",
  label: "Dealership programs",
  component: DealershipPrograms,
  icon: <Error />,
  isForSidebar: true,
  isForQuickAccess: true,
  isPersistent: false,
  show: () => isAllowed({ entity: "dealership_program", action: "read" })
} as const;

const managerPrograms = {
  type: "manager_programs",
  index: "manager_programs",
  label: "Manager programs",
  component: ManagerPrograms,
  icon: <Error />,
  isForSidebar: true,
  isForQuickAccess: true,
  isPersistent: false,
  show: () => isAllowed({ entity: "manager_program", action: "read" })
} as const;

const documentValidations = {
  type: "document_validations",
  index: "document_validations",
  label: "Document validations",
  component: DocumentValidations,
  icon: <LibraryAddCheck />,
  isForSidebar: true,
  isForQuickAccess: true,
  isPersistent: false,
  show: () => isAllowed({ entity: "document_validation", action: "read" })
} as const;

const vehicleInsuranceCompany = {
  type: "vehicle_insurance_company",
  index: "vehicle_insurance_company",
  label: "Vehicle insurance company",
  component: VehicleInsuranceCompanies,
  icon: <VerifiedUser />,
  isForSidebar: true,
  isForQuickAccess: true,
  isPersistent: false,
  show: () => isAllowed({ entity: "vehicle_insurance_company", action: "read" })
} as const;

const payoffBanks = {
  type: "payoff_banks",
  index: "payoff_banks",
  label: "Payoff banks",
  component: PayoffBank,
  icon: <AccountBalance />,
  isForSidebar: true,
  isForQuickAccess: true,
  isPersistent: false,
  show: () => isAllowed({ entity: "payoff_bank", action: "read" })
} as const;
const defaultStipulations = {
  type: "default_stipulations",
  index: "default_stipulations",
  label: "Default Stipulations",
  component: DefaultStipulations,
  icon: <Assignment />,
  isForSidebar: true,
  isForQuickAccess: true,
  isPersistent: false,
  show: () => isAllowed({ entity: "default_stipulation", action: "read" })
} as const;
const otherVendors = {
  type: "other_vendors",
  index: "other_vendors",
  label: "Vendors",
  component: Vendors,
  icon: <AccountBalance />,
  isForSidebar: true,
  isForQuickAccess: true,
  isPersistent: false,
  show: () => isAllowed({ entity: "other_vendor", action: "read" })
} as const;

const creditScores = {
  type: "credit_scores",
  index: "credit_scores",
  label: "Credit Bureaus",
  component: CreditScores,
  icon: <Beenhere />,
  isForSidebar: true,
  isForQuickAccess: true,
  isPersistent: false,
  show: () => isAllowed({ entity: "credit_score", action: "read" })
} as const;
const leads = {
  type: "leads",
  index: "leads",
  label: "Leads log",
  component: Leads,
  icon: <DoubleArrow />,
  isForSidebar: true,
  isForQuickAccess: true,
  isPersistent: false,
  show: () => isAllowed({ entity: "deal", action: "menu" })
} as const;
const dealershipTickets = {
  type: "dealership_tickets",
  index: "dealership_tickets",
  label: "Dealership Tickets",
  component: DealershipTickets,
  icon: <BugReport />,
  isForSidebar: true,
  isForQuickAccess: true,
  isPersistent: false,
  show: () => isAllowed({ entity: "dealership_ticket", action: "menu" })
} as const;
const lenderDecisions = {
  type: "lender_decisions",
  index: "lender_decisions",
  label: "Lender decisions",
  component: LenderDecisions,
  icon: <ThumbsUpDown />,
  isForSidebar: true,
  isForQuickAccess: true,
  isPersistent: false,
  show: () => isAllowed({ entity: "lender_decision", action: "read" })
} as const;
const showPage = {
  type: "showPage",
  index: "showPage",
  label: "Show page",
  component: ShowEditEntity,
  icon: <PeopleAlt />,
  isForSidebar: false,
  isForQuickAccess: false,
  isPersistent: false,
  show: () => true
} as const;

const addPage = {
  type: "addPage",
  index: "addPage",
  label: "Add page",
  component: AddEntity,
  icon: <PeopleAlt />,
  isForSidebar: false,
  isForQuickAccess: false,
  isPersistent: false,
  show: () => true
} as const;

const mixPanelReports = {
  type: "mixpanel_reports",
  index: "mixpanel_reports",
  label: "Mixpanel Reports",
  component: MixPanelReportsTable,
  icon: <ReportIcon />,
  isForSidebar: true,
  isForQuickAccess: true,
  isPersistent: false,
  show: () => isAllowed({ entity: "custom_report", action: "read" })
} as const;

export const pages: Pages = {
  deals_map_dashboard: dealsMapDashboard,
  default_stipulations: defaultStipulations,
  dealership_performance_report: dealershipPerformanceReport,
  contracts: contracts,
  additional_required_documents: additionalRequiredDocuments,
  settings: settings,
  contract_types: contractTypes,
  leads: leads,
  dashboard: dashboard,
  dealerships: dealerships,
  suspended_deals_log: suspendedDealsLog,
  dealership_tickets: dealershipTickets,
  lenders: lenders,
  lender_decisions: lenderDecisions,
  wfd_users: wfd_users,
  roles: roles,
  applicants: applicants,
  dmv: dmv,
  credit_score_range: creditScoreRange,
  lender_tickets: lenderTickets,
  lender_ticket_types: lenderTicketsTypes,
  deals: deals,
  status_reasons: status_reasons,
  credit_scores: creditScores,
  deals_log: dealsLog,
  states: states,
  document_templates: documentTemplates,
  title_issues: titleIssues,
  title_dashboard: titleDashboard,
  chargeback_table: chargebacksTable,
  chargeback_dashboard: chargebacksDashboard,
  lender_coverage_map: lenderCoverageMap,
  chargeback_monthly_bills: chargebacksBills,
  dealership_programs: dealershipPrograms,
  manager_programs: managerPrograms,
  document_validations: documentValidations,
  payoff_banks: payoffBanks,
  other_vendors: otherVendors,
  vehicle_insurance_company: vehicleInsuranceCompany,
  external_credit_application: externalCreditApplication,
  deals_recap: dealsRecap,
  lender_dashboard: lenderDashboard,
  representative_dashboard: representativeDashboard,
  lender_allocation_dashboard: lenderAllocationDashboard,
  lender_estimation_dashboard: lenderEstimationDashboard,
  sales_representative_dashboard: salesRepresentativeDashboard,
  accountant_dashboard: accountantDashboard,
  manager_dashboard: managerDashboard,
  funding_notification: fundingNotification,
  payroll: payroll,
  third_party_documents_usage: thirdPartyDocumentsUsage,
  income_report: incomeReport,
  custom_report: customReports,
  business_report: businessReports,
  lender_coverage_statistics: lenderCoverageStatistics,
  dealer_activity: dealerActivity,
  dealer_activity_warning: dealerActivityWarning,
  dealership_bills_report: dealershipBillsReport,
  mixpanel_reports: mixPanelReports,
  prescreen: prescreen,
  addPage,
  showPage
} as const;

export const sidebarLinks: SidebarLinks<TabTypesPages> = {
  dashboards: {
    icon: <DashboardSharp />,
    index: "dashboards",
    label: "Dashboards",
    isForSidebar: true,
    isForQuickAccess: true,
    isPersistent: false,
    show: (user) =>
      lenderDashboard.show(user) ||
      lenderAllocationDashboard.show(user) ||
      lenderEstimationDashboard.show(user) ||
      salesRepresentativeDashboard.show(user) ||
      titleDashboard.show(user) ||
      accountantDashboard.show(user) ||
      lenderCoverageMap.show(user) ||
      dealsMapDashboard.show(user) ||
      managerDashboard.show(user) ||
      representativeDashboard.show(user),
    children: {
      deals_map_dashboard: dealsMapDashboard,
      lender_coverage_map: lenderCoverageMap,
      lender_dashboard: lenderDashboard,
      lender_allocation_dashboard: lenderAllocationDashboard,
      lender_estimation_dashboard: lenderEstimationDashboard,
      sales_representative_dashboard: salesRepresentativeDashboard,
      representative_dashboard: representativeDashboard,
      accountant_dashboard: accountantDashboard,
      manager_dashboard: managerDashboard,
      lender_coverage_statistics: lenderCoverageStatistics,
      title_dashboard: titleDashboard
    }
  },
  leads: leads,
  status_reasons: status_reasons,
  deals_log: dealsLog,
  applicants: applicants,
  external_credit_application: externalCreditApplication,
  lenders: lenders,
  lender_decisions: lenderDecisions,
  deals: deals,
  dealerships: dealerships,
  suspended_deals_log: suspendedDealsLog,
  reports: {
    icon: <Assessment />,
    index: "reports",
    label: "Reports",
    isForSidebar: true,
    isForQuickAccess: true,
    isPersistent: false,
    show: () =>
      dealsRecap.show() ||
      fundingNotification.show() ||
      payroll.show() ||
      thirdPartyDocumentsUsage.show() ||
      incomeReport.show() ||
      dealershipBillsReport.show() ||
      dealerActivity.show() ||
      dealerActivityWarning.show() ||
      businessReports.show() ||
      customReports.show(),
    children: {
      dealership_performance_report: dealershipPerformanceReport,
      deals_recap: dealsRecap,
      funding_notification: fundingNotification,
      payroll: payroll,
      third_party_documents_usage: thirdPartyDocumentsUsage,
      income_report: incomeReport,
      dealer_activity: dealerActivity,
      dealer_activity_warning: dealerActivityWarning,
      custom_report: customReports,
      dealership_bills_report: dealershipBillsReport,
      business_report: businessReports,
      lender_coverage_statistics: lenderCoverageStatistics,
      mixpanel_reports: mixPanelReports
    }
  },
  tickets: {
    icon: <BugReport />,
    index: "tickets",
    label: "Tickets",
    isForSidebar: true,
    isForQuickAccess: true,
    isPersistent: false,
    show: () => dealershipTickets.show() || lenderTickets.show(),
    children: {
      lender_tickets: lenderTickets,
      lender_ticket_types: lenderTicketsTypes,
      dealership_tickets: dealershipTickets
    }
  },
  credit_scores: creditScores,
  states: states,
  contractsTab: {
    icon: <Create />,
    index: "contractsTab",
    label: "Contracts",
    isForSidebar: true,
    isForQuickAccess: true,
    isPersistent: false,
    show: () => isAllowed({ entity: "contract", action: "read" }),
    children: {
      contracts: contracts,
      contract_types: contractTypes
    }
  },
  dashboard: dashboard,
  wfd_users: wfd_users,
  roles: roles,
  dmv: dmv,
  prescreen: prescreen,
  documents: {
    icon: <Description />,
    index: "documents",
    label: "Documents",
    isForSidebar: true,
    isForQuickAccess: true,
    isPersistent: false,
    show: () => isAllowed({ entity: "document_template", action: "read" }),
    children: {
      document_templates: documentTemplates,
      document_validations: documentValidations,
      default_stipulations: defaultStipulations
    }
  },
  additional_required_documents: additionalRequiredDocuments,
  dealership_programs: dealershipPrograms,
  manager_programs: managerPrograms,
  vehicle_insurance_company: vehicleInsuranceCompany,
  payoff_banks: payoffBanks,
  other_vendors: otherVendors,
  chargebacks: {
    icon: <MoneyOff />,
    index: "chargebacks",
    label: "Chargebacks",
    isForSidebar: true,
    isForQuickAccess: true,
    isPersistent: false,
    show: () => isAllowed({ entity: "chargeback", action: "read" }),
    children: {
      chargeback_table: chargebacksTable,
      chargeback_dashboard: chargebacksDashboard,
      chargeback_monthly_bills: chargebacksBills
    }
  },
  titles: {
    icon: <Description />,
    index: "titles",
    label: "Titles",
    isForSidebar: true,
    isForQuickAccess: true,
    isPersistent: false,
    show: () => isAllowed({ entity: "title_issue", action: "read" }),
    children: {
      title_dashboard: titleDashboard,
      title_issues: titleIssues
    }
  },
  credit_score_range: creditScoreRange,
  mixpanel_reports: mixPanelReports,
  settings: settings,
  addPage,
  showPage
} as const;

const DropDown = ({
  tab,
  keyId,
  user,
  createOrFocusTab
}: {
  keyId: string;
  user: User | undefined;
  tab:
    | typeof sidebarLinks["reports"]
    | typeof sidebarLinks["documents"]
    | typeof sidebarLinks["titles"]
    | typeof sidebarLinks["chargebacks"]
    | typeof sidebarLinks["dashboards"]
    | typeof sidebarLinks["tickets"]
    | typeof sidebarLinks["contractsTab"];
  createOrFocusTab: (tabInfo: QuickAccessTab) => void;
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const dispatch = useDispatch();
  return (
    <React.Fragment key={tab.index}>
      <ListItem id={tab.label} button onClick={() => setOpen((open) => !open)}>
        <ListItemIcon style={{ marginLeft: "5px" }}>{tab.icon}</ListItemIcon>
        <ListItemText primary={tab.label} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse
        in={open}
        timeout="auto"
        unmountOnExit
        style={{ paddingLeft: "5px", background: "rgb(200,230,255)" }}
      >
        <List component="div" disablePadding>
          {Object.values(tab.children).map((childTab) => {
            return (
              childTab.show(user) && (
                <ListItem
                  button
                  id={childTab.label}
                  key={"inner" + childTab.index}
                  onClick={() => {
                    dispatch(closeDrawer()); // close drawer on drawer item click
                    createOrFocusTab({
                      _id: keyId,
                      label: childTab.label,
                      index: childTab.index,
                      screenId: "leftScreen",
                      isForSidebar: true,
                      isForQuickAccess: true,
                      isPersistent: true
                    });
                  }}
                >
                  <ListItemIcon style={{ marginLeft: "5px" }}>{childTab?.icon}</ListItemIcon>
                  <ListItemText primary={childTab?.label} />
                </ListItem>
              )
            );
          })}
        </List>
      </Collapse>
    </React.Fragment>
  );
};

export default function DrawerLinks({
  createOrFocusTab
}: {
  createOrFocusTab: (tabInfo: QuickAccessTab) => void;
}) {
  const dispatch = useDispatch();
  const currentUser = useSelector((state: RootState) => state.authSlice.user)?.databaseData;
  return (
    <div style={{ width: 240 }} role="presentation">
      <Divider />
      <List>
        {Object.entries(sidebarLinks)
          .filter(
            ([, tab]) => tab.isForSidebar && tab.show(currentUser) && tab.index !== "showPage"
          )
          .map(([key, tab]) => {
            if (tab.index === "showPage" || tab.index === "addPage") {
              return <></>;
            }
            if (
              tab.index === "reports" ||
              tab.index === "documents" ||
              tab.index === "contractsTab" ||
              tab.index === "titles" ||
              tab.index === "dashboards" ||
              tab.index === "tickets" ||
              tab.index === "chargebacks"
            ) {
              return (
                <div key={key}>
                  <DropDown
                    tab={tab}
                    user={currentUser}
                    keyId={key}
                    createOrFocusTab={createOrFocusTab}
                  />
                </div>
              );
            }

            return (
              <ListItem
                button
                id={tab.label}
                key={tab.index}
                onClick={() => {
                  dispatch(closeDrawer()); // close drawer on sub list item click
                  //@ts-ignore
                  createOrFocusTab({
                    _id: key,
                    label: tab.label,
                    index: tab.index,
                    screenId: "leftScreen",
                    isForSidebar: true,
                    isForQuickAccess: true,
                    isPersistent: true
                  });
                }}
              >
                <ListItemIcon style={{ marginLeft: "5px" }}>
                  {sidebarLinks[tab.index].icon}
                </ListItemIcon>
                <ListItemText primary={tab.label} />
              </ListItem>
            );
          })}
      </List>
    </div>
  );
}
