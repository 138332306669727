import { Button, Grid } from "@material-ui/core";
import { RootState } from "app/rootReducer";
import { LenderNote } from "components/LenderTickets/LenderNotes/types";
import LenderTickets from "components/LenderTickets/LenderTickets";
import { getLenderList, removeLenderList } from "components/Lenders/listLenderSlice";
import { TitleIssueNote } from "components/TitleIssueNotes/types";
import { useStickyState } from "index";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import LatestTitlesTable from "./LatestTitlesTable";
import UnreadNotes from "./UnreadNotes";

import StyledBadge from "components/StyledBadge";
import useGetLenderTickets from "hooks/useGetLenderTickets/useGetLenderTickets";
import useGetTitleIssues from "hooks/useGetTitleIssues/useGetTitleIssues";
import DealershipLimitsTable from "./DealershipLimitsTable";

export type DmvQuery = {
  lender: object | null;
  dmv: object | null;
  collateralTypes: object | null;
};

const projection = {
  "data.deal.data.lender._id": 1,
  "data.deal.data.applicant.data.info.currentState": 1,
  "data.info.dmvInfo.status": 1,
  "data.info.oldTitleRegistrationInfo.status": 1,
  "data.info.titleRegistrationInfo.status": 1,
  "data.info.receiptInfo.status": 1,
  "data.info.dmvCheckedCount": 1,
  "data.titleIssueNotes": 1,
  _id: 1,
  createdAt: 1,
  creatorId: 1,
  deleted: 1
};

export default ({
  setResetStickyCallback
}: {
  setResetStickyCallback?: React.Dispatch<React.SetStateAction<(() => void) | undefined>>;
}) => {
  const dispatch = useDispatch();
  const listId = "titleIssuesDashboard";

  const lenderList = useSelector((state: RootState) => state.listLenderSlice[listId]);

  const [lenderTicketsResetStickyCallback, setLenderTicketsResetStickyCallback] = React.useState<
    () => void
  >();
  const [
    latestTitlesTableResetStickyCallback,
    setLatestTitlesTableResetStickyCallback
  ] = React.useState<() => void>();
  const [
    restrictedToBeFundedTableResetStickyCallback,
    setRestrictedToBeFundedTableResetStickyCallback
  ] = React.useState<() => void>();
  const [
    restrictedApplicationProgressTableResetStickyCallback,
    setRestrictedApplicationProgressTableResetStickyCallback
  ] = React.useState<() => void>();

  const [overallQuery, setOverallQuery] = useStickyState<Record<string, any>>(
    {
      "data.info.dmvInfo.status": { $ne: "not_supported" },
      "data.deal.data.info.dealDates.contractDate": { $ne: null },
      "data.deal.data.info.vehicle.VIN": { $ne: null },
      $expr: { $gte: ["$createdAt", { $toDate: "$data.deal.data.dealership.data.info.titleDate" }] }
    },
    "title_issues_dashboard_overallQuery"
  );
  const [titleIssuesQueryType, setTitleIssuesQueryType] = useStickyState<
    "supported" | "not_supported" | "all"
  >("supported", "title_issues_dashboard_titleIssuesQueryType");
  const { data: titleIssues } = useGetTitleIssues({
    query: overallQuery,
    options: {
      projection,
      pagination: false
    },
    aggregateFirst: true
  });
  const { data: lenderTickets } = useGetLenderTickets({
    query: overallQuery,
    options: {
      projection: {
        "data.info.status": 1,
        "data.lenderNotes": 1
      },
      pagination: false
    },
    aggregateFirst: true
  });

  const unreadNotes = (titleIssues ?? []).reduce((acc, titleIssue) => {
    const unreadNotes =
      titleIssue?.data?.titleIssueNotes?.filter(
        (note) => !note.data?.info?.seen?.wfd && note.data.info.creator === "dealer"
      ) || [];
    return [...acc, ...unreadNotes];
  }, [] as TitleIssueNote[]);

  const unreadNotesTickets = (lenderTickets ?? []).reduce((acc, ticket) => {
    const unreadNotes =
      ticket?.data?.lenderNotes?.filter(
        (note) => !note.data?.info?.seen?.wfd && note.data.info.creator === "lender"
      ) || [];
    return [...acc, ...unreadNotes];
  }, [] as LenderNote[]);

  React.useEffect(() => {
    if (!lenderList)
      dispatch(
        getLenderList(listId, {
          options: { pagination: false, sort: { "data.info.name": "asc" } }
        })
      );
    return () => {
      dispatch(removeLenderList(listId));
      return;
    };
  }, [dispatch, listId]);

  const handleResetStickyState = () => {
    setOverallQuery((prev) => ({
      ...prev,
      "data.info.dmvInfo.status": { $ne: "not_supported" }
    }));
    lenderTicketsResetStickyCallback?.();
    latestTitlesTableResetStickyCallback?.();
    restrictedToBeFundedTableResetStickyCallback?.();
    restrictedApplicationProgressTableResetStickyCallback?.();
  };
  React.useEffect(() => {
    setResetStickyCallback?.(() => handleResetStickyState);
  }, [
    lenderTicketsResetStickyCallback,
    latestTitlesTableResetStickyCallback,
    restrictedToBeFundedTableResetStickyCallback,
    restrictedApplicationProgressTableResetStickyCallback
  ]);
  return (
    <React.Fragment>
      {setResetStickyCallback === undefined && (
        <Button
          onClick={() => handleResetStickyState()}
          style={{ margin: "5px 0px", float: "right" }}
          color="primary"
          variant="contained"
        >
          Reset state
        </Button>
      )}
      <Grid container xs={12} spacing={2}>
        <Grid container xs={3} item spacing={2}>
          <div style={{ display: "flex", margin: "5px", gap: "10px" }}>
            <StyledBadge
              badgeContent={titleIssuesQueryType === "supported" ? 1 : 0}
              color={"rgb(0, 128, 0)"}
              variant="dot"
            >
              <Button
                fullWidth
                variant="contained"
                style={{ background: "rgb(0, 128, 0)", marginTop: "5px", marginRight: "5px" }}
                onClick={() => {
                  setOverallQuery((prev) => ({
                    ...prev,
                    $expr: {
                      $gte: [
                        "$createdAt",
                        { $toDate: "$data.deal.data.dealership.data.info.titleDate" }
                      ]
                    },
                    "data.info.dmvInfo.status": { $ne: "not_supported" }
                  }));
                  setTitleIssuesQueryType("supported");
                }}
              >
                Supported states
              </Button>
            </StyledBadge>
            <StyledBadge
              badgeContent={titleIssuesQueryType === "not_supported" ? 1 : 0}
              color={"rgb(250, 155, 155)"}
              variant="dot"
            >
              <Button
                fullWidth
                variant="contained"
                style={{ background: "rgb(250, 155, 155)", marginTop: "5px", marginRight: "5px" }}
                onClick={() => {
                  setOverallQuery((prev) => ({
                    ...prev,
                    $expr: {
                      $gte: [
                        "$createdAt",
                        { $toDate: "$data.deal.data.dealership.data.info.titleDate" }
                      ]
                    },
                    "data.info.dmvInfo.status": "not_supported"
                  }));
                  setTitleIssuesQueryType("not_supported");
                }}
              >
                Unsupported states
              </Button>
            </StyledBadge>
            <StyledBadge
              badgeContent={titleIssuesQueryType === "all" ? 1 : 0}
              color={"rgb(155 180 250)"}
              variant="dot"
            >
              <Button
                fullWidth
                variant="contained"
                style={{ background: "rgb(155 180 250)", marginTop: "5px", marginRight: "5px" }}
                onClick={() => {
                  setOverallQuery((prev) => ({
                    ...prev,
                    $expr: {
                      $gte: [
                        "$createdAt",
                        { $toDate: "$data.deal.data.dealership.data.info.titleDate" }
                      ]
                    },
                    "data.info.dmvInfo.status": undefined
                  }));
                  setTitleIssuesQueryType("all");
                }}
              >
                All
              </Button>
            </StyledBadge>
          </div>
        </Grid>
        <Grid
          item
          id="dmvChecks"
          xs={12}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: " flex-start"
          }}
        >
          <LatestTitlesTable
            setResetStickyCallback={setLatestTitlesTableResetStickyCallback}
            overallQuery={overallQuery}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <DealershipLimitsTable
                query={{
                  "data.info.titleManagement.suspensions.funding": true,
                  "data.info.titleManagement.isRestricted": true
                }}
                title="Funding limit"
                slice="funding-limit"
                setResetStickyCallback={setRestrictedToBeFundedTableResetStickyCallback}
              />
            </Grid>
            <Grid item xs={6}>
              <DealershipLimitsTable
                query={{
                  "data.info.titleManagement.suspensions.applicationProcessing": true,
                  "data.info.titleManagement.isRestricted": true
                }}
                title="Penalty box limit"
                slice="penalty-box-limit"
                setResetStickyCallback={setRestrictedApplicationProgressTableResetStickyCallback}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <LenderTickets
                dashboardView
                overallQuery={overallQuery}
                setResetStickyCallback={setLenderTicketsResetStickyCallback}
              />
            </Grid>
            <Grid xs={6} item>
              <Grid container spacing={2} direction="column">
                <Grid item xs={12}>
                  <UnreadNotes notes={unreadNotes} type="dealer" />
                </Grid>
                <Grid item xs={12}>
                  <UnreadNotes notes={unreadNotesTickets} type="lender" />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
